import React, { useEffect, useState } from 'react';
import { Box, Button, FormField, Link, Modal, Textarea } from '@amzn/awsui-components-react-v3';

function validateCreds(credentialsString: string): Record<string, any> | undefined {
  try {
    const creds = JSON.parse(credentialsString);
    if ('credentials' in creds && 'assumedRoleUser' in creds) {
      const credentials = creds['credentials'];
      const realToken = typeof credentials['sessionToken'] === 'string' && credentials['sessionToken'].length > 0;
      const futureExpiration =
        credentials['expiration'] &&
        Number.isInteger(credentials['expiration']) &&
        credentials['expiration'] > new Date().getTime();
      const assumedIsengard =
        typeof creds['assumedRoleUser']['assumedRoleId'] === 'string' &&
        creds['assumedRoleUser']['assumedRoleId'].endsWith('-Isengard');
      if (realToken && futureExpiration && assumedIsengard) return creds;
    }
  } catch (e) {
    console.warn('Invalid Credentials JSON: ', e);
  }
  return undefined;
}

function getIsengardLink(): string {
  const gamma = document.location.hostname.includes('gamma');
  const region = document.location.hostname.split('.')[0];
  if (region === 'us-isof-south-1') {
    return gamma
      ? 'https://isengard.ale.aws-border.hci.ic.gov/manage-accounts/182597795218/console-roles'
      : 'https://isengard.ale.aws-border.hci.ic.gov/manage-accounts/952659838590/console-roles';
  }
  if (region === 'eu-isoe-west-1') {
    return gamma
      ? 'https://isengard.ncl.aws-border.adc-e.uk/manage-accounts/119487713158/console-roles'
      : 'https://isengard.ncl.aws-border.adc-e.uk/manage-accounts/780317141464/console-roles';
  }
  return 'https://isengard.amazon.com/console-access';
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Global callbacks are safe as long as only one IsengardAuthModal is created
// and only one call to waitForIsengardCredentials is made at a time
let openModal: () => void = undefined;
let submitModal: (c: Record<string, any>) => void = undefined;

export async function waitForIsengardCredentials() {
  console.log('Waiting for credential submission');
  // Wait for openModal to be assigned if this function is called before the modal is created
  while (!openModal) {
    await sleep(10);
  }

  openModal();
  return await new Promise<Record<string, any>>((resolve) => {
    submitModal = resolve;
  });
}

export function IsengardAuthModal() {
  // If this modal is re-mounted while a submitModal is set, start open, otherwise start closed.
  const [authOpen, setAuthOpen] = useState(!!submitModal);
  const [jsonContent, setJsonContent] = useState('');
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    openModal = () => setAuthOpen(true);
  }, []);

  const footer = (
    <Box float='right'>
      <Button
        variant='primary'
        onClick={() => {
          if (jsonContent.length) {
            const parsedCredentials = validateCreds(jsonContent);
            if (parsedCredentials) {
              setErrorText('');
              setJsonContent('');
              submitModal?.(parsedCredentials);
              setAuthOpen(false);
              submitModal = undefined;
              return;
            }
            setErrorText('Invalid credentials!');
          }
        }}
      >
        Submit
      </Button>
    </Box>
  );

  const formLabel = (
    <span>
      Copy JSON credentials for <b>DPServices</b> role from
      <Box margin={{ horizontal: 'xs' }} display='inline'>
        <Link href={getIsengardLink()} external>
          Isengard
        </Link>
        :
      </Box>
    </span>
  );

  return (
    <Modal visible={authOpen} footer={footer}>
      <FormField label={formLabel} errorText={errorText}>
        <Textarea
          value={jsonContent}
          onChange={({ detail }) => setJsonContent(detail.value)}
          placeholder='{ ... }'
          ariaRequired
        />
      </FormField>
    </Modal>
  );
}
