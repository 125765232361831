import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, ColumnLayout, Flashbar, Header, Tabs } from '@amzn/awsui-components-react-v3';
import {
  convertToDgsHCResourceArn,
  convertToDgsHCResourceId,
  CopiableText,
  DefaultRouteProps,
  fetchTemplatesForResourceId,
  isDGSAdmin,
} from 'src/commons/common';
import { WSDatasetList } from 'src/components/workspaces/dataBrowse/database/datasetList';
import { DatabaseTagList } from 'src/components/workspaces/dataBrowse/database/DatabaseTagList';
import { DatabaseEditModal } from 'src/components/workspaces/dataBrowse/database/databaseEdit';
import { listDatabases, listDatasetsForDatabase } from 'src/api/catalog';
import { signedInWithWorkspace } from 'src/components/dataadvisory/constants';

import { getResourceTags } from 'src/api/permissions';
import { PageHeader } from 'src/components/notifications/common';
import { AccessSelectModal } from 'src/components/workspaces/dataBrowse/database/dbAccessSelectModal';
import { createGlueCatalogDetailLink } from 'src/routes';
import { TABLE_CONTENT_TYPE, DATA_PERMISSION_LAKE_FORMATION_TYPE, GALAXI_DATASOURCE_ID } from 'src/commons/constants';
import { DataConsumersTable } from 'src/components/permissions/myBaselining/dataConsumersTable';
import { coloredStatus } from 'src/components/resourcesmanager/components';
import { SubscriptionsTable } from 'src/components/catalog/subscriptions/subscriptionsTable';
import MetadataDetails from '../../common/metadataDetails';
import { AdvisoriesForResourceTable } from 'src/components/dataadvisory/listAdvisoriesPage/advisoriesForResource';
import { generateArnFromId } from 'src/components/utils/arnUtil';
import { TemplatesForResourceDetailsTable } from 'src/components/templates/TemplatesForResourceDetailsTable';
import { enableAdvisories } from 'src/api/config';
import _ from 'lodash';
import { ContactInfo } from 'src/components/workspaces/common/ContactInfo';
import { DataBadges } from 'src/components/catalog/common';
import { getGlossariesForResource, lakeFormationIcon, ownerLinkItem } from 'src/components/workspaces/common/common';
import RegisterTable from 'src/components/catalog/register';

export interface WorkspaceDatabaseDetailProps extends DefaultRouteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
  setActiveDatabaseName: any;
  cartItemIds: [];
  addToCart: any;
  catalogMap: any;
  userInfo: any;
  cartItems: any;
}

export const DatabaseDetail = (props: WorkspaceDatabaseDetailProps) => {
  const [database, setDatabase] = useState(undefined);
  const [editDatabaseModalVisible, setEditDatabaseModalVisible] = useState(false);
  const [tags, setTags] = useState(undefined);
  const [accessSelectVisible, setAccessSelectVisible] = useState(undefined);
  const [catalogName, setCatalogName] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState(undefined);
  const [businessGlossaries, setBusinessGlossaries] = useState(undefined);
  const [datasetIds, setDatasetIds] = useState(undefined);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const fetchTags = async (database) => {
    let resourceId = `DS-glueLF|A-${database?.CatalogId}|DN-${database?.DatabaseName}|R-${database?.Region}`;
    let tagList = [];
    try {
      let getResourceTagsResponse = await getResourceTags({
        resourceId: resourceId,
        statusCustomerType: 'Active:Publisher',
      });
      tagList.push(...getResourceTagsResponse.tags);
    } catch (err) {
      console.log(err);
    }
    setTags(tagList);
  };

  const getDatabaseDatasets = async () => {
    let nextToken = null;
    let datasetIds = [];
    do {
      try {
        let datasets = await listDatasetsForDatabase({
          CatalogId: props.match.params.catalogid,
          DatabaseName: props.match.params.databasename,
          NextToken: nextToken,
        });
        const newIds = datasets.DataSetList.map((item) => item.Id);
        datasetIds = datasetIds.concat(newIds);
        nextToken = datasets.NextToken;
      } catch (err) {
        console.log('Exception when fetch datasets for database', err);
        break;
      }
    } while (nextToken != null);
    setDatasetIds(datasetIds);
  };

  const fetchTemplatesForDatabase = async (database) => {
    let resourceId = convertToDgsHCResourceId(
      database?.CatalogId,
      database?.ClusterIdentifier,
      database?.DatabaseName,
      undefined,
      undefined,
      undefined,
      database?.DataAccessRole,
    );
    let fetchedTemplates = await fetchTemplatesForResourceId(resourceId);
    setTemplates(fetchedTemplates);
  };

  const notifyEditSuccess = async (msg) => {
    //on success update details
    await handleRefresh();
    setNotifications([
      {
        type: 'success',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };
  const notifyEditFailure = async (msg) => {
    setNotifications([
      {
        type: 'error',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    props.setActiveDatabaseName(props.match.params.databasename);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const databases = await listDatabases({
        DatabaseKeyList: [
          {
            CatalogId: props.match.params.catalogid,
            DatabaseName: props.match.params.databasename,
          },
        ],
      });
      let database = databases.DatabaseInfoList[0];
      setDatabase(database);
      setCatalogName(props.catalogMap.get(database?.CatalogId + ':' + database?.Region));
      await fetchTags(database);
      await fetchTemplatesForDatabase(database);
      setBusinessGlossaries(await getGlossariesForResource(getArn(database)));

      if (!signedInWithWorkspace(props)) {
        await getDatabaseDatasets();
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    setLoading(false);
    setAccessSelectVisible(false);
  };

  const getTemplateForDatabase = () => {
    return <TemplatesForResourceDetailsTable items={templates} userOwnsResource={userOwnsDatabase()} />;
  };

  const userOwnsDatabase = () => {
    if (!database || !database.Owners) return false;
    if (props.userInfo && isDGSAdmin(props.userInfo.memberGroupIds)) return true;
    return _.intersection(database.Owners, props.userInfo.memberWorkspaceIds).length > 0;
  };

  const buildResource = () => {
    if (!database) return {};
    return {
      accountId: database.CatalogId,
      region: database.Region,
      type: 'DATABASE',
      dataCatalogObjectDetails: {
        dataSourceId: 'GlueLF',
        databaseName: database.DatabaseName,
      },
      dpType: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      tagResourceId: `DS-glueLF|A-${database.CatalogId}|DN-${database.DatabaseName}`,
    };
  };

  const getDatabaseDetailsMetadata = (props: any) => {
    const databaseDetails = [];
    if (database?.DatabaseName) {
      databaseDetails.push(
        <CopiableText name={'Database name'} key={'Database name'} value={database?.DatabaseName} />,
      );
    }
    if (database?.Description) {
      databaseDetails.push(<CopiableText name={'Description'} key={'Description'} value={database?.Description} />);
    }
    if (database?.CatalogId) {
      databaseDetails.push(
        <CopiableText
          name={'Catalog ID'}
          key={'Catalog ID'}
          value={database?.CatalogId}
          url={createGlueCatalogDetailLink(database?.CatalogId, database?.Region)}
        />,
      );
    }
    if (catalogName) {
      databaseDetails.push(
        <CopiableText
          name={'Catalog name'}
          key={'Catalog name'}
          value={catalogName}
          url={createGlueCatalogDetailLink(database?.CatalogId, database?.Region)}
        />,
      );
    }
    if (database?.Region) {
      databaseDetails.push(<CopiableText name={'Region'} key={'Region'} value={database?.Region} />);
    }
    if (database?.Owners) {
      databaseDetails.push(
        <CopiableText
          copiable={false}
          name={'Owner'}
          key={'Owner'}
          value={database?.Owners.map((owner) => ownerLinkItem(owner, props.workspaceNameMap))}
        />,
      );
    }
    if (database?.CreatedBy) {
      databaseDetails.push(<CopiableText name={'Created by'} key={'Created by'} value={database?.CreatedBy} />);
    }
    if (database?.CreatedOn) {
      databaseDetails.push(<CopiableText name={'Created on'} key={'CreatedOn'} value={database?.CreatedOn} />);
    }
    if (database?.UpdatedBy) {
      databaseDetails.push(<CopiableText name={'Updated by'} key={'UpdatedBy'} value={database?.UpdatedBy} />);
    }
    if (database?.UpdatedOn) {
      databaseDetails.push(<CopiableText name={'Updated on'} key={'UpdatedOn'} value={database?.UpdatedOn} />);
    }
    if (database?.AutoTableOnboard !== null) {
      databaseDetails.push(
        <CopiableText
          copiable={false}
          name={'Auto-onboard'}
          key={'Auto-onboard'}
          value={coloredStatus(database?.AutoTableOnboard ? 'ENABLED' : 'DISABLED')}
        />,
      );
    }
    if (database?.AllowSNSEventSubscription !== null) {
      databaseDetails.push(
        <CopiableText
          copiable={false}
          name={'Allow event subscriptions'}
          key={'Allow event subscriptions'}
          value={coloredStatus(database?.AllowSNSEventSubscription ? 'ENABLED' : 'DISABLED')}
        />,
      );
    }
    return databaseDetails;
  };

  const closeEditModal = () => {
    setEditDatabaseModalVisible(false);
  };

  const notify = async (type, msg) => {
    setNotifications([
      {
        type: type,
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const databaseDetail = () => {
    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: (
          <>
            <div className='awsui-util-container'>
              <div className='awsui-util-container-header'>
                <div className='awsui-util-action-stripe'>
                  <div className='awsui-util-action-stripe-title'>
                    <h2>Database details</h2>
                  </div>
                  <div className='awsui-util-action-stripe-group'>
                    <div style={{ paddingTop: 5, paddingRight: 10 }} hidden={!userOwnsDatabase()}>
                      <Button
                        variant='normal'
                        onClick={() => {
                          setEditDatabaseModalVisible(true);
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <ColumnLayout columns={3} borders='horizontal'>
                {getDatabaseDetailsMetadata(props)}
              </ColumnLayout>
            </div>
            <br />
            {!loading && database && <ContactInfo resource={getArn(database)} />}
          </>
        ),
      },
      {
        label: 'Datasets',
        id: 'Datasets',
        content: (
          <WSDatasetList
            {...props}
            catalogId={props.match.params.catalogid}
            databaseName={props.match.params.databasename}
            datasets={database?.tables}
          />
        ),
      },

      {
        label: 'Templates',
        id: 'Templates',
        content: getTemplateForDatabase(),
      },
      {
        label: 'Metadata',
        id: 'Metadata',
        content: (
          <MetadataDetails
            resourceOwnerIds={database?.Owners}
            resource={convertToDgsHCResourceArn(
              database?.CatalogId,
              database?.ClusterIdentifier,
              database?.DatabaseName,
              undefined,
              undefined,
              undefined,
              database?.DataAccessRole,
            )}
            activeGroup={props.activeGroup}
            activeWorkspace={props.activeWorkspace}
            setContentType={props.setContentType}
            setNotification={notify}
          />
        ),
      },
    ];

    if (userOwnsDatabase())
      tabs.push({
        label: 'Consumers',
        id: 'consumers',
        content: <DataConsumersTable {...props} resource={buildResource()} />,
      });

    if (signedInWithWorkspace(props)) {
      tabs.push({
        label: 'Tags',
        id: 'Tags',
        content: (
          <DatabaseTagList
            {...props}
            notifyEditFailure={notifyEditFailure}
            notifyEditSuccess={notifyEditSuccess}
            database={database}
            updateTagLabel={handleRefresh}
          />
        ),
      });
    }

    tabs.push({
      label: 'Subscriptions',
      id: 'Subscriptions',
      content: (
        <SubscriptionsTable
          {...props}
          allowSNSEventSubscription={database?.AllowSNSEventSubscription}
          resource={buildResource()}
        />
      ),
    });

    if (database && database.DataAccessRole !== null && userOwnsDatabase() && !signedInWithWorkspace(props)) {
      tabs.push({
        label: 'Register datasets',
        id: 'Register datasets',
        content: (
          <RegisterTable
            setContentType={(type) => props.setContentType(type)}
            title='Register datasets'
            onNotificationChange={setNotifications}
            extraFeatures={true}
            databaseDetail={database}
            idFilter={datasetIds}
            onlyShowDatasetName={false}
            loading={loading}
            activeGroup={props.activeGroup}
          />
        ),
      });
    }

    if (enableAdvisories()) {
      tabs.push({
        label: 'Advisories',
        id: 'advisories',
        content: (
          <AdvisoriesForResourceTable
            resourceArn={getArn(database)}
            activeGroup={props.activeGroup}
            setContentType={props.setContentType}
          />
        ),
      });
    }

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  };

  const getArn = (database) => {
    const id = `DS-glueLF|A-${database?.CatalogId}|DN-${database?.DatabaseName}|R-${database?.Region}`;
    const arn = generateArnFromId(id);
    return arn;
  };

  const handleAddToCart = (_) => {
    setAccessSelectVisible(true);
  };

  if (!database && !loading) {
    return (
      <>
        <h2>Database not found</h2>
        The given database is not valid, or you do not have permission to view it. Please check the URL for mistakes and
        try again.
      </>
    );
  }

  return (
    <>
      <Flashbar items={notifications} />

      <PageHeader
        buttons={
          GALAXI_DATASOURCE_ID != database?.DataSourceId && signedInWithWorkspace(props)
            ? [
                {
                  text: 'Request Lake Formation access',
                  onItemClick: handleAddToCart,
                  disabled: props.activeWorkspace == null,
                },
              ]
            : []
        }
        header={
          <Header description={database?.Description} variant={'h1'}>
            {lakeFormationIcon} {database?.DatabaseName}
          </Header>
        }
      />
      {<DataBadges tags={tags} businessGlossaries={businessGlossaries} database={database} />}
      {databaseDetail()}

      {database !== undefined && (
        <DatabaseEditModal
          {...props}
          database={database}
          visible={editDatabaseModalVisible}
          dismiss={closeEditModal}
          notifyEditSuccess={notifyEditSuccess}
          notifyEditFailure={notifyEditFailure}
        />
      )}

      {accessSelectVisible !== undefined && accessSelectVisible && tags && database && (
        <AccessSelectModal
          {...props}
          visible={accessSelectVisible}
          dismiss={() => {
            setAccessSelectVisible(false);
          }}
          // candidateAccess={accessApproaches}
          tags={tags}
          database={database}
          catalogName={catalogName}
        />
      )}
    </>
  );
};
