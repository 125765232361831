import React from 'react';
import { Redirect } from 'react-router-dom';

export interface CurrentWorkspaceProps {
  tabId: string | undefined;
  setContentType: any;
  activeGroup: string;
  activeWorkspace: any;
}

export const CurrentWorkspace = (props: CurrentWorkspaceProps) => {
  return <Redirect push to={`/current-workspace/${props.activeWorkspace.workspaceId}`} />;
};
