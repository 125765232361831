import * as React from 'react';

import { Button, ButtonDropdown, ColumnLayout, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import {
  ADVISORY_STATUS_ACTIVE,
  ADVISORY_STATUS_CANCELED,
  ADVISORY_STATUS_DRAFT,
  ADVISORY_STATUS_RESOLVED,
  statusIndicatorForAdvisory,
} from 'src/components/dataadvisory/constants';
import { Advisory, UserPreference } from 'aws-sdk/clients/awsdlomni';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { OptInOrOutNotificationsModal } from 'src/components/dataadvisory/advisoryDetailsPage/optInOrOutNotificationsModal';
import { createCreateAdvisoryFromAdvisoryLink, createSendAdvisoryNotificationLink } from 'src/routes';

export interface AdvisoryDetailHeaderProps {
  advisory: Advisory;
  activeGroup: string;
  activeWorkspace?: any;
  userOwnsAdvisory: boolean;
  userPreferences: UserPreference[];
  onPreferenceUpdateSuccess: any;
  onPreferenceUpdateFailure: any;
  handleEdit?: any;
  setStatus?: any;
}

export const AdvisoryDetailHeader = (props: AdvisoryDetailHeaderProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [optInModalVisible, setOptInModalVisible] = useState(false);
  const [optOutModalVisible, setOptOutModalVisible] = useState(false);

  const statusChangeOptions = () => {
    if (props.advisory.status == ADVISORY_STATUS_ACTIVE) {
      return [
        { text: 'Resolved', id: 'resolved' },
        { text: 'Canceled', id: 'canceled' },
      ];
    } else if (props.advisory.status == ADVISORY_STATUS_RESOLVED || props.advisory.status == ADVISORY_STATUS_CANCELED) {
      return [{ text: 'Active', id: 'active' }];
    } else if (props.advisory.status == ADVISORY_STATUS_DRAFT) {
      return [
        { text: 'Active', id: 'active' },
        { text: 'Canceled', id: 'canceled' },
      ];
    }
    return [];
  };

  const actionsForAdvisoryOwner = () => {
    return [
      <SpaceBetween size={'xs'} direction={'horizontal'}>
        <Button onClick={props.handleEdit}>Edit</Button>
        <ButtonDropdown
          expandableGroups
          items={[
            {
              text: 'Set state',
              id: 'state',
              items: statusChangeOptions(),
            },
            {
              text: 'Notify customers...',
              id: 'notify',
              disabled: disableNotifyCustomers(),
            },
            {
              text: 'Clone to new advisory',
              id: 'clone',
              disabled: false,
            },
          ]}
          onItemClick={(e) => {
            if (e.detail.id == 'notify') {
              setRedirect(createSendAdvisoryNotificationLink(props.advisory.advisoryId));
            }
            if (e.detail.id == 'active' || e.detail.id == 'resolved' || e.detail.id == 'canceled') {
              props.setStatus(e.detail.id);
            }
            if (e.detail.id == 'clone') {
              setRedirect(createCreateAdvisoryFromAdvisoryLink(props.advisory.advisoryId));
            }
          }}
        >
          Actions
        </ButtonDropdown>
      </SpaceBetween>,
    ];
  };

  const actionsForAdvisoryNonOwner = () => {
    return [
      <SpaceBetween size={'xs'} direction={'horizontal'}>
        <ButtonDropdown
          expandableGroups
          items={[
            {
              text: 'Subscribe to notifications',
              id: 'optin',
              disabled: false,
            },
            {
              text: 'Unsubscribe from notifications',
              id: 'optout',
              disabled: false,
            },
          ]}
          onItemClick={(e) => {
            if (e.detail.id == 'optin') {
              setOptInModalVisible(true);
            }
            if (e.detail.id == 'optout') {
              setOptOutModalVisible(true);
            }
          }}
        >
          Actions
        </ButtonDropdown>
      </SpaceBetween>,
    ];
  };

  const disableNotifyCustomers = () => {
    // can only send a notification if the advisory is active.
    return props.advisory.status != ADVISORY_STATUS_ACTIVE;
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <OptInOrOutNotificationsModal
        activeGroup={props.activeGroup}
        activeWorkspace={props.activeWorkspace}
        advisory={props.advisory}
        userOwnsAdvisory={props.userOwnsAdvisory}
        userPreferences={props.userPreferences}
        onPreferenceUpdateSuccess={props.onPreferenceUpdateSuccess}
        onPreferenceUpdateFailure={props.onPreferenceUpdateFailure}
        visible={optInModalVisible}
        opt={'in'}
        onDismiss={() => setOptInModalVisible(false)}
      />
      <OptInOrOutNotificationsModal
        activeGroup={props.activeGroup}
        activeWorkspace={props.activeWorkspace}
        advisory={props.advisory}
        userOwnsAdvisory={props.userOwnsAdvisory}
        userPreferences={props.userPreferences}
        onPreferenceUpdateSuccess={props.onPreferenceUpdateSuccess}
        onPreferenceUpdateFailure={props.onPreferenceUpdateFailure}
        visible={optOutModalVisible}
        opt={'out'}
        onDismiss={() => setOptOutModalVisible(false)}
      />
      <SpaceBetween size={'s'}>
        <Header
          variant={'h1'}
          actions={props.userOwnsAdvisory ? actionsForAdvisoryOwner() : actionsForAdvisoryNonOwner()}
        >
          {props.advisory.title}
        </Header>
        <ColumnLayout columns={3}>
          <div>{statusIndicatorForAdvisory(props.advisory)}</div>
          <div>
            <b>Created on:</b> {props.advisory.createdOn}
          </div>
          <div>
            <b>Updated on:</b> {props.advisory.updatedOn}
          </div>
        </ColumnLayout>
      </SpaceBetween>
    </>
  );
};
