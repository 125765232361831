import * as React from 'react';
import { useState, useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import { defaultPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  Header,
  Link,
  Button,
  SpaceBetween,
  Box,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { linkForResource, statusIndicatorForAdvisory } from 'src/components/dataadvisory/constants';
import { Advisory, AdvisoryList } from 'aws-sdk/clients/awsdlomni';
import { createAdvisoryDetailsLink } from 'src/routes';
//import {PageHeader} from "src/components/common/header";

export interface DataAdvisoryTableProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  advisories: AdvisoryList;
  nextToken?: string;
  loadMoreResults?: any;
  loading: boolean;
}

export const DataAdvisoryTable = (props: DataAdvisoryTableProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [tableMessage] = useState('No advisories');
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'advisoryTitle',
      header: 'Title',
      cell: (item) => advisoryDetailsLink(item),
      width: 500,
      sortingField: 'advisoryTitle',
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => statusIndicatorForAdvisory(item),
      width: 120,
    },
    {
      id: 'impactedResources',
      header: 'Impacted resources',
      cell: (item) => impactedResourcesList(item),
      width: 250,
    },
    {
      id: 'createdOn',
      header: 'Created on',
      cell: (item) => item.createdOn,
      width: 200,
    },
    {
      id: 'updatedOn',
      header: 'Updated on',
      cell: (item) => item.updatedOn,
      width: 200,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const advisoryDetailsLink = (item: Advisory) => {
    return <Link onFollow={() => setRedirect(createAdvisoryDetailsLink(item.advisoryId))}>{item.title}</Link>;
  };

  const impactedResourcesList = (item) => {
    let listOfLineItems = item.impactedResources.map((resource) => <li>{linkForResource(resource)}</li>);
    if (listOfLineItems.length > 3) {
      const howManyMoreThan3 = listOfLineItems.length - 3;
      listOfLineItems = listOfLineItems.slice(0, 3);

      listOfLineItems.push(<li>And {howManyMoreThan3} more...</li>);
    }
    return <ul>{listOfLineItems}</ul>;
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(props.advisories, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No advisories to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <Table
        {...collectionProps}
        loadingText='Loading advisories...'
        loading={props.loading}
        columnDefinitions={columnDefinitions}
        items={items}
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
            <SpaceBetween size='m'>
              <b>No advisories</b>
              <p>There are no advisories available.</p>
            </SpaceBetween>
          </Box>
        }
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <Header
            counter={'(' + props.advisories.length + ')'}
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                {props.nextToken && props.nextToken.length > 0 && (
                  <Button variant={'normal'} onClick={props.loadMoreResults}>
                    Load more results...
                  </Button>
                )}
              </SpaceBetween>
            }
          >
            Data advisories
          </Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
