import { useEffect, useState } from 'react';
import { FORM_CONTENT_TYPE, hcMetadataStatus } from 'src/commons/constants';
import { Page } from 'src/routes';
import { editGlossary, getGlossary } from 'src/api/catalog';
import * as React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import {
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

export interface EditBusinessGlossaryProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const EditBusinessGlossary = (props: EditBusinessGlossaryProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [glossaryId, setGlossaryId] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [ownerId, setOwnerId] = useState(undefined);
  const [status, setStatus] = useState(null);
  const [description, setDescription] = useState(undefined);
  const [glossaryValue, setGlossaryValue] = useState(undefined);
  const [name, setName] = useState(undefined);
  const { state } = useLocation();

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);
    const activeGroup = props.activeGroup;
    const activeWorkspace = props.activeWorkspace;
    const ownerId = activeWorkspace ? activeWorkspace.workspaceId : activeGroup;
    setOwnerId(ownerId);
    setGlossaryId(state.glossaryId);
  }, []);

  useEffect(() => {
    if (ownerId && glossaryId) {
      fetchGlossaryToEdit();
    }
  }, [glossaryId, ownerId]);

  const fetchGlossaryToEdit = async () => {
    try {
      let getGlossaryResult = await getGlossary({
        OwnerId: ownerId,
        GlossaryId: glossaryId,
      });
      let glossaryDetails = getGlossaryResult.GlossaryDetails;
      if (!glossaryDetails) {
        setNotification('error', 'No glossary found with id:' + glossaryId);
      } else {
        setDescription(glossaryDetails.Description);
        setName(glossaryDetails.Name);
        setGlossaryValue(glossaryDetails.GlossaryValue);
        setStatus(
          hcMetadataStatus.filter((statusSelection) => {
            return statusSelection.value === glossaryDetails.Status;
          })[0],
        );
      }
    } catch (err) {
      setNotification('error', err.message);
    }
  };

  const handleEditGlossary = async () => {
    setButtonLoading(true);
    try {
      await editGlossary({
        GlossaryId: glossaryId,
        OwnerId: ownerId,
        NewDescription: description,
        NewName: name,
        NewGlossaryValue: glossaryValue,
        NewStatus: status.value,
      });
      setRedirect({
        pathname: Page.BUSINESS_GLOSSARIES,
        state: {
          status: 'success',
          message: 'Glossary updated successfully',
        },
      });
    } catch (e) {
      setNotification('error', `Error when trying to edit glossary: ${e.message}`);
    } finally {
      setButtonLoading(false);
      setDescription(undefined);
      setStatus(null);
      setName(undefined);
    }
  };

  const setNotification = async (header, message) => {
    if (header === 'error') {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}
      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>
      <Form
        header={
          <Header variant='h1' description='Provide the details to edit glossary.'>
            Edit glossary
          </Header>
        }
        actions={
          <SpaceBetween direction='horizontal' size='s'>
            <Button variant='link' onClick={() => setRedirect(Page.BUSINESS_GLOSSARIES)}>
              Cancel
            </Button>
            <Button variant='primary' loading={buttonLoading} onClick={handleEditGlossary}>
              {'Save changes'}
            </Button>
          </SpaceBetween>
        }
      >
        <Container className='custom-screenshot-hide' header={<Header variant='h2'>Glossary details</Header>}>
          <ColumnLayout>
            <SpaceBetween size='m'>
              <FormField label={<div>Glossary ID</div>} description='A glossary Id'>
                <Input name='glossaryId' value={glossaryId} ariaRequired={true} disabled={true} />
              </FormField>
              <FormField label={<div>Owner Id</div>} description='glossary owner'>
                <Input name='ownerId' value={ownerId} ariaRequired={true} disabled={true} />
              </FormField>
              <FormField label={<div>Name</div>} description='Name of glossary.'>
                <Input
                  name='ownerId'
                  value={name}
                  ariaRequired={true}
                  onChange={({ detail }) => setName(detail.value)}
                />
              </FormField>
              <FormField label={<div>Description</div>} description='Description of glossary.'>
                <Input
                  name='description'
                  value={description}
                  ariaRequired={true}
                  onChange={({ detail }) => setDescription(detail.value)}
                />
              </FormField>

              <FormField label={<div>Value</div>} description='Value for the glossary.'>
                <Input
                  name='value'
                  value={glossaryValue}
                  onChange={({ detail }) => setGlossaryValue(detail.value.trim())}
                  ariaRequired={true}
                />
              </FormField>
              <FormField label='Status' description='Status of glossary'>
                <Select
                  name='status'
                  placeholder='Enabled'
                  selectedOption={status}
                  options={hcMetadataStatus}
                  ariaRequired={true}
                  onChange={({ detail }) => {
                    setStatus(detail.selectedOption);
                  }}
                  disabled={false}
                />
              </FormField>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};

export default EditBusinessGlossary;
