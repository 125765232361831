import { Box, Button, ColumnLayout, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { editDatabase } from 'src/api/catalog';
import { Toggle } from '@amzn/awsui-components-react-v3/polaris';

export interface DatabaseEditModalProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  database: any;
  visible: boolean;
  dismiss: any;
  notifyEditSuccess: any;
  notifyEditFailure: any;
}

export const DatabaseEditModal = (props: DatabaseEditModalProps) => {
  const [autoTableOnboard, setAutoTableOnboard] = useState(undefined);
  const [eventSubscriptions, setEventSubscriptions] = useState(undefined);
  const [databaseDescription, setDatabaseDescription] = useState(undefined);
  const [databaseName, setDatabaseName] = useState(undefined);

  const handleEdit = async () => {
    try {
      let request = {
        DatabaseName: props.database.DatabaseName,
        CatalogId: props.database.CatalogId,
        Description: databaseDescription,
        AutoTableOnboard: autoTableOnboard,
        AllowSNSEventSubscription: eventSubscriptions,
      };
      let response = await editDatabase(request);
      props.notifyEditSuccess(response.Message);
    } catch (err) {
      props.notifyEditFailure('Exception edit database: ' + err.message);
    }
    props.dismiss();
  };

  const handleRefresh = async () => {
    setDatabaseDescription(props.database?.Description);
    setDatabaseName(props.database?.DatabaseName);
    setAutoTableOnboard(props.database?.AutoTableOnboard);
    setEventSubscriptions(props.database?.AllowSNSEventSubscription);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <Modal
      onDismiss={props.dismiss}
      visible={props.visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={props.dismiss}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleEdit}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      size='medium'
      header='Edit database'
    >
      <ColumnLayout>
        <FormField label='Database name'>
          <Input
            placeholder={'Database name'}
            value={databaseName}
            onChange={(e) => {
              setDatabaseName(e.detail.value);
            }}
            disabled
          />
        </FormField>
        <FormField label='Database description'>
          <Input
            placeholder={'Database description'}
            value={databaseDescription}
            onChange={(e) => {
              setDatabaseDescription(e.detail.value);
            }}
          />
        </FormField>
        <FormField
          label='Auto table onboard'
          description={'Select this to automatically register new datasets from this database into Omni.'}
        >
          <Toggle onChange={(e) => setAutoTableOnboard(e.detail.checked)} checked={autoTableOnboard} />
        </FormField>
        <FormField label='Allow SNS event subscriptions' description={'Allows subscriptions for event notifications.'}>
          <Toggle onChange={(e) => setEventSubscriptions(e.detail.checked)} checked={eventSubscriptions} />
        </FormField>
      </ColumnLayout>
    </Modal>
  );
};
