import React, { useState } from 'react';
import { Box, Button, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { associateTemplateToResources } from 'src/api/permissions';
import { ResourceSelector } from 'src/components/common/resourceSelector/resourceSelector';

export interface AssociateTemplateModalProps {
  templateId: string;
  setNotification: (header: any, message: string) => void;
  close: () => void;
  visible: boolean;
  activeGroup?: string;
  activeWorkspace?: any;
  handleRefresh?: () => void;
}

export const AssociateTemplateModal = (props: AssociateTemplateModalProps) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [resourceId, setResourceId] = useState('');

  const handleAssociateResource = async () => {
    setButtonLoading(true);
    try {
      await associateTemplateToResources({
        templateId: props.templateId,
        resourceId: resourceId,
      });
      props.setNotification('success', `Template is associated with resource : ${resourceId}`);
      props.handleRefresh();
    } catch (err) {
      props.setNotification('error', `Failed to associate with resource ID: ${resourceId}`);
    }
    props.close();
    resetToDefault();
    setResourceId('');
    setButtonLoading(false);
  };

  const clearSelectedOptions = () => {
    setResourceId(null);
  };

  const resetToDefault = () => {
    clearSelectedOptions();
  };

  const enableAssociateButton = () => {
    return resourceId != null;
  };

  return (
    <Modal
      onDismiss={() => {
        resetToDefault();
        props.close();
      }}
      visible={props.visible}
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              variant='link'
              onClick={() => {
                resetToDefault();
                props.close();
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              disabled={!enableAssociateButton()}
              onClick={handleAssociateResource}
              loading={buttonLoading}
            >
              {'Associate'}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header='Associate resource to template'
    >
      <FormField label={'Template ID'}>
        <Input ariaRequired={true} value={props.templateId} readOnly={true} disabled />
      </FormField>
      <ResourceSelector
        {...props}
        setNotification={props.setNotification}
        onSelect={(resourceId) => setResourceId(resourceId)}
      />
    </Modal>
  );
};
