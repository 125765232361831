import { Box, Button, ColumnLayout, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { editSchema } from 'src/api/catalog';
import { REDSHIFT_DATASOURCE_ID } from 'src/commons/constants';

export interface DatabaseEditModalProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  schema: any;
  visible: boolean;
  dismiss: any;
  notifyEditSuccess: any;
  notifyEditFailure: any;
}

export const SchemaEditModal = (props: DatabaseEditModalProps) => {
  const [schemaDescription, setSchemaDescription] = useState(undefined);
  const [schemaName, setSchemaName] = useState(undefined);

  const handleEdit = async () => {
    let request = {
      DataSourceId: REDSHIFT_DATASOURCE_ID,
      CatalogId: props.schema.CatalogId,
      ClusterIdentifier: props.schema.ClusterIdentifier,
      DatabaseName: props.schema.DatabaseName,
      Schema: props.schema.Schema,
      Region: props.schema.Region,
      Owners: props.schema.Owners,
      Description: schemaDescription,
    };
    await editSchema(request);
  };

  const handleRefresh = async () => {
    setSchemaDescription(props.schema?.Description);
    setSchemaName(props.schema?.Schema);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <Modal
      onDismiss={props.dismiss}
      visible={props.visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={props.dismiss}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleEdit}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      size='medium'
      header='Edit schema'
    >
      <ColumnLayout>
        <FormField label='Schema name'>
          <Input
            placeholder={'Schema name'}
            value={schemaName}
            onChange={(e) => {
              setSchemaName(e.detail.value);
            }}
            disabled
          />
        </FormField>
        <FormField label='Schema description'>
          <Input
            placeholder={'Schema description'}
            value={schemaDescription}
            onChange={(e) => {
              setSchemaDescription(e.detail.value);
            }}
          />
        </FormField>
      </ColumnLayout>
    </Modal>
  );
};
