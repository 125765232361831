import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Flashbar,
  FormField,
  Input,
  Textarea,
  Header,
  ColumnLayout,
  Container,
} from '@amzn/awsui-components-react-v3';
import { getNotificationTemplate, updateNotificationTemplate } from 'src/api/notifications';
import { Page } from 'src/routes';
import { Redirect } from 'react-router-dom';
import { scrollUp } from 'src/components/utils/navigation';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface NotificationTemplateUpdateFormProps {
  activeWorkspace: any;
  setContentType: any;
  match: any;
  activeGroup: string;
  username: string;
}

export const NotificationTemplateUpdateForm = (props: NotificationTemplateUpdateFormProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [notifications, setNotifications] = useState([]);

  const [buttonLoading] = useState(false);
  const [content, setContent] = useState(undefined);
  const [isPublic, setIsPublic] = useState(false);
  const [notificationTemplateName, setNotificationTemplateName] = useState(undefined);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    const template = await getNotificationTemplate({
      NotificationTemplateId: props.match.params.NotificationTemplateId,
    });
    setContent(template.NotificationTemplate.Content);
    setIsPublic(template.NotificationTemplate.IsPublic);
    setNotificationTemplateName(template.NotificationTemplate.NotificationTemplateName);
  };

  const handleEdit = async () => {
    await updateNotificationTemplate({
      NotificationTemplateId: props.match.params.NotificationTemplateId,
      NotificationTemplateName: notificationTemplateName,
      Content: content,
      IsPublic: isPublic,
      NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
    });
    setNotifications([
      {
        type: 'success',
        content: `Notification template updated.`,
        dismissible: true,
        action: <Button onClick={() => setRedirect(Page.NOTIFICATION_TEMPLATES)}>View Notification Templates</Button>,
        onDismiss: () => setNotifications([]),
      },
    ]);
    scrollUp();
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header description='You can create a notification template here'>
            <h1 className='awsui-util-d-ib'>Update notification template</h1>
          </Header>
        }
        actions={
          <div>
            <Button variant='link' onClick={() => setRedirect(Page.NOTIFICATION_TEMPLATES)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleEdit} loading={buttonLoading}>
              Submit
            </Button>
          </div>
        }
      >
        <Container className='custom-screenshot-hide' header={<h2>Edit notification template</h2>}>
          <ColumnLayout>
            <>
              <FormField label={<div>Template name</div>} description='The name of the template to be created.'>
                <Input
                  name='redshift-iam-role'
                  onChange={(event) => setNotificationTemplateName(event.detail.value)}
                  ariaRequired={true}
                  value={notificationTemplateName}
                />
              </FormField>

              <FormField label={<div>Content</div>} description='The default content of the notification.'>
                <Textarea
                  name='content'
                  placeholder='content'
                  ariaRequired={true}
                  onChange={(event) => setContent(event.detail.value)}
                  value={content}
                  rows={10}
                />
              </FormField>
            </>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};
