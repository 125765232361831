import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { useState } from 'react';
import { hcMetadataFieldType, hcMetadataStatus, hcMetadataStringFormatType } from 'src/commons/constants';
import { createMetadataField } from 'src/api/catalog';

export interface CreateMetadataFormFieldModalProps {
  formId: string;
  setNotification: (header: any, message: string) => void;
  close: () => void;
  visible: boolean;
  ownerId?: string;
  handleRefresh?: () => void;
}

export const CreateMetadataFieldModal = (props: CreateMetadataFormFieldModalProps) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fieldName, setFieldName] = useState(undefined);
  const [fieldType, setFieldType] = useState(undefined);
  const [fieldStatus, setFieldStatus] = useState(undefined);
  const [fieldDescription, setFieldDescription] = useState(undefined);
  const [stringFormat, setStringFormat] = useState(undefined);
  const [minValue, setMinValue] = useState(undefined);
  const [maxValue, setMaxValue] = useState(undefined);
  const [minLength, setMinLength] = useState(undefined);
  const [maxLength, setMaxLength] = useState(undefined);
  const ownerId = props.ownerId;
  const formId = props.formId;

  const handleCreateMetadataField = async () => {
    setButtonLoading(true);
    let fieldConfig = undefined;
    if (fieldType && fieldType.value == 'String') {
      fieldConfig = {
        StringConfig: {
          StringConfigFormat: stringFormat?.value,
          MinLength: parseInt(minLength),
          MaxLength: parseInt(maxLength),
        },
      };
    }
    if (fieldType && fieldType.value == 'Number') {
      fieldConfig = {
        NumberConfig: {
          Min: parseInt(minValue),
          Max: parseInt(maxValue),
        },
      };
    }
    if (fieldType && fieldType.value == 'Boolean') {
      fieldConfig = {
        BooleanConfig: {},
      };
    }
    if (fieldType && fieldType.value == 'BusinessGlossary') {
      fieldConfig = {
        BusinessGlossaryConfig: {
          MinValue: parseInt(minValue),
          MaxValue: parseInt(maxValue),
        },
      };
    }
    try {
      let createMetadataFieldRequest = {
        Name: fieldName,
        OwnerId: ownerId,
        Description: fieldDescription,
        FieldType: fieldType.value,
        MetadataFormId: formId,
        Status: fieldStatus.value,
        FieldConfig: fieldConfig,
      };
      let createMetadataFieldResult = await createMetadataField(createMetadataFieldRequest);
      if (createMetadataFieldResult.Message == 'Success') {
        props.setNotification('success', 'Successfully created a field');
      } else {
        props.setNotification('Error', `Failed to create field. Reason: ${createMetadataFieldResult.Message}`);
      }
    } catch (err) {
      props.setNotification('error', `Error in creating field. ${err.message}`);
    } finally {
      setButtonLoading(false);
      props.close();
      setFieldName(undefined);
      setFieldDescription(undefined);
      setFieldType(undefined);
      setFieldStatus(undefined);
      setMinValue(undefined);
      setMaxValue(undefined);
      setStringFormat(undefined);
      props.handleRefresh();
    }
  };

  const enableCreateButton = () => {
    return fieldName && fieldDescription && fieldType && fieldStatus;
  };

  return (
    <Modal
      onDismiss={() => {
        props.close();
      }}
      visible={props.visible}
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              variant='link'
              onClick={() => {
                props.close();
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              disabled={!enableCreateButton()}
              onClick={handleCreateMetadataField}
              loading={buttonLoading}
            >
              {'Create'}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header='Create field in metadata form'
    >
      <ColumnLayout>
        <FormField label={'Form ID'}>
          <Input ariaRequired={true} value={props.formId} readOnly={true} disabled />
        </FormField>
        <FormField label={'Field name'} description='Enter the name for the field.'>
          <Input
            name='fieldName'
            placeholder='Enter the name for the field'
            value={fieldName}
            ariaRequired={true}
            onChange={({ detail }) => setFieldName(detail.value)}
          />
        </FormField>
        <FormField label={'Field description'} description='Enter the name for the field.'>
          <Input
            name='fieldDescription'
            placeholder='Enter the description of the field'
            value={fieldDescription}
            ariaRequired={true}
            onChange={({ detail }) => setFieldDescription(detail.value)}
          />
        </FormField>
        <FormField label={<div>Field status</div>} description='Select the status of the field.'>
          <Select
            name='fieldStatus'
            selectedOption={fieldStatus}
            ariaRequired={true}
            placeholder='Choose a type'
            onChange={({ detail }) => setFieldStatus(detail.selectedOption)}
            options={hcMetadataStatus}
            selectedAriaLabel='Selected'
          />
        </FormField>
        <FormField label={<div>Field type</div>} description='Select the type of the field.'>
          <Select
            name='fieldType'
            selectedOption={fieldType}
            ariaRequired={true}
            placeholder='Choose a type'
            onChange={({ detail }) => setFieldType(detail.selectedOption)}
            options={hcMetadataFieldType}
            selectedAriaLabel='Selected'
          />
        </FormField>
        {fieldType && fieldType.value == 'String' && (
          <FormField label={'String format'} description='String format.'>
            <Select
              name='stringFormat'
              placeholder='Enter the String format'
              selectedOption={stringFormat}
              ariaRequired={true}
              onChange={({ detail }) => setStringFormat(detail.selectedOption)}
              options={hcMetadataStringFormatType}
              selectedAriaLabel='Selected'
            />
          </FormField>
        )}
        {fieldType && fieldType.value == 'String' && (
          <FormField label={'Minimum length'} description='Minimum length of a string'>
            <Input
              name='minLength'
              placeholder='0'
              value={minLength}
              type='number'
              onChange={({ detail }) => setMinLength(detail.value)}
              ariaRequired={true}
            />
          </FormField>
        )}
        {fieldType && fieldType.value == 'String' && (
          <FormField label={'Maximum length'} description='Maximum length of a string'>
            <Input
              name='maxLength'
              placeholder='0'
              value={maxLength}
              type='number'
              onChange={({ detail }) => setMaxLength(detail.value)}
              ariaRequired={true}
            />
          </FormField>
        )}
        {fieldType && (fieldType.value == 'Number' || fieldType.value == 'BusinessGlossary') && (
          <FormField label={'Minimum value'} description='Minimum value'>
            <Input
              name='minValue'
              placeholder='0'
              value={minValue}
              type='number'
              onChange={({ detail }) => setMinValue(detail.value)}
              ariaRequired={true}
            />
          </FormField>
        )}
        {fieldType && (fieldType.value == 'Number' || fieldType.value == 'BusinessGlossary') && (
          <FormField label={'Maximum value'} description='Maximum value'>
            <Input
              name='maxValue'
              placeholder='0'
              value={maxValue}
              type='number'
              onChange={({ detail }) => setMaxValue(detail.value)}
              ariaRequired={true}
            />
          </FormField>
        )}
      </ColumnLayout>
    </Modal>
  );
};
