import * as React from 'react';
import { useEffect, useState } from 'react';
import { ColumnLayout, Container, Header } from '@amzn/awsui-components-react-v3';
import {
  CONTACT_PRIMARY,
  CONTACT_PRIMARY_OWNER_FORM_NAME,
  CONTACT_SECONDARY,
  CONTACT_SECONDARY_OWNER_FORM_NAME,
} from 'src/commons/constants';
import { contactInfoMetadata, generateMetaDataMap } from 'src/components/workspaces/common/common';

export interface ContactInfoProps {
  resource: any;
}

export const ContactInfo = (props: ContactInfoProps) => {
  const resource = props.resource;

  const [primaryContactMetadata, setPrimaryContactMetadata] = useState(undefined);
  const [secondaryContactMetadata, setSecondaryContactMetadata] = useState(undefined);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    let metadataFormMap = await generateMetaDataMap(resource);
    if (metadataFormMap.get(CONTACT_PRIMARY_OWNER_FORM_NAME)?.displayFormOn?.includes('Detail')) {
      setPrimaryContactMetadata(metadataFormMap.get(CONTACT_PRIMARY_OWNER_FORM_NAME).values);
    }
    if (metadataFormMap.get(CONTACT_SECONDARY_OWNER_FORM_NAME)?.displayFormOn?.includes('Detail')) {
      setSecondaryContactMetadata(metadataFormMap.get(CONTACT_SECONDARY_OWNER_FORM_NAME).values);
    }
  };

  return (
    <>
      {resource != null && primaryContactMetadata != null && primaryContactMetadata.length > 0 && (
        <Container header={<Header variant='h2'>Data Owner Contact Information</Header>}>
          <ColumnLayout borders='horizontal' columns={1}>
            <ColumnLayout columns={3}>
              {contactInfoMetadata(primaryContactMetadata, CONTACT_PRIMARY, resource)}
            </ColumnLayout>
            {secondaryContactMetadata != null && secondaryContactMetadata.length > 0 && (
              <ColumnLayout columns={3}>
                {contactInfoMetadata(secondaryContactMetadata, CONTACT_SECONDARY, resource)}
              </ColumnLayout>
            )}
          </ColumnLayout>
        </Container>
      )}
    </>
  );
};

export default ContactInfo;
