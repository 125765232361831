import { useState } from 'react';
import { generateIdFromArn } from 'src/components/utils/arnUtil';
import {
  Alert,
  Box,
  Button,
  Form,
  FormField,
  Header,
  Input,
  Link,
  Modal,
  SegmentedControl,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { isValidHybridCatalogIdFormat } from 'src/commons/validationUtils';
import { ResourceSelector } from 'src/components/common/resourceSelector/resourceSelector';
import * as React from 'react';
import { ADVISORIES_WIKI_PAGE_CREATE_SECTION } from 'src/components/dataadvisory/constants';
import { ResourceSearcher } from 'src/components/common/resourceSelector/resourceSearcher';

export interface SelectResourceModalProps {
  onSelect: any;
  visible: boolean;
  close: any;
  activeGroup?: string;
  activeWorkspace?: any;
}
export const ResourceSelectorModal = (props: SelectResourceModalProps) => {
  const SEARCH_METHOD = 'Search';
  const SEARCH_METHOD_LABEL = 'Search';
  const SELECT_METHOD = 'Select';
  const SELECT_METHOD_LABEL = 'Select from resources you own';
  const INPUT_METHOD = 'Input';
  const INPUT_METHOD_LABEL = 'Enter ARN or ID';

  const [searchedResourceId, setSearchedResourceId] = useState(undefined);
  const [selectedResourceId, setSelectedResourceId] = useState(undefined);
  const [inputtedResourceId, setInputtedResourceId] = useState(undefined);

  const [selectedImportMethodId, setSelectedImportMethodId] = useState(SELECT_METHOD);
  const [inputArnOrId, setInputArnOrId] = useState(undefined);

  const IMPORT_METHOD_OPTIONS = [
    { text: SEARCH_METHOD, id: SEARCH_METHOD_LABEL },
    { text: SELECT_METHOD_LABEL, id: SELECT_METHOD },
    { text: INPUT_METHOD_LABEL, id: INPUT_METHOD },
  ];

  const setFromInputField = (arnOrId) => {
    arnOrId = arnOrId.trim();
    setInputArnOrId(arnOrId);
    if (arnOrId.startsWith('arn:')) {
      setInputtedResourceId(generateIdFromArn(arnOrId));
    } else {
      setInputtedResourceId(arnOrId);
    }
  };

  let resourceId;
  if (selectedImportMethodId == SELECT_METHOD) {
    resourceId = selectedResourceId;
  } else if (selectedImportMethodId == INPUT_METHOD) {
    resourceId = inputtedResourceId;
  } else {
    resourceId = searchedResourceId;
  }

  return (
    <Modal
      visible={props.visible}
      header={<Header>Select resource</Header>}
      onDismiss={props.close}
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={props.close}>
              Cancel
            </Button>
            <Button
              variant='primary'
              disabled={resourceId == undefined || !isValidHybridCatalogIdFormat(resourceId)}
              onClick={() => {
                props.onSelect(resourceId);
                props.close();
              }}
            >
              {'Select'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size={'m'}>
        <FormField
          label={'Selection type'}
          description={'You can select from a dropdown of resources you own, or input a resource ARN or ID directly.'}
        >
          <SegmentedControl
            selectedId={selectedImportMethodId}
            options={IMPORT_METHOD_OPTIONS}
            onChange={(e) => setSelectedImportMethodId(e.detail.selectedId)}
          />
        </FormField>
        {selectedImportMethodId == SEARCH_METHOD && (
          <ResourceSearcher
            {...props}
            setNotification={() => {}}
            onSelect={(newResourceId) => {
              setSearchedResourceId(newResourceId);
            }}
            disableGroupAndWorkspaceSelection={true}
            disableTagSelection={true}
          />
        )}
        {selectedImportMethodId == SELECT_METHOD && (
          <ResourceSelector
            {...props}
            setNotification={() => {}}
            onSelect={(newResourceId) => {
              setSelectedResourceId(newResourceId);
            }}
            disableGroupAndWorkspaceSelection={true}
            disableTagSelection={true}
          />
        )}
        {selectedImportMethodId == INPUT_METHOD && (
          <>
            <Alert statusIconAriaLabel={'info'} header={'Getting ARN or ID'}>
              Every data resource (catalog, database, schema, table) has a unique ID and a unique ARN. You can find
              these values from the respective resource details page on Omni.
              <ul>
                <li>
                  Example ARN: <b>arn:aws:dgs-hc:us-east-1:111111111111:glueLF/database/db_name/table/tbl_name</b>
                </li>
                <li>
                  Example ID: <b>DS-glueLF|A-111111111111|DN-db_name|TN-tbl_name|R-us-east-1</b>
                </li>
              </ul>
              Click <Link href={ADVISORIES_WIKI_PAGE_CREATE_SECTION}>here</Link> for more info.
            </Alert>
            <Form>
              <FormField
                label={'Resource ARN or ID'}
                description={'You can find this value from the details page for any dataset, database, or catalog.'}
              >
                <Input
                  value={inputArnOrId}
                  onChange={(e) => setFromInputField(e.detail.value)}
                  placeholder={'DS-glueLF|A-123412341234|DN-databasename|TN-tablename|R-us-east-1'}
                />
              </FormField>
            </Form>
          </>
        )}
      </SpaceBetween>
    </Modal>
  );
};
