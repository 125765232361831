import * as React from 'react';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  Table,
  Flashbar,
  FlashbarProps,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { largePageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  getDataSourceTables,
  registerDataSets as catalogRegisterDataSets,
  syncDataSets,
} from '../../../src/api/catalog';
import { Link } from 'react-router-dom';
import { compareBy } from '../../../src/components/utils/sorting';
import { PageHeader } from '../../../src/components/subscriptions/common';
import { getDataAccessConfig } from '../../../src/api/config';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { createGlueCatalogDetailLink, createGlueDatabaseDetailLink } from 'src/routes';
import { LAKE_FORMATION_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface RegisterTableProps {
  setContentType: any;
  onNotificationChange?: (notifications: FlashbarProps.MessageDefinition[]) => void;
  idFilter?: string[];
  databaseDetail: object;
  extraFeatures: boolean;
  onlyShowDatasetName: boolean;
  title: string;
  loading?: boolean; // This is unused.
  activeGroup?: string;
}

const RegisterTable = (props: RegisterTableProps) => {
  const [dataSourceTables, setDataSourceTables] = useState([]);
  const [registeredItems, setItems] = useState(['cloudtrailtest_cloudtrail']);

  const [redirect] = useState(undefined);
  const [dataSetsFromDataSourceLoading, setDataSetsFromDataSourceLoading] = useState(true);
  const [registerDataSetsLoading, setRegisterDataSetsLoading] = useState(false);
  const [dataAccessDetails, setDataAccessDetails] = useState({});
  const [notifications, setNotifications] = useState([]);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const [columnDefinitions, setColumnDefinitions] = useState<TableProps.ColumnDefinition<any>[]>([
    {
      id: 'catalogId',
      header: 'Catalog ID',
      cell: () => (
        <Link to={createGlueCatalogDetailLink(props.databaseDetail['CatalogId'], props.databaseDetail['Region'])}>
          {props.databaseDetail['CatalogId']}
        </Link>
      ),
      minWidth: 100,
      sortingComparator: compareBy('catalogId', 'databaseName', 'tableName'),
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: () => (
        <Link
          to={createGlueDatabaseDetailLink(
            props.databaseDetail['CatalogId'],
            props.databaseDetail['DatabaseName'],
            props.databaseDetail['Region'],
          )}
        >
          {props.databaseDetail['DatabaseName']}
        </Link>
      ),
      minWidth: 200,
      sortingComparator: compareBy('databaseName', 'tableName'),
    },
    {
      id: 'tableName',
      header: 'Table name',
      cell: (item) => item.Name,
      minWidth: 200,
      sortingField: 'Name,',
    },
  ]);

  useEffect(() => {
    if (props.onlyShowDatasetName) {
      setColumnDefinitions([
        {
          id: 'dataSetName',
          header: 'Table name',
          cell: (item) => item.Name,
          minWidth: 200,
        },
      ]);
    }
    setDataAccessDetails(getDataAccessConfig());

    props.setContentType(TABLE_CONTENT_TYPE);
    fetchAllData();
  }, []);

  useEffect(() => {
    fetchAllData();
  }, [props.idFilter, props.databaseDetail]);

  // Fetch datasets to register and tables names from registered tables.
  const fetchAllData = async () => {
    getTableNamesFromIdFilter();
    await fetchDataSetsFromDataSource();
  };

  // Gets all the Tables from the Data Source
  const fetchDataSetsFromDataSource = async () => {
    setDataSetsFromDataSourceLoading(true);
    let getDataSourceTablesRequest = {
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
      CatalogId: props.databaseDetail['CatalogId'],
      DatabaseName: props.databaseDetail['DatabaseName'],
      Region: props.databaseDetail['Region'],
      NextToken: null,
    };
    try {
      let tableList = [];
      let getDataSourceTablesResponse = await getDataSourceTables(getDataSourceTablesRequest);
      tableList = getDataSourceTablesResponse.TableList;
      while (getDataSourceTablesResponse.NextToken != null) {
        getDataSourceTablesRequest.NextToken = getDataSourceTablesResponse.NextToken;
        getDataSourceTablesResponse = await getDataSourceTables(getDataSourceTablesRequest);
        tableList.push(...getDataSourceTablesResponse.TableList);
      }
      setDataSourceTables(tableList);
    } catch (e) {
      console.log('Error getting tables from: ', props.databaseDetail['DatabaseName'], e.message);
    }

    setDataSetsFromDataSourceLoading(false);
  };

  // Reuse the Id filter and get registered table names.
  const getTableNamesFromIdFilter = () => {
    if (props.idFilter) {
      setItems(props.idFilter.map((value) => value.split('|')[3].replace(/TN-/i, '')));
    }
  };

  const registerDataSets = async () => {
    setRegisterDataSetsLoading(true);
    const dataSetList = createDataSetList();
    const registerRequest = {
      CreatedBy: props.activeGroup,
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
      CatalogId: props.databaseDetail['CatalogId'],
      Region: props.databaseDetail['Region'],
      DataAccessRole: dataAccessDetails['dataAccessRoleArn'],
      DataSetList: dataSetList,
    };

    // if register step fails, do not move on to sync step
    let registerResponse;
    try {
      registerResponse = await catalogRegisterDataSets(registerRequest);
    } catch (err) {
      props.onNotificationChange([
        {
          type: 'error' as FlashbarProps.Type,
          content: `Failed to register datasets.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      return;
    }

    // now sync datasets' metadata with glue (or other data sources in the future)
    const idList = registerResponse.DataSetList.map((e) => e.Id);
    const syncRequest = {
      IdList: idList,
    };

    try {
      await syncDataSets(syncRequest);
    } catch (err) {
      props.onNotificationChange([
        {
          type: 'error' as FlashbarProps.Type,
          content: `Failed to sync datasets with source.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      return;
    }

    props.onNotificationChange([
      {
        type: 'success' as FlashbarProps.Type,
        content: `Successfully registered and synced.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);

    setRegisterDataSetsLoading(false);
  };

  const createDataSetList = () => {
    const newItems = collectionProps.selectedItems.map((item) => ({
      DatabaseName: props.databaseDetail['DatabaseName'],
      TableName: item['Name'],
      DataSetName: item['Name'],
      PrimaryOwner: props.activeGroup,
      TableOwners: [props.activeGroup],
      DataClassification: 'Public',
      TableState: 'Active',
    }));

    return newItems;
  };

  const { items, collectionProps, paginationProps } = useCollection(dataSourceTables, {
    filtering: {},
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Flashbar items={notifications} />
      <Table
        {...collectionProps}
        selectionType='multi'
        isItemDisabled={(item) => registeredItems.includes(item['Name'])}
        loadingText='Loading datasets...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={
                props.extraFeatures
                  ? [
                      {
                        text: 'Register',
                        variant: 'normal',
                        disabled: collectionProps.selectedItems.length === 0,
                        loading: registerDataSetsLoading,
                        onItemClick: (_) => {
                          registerDataSets();
                        },
                      },
                    ]
                  : []
              }
              header={
                <>
                  {props.title}
                  <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                </>
              }
            />
          </>
        }
        empty={
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No datasets</b>
            </div>
            <p className='awsui-util-mb-s'>No datasets were found for this database.</p>
          </div>
        }
        loading={dataSetsFromDataSourceLoading}
        pagination={props.extraFeatures && <Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          props.extraFeatures && (
            <CollectionPreferences
              title={'Preferences'}
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={largePageSizePreference}
              wrapLinesPreference={defaultWrapLinesPreference}
            />
          )
        }
      />
    </>
  );
};

export default RegisterTable;
