import * as React from 'react';
import { useState, useEffect } from 'react';

import { mediumPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  TableProps,
  Spinner,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { listTemplates } from 'src/api/resourcesmanager';

import { Link } from 'react-router-dom';
import { RMPageHeader } from '../components';
import { Page } from 'src/routes';
import { UnauthorizedModal } from '../components-V3';
import { EmptyState } from 'src/commons/EmptyState';

export interface MyTemplatesProps {
  activeGroup: string;
  redirect: any;
  userInfo: any;
}

export const MyTemplates = (props: MyTemplatesProps) => {
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [redirectParams, setRedirectParams] = useState({});
  const [templates, setTemplates] = useState([]);
  const [redirect, setRedirect] = useState(undefined);
  const [tableMessage, setTableMessage] = useState('No templates');
  const [onboardModal, setOnboardModal] = useState<boolean>(false);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const ctColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'templateName',
      header: 'Name',
      cell: (item) => <Link to={Page.TEMPLATE_DETAILS.replace(':id', item.id)}>{item.name}</Link>,
      minWidth: 200,
      sortingField: 'name',
    },
    {
      id: 'configTemplatDescription',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: 300,
    },
  ];

  useEffect(() => {
    handleRefresh();
  }, []);

  const listCTs = async () =>
    listTemplates({
      groupId: props.activeGroup,
    });

  const fetchCTs = async () => {
    const { templates } = await listCTs();
    setTemplates(templates);
    setLoadingTemplates(false);
  };

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    setLoadingTemplates(true);
    try {
      fetchCTs();
    } catch (err) {
      if (err.name == 'InsufficientPermissionException') {
        setOnboardModal(true);
      }
      setTableMessage(`Unable to load templates. ${err.message}`);
    }
    setLoadingTemplates(false);
  };

  const handleAction = async (e) => {
    switch (e.detail.id) {
      case 'details':
        setRedirect(Page.TEMPLATE_DETAILS.replace(':id', collectionProps.selectedItems[0].id));
        break;

      case 'create':
        setRedirect(Page.CREATE_RESOURCEGROUP);
        setRedirectParams({
          values: JSON.parse(
            templates.filter((t) => t.id == collectionProps.selectedItems[0].id)[0].templateJsonString,
          ),
        });
        break;

      case 'edit':
        setRedirect(Page.UPDATE_RG_TEMPLATE.replace(':id', collectionProps.selectedItems[0].id));
        break;
    }
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(templates, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No templates to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'name',
        },
      },
    },
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  if (redirect) props.redirect(redirect, redirectParams);

  return (
    <>
      <UnauthorizedModal visible={onboardModal} onClose={() => setOnboardModal(false)} />

      <Table
        {...collectionProps}
        loadingText='Loading templates...'
        loading={loadingTemplates}
        columnDefinitions={ctColumnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        empty={<EmptyState title={'No templates'} subtitle={'No templates to display'} />}
        header={
          <>
            <RMPageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: fetchCTs,
                },
                {
                  text: 'Actions',
                  onItemClick: handleAction,
                  items: [
                    {
                      text: 'Quick create',
                      id: 'create',
                      disabled: !collectionProps.selectedItems.length,
                    },
                    {
                      text: 'View details',
                      id: 'details',
                      disabled: !collectionProps.selectedItems.length,
                    },
                    {
                      text: 'Edit',
                      id: 'edit',
                      disabled: !collectionProps.selectedItems.length,
                    },
                  ],
                  loading: loadingTemplates,
                },
                {
                  //Using 'New Template' to differentiate from 'Quick Create' (Resource Group)
                  text: 'New template',
                  onItemClick: () => {
                    setRedirect(Page.CREATE_TEMPLATE);
                  },
                },
              ]}
              subheader={
                <>
                  Templates{' '}
                  <span className='awsui-util-header-counter'>({loadingTemplates ? <Spinner /> : items.length})</span>
                </>
              }
            />
          </>
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter templates'
            filteringPlaceholder='Find templates'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        selectionType={'single'}
      />
    </>
  );
};
