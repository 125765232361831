import { GetDataSetResponse } from 'aws-sdk/clients/tethyscontractservicelambda';
import { PropertiesPresent, RecursivePartial } from '../utils/types';
import { doPropertiesMatch } from '../utils/validation';

interface DataSetManifestV1 {
  version: 1;
  source_site: string;
  contract: RecursivePartial<GetDataSetResponse>;
}

interface DataSetManifestV2 {
  version: 2;
  source_site: string;
  // Fill in once a second version is created
  contents: unknown;
}

export type DataSetManifest = DataSetManifestV1 | DataSetManifestV2;

/**
 * Checks whether an arbitrary object is a valid DataSet Manifest of any version.
 * Returns a `DataSetManifest` if valid, undefined otherwise.
 */
export function deserializeVersionedDataSetManifest(content: string): DataSetManifest | undefined {
  try {
    const data = JSON.parse(content);
    if (typeof data === 'object') {
      if (!data.version) return undefined;
      // validate each version separately
      if (data.version === 1) {
        if (validateManifestV1(data)) {
          return data as DataSetManifestV1;
        }
      }
    }
  } catch (e) {
    console.warn('Failed to Deserialize Dataset Manifest', e);
  }
  return undefined;
}

export function validateManifestV1(manifest: Record<string, any>): boolean {
  const reference: PropertiesPresent<DataSetManifestV1> = {
    version: 1,
    source_site: '',
    contract: {
      AcqDsStatus: '',
      CatalogId: '',
      CreatedBy: '',
      DatabaseName: '',
      DataCompletenessDate: '',
      DataContract: {
        DataFormat: '',
        DataProperties: {
          CompressionFormat: '',
          DeDupKeyColumn: '',
          DeDupKeyColumns: [
            {
              ColumnName: '',
              SortOrder: '',
            },
          ],
          PrimaryKeyColumns: [''],
          SchemaDefinition: '',
          SchemaFormat: '',
          TargetSchema: [
            {
              DynamoDBSourceType: '',
              Name: '',
              Precision: 0,
              Scale: 0,
              SourceName: '',
              Type: '',
            },
          ],
        },
        FileProperties: {
          FieldDelimiter: '',
          FileType: '',
          HeaderLines: 0,
        },
        ServiceLevelAgreement: {
          ConfidenceFileEnabled: false,
          IAMRole: '',
          PublishType: '',
          RefreshCadence: '',
          Schedule: '',
        },
        Version: 0,
      },
      DataSetId: '',
      DataSource: {
        DynamoDBSourceProperties: {
          DynamoTableArn: '',
          KinesisStreamArn: '',
        },
        S3SourceProperties: {
          Bucket: '',
          Prefix: '',
          Wildcard: '',
        },
        SourceType: '',
      },
      HCDataSetId: '',
      LastIngestedAt: '',
      LastUpdatedAt: '',
      LatestVersion: '',
      PrimaryOwner: '',
      PublishEndpoint: '',
      TableName: '',
      ResourceGroupName: '',
    },
    // Remove the type cast to validate types, keep to maintain backwards-compatibility for builds
  } as PropertiesPresent<DataSetManifestV1>;

  if (!manifest.source_site || !manifest.contract) return false;
  if (
    typeof manifest.source_site !== 'string' ||
    typeof manifest.contract !== 'object' ||
    Array.isArray(manifest.contract)
  ) {
    return false;
  }

  // We only need to care whether the property is the correct type *if* it exists. Missing properties
  // will be identified at runtime by field value validation.
  return doPropertiesMatch<DataSetManifestV1['contract']>(manifest.contract, reference.contract);
}

export function getManifestDatasetName(manifest: DataSetManifest): string {
  if (manifest.version === 1) {
    return manifest.contract.TableName ?? 'unknown';
  }
  return 'Unsupported Manifest';
}
