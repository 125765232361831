import { Box, Button, ColumnLayout, FormField, Modal, Select, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { associateTagToResources, getTags } from 'src/api/permissions';
import { Link } from 'react-router-dom';
import { Page } from 'src/routes';

export interface TagEditModalProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  visible: boolean;
  dismiss: any;
  resource: any;
  refresh: any;
  updateTagLabel: any;
  currentTags: any[];
  tagType: string;
  notifyEditFailure: any;
  notifyEditSuccess: any;
}

export const TagEditModal = (props: TagEditModalProps) => {
  const [selectedTag, setSelectedTag] = useState(undefined);
  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    let optionList = [];
    try {
      const tagsResponse = await getTags({
        ownerId: props.activeWorkspace.workspaceId,
      });
      const tags = tagsResponse.tags;
      const publisherTags = tags.filter((tag) => {
        return (
          tag.statusCustomerType.includes('Publisher') &&
          tag.resourceId === 'ACCOUNT_TAG' &&
          tag.tagType == props.tagType
        );
      });
      if (publisherTags.length > 0) {
        let attachedTagKeySet = await fetchAttachedTagKeys();
        for (let tag of publisherTags) {
          optionList.push({
            label: tag.tagKey + '.' + tag.tagValue,
            value: tag.tagKey + '.' + tag.tagValue + '.' + tag.catalogId,
            disabled: attachedTagKeySet.has(tag.tagKey),
          });
        }
      }
    } catch (err) {
      console.log('Exception when fetching tags ', err);
    }
    setTagOptions(optionList);
  };

  const handleConfirm = async () => {
    try {
      let request = {
        tagPair: selectedTag.label.split('.')[0] + ':' + selectedTag.label.split('.')[1],
        catalogId: props.activeWorkspace.accountId,
        region: props.activeWorkspace.region,
        type: props.tagType,
        resources: [props.resource],
      };
      let response = await associateTagToResources(request);
      if (response.succeeded) {
        props.notifyEditSuccess(response.message);
      } else {
        props.notifyEditFailure(response.message);
      }
    } catch (err) {
      props.notifyEditFailure('Fail to attach tag to resource');
      console.log(err);
    }
    props.refresh();
    props.updateTagLabel();
    props.dismiss();
  };

  let createTagsLink = <Link to={Page.CREATE_TAGS_TEMPLATE}>Create new tags in your workspace.</Link>;

  const fetchAttachedTagKeys = async () => {
    let attachedTagKeySet = new Set();
    for (let tag of props.currentTags) {
      attachedTagKeySet.add(tag.tagKey);
    }
    return attachedTagKeySet;
  };

  return (
    <Modal
      onDismiss={props.dismiss}
      visible={props.visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={props.dismiss}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleConfirm}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      size='medium'
      header='Attach tag'
    >
      <ColumnLayout>
        <FormField label={createTagsLink} />
        <FormField label='Select tag' description='Set tag to attach'>
          <Select
            selectedOption={selectedTag}
            onChange={({ detail }) => setSelectedTag(detail.selectedOption)}
            options={tagOptions}
            selectedAriaLabel='Selected'
            empty={'No available tags'}
          />
        </FormField>
      </ColumnLayout>
    </Modal>
  );
};
