import * as React from 'react';
import { useState, useEffect } from 'react';

import { notificationsToFlashbar } from 'src/components/dataadvisory/constants';
import {
  AdvisoryChangeMode,
  CreateOrUpdateAdvisoryWizard,
} from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/createOrUpdateAdvisoryWizard';
import { Alert, SpaceBetween, Spinner } from '@amzn/awsui-components-react-v3';
import { getAdvisory, updateAdvisory } from 'src/api/notifications';
import { Advisory, UpdateAdvisoryRequest } from 'aws-sdk/clients/awsdlomni';
import { createAdvisoryDetailsLink } from 'src/routes';

export interface UpdateAdvisoryPageProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  activeWorkspace?: any;
  userInfo?: any;
}

export const UpdateAdvisoryPage = (props: UpdateAdvisoryPageProps) => {
  const [advisory, setAdvisory] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);

  useEffect(() => {
    props.setContentType('wizard');
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const response = await getAdvisory({
        advisoryId: advisoryId(),
      });
      const currentAdvisory = response.advisory;
      setAdvisory(currentAdvisory);
    } catch (e) {
      setFailedToLoad(true);
    }
    setLoading(false);
  };

  const advisoryId = () => {
    return props.match.params.id;
  };

  const handleUpdateAdvisory = async (advisory: Advisory) => {
    setUpdating(true);
    try {
      const request: UpdateAdvisoryRequest = {
        advisoryId: advisoryId(),
        title: advisory.title,
        content: advisory.content,
        impactedResources: advisory.impactedResources,
        attachedTickets: advisory.attachedTickets,
        customers: advisory.customers,
        contactInfo: advisory.contactInfo,
        lifecycleConfiguration: advisory.lifecycleConfiguration,
      };
      await updateAdvisory(request);
      setNotificationForSuccessfulUpdate();
    } catch (e) {
      setNotificationForFailedUpdate(e);
    }
    setUpdating(false);
  };

  const setNotificationForSuccessfulUpdate = () => {
    setNotifications([
      {
        type: 'success',
        content: 'Successfully updated the advisory.',
      },
    ]);
  };

  const setNotificationForFailedUpdate = (e) => {
    setNotifications([
      {
        type: 'error',
        content: 'Failed to update the advisory:' + e.message,
      },
    ]);
  };

  if (loading) {
    return <Spinner />;
  }
  if (failedToLoad) {
    return (
      <Alert type={'error'} statusIconAriaLabel={'Error'} header={'Unable to load advisory'}>
        Please double-check the advisory ID in the URL and try again.
      </Alert>
    );
  }

  return (
    <SpaceBetween size={'m'}>
      {notifications.length > 0 && notificationsToFlashbar(notifications, setNotifications)}
      <CreateOrUpdateAdvisoryWizard
        {...props}
        mode={AdvisoryChangeMode.UPDATE_ADVISORY_MODE}
        onSubmit={handleUpdateAdvisory}
        advisory={advisory}
        redirectTo={createAdvisoryDetailsLink(advisoryId())}
        setNotifications={setNotifications}
        loading={updating}
        activeGroup={props.activeGroup}
        activeWorkspace={props.activeWorkspace}
      />
    </SpaceBetween>
  );
};
