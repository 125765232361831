import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Multiselect,
  SelectProps,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { createMetadataValue, editMetadataValue, getGlossary, listGlossaries } from 'src/api/catalog';
import { GlossaryDetails, GlossaryDetailsList } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';

export interface MetadataFieldValueModalProps {
  formId: string;
  resource: any;
  setNotification: (header: any, message: string) => void;
  close: () => void;
  visible: boolean;
  ownerId?: string;
  formOwnerId?: string;
  metadataFormField: any;
  handleRefresh: any;
  modalType: string;
}

export const MetadataFieldValueModal = (props: MetadataFieldValueModalProps) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [, setLoadingGlossaries] = useState(false);
  const [currentStringValue, setCurrentStringValue] = useState(undefined);
  const [currentNumberValue, setCurrentNumberValue] = useState(undefined);
  const [currentBooleanValue, setCurrentBooleanValue] = useState(undefined);
  const [businessGlossarySelectedOptions, setBusinessGlossarySelectedOptions] = useState([]);
  const [businessGlossaryAllOptions, setBusinessGlossaryAllOptions] = useState([]);
  const formId = props.formId;
  const formOwnerId = props.formOwnerId;
  const field = props.metadataFormField;
  const resource = props.resource;

  useEffect(() => {
    if (field) {
      getGlossariesForOwner();
      setCurrentStringValue(field.Value);
      setCurrentBooleanValue(field.Value == 'true');
      setCurrentNumberValue(field.Value);
      getCurrentSelectedGlossaries();
    }
  }, [field]);

  const getCurrentSelectedGlossaries = async () => {
    if (field?.GlossaryValues) {
      let glossaryNamesAndIds = [];
      for (let glossaryValue of field.GlossaryValues) {
        const getGlossaryResponse = await getGlossary({
          GlossaryId: glossaryValue.GlossaryId,
          OwnerId: glossaryValue.OwnerId,
        });
        glossaryNamesAndIds.push({
          label: getGlossaryResponse.GlossaryDetails?.Name,
          value: getGlossaryResponse.GlossaryDetails?.GlossaryId,
          ownerId: getGlossaryResponse.GlossaryDetails?.OwnerId,
        });
      }
      setBusinessGlossarySelectedOptions(glossaryNamesAndIds);
    }
  };

  const inputIsInvalid = () => {
    if (field?.FieldType == 'Field#String') {
      const length = currentStringValue?.length;
      if (length < field.FieldConfig.StringConfig.MinLength || length > field.FieldConfig.StringConfig.MaxLength) {
        return true;
      }
    }
    if (field?.FieldType == 'Field#Number') {
      const value = Number(currentNumberValue);
      if (value < field.FieldConfig.NumberConfig.Min || value > field.FieldConfig.NumberConfig.Max) {
        return true;
      }
    }
    if (field?.FieldType == 'Field#BusinessGlossary') {
      const values = businessGlossarySelectedOptions.length;
      if (
        values < field.FieldConfig.BusinessGlossaryConfig.MinValue ||
        values > field.FieldConfig.BusinessGlossaryConfig.MaxValue
      ) {
        return true;
      }
    }
    return false;
  };

  const convertGlossaryInfosToOptions = (glossaryDetails: GlossaryDetailsList): SelectProps.Option[] => {
    return glossaryDetails?.map((glossaryDetail: GlossaryDetails) => {
      return {
        label: `${glossaryDetail?.Name} (${glossaryDetail?.GlossaryValue})`,
        value: glossaryDetail?.GlossaryId,
        ownerId: glossaryDetail?.OwnerId,
      };
    });
  };

  const generateValue = () => {
    if (field.FieldType == 'Field#String') {
      return {
        StringValue: currentStringValue,
      };
    } else if (field.FieldType == 'Field#Number') {
      return {
        NumberValue: currentNumberValue,
      };
    } else if (field.FieldType == 'Field#BusinessGlossary') {
      return {
        GlossaryValueList: businessGlossarySelectedOptions.map((option) => {
          return {
            GlossaryId: option.value,
            OwnerId: option.ownerId,
          };
        }),
      };
    } else if (field.FieldType == 'Field#Boolean') {
      return {
        BooleanValue: currentBooleanValue,
      };
    }
  };

  const handleCreateMetadataValue = async () => {
    setButtonLoading(true);
    try {
      let createMetadataValueRequest = {
        ResourceArn: resource,
        MetadataFormId: formId,
        OwnerId: formOwnerId,
        MetadataValuesList: [
          {
            Id: field.MetadataFieldId,
            Type: field.FieldType.replace('Field#', ''),
            Value: generateValue(),
          },
        ],
      };
      let createMetadataValueResult = await createMetadataValue(createMetadataValueRequest);
      if (createMetadataValueResult.Message == 'Success') {
        props.setNotification('success', 'Successfully created a value for a field');
      } else {
        props.setNotification(
          'error',
          `Failed to create value for a field. Reason: ${createMetadataValueResult.Message}`,
        );
      }
    } catch (err) {
      props.setNotification('error', `Error in creating value for a field. ${err.message}`);
    } finally {
      setButtonLoading(false);
      props.close();
      props.handleRefresh();
    }
  };

  const handleEditMetadataValue = async () => {
    setButtonLoading(true);
    try {
      let editMetadataValueRequest = {
        ResourceArn: resource,
        MetadataFormId: formId,
        OwnerId: formOwnerId,
        NewMetadataValuesList: [
          {
            Id: field.MetadataFieldId,
            Type: field.FieldType.replace('Field#', ''),
            Value: generateValue(),
          },
        ],
      };
      let editMetadataValueResult = await editMetadataValue(editMetadataValueRequest);
      if (editMetadataValueResult.Message == 'Success') {
        props.setNotification('success', 'Successfully edited a value for a field');
      } else {
        props.setNotification('Error', `Failed to edit value for a field. Reason: ${editMetadataValueResult.Message}`);
      }
    } catch (err) {
      props.setNotification('error', `Error in editing value for a field. ${err.message}`);
    } finally {
      setButtonLoading(false);
      props.close();
      props.handleRefresh();
    }
  };

  const getGlossariesForOwner = async () => {
    setLoadingGlossaries(true);
    try {
      let listGlossariesResult = await listGlossaries({
        OwnerId: formOwnerId,
      });
      let glossaries = listGlossariesResult.GlossaryDetailsList;
      while (listGlossariesResult.NextToken != undefined) {
        listGlossariesResult = await listGlossaries({
          OwnerId: formOwnerId,
          NextToken: listGlossariesResult.NextToken,
        });
        glossaries.push(...listGlossariesResult.GlossaryDetailsList);
      }
      let optionsReturned = convertGlossaryInfosToOptions(glossaries);
      setBusinessGlossaryAllOptions(optionsReturned);
    } catch (err) {
      props.setNotification('error', `Unable to load glossaries. ${err.message}`);
    } finally {
      setLoadingGlossaries(false);
    }
  };

  const generateInvalidMessage = () => {
    if (field?.FieldType == 'Field#String') {
      return `String length should be at least ${field.FieldConfig.StringConfig.MinLength} and at most ${field.FieldConfig.StringConfig.MaxLength}.`;
    }
    if (field?.FieldType == 'Field#Number') {
      return `Number value should be at least ${field.FieldConfig.NumberConfig.Min} and at most ${field.FieldConfig.NumberConfig.Max}.`;
    }
    if (field?.FieldType == 'Field#BusinessGlossary') {
      return `You can select between ${field.FieldConfig.BusinessGlossaryConfig.MinValue} and ${field.FieldConfig.BusinessGlossaryConfig.MaxValue} glossaries.`;
    }
  };

  return (
    <Modal
      onDismiss={() => {
        props.close();
      }}
      visible={props.visible}
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              variant='link'
              onClick={() => {
                props.close();
              }}
            >
              Cancel
            </Button>
            {props.modalType == 'Edit' && (
              <Button
                variant='primary'
                disabled={inputIsInvalid()}
                onClick={handleEditMetadataValue}
                loading={buttonLoading}
              >
                {'Save changes'}
              </Button>
            )}
            {props.modalType == 'Create' && (
              <Button
                variant='primary'
                disabled={inputIsInvalid()}
                onClick={handleCreateMetadataValue}
                loading={buttonLoading}
              >
                {'Create'}
              </Button>
            )}
          </SpaceBetween>
        </Box>
      }
      header={props.modalType == 'Edit' ? 'Edit value for a metadata field' : 'Create a value for a metadata field'}
    >
      <ColumnLayout>
        {field && (
          <FormField label={'Field Name'}>
            <Input ariaRequired={true} value={field.Name} readOnly={true} disabled />
          </FormField>
        )}
        {field && field.FieldType == 'Field#String' && (
          <FormField
            label={'String value'}
            description='Enter the value for this field.'
            errorText={inputIsInvalid() ? generateInvalidMessage() : ''}
          >
            <Input
              name='fieldName'
              placeholder='Enter the value for the field'
              value={currentStringValue}
              ariaRequired={true}
              invalid={inputIsInvalid()}
              onChange={({ detail }) => setCurrentStringValue(detail.value)}
            />
          </FormField>
        )}
        {field && field.FieldType == 'Field#Number' && (
          <FormField
            label={'Number value'}
            description='Enter the value for this field.'
            errorText={inputIsInvalid() ? generateInvalidMessage() : ''}
          >
            <Input
              name='fieldName'
              placeholder='Enter the value for the field'
              value={currentNumberValue}
              type='number'
              ariaRequired={true}
              invalid={inputIsInvalid()}
              onChange={({ detail }) => setCurrentNumberValue(detail.value)}
            />
          </FormField>
        )}
        {field && field.FieldType == 'Field#Boolean' && (
          <FormField label={'Boolean value'} description='Enter the value for this field.'>
            <Toggle onChange={({ detail }) => setCurrentBooleanValue(detail.checked)} checked={currentBooleanValue}>
              Toggle
            </Toggle>
          </FormField>
        )}
        {field && field.FieldType == 'Field#BusinessGlossary' && (
          <FormField
            label={'Glossary'}
            description='Enter the value for this field.'
            errorText={inputIsInvalid() ? generateInvalidMessage() : ''}
          >
            <Multiselect
              name='glossary'
              selectedOptions={businessGlossarySelectedOptions}
              ariaRequired={true}
              placeholder='Choose a type'
              onChange={({ detail }) => setBusinessGlossarySelectedOptions(detail.selectedOptions as any[])}
              options={businessGlossaryAllOptions}
              invalid={inputIsInvalid()}
              selectedAriaLabel='Selected'
            />
          </FormField>
        )}
      </ColumnLayout>
    </Modal>
  );
};

export default MetadataFieldValueModal;
