import { Box, Button, FileUpload, Form, Modal, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { DataSetManifest, deserializeVersionedDataSetManifest } from './manifest';

export interface ImportManifestModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (manifest: DataSetManifest) => void;
}

export function ImportManifestModal(props: ImportManifestModalProps): JSX.Element {
  const { visible, onClose, onSubmit } = props;
  const [file, setFile] = useState<File | undefined>(undefined);
  // undefined for not-set, null for invalid
  const [parsedFile, setParsedFile] = useState<DataSetManifest | undefined | null>(undefined);
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    if (file) {
      setFileLoading(true);
      file.text().then((text) => {
        setParsedFile(deserializeVersionedDataSetManifest(text) ?? null);
        setFileLoading(false);
      });
    }
  }, [file]);

  const footer = (
    <Box float='right'>
      <SpaceBetween direction='horizontal' size='xs'>
        <Button variant='link' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='primary'
          disabled={parsedFile === null}
          loading={fileLoading}
          loadingText='validating selected manifest file'
          onClick={() => {
            if (parsedFile) {
              onSubmit(parsedFile);
            }
          }}
        >
          Populate Form
        </Button>
      </SpaceBetween>
    </Box>
  );

  return (
    <Modal visible={visible} onDismiss={onClose} footer={footer} header='Import Dataset Manifest'>
      <Form>
        <FileUpload
          value={file ? [file] : []}
          tokenLimit={1}
          constraintText='Dataset Manifest exported from Tethys'
          onChange={(e) => {
            setFile(e.detail.value[0]);
          }}
          showFileLastModified
          i18nStrings={{
            uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
            dropzoneText: (e) => (e ? 'Drop files to upload' : 'Drop file to upload'),
            removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
            limitShowFewer: 'Show fewer files',
            limitShowMore: 'Show more files',
            errorIconAriaLabel: 'Error',
          }}
          fileErrors={parsedFile === null ? ['File does not match any expected manifest format'] : []}
        />
      </Form>
    </Modal>
  );
}
