import * as React from 'react';
import { useEffect, useState } from 'react';
import { ColumnLayout, FormField, Icon } from '@amzn/awsui-components-react-v3';

import { getWorkspace } from 'src/api/permissions';
import { PageHeader } from 'src/components/notifications/common';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface WorkspaceDetailPageProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
}

export const WorkspaceDetailPage = (props: WorkspaceDetailPageProps) => {
  const [workspace, setWorkspace] = useState(undefined);
  const [catalogManagerEnabled, setCatalogManagerEnabled] = useState(undefined);
  const [redshiftManagerEnabled, setRedshiftManagerEnabled] = useState(undefined);
  const [analyticsManagerEnabled, setAnalyticsManagerEnabled] = useState(undefined);
  const [refreshButtonLoading, setRefreshButtonLoading] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleRefresh = async () => {
    setLoading(true);
    setRefreshButtonLoading(true);
    try {
      let getWorkspaceResponse = await getWorkspace({
        workspaceId: props.match.params.workspaceId,
      });

      setWorkspace(getWorkspaceResponse.workspace);
      let supportedServices = getWorkspaceResponse.workspace.supportedServices;

      for (let supportedService of supportedServices) {
        if (supportedService == 'FoundryAnalyticsManager') {
          setAnalyticsManagerEnabled(true);
        } else if (supportedService == 'FoundryCatalogManager') {
          setCatalogManagerEnabled(true);
        } else if (supportedService == 'FoundryRedshiftManager') {
          setRedshiftManagerEnabled(true);
        }
      }
      setRefreshButtonLoading(false);
    } catch (err) {
      console.log('Exception when get workspace for group', err);
      setRefreshButtonLoading(false);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const serviceStatus = (serviceName, successful, description) => {
    if (successful) {
      let label = (
        <div>
          {serviceName}
          <Icon name='status-positive' variant='success' />
        </div>
      );
      return <FormField label={label} description={description}></FormField>;
    } else {
      let label = (
        <div>
          {serviceName}
          <Icon name='status-negative' variant='warning' />
        </div>
      );
      return <FormField label={label} description={description}></FormField>;
    }
  };

  const getStatus = (item) => {
    let currentStatus = <></>;

    switch (item?.status) {
      case 'Active_Sync':
        currentStatus = (
          <div>
            <Icon variant='success' name='status-positive' /> In sync
          </div>
        );
        break;
      case 'Active_Syncing':
        currentStatus = (
          <div>
            <Icon name='status-pending' variant='success' /> Syncing
          </div>
        );
        break;
      case 'Active_Auditing':
        currentStatus = (
          <div>
            <Icon name='status-pending' variant='success' /> Auditing
          </div>
        );
        break;
      case 'Active_Running':
        currentStatus = (
          <div>
            <Icon name='status-pending' variant='success' /> Running
          </div>
        );
        break;
      case 'Active_Outdated':
        currentStatus = (
          <div>
            <Icon name='status-warning' variant='warning' /> Outdated
          </div>
        );
        break;
      case 'Active_Error':
        currentStatus = (
          <div>
            <Icon name='status-warning' variant='warning' /> Error
          </div>
        );
        break;
      case 'Inactive':
        currentStatus = (
          <div>
            <Icon name='status-warning' variant='warning' /> Inactive
          </div>
        );
        break;
    }
    return (
      <>
        {currentStatus}
        <div>
          completed tasks: {item?.workspaceConfig['workflowCompletedTaskCount']} /{' '}
          {item?.workspaceConfig['workflowTotalTaskCount']}
        </div>
      </>
    );
  };

  if (workspace != null && workspace.workspaceId == props.activeWorkspace?.workspaceId) {
    return <Redirect push to={`/current-workspace/${props.activeWorkspace.workspaceId}`} />;
  }

  if (!workspace && !loading) {
    return (
      <>
        <h2>Workspace not found</h2>
        The given workspace is not valid, or you do not have permission to view it. Please check the URL for mistakes
        and try again.
      </>
    );
  }

  return (
    <>
      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            onItemClick: handleRefresh,
            loading: refreshButtonLoading,
          },
        ]}
        header='Workspace details'
      />

      <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
          <h2>{workspace?.workspaceName}</h2>
        </div>
        <ColumnLayout columns={4} variant='text-grid'>
          <div>
            <div className='awsui-util-label'>Workspace ID</div>
            <div> {workspace?.workspaceId}</div>
          </div>

          <div>
            <div className='awsui-util-label'>Group</div>
            <div>
              <Link to={`/groups/${workspace?.groupId}`}>{workspace?.groupId}</Link>
            </div>
          </div>

          <div>
            <div className='awsui-util-label'>Owner</div>
            <div>{workspace?.owner}</div>
          </div>

          <div>
            <div className='awsui-util-label'>Account ID</div>
            <div>{workspace?.accountId}</div>
          </div>

          <div>
            <div className='awsui-util-label'>Region</div>
            <div>us-east-1</div>
          </div>

          <div>
            <div className='awsui-util-label'>Last update</div>
            <div>
              {workspace?.workspaceConfig != undefined &&
              workspace.workspaceConfig?.lastExecutedWorkflowType != undefined
                ? workspace.workspaceConfig.lastExecutedWorkflowType + ' ' + workspace.updateDate
                : workspace?.updateDate}
            </div>
          </div>

          <div>
            <div className='awsui-util-label'>Status</div>
            <div>{getStatus(workspace)}</div>
          </div>
        </ColumnLayout>
        {catalogManagerEnabled != null && redshiftManagerEnabled != null && analyticsManagerEnabled != null && (
          <ColumnLayout>
            <div>
              <div className='awsui-util-label'>Supported services</div>
              {serviceStatus(
                'FoundryCatalogManager ',
                catalogManagerEnabled,
                'Enables you to perform glue catalog management on Omni',
              )}
              {serviceStatus(
                'FoundryRedshiftManager ',
                redshiftManagerEnabled,
                'Enables you to perform redshift management on Omni',
              )}
              {serviceStatus(
                'FoundryAnalyticsManager ',
                analyticsManagerEnabled,
                'Enables you to perform analytics on the data through Omni',
              )}
            </div>
          </ColumnLayout>
        )}
      </div>
    </>
  );
};
