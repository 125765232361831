import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  ColumnLayout,
  FormField,
  Input,
  Header,
  Modal,
  Box,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { Page } from '../../../../routes/Paths';
import { createResourceLinkForDataPermission } from 'src/api/permissions';
import {
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';

export interface TableResourceLinkProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace: any;
  dataset: any;
  visible: boolean;
  closeModal: any;
  notifyCreateResourceLinkSuccess: any;
  notifyCreateResourceLinkFailure: any;
}

export const TableResourceLink = (props: TableResourceLinkProps) => {
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [resourceName, setResourceName] = useState(undefined);
  const [database, setDatabase] = useState(undefined);
  const [region, setRegion] = useState(undefined);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      let request = {
        dataPermissionId: props.dataset.dataPermissionId,
        resourceLink: {
          ownerId: props.activeWorkspace.workspaceId,
          option: DATA_PERMISSION_CONSUMER_OPTION,
          type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
          dataConsumerRole: props.activeWorkspace.workspaceRoleArn,
          region: region != null ? region : props.activeWorkspace.region,
          resource: {
            glueTable: {
              name: resourceName,
              catalogId: props.activeWorkspace.accountId,
              databaseName: database,
              targetTable: {
                catalogId: props.dataset.catalogId,
                name: props.dataset.tableName,
                databaseName: props.dataset.databaseName,
                region: props.dataset.region,
              },
            },
          },
        },
      };
      await createResourceLinkForDataPermission(request);
      props.notifyCreateResourceLinkSuccess('Create resource link for dataset success');
      setLoading(false);
    } catch (err) {
      props.notifyCreateResourceLinkFailure('Failed to create resource link for dataset' + err.message);
      setLoading(false);
    }
    props.closeModal();
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Modal
        visible={props.visible}
        onDismiss={props.closeModal}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={() => setRedirect(Page.HOME)}>
                Cancel
              </Button>
              <Button variant='primary' loading={loading} onClick={handleConfirm}>
                Create Dataset Resource Link
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header description='You can create database resource link here' variant='h1'>
            Dataset resource link details
          </Header>
        }
      >
        <ColumnLayout>
          <FormField
            label={<div>Resource link name</div>}
            description={
              <>
                If you plan to access the database from Amazon Athena, then the name should be under 256 characters and
                contain only lowercase letters (a-z), numbers (0-9), and underscore (_).
                <a
                  href='https://docs.aws.amazon.com/athena/latest/ug/tables-databases-columns-names.html#ate-table-database-and-column-names-allow-only-underscore-special-characters'
                  className='awsui-util-help-info-link'
                  target={'_blank'}
                >
                  Athena names.
                </a>
              </>
            }
          >
            <Input
              placeholder='Enter resource link name'
              ariaRequired={true}
              onChange={(event) => setResourceName(event.detail.value)}
              value={resourceName}
            />
          </FormField>
          <FormField
            label={<div>Database</div>}
            description='Dataset resource link will be contained in this database.'
          >
            <Input
              value={database}
              placeholder='Enter a valid existing database name in this workspace'
              onChange={(event) => setDatabase(event.detail.value)}
              ariaRequired={true}
            />
          </FormField>
          <FormField
            label={<div>Region</div>}
            description='Selected the region of the previously entered database. (us-east-1)'
          >
            <Input
              value={region}
              placeholder='Input a valid region for the database.'
              onChange={(event) => setRegion(event.detail.value)}
              ariaRequired={true}
            />
          </FormField>
          <FormField label={<div>Datasource table</div>}>
            <Input ariaRequired={true} value={props.dataset?.tableName} disabled />
          </FormField>
          <FormField label={<div>Datasource database</div>}>
            <Input ariaRequired={true} value={props.dataset?.databaseName} disabled />
          </FormField>
          <FormField label={<div>Datasource catalogId</div>}>
            <Input ariaRequired={true} value={props.dataset?.catalogId} disabled />
          </FormField>
          <FormField label={<div>Datasource region</div>}>
            <Input ariaRequired={true} value={props.dataset?.region} disabled />
          </FormField>
        </ColumnLayout>
      </Modal>
    </div>
  );
};
