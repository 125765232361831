import { GetDataSetResponse, RegisterDataSetRequest } from 'aws-sdk/clients/tethyscontractservicelambda';
import { DataSetManifest } from './manifest';
import { RecursivePartial } from '../utils/types';

function buildOptionalDataSource(
  dataSource: RecursivePartial<GetDataSetResponse>['DataSource'],
): RegisterDataSetRequest['DataSource'] {
  if (!dataSource) return undefined;
  const dataSourceType = dataSource.SourceType;
  const s3Bucket = dataSource.S3SourceProperties?.Bucket;
  const s3Prefix = dataSource.S3SourceProperties?.Prefix;
  const ddbArn = dataSource.DynamoDBSourceProperties?.DynamoTableArn;
  return dataSourceType
    ? {
        S3SourceProperties:
          s3Bucket && s3Prefix
            ? {
                Bucket: s3Bucket,
                Prefix: s3Prefix,
                Wildcard: dataSource?.S3SourceProperties?.Wildcard,
              }
            : undefined,
        SourceType: dataSourceType,
        DynamoDBSourceProperties: ddbArn
          ? {
              DynamoTableArn: ddbArn,
              KinesisStreamArn: dataSource?.DynamoDBSourceProperties?.KinesisStreamArn,
            }
          : undefined,
      }
    : undefined;
}

/**
 * Builds a RegisterDataSetRequest with all properties filled with either
 * information from a DataSetManifest or type-safe default values.
 */
export function buildRegistrationFromManifest(
  manifest: DataSetManifest,
  creator: string,
  owner: string,
): RegisterDataSetRequest | undefined {
  if (manifest.version === 1) {
    // Most logic here is handling fields that can't be undefined (but may not exist in the manifest)
    // or fields that are required iff the parent object exists (but can remain undefined otherwise).
    // Fields that exist in the DataSetManifest but aren't in the RegisterDataSetRequest must be dropped
    // to succeed future validation.
    return {
      CreatedBy: creator,
      PrimaryOwner: owner,
      DataSetName: manifest.contract.TableName ?? '',
      TableName: manifest.contract.TableName ?? '',
      DatabaseName: manifest.contract.DatabaseName ?? '',
      CatalogId: manifest.contract.CatalogId ?? '',
      // Region is deprecated and hard-coded for backwards compatibility
      Region: 'us-east-1',
      DataSource: buildOptionalDataSource(manifest.contract.DataSource),
      DataContract: manifest.contract.DataContract ?? {},
      RegistrationSource: 'MANIFEST',
    };
  } else {
    return undefined;
  }
}
