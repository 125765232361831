import React, { useCallback, useEffect, useState } from 'react';
import { SelectProps, Select } from '@amzn/awsui-components-react-v3';
import { useDatabases } from './use-databases';
import { DatabaseInfo } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { NonCancelableCustomEvent } from '@amzn/awsui-components-react-v3/polaris/internal/events';

export type SelectChange = NonCancelableCustomEvent<SelectProps.ChangeDetail>;
export type Option = SelectProps.Option; // BUG: Type validation is not enforced.
export type Options = Option[];

interface DatabaseSelectorProps {
  onChange?: (database: DatabaseInfo | undefined) => void;
  initialSelection?: string;
  initialCatalogId: string;
}

const optionsFrom = (database: DatabaseInfo[]): Options => {
  return database.map((db) => ({
    value: db['DatabaseName'] + db['CatalogId'],
    label: db['DatabaseName'],
    description: db['CatalogId'] || 'No Catalog ID available',
  }));
};

const getSelectedOption = (database: DatabaseInfo[], selection: string, catalogId: string): Option => {
  const option = database
    .filter((db) => db['DatabaseName'] === selection && db['CatalogId'] === catalogId)
    .map((db) => ({
      value: db['DatabaseName'] + db['CatalogId'],
      label: db['DatabaseName'],
      description: db['CatalogId'] || 'No Catalog ID available',
    }));
  return option[0];
};

const DatabaseSelector = (props: DatabaseSelectorProps) => {
  const { initialSelection, initialCatalogId } = props;
  const { status, databases } = useDatabases();
  const [selection, setSelection] = useState<Option>(undefined);

  useEffect(() => {
    const selectedOption = getSelectedOption(databases, initialSelection, initialCatalogId);
    const database = databases.find((db) => db.DatabaseName === initialSelection && db.CatalogId === initialCatalogId);
    setSelection(selectedOption);
    if (props.onChange) props.onChange(database);
  }, [initialSelection, databases]);

  const handleChange = useCallback(
    (e: SelectChange) => {
      const selection = e.detail.selectedOption;
      const database = selection
        ? databases.find((db) => db.DatabaseName === selection.label && db.CatalogId === selection.description)
        : undefined;
      setSelection(e.detail.selectedOption);
      if (props.onChange) props.onChange(database);
    },
    [databases, props.onChange],
  );

  return (
    <Select
      options={optionsFrom(databases)}
      selectedOption={selection}
      placeholder='Choose a database'
      filteringType='auto'
      statusType={status}
      empty='No databases available.'
      loadingText='Loading databases.'
      errorText='Unable to load databases.'
      onChange={handleChange}
    />
  );
};

export default DatabaseSelector;
