import React, { useEffect, useState } from 'react';
import { Button, Form, FormField, Input, Select, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { getTags, getWorkspacesForGroups } from 'src/api/permissions';
import { getSchemasForDatabase, listCatalogs, listDatabases, listDatasetsForDatabase } from 'src/api/catalog';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import {
  LAKEFORMATION_TAG_TYPE,
  REDSHIFT_DATASOURCE_ID,
  REDSHIFT_TAG_TYPE,
  TEMPLATE_ACTIVE_STATUS,
  TEMPLATE_CATALOG_RESOURCE_TYPE,
  TEMPLATE_DATABASE_RESOURCE_TYPE,
  TEMPLATE_GROUP_RESOURCE_TYPE,
  TEMPLATE_LAKE_FORMATION,
  TEMPLATE_REDSHIFT,
  TEMPLATE_SCHEMA_RESOURCE_TYPE,
  TEMPLATE_TABLE_RESOURCE_TYPE,
  TEMPLATE_FGAP_RESOURCE_TYPE,
  TEMPLATE_TAG_RESOURCE_TYPE,
  TEMPLATE_WORKSPACE_RESOURCE_TYPE,
  STATUS_ACTIVE,
  LAKE_FORMATION_DATASOURCE_ID,
  LAKEFORMATION_FGA_POLICY_TYPE,
  REDSHIFT_FGA_POLICY_TYPE,
} from 'src/commons/constants';
import {
  generateCatalogId,
  generateDatabaseId,
  generateDatasetId,
  generateSchemaId,
  getDataSource,
} from 'src/components/utils/hybridCatalog/idUtil';
import { fetchFineGrainedAccessPolicies } from 'src/commons/common';
import { generateFGAPArn } from 'src/components/utils/arnUtil';
import { resourceTypeOptions } from 'src/components/common/resourceSelector/common';

export interface ResourceSelectorProps {
  setNotification: (header: any, message: string) => void;
  activeGroup?: string;
  activeWorkspace?: any;
  handleRefresh?: () => void;
  onSelect: (resourceId: string) => void;
  disableGroupAndWorkspaceSelection?: boolean;
  disableTagSelection?: boolean;
}

export const ResourceSelector = (props: ResourceSelectorProps) => {
  const [resourceId, setResourceId] = useState('');
  const [resourceType, setResourceType] = useState({
    value: '',
    label: '',
  } as OptionDefinition);
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [catalogs, setCatalogs] = useState([]);
  const [selectedCatalog, setSelectedCatalog] = useState(null);
  const [databases, setDatabases] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [fgaps, setFGAPs] = useState([]);
  const [selectedFGAP, setSelectedFGAP] = useState(null);
  const [schemas, setSchemas] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState(null);

  const isWorkspace = !!props.activeWorkspace;
  const ownerId = isWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup;
  const ownerIds = isWorkspace
    ? [props.activeWorkspace.workspaceId, props.activeWorkspace.groupId]
    : [props.activeGroup];
  const resourceTypes = resourceTypeOptions(
    isWorkspace,
    props.disableGroupAndWorkspaceSelection,
    props.disableTagSelection,
  );

  useEffect(() => {
    props.onSelect(resourceId);
  }, [resourceId]);

  const reset = () => {
    setResourceId('');
    setResourceType({
      value: '',
      label: '',
    });
    setSelectedWorkspace(null);
    setWorkspaces([]);
    setSelectedTag(null);
    setTags([]);
    setSelectedCatalog(null);
    setCatalogs([]);
    setSelectedDatabase(null);
    setDatabases([]);
    setSelectedTable(null);
    setTables([]);
    setSelectedFGAP(null);
    setFGAPs([]);
    setSelectedSchema(null);
    setSchemas([]);
  };

  const getWorkspaces = async () => {
    try {
      const getWorkspacesForGroupsResult = await getWorkspacesForGroups({
        groupIds: [props.activeGroup],
      });
      const groupWorkspacesMap = getWorkspacesForGroupsResult.workspaces;
      const workspacesForActiveGroup = groupWorkspacesMap[props.activeGroup];
      if (workspacesForActiveGroup !== undefined && workspacesForActiveGroup.succeeded) {
        let formattedWorkspaces: any = workspacesForActiveGroup.workspaces;
        if (isWorkspace) {
          // for workspace, we only get the current workspace. else, we get all of them
          formattedWorkspaces = formattedWorkspaces.filter((wks) => wks.workspaceId == ownerId);
        }
        formattedWorkspaces = formattedWorkspaces.map((wks) => {
          return {
            value: wks.workspaceId,
            label: `${wks.workspaceId} (${wks.workspaceName})`,
          };
        });
        setWorkspaces(formattedWorkspaces);
      } else {
        console.log('Failed to get workspaces for group.', workspacesForActiveGroup?.message);
      }
    } catch (err) {
      console.log('Exception when getting workspaces for group', err);
    }
  };

  const handleResourceTypeOnClick = (event) => {
    clearSelectedOptions();
    setResourceType(event.detail.selectedOption);
    const label = event.detail.selectedOption.label;
    if (label.includes(TEMPLATE_GROUP_RESOURCE_TYPE)) {
      setResourceId(props.activeGroup);
    } else if (label.includes(TEMPLATE_WORKSPACE_RESOURCE_TYPE)) {
      getWorkspaces();
    } else if (
      label.includes(TEMPLATE_CATALOG_RESOURCE_TYPE) ||
      label.includes(TEMPLATE_DATABASE_RESOURCE_TYPE) ||
      label.includes(TEMPLATE_TABLE_RESOURCE_TYPE) ||
      label.replaceAll(' ', '').includes(TEMPLATE_FGAP_RESOURCE_TYPE) ||
      label.includes(TEMPLATE_SCHEMA_RESOURCE_TYPE)
    ) {
      getCatalogs(label);
    } else if (label.includes(TEMPLATE_TAG_RESOURCE_TYPE)) {
      getTagsForWorkspace(label);
    } else {
      setResourceId('');
      setSelectedCatalog(null);
    }
  };

  const getCatalogs = async (selectedResourceType: string) => {
    try {
      let request = { NextToken: null };
      const catalogs = await listCatalogs(request);
      let catalogList = catalogs.CatalogInfoList.filter((item) => item.Owner == ownerId);
      if (selectedResourceType.includes(TEMPLATE_LAKE_FORMATION)) {
        catalogList = catalogList.filter((item) => item.DataSourceId == null || item.DataSourceId !== 'redshift');
      } else if (selectedResourceType.includes(TEMPLATE_REDSHIFT)) {
        catalogList = catalogList.filter((item) => item.DataSourceId === 'redshift');
      }
      setCatalogs(catalogList);
    } catch (err) {
      console.log('Exception when getting catalogs', err);
    }
  };

  const getTables = async (database) => {
    try {
      let nextToken = '';
      let tableList = [];
      while (nextToken != null) {
        try {
          let datasets;
          const datasourceId = database.DataSourceId != null ? database.DataSourceId : getDataSource(database);
          if (nextToken != '') {
            datasets = await listDatasetsForDatabase({
              DatabaseName: database.DatabaseName,
              CatalogId: database.CatalogId,
              DataSourceId: datasourceId,
              ClusterIdentifier: database.ClusterIdentifier,
              SchemaName: database.Schema,
              Region: database.Region,
              NextToken: nextToken,
            });
          } else {
            datasets = await listDatasetsForDatabase({
              DatabaseName: database.DatabaseName,
              CatalogId: database.CatalogId,
              DataSourceId: datasourceId,
              ClusterIdentifier: database.ClusterIdentifier,
              SchemaName: database.Schema,
              Region: database.Region,
            });
          }
          tableList = tableList.concat(datasets.DataSetList);
          nextToken = datasets.NextToken;
        } catch (err) {
          console.log('Exception when fetching datasets for database', err);
          break;
        }
      }
      tableList = tableList.filter((table) => ownerIds.includes(table.PrimaryOwner));
      setTables(tableList);
    } catch (err) {
      console.log('Exception when fetching tables', err);
    }
  };

  const getSchema = async (database) => {
    try {
      let nextToken = '';
      let schemaList = [];
      while (nextToken != null) {
        try {
          let schemas;
          if (nextToken != '') {
            schemas = await getSchemasForDatabase({
              DatabaseName: database.DatabaseName,
              CatalogId: database.CatalogId,
              DataSourceId: REDSHIFT_DATASOURCE_ID,
              ClusterIdentifier: database.ClusterIdentifier,
              Region: database.Region,
              NextToken: nextToken,
            });
          } else {
            schemas = await getSchemasForDatabase({
              DatabaseName: database.DatabaseName,
              CatalogId: database.CatalogId,
              DataSourceId: REDSHIFT_DATASOURCE_ID,
              ClusterIdentifier: database.ClusterIdentifier,
              Region: database.Region,
            });
          }
          schemaList = schemaList.concat(schemas.SchemaInfoList);
          nextToken = schemas.NextToken;
        } catch (err) {
          console.log('Exception when fetching schemas for database', err);
          break;
        }
      }
      setSchemas(schemaList);
    } catch (err) {
      console.log('Exception when fetching schemas', err);
    }
  };

  const getFineGrainedAccessPolicies = async (table) => {
    if (!table || !table.IdInfo || !table.IdInfo.DataSourceId) return [];

    const dataSourceId = table.IdInfo.DataSourceId;
    const fgapType =
      dataSourceId == LAKE_FORMATION_DATASOURCE_ID ? LAKEFORMATION_FGA_POLICY_TYPE : REDSHIFT_FGA_POLICY_TYPE;

    // listFGAPByResourceId
    let fgaps = await fetchFineGrainedAccessPolicies(null, STATUS_ACTIVE, fgapType, table.IdInfo);
    setFGAPs(fgaps);
  };

  const getDatabasesForCatalog = async (catalog) => {
    try {
      let request = {
        NextToken: null,
        DatabaseKeyList: [
          {
            CatalogId: catalog.CatalogId,
            Region: catalog.Region,
            DataSourceId: catalog.DataSourceId,
            ClusterIdentifier: catalog.ClusterIdentifier,
          },
        ],
      };
      let databases = await listDatabases(request);
      let databaseInfoList = databases.DatabaseInfoList;
      while (databases.NextToken != null) {
        request.NextToken = databases.NextToken;
        databases = await listDatabases(request);
        databaseInfoList.push(...databases.DatabaseInfoList);
      }
      if (resourceType.label.includes(TEMPLATE_LAKE_FORMATION)) {
        databaseInfoList = databaseInfoList.filter((item) => item.DataSourceId !== 'redshift');
      } else if (resourceType.label.includes(TEMPLATE_REDSHIFT)) {
        databaseInfoList = databaseInfoList.filter((item) => item.DataSourceId == 'redshift');
      }
      let databasesInCatalog = databaseInfoList.map((database) => {
        return {
          CatalogId: database.CatalogId,
          DatabaseName: database.DatabaseName,
          Region: database.Region,
          DataSourceId: catalog.DataSourceId,
          DataAccessRole: database.DataAccessRole,
          ClusterIdentifier: database.ClusterIdentifier,
        };
      });
      databasesInCatalog = databasesInCatalog.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.CatalogId === value.CatalogId && t.DatabaseName === value.DatabaseName),
      );
      setDatabases(databasesInCatalog);
    } catch (err) {
      console.log('Exception when fetch databases', err);
    }
  };

  const getTagsForWorkspace = async (selectedResourceType: string) => {
    try {
      const tagsResponse = await getTags({
        ownerId: props.activeWorkspace.workspaceId,
      });
      let tags = tagsResponse.tags;
      if (selectedResourceType.includes(TEMPLATE_REDSHIFT)) {
        tags = tags.filter((tag) => tag.tagType === REDSHIFT_TAG_TYPE);
      } else {
        tags = tags.filter((tag) => tag.tagType === LAKEFORMATION_TAG_TYPE);
      }
      const publisherTags = tags.filter((tag) => {
        return tag.status == TEMPLATE_ACTIVE_STATUS;
      });
      setTags(publisherTags);
    } catch (err) {
      console.log('Exception when fetching tags ', err);
    }
  };

  const clearSelectedOptions = () => {
    setSelectedCatalog(null);
    setSelectedWorkspace(null);
    setSelectedDatabase(null);
    setSelectedSchema(null);
    setSelectedTable(null);
    setSelectedFGAP(null);
    setSelectedTag(null);
  };

  return (
    <SpaceBetween direction={'vertical'} size={'s'}>
      <Form>
        <FormField label={<div>Resource type</div>} description='Select the type of resource.'>
          <Select
            selectedAriaLabel='Selected'
            options={resourceTypes}
            onChange={(event) => handleResourceTypeOnClick(event)}
            placeholder='Please select the resource type'
            errorText='Error fetching type.'
            recoveryText='Retry'
            empty={'No types available'}
            filteringType='auto'
            filteringAriaLabel='Filter resource types'
            ariaRequired={true}
            selectedOption={resourceType}
          />
        </FormField>
        {resourceType != null && resourceType.label == 'Group' && (
          <>
            <FormField label={<div>Group ID</div>} description={<div>The group you are logged in with.</div>}>
              <Input ariaRequired={true} value={props.activeGroup} readOnly={true} disabled />
            </FormField>
          </>
        )}
        {resourceType != null && resourceType.label == 'Workspace' && (
          <>
            <FormField label={<div>Workspace ID</div>} description={<div>Select a workspace from your group.</div>}>
              <Select
                selectedAriaLabel='Selected'
                options={workspaces}
                onChange={(event) => {
                  setResourceId(event.detail.selectedOption.value);
                  setSelectedWorkspace(event.detail.selectedOption);
                }}
                placeholder='Please select the workspace'
                errorText='Error fetching workspaces.'
                recoveryText='Retry'
                empty={'No workspaces for this group.'}
                filteringType='auto'
                filteringAriaLabel='Filter workspaces'
                ariaRequired={true}
                selectedOption={selectedWorkspace}
              />
            </FormField>
          </>
        )}
        {resourceType != null &&
          (resourceType.label == 'Lake Formation Catalog' ||
            resourceType.label == 'Redshift Catalog' ||
            resourceType.label == 'Lake Formation Database' ||
            resourceType.label == 'Redshift Database' ||
            resourceType.label == 'Lake Formation Table' ||
            resourceType.label == 'Lake Formation Fine Grained Access Policy' ||
            resourceType.label == 'Redshift Table' ||
            resourceType.label == 'Redshift Fine Grained Access Policy' ||
            resourceType.label == 'Redshift Schema') && (
            <>
              <FormField label={<div>Catalog</div>} description={<div>Select the catalog.</div>}>
                <Select
                  selectedAriaLabel='Selected'
                  options={catalogs.map((catalog) => {
                    return {
                      value: catalog.CatalogId,
                      label: `${catalog.CatalogId} (${catalog.Name})`,
                    };
                  })}
                  onChange={(event) => {
                    const selected = catalogs.find((catalog) => catalog.CatalogId == event.detail.selectedOption.value);
                    setSelectedCatalog(event.detail.selectedOption);
                    if (resourceType.label.includes(TEMPLATE_CATALOG_RESOURCE_TYPE)) {
                      setResourceId(generateCatalogId(selected));
                    }
                    getDatabasesForCatalog(selected);
                    setSelectedDatabase(null);
                    setSelectedTable(null);
                    setSelectedFGAP(null);
                  }}
                  placeholder='Please select the catalog'
                  errorText='Error fetching catalogs.'
                  recoveryText='Retry'
                  empty={'Your group does not own any catalogs.'}
                  filteringType='auto'
                  filteringAriaLabel='Filter catalogs'
                  ariaRequired={true}
                  selectedOption={selectedCatalog}
                />
              </FormField>
            </>
          )}
        {resourceType != null &&
          selectedCatalog != null &&
          (resourceType.label == 'Lake Formation Database' ||
            resourceType.label == 'Redshift Database' ||
            resourceType.label == 'Lake Formation Table' ||
            resourceType.label == 'Lake Formation Fine Grained Access Policy' ||
            resourceType.label == 'Redshift Table' ||
            resourceType.label == 'Redshift Fine Grained Access Policy' ||
            resourceType.label == 'Redshift Schema') && (
            <>
              <FormField label={<div>Database</div>} description={<div>Select the database.</div>}>
                <Select
                  selectedAriaLabel='Selected'
                  options={databases.map((database) => {
                    return {
                      value: database.CatalogId,
                      label: database.DatabaseName,
                    };
                  })}
                  onChange={(event) => {
                    const selected = databases.find(
                      (database) =>
                        database.CatalogId == event.detail.selectedOption.value &&
                        database.DatabaseName == event.detail.selectedOption.label,
                    );
                    setSelectedDatabase(event.detail.selectedOption);
                    setSelectedTable(null);
                    setSelectedFGAP(null);
                    if (resourceType.label.includes(TEMPLATE_DATABASE_RESOURCE_TYPE)) {
                      setResourceId(generateDatabaseId(selected));
                    }
                    if (resourceType.label.includes(TEMPLATE_REDSHIFT)) {
                      getSchema(selected);
                    } else {
                      getTables(selected);
                    }
                  }}
                  placeholder='Please select the database.'
                  errorText='Error fetching databases.'
                  recoveryText='Retry'
                  empty={'No databases in this catalog.'}
                  filteringType='auto'
                  filteringAriaLabel='Filter databases'
                  ariaRequired={true}
                  selectedOption={selectedDatabase}
                />
              </FormField>
              {selectedDatabase != null &&
                (resourceType.label == 'Redshift Schema' ||
                  resourceType.label == 'Redshift Table' ||
                  resourceType.label == 'Redshift Fine Grained Access Policy') && (
                  <FormField label={<div>Schema</div>} description={<div>Select the schema.</div>}>
                    <Select
                      selectedAriaLabel='Selected'
                      options={schemas.map((schemaItem) => {
                        return {
                          value: schemaItem.Schema,
                          label: schemaItem.Schema,
                        };
                      })}
                      onChange={(event) => {
                        const selected = schemas.find((schema) => schema.Schema == event.detail.selectedOption.value);
                        setSelectedSchema(event.detail.selectedOption);
                        setSelectedTable(null);
                        setSelectedFGAP(null);
                        if (resourceType.label.includes(TEMPLATE_SCHEMA_RESOURCE_TYPE)) {
                          setResourceId(generateSchemaId(selected));
                        }
                        getTables(selected);
                      }}
                      placeholder='Please select the schema.'
                      errorText='Error fetching schemas.'
                      recoveryText='Retry'
                      empty={'No schemas for this database.'}
                      filteringType='auto'
                      filteringAriaLabel='Filter schemas'
                      ariaRequired={true}
                      selectedOption={selectedSchema}
                    />
                  </FormField>
                )}
              {selectedDatabase != null &&
                (resourceType.label == 'Lake Formation Table' ||
                  resourceType.label == 'Lake Formation Fine Grained Access Policy' ||
                  resourceType.label == 'Redshift Table' ||
                  resourceType.label == 'Redshift Fine Grained Access Policy') && (
                  <FormField label={<div>Table</div>} description={<div>Select the table.</div>}>
                    <Select
                      selectedAriaLabel='Selected'
                      options={tables.map((table) => {
                        return {
                          value: table.Id,
                          label: table.DataSetName,
                        };
                      })}
                      onChange={(event) => {
                        const selected = tables.find((table) => table.Id == event.detail.selectedOption.value);
                        setSelectedTable(event.detail.selectedOption);
                        setSelectedFGAP(null);
                        if (resourceType.label.includes(TEMPLATE_TABLE_RESOURCE_TYPE)) {
                          setResourceId(generateDatasetId(selected));
                        }
                        getFineGrainedAccessPolicies(selected);
                      }}
                      placeholder='Please select the table.'
                      errorText='Error fetching tables.'
                      recoveryText='Retry'
                      empty={'No tables in this database.'}
                      filteringType='auto'
                      filteringAriaLabel='Filter tables'
                      ariaRequired={true}
                      selectedOption={selectedTable}
                    />
                  </FormField>
                )}
              {selectedTable != null &&
                (resourceType.label == 'Lake Formation Fine Grained Access Policy' ||
                  resourceType.label == 'Redshift Fine Grained Access Policy') && (
                  <FormField
                    label={<div>Fine grained access policy</div>}
                    description={<div>Select the fine grained access policy.</div>}
                  >
                    <Select
                      selectedAriaLabel='Selected'
                      options={fgaps.map((policy) => {
                        return {
                          value: policy.id,
                          label: policy.tableData.name,
                        };
                      })}
                      onChange={(event) => {
                        const selected = fgaps.find((policy) => policy.id == event.detail.selectedOption.value);
                        setSelectedFGAP(event.detail.selectedOption);
                        if (resourceType.label.replaceAll(' ', '').includes(TEMPLATE_FGAP_RESOURCE_TYPE)) {
                          setResourceId(generateFGAPArn(selected));
                        }
                      }}
                      placeholder='Please select the fine grained access policy.'
                      errorText='Error fetching fine grained access policies.'
                      recoveryText='Retry'
                      empty={'No fine grained access policy available.'}
                      filteringType='auto'
                      filteringAriaLabel='Filter fine grained policies'
                      ariaRequired={true}
                      selectedOption={selectedFGAP}
                    />
                  </FormField>
                )}
            </>
          )}
        {resourceType != null &&
          (resourceType.label == 'Lake Formation Tag' || resourceType.label == 'Redshift Tag') && (
            <>
              <FormField
                label={<div>Tags</div>}
                description={<div>Select the tag to associate with the template.</div>}
              >
                <Select
                  selectedAriaLabel='Selected'
                  options={tags.map((tag) => {
                    return { value: tag.tagId, label: tag.tagId };
                  })}
                  onChange={(event) => {
                    if (resourceType.label.includes(TEMPLATE_TAG_RESOURCE_TYPE)) {
                      setResourceId(event.detail.selectedOption.label);
                    }
                    setSelectedTag(event.detail.selectedOption);
                  }}
                  placeholder='Please select the tag'
                  errorText='Error fetching tags.'
                  recoveryText='Retry'
                  empty={'No tags available.'}
                  filteringType='auto'
                  filteringAriaLabel='Filter tags'
                  ariaRequired={true}
                  selectedOption={selectedTag}
                />
              </FormField>
            </>
          )}
      </Form>
      <Button formAction={'none'} variant={'link'} onClick={reset}>
        Reset
      </Button>
    </SpaceBetween>
  );
};
