import * as React from 'react';
import { MetricTableView } from 'src/components/metricstore/metriccollection/metricTableView';
import { ViewType } from "src/components/metricstore/helpers";

export interface monthlyViewProps {
  setContentType: any;
  match: any;
}

export const MonthlyView = (props: monthlyViewProps) => {
  return <MetricTableView props={props} viewType={ViewType.MONTHLY} columnCount={12} />;
};
