import * as React from 'react';
import { useState, useEffect } from 'react';

import { Alert, Container, SpaceBetween, Spinner } from '@amzn/awsui-components-react-v3';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { AdvisoryDetailHeader } from 'src/components/dataadvisory/advisoryDetailsPage/advisoryDetailHeader';
import {
  activeGroupOrWorkspaceId,
  notificationsToFlashbar,
  safelyRenderedAdvisoryMarkdownContent,
} from 'src/components/dataadvisory/constants';
import { ImpactedResourceTable } from 'src/components/dataadvisory/advisoryDetailsPage/impactedResourceTable';
import { AttachedTicketTable } from 'src/components/dataadvisory/advisoryDetailsPage/attachedTicketTable';
import { Redirect } from 'react-router-dom';
import { ContactInformationTable } from 'src/components/dataadvisory/advisoryDetailsPage/contactInformationTable';
import { getAdvisory, listUserPreferences, updateAdvisory } from 'src/api/notifications';
import { createUpdateAdvisoryLink } from 'src/routes';
import { addDismissedAdvisory } from 'src/components/common/activeAdvisoryGlobalFlashbar';

export interface AdvisoryDetailPageProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
}

export const AdvisoryDetailPage = (props: AdvisoryDetailPageProps) => {
  const [advisory, setAdvisory] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(undefined);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [userPreferences, setUserPreferences] = useState([]);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const advisory = await getAdvisory({ advisoryId: props.match.params.id });
      setAdvisory(advisory.advisory);
      const userPreferences = await listUserPreferences({
        filter: {
          ownerId: activeGroupOrWorkspaceId(props),
          resourceArns: [
            advisory.advisory.advisoryId,
            ...advisory.advisory.impactedResources.map((impactedResource) => impactedResource.resourceArn),
            advisory.advisory.ownerId,
          ],
          types: ['AdvisoryPreferences'],
        },
      });
      setUserPreferences(userPreferences.userPreferences);
      // set this advisory as "dismissed" in browser storage.
      addDismissedAdvisory(advisory.advisory.advisoryId);
    } catch (e) {
      console.log(e);
      setFailedToLoad(true);
    }
    setLoading(false);
  };

  const userOwnsAdvisory = () => {
    return activeGroupOrWorkspaceId(props) == advisory.ownerId;
  };

  const handleEdit = () => {
    setRedirect(createUpdateAdvisoryLink(advisory.advisoryId));
  };

  const setNotificationForSuccessfulStatusChange = () => {
    setNotifications([
      {
        type: 'success',
        content: 'Successfully updated the advisory status.',
      },
    ]);
  };

  const setNotificationForFailedStatusChange = (e) => {
    setNotifications([
      {
        type: 'error',
        content: 'Failed to set the advisory status: ' + e.message,
      },
    ]);
  };

  const setNotificationForSuccessfulPreferencesUpdate = () => {
    setNotifications([
      {
        type: 'success',
        content: 'Successfully updated notification preferences.',
      },
    ]);
  };

  const setNotificationForFailedPreferencesUpdate = (e) => {
    setNotifications([
      {
        type: 'error',
        content: 'Failed to update notification preferences: ' + e.message,
      },
    ]);
  };

  const setStatus = async (status: string) => {
    try {
      const statusWithCapitalFirstLetter = status.charAt(0).toUpperCase() + status.slice(1);
      await updateAdvisory({
        advisoryId: advisory.advisoryId,
        status: statusWithCapitalFirstLetter,
      });
      setNotificationForSuccessfulStatusChange();
    } catch (e) {
      setNotificationForFailedStatusChange(e);
    } finally {
      await handleRefresh();
    }
  };

  if (loading) {
    return <Spinner size={'normal'} />;
  }
  if (failedToLoad) {
    return (
      <Alert type={'error'} statusIconAriaLabel={'Error'} header={'Unable to load advisory'}>
        Please double-check the advisory ID in the URL and try again.
      </Alert>
    );
  }

  return (
    <SpaceBetween size={'m'}>
      {redirect && <Redirect to={redirect} />}
      {notifications.length > 0 && notificationsToFlashbar(notifications, setNotifications)}
      <SpaceBetween size={'l'}>
        <AdvisoryDetailHeader
          advisory={advisory}
          activeGroup={props.activeGroup}
          activeWorkspace={props.activeWorkspace}
          userOwnsAdvisory={userOwnsAdvisory()}
          userPreferences={userPreferences}
          onPreferenceUpdateSuccess={setNotificationForSuccessfulPreferencesUpdate}
          onPreferenceUpdateFailure={setNotificationForFailedPreferencesUpdate}
          handleEdit={() => handleEdit()}
          setStatus={(status) => setStatus(status)}
        />
        <Container>
          {
            <>
              {advisory.content.format == 'Markdown'
                ? safelyRenderedAdvisoryMarkdownContent(advisory.content.rawContent)
                : advisory.content.rawContent}
            </>
          }
        </Container>
        <ImpactedResourceTable advisory={advisory} {...props} />
        <AttachedTicketTable advisory={advisory} {...props} />
        <ContactInformationTable advisory={advisory} {...props} />
      </SpaceBetween>
    </SpaceBetween>
  );
};
