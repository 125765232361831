import { Box, Button, ColumnLayout, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { editCatalog } from 'src/api/catalog';
import { REDSHIFT_DATASOURCE_ID } from 'src/commons/constants';

export interface CatalogEditModalProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  catalog: any;
  visible: boolean;
  dismiss: any;
  notify: any;
  handleRefresh: any;
}

export const CatalogEditModal = (props: CatalogEditModalProps) => {
  const [catalogDescription, setCatalogDescription] = useState(undefined);
  const [catalogName, setCatalogName] = useState(undefined);
  const [CTI, setCTI] = useState(undefined);
  const [catalogId, setCatalogId] = useState(undefined);
  const clusterIdentifier = props.catalog?.ClusterIdentifier;

  const handleEdit = async () => {
    try {
      let request;
      if (clusterIdentifier != null) {
        request = {
          CatalogId: props.catalog.CatalogId,
          ClusterIdentifier: clusterIdentifier,
          Region: props.catalog.Region,
          Name: catalogName,
          CTI: CTI,
          Description: catalogDescription,
          DataSourceId: REDSHIFT_DATASOURCE_ID,
        };
      } else {
        request = {
          CatalogId: props.catalog.CatalogId,
          Region: props.catalog.Region,
          Name: catalogName,
          CTI: CTI,
          Description: catalogDescription,
        };
      }
      await editCatalog(request);
      props.notify('success', 'Successfully edited catalog details.');
      props.handleRefresh();
    } catch (err) {
      props.notify('Failed to edit catalog: ' + err.message);
    }
    props.dismiss();
  };

  const handleRefresh = async () => {
    setCatalogId(props.catalog?.CatalogId);
    setCatalogDescription(props.catalog?.Description);
    setCatalogName(props.catalog?.Name);
    setCTI(props.catalog?.CTI);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <Modal
      onDismiss={props.dismiss}
      visible={props.visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={props.dismiss}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleEdit}>
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
      size='medium'
      header='Edit catalog'
    >
      <ColumnLayout>
        <FormField label='Catalog ID'>
          <Input
            disabled={true}
            placeholder={'Catalog Id'}
            value={catalogId}
            onChange={(e) => {
              setCatalogId(e.detail.value);
            }}
          />
        </FormField>
        <FormField label='Catalog name'>
          <Input
            placeholder={'Catalog name'}
            value={catalogName}
            onChange={(e) => {
              setCatalogName(e.detail.value);
            }}
          />
        </FormField>
        <FormField label='Catalog description'>
          <Input
            placeholder={'Catalog description'}
            value={catalogDescription}
            onChange={(e) => {
              setCatalogDescription(e.detail.value);
            }}
          />
        </FormField>
        <FormField label='CTI'>
          <Input
            placeholder={'CTI'}
            value={CTI}
            onChange={(e) => {
              setCTI(e.detail.value);
            }}
          />
        </FormField>
      </ColumnLayout>
    </Modal>
  );
};
