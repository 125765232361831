import { useEffect, useState } from 'react';
import { FORM_CONTENT_TYPE, hcMetadataFormType, hcMetadataStatus } from 'src/commons/constants';
import { Page } from 'src/routes';
import { editMetadataForm, getMetadataForm } from 'src/api/catalog';
import * as React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import {
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

export interface EditMetadataFormProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace?: any;
}

const EditMetadataForm = (props: EditMetadataFormProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [formId, setFormId] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [ownerId, setOwnerId] = useState(undefined);
  const [, setFormType] = useState(null);
  const [status, setStatus] = useState(null);
  const [description, setDescription] = useState(undefined);
  const [name, setName] = useState(undefined);
  const { state } = useLocation();

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);
    const activeGroup = props.activeGroup;
    const activeWorkspace = props.activeWorkspace;
    const ownerId = activeWorkspace ? activeWorkspace.workspaceId : activeGroup;
    setOwnerId(ownerId);
    setFormId(state.metadataFormId);
  }, []);

  useEffect(() => {
    if (ownerId && formId) {
      fetchMetadataFormToEdit();
    }
  }, [formId, ownerId]);

  const fetchMetadataFormToEdit = async () => {
    try {
      let getMetadataFormResult = await getMetadataForm({
        OwnerId: ownerId,
        MetadataFormId: formId,
      });
      let formDetails = getMetadataFormResult.FormDetails;
      if (!formDetails) {
        await setNotification('Error', 'No form found with id:' + formId);
      } else {
        setDescription(formDetails.Description);
        setName(formDetails.Name);
        setStatus(
          hcMetadataStatus.filter((statusSelection) => {
            return statusSelection.value === formDetails.Status;
          })[0],
        );
        setFormType(
          hcMetadataFormType.filter((formTypeSelection) => {
            return formTypeSelection.value === formDetails.FormType;
          })[0],
        );
      }
    } catch (err) {
      await setNotification('error', err.message);
    }
  };

  const handleEditMetadataForm = async () => {
    setButtonLoading(true);
    try {
      await editMetadataForm({
        MetadataFormId: formId,
        OwnerId: ownerId,
        NewDescription: description,
        NewName: name,
        NewStatus: status.value,
      });
      setRedirect({
        pathname: Page.METADATA_FORMS,
        state: {
          status: 'success',
          message: 'Form updated successfully',
        },
      });
    } catch (e) {
      await setNotification('Error', `Error when trying to edit form: ${e.message}`);
    } finally {
      setButtonLoading(false);
      setDescription(undefined);
      setStatus(null);
      setName(undefined);
    }
  };

  const setNotification = async (header, message) => {
    if (header === 'error') {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}
      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>
      <Form
        header={
          <Header variant='h1' description='Provide the details to edit form.'>
            Edit Form
          </Header>
        }
        actions={
          <SpaceBetween direction='horizontal' size='s'>
            <Button variant='link' onClick={() => setRedirect(Page.METADATA_FORMS)}>
              Cancel
            </Button>
            <Button variant='primary' loading={buttonLoading} onClick={handleEditMetadataForm}>
              {'Save changes'}
            </Button>
          </SpaceBetween>
        }
      >
        <Container className='custom-screenshot-hide' header={<Header variant='h2'>Form details</Header>}>
          <ColumnLayout>
            <SpaceBetween size='m'>
              <FormField label={<div>Form id</div>} description='Form id.'>
                <Input name='formId' value={formId} ariaRequired={true} disabled={true} />
              </FormField>
              <FormField label={<div>Owner id</div>} description='Owner id.'>
                <Input name='ownerId' value={ownerId} ariaRequired={true} disabled={true} />
              </FormField>
              <FormField label={<div>Name</div>} description='Name of form.'>
                <Input
                  name='ownerId'
                  value={name}
                  ariaRequired={true}
                  onChange={({ detail }) => setName(detail.value)}
                />
              </FormField>
              <FormField label={<div>Description</div>} description='Description of form.'>
                <Input
                  name='description'
                  value={description}
                  ariaRequired={true}
                  onChange={({ detail }) => setDescription(detail.value)}
                />
              </FormField>
              <FormField label='Status' description='Status of form'>
                <Select
                  name='status'
                  placeholder='Enabled'
                  selectedOption={status}
                  options={hcMetadataStatus}
                  ariaRequired={true}
                  onChange={({ detail }) => {
                    setStatus(detail.selectedOption);
                  }}
                  disabled={false}
                />
              </FormField>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};

export default EditMetadataForm;
