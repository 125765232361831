import * as React from 'react';
import { ColumnLayout, Container, Header, SpaceBetween, Box, Button, Modal } from '@amzn/awsui-components-react-v3';
import { useEffect, useState } from 'react';

import { getBootstrapAction, deleteBootstrapAction } from '../../../api/resourcesmanager';
import { labeledTextFromList, RMPageHeader, ErrorAlert, AuditLogsCard, s3url } from '../components';
import { Redirect } from 'react-router-dom';
import { Page } from 'src/routes';
import { dateString, pop } from '../helpers';
import { CARDS_CONTENT_TYPE } from 'src/commons/constants';
import { DefaultRouteProps } from 'src/commons/common';

export interface bootstrapActionDetailProps extends DefaultRouteProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}

export interface bootstrapActionDetailState {
  loading: boolean;
  bootstrapAction: object;
  error: object;
  redirect: string;
  contentType: string;
  redirectParams: object;
  guardrail: any;
}

export const BootstrapActionDetails = (props: bootstrapActionDetailProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [bootstrapAction, setBootstrapAction] = useState(null);
  const [redirect, setRedirect] = useState(undefined);
  const [redirectParams, setRedirectParams] = useState({});
  const [guardrail, setGuardrail] = useState(null);

  useEffect(() => {
    props.setContentType(CARDS_CONTENT_TYPE);
    handleRefresh();
  }, []);

  /**
   * Asynchronously fetches the bootstrapactions from the RM API and updates the state.
   */
  const handleRefresh = async () => {
    setLoading(true);

    try {
      const bootstrapaction = await getBootstrapAction({
        id: props.match.params.id,
      });
      setBootstrapAction(bootstrapaction);
      setLoading(false);
    } catch (err) {
      setError({ ...err, while: 'LOADING BOOTSTRAP ACTION' });
      setBootstrapAction(null);
      setLoading(false);
    }
  };

  /**
   * [UNGUARDED]: Asynchronously calls the DELETE API for the given bootstrap action.
   */
  const deleteBSA_UNGUARDED = async () => {
    try {
      const response = await deleteBootstrapAction({
        id: props.match.params.id,
      });

      if (response.successful) {
        setRedirect(Page.BOOTSTRAPACTIONS);
      } else {
        setError({
          ...response,
          code: 'ResponseNotSuccessful',
          while: 'DELETING BOOTSTRAP ACTION',
        });
      }
    } catch (err) {
      console.error(err);
      setError({ ...err, while: 'DELETING BOOTSTRAP ACTION' });
    }
  };

  /**
   * A wrapper which requires the user to confirm before submitting a DeleteBootstrapAction request to the API.
   */
  const deleteBSA = async () => {
    setGuardrail({
      header: `DELETE ${props.match.params.id}?`,
      action: deleteBSA_UNGUARDED,
    });
  };

  /**
   * Redirects the user to the Update Bootstrap Action page.
   */
  const updateBSA = () => {
    setRedirect(Page.UPDATE_BOOTSTRAPACTION.replace(':id', props.match.params.id));
  };

  /**
   * Redirects the user to the Create Bootstrap Action page, prefilled with the configuration of the current BootstrapAction.
   */
  const cloneBSA = () => {
    setRedirect(Page.CREATE_BOOTSTRAPACTION);
    setRedirectParams({ values: bootstrapAction });
  };

  const Guardrail_UNBOUND = () => {
    if (guardrail) {
      var props = new Object({ ...guardrail });
      props['header'] = props['header'] || 'PLEASE CONFIRM ACTION:';
      props['closeAriaLabel'] = props['closeAriaLabel'] || 'Close modal';
      props['visible'] = typeof props['visible'] !== 'undefined' ? props['visible'] : true;
      props['onDismiss'] = () => {
        setGuardrail(null);
      };

      const content = pop(
        props,
        'content',
        [
          'Are you sure you want to continue with the above action?',
          'If so, please click "OK" to proceed.',
          'Otherwise close this box by clicking "Cancel" below or "X" above.',
        ].map((t) => <p>{t}</p>),
      );
      const action = pop(props, 'action', (e) => console.error('No Action Defined. Event: ', e));

      props['footer'] = (
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => setGuardrail(null)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={action}>
              OK
            </Button>
          </SpaceBetween>
        </Box>
      );

      return (
        <Modal visible={props['visible']} {...props}>
          {content}
        </Modal>
      );
    } else {
      return [];
    }
  };

  return (
    <>
      {redirect && (
        <Redirect
          push
          to={{
            pathname: redirect,
            state: redirectParams,
          }}
        />
      )}

      {ErrorAlert(error)}
      {Guardrail_UNBOUND}

      <SpaceBetween size='l'>
        <RMPageHeader
          buttons={[
            {
              text: 'Delete',
              onItemClick: deleteBSA,
            },
            {
              text: 'Edit',
              onItemClick: updateBSA,
            },
            {
              text: 'Clone',
              onItemClick: cloneBSA,
            },
          ]}
          subheader={bootstrapAction?.name}
        />

        <Container header={<Header variant='h2'> Bootstrap action details </Header>}>
          <ColumnLayout columns={2} borders='horizontal'>
            {labeledTextFromList(
              [
                {
                  label: 'Bootstrap action ID',
                  value: bootstrapAction?.id,
                  copiable: true,
                },
                {
                  label: 'Description',
                  value: bootstrapAction?.description,
                },
                {
                  label: 'S3 location',
                  value: bootstrapAction?.s3FileLocation,
                  url: s3url(bootstrapAction?.s3FileLocation),
                  copiable: true,
                },
                {
                  label: 'Args',
                  value: bootstrapAction?.args ? bootstrapAction?.args.join(', ') : 'no arguments',
                },
                {
                  label: 'Owner (Group ID)',
                  value: bootstrapAction?.groupId,
                  copiable: true,
                },
                {
                  label: 'Is default?',
                  value: bootstrapAction?.isDefaultBootstrap ? 'Yes' : 'No',
                },
                {
                  label: 'Created by',
                  value: bootstrapAction?.createdBy,
                  copiable: true,
                },
                {
                  label: 'Create date',
                  value: dateString(bootstrapAction?.createDate),
                },
                {
                  label: 'Updated by',
                  value: bootstrapAction?.updatedBy,
                  copiable: true,
                },
                {
                  label: 'Update date',
                  value: dateString(bootstrapAction?.updateDate),
                },
              ],
              loading,
            )}
          </ColumnLayout>
        </Container>

        <AuditLogsCard auditLogs={bootstrapAction?.auditLogs} loading={loading} />
      </SpaceBetween>
    </>
  );
};
