import * as React from 'react';
import {
  Button,
  Container,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Wizard,
  Link,
  Flashbar,
  Icon,
} from '@amzn/awsui-components-react-v3';
import { useState, useEffect } from 'react';
import { createWorkspace, getGroupInfo, getSupportedServicesForWorkspace } from 'src/api/permissions';
import { scrollUp } from 'src/components/utils/navigation';
import { Redirect } from 'react-router-dom';
import { createCurrentWSDetailLink, Page } from 'src/routes';
import { isValidAccount } from 'src/commons/validationUtils';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { getRegion } from 'src/api/config';

export interface CreateWorkspaceProps {
  setContentType: any;
  activeGroup: string;
  username: string;
}

export const CreateWorkspace = (props: CreateWorkspaceProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const [accountId, setAccountId] = useState(undefined);
  const [workspaceName, setWorkspaceName] = useState(undefined);
  const region = getRegion();
  const [owner, setOwner] = useState(undefined);
  const [catalogManagerEnabled, setCatalogManagerEnabled] = useState(false);
  const [redshiftManagerEnabled, setRedshiftManagerEnabled] = useState(false);
  const [analyticsManagerEnabled, setAnalyticsManagerEnabled] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [verifyButtonLoading, setVerifyButtonLoading] = useState(undefined);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    setWorkspaceOwner();
  }, []);

  const verifyWorkspacePermissions = async (groupId, accountId, region) => {
    setVerifyButtonLoading(true);
    let getSupportedServicesRequest = {
      groupId: groupId,
      accountId: accountId,
      region: region,
    };
    let response;
    try {
      response = await getSupportedServicesForWorkspace(getSupportedServicesRequest);
      let redshiftStatus = false;
      let catalogManager = false;
      let analyticsManager = false;
      let supportedServices = response.supportedServices;
      for (let service of supportedServices) {
        switch (service) {
          case 'FoundryRedshiftManager':
            redshiftStatus = true;
            break;
          case 'FoundryCatalogManager':
            catalogManager = true;
            break;
          case 'FoundryAnalyticsManager':
            analyticsManager = true;
            break;
        }
      }
      setRedshiftManagerEnabled(redshiftStatus);
      setCatalogManagerEnabled(catalogManager);
      setAnalyticsManagerEnabled(analyticsManager);

      setNotifications([
        {
          type: 'success',
          content: 'Workspace permissions verified',
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setVerifyButtonLoading(false);
    } catch (err) {
      setRedshiftManagerEnabled(false);
      setCatalogManagerEnabled(false);
      setAnalyticsManagerEnabled(false);
      setNotifications([
        {
          type: 'error',
          content: 'Failed to verify workspace. ' + err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setVerifyButtonLoading(false);
      scrollUp();
    }
  };

  const setWorkspaceOwner = async () => {
    const activeGroupInfo = await getGroupInfo({
      groupId: props.activeGroup,
    });
    // setting posix as owner if present else group
    if (activeGroupInfo.groupingMechanismId !== null && !activeGroupInfo.groupingMechanismId.includes('.')) {
      setOwner(activeGroupInfo.groupingMechanismId);
    } else {
      setOwner(props.activeGroup);
    }
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    let createWorkspaceRequest = {
      workspaceName: workspaceName,
      accountId: accountId,
      groupId: props.activeGroup,
      region: region,
      owner: owner,
    };
    let createWorkspaceResponse;
    try {
      createWorkspaceResponse = await createWorkspace(createWorkspaceRequest);
      if (createWorkspaceResponse.succeeded) {
        setNotifications([
          {
            type: 'success',
            content: `Successfully initiated creation of workspace. Track progress..`,
            dismissible: true,
            action: (
              <Button
                onClick={() => setRedirect(createCurrentWSDetailLink(createWorkspaceResponse.workspace.workspaceId))}
              >
                View workspace details
              </Button>
            ),
            onDismiss: () => setNotifications([]),
          },
        ]);
      } else {
        console.log('Failed to create workspace. Reason:', createWorkspaceResponse.message);
        setNotifications([
          {
            type: 'error',
            content: 'Failed to create a workspace. Reason:' + createWorkspaceResponse.message,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      }
      setButtonLoading(false);
    } catch (err) {
      console.log('Exception when creating workspace. ', err);
      setNotifications([
        {
          type: 'error',
          content: `Failed to create workspace. Reason:` + err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setButtonLoading(false);
    }
    scrollUp();
  };

  const handleWizardNavigation = (detail) => {
    if (activeStepIndex == 0 && detail.requestedStepIndex == 1) {
      if (!isValidAccount(accountId)) {
        setNotifications([
          {
            type: 'error',
            content: `Invalid account(should contain 12 digits)`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
        return;
      }
      if (workspaceName == undefined || workspaceName.length === 0) {
        setNotifications([
          {
            type: 'error',
            content: `Workspace name is required`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
        return;
      }
      if (owner == undefined || owner.length === 0) {
        setNotifications([
          {
            type: 'error',
            content: `Owner is required`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
        return;
      }
    }
    if (activeStepIndex == 1 && detail.requestedStepIndex == 2) {
      if (catalogManagerEnabled == false && redshiftManagerEnabled == false && analyticsManagerEnabled == false) {
        setNotifications([
          {
            type: 'error',
            content: `At least one service permission needs to get verified`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
        return;
      }
    }
    setActiveStepIndex(detail.requestedStepIndex);
    setNotifications([]);
  };

  const serviceStatus = (serviceName, successful, description) => {
    if (successful) {
      let label = (
        <div>
          {serviceName}
          <Icon name='status-positive' variant='success' />
        </div>
      );
      return <FormField label={label} description={description}></FormField>;
    } else {
      let label = (
        <div>
          {serviceName}
          <Icon name='status-negative' variant='warning' />
        </div>
      );
      return <FormField label={label} description={description}></FormField>;
    }
  };

  const i18nStrings = {
    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: 'Submit',
    optional: 'optional',
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <Wizard
        activeStepIndex={activeStepIndex}
        steps={[
          {
            title: 'Provide workspace configuration',
            content: (
              <Container header={<Header variant='h2'>Workspace configuration</Header>}>
                <SpaceBetween direction='vertical' size='l'>
                  <FormField label='Account ID' description='Enter a Isengard account ID for your workspace.'>
                    <Input value={accountId} onChange={(e) => setAccountId(e.detail.value)} />
                  </FormField>
                  <FormField label='Name' description='Enter a name for your workspace.'>
                    <Input value={workspaceName} onChange={(e) => setWorkspaceName(e.detail.value)} />
                  </FormField>
                  <FormField label='Region'>
                    <Input placeholder='Workspace region' value={region} disabled />
                  </FormField>
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: 'Verify permissions',
            content: (
              <Container header={<Header variant='h2'>Workspace permission verification</Header>}>
                <SpaceBetween direction='vertical' size='l'>
                  <FormField>
                    <Header
                      variant='h3'
                      actions={
                        <Button
                          onClick={() => verifyWorkspacePermissions(props.activeGroup, accountId, region)}
                          loading={verifyButtonLoading}
                        >
                          Verify
                        </Button>
                      }
                    >
                      Enabled permissions
                    </Header>
                  </FormField>
                  {serviceStatus(
                    'FoundryCatalogManager ',
                    catalogManagerEnabled,
                    'Enables you to perform glue catalog management on Omni',
                  )}
                  {serviceStatus(
                    'FoundryRedshiftManager ',
                    redshiftManagerEnabled,
                    'Enables you to perform redshift management on Omni',
                  )}
                  {serviceStatus(
                    'FoundryAnalyticsManager ',
                    analyticsManagerEnabled,
                    'Enables you to perform analytics on the data through Omni',
                  )}
                  <FormField>
                    Visit{' '}
                    <Link href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry/#HFoundryOnboarding'>
                      this wiki
                    </Link>{' '}
                    to see how to set up{' '}
                    <Link href='https://isengard.amazon.com/policy-templates?filter=%5B%5D'>
                      the Foundry Isengard permissions
                    </Link>{' '}
                    in your{' '}
                    <Link href={`https://isengard.amazon.com/manage-accounts/${accountId}/application-roles`}>
                      account page
                    </Link>{' '}
                    through the Foundry management IAM role.
                  </FormField>
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: 'Review and create',
            content: (
              <Container
                header={
                  <Header
                    variant='h3'
                    actions={<Button onClick={() => setActiveStepIndex(0)}>Edit configuration</Button>}
                  >
                    Review workspace configuration
                  </Header>
                }
              >
                <SpaceBetween direction='vertical' size='l'>
                  <FormField label='Isengard accountId' description='Input Isengard accountId for workspace'>
                    <Input value={accountId} readOnly disabled />
                  </FormField>
                  <FormField label='Workspace name'>
                    <Input value={workspaceName} readOnly disabled />
                  </FormField>
                  <FormField label='Region'>
                    <Input value={region} readOnly disabled />
                  </FormField>
                  <FormField label='Owner'>
                    <Input value={owner} readOnly disabled />
                  </FormField>
                  <FormField label='Services'>
                    {serviceStatus(
                      'FoundryCatalogManager ',
                      catalogManagerEnabled,
                      'Enables you to perform glue catalog management on Omni',
                    )}
                    {serviceStatus(
                      'FoundryRedshiftManager ',
                      redshiftManagerEnabled,
                      'Enables you to perform redshift management on Omni',
                    )}
                    {serviceStatus(
                      'FoundryAnalyticsManager ',
                      analyticsManagerEnabled,
                      'Enables you to perform analytics on the data through Omni',
                    )}
                  </FormField>
                </SpaceBetween>
              </Container>
            ),
          },
        ]}
        onNavigate={({ detail }) => handleWizardNavigation(detail)}
        i18nStrings={i18nStrings}
        onSubmit={handleSubmit}
        isLoadingNextStep={buttonLoading}
        onCancel={() => setRedirect(Page.BROWSE_WORKSPACES)}
      />
    </div>
  );
};
