import React, { useEffect, useCallback, useState } from 'react';
import { Select } from '@amzn/awsui-components-react-v3';
import { useTables } from './use-tables';
import { Database } from 'aws-sdk/clients/glue';
import { HybridCatalogDataSet } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { SelectChange, Option, Options } from './databaseSelectorComponent';

interface TableSelectorProps {
  database: Database | undefined;
  initialSelection?: string;
  activeGroup: string | undefined;
  onChange?: (table: HybridCatalogDataSet | undefined) => void;
}

const optionsFrom = (tables: HybridCatalogDataSet[]): Options => {
  return tables.map((table) => ({
    value: table['Id'],
    label: table['IdInfo'].TableName,
    description: table['DataSetName'] || 'No description available',
  }));
};

const getSelectedOption = (table: HybridCatalogDataSet[], selection: string): Option => {
  const option = table
    .filter((table) => table['IdInfo'].TableName === selection)
    .map((table) => ({
      value: table['Id'],
      label: table['IdInfo'].TableName,
      description: table['DataSetName'] || 'No description available',
    }));
  return option[0];
};

const TableSelector = (props: TableSelectorProps) => {
  const { initialSelection, database } = props;
  const { status, tables } = useTables(database);
  const [selection, setSelection] = useState<Option>(undefined);

  //Update table to "undefined" when the database changes and doesn't exist
  useEffect(() => {
    if (!database) {
      props.onChange(undefined);
      return;
    }

    const selectedOption = getSelectedOption(tables, initialSelection);
    const table = tables.find((table) => table['IdInfo'].TableName === initialSelection);
    setSelection(selectedOption);
    if (props.onChange) props.onChange(table);
  }, [database, tables]);

  const handleChange = useCallback(
    (e: SelectChange) => {
      const selection = e.detail.selectedOption;
      const table = selection ? tables.find((table) => table.Id === selection.value) : undefined;
      setSelection(selection);
      if (props.onChange) props.onChange(table);
    },
    [tables],
  );
  return (
    <Select
      options={optionsFrom(tables)}
      selectedOption={selection}
      placeholder='Choose a table'
      filteringType='auto'
      statusType={status}
      empty={database ? 'No tables available.' : 'Select a database.'}
      loadingText='Loading tables.'
      errorText='Unable to load tables.'
      onChange={handleChange}
    />
  );
};

export default TableSelector;
