export const getDataSource = (resourceDetails) => {
  if (resourceDetails.ClusterIdentifier != null) {
    return 'redshift';
  } else if (resourceDetails.ClusterIdentifier == null && resourceDetails.DataAccessRole == null) {
    return 'glue';
  } else {
    return 'glueLF';
  }
};

export const generateCatalogId = (catalog) => {
  const dataSource = catalog.DataSourceId ? catalog.DataSourceId : getDataSource(catalog);
  if (dataSource == 'redshift') {
    return `DS-${dataSource}|A-${catalog.CatalogId}|CI-${catalog.ClusterIdentifier}|R-${catalog.Region}`;
  } else {
    return `DS-${dataSource}|A-${catalog.CatalogId}|R-${catalog.Region}`;
  }
};

export const generateDatabaseId = (database) => {
  const dataSource = database.DataSourceId ? database.DataSourceId : getDataSource(database);
  if (dataSource == 'redshift') {
    return `DS-${dataSource}|A-${database.CatalogId}|CI-${database.ClusterIdentifier}|DN-${database.DatabaseName}|R-${database.Region}`;
  } else {
    return `DS-${dataSource}|A-${database.CatalogId}|DN-${database.DatabaseName}|R-${database.Region}`;
  }
};

export const generateDatasetId = (dataset) => {
  return dataset.Id;
};

export const generateSchemaId = (schema) => {
  const dataSource = schema.DataSourceId ? schema.DataSourceId : 'redshift';
  return `DS-${dataSource}|A-${schema.CatalogId}|CI-${schema.ClusterIdentifier}|DN-${schema.DatabaseName}|SN-${schema.Schema}|R-${schema.Region}`;
};

export const getDatasetUpperLevelResourceId = (dataset) => {
  let dataSourceId = dataset.IdInfo.DataSourceId.charAt(0).toUpperCase() + dataset.IdInfo.DataSourceId.slice(1);
  return dataSourceId === 'GlueLF'
    ? `DS-glueLF|A-${dataset.IdInfo.CatalogId}|DN-${dataset.IdInfo.DatabaseName}|R-${dataset.IdInfo.Region}`
    : `DS-redshift|A-${dataset.IdInfo.CatalogId}|CI-${dataset.IdInfo.ClusterIdentifier}|DN-${dataset.IdInfo.DatabaseName}|SN-${dataset.IdInfo.SchemaName}|R-${dataset.IdInfo.Region}`;
};
