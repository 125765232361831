import { RegisterStep } from '../steps/1-Register';
import { SourceStep } from '../steps/2-Source';
import { SchemaStep } from '../steps/3-Schema';
import { IngestionStep } from '../steps/4-Ingestion';
import { ReviewStep } from '../steps/5-Review';

import { Wizard, Link } from '@amzn/awsui-components-react-v3';
import { i18nStrings, stepsHeader } from '../utils/content';
import { isSourceS3 } from './validation';
import { Page } from 'src/routes';
import * as React from 'react';

export const TethysWizard = ({
  submit,
  loading,
  request,
  setRequest,
  validationStep,
  setActiveStepIndex,
  activeStepIndex,
  setJsonValid,
  isJsonValid,
  setRedirect,
  toggleHelp,
  nextStep,
  isCreate = false,
  isUpdate = false, // Using 2 booleans (create & update) for when cloning contracts is implemented.
}) => {
  return (
    <Wizard
      isLoadingNextStep={loading}
      i18nStrings={{
        ...i18nStrings,
        submitButton: `${isCreate ? 'Create' : 'Update'} contract`,
      }}
      steps={[
        {
          ...stepsHeader.step1,
          content: (
            <RegisterStep
              isUpdate={isUpdate}
              request={request}
              setRequest={setRequest}
              isValidated={validationStep > 0}
            />
          ),
        },
        {
          ...stepsHeader.step2,
          content: (
            <SourceStep
              isCreate={isCreate}
              request={request}
              setRequest={setRequest}
              isValidated={validationStep > 1}
            />
          ),
        },
        {
          title: stepsHeader.step3.title,
          description: isSourceS3(request) ? stepsHeader.step3.description : stepsHeader.dynamoSchema.description,
          content: (
            <SchemaStep
              request={request}
              isCreate={isCreate}
              isUpdate={isUpdate}
              setRequest={setRequest}
              isJsonValid={isJsonValid}
              setJsonValid={setJsonValid}
              isValidated={validationStep > 2}
            />
          ),
        },
        {
          ...stepsHeader.step4,
          content: (
            <IngestionStep
              isUpdate={isUpdate}
              request={request}
              setRequest={setRequest}
              isValidated={validationStep > 3}
            />
          ),
        },
        {
          ...stepsHeader.step5,
          content: <ReviewStep request={request} setActiveStepIndex={setActiveStepIndex} />,
        },
      ].map((step) => ({
        ...step,
        info: (
          <Link variant='info' onFollow={toggleHelp}>
            Info
          </Link>
        ),
      }))}
      onSubmit={submit}
      activeStepIndex={activeStepIndex}
      onCancel={() => setRedirect(Page.PUBLISHER_TABLES)}
      onNavigate={({ detail }) => nextStep(detail.requestedStepIndex)}
    />
  );
};
