import { iTethysRouteProps } from './types';
import { Redirect } from 'react-router-dom';
import { Page } from 'src/routes/Paths';
import React from 'react';

interface iGotoMonitoring {
  team?: string;
  database?: string;
  arn: string;
}

export const gotoMonitoring = ({ team, database, arn }: iGotoMonitoring) =>
  arn.includes('dataset')
    ? Page.INGESTION.MONITORING_V2.replace(':arn/:database/:dataset', arn)
    : Page.INGESTION.MONITORING.replace(':team', team).replace(':database', database).replace(':arn/:dataset', arn);

export const UpdateRedirect = ({ id }: { id: string }) => (
  <Redirect push to={Page.INGESTION.UPDATE_CONTRACT.replace(':arn/:dataset', id)} />
);

export const createIngestedDatasetDetailPath = ({ arn, dataset, database }: iTethysRouteProps) =>
  arn.includes('dataset')
    ? Page.INGESTION.DETAIL_V2.replace(':arn/:database/:dataset', `${arn}/${database}/${dataset}`)
    : Page.INGESTION.DETAIL.replace(':arn/:dataset', `${arn}/${dataset}`);
