import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert, AlertProps } from '@amzn/awsui-components-react-v3';
import { describeTarget, deleteTarget, checkConnectivity } from 'src/api/subscriptions';
import { PageHeader, ConnectionDetailsComponent } from '../subscriptions/common';
import { ConfirmModal } from '../utils/modal';
import { Page } from 'src/routes';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface ConnectionDetailsProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}

export const ConnectionDetails = (props: ConnectionDetailsProps) => {
  const [target, setTarget] = useState({});
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('success' as AlertProps.Type);
  const [actionLoading, setActionLoading] = useState(false);
  const [, setDeleteLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteText, setDeleteText] = useState(undefined);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup]);

  const checkConnection = async () => {
    try {
      await checkConnectivity({
        database: target['defaultDatabase'],
        iamRole: target['IamRole'],
        secret: target['secret'],
        region: target['region'],
      });
      setAlertType('success');
      setAlertVisible(true);
      setAlertMessage('The connectivity check was successful.');
    } catch (err) {
      setAlertType('error');
      setAlertVisible(true);
      setAlertMessage(err.message);
      console.log(err.message);
    }
  };

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    try {
      const describe = await describeTarget({
        ownerId: props.activeGroup,
        targetId: props.match.params.id,
      });
      setTarget(describe.target);
    } catch (err) {
      setTarget(null);
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    await deleteTarget({
      ownerId: props.activeGroup,
      targetId: props.match.params.id,
    });
    setDeleteLoading(false);
    setRedirect(Page.CONNECTIONS);
  };

  const handleAction = async (e) => {
    if (e.detail.id === 'connectivity') {
      setActionLoading(true);
      await checkConnection();
      await handleRefresh();
      setActionLoading(false);
    }
    if (e.detail.id === 'delete') {
      setDeleteText(`Connection ${props.match.params.id}`);
      setModalVisible(true);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  if (redirect) return <Redirect push to={redirect} />;

  if (!target) {
    return (
      <>
        <h2>Connection not found</h2>
        The given connection ID {props.match.params.id} is not valid, or you do not have permission to view it. Please
        check the URL for mistakes and try again.
      </>
    );
  }

  return (
    <>
      <ConfirmModal
        visible={modalVisible}
        onClose={closeModal}
        onConfirm={handleDelete}
        variant={'delete'}
        content={deleteText}
      ></ConfirmModal>
      <div style={{ marginBottom: '10px' }}>
        <Alert type={alertType} dismissible={true} visible={alertVisible} onDismiss={() => setAlertVisible(false)}>
          {alertMessage}
        </Alert>
      </div>
      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            onItemClick: handleRefresh,
          },
          {
            text: 'Actions',
            onItemClick: handleAction,
            items: [
              {
                text: 'Check connectivity',
                id: 'connectivity',
              },
              {
                text: 'Delete',
                id: 'delete',
              },
            ],
            loading: actionLoading,
          },
        ]}
        header={`${props.match.params.id}`}
      />
      <ConnectionDetailsComponent target={target} />
    </>
  );
};
