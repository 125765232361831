import * as React from 'react';
import { ColumnLayout, Container, Header, SpaceBetween, Box, Button, Modal } from '@amzn/awsui-components-react-v3';
import { useState, useEffect } from 'react';

import { getTemplate, deleteTemplate } from 'src/api/resourcesmanager';
import { LabeledText, labeledTextFromList, RMPageHeader, ErrorAlert } from '../components';
import { Redirect } from 'react-router-dom';
import { Page } from 'src/routes';
import { dateString, pop } from '../helpers';
import { CARDS_CONTENT_TYPE } from 'src/commons/constants';

export interface templateDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}

export const TemplateDetails = (props: templateDetailProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [template, setTemplate] = useState(null);
  const [redirect, setRedirect] = useState(undefined);
  const [redirectParams, setRedirectParams] = useState({});
  const [guardrail, setGuardrail] = useState(null);

  useEffect(() => {
    props.setContentType(CARDS_CONTENT_TYPE);
    handleRefresh();
  }, [props.activeGroup]);

  /**
   * Asynchronously fetches the templates from the RM API and updates the state.
   */
  const handleRefresh = async () => {
    setLoading(true);
    try {
      const template = await getTemplate({
        id: props.match.params.id,
      });
      try {
        template.templateJsonString = JSON.stringify(JSON.parse(template.templateJsonString), null, 2);
      } catch (err) {
        //Do nothing
        console.log('Quiet JSON parse error for JSON', template.templateJsonString);
      }
      setTemplate(template);
      setLoading(false);
    } catch (err) {
      setError({ ...err, while: 'LOADING TEMPLATE' });
      setTemplate(null);
    }
  };

  /**
   * [UNGUARDED]: Asynchronously calls the DELETE API for the given Template.
   *
   * @param {Event} e - The event which triggered the functin call, if it exists.
   */
  const deleteCT_UNGUARDED = async () => {
    try {
      const response = await deleteTemplate({
        id: props.match.params.id,
      });

      if (response.successful) {
        setRedirect(Page.RESOURCEGROUPS);
      } else {
        setError({
          ...response,
          code: 'ResponseNotSuccessful',
          while: 'DELETING TEMPLATE',
        });
      }
    } catch (err) {
      console.error(err);
      setError({ ...err, while: 'DELETING TEMPLATE' });
    }
  };

  /**
   * A wrapper which requires the user to confirm before submitting a DeleteTemplate request to the API.
   *
   * @param {Event} e - The event which triggered the functin call, if it exists.
   */
  const deleteCT = async () => {
    setGuardrail({
      header: `DELETE ${props.match.params.id}?`,
      action: deleteCT_UNGUARDED,
    });
  };

  /**
   * Redirects the user to the Update Template page.
   */
  const updateCT = () => {
    setRedirect(Page.UPDATE_RG_TEMPLATE.replace(':id', props.match.params.id));
  };

  /**
   * Redirects the user to the Create Template page, prefilled with the configuration of the current Template.
   */
  const cloneCT = () => {
    setRedirect(Page.CREATE_RG_TEMPLATE);
    setRedirectParams({ values: template });
  };

  const createResourceFromCT = () => {
    if (template.type === 'EMR_GROUP') {
      setRedirectParams({
        values: JSON.parse(template.templateJsonString),
      });
      setRedirect(Page.CREATE_RESOURCEGROUP);
    } else {
      setError({
        while: 'ATTEMPTING TO CREATE RESOURCE FROM TEMPLATE',
        code: 'custom_error',
        message: `Attempted to create resource from Template. The Type ${template.type} is not supported`,
      });
    }
  };

  const Guardrail_UNBOUND = () => {
    if (guardrail) {
      var props = new Object({ ...guardrail });
      props['header'] = props['header'] || 'PLEASE CONFIRM ACTION:';
      props['closeAriaLabel'] = props['closeAriaLabel'] || 'Close modal';
      props['visible'] = typeof props['visible'] !== 'undefined' ? props['visible'] : true;
      props['onDismiss'] = () => setGuardrail(null);

      const content = pop(
        props,
        'content',
        [
          'Are you sure you want to continue with the above action?',
          'If so, please click "OK" to proceed.',
          'Otherwise close this box by clicking "Cancel" below or "X" above.',
        ].map((t) => <p>{t}</p>),
      );
      const action = pop(props, 'action', (e) => console.error('No Action Defined. Event: ', e));

      props['footer'] = (
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => setGuardrail(null)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={action}>
              OK
            </Button>
          </SpaceBetween>
        </Box>
      );

      return (
        <Modal visible={props['visible']} {...props}>
          {content}
        </Modal>
      );
    } else {
      return [];
    }
  };

  return (
    <>
      {redirect && (
        <Redirect
          push
          to={{
            pathname: redirect,
            state: redirectParams,
          }}
        />
      )}
      {ErrorAlert(error)}
      {Guardrail_UNBOUND()}

      <SpaceBetween size='m'>
        <RMPageHeader
          buttons={[
            {
              text: 'Delete',
              onItemClick: deleteCT,
            },
            {
              text: 'Edit',
              onItemClick: updateCT,
            },
            {
              text: 'Clone',
              onItemClick: cloneCT,
            },
            {
              text: 'Create',
              onItemClick: createResourceFromCT,
              variant: 'primary',
            },
          ]}
          subheader={template?.name}
        />
        <Container header={<Header variant='h2'> Template Details </Header>}>
          <ColumnLayout columns={2} borders='horizontal'>
            {labeledTextFromList(
              [
                {
                  label: 'Template ID',
                  value: template?.id,
                  copiable: true,
                },
                {
                  label: 'Name',
                  value: template?.name,
                },
                {
                  label: 'Owner (Group ID)',
                  value: template?.groupId,
                  copiable: true,
                },
                {
                  label: 'Description',
                  value: template?.description,
                },
                {
                  label: 'Created By',
                  value: template?.createdBy,
                  copiable: true,
                },
                {
                  label: 'Create Date',
                  value: dateString(template?.createDate),
                },
                {
                  label: 'Updated By',
                  value: template?.updatedBy,
                  copiable: true,
                },
                {
                  label: 'Update Date',
                  value: dateString(template?.updateDate),
                },
              ],
              loading,
            )}
          </ColumnLayout>
          <pre>
            <LabeledText label={'Template JSON'} value={template?.templateJsonString} loading={loading} copiable />
          </pre>
          <br />
        </Container>
      </SpaceBetween>
    </>
  );
};
