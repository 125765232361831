import { getRegion, getStage, METRIC_STORE_STAGE_API_ENDPOINT_MAP } from 'src/api/config';
import { MetricStore } from '@amzn/metric-store-typescript-client';
import {
  GetMetricMetadataByIdRequest,
  GetMetricMetadataByIdResponse,
  ListAllMetricMetadataRequest,
  ListAllMetricMetadataResponse,
  ListAllMetricCollectionsRequest,
  ListAllMetricCollectionsResponse,
  ListAllReportingTablesRequest,
  ListAllReportingTablesResponse,
  GetMetricCollectionByIdRequest,
  GetMetricCollectionByIdResponse,
  GetReportingTableByIdRequest,
  GetReportingTableByIdResponse,
  GetMetricInput,
  GetMetricOutput,
} from '@amzn/metric-store-typescript-client/clients/metricstore';
import basicCallWrapper from 'src/api/client_call';
import { Credentials, CredentialsOptions } from 'aws-sdk/lib/credentials';

let metricStoreClient: MetricStore = new MetricStore();
export async function initMetricStoreClient(credentials: Credentials | CredentialsOptions | null) {
  const metricStoreClientConfig = {
    region: getRegion(),
    endpoint: METRIC_STORE_STAGE_API_ENDPOINT_MAP[getStage()],
    credentials: credentials,
  };
  metricStoreClient = new MetricStore(metricStoreClientConfig);
}

export const getMetricMetadataById = (input: GetMetricMetadataByIdRequest) => {
  return basicCallWrapper<GetMetricMetadataByIdRequest, GetMetricMetadataByIdResponse>(
    metricStoreClient,
    metricStoreClient.getMetricMetadataById,
  )(input);
};

export const getReportingTableById = (input: GetReportingTableByIdRequest) => {
  return basicCallWrapper<GetReportingTableByIdRequest, GetReportingTableByIdResponse>(
    metricStoreClient,
    metricStoreClient.getReportingTableById,
  )(input);
};

export const getMetricCollectionById = (input: GetMetricCollectionByIdRequest) => {
  return basicCallWrapper<GetMetricCollectionByIdRequest, GetMetricCollectionByIdResponse>(
    metricStoreClient,
    metricStoreClient.getMetricCollectionById,
  )(input);
};

export const listAllMetricMetadata = (input: ListAllMetricMetadataRequest) => {
  return basicCallWrapper<ListAllMetricMetadataRequest, ListAllMetricMetadataResponse>(
    metricStoreClient,
    metricStoreClient.listAllMetricMetadata,
  )(input);
};

export const listAllReportingTables = (input: ListAllReportingTablesRequest) => {
  return basicCallWrapper<ListAllReportingTablesRequest, ListAllReportingTablesResponse>(
    metricStoreClient,
    metricStoreClient.listAllReportingTables,
  )(input);
};

export const listAllMetricCollections = (input: ListAllMetricCollectionsRequest) => {
  return basicCallWrapper<ListAllMetricCollectionsRequest, ListAllMetricCollectionsResponse>(
    metricStoreClient,
    metricStoreClient.listAllMetricCollections,
  )(input);
};

export const getMetric = (input: GetMetricInput) => {
  return basicCallWrapper<GetMetricInput, GetMetricOutput>(metricStoreClient, metricStoreClient.getMetric)(input);
};
