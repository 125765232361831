import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  activeGroupOrWorkspaceId,
  CC_EMAIL,
  ConfirmCancelModal,
  CTI,
  EMAIL,
  notificationsToFlashbar,
  OMNI,
  safelyRenderedAdvisoryMarkdownContent,
  SLACK,
} from 'src/components/dataadvisory/constants';
import {
  AdvisoryContent,
  AdvisoryCustomerType,
  GetAdvisoryResponse,
  SendNotificationRequest,
  SendNotificationResponse,
} from 'aws-sdk/clients/awsdlomni';
import {
  Alert,
  Button,
  Container,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Spinner,
  Textarea,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import { Checkbox } from '@amzn/awsui-components-react-v3/polaris';
import { Redirect } from 'react-router-dom';
import { getAdvisory, sendNotification, updateAdvisory } from 'src/api/notifications';
import { createAdvisoryDetailsLink } from 'src/routes';

export interface SendNotificationPageProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
}

export const SendNotificationPage = (props: SendNotificationPageProps) => {
  const [advisory, setAdvisory] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [redirect, setRedirect] = useState(undefined);
  const [title, setTitle] = useState('');
  const [rawContent, setRawContent] = useState('');
  const [contentFormat, setContentFormat] = useState('PlainText');

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const [sending, setSending] = useState(false);
  const [updateAdvisoryChecked, setUpdateAdvisoryChecked] = useState(true);

  const [emails, setEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);
  const [selectedCcEmails, setSelectedCcEmails] = useState([]);
  const [slacks, setSlacks] = useState([]);
  const [selectedSlacks, setSelectedSlacks] = useState([]);
  const [ctis, setCtis] = useState([]);
  const [selectedCtis, setSelectedCtis] = useState([]);
  const [groupsAndWorkspaces, setGroupsAndWorkspaces] = useState([]);
  const [selectedGroupsAndWorkspaces, setSelectedGroupsAndWorkspaces] = useState([]);

  useEffect(() => {
    props.setContentType('form');
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const advisoryId = props.match.params.id;
      const response: GetAdvisoryResponse = await getAdvisory({
        advisoryId: advisoryId,
      });
      console.log(response);
      await setAdvisory(response.advisory);
      setTitle(response.advisory.title);
      setRawContent(response.advisory.content.rawContent);
      setContentFormat(response.advisory.content.format);
      setSelectedCustomers(response.advisory.customers);

      const emailOptions = response.advisory.customers
        .filter((customer) => customer.emailAddress != undefined)
        .filter((customer) => customer.type != CC_EMAIL)
        .map((customer) => customer.emailAddress);
      const ccEmailOptions = response.advisory.customers
        .filter((customer) => customer.emailAddress != undefined)
        .filter((customer) => customer.type == CC_EMAIL)
        .map((customer) => customer.emailAddress);
      const slackOptions = response.advisory.customers
        .filter((customer) => customer.slackChannel != undefined)
        .map((customer) => customer.slackChannel);
      const ctiOptions = response.advisory.customers
        .filter((customer) => customer.cti != undefined)
        .map((customer) => customer.cti);
      const omniOptions = response.advisory.customers
        .filter((customer) => customer.groupOrWorkspaceId != undefined)
        .map((customer) => customer.groupOrWorkspaceId);

      setEmails(emailOptions);
      setSelectedEmails(emailOptions);
      setCcEmails(ccEmailOptions);
      setSelectedCcEmails(ccEmailOptions);
      setSlacks(slackOptions);
      setSelectedSlacks(slackOptions);
      setCtis(ctiOptions);
      setSelectedCtis(ctiOptions);
      setGroupsAndWorkspaces(omniOptions);
      setSelectedGroupsAndWorkspaces(omniOptions);
    } catch (e) {
      setFailedToLoad(true);
    }
    setLoading(false);
  };

  const send = async () => {
    setSending(true);
    try {
      // 1. send the notification
      const content: AdvisoryContent = {
        rawContent: rawContent,
        format: contentFormat,
      };
      let request: SendNotificationRequest = {
        title: title,
        content: content,
        senderGroupOrWorkspace: activeGroupOrWorkspaceId(props),
        advisoryConfig: {
          advisoryId: props.match.params.id,
        },
      };
      if (selectedEmails.length > 0 || selectedCcEmails.length > 0) {
        request.emailConfig = {
          emailAddresses: selectedEmails,
          ccEmailAddresses: selectedCcEmails,
        };
      }
      if (selectedGroupsAndWorkspaces.length > 0) {
        request.omniNotificationConfig = {
          recipientGroupsAndWorkspaces: selectedGroupsAndWorkspaces,
          priority: 'MEDIUM',
        };
      }
      if (selectedSlacks.length > 0) {
        request.slackConfig = {
          slackChannels: selectedSlacks,
        };
      }
      if (selectedCtis.length > 0) {
        request.ticketConfig = {
          ctis: selectedCtis,
          severity: '4',
          tags: [],
        };
      }
      const response: SendNotificationResponse = await sendNotification(request);
      if (response.failedSends.length > 0) {
        setNotificationForPartialFailedSend(response);
      } else {
        setNotificationForSuccessfulSend();
      }

      // 2. update the advisory if checked
      if (updateAdvisoryChecked) {
        try {
          await updateAdvisory({
            advisoryId: advisory.advisoryId,
            title: title,
            content: content,
          });
        } catch (e) {
          setNotificationForFailedAdvisoryUpdate(e);
        }
      }
    } catch (e) {
      setNotificationForFullFailedSend(e);
    }
    setSending(false);
  };

  const setNotificationForPartialFailedSend = (response: SendNotificationResponse) => {
    const numFailedSends = response.failedSends.length;
    const numSuccessfulSends = response.successfulSends.length;
    const totalSends = numFailedSends + numSuccessfulSends;
    setNotifications([
      {
        type: 'error',
        // example message:

        // Failed to send to 6 of 10 recipients.
        // - email1@amazon.com (Email): could not verify email address
        // - AWSDL_Services (Omni): insufficient permissions
        // - email2@amazon.com (Email): unknown error
        // - AWS/DataGovernanceServices/Issues (CTI): ticket cutting is admin-only
        // - email2@amazon.com (Email): unknown error
        // And 1 more (truncated).
        // Successfully sent to 4 other recipients.
        content: (
          <>
            Failed to send to {numFailedSends} of {totalSends} {totalSends > 1 ? 'recipients' : 'recipient'}.
            <ul>
              {response.failedSends.slice(0, 5).map((failedSendObject) => (
                <li>{failedSendObject.value + ' (' + failedSendObject.type + '): ' + failedSendObject.message}</li>
              ))}
            </ul>
            {numFailedSends >= 6 && <p>And {numFailedSends - 5} more (truncated).</p>}
            {numSuccessfulSends > 0 && (
              <p>
                Successfully sent to {numSuccessfulSends} other {totalSends > 1 ? 'recipients' : 'recipient'}.
              </p>
            )}
          </>
        ),
      },
    ]);
  };

  const setNotificationForFullFailedSend = (e) => {
    setNotifications([
      {
        type: 'error',
        content: 'Failed to send the notifications: ' + e.message,
      },
    ]);
  };

  const setNotificationForSuccessfulSend = () => {
    setNotifications([
      {
        type: 'success',
        content: 'Successfully sent the notifications.',
      },
    ]);
  };

  const setNotificationForFailedAdvisoryUpdate = (e) => {
    setNotifications([
      ...notifications,
      {
        type: 'error',
        content: 'Failed to update the advisory: ' + e.message,
      },
    ]);
  };

  const cancel = () => {
    if (hasUnsavedChanges()) {
      setCancelModalVisible(true);
    } else {
      redirectToAdvisoryDetailsPage();
    }
  };
  const redirectToAdvisoryDetailsPage = () => {
    setRedirect(createAdvisoryDetailsLink(advisory.advisoryId));
  };

  const hasUnsavedChanges = () => {
    return rawContent != advisory.content.rawContent || title != advisory.title;
  };

  if (loading) {
    return <Spinner />;
  }
  if (failedToLoad) {
    return (
      <Alert type={'error'} statusIconAriaLabel={'Error'} header={'Unable to load advisory'}>
        Please double-check the advisory ID in the URL and try again.
      </Alert>
    );
  }

  return (
    <SpaceBetween size={'m'}>
      {redirect && <Redirect to={redirect} />}
      {notifications.length > 0 && notificationsToFlashbar(notifications, setNotifications)}
      <ConfirmCancelModal
        onYes={redirectToAdvisoryDetailsPage}
        onNo={() => setCancelModalVisible(false)}
        visible={cancelModalVisible}
      />
      <Form
        header={
          <Header variant={'h1'} description={'Use this form to send a notification to the customers of the advisory.'}>
            Send advisory notification
          </Header>
        }
        actions={
          <SpaceBetween size={'s'} direction={'horizontal'}>
            <Button variant={'link'} onClick={cancel}>
              Cancel
            </Button>
            <Button variant={'primary'} onClick={send} disabled={selectedCustomers.length == 0} loading={sending}>
              Send
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size={'s'} direction={'vertical'}>
          <FormField label={'Title'} description={'The subject line for the notification.'}>
            <Input value={title} onChange={(e) => setTitle(e.detail.value)} />
          </FormField>
          <FormField
            label={'Message content'}
            description={
              'The content for the notification, to be converted to text or HTML depending on whether Markdown is selected.'
            }
          >
            <Textarea value={rawContent} onChange={(e) => setRawContent(e.detail.value)} rows={16} />
            <Toggle
              checked={contentFormat == 'Markdown'}
              onChange={(e) => setContentFormat(e.detail.checked ? 'Markdown' : 'PlainText')}
            >
              Markdown
            </Toggle>
          </FormField>
          <Checkbox
            checked={updateAdvisoryChecked}
            description={
              'If you make changes to the title and content here, check this box to save those changes in the advisory itself.'
            }
            onChange={(e) => setUpdateAdvisoryChecked(e.detail.checked)}
          >
            Update advisory with new title and content
          </Checkbox>
          {contentFormat && (
            <>
              <FormField
                label={'Message preview'}
                description={
                  'This is how the Markdown message will appear in emails and tickets. Slack messages do not support the same Markdown flavor, so they will just provide a link to view the advisory in Omni.'
                }
              >
                <Container>{safelyRenderedAdvisoryMarkdownContent(rawContent)}</Container>
              </FormField>
            </>
          )}
          {emails.length > 0 && (
            <RecipientSelector
              options={emails}
              selectedOptions={selectedEmails}
              setSelectedOptions={setSelectedEmails}
              type={EMAIL}
            />
          )}
          {ccEmails.length > 0 && (
            <RecipientSelector
              options={ccEmails}
              selectedOptions={selectedCcEmails}
              setSelectedOptions={setSelectedCcEmails}
              type={CC_EMAIL}
            />
          )}
          {slacks.length > 0 && (
            <RecipientSelector
              options={slacks}
              selectedOptions={selectedSlacks}
              setSelectedOptions={setSelectedSlacks}
              type={SLACK}
            />
          )}
          {ctis.length > 0 && (
            <RecipientSelector
              options={ctis}
              selectedOptions={selectedCtis}
              setSelectedOptions={setSelectedCtis}
              type={CTI}
            />
          )}
          {groupsAndWorkspaces.length > 0 && (
            <RecipientSelector
              options={groupsAndWorkspaces}
              selectedOptions={selectedGroupsAndWorkspaces}
              setSelectedOptions={setSelectedGroupsAndWorkspaces}
              type={OMNI}
            />
          )}
        </SpaceBetween>
      </Form>
    </SpaceBetween>
  );
};

export interface RecipientSelectorProps {
  options: string[];
  selectedOptions: string[];
  setSelectedOptions: any;
  type: AdvisoryCustomerType;
}

export const RecipientSelector = (props: RecipientSelectorProps) => {
  const EMAIL_LABEL = 'Email recipients';
  const CC_EMAIL_LABEL = 'Email CC recipients';
  const SLACK_LABEL = 'Slack recipients';
  const OMNI_LABEL = 'Omni recipients';
  const TICKET_LABEL = 'Ticket recipients';
  const DEFAULT_LABEL = 'Recipients';
  const EMAIL_DESCRIPTION =
    'Select email addresses to send this notification to. To add a new email address, add a new customer in the Edit Advisory page.';
  const CC_EMAIL_DESCRIPTION =
    'Select email addresses add as CC. To add a new email address, add a new customer in the Edit Advisory page.';
  const SLACK_DESCRIPTION =
    'Select Slack channels to send this message to. To add a new channel, add a new customer in the Edit Advisory page.';
  const OMNI_DESCRIPTION =
    'Select Omni groups and workspaces to send an Omni Notification to. To add a new recipient, add a new customer in the Edit Advisory page.';
  const TICKET_DESCRIPTION =
    'Select CTIs to cut a ticket to. To add a new CTI, add a new customer in the Edit Advisory page.';
  const DEFAULT_DESCRIPTION = 'Select recipients to send this notification to.';

  const getLabel = () => {
    if (props.type == EMAIL) {
      return EMAIL_LABEL;
    }
    if (props.type == CC_EMAIL) {
      return CC_EMAIL_LABEL;
    }
    if (props.type == SLACK) {
      return SLACK_LABEL;
    }
    if (props.type == OMNI) {
      return OMNI_LABEL;
    }
    if (props.type == CTI) {
      return TICKET_LABEL;
    }
    return DEFAULT_LABEL;
  };

  const getDescription = () => {
    if (props.type == EMAIL) {
      return EMAIL_DESCRIPTION;
    }
    if (props.type == CC_EMAIL) {
      return CC_EMAIL_DESCRIPTION;
    }
    if (props.type == SLACK) {
      return SLACK_DESCRIPTION;
    }
    if (props.type == OMNI) {
      return OMNI_DESCRIPTION;
    }
    if (props.type == CTI) {
      return TICKET_DESCRIPTION;
    }
    return DEFAULT_DESCRIPTION;
  };

  const setOptionSelected = async (option: string, selected: boolean) => {
    if (selected && !props.selectedOptions.includes(option)) {
      const newSelectedOptions = [...props.selectedOptions, option];
      props.setSelectedOptions(newSelectedOptions);
    }
    if (!selected && props.selectedOptions.includes(option)) {
      const newSelectedOptions = props.selectedOptions.filter((opt) => opt != option);
      props.setSelectedOptions(newSelectedOptions);
    }
  };

  const setAllSelected = async (selected: boolean) => {
    let newSelectedOptions = [];
    if (selected) {
      newSelectedOptions = [...props.options];
    }
    props.setSelectedOptions(newSelectedOptions);
  };

  const optionIsSelected = (option: string) => {
    return props.selectedOptions.includes(option);
  };

  if (props.options.length == 0) {
    return <></>;
  }

  return (
    <FormField label={getLabel()} description={getDescription()}>
      <SpaceBetween size={'s'} direction={'vertical'}>
        <SpaceBetween size={'xs'} direction={'vertical'}>
          {props.options.map((option) => (
            <Checkbox
              checked={optionIsSelected(option)}
              onChange={(e) => {
                setOptionSelected(option, e.detail.checked);
              }}
            >
              {option}
            </Checkbox>
          ))}
        </SpaceBetween>
        <SpaceBetween size={'s'} direction={'horizontal'}>
          <Button variant={'link'} onClick={() => setAllSelected(true)}>
            Select all
          </Button>
          <Button variant={'link'} onClick={() => setAllSelected(false)}>
            Deselect all
          </Button>
        </SpaceBetween>
      </SpaceBetween>
    </FormField>
  );
};
