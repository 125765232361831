import { Container, Header, SpaceBetween, Table, TableProps } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import { TABLE_CONTENT_TYPE, TEMPLATE_NONE, TEMPLATE_TYPE_ACCESS_MANAGEMENT } from 'src/commons/constants';
import { Toggle } from '@amzn/awsui-components-react';
import { EmptyState } from 'src/commons/EmptyState';

import { Link } from 'react-router-dom';
import { listTemplates } from 'src/api/permissions';
import { createTemplateDetailsLink, Page } from 'src/routes';
import { Button } from '@amzn/awsui-components-react-v3/polaris';
import { PageHeader } from 'src/components/notifications/common';

export interface AccessTemplatesProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  registerItems: any[];
  selectedDatabaseName: string;
  catalogName: string;
  setNotifications: any;
  associateTemplate: boolean;
  setAssociateTemplate: any;
  setSelectedTemplate: any;
}

export const AccessTemplates = (props: AccessTemplatesProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResource, setLoadingResource] = useState(false);
  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'templateName',
      header: 'Name',
      cell: (item) => <Link to={createTemplateDetailsLink(item.templateId, item.resourceId)}>{item.name.split()}</Link>,
      minWidth: 200,
      sortingField: 'templateName',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: 200,
    },
    {
      id: 'simTemplateLink',
      header: 'SIM template link',
      cell: (item) =>
        item.accessManagementInfo?.simTemplates?.[0]?.simTemplateLink ? (
          <a href={item.accessManagementInfo?.simTemplates?.[0]?.simTemplateLink} target='_blank'>
            {'Sim template link'}
          </a>
        ) : (
          'None'
        ),
      minWidth: 200,
    },
    {
      id: 'approvalTemplateLink',
      header: 'Approval template link',
      cell: (item) =>
        item.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink ? (
          <a href={item.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink} target='_blank'>
            {'Approval template link'}
          </a>
        ) : (
          'None'
        ),
      minWidth: 200,
    },
  ];

  const handleRefresh = async () => {
    setLoadingResource(true);
    try {
      let templates = [];
      let ownerId = props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup;
      const listTemplatesResult = await listTemplates({
        ownerId: ownerId,
        templateType: TEMPLATE_TYPE_ACCESS_MANAGEMENT,
      });
      templates = listTemplatesResult.templates;
      setItems(templates.filter((t) => t.resourceId == TEMPLATE_NONE));
    } catch (err) {
      props.setNotifications([
        {
          type: 'error',
          content: `Unable to load templates. ${err.message}`,
          dismissible: true,
          onDismiss: () => props.setNotifications([]),
        },
      ]);
    } finally {
      setLoadingResource(false);
    }
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh().catch(console.error);
  }, []);

  const { items, collectionProps } = useCollection(allItems, {
    filtering: {},
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  useEffect(() => {
    const { selectedItems } = collectionProps;
    props.setSelectedTemplate(selectedItems?.[0]);
  }, [collectionProps.selectedItems]);

  return (
    <>
      <Container
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
              ]}
              header={
                <Header
                  variant='h2'
                  description={
                    'Associate template to all datasets to register. This can be also done individually after registration. This is required for all PII datasets.'
                  }
                >
                  Templates
                </Header>
              }
            />
          </>
        }
      >
        <SpaceBetween size='l' direction={'vertical'}>
          <Toggle
            onChange={({ detail }) => props.setAssociateTemplate(detail.checked)}
            checked={props.associateTemplate}
          >
            Associate an access template to the datasets
          </Toggle>

          {props.associateTemplate && (
            <Table
              {...collectionProps}
              loadingText='Loading templates...'
              loading={loadingResource}
              columnDefinitions={columnDefinitions}
              items={items}
              resizableColumns={true}
              selectionType={'single'}
              header={
                <Header variant='h3' description={'Select the template to associate with all the datasets.'}></Header>
              }
              empty={
                <EmptyState
                  title='No templates'
                  subtitle='No templates to display.'
                  action={<Button onClick={() => window.open(Page.CREATE_TEMPLATE)}>Create template</Button>}
                />
              }
            />
          )}
        </SpaceBetween>
      </Container>
    </>
  );
};
