import React, { useState } from 'react';
import { Tabs } from '@amzn/awsui-components-react-v3';
import { InboxNotifications } from 'src/components/notifications/notificationTabs/inboxNotifications';
import { ArchivedNotifications } from 'src/components/notifications/notificationTabs/archivedNotifications';
import { SentNotifications } from 'src/components/notifications/notificationTabs/sentNotifications';
import { NotificationDetail } from 'src/components/notifications/detail';

export interface MyNotificationsProps {
  activeWorkspace: any;
  activeGroup: string | undefined;
  username: string | undefined;
  tabId: string | undefined;
  setContentType: any;
  refreshUnReadNum: any;
  setActiveTabId: any;
  refreshNotificationNum: any;
  refreshUnreadNotifications: any;
  refreshUnreadNotificationBatch: any;
  workspacesForGroups: any;
}

export const MyNotifications = (props: MyNotificationsProps) => {
  const [activeTabId, setActiveTabId] = useState('inbox');
  const [selectedNotificationInfo, setSelectedNotificationInfo] = useState({});
  const [selectedNotificationBatchInfo, setSelectedNotificationBatchInfo] = useState({});

  const handleChange = async (e) => {
    setSelectedNotificationInfo({});
    setSelectedNotificationBatchInfo({});
    await setActiveTabId(e.detail.activeTabId);
  };

  const tabs = [
    {
      label: 'Inbox',
      id: 'inbox',
      content: (
        <InboxNotifications
          {...props}
          setSelectedNotification={setSelectedNotificationInfo}
          setActiveTabId={setActiveTabId}
        />
      ),
    },
    {
      label: 'Archive',
      id: 'archive',
      content: (
        <ArchivedNotifications
          {...props}
          setSelectedNotification={setSelectedNotificationInfo}
          setActiveTabId={setActiveTabId}
        />
      ),
    },
    {
      label: 'Sent',
      id: 'sent',
      content: (
        <SentNotifications
          {...props}
          setSelectedNotificationBatch={setSelectedNotificationBatchInfo}
          setActiveTabId={setActiveTabId}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs tabs={tabs} variant='default' onChange={handleChange} activeTabId={activeTabId} />
      {selectedNotificationInfo['selectedNotificationId'] && (
        <>
          <div>
            <NotificationDetail
              setContentType={props.setContentType}
              activeGroup={props.activeGroup}
              activeWorkspace={props.activeWorkspace}
              username={props.username}
              selectedNotificationInfo={selectedNotificationInfo}
              activeTabId={activeTabId}
            />
          </div>
        </>
      )}

      {selectedNotificationBatchInfo['selectedNotificationBatchId'] && (
        <>
          <div>
            <NotificationDetail
              setContentType={props.setContentType}
              activeGroup={props.activeGroup}
              activeWorkspace={props.activeWorkspace}
              username={props.username}
              selectedNotificationInfo={selectedNotificationBatchInfo}
              activeTabId={activeTabId}
            />
          </div>
        </>
      )}
    </>
  );
};
