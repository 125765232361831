import * as React from 'react';
import { useState, useEffect } from 'react';

import { mediumPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
} from '@amzn/awsui-components-react-v3';

import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

import { listBootstrapActions } from 'src/api/resourcesmanager';
import { Link, Redirect } from 'react-router-dom';
import { RMPageHeader, ErrorAlert, s3url } from '../components';

import { UnauthorizedModal } from '../components-V3';

import { Page } from 'src/routes';
import { BootstrapAction } from 'aws-sdk/clients/awsdatalakeresourcesmanagerlambda';
import { EmptyState } from 'src/commons/EmptyState';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface MyBootstrapActionsProps {
  setContentType: any;
  activeGroup: string;
  userInfo: any;
}

export const MyBootstrapActions = (props: MyBootstrapActionsProps) => {
  const [error, setError] = useState(undefined);
  const [allItems, setItems] = useState([]);
  const [loadingBootstrapActions, setLoadingBootstrapActions] = useState(false);
  const [redirectParams] = useState(null);
  const [redirect, setRedirect] = useState(undefined);
  const [tableMessage] = useState('No bootstrap actions');
  const [onboardModal, setOnboardModal] = useState(false);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const columnDefinitions: TableProps.ColumnDefinition<BootstrapAction>[] = [
    {
      id: 'bootstrapActionId',
      header: 'Bootstrap action ID',
      cell: (item) => <Link to={`bootstrapactions/${item.id}`}>{item.id}</Link>,
      minWidth: 225,
    },
    {
      id: 'bootstrapActionName',
      header: 'Name',
      cell: (item) => item.name,
      minWidth: 150,
      sortingField: 'name',
    },
    {
      id: 'bootstrapActionIsDefault',
      header: 'Default',
      cell: (item) => (item.isDefaultBootstrap ? 'Yes' : 'No'),
      minWidth: 30,
      sortingField: 'isDefaultBootstrap',
    },
    {
      id: 'bootstrapActionLocation',
      header: 'S3 location',
      cell: (item) => (
        <a href={s3url(item.s3FileLocation)} target='_blank'>
          {item.s3FileLocation}
        </a>
      ),
      minWidth: 300,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup]);

  /**
   * Asynchronously fetches and stores the BootstrapActions of the active group.
   */
  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    setLoadingBootstrapActions(true);

    try {
      const bootstrapactions = await listBootstrapActions({
        groupId: props.activeGroup,
      });

      setItems(bootstrapactions.bootstrapActions);
    } catch (err) {
      if (err.name == 'InsufficientPermissionException') {
        setOnboardModal(true);
      }
      setError('LOADING BOOTSTRAP ACTIONS');
    }
    setLoadingBootstrapActions(false);
  };

  const handleAction = async (e) => {
    switch (e.detail.id) {
      case 'details':
        setRedirect(Page.BOOTSTRAPACTION_DETAILS.replace(':id', collectionProps.selectedItems[0].id));
        break;
      case 'edit':
        setRedirect(Page.UPDATE_BOOTSTRAPACTION.replace(':id', collectionProps.selectedItems[0].id));
        break;
    }
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {},
    pagination: { pageSize: 25 },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'name',
        },
      },
    },
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  if (redirect) {
    return <Redirect push to={{ pathname: redirect, state: redirectParams }} />;
  }

  return (
    <>
      {ErrorAlert(error)}
      <UnauthorizedModal visible={onboardModal} onClose={() => setOnboardModal(false)} />

      <Table
        {...collectionProps}
        loadingText='Loading bootstrap actions...'
        loading={loadingBootstrapActions}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <RMPageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
                {
                  text: 'Actions',
                  onItemClick: handleAction,
                  items: [
                    {
                      text: 'View details',
                      id: 'details',
                      disabled: !collectionProps.selectedItems.length,
                    },
                    {
                      text: 'Edit',
                      id: 'edit',
                      disabled: !collectionProps.selectedItems.length,
                    },
                  ],
                  loading: loadingBootstrapActions,
                },
                {
                  text: 'Create',
                  variant: 'primary',
                  onItemClick: () => {
                    setRedirect(Page.CREATE_BOOTSTRAPACTION);
                  },
                },
              ]}
              subheader={
                <>
                  Bootstrap actions
                  <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                </>
              }
            />
          </>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Find bootstrap actions'
            filteringPlaceholder='Find bootstrap actions'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        empty={<EmptyState title={tableMessage} subtitle='No bootstrap actions to display.' />}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        selectionType='single'
      />
    </>
  );
};
