import React, { useCallback, useState } from 'react';
import { Container, ColumnLayout, Header, FormField, Select } from '@amzn/awsui-components-react-v3';
import { DataLakePrincipal } from 'aws-sdk/clients/lakeformation';
import OrganizationsSelector from './organizationsSelector';

interface RecipientSelectorComponentProps {
  activeGroup?: string;
  onChange: (principal: DataLakePrincipal) => void;
  onRamRoleChange: (ramRole: any) => void;
}

const RecipientSelectorComponent = (props: RecipientSelectorComponentProps) => {
  const [selectedPrincipalType, setSelectedPrincipalType] = useState(undefined);
  const [ramFields] = useState<any>({
    accountId: {
      errorText: '',
      value: '',
      validation: [],
    },
    accountIds: {
      errorText: '',
      value: [],
      validation: [],
    },
  });
  const [principalFields] = useState<any>({
    accountId: {
      errorText: '',
      value: '',
      validation: [],
    },
    accountIds: {
      errorText: '',
      value: [],
      validation: [],
    },
  });

  const handleRamRoleChange = useCallback(
    (accounts) => {
      props.onRamRoleChange(accounts.accountIds.value[0]);
    },
    [props.onRamRoleChange],
  );

  const handleIamRoleChange = useCallback(
    (accounts) => {
      props.onChange({
        DataLakePrincipalIdentifier: accounts.accountIds.value[0],
      });
    },
    [props.onChange],
  );

  return (
    <Container
      header={
        <Header
          variant='h2'
          description='Select the Resource Access Manager roles from an account to grant permissions to.'
        >
          Target principals
        </Header>
      }
    >
      <ColumnLayout>
        <div data-awsui-column-layout-root={true}>
          <OrganizationsSelector
            label='Enter an AWS RAM Share Role'
            description={
              <>
                Enter an RAM Share role ARN. Press enter after.
                <a
                  href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/LakeFormationRoles/#HConsumingRole'
                  className='awsui-util-help-info-link'
                >
                  Learn more
                </a>
              </>
            }
            hintText=''
            handleInputChange={(e) => e}
            isAccountsOnly={false}
            placeholder={
              ramFields.accountIds.value.length == 0
                ? 'Enter an AWS RAM Share role ARN.'
                : 'You can only enter one; deselect to type another.'
            }
            setFields={handleRamRoleChange}
            parentFields={ramFields}
          />
        </div>
        <FormField label='Select Lake Formation access principal type'>
          <Select
            selectedOption={selectedPrincipalType}
            onChange={(e) => {
              setSelectedPrincipalType(e.detail.selectedOption);
              if (e.detail.selectedOption.value === 'accountAccess')
                props.onChange({
                  DataLakePrincipalIdentifier: ramFields.accountIds.value
                    ? ramFields.accountIds.value[0].split(':')[4]
                    : '',
                });
            }}
            options={[
              {
                label: 'Account access (Entire account)',
                value: 'accountAccess',
              },
              {
                label: 'IAM role/user access',
                value: 'iamRoleUserAccess',
              },
            ]}
            loadingText='Loading access options'
          />
        </FormField>
        {selectedPrincipalType !== undefined && selectedPrincipalType.value === 'iamRoleUserAccess' && (
          <OrganizationsSelector
            label='IAM User/Role'
            description='Enter an IAM principal ARN. Press Enter after.'
            hintText=''
            handleInputChange={(e) => e}
            isAccountsOnly={false}
            placeholder={
              principalFields.accountIds.value.length == 0
                ? 'Enter an IAM principal ARN.'
                : 'You can only enter one; deselect to type another.'
            }
            setFields={handleIamRoleChange}
            parentFields={principalFields}
          />
        )}
      </ColumnLayout>
    </Container>
  );
};

export default RecipientSelectorComponent;
