import awsdlhybridcatalogservicelambda, {
  EditDatabaseResponse,
  EditDatabaseRequest,
  EditDataSetResponse,
  EditDataSetRequest,
  GetCatalogRequest,
  GetCatalogResponse,
  GetHybridCatalogDatabasesRequest,
  GetHybridCatalogDatabasesResponse,
  GetDataSetsFromHybridCatalogDatabaseRequest,
  GetDataSetsFromHybridCatalogDatabaseResponse,
  GetDataSetDetailRequest,
  GetDataSetDetailResponse,
  GetDataSetRequest,
  GetDataSetResponse,
  GetDataSourcesResponse,
  GetDataSourceDatabasesRequest,
  GetDataSourceDatabasesResponse,
  GetDataSourceTablesRequest,
  GetDataSourceTablesResponse,
  RegisterCatalogRequest,
  RegisterCatalogResponse,
  RegisterDatabaseRequest,
  RegisterDatabaseResponse,
  RegisterDataSetRequest,
  RegisterDataSetResponse,
  SyncDataSetDetailRequest,
  SyncDataSetDetailResponse,
  GetLatestDataSetDetailRequest,
  GetLatestDataSetDetailResponse,
  GetDataSetLineageRequest,
  GetDataSetLineageResponse,
  GetDataSourceSchemasRequest,
  GetDataSourceSchemasResponse,
  GetSchemasForDatabaseRequest,
  GetSchemasForDatabaseResponse,
  RegisterSchemaRequest,
  RegisterSchemaResponse,
  GetSchemasRequest,
  GetSchemasResponse,
  EditSchemaRequest,
  EditSchemaResponse,
  EditCatalogRequest,
  EditCatalogResponse,
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  DeleteSubscriptionRequest,
  DeleteSubscriptionResponse,
  GetSubscriptionRequest,
  GetSubscriptionResponse,
  ListSubscriptionsRequest,
  ListSubscriptionsResponse,
  CreateGlossaryRequest,
  CreateGlossaryResponse,
  CreateMetadataFieldRequest,
  CreateMetadataFieldResponse,
  CreateMetadataValueRequest,
  CreateMetadataValueResponse,
  CreateMetadataFormRequest,
  CreateMetadataFormResponse,
  AssociateFormToResourceRequest,
  AssociateFormToResourceResponse,
  AssociateGlossaryToResourceRequest,
  AssociateGlossaryToResourceResponse,
  DissociateFormFromResourceRequest,
  DissociateFormFromResourceResponse,
  DissociateGlossaryFromResourceRequest,
  DissociateGlossaryFromResourceResponse,
  EditGlossaryRequest,
  EditGlossaryResponse,
  EditMetadataFieldRequest,
  EditMetadataFieldResponse,
  EditMetadataFormRequest,
  EditMetadataFormResponse,
  EditMetadataValueRequest,
  EditMetadataValueResponse,
  DeleteGlossaryRequest,
  DeleteGlossaryResponse,
  DeleteMetadataFieldRequest,
  DeleteMetadataFieldResponse,
  DeleteMetadataFormRequest,
  DeleteMetadataFormResponse,
  GetGlossaryRequest,
  GetGlossaryResponse,
  GetMetadataFieldRequest,
  GetMetadataFieldResponse,
  GetMetadataFormRequest,
  GetMetadataFormResponse,
  ListGlossariesRequest,
  ListGlossariesResponse,
  ListMetadataFieldsRequest,
  ListMetadataFieldsResponse,
  ListMetadataFormsRequest,
  ListMetadataFormsResponse,
  ListMetadataValuesRequest,
  ListMetadataValuesResponse,
  ListMetadataEntitiesAssociatedToResourceRequest,
  ListMetadataEntitiesAssociatedToResourceResponse,
  ListResourcesAssociatedToMetadataEntityRequest,
  ListResourcesAssociatedToMetadataEntityResponse,
  ListDataQualityMetricsRequest,
  ListDataQualityMetricsResponse,
} from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import basicCallWrapper from './client_call';
import { getCatalogConfig } from './config';
import { SelectProps } from '@amzn/awsui-components-react-v3';
import { RedshiftCluster, RedshiftClusterList } from 'aws-sdk/clients/awsdatalakegladstonelambda';

const config = getCatalogConfig();

let catalog = new awsdlhybridcatalogservicelambda();

export async function initCatalogClient(credentials) {
  const catalogClientConfig = {
    region: config.region,
    endpoint: config.endpoint,
    credentials: credentials,
  };
  catalog = new awsdlhybridcatalogservicelambda(catalogClientConfig);
}

export const listDataQualityMetrics = (input?: ListDataQualityMetricsRequest) => {
  return basicCallWrapper<ListDataQualityMetricsRequest, ListDataQualityMetricsResponse>(
    catalog,
    catalog.listDataQualityMetrics,
  )(input);
};

export const getSubscription = (input?: GetSubscriptionRequest) => {
  return basicCallWrapper<GetSubscriptionRequest, GetSubscriptionResponse>(catalog, catalog.getSubscription)(input);
};

export const listSubscriptions = (input?: ListSubscriptionsRequest) => {
  return basicCallWrapper<ListSubscriptionsRequest, ListSubscriptionsResponse>(
    catalog,
    catalog.listSubscriptions,
  )(input);
};

export const createSubscription = (input?: CreateSubscriptionRequest) => {
  return basicCallWrapper<CreateSubscriptionRequest, CreateSubscriptionResponse>(
    catalog,
    catalog.createSubscription,
  )(input);
};

export const deleteSubscription = (input?: DeleteSubscriptionRequest) => {
  return basicCallWrapper<DeleteSubscriptionRequest, DeleteSubscriptionResponse>(
    catalog,
    catalog.deleteSubscription,
  )(input);
};

export const createMetadataForm = (input?: CreateMetadataFormRequest) => {
  return basicCallWrapper<CreateMetadataFormRequest, CreateMetadataFormResponse>(
    catalog,
    catalog.createMetadataForm,
  )(input);
};
export const createMetadataField = (input?: CreateMetadataFieldRequest) => {
  return basicCallWrapper<CreateMetadataFieldRequest, CreateMetadataFieldResponse>(
    catalog,
    catalog.createMetadataField,
  )(input);
};
export const createMetadataValue = (input?: CreateMetadataValueRequest) => {
  return basicCallWrapper<CreateMetadataValueRequest, CreateMetadataValueResponse>(
    catalog,
    catalog.createMetadataValue,
  )(input);
};
export const createGlossary = (input?: CreateGlossaryRequest) => {
  return basicCallWrapper<CreateGlossaryRequest, CreateGlossaryResponse>(catalog, catalog.createGlossary)(input);
};

export const associateMetadataFormToResource = (input?: AssociateFormToResourceRequest) => {
  return basicCallWrapper<AssociateFormToResourceRequest, AssociateFormToResourceResponse>(
    catalog,
    catalog.associateFormToResource,
  )(input);
};
export const associateGlossaryToResource = (input?: AssociateGlossaryToResourceRequest) => {
  return basicCallWrapper<AssociateGlossaryToResourceRequest, AssociateGlossaryToResourceResponse>(
    catalog,
    catalog.associateGlossaryToResource,
  )(input);
};

export const dissociateMetadataFormFromResource = (input?: DissociateFormFromResourceRequest) => {
  return basicCallWrapper<DissociateFormFromResourceRequest, DissociateFormFromResourceResponse>(
    catalog,
    catalog.dissociateFormFromResource,
  )(input);
};
export const dissociateGlossaryFromResource = (input?: DissociateGlossaryFromResourceRequest) => {
  return basicCallWrapper<DissociateGlossaryFromResourceRequest, DissociateGlossaryFromResourceResponse>(
    catalog,
    catalog.dissociateGlossaryFromResource,
  )(input);
};

export const editMetadataForm = (input?: EditMetadataFormRequest) => {
  return basicCallWrapper<EditMetadataFormRequest, EditMetadataFormResponse>(catalog, catalog.editMetadataForm)(input);
};
export const editMetadataField = (input?: EditMetadataFieldRequest) => {
  return basicCallWrapper<EditMetadataFieldRequest, EditMetadataFieldResponse>(
    catalog,
    catalog.editMetadataField,
  )(input);
};
export const editMetadataValue = (input?: EditMetadataValueRequest) => {
  return basicCallWrapper<EditMetadataValueRequest, EditMetadataValueResponse>(
    catalog,
    catalog.editMetadataValue,
  )(input);
};
export const editGlossary = (input?: EditGlossaryRequest) => {
  return basicCallWrapper<EditGlossaryRequest, EditGlossaryResponse>(catalog, catalog.editGlossary)(input);
};

export const deleteMetadataForm = (input?: DeleteMetadataFormRequest) => {
  return basicCallWrapper<DeleteMetadataFormRequest, DeleteMetadataFormResponse>(
    catalog,
    catalog.deleteMetadataForm,
  )(input);
};
export const deleteMetadataField = (input?: DeleteMetadataFieldRequest) => {
  return basicCallWrapper<DeleteMetadataFieldRequest, DeleteMetadataFieldResponse>(
    catalog,
    catalog.deleteMetadataField,
  )(input);
};
export const deleteGlossary = (input?: DeleteGlossaryRequest) => {
  return basicCallWrapper<DeleteGlossaryRequest, DeleteGlossaryResponse>(catalog, catalog.deleteGlossary)(input);
};

export const listMetadataForms = (input?: ListMetadataFormsRequest) => {
  return basicCallWrapper<ListMetadataFormsRequest, ListMetadataFormsResponse>(
    catalog,
    catalog.listMetadataForms,
  )(input);
};
export const listMetadataFields = (input?: ListMetadataFieldsRequest) => {
  return basicCallWrapper<ListMetadataFieldsRequest, ListMetadataFieldsResponse>(
    catalog,
    catalog.listMetadataFields,
  )(input);
};
export const listMetadataValues = (input?: ListMetadataValuesRequest) => {
  return basicCallWrapper<ListMetadataValuesRequest, ListMetadataValuesResponse>(
    catalog,
    catalog.listMetadataValues,
  )(input);
};
export const listGlossaries = (input?: ListGlossariesRequest) => {
  return basicCallWrapper<ListGlossariesRequest, ListGlossariesResponse>(catalog, catalog.listGlossaries)(input);
};
export const listMetadataEntitiesAssociatedToResource = (input?: ListMetadataEntitiesAssociatedToResourceRequest) => {
  return basicCallWrapper<
    ListMetadataEntitiesAssociatedToResourceRequest,
    ListMetadataEntitiesAssociatedToResourceResponse
  >(
    catalog,
    catalog.listMetadataEntitiesAssociatedToResource,
  )(input);
};
export const listResourcesAssociatedToMetadataEntity = (input?: ListResourcesAssociatedToMetadataEntityRequest) => {
  return basicCallWrapper<
    ListResourcesAssociatedToMetadataEntityRequest,
    ListResourcesAssociatedToMetadataEntityResponse
  >(
    catalog,
    catalog.listResourcesAssociatedToMetadataEntity,
  )(input);
};
export const getMetadataForm = (input?: GetMetadataFormRequest) => {
  return basicCallWrapper<GetMetadataFormRequest, GetMetadataFormResponse>(catalog, catalog.getMetadataForm)(input);
};
export const getMetadataField = (input?: GetMetadataFieldRequest) => {
  return basicCallWrapper<GetMetadataFieldRequest, GetMetadataFieldResponse>(catalog, catalog.getMetadataField)(input);
};
export const getGlossary = (input?: GetGlossaryRequest) => {
  return basicCallWrapper<GetGlossaryRequest, GetGlossaryResponse>(catalog, catalog.getGlossary)(input);
};

export const getDataSetLineage = (input?: GetDataSetLineageRequest) => {
  return basicCallWrapper<GetDataSetLineageRequest, GetDataSetLineageResponse>(
    catalog,
    catalog.getDataSetLineage,
  )(input);
};

export const editDatabase = (input: EditDatabaseRequest) => {
  return basicCallWrapper<EditDatabaseRequest, EditDatabaseResponse>(catalog, catalog.editDatabase)(input);
};

export const editSchema = (input: EditSchemaRequest) => {
  return basicCallWrapper<EditSchemaRequest, EditSchemaResponse>(catalog, catalog.editSchema)(input);
};

export const editDataset = (input: EditDataSetRequest) => {
  return basicCallWrapper<EditDataSetRequest, EditDataSetResponse>(catalog, catalog.editDataSet)(input);
};

export const registerCatalog = (input: RegisterCatalogRequest) => {
  return basicCallWrapper<RegisterCatalogRequest, RegisterCatalogResponse>(catalog, catalog.registerCatalog)(input);
};

export const editCatalog = (input: EditCatalogRequest) => {
  return basicCallWrapper<EditCatalogRequest, EditCatalogResponse>(catalog, catalog.editCatalog)(input);
};

export const registerDatabase = (input: RegisterDatabaseRequest) => {
  return basicCallWrapper<RegisterDatabaseRequest, RegisterDatabaseResponse>(catalog, catalog.registerDatabase)(input);
};

export const registerSchema = (input: RegisterSchemaRequest) => {
  return basicCallWrapper<RegisterSchemaRequest, RegisterSchemaResponse>(catalog, catalog.registerSchema)(input);
};

export const registerDataSets = (input: RegisterDataSetRequest) => {
  return basicCallWrapper<RegisterDataSetRequest, RegisterDataSetResponse>(catalog, catalog.registerDataSet)(input);
};

export const syncDataSets = (input: SyncDataSetDetailRequest) => {
  return basicCallWrapper<SyncDataSetDetailRequest, SyncDataSetDetailResponse>(
    catalog,
    catalog.syncDataSetDetail,
  )(input);
};

export const listDataSources = () => {
  return basicCallWrapper<object, GetDataSourcesResponse>(catalog, catalog.getDataSources)({});
};

export const listDataSets = (input?: GetDataSetRequest) => {
  return basicCallWrapper<GetDataSetRequest, GetDataSetResponse>(catalog, catalog.getDataSet)(input);
};

export const listDatabases = (input?: GetHybridCatalogDatabasesRequest) => {
  return basicCallWrapper<GetHybridCatalogDatabasesRequest, GetHybridCatalogDatabasesResponse>(
    catalog,
    catalog.getHybridCatalogDatabases,
  )(input);
};

export const getSchemas = (input?: GetSchemasRequest) => {
  return basicCallWrapper<GetSchemasRequest, GetSchemasResponse>(catalog, catalog.getSchemas)(input);
};

export const getSchemasForDatabase = (input?: GetSchemasForDatabaseRequest) => {
  return basicCallWrapper<GetSchemasForDatabaseRequest, GetSchemasForDatabaseResponse>(
    catalog,
    catalog.getSchemasForDatabase,
  )(input);
};

export const getDataSetsFromHybridCatalogDatabase = (input?: GetDataSetsFromHybridCatalogDatabaseRequest) => {
  return basicCallWrapper<GetDataSetsFromHybridCatalogDatabaseRequest, GetDataSetsFromHybridCatalogDatabaseResponse>(
    catalog,
    catalog.getDataSetsFromHybridCatalogDatabase,
  )(input);
};

export const listCatalogs = (input?: GetCatalogRequest) => {
  return basicCallWrapper<GetCatalogRequest, GetCatalogResponse>(catalog, catalog.getCatalog)(input);
};

export const listDatasetsForDatabase = (input?: GetDataSetsFromHybridCatalogDatabaseRequest) => {
  return basicCallWrapper<GetDataSetsFromHybridCatalogDatabaseRequest, GetDataSetsFromHybridCatalogDatabaseResponse>(
    catalog,
    catalog.getDataSetsFromHybridCatalogDatabase,
  )(input);
};

export const dataSetDetail = (input: GetDataSetDetailRequest) => {
  return basicCallWrapper<GetDataSetDetailRequest, GetDataSetDetailResponse>(catalog, catalog.getDataSetDetail)(input);
};

export const getDataSourceTables = (input: GetDataSourceTablesRequest) => {
  return basicCallWrapper<GetDataSourceTablesRequest, GetDataSourceTablesResponse>(
    catalog,
    catalog.getDataSourceTables,
  )(input);
};

export const getDataSourceDatabases = (input: GetDataSourceDatabasesRequest) => {
  return basicCallWrapper<GetDataSourceDatabasesRequest, GetDataSourceDatabasesResponse>(
    catalog,
    catalog.getDataSourceDatabases,
  )(input);
};

export const getDataSourceSchemas = (input: GetDataSourceSchemasRequest) => {
  return basicCallWrapper<GetDataSourceSchemasRequest, GetDataSourceSchemasResponse>(
    catalog,
    catalog.getDataSourceSchemas,
  )(input);
};

export const getHybridCatalogDatabases = (input: GetHybridCatalogDatabasesRequest) => {
  return basicCallWrapper<GetHybridCatalogDatabasesRequest, GetHybridCatalogDatabasesResponse>(
    catalog,
    catalog.getHybridCatalogDatabases,
  )(input);
};

export const getLatestDataSetDetail = (input: GetLatestDataSetDetailRequest) => {
  return basicCallWrapper<GetLatestDataSetDetailRequest, GetLatestDataSetDetailResponse>(
    catalog,
    catalog.getLatestDataSetDetail,
  )(input);
};

export const convertWorkspaceRedshiftResourcesToOptions = (
  workspaceRedshiftResources: RedshiftClusterList,
): SelectProps.Option[] => {
  const options: SelectProps.Option[] = workspaceRedshiftResources.map((workspaceRedshiftResource: RedshiftCluster) => {
    return {
      id: workspaceRedshiftResource.clusterId,
      value: workspaceRedshiftResource.clusterId,
      label: workspaceRedshiftResource.clusterId,
      dbUser: workspaceRedshiftResource.masterUser,
    };
  });
  return options;
};

export const convertRedshiftClustersToOptions = (redshiftClusters: RedshiftClusterList): SelectProps.Option[] => {
  const options: SelectProps.Option[] = redshiftClusters
    .filter((cluster) => cluster.clusterStatus == 'available')
    .map((redshiftCluster: RedshiftCluster) => {
      return {
        id: redshiftCluster.clusterId,
        value: redshiftCluster.clusterId,
        label: redshiftCluster.clusterId,
        masterUser: redshiftCluster.masterUser,
      };
    });
  return options;
};
