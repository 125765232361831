import React, { useEffect, useState } from 'react';
import { ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';

import { getMetricCollectionById } from 'src/api/metricstore';

import { labeledTextFromList, ErrorAlert } from 'src/components/resourcesmanager/components';
import { dateString } from 'src/components/metricstore/helpers';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface MetricCollectionDefinitionProps {
  match: any;
  setContentType: any;
}

export const MetricCollectionDefinition = (props: MetricCollectionDefinitionProps) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [metricCollection, setMetricCollection] = useState(null);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  /**
   * Asynchronously fetches the metric definition from the metricstore API and updates the state.
   */
  const handleRefresh = async () => {
    setLoading(true);

    try {
      const metricCollectionResponse = await getMetricCollectionById({
        id: props.match.params.id,
      });
      setMetricCollection(metricCollectionResponse);
    } catch (err) {
      console.log(err);
      setError('Error loading metric collection');
    }
    setLoading(false);
  };

  return (
    <>
      {ErrorAlert(error)}

      <SpaceBetween size='s'>
        <Header variant='h1'>{metricCollection?.name}</Header>
        <Container header={<Header variant='h2'> {metricCollection?.name} </Header>}>
          <ColumnLayout columns={2} borders='horizontal'>
            {labeledTextFromList(
              [
                {
                  label: 'Id',
                  value: metricCollection?.id,
                  copiable: true,
                },
                {
                  label: 'Name',
                  value: metricCollection?.name,
                  copiable: true,
                },
                {
                  label: 'Description',
                  value: metricCollection?.description,
                },
                {
                  label: 'Status',
                  value: metricCollection?.isArchived ? 'Inactive' : 'Active',
                },
                {
                  label: 'Owner',
                  value: metricCollection?.owner,
                },
                {
                  label: 'List of metrics',
                  value: JSON.stringify(metricCollection?.metricPositionToIdMap),
                },
                {
                  label: 'Created by',
                  value: metricCollection?.createdBy,
                  copiable: true,
                },
                {
                  label: 'Created on',
                  value: dateString(metricCollection?.createdAt),
                },
                {
                  label: 'Updated by',
                  value: metricCollection?.updatedBy,
                  copiable: true,
                },
                {
                  label: 'Updated on',
                  value: dateString(metricCollection?.updatedAt),
                },
                {
                  label: 'Tags',
                  value: metricCollection?.tags?.toString(),
                },
              ],
              loading,
            )}
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    </>
  );
};
