import { Alert, Box, Button, Header, Input, Link, SpaceBetween, Table } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { validateTicketUrl } from 'src/commons/validationUtils';
import { AdvisoryAttachedTicket, AdvisoryAttachedTicketList } from 'aws-sdk/clients/awsdlomni';
import { ADVISORIES_WIKI_PAGE, COMMENT_TOO_LONG_ERROR } from 'src/components/dataadvisory/constants';

export interface AttachedTicketsEditorProps {
  attachedTickets: AdvisoryAttachedTicketList;
  setAttachedTickets?: any;
  setErrorMessage?: any;
  readonly: boolean;
}

export const AttachedTicketsEditor = (props: AttachedTicketsEditorProps) => {
  const [currentEditValue, setCurrentEditValue] = useState(undefined);
  const addItem = () => {
    let item: AdvisoryAttachedTicket = {
      ticketUrl: '',
      comment: '',
    };
    let newItems: AdvisoryAttachedTicketList = [];
    newItems.push(...props.attachedTickets);
    newItems.push(item);
    props.setAttachedTickets(newItems);
  };

  const validateTickets = () => {
    if (!props.setErrorMessage) return;

    let invalidTicketUrls = [];
    props.attachedTickets.forEach((item: AdvisoryAttachedTicket) => {
      if (!validateTicketUrl(item.ticketUrl)) {
        invalidTicketUrls.push(item.ticketUrl);
      }
    });

    const aCommentIsTooLong =
      props.attachedTickets.filter((ticket: AdvisoryAttachedTicket) => ticket.comment?.length > 256).length > 0;
    if (aCommentIsTooLong) {
      props.setErrorMessage(COMMENT_TOO_LONG_ERROR);
    } else if (invalidTicketUrls.length > 0) {
      props.setErrorMessage(
        'The following ticket URLs are invalid: ' + invalidTicketUrls.toString().replaceAll(',', ', '),
      );
    } else {
      props.setErrorMessage(undefined);
    }
  };

  useEffect(() => {
    validateTickets();
  }, [props.attachedTickets]);

  return (
    <SpaceBetween size={'l'} direction={'vertical'}>
      {props.readonly == false && (
        <Alert statusIconAriaLabel='Info' header='Ticket access'>
          For Omni to read your tickets, set the ticket visibility to Public or add Omni to your resolver group. Click{' '}
          <Link href={ADVISORIES_WIKI_PAGE}>here</Link> for more details.
        </Alert>
      )}
      <Table
        items={props.attachedTickets}
        ariaLabels={{
          activateEditLabel: (column, item) => `Edit ${item.name} ${column.header}`,
          cancelEditLabel: (column) => `Cancel editing ${column.header}`,
          submitEditLabel: (column) => `Submit editing ${column.header}`,
        }}
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign={'center'} color={'inherit'}>
            <SpaceBetween size={'m'}>
              <b>No tickets</b>
              <p>Attach tickets above.</p>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            counter={'(' + props.attachedTickets.length + ')'}
            actions={
              props.readonly ? (
                []
              ) : (
                <SpaceBetween size={'xs'} direction={'horizontal'}>
                  <Button variant={'normal'} onClick={() => addItem()}>
                    Add ticket
                  </Button>
                </SpaceBetween>
              )
            }
          >
            Attached tickets
          </Header>
        }
        columnDefinitions={[
          {
            id: 'url',
            header: 'Ticket URL',
            cell: (item: AdvisoryAttachedTicket) => {
              if (item.ticketUrl == '') {
                return <i>Click to edit...</i>;
              }
              return <Link href={item.ticketUrl}>{item.ticketUrl}</Link>;
            },
            minWidth: 125,
            editConfig: props.readonly
              ? undefined
              : {
                  ariaLabel: 'Ticket URL',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'URL error',
                  editingCell: (item: AdvisoryAttachedTicket, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={currentValue ?? item.ticketUrl}
                        onChange={(event) => {
                          const value = event.detail.value ?? item.ticketUrl;
                          setValue(value);
                          setCurrentEditValue(value);
                        }}
                        placeholder={'https://sim.amazon.com/12345'}
                      />
                    );
                  },
                },
          },
          {
            id: 'comment',
            header: 'Comment',
            cell: (item: AdvisoryAttachedTicket) => item.comment,
            minWidth: 200,
            editConfig: props.readonly
              ? undefined
              : {
                  ariaLabel: 'Ticket comment',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Comment error',
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={currentValue ?? item.comment}
                        onChange={(event) => {
                          const value = event.detail.value ?? item.comment;
                          setValue(value);
                          setCurrentEditValue(value);
                        }}
                        placeholder={'A short comment or description for this ticket.'}
                      />
                    );
                  },
                },
          },
          {
            id: 'actions',
            header: '',
            cell: (item: AdvisoryAttachedTicket) => (
              <Button
                variant={'icon'}
                iconName={'remove'}
                onClick={() => {
                  props.setAttachedTickets(props.attachedTickets.filter((itm) => itm.ticketUrl != item.ticketUrl));
                }}
              />
            ),
          },
        ].slice(0, props.readonly ? 2 : 3)} // remove the "delete" column in readonly mode
        submitEdit={(item, column) => {
          const newItems = props.attachedTickets.map((itm) => {
            if (itm == item) {
              if (column.header == 'Comment') {
                return {
                  ticketUrl: itm.ticketUrl,
                  comment: currentEditValue,
                };
              } else {
                return {
                  ticketUrl: currentEditValue,
                  comment: itm.comment,
                };
              }
            }
            return itm;
          });
          props.setAttachedTickets(newItems);
          setCurrentEditValue(undefined);
        }}
      />
    </SpaceBetween>
  );
};
