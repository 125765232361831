import { Table, ColumnLayout } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import { PageHeader } from 'src/components/notifications/common';
import { CopiableText } from 'src/commons/common';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface datasetsReviewProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  registerItems: any[];
  selectedDatabaseName: string;
  catalogName: string;
}

export const RedshiftDatasetsReview = (props: datasetsReviewProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResource, setLoadingResource] = useState(false);
  const columnDefinitions = [
    {
      id: 'dataset',
      header: 'Dataset name',
      cell: (item) => item.label,
      minWidth: 250,
    },
    {
      id: 'schema',
      header: 'Schema name',
      cell: (item) => item.labelTag.split('.')[2],
      minWidth: 250,
    },
    {
      id: 'database',
      header: 'Database name',
      cell: (item) => item.labelTag.split('.')[1],
      minWidth: 250,
    },
    {
      id: 'cluster',
      header: 'Cluster name',
      cell: (item) => item.labelTag.split('.')[0],
      minWidth: 250,
    },
  ];

  const handleRefresh = async () => {
    setLoadingResource(true);
    setItems(props.registerItems);
    setLoadingResource(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const { items, collectionProps } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No datasets to register</b>
          </div>
        </div>
      ),
    },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const getRegisterMetadata = () => {
    const details = [];
    {
      props.activeWorkspace?.accountId !== undefined &&
        details.push(<CopiableText name={'Account ID'} key={'Account ID'} value={props.activeWorkspace?.accountId} />);
    }

    {
      props.catalogName !== undefined &&
        details.push(<CopiableText name={'Catalog name'} key={'Catalog name'} value={props.catalogName} />);
    }

    return details;
  };

  return (
    <>
      <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2>Register dataset details</h2>
            </div>
          </div>
        </div>
        <ColumnLayout columns={3} borders='horizontal'>
          {getRegisterMetadata()}
        </ColumnLayout>
      </div>

      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        loading={loadingResource}
        columnDefinitions={columnDefinitions}
        items={items}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Datasets to register
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No datasets selected</p>
          </div>
        }
      />
    </>
  );
};
