import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Button,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Input,
  Header,
  Container,
  Select,
  SpaceBetween,
  TokenGroup,
} from '@amzn/awsui-components-react-v3';

import { Redirect } from 'react-router-dom';
import * as validate from '../../commons/validationUtils';
import { createTag } from '../../api/permissions';
import { Page } from '../../routes/Paths';
import { FORM_CONTENT_TYPE, LAKEFORMATION_TAG_TYPE, NUM_TAG_VALUES, REDSHIFT_TAG_TYPE } from '../../commons/constants';
import { isValidTagType } from '../../commons/validationUtils';
import { getRegion } from 'src/api/config';

export interface CreateTagProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const CreateTag = (props: CreateTagProps) => {
  const [redirect, setRedirect] = useState(undefined);

  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [catalogId, setCatalogId] = useState('');
  const [tagKey, setTagKey] = useState('');
  const [tagType, setTagType] = useState('');
  const [tagValue, setTagValue] = useState('');
  const [tagValues, setTagValues] = useState([]);
  const [tagValuesTokens, setTagValuesTokens] = useState([]);

  const region = getRegion();
  const [type, setType] = useState({ label: '', value: '' });

  const [ownerId, setOwnerId] = useState('');

  const catalogIdPlaceholder = '12 digit account number, e.g. 123456789012';
  const tagKeyPlaceholder = 'MyTagName';
  const tagValuePlaceholder = 'MyTagValue';
  const regionPlaceholder = 'us-east-1';
  const typePlaceholder = 'Lake Formation/ Redshift';

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);

    const activeWorkspace = props.activeWorkspace;

    if (activeWorkspace) {
      const ownerId = props.match.params.id ? props.match.params.id : activeWorkspace.workspaceId;
      setOwnerId(ownerId);

      // defaulting and locking catalogId based on workspace
      setCatalogId(activeWorkspace.accountId);
    } else {
      setRedirect(Page.HOME);
    }
  }, []);

  const handleTagValue = (value) => {
    // display the text on field
    setTagValue(value);

    // check for special delimiter characters (comma, space, or return) that signal when entry is complete
    // but entry must require at least one regular character (number or letter)
    if (tagValues.length < NUM_TAG_VALUES && /^\w+(,| )$/.test(value)) {
      // cutoff delimiter character and empty out the input field since delimiter was added
      value = value.slice(0, -1);
      setTagValue('');

      // add the value only if it's not already in the list
      // note: the API should already account for duplicates; this is more to avoid UI confusion
      if (!tagValues.includes(value)) {
        setTagValues(tagValues.concat(value));
        setTagValuesTokens(
          tagValuesTokens.concat({
            label: value,
            dismissLabel: `Remove ${value}`,
          }),
        );
      }
    }
  };

  const handleConfirm = async () => {
    setButtonLoading(true);
    try {
      const createTagResponse = await createTag({
        ownerId,
        type: tagType,
        tagKey: tagKey,
        tagValues: tagValues,
        catalogId: catalogId,
        region: region,
      });
      setButtonLoading(false);

      const status = createTagResponse.status == 'Success' ? 'success' : 'error';

      if (status === 'error') {
        setNotifications([
          {
            type: status,
            content: ` ${createTagResponse.message}`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      } else {
        setRedirect({
          pathname: Page.TAGS,
          state: {
            status,
            message: 'Tag created successfully',
          },
        });
      }
    } catch (e) {
      setNotifications([
        {
          type: 'error',
          content: `Error when trying to create your tag: ${e.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}

      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header variant='h1' description='Please specify the details of your Omni tag.'>
            Create Tag
          </Header>
        }
        actions={
          <SpaceBetween direction='horizontal' size='s'>
            <Button variant='link' onClick={() => setRedirect(Page.TAGS)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              loading={buttonLoading}
              onClick={handleConfirm}
              disabled={
                !validate.isValidAccountId(catalogId) ||
                !validate.isValidTagKey(tagKey) ||
                !validate.isValidTagValues(tagValues) ||
                !isValidTagType(tagType)
              }
            >
              {'Create tag'}
            </Button>
          </SpaceBetween>
        }
      >
        <Container className='custom-screenshot-hide' header={<Header variant='h2'>Tag details</Header>}>
          <ColumnLayout>
            <SpaceBetween size='m'>
              <FormField label={<div>Catalog ID</div>} description='AWS account that owns the tags/tagged resources.'>
                <Input
                  name='catalogId'
                  placeholder={catalogIdPlaceholder}
                  value={catalogId}
                  ariaRequired={true}
                  onChange={({ detail }) => setCatalogId(detail.value.trim())}
                  invalid={catalogId !== '' && !validate.isValidAccountId(catalogId)}
                  disabled={true}
                />
              </FormField>

              <FormField
                label='Tag key'
                description='Unique name of your tag. Once this name is created, it cannot be deleted.'
              >
                <Input
                  name='tagKey'
                  placeholder={tagKeyPlaceholder}
                  value={tagKey}
                  ariaRequired={true}
                  onChange={({ detail }) => setTagKey(detail.value.trim())}
                  invalid={tagKey !== '' && !validate.isValidTagKey(tagKey)}
                />
              </FormField>

              <FormField
                label={`Tag value(s) (${tagValues.length}/${NUM_TAG_VALUES})`}
                description='Subcategories of your tag: must be letters or numbers separated by commas or spaces. May be edited later.'
              >
                <Input
                  name='tagValue'
                  placeholder={tagValuePlaceholder}
                  value={tagValue}
                  ariaRequired={true}
                  onChange={({ detail }) => handleTagValue(detail.value)}
                  onKeyDown={(e) => e.detail.key === 'Enter' && tagValue && handleTagValue(tagValue + ',')}
                  invalid={
                    // field is red if there's too many values or the value itself is not valid
                    (tagValues.length >= NUM_TAG_VALUES && tagValue !== '') ||
                    (!validate.isValidTagValues([...tagValues, tagValue]) && tagValue !== '')
                  }
                />

                <TokenGroup
                  onDismiss={({ detail: { itemIndex } }) => {
                    setTagValues([...tagValues.slice(0, itemIndex), ...tagValues.slice(itemIndex + 1)]);
                    setTagValuesTokens([
                      ...tagValuesTokens.slice(0, itemIndex),
                      ...tagValuesTokens.slice(itemIndex + 1),
                    ]);
                  }}
                  items={tagValuesTokens}
                  limit={NUM_TAG_VALUES} // limit for Lake Formation tag: https://tiny.amazon.com/ho1clt35/docsawsamazlakelatedgTBAC
                />
              </FormField>

              <FormField label='Region' description='The region your tag will be stored in.'>
                <Input
                  name='region'
                  value={region}
                  ariaRequired={true}
                  placeholder={regionPlaceholder}
                  disabled={true}
                />
              </FormField>

              <FormField label='Type' description='The type of your tag'>
                <Select
                  name='type'
                  placeholder={typePlaceholder}
                  selectedOption={type}
                  options={[
                    { label: 'Lake Formation', value: LAKEFORMATION_TAG_TYPE },
                    { label: 'Redshift', value: REDSHIFT_TAG_TYPE },
                  ]}
                  ariaRequired={true}
                  onChange={({ detail }) => {
                    setType({
                      label: detail.selectedOption.label,
                      value: detail.selectedOption.value,
                    });
                    setTagType(detail.selectedOption.value);
                  }}
                />
              </FormField>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};

export default CreateTag;
