import * as React from 'react';
import { useEffect, useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  CollectionPreferences,
  Input,
  Modal,
  TextFilter,
  Table,
  Pagination,
  Header,
} from '@amzn/awsui-components-react-v3/polaris';

import { scrollUp } from '../utils/navigation';

import {
  batchApprove,
  batchDeny,
  cancelRequest,
  getRequestedByRequests,
  getRequestedToRequests,
} from '../../api/permissions';
import { PageHeader } from '../subscriptions/common';
import { iamColumnDefinition } from 'src/components/requests/utils';
import { EmptyState } from 'src/commons/EmptyState';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface BrowseRequestsTableProps {
  setContentType: any;
  isIncomingRequests: boolean;
  activeGroup?: string;
}

export interface BrowseRequestsTableState {
  filterText: string;
}

const DEFAULT_CANCEL_REASON = 'Please cancel the request.';

interface iBrowseRequestsTable {
  isIncomingRequests: boolean;
  setContentType(contentType: string): void;
  activeGroup: string;
}

export function BrowseRequestsTable({ isIncomingRequests, setContentType, activeGroup }: iBrowseRequestsTable) {
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [allItems, setAllItems] = useState([]);
  const [, setSelected] = useState(undefined);
  const [, setNotifications] = useState([]);
  const [columnDefinitions] = useState(iamColumnDefinition(isIncomingRequests));
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [cancelRequestLoading, setCancelRequestLoading] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: (
          <EmptyState
            title='No instances'
            subtitle='No instances to display.'
            action={<Button>Create instance</Button>}
          />
        ),
        noMatch: (
          <EmptyState
            title='No matches'
            subtitle='We can’t find a match.'
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 25 },
      sorting: {},
      selection: {
        trackBy: 'requestId',
        defaultSelectedItems: [],
        keepSelection: false,
      },
    },
  );
  const { selectedItems } = collectionProps;

  // component did mount
  useEffect(() => {
    setContentType(TABLE_CONTENT_TYPE);
    const refresh = async () => {
      await handleRefresh();
    };
    refresh();
  }, []);

  // component did update
  useEffect(() => {
    const refresh = async () => {
      await handleRefresh();
    };
    refresh();
  }, [activeGroup, isIncomingRequests]);

  const transformData = (items) => {
    const transformedData = [];
    items.forEach((item) => {
      const transform = {};
      transform['requestId'] = item.requestId;
      transform['status'] = item.status;
      transform['groupRequestedTo'] = item.groupRequestedTo;
      transform['groupRequestedBy'] = item.groupRequestedBy;
      transform['hybridCatalogId'] = item.request.DatasetsToAdd[0];
      let hcIdArr = transform['hybridCatalogId'].split('|');
      transform['catalogId'] = hcIdArr[1].slice(2);
      transform['databaseName'] = hcIdArr[2].slice(3);
      transform['tableName'] = hcIdArr[3].slice(3);
      transform['timeOfRequest'] = item.timeOfRequest;
      transform['requester'] = item.requestedBy;
      transformedData.push(transform);
    });
    return transformedData;
  };

  const handleCancel = async () => {
    setCancelRequestLoading(true);

    let finalCancelReason = DEFAULT_CANCEL_REASON;
    if (cancelReason !== '') {
      finalCancelReason = cancelReason;
    }
    try {
      await cancelRequest({
        requestId: selectedItems[0].requestId,
        reason: finalCancelReason,
      });
      await handleRefresh();
    } catch (err) {
      console.log(err);
    }

    setCancelRequestLoading(false);
    setCancelReason('');
    setCancelModalVisible(false);
    setNotifications([
      {
        type: 'success',
        content: 'Request cancel succeed',
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
    scrollUp();
  };

  const handleRefresh = async () => {
    if (!activeGroup) return;
    setRequestsLoading(true);
    const requests = [];
    try {
      let nextToken = undefined;
      let getIamAccessRequestResult;
      do {
        let iamAccessRequest = {
          groupId: activeGroup,
          requestType: 'AddDatasets',
        };
        if (nextToken != undefined) {
          iamAccessRequest['nextToken'] = nextToken;
        }
        if (isIncomingRequests) {
          getIamAccessRequestResult = await getRequestedToRequests(iamAccessRequest);
        } else {
          getIamAccessRequestResult = await getRequestedByRequests(iamAccessRequest);
        }
        nextToken = getIamAccessRequestResult.nextToken;
        requests.push(...getIamAccessRequestResult.requestList);
      } while (nextToken != undefined);
      setAllItems(transformData(requests));
      setRequestsLoading(false);
      setSelected(undefined);
    } catch (err) {}
  };

  const handleAction = async (e) => {
    if (e.detail.id === 'cancel') {
      setCancelModalVisible(true);
    } else if (e.detail.id === 'approve') {
      setRequestsLoading(true);
      let requestList = [];
      selectedItems.forEach((item) =>
        requestList.push({
          requestId: item.requestId,
        }),
      );

      try {
        await batchApprove({
          approveRequestList: requestList,
        });
        await handleRefresh();
      } catch (err) {
        console.log(err);
      }

      setRequestsLoading(false);
      setNotifications([
        {
          type: 'success',
          content: 'Successfully approved the request.',
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else if (e.detail.id === 'deny') {
      setRequestsLoading(true);
      let requestList = [];
      selectedItems.forEach((item) =>
        requestList.push({
          requestId: item.requestId,
          reason: 'DENIED',
        }),
      );
      try {
        await batchDeny({
          denyRequestList: requestList,
        });
        await handleRefresh();
      } catch (err) {
        console.log(err);
      }
      setRequestsLoading(false);
      setNotifications([
        {
          type: 'success',
          content: 'Successfully denied the request.',
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const options = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Actions',
        onItemClick: handleAction,
        loading: requestsLoading,
        items: isIncomingRequests
          ? [
              {
                text: 'Approve',
                id: 'approve',
                disabled: selectedItems.length === 0,
              },
              {
                text: 'Deny',
                id: 'deny',
                disabled: selectedItems.length === 0,
              },
            ]
          : [
              {
                text: 'Cancel',
                id: 'cancel',
                disabled: selectedItems.length === 0 || selectedItems.length > 1,
              },
            ],
      },
    ];
  };

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading requests...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={false}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={options()}
              header={<Header counter={`(${allItems.length})`}>IAM access requests</Header>}
            />
          </>
        }
        empty={<EmptyState title='No requests' subtitle='No requests were found.' />}
        loading={requestsLoading}
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            preferences={{
              pageSize: 25,
            }}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 25, label: '25 items' },
                { value: 50, label: '50 items' },
                { value: 100, label: '100 items' },
              ],
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Enable to wrap table cell content, disable to truncate text.',
            }}
          />
        }
        trackBy='requestId'
        selectionType={isIncomingRequests ? 'multi' : 'single'}
        selectedItems={selectedItems}
        filter={
          <TextFilter
            {...filterProps}
            filteringPlaceholder='Find resources'
            filteringAriaLabel='Filter IAM access requests'
            countText={`${filteredItemsCount} ${filteredItemsCount > 1 ? 'matches' : 'match'}`}
          />
        }
        pagination={<Pagination {...paginationProps} />}
      ></Table>
      <Modal
        onDismiss={() => setCancelModalVisible(false)}
        visible={cancelModalVisible}
        size='small'
        footer={
          <span className='awsui-util-f-r'>
            <Button
              variant='link'
              onClick={() => {
                setCancelModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' onClick={handleCancel} loading={cancelRequestLoading}>
              Confirm
            </Button>
          </span>
        }
        header={'Please provide the reason for the request cancellation.'}
      >
        <Input
          placeholder='Please provide your reason.'
          onChange={(e) => setCancelReason(e.detail.value.trim())}
          value={cancelReason}
        />
      </Modal>
    </>
  );
}
