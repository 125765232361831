import * as React from 'react';
import { useState, useEffect } from 'react';

import { SpaceBetween, FlashbarProps, Flashbar } from '@amzn/awsui-components-react-v3/polaris';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { listDataQualityMetrics } from 'src/api/catalog';
import { generateGlueArnFromId } from 'src/components/utils/arnUtil';
import { DataQualityReportTable } from 'src/components/catalog/dataquality/dataQualityReportTable';
import { DataQualityTable } from 'src/components/catalog/dataquality/dataQualityTable';
import { DataQualityLineChart } from 'src/components/catalog/dataquality/dataQualityLineChart';

export interface DataQualityProps {
  setContentType: any;
  datasetId: string;
}

export const DataQuality = (props: DataQualityProps) => {
  const [dataQualityMetrics, setDataQualityMetrics] = useState([]);
  const [loadingDataQuality, setLoadingDataQuality] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedDataQuality, setSelectedDataQuality] = useState(null);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  useEffect(() => {
    handleListDataQualityMetrics(props.datasetId);
  }, [props.datasetId]);

  const handleListDataQualityMetrics = async (datasetId: string) => {
    setLoadingDataQuality(true);
    try {
      const response = await listDataQualityMetrics({
        ResourceARN: generateGlueArnFromId(datasetId),
        MaxResults: 10,
      });
      setDataQualityMetrics(response.DataQualityMetrics);
    } catch (err) {
      console.log(err);
      setNotifications([
        {
          type: 'error' as FlashbarProps.Type,
          content: `Failed to load data quality metrics.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    setLoadingDataQuality(false);
  };

  const calculateDQScore = (dataQualityMetric) => {
    if (dataQualityMetric.Data.metrics.length === 0) return 0;
    let pass = 0;
    if (dataQualityMetric.Type === 'GlueDQ') {
      for (let j = 0; j < dataQualityMetric.Data.metrics.length; j++) {
        if (dataQualityMetric.Data.metrics[j].result === 'PASS') pass++;
      }
    }
    return {
      score: (pass / dataQualityMetric.Data.metrics.length) * 100,
      pass: pass,
      total: dataQualityMetric.Data.metrics.length,
    };
  };

  return (
    <>
      <Flashbar items={notifications} />
      <SpaceBetween size={'l'}>
        <DataQualityLineChart dataQualityReports={dataQualityMetrics} loadingDataQuality={loadingDataQuality} />

        <DataQualityTable
          dataQualityReports={dataQualityMetrics}
          loadingDataQuality={loadingDataQuality}
          calculateDQScore={calculateDQScore}
          setSelectedItem={setSelectedDataQuality}
        />

        {selectedDataQuality !== null && selectedDataQuality.length > 0 && (
          <DataQualityReportTable dataQualityMetrics={selectedDataQuality[0]} />
        )}
      </SpaceBetween>
    </>
  );
};
