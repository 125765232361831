import { useEffect } from 'react';
import * as React from 'react';
import { ColumnLayout, Container, Form, FormField, Input } from '@amzn/awsui-components-react-v3';
import { listCatalogs } from 'src/api/catalog';
import { FOUNDRY_TRUSTED_ROLES, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { getFoundryRoleARN } from 'src/commons/common';
import { getRegion } from 'src/api/config';

export interface CatalogDetailsProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  CTI: any;
  catalogName: any;
  description: any;
  setCTI: any;
  setCatalogName: any;
  setDescription: any;
  setGetCatalogSuccess: any;
  getCatalogSuccess: boolean;
  setIsDefaultCatalog: any;
  isDefaultCatalog: boolean;
  setIsGroupBasedCatalog: any;
  isGroupBasedCatalog: boolean;
}

export const CatalogDetails = (props: CatalogDetailsProps) => {
  const getCatalog = async () => {
    try {
      const catalogs = await listCatalogs({
        Filter: {
          CatalogKeyList: [
            {
              CatalogId: props.activeWorkspace.accountId,
              Region: props.activeWorkspace.region,
            },
          ],
        },
      });
      if (catalogs !== undefined && catalogs.CatalogInfoList !== undefined && catalogs.CatalogInfoList.length > 0) {
        let catalog = catalogs.CatalogInfoList[0];
        props.setGetCatalogSuccess(true);
        if (FOUNDRY_TRUSTED_ROLES.includes(catalog.DataAccessRole)) {
          props.setIsDefaultCatalog(true);
        } else if (
          catalog.DataAccessRole == getFoundryRoleARN(props.activeWorkspace.region, props.activeWorkspace.accountId)
        ) {
          props.setCTI(catalog?.CTI);
          props.setDescription(catalog?.Description);
          props.setCatalogName(catalog?.Name);
          props.setGetCatalogSuccess(true);
        } else {
          props.setIsGroupBasedCatalog(true);
        }
      }
    } catch (err) {
      console.log('Exception when get catalog', err);
    }
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    getCatalog();
  };

  return (
    <div>
      <br />
      <Form>
        <Container className='custom-screenshot-hide' header={<h2>Catalog details</h2>}>
          <ColumnLayout>
            <FormField label={<div>Account ID</div>} description='The AWS account for the Glue catalog.'>
              <Input
                placeholder='Input the account ID here'
                ariaRequired={true}
                value={props.activeWorkspace.accountId}
                disabled
              />
            </FormField>

            <FormField label={<div>Catalog owner</div>} description='Catalog owner group or workspace ID.'>
              <Input
                placeholder='Input the group or workspace ID here'
                ariaRequired={true}
                value={props.activeWorkspace.workspaceName}
                disabled
              />
            </FormField>

            <FormField label={<div>Region</div>} description='The AWS region of the catalog.'>
              <Input ariaRequired={true} value={getRegion()} disabled />
            </FormField>

            <FormField label={<div>Catalog name</div>} description='Input the name for your catalog.'>
              {props.getCatalogSuccess && !props.isDefaultCatalog && !props.isGroupBasedCatalog ? (
                <Input placeholder='Enter catalog name here' ariaRequired={true} value={props.catalogName} disabled />
              ) : (
                <Input
                  placeholder='Enter catalog name here'
                  ariaRequired={true}
                  onChange={(event) => props.setCatalogName(event.detail.value)}
                  value={props.catalogName}
                />
              )}
            </FormField>

            <FormField label={<div>Catalog description</div>} description='Input the description for your catalog.'>
              {props.getCatalogSuccess && !props.isDefaultCatalog && !props.isGroupBasedCatalog ? (
                <Input placeholder='Enter description here' ariaRequired={true} value={props.description} disabled />
              ) : (
                <Input
                  placeholder='Enter description here'
                  ariaRequired={true}
                  onChange={(event) => props.setDescription(event.detail.value)}
                  value={props.description}
                />
              )}
            </FormField>

            <FormField label={<div>CTI</div>} description='Input a CTI for your catalog.'>
              {props.getCatalogSuccess && !props.isDefaultCatalog && !props.isGroupBasedCatalog ? (
                <Input
                  placeholder='Enter CTI here. Format: Category/Type/Item'
                  ariaRequired={true}
                  value={props.CTI}
                  disabled
                />
              ) : (
                <Input
                  placeholder='Enter CTI here. Format: Category/Type/Item'
                  ariaRequired={true}
                  onChange={(event) => props.setCTI(event.detail.value)}
                  value={props.CTI}
                />
              )}
            </FormField>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};
