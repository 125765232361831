import React, { useEffect, useState } from 'react';
import { DataAdvisoryTable } from 'src/components/dataadvisory/listAdvisoriesPage/advisoryTable';
import { DataAdvisoryTableFilter } from 'src/components/dataadvisory/listAdvisoriesPage/advisoryTableFilter';
import { ButtonDropdown, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { Redirect } from 'react-router-dom';
import { createCreateAdvisoryFromTemplateLink, Page } from 'src/routes';
import { ListAdvisoriesFilter, ListAdvisoriesRequest, ListAdvisoriesResponse } from 'aws-sdk/clients/awsdlomni';
import { listAdvisories } from 'src/api/notifications';
import { activeGroupOrWorkspaceId, getTemplateOptions } from 'src/components/dataadvisory/constants';
import { SelectAdvisoryTemplateModal } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/selectAdvisoryTemplateModal';

export interface ListAdvisoriesPageProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
}

export const ListAdvisoriesPage = (props: ListAdvisoriesPageProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [advisories, setAdvisories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextToken, setNextToken] = useState('');
  // this only gets updated when clicking the "Search" button in the filter component. Used for "Load more results" button
  const [currentSearchFilter, setCurrentSearchFilter] = useState(undefined);
  const [selectAdvisoryTemplateModalVisible, setSelectAdvisoryTemplateModalVisible] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);

  const DEFAULT_FILTER: ListAdvisoriesFilter = {
    quickFilters: {
      allAdvisories: false,
      advisoriesForDataYouHaveAccessTo: true,
      advisoriesForDataYouOwn: false,
    },
    statuses: {
      active: true,
      draft: false,
      resolved: false,
      canceled: false,
    },
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    search(DEFAULT_FILTER);
    fetchTemplates();
  }, []);

  const search = async (filter: ListAdvisoriesFilter) => {
    setLoading(true);
    setCurrentSearchFilter(filter);
    const request: ListAdvisoriesRequest = {
      filter: filter,
      ownerId: activeGroupOrWorkspaceId(props),
    };
    const response: ListAdvisoriesResponse = await listAdvisories(request);

    setAdvisories(response.advisories);
    setNextToken(response.nextToken);
    setLoading(false);
  };

  const fetchTemplates = async () => {
    const mappedItems = await getTemplateOptions(props);
    setTemplateOptions(mappedItems);
  };

  const loadMoreResults = async () => {
    let newAdvisories = [];
    newAdvisories.push(...advisories);

    const request: ListAdvisoriesRequest = {
      filter: currentSearchFilter,
      nextToken: nextToken,
      ownerId: activeGroupOrWorkspaceId(props),
    };
    const response: ListAdvisoriesResponse = await listAdvisories(request);

    newAdvisories.push(...response.advisories);
    setNextToken(response.nextToken);
    setAdvisories(newAdvisories);
  };

  const createAdvisoryFromTemplate = (templateId: string) => {
    setRedirect(createCreateAdvisoryFromTemplateLink(templateId));
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <SelectAdvisoryTemplateModal
        visible={selectAdvisoryTemplateModalVisible}
        templateOptions={templateOptions}
        onSelect={createAdvisoryFromTemplate}
        onClose={() => setSelectAdvisoryTemplateModalVisible(false)}
        activeGroup={props.activeGroup}
        confirmButtonText={'Select'}
        descriptionText={
          'Select a template to create an advisory from. You can save as a template when creating an advisory.'
        }
      />
      <SpaceBetween size={'s'}>
        <Header
          variant='h1'
          description={'View active issues, campaigns, alerts, and notifications for data in Omni.'}
          actions={
            <ButtonDropdown
              items={[
                { text: 'From scratch', id: 'scratch' },
                { text: 'From template', id: 'template' },
              ]}
              onItemClick={(e) => {
                if (e.detail.id == 'scratch') {
                  setRedirect(Page.CREATE_ADVISORY);
                } else {
                  setSelectAdvisoryTemplateModalVisible(true);
                }
              }}
            >
              Create advisory
            </ButtonDropdown>
          }
        >
          Data advisories
        </Header>
        <DataAdvisoryTableFilter {...props} onSearch={(filter: ListAdvisoriesFilter) => search(filter)} />
        <DataAdvisoryTable
          setContentType={props.setContentType}
          activeGroup={props.activeGroup}
          activeWorkspace={props.activeWorkspace}
          advisories={advisories}
          nextToken={nextToken}
          loadMoreResults={loadMoreResults}
          loading={loading}
        />
      </SpaceBetween>
    </>
  );
};
