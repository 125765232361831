import * as React from 'react';
import { useEffect, useState } from 'react';

import { scrollUp } from 'src/components/utils/navigation';
import { Button, Flashbar, Link, SpaceBetween, Wizard } from '@amzn/awsui-components-react-v3';

import { RedshiftDatasetsReview } from 'src/components/workspaces/dataRegister/redshift/redshiftDatasetsReview';
import { RedshiftCatalogDetails } from 'src/components/workspaces/dataRegister/redshift/redshiftCatalogDetails';
import { RedshiftDatasets } from 'src/components/workspaces/dataRegister/redshift/redshiftDatasets';
import { RedshiftSchemas } from 'src/components/workspaces/dataRegister/redshift/redshiftSchema';
import { RedshiftDatabases } from 'src/components/workspaces/dataRegister/redshift/redshiftDatabases';
import {
  editCatalog,
  registerCatalog,
  registerDatabase,
  registerDataSets,
  registerSchema,
  syncDataSets,
} from 'src/api/catalog';
import { registerRedshiftCluster } from 'src/api/permissions';
import { REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { createSchemaDetailLink } from 'src/routes';
import { isValidClusterUser } from 'src/commons/validationUtils';

export interface WorkspaceRedshiftRegisterProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  toggleHelp: any;
}

export const WorkspaceRedshiftRegister = (props: WorkspaceRedshiftRegisterProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const [notifications, setNotifications] = useState([]);
  const [registerItems, setRegisterItems] = useState([]);
  const [catalogName, setCatalogName] = useState(undefined);
  const [catalogDescription, setCatalogDescription] = useState(undefined);
  const [CTI, setCTI] = useState(undefined);
  const [getCatalogSuccess, setGetCatalogSuccess] = useState(false);
  const [selectedDatabaseName, setSelectedDatabaseName] = useState(undefined);
  const [selectedDatabaseDescription, setSelectedDatabaseDescription] = useState(undefined);
  const [selectedSchemaDescription, setSelectedSchemaDescription] = useState(undefined);
  const [selectedCluster, setSelectedCluster] = useState(undefined);
  const [isDefaultCatalog, setIsDefaultCatalog] = useState(false); // if catalog onboarded with trusted service
  const [isGroupBasedCatalog, setIsGroupBasedCatalog] = useState(false); // if catalog onboarded with Group based model
  const [isDbUserRegistered, setIsDbUserRegistered] = useState(false);
  const [, setRegisterDataPermissionType] = useState(undefined);
  const [selectedSchemaName, setSelectedSchemaName] = useState(undefined);

  const [loadingButton, setLoadingButton] = useState(false);

  const [clusterName, setClusterName] = useState(undefined);
  const [selectedDatabaseRegistered, setSelectedDatabaseRegistered] = useState(true);
  const [selectedSchemaRegistered, setSelectedSchemaRegistered] = useState(true);
  const [redshiftDbUserName, setRedshiftDbUserName] = useState(undefined);
  const [isDbUserDisabled, setIsDbUserDisabled] = useState(false);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const registerCatalogToHC = async () => {
    let registerCatalogRequest = {
      CatalogId: props.activeWorkspace.accountId,
      Region: props.activeWorkspace.region,
      Name: catalogName,
      Owner: props.activeWorkspace.workspaceId,
      CTI: CTI,
      Description: catalogDescription,
      DataAccessRole: props.activeWorkspace.workspaceRoleArn,
      DataClassification: 'Public',
      ClusterIdentifier: clusterName,
      DataSourceId: REDSHIFT_DATASOURCE_ID,
    };
    try {
      await registerCatalog(registerCatalogRequest);
    } catch (err) {
      if (err.name == 'InvalidParameterException' && err.message == 'Catalog already exists') {
        // we can't use the same request because edit currently doesn't support the data access role field
        await editCatalog({
          CatalogId: props.activeWorkspace.accountId,
          Region: props.activeWorkspace.region,
          Name: catalogName,
          Owner: props.activeWorkspace.workspaceId,
          CTI: CTI,
          Description: catalogDescription,
          DataClassification: 'Public',
          ClusterIdentifier: clusterName,
          DataSourceId: REDSHIFT_DATASOURCE_ID,
        });
      }
    }
  };

  const i18nStrings = {
    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: 'Submit',
    optional: 'optional',
  };

  const registerRedshiftDatabaseToHC = async () => {
    let registerDatabaseRequest = {
      CreatedBy: props.activeWorkspace.workspaceId,
      Region: props.activeWorkspace.region,
      DatabaseName: selectedDatabaseName,
      CatalogId: props.activeWorkspace.accountId,
      Description: selectedDatabaseDescription,
      Owners: [props.activeWorkspace.workspaceId],
      AutoTableOnboard: false,
      DataAccessRole: props.activeWorkspace.workspaceRoleArn,
      DataClassification: 'Public',
      DataSourceId: REDSHIFT_DATASOURCE_ID,
      ClusterIdentifier: clusterName,
    };
    await registerDatabase(registerDatabaseRequest);
  };

  const registerRedshiftSchemaToHC = async () => {
    let registerSchemaRequest = {
      Region: props.activeWorkspace.region,
      DatabaseName: selectedDatabaseName,
      CatalogId: props.activeWorkspace.accountId,
      Description: selectedSchemaDescription,
      Owners: [props.activeWorkspace.workspaceId],
      DataClassification: 'Public',
      DataSourceId: REDSHIFT_DATASOURCE_ID,
      ClusterIdentifier: clusterName,
      Schema: selectedSchemaName,
    };
    await registerSchema(registerSchemaRequest);
  };

  const transferRegisterItems = () => {
    const newItems = registerItems.map((item) => ({
      DatabaseName: selectedDatabaseName,
      TableName: item.label,
      ClusterIdentifier: clusterName,
      SchemaName: selectedSchemaName,
      DataSetName: item.label,
      PrimaryOwner: props.activeWorkspace.workspaceId,
      TableOwners: [props.activeWorkspace.workspaceId],
      DataClassification: 'Public',
      TableState: 'Active',
    }));
    return newItems;
  };

  const registerDatasetsToHC = async () => {
    let registerRequest = {
      CreatedBy: props.activeWorkspace.workspaceId,
      DataSourceId: REDSHIFT_DATASOURCE_ID,
      CatalogId: props.activeWorkspace.accountId,
      Region: props.activeWorkspace.region,
      DataSetList: transferRegisterItems(),
      DataAccessRole: props.activeWorkspace.workspaceRoleArn,
      ClusterIdentifier: clusterName,
    };
    let registerResponse;
    try {
      registerResponse = await registerDataSets(registerRequest);
    } catch (err) {
      console.error(err);
      throw err;
    }
    const idList = registerResponse.DataSetList.map((e) => e.Id);
    const syncRequest = {
      IdList: idList,
    };
    try {
      await syncDataSets(syncRequest);
    } catch (err) {
      console.log('Exception when syncing dataset', err);
      return;
    }

    let failedDatasets = registerResponse.DataSetList.filter((item) => item.message == 'Failed');
    if (failedDatasets != null && failedDatasets.length > 0) {
      setNotifications([
        {
          type: 'error',
          content:
            failedDatasets.map((item) => item.id) +
            ` failed to register because ` +
            failedDatasets.map((item) => item.reason),
          action: (
            <Button
              href={createSchemaDetailLink(
                props.activeWorkspace.accountId,
                clusterName,
                selectedDatabaseName,
                selectedSchemaName,
                props.activeWorkspace.region,
              )}
            >
              View schema
            </Button>
          ),
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'success',
          content: `Successfully registered the datasets.`,
          action: (
            <Button
              href={createSchemaDetailLink(
                props.activeWorkspace.accountId,
                clusterName,
                selectedDatabaseName,
                selectedSchemaName,
                props.activeWorkspace.region,
              )}
            >
              View schema
            </Button>
          ),
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const registerWSRedshiftCluster = async () => {
    if (!isValidClusterUser(redshiftDbUserName)) {
      throw Error('redshift user name not valid');
    }
    let registerRedshiftClusterRequest = {
      workspaceId: props.activeWorkspace.workspaceId,
      accountId: props.activeWorkspace.accountId,
      region: props.activeWorkspace.region,
      clusterId: clusterName,
      redshiftDbUser: redshiftDbUserName,
    };
    await registerRedshiftCluster(registerRedshiftClusterRequest);
  };

  const buildErrorMessage = (err) => {
    if (!err?.message) return '';
    if (err.message.includes('Member must satisfy regular expression pattern')) {
      return err.message + ' Only standard identifiers are supported';
    }
    return err.message;
  };

  const handleSubmit = async () => {
    setLoadingButton(true);
    if (registerItems == undefined || registerItems.length == 0) {
      setNotifications([
        {
          type: 'error',
          content: `Please select dataset(s) to register`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setLoadingButton(false);
      return;
    }

    setNotifications([
      {
        loading: true,
        content: `Datasets are being registered.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);

    try {
      await registerDatasetsToHC();
    } catch (e) {
      setLoadingButton(false);

      setNotifications([
        {
          type: 'error',
          content: `Failed to register the selected datasets. `,
          e,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    setLoadingButton(false);
    scrollUp();
  };

  const handleWizardNavigation = async (detail) => {
    setLoadingButton(true);
    setNotifications([]); // reset notifications
    if (activeStepIndex == 0 && detail.requestedStepIndex == 1) {
      if (getCatalogSuccess && (isGroupBasedCatalog || isDefaultCatalog)) {
        try {
          if (!isDbUserRegistered) {
            await registerWSRedshiftCluster();
          }
          await registerCatalogToHC();
          setNotifications([
            {
              type: 'success',
              content: `Successfully registered catalog`,
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        } catch (err) {
          setNotifications([
            {
              type: 'error',
              content: `Failed to register catalog. ` + buildErrorMessage(err),
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
          setLoadingButton(false);
          scrollUp();
          return;
        }
      } else if (!getCatalogSuccess) {
        try {
          if (!isDbUserRegistered) {
            await registerWSRedshiftCluster();
          }
          await registerCatalogToHC();
          setNotifications([
            {
              type: 'success',
              content: `Successfully registered catalog`,
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        } catch (err) {
          setNotifications([
            {
              type: 'error',
              content: `Failed to register catalog. ` + buildErrorMessage(err),
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
          setLoadingButton(false);
          console.log(err);
          return;
        }
      }
    }

    if (activeStepIndex == 1 && detail.requestedStepIndex == 2) {
      if (selectedDatabaseName == undefined) {
        setNotifications([
          {
            type: 'error',
            content: `Select a database to continue`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
        setLoadingButton(false);
        scrollUp();
        return;
      }
      if (!selectedDatabaseRegistered) {
        try {
          await registerRedshiftDatabaseToHC();
          setNotifications([
            {
              type: 'success',
              content: `Successfully registered database`,
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        } catch (err) {
          console.log(err);
          setNotifications([
            {
              type: 'error',
              content: `Database register error` + buildErrorMessage(err),
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
          setLoadingButton(false);
          return;
        }
      }
    }

    if (activeStepIndex == 2 && detail.requestedStepIndex == 3) {
      if (selectedSchemaName == undefined) {
        setNotifications([
          {
            type: 'error',
            content: `Select a schema to continue`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
        setLoadingButton(false);
        scrollUp();
        return;
      }
      if (!selectedSchemaRegistered) {
        try {
          await registerRedshiftSchemaToHC();
          setNotifications([
            {
              type: 'success',
              content: `Successfully registered schema`,
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        } catch (err) {
          console.log(err);
          setNotifications([
            {
              type: 'error',
              content: `Schema register error ` + buildErrorMessage(err),
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
          setLoadingButton(false);
          return;
        }
      }
    }

    if (activeStepIndex == 3 && detail.requestedStepIndex == 4) {
      if (registerItems == undefined || registerItems.length == 0) {
        setNotifications([
          {
            type: 'error',
            content: `Add the selected items to list by clicking "Add to register list"`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
        scrollUp();
        setLoadingButton(false);
        return;
      }
    }

    setActiveStepIndex(detail.requestedStepIndex);
    setLoadingButton(false);
  };

  return (
    <div>
      <SpaceBetween size='xs'>
        <Flashbar items={notifications}></Flashbar>
      </SpaceBetween>
      <Wizard
        activeStepIndex={activeStepIndex}
        steps={[
          {
            title: 'Register/Select catalog',
            description: 'Select registered catalog or register a new catalog before next step',
            info: (
              <Link variant='info' onFollow={props.toggleHelp}>
                {' '}
                Info{' '}
              </Link>
            ),
            content: (
              <>
                <RedshiftCatalogDetails
                  {...props}
                  CTI={CTI}
                  catalogName={catalogName}
                  description={catalogDescription}
                  setCTI={setCTI}
                  setCatalogName={setCatalogName}
                  setDescription={setCatalogDescription}
                  setGetCatalogSuccess={setGetCatalogSuccess}
                  getCatalogSuccess={getCatalogSuccess}
                  setIsDefaultCatalog={setIsDefaultCatalog}
                  isDefaultCatalog={isDefaultCatalog}
                  setIsGroupBasedCatalog={setIsGroupBasedCatalog}
                  isGroupBasedCatalog={isGroupBasedCatalog}
                  setRedshiftDbUserName={setRedshiftDbUserName}
                  setClusterName={setClusterName}
                  clusterName={clusterName}
                  redshiftDbUserName={redshiftDbUserName}
                  isDbUserRegistered={isDbUserRegistered}
                  setIsDbUserRegistered={setIsDbUserRegistered}
                  selectedCluster={selectedCluster}
                  setSelectedCluster={setSelectedCluster}
                  isDbUserDisabled={isDbUserDisabled}
                  setIsDbUserDisabled={setIsDbUserDisabled}
                />
              </>
            ),
          },
          {
            title: 'Register/Select database',
            description:
              'Select a registered database or register a new database before next step. Only standard identifiers are supported.',
            info: (
              <Link variant='info' onFollow={props.toggleHelp}>
                {' '}
                Info{' '}
              </Link>
            ),
            content: (
              <>
                <RedshiftDatabases
                  {...props}
                  selectedDatabaseDescription={selectedDatabaseDescription}
                  selectedDatabaseName={selectedDatabaseName}
                  setSelectedDatabaseDescription={setSelectedDatabaseDescription}
                  setSelectedDatabaseName={setSelectedDatabaseName}
                  setSelectedDatabaseRegistered={setSelectedDatabaseRegistered}
                  clusterName={clusterName}
                  setNotifications={setNotifications}
                  onDatabaseLoadFailure={() => {
                    // allow setting a new user if we can't list databases
                    setGetCatalogSuccess(false);
                    setIsDbUserDisabled(false);
                    setIsDbUserRegistered(false);
                  }}
                />
              </>
            ),
          },
          {
            title: 'Register/Select schema',
            description:
              'Select a registered schema or register a new schema before next step. Only standard identifiers are supported.',
            info: (
              <Link variant='info' onFollow={props.toggleHelp}>
                {' '}
                Info{' '}
              </Link>
            ),
            content: (
              <>
                <RedshiftSchemas
                  {...props}
                  selectedDatabaseDescription={selectedDatabaseDescription}
                  selectedDatabaseName={selectedDatabaseName}
                  setSelectedSchemaDescription={setSelectedSchemaDescription}
                  setSelectedSchemaName={setSelectedSchemaName}
                  selectedSchemaName={selectedSchemaName}
                  setSelectedSchemaRegistered={setSelectedSchemaRegistered}
                  clusterName={clusterName}
                  setNotifications={setNotifications}
                />
              </>
            ),
          },
          {
            title: 'Select datasets',
            description: 'Select datasets in the selected schema. Only standard identifiers are supported.',
            info: (
              <Link variant='info' onFollow={props.toggleHelp}>
                {' '}
                Info{' '}
              </Link>
            ),
            content: (
              <>
                <RedshiftDatasets
                  {...props}
                  setRegisterItems={setRegisterItems}
                  registerItems={registerItems}
                  selectedDatabaseName={selectedDatabaseName}
                  setRegisterDataPermissionType={setRegisterDataPermissionType}
                  setNotifications={setNotifications}
                  selectedSchemaName={selectedSchemaName}
                  setSelectedSchemaName={setSelectedSchemaName}
                  clusterName={clusterName}
                />
              </>
            ),
          },
          {
            title: 'Review datasets to register',
            description: 'Review selected datasets in the selected database/schema',
            info: (
              <Link variant='info' onFollow={props.toggleHelp}>
                {' '}
                Info{' '}
              </Link>
            ),
            content: (
              <>
                <RedshiftDatasetsReview
                  {...props}
                  registerItems={registerItems}
                  selectedDatabaseName={selectedDatabaseName}
                  catalogName={catalogName}
                />
              </>
            ),
          },
        ]}
        onNavigate={({ detail }) => handleWizardNavigation(detail)}
        i18nStrings={i18nStrings}
        onSubmit={handleSubmit}
        onCancel={() => {
          setActiveStepIndex(0);
        }}
        isLoadingNextStep={loadingButton}
      />
    </div>
  );
};
