import * as React from 'react';
import { useState, useEffect } from 'react';

import { activeGroupOrWorkspaceId, notificationsToFlashbar } from 'src/components/dataadvisory/constants';
import { Alert, ButtonDropdown, Header, Link, SpaceBetween, Spinner, Table } from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { ConfirmDeleteTemplateModal } from 'src/components/dataadvisory/templates/confirmDeleteTemplateModal';
import {
  DeleteTemplateRequest,
  ListTemplatesRequest,
  ListTemplatesResult,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { deleteTemplate, listTemplates } from 'src/api/permissions';
import {
  createAdvisoryTemplateDetailsLink,
  createCreateAdvisoryFromTemplateLink,
  createUpdateAdvisoryTemplateLink,
} from 'src/routes';

export interface ManageTemplatesPageProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
}

export const ManageTemplatesPage = (props: ManageTemplatesPageProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [templates, setTemplates] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failedToLoad, setFailedToLoad] = useState(false);

  useEffect(() => {
    props.setContentType('form');
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const request: ListTemplatesRequest = {
        templateType: 'DataAdvisory',
        ownerId: activeGroupOrWorkspaceId(props),
      };
      const response: ListTemplatesResult = await listTemplates(request);
      if (response.templates) {
        setTemplates(response.templates);
      }
    } catch (e) {
      setFailedToLoad(true);
    }
    setLoading(false);
  };

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'name',
      header: 'Template name',
      cell: (item) => (
        <Link onFollow={() => setRedirect(createAdvisoryTemplateDetailsLink(item.templateId))}>{item.name}</Link>
      ),
      minWidth: 300,
      sortingField: 'name',
    },
    {
      id: 'description',
      header: 'Template description',
      cell: (item) => item.description,
      minWidth: 300,
      sortingField: 'name',
    },
  ];

  const itemIsSelected = () => {
    return selectedItems.length > 0;
  };

  const selectedItem = () => {
    return selectedItems[0];
  };

  const handleDeleteTemplate = async (templateId: string) => {
    try {
      const request: DeleteTemplateRequest = {
        templateId: templateId,
      };
      await deleteTemplate(request);
      setNotificationForSuccessfulDelete();
    } catch (e) {
      setNotificationForFailedDelete(e);
    } finally {
      await handleRefresh();
      setDeleteModalVisible(false);
    }
  };

  const setNotificationForFailedDelete = (e) => {
    setNotifications([
      {
        type: 'error',
        content: 'Failed to delete the template: ' + e.message,
      },
    ]);
  };

  const setNotificationForSuccessfulDelete = () => {
    setNotifications([
      {
        type: 'success',
        content: 'Successfully deleted the template.',
      },
    ]);
  };

  if (failedToLoad) {
    return (
      <Alert type={'error'} statusIconAriaLabel={'Error'} header={'Unable to load templates'}>
        Please try again later, or contact Omni oncall.
      </Alert>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <SpaceBetween size={'m'}>
      {redirect && <Redirect to={redirect} />}
      {notifications.length > 0 && notificationsToFlashbar(notifications, setNotifications)}
      <SpaceBetween size={'l'} direction={'vertical'}>
        <Header
          variant={'h1'}
          description={
            'Use templates to pre-populate content when creating new advisories. To create a new advisory template, select "Save as template" when creating an advisory.'
          }
        >
          Advisory templates
        </Header>
        <ConfirmDeleteTemplateModal
          visible={deleteModalVisible}
          onDelete={() => handleDeleteTemplate(selectedItem()?.templateId)}
          onCancel={() => setDeleteModalVisible(false)}
          templateName={selectedItem()?.templateName}
        />
        <Table
          columnDefinitions={columnDefinitions}
          items={templates}
          resizableColumns={true}
          header={
            <Header
              counter={'(' + templates.length + ')'}
              actions={
                <SpaceBetween direction='horizontal' size='s'>
                  <ButtonDropdown
                    items={[
                      { text: 'Edit', id: 'edit', disabled: !itemIsSelected() },
                      {
                        text: 'Delete',
                        id: 'delete',
                        disabled: !itemIsSelected(),
                      },
                      {
                        text: 'Create advisory from template',
                        id: 'create',
                        disabled: !itemIsSelected(),
                      },
                    ]}
                    onItemClick={(e) => {
                      if (e.detail.id == 'edit') {
                        setRedirect(createUpdateAdvisoryTemplateLink(selectedItem()?.templateId));
                      } else if (e.detail.id == 'delete') {
                        setDeleteModalVisible(true);
                      } else if (e.detail.id == 'create') {
                        setRedirect(createCreateAdvisoryFromTemplateLink(selectedItem()?.templateId));
                      }
                    }}
                  >
                    Actions
                  </ButtonDropdown>
                </SpaceBetween>
              }
            >
              Templates
            </Header>
          }
          selectionType={'single'}
          selectedItems={selectedItems}
          onSelectionChange={({ detail }) => {
            setSelectedItems(detail.selectedItems);
          }}
        />
      </SpaceBetween>
    </SpaceBetween>
  );
};
