import { DeDupKeyColumnList } from 'aws-sdk/clients/tethyscontractservicelambda';
import { TokenGroup } from '@amzn/awsui-components-react-v3';
import * as React from 'react';

export const PrimaryKeys = ({ keys }: { keys: string[] }) => (
  <TokenGroup items={keys.map((key) => ({ label: key, disabled: true }))} />
);

export const DedupKeys = ({ keys = [] }: { keys: DeDupKeyColumnList }) => (
  <TokenGroup
    items={keys.map(({ ColumnName, SortOrder }) => ({
      label: `${ColumnName} (${SortOrder})`,
      disabled: true,
    }))}
  />
);
