import React, { useEffect, useState } from 'react';
import { Spinner, Tabs } from '@amzn/awsui-components-react-v3';
import { WorkspaceDetail } from 'src/components/workspaces/workspaceManagement/detail';
import { WorkspaceResourceStatus } from 'src/components/workspaces/workspaceManagement/resourceStatus';
import { getWorkspace } from 'src/api/permissions';
import { ClusterManagement } from 'src/components/workspaces/workspaceManagement/clusterManagement';
import { ManageRoles } from 'src/components/groups/manageRoles';

export interface WorkspaceDetailTabProps {
  tabId: string | undefined;
  setContentType: any;
  match: any;
  activeGroup: string;
  workspace: any;
}

export const WorkspaceDetailTab = (props: WorkspaceDetailTabProps) => {
  const [activeTabId, setActiveTabId] = useState('detail');
  const [workspace, setWorkspace] = useState(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    if (!props.activeGroup && !props.match.params.workspaceId) return;
    setLoading(true);
    try {
      let getWorkspaceResponse = await getWorkspace({ workspaceId: props.match.params.workspaceId });
      setWorkspace(getWorkspaceResponse.workspace);
    } catch (err) {
      console.log('Exception when get workspace for group', err);
    }
    setLoading(false);
  };

  const tabs = [
    {
      label: 'Detail',
      id: 'detail',
      content: workspace !== undefined && (
        <WorkspaceDetail {...props} workspace={workspace} setActiveTabId={setActiveTabId} refresh={handleRefresh} />
      ),
    },
    {
      label: 'Resource status',
      id: 'resourceStatus',
      content: (
        <>
          <WorkspaceResourceStatus
            {...props}
            service={workspace?.workspaceConfig?.serviceConfig['FoundryCatalogManager']}
            setActiveTabId={setActiveTabId}
            name={'FoundryCatalogManager'}
          />
          <br />

          <WorkspaceResourceStatus
            {...props}
            service={workspace?.workspaceConfig?.serviceConfig['FoundryAccessLogging']}
            setActiveTabId={setActiveTabId}
            name={'FoundryAccessLogging'}
          />
          <br />

          <WorkspaceResourceStatus
            {...props}
            service={workspace?.workspaceConfig?.serviceConfig['FoundryAnalyticsManager']}
            setActiveTabId={setActiveTabId}
            name={'FoundryAnalyticsManager'}
          />
        </>
      ),
    },
    {
      label: 'Cluster management',
      id: 'clusterManagement',
      content: workspace !== undefined && (
        <ClusterManagement {...props} workspace={workspace} setActiveTabId={setActiveTabId} refresh={handleRefresh} />
      ),
    },
    {
      label: 'Roles',
      id: 'roles',
      content: workspace !== undefined && (
        <ManageRoles
          ownerId={props.match.params.workspaceId}
          roleMapping={workspace ? workspace.roleMapping : undefined}
        />
      ),
    },
  ];

  const handleChange = async (e) => {
    await setActiveTabId(e.detail.activeTabId);
  };

  if (loading) {
    return <Spinner size='large' />;
  }

  return (
    <>
      <Tabs tabs={tabs} variant='default' onChange={handleChange} activeTabId={activeTabId} />
    </>
  );
};
