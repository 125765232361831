import { Tabs, Alert, Spinner, SpaceBetween, FlashbarProps } from '@amzn/awsui-components-react-v3';
import { SchemaTab } from './tabs/schema';
import { ContractTab } from './tabs/contract';
import { PublishModal } from './PublishModal';
import { getDataSetId } from '../common/utils';
import { ContractHeader } from './ContractHeader';

import React, { useEffect, useState } from 'react';
import { getDataset, registerNotification } from '../../../api/ingestion';
import { iTethysRouteProps } from '../common/types';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { iIngestionComponentProps } from '../registerContract';
import { GetDataSetResponse, S3NotificationConfig } from 'aws-sdk/clients/tethyscontractservicelambda';

interface iDatasetDetail extends iIngestionComponentProps {
  params: iTethysRouteProps;
  setNotifications(notifications: FlashbarProps.MessageDefinition[]): void;
}

export const DatasetDetail = ({ params, setContentType, setNotifications }: iDatasetDetail) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const [visible, setVisible] = useState(false);
  const [DataSetId, setDatasetId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [dataset, setDataset] = useState<GetDataSetResponse>();

  useEffect(() => {
    setContentType(TABLE_CONTENT_TYPE);
    const id = getDataSetId(params);

    fetchDataset(id);
    setDatasetId(id);
  }, []);

  const fetchDataset = async (id: string) => {
    setLoading(true);
    try {
      const dataset = await getDataset({ DataSetId: id });
      setDataset(dataset);
    } catch (e) {
      setErrorMsg(true);
    }
    setLoading(false);
  };

  const addQueue = async (S3NotificationConfig: S3NotificationConfig) => {
    setVisible(false);
    setLoading(true);
    try {
      await registerNotification({
        DataSetId,
        NotificationType: 'S3',
        S3NotificationConfig,
      });
      setNotifications([
        {
          type: 'success',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: 'Notification registered successfully.',
        },
      ]);
    } catch (e) {
      setNotifications([
        {
          type: 'error',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: e.message,
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const tabs = [
    {
      label: 'Data contract',
      id: 'contract',
      content: <ContractTab dataset={dataset} arn={params.arn} />,
    },
    {
      label: 'Schema definition',
      id: 'schema',
      content: <SchemaTab dataset={dataset} />,
    },
  ];

  if (loading) return <Spinner />;
  if (errorMsg || !dataset.DataContract)
    return <Alert type='warning'>Error loading dataset. Contact Tethys Support (AWS-DI).</Alert>;

  return (
    <>
      <PublishModal visible={visible} setVisible={setVisible} addQueue={addQueue} />
      <SpaceBetween size='l'>
        <ContractHeader dataset={dataset} setVisible={setVisible} />
        <Tabs tabs={tabs} ariaLabel='Resource details' />
      </SpaceBetween>
    </>
  );
};
