import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '@amzn/awsui-components-react/index.css';
import { BrowserRouter, Route } from 'react-router-dom';

ReactDOM.render(
  <div className='awsui'>
    <BrowserRouter>
      <React.StrictMode>
        <Route component={App} />
      </React.StrictMode>
    </BrowserRouter>
  </div>,
  document.getElementById('app'),
);
