import * as React from 'react';
import {
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Container,
  Input,
  Multiselect,
  Header,
  Box,
  Button,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import {
  convertDataSetsToOptions,
  convertTargetsToOptions,
  createSubscription,
  listDatasets,
  listTargets,
  syncSubscription,
} from 'src/api/subscriptions';
import { Redirect } from 'react-router-dom';
import { scrollUp } from '../utils/navigation';
import * as validate from '../../commons/validationUtils';
import { Page } from 'src/routes';
import { useEffect, useState } from 'react';
import { FORM_CONTENT_TYPE } from 'src/commons/constants';

export interface CreateSubscriptionComponentProps {
  setContentType: any;
  location: any;
  activeGroup: string;
}

export const CreateSubscriptionComponent = (props: CreateSubscriptionComponentProps) => {
  const [iamRolePlaceholder, setIamRolePlaceholder] = useState('arn:aws:iam::<yourAccount>:role/MyClusterRole');
  const [iamRole, setIamRole] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [database, setDatabase] = useState(null);
  const [schema, setSchema] = useState('public');
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [selectedTables, setSelectedTables] = useState(undefined);
  const [targetsStatusType, setTargetsStatusType] = useState(null);
  const [datasetsStatusType, setDatasetsStatusType] = useState(null);
  const [tableInfo, settableInfo] = useState(null);
  const [tableName, setTableName] = useState('tableName');
  const [tables, setTables] = useState([]);
  const [targets, setTargets] = useState([]);
  const [targetsRaw, setTargetsRaw] = useState({});
  const [redirect, setRedirect] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonText, setButtonText] = useState('Create subscription');

  const getTargetsById = (targets) => {
    let results = {};
    targets.targets.forEach((t) => (results[t.targetId] = t));
    return results;
  };

  useEffect(() => {
    // if we were redirected here from the catalog page,
    // replace some state values with the props passed in
    if (props.location.state) {
      setSelectedTables(props.location.state.selectedTables);
      settableInfo(props.location.state.tableInfo);
      setTableName(props.location.state.tableName);
    }
    props.setContentType(FORM_CONTENT_TYPE);
    fetchDatasets();
    fetchTargets();
  }, []);

  const fetchTargets = async () => {
    setTargetsStatusType('loading');
    const targets = await listTargets({
      ownerId: props.activeGroup,
    });
    setTargets(convertTargetsToOptions(targets));
    setTargetsRaw(getTargetsById(targets));
    setTargetsStatusType('finished');
  };

  const fetchDatasets = async () => {
    setDatasetsStatusType('loading');
    const datasets = await listDatasets({
      ownerId: props.activeGroup,
      type: 'HYBRID_CATALOG',
    });
    setDatasetsStatusType('finished');
    setTables(convertDataSetsToOptions(datasets));
  };

  const createAndEnableSubscription = async (request) => {
    const subscription = await createSubscription(request);
    await syncSubscription({
      subscriptionId: subscription.subscriptionId,
    });
    return subscription.subscriptionId;
  };

  const handleSingleSubscription = async () => {
    let target = selectedTargets[0];
    const subscriptionId = await createAndEnableSubscription({
      database: targetsRaw[target.id].defaultDatabase,
      datasetId: tableInfo.id,
      iamRole: iamRole,
      ownerId: props.activeGroup,
      schema: schema,
      table: tableName,
      targetId: target.id,
    });
    setNotifications([
      {
        type: 'success',
        content: `Subscription created with ID ${subscriptionId}.`,
        dismissible: true,
        action: (
          <Button
            onClick={() => {
              setRedirect(`/subscriptions/${subscriptionId}`);
            }}
          >
            View subscription
          </Button>
        ),
        onDismiss: () => setNotifications([]),
      },
    ]);

    scrollUp();
  };

  const handleManySubscriptions = async () => {
    let subscriptions = [];
    for (var i = 0; i < selectedTables.length; i++) {
      for (var j = 0; j < selectedTargets.length; j++) {
        let dataset = selectedTables[i];
        let target = selectedTargets[j];
        subscriptions.push(
          createAndEnableSubscription({
            database: targetsRaw[target.id].defaultDatabase,
            datasetId: dataset.id,
            iamRole: targetsRaw[target.id].defaultAttachedRole,
            ownerId: props.activeGroup,
            schema: targetsRaw[target.id].defaultSchema,
            table: dataset.description,
            targetId: target.id,
          }),
        );
      }
    }
    await Promise.all(subscriptions);
    setNotifications([
      {
        type: 'success',
        content: `Subscriptions created.`,
        dismissible: true,
        buttonText: 'View subscriptions',
        buttonClick: () => setRedirect(Page.SUBSCRIPTIONS),
        dismiss: () => setNotifications([]),
      },
    ]);
    scrollUp();
  };

  const handleConfirm = async () => {
    setButtonLoading(true);
    if (getNumberOfSubscriptions() == 1) {
      await handleSingleSubscription();
    } else {
      await handleManySubscriptions();
    }
    setButtonLoading(false);
  };

  const getNumberOfSubscriptions = () => {
    if (selectedTables == undefined) {
      return 0;
    }

    return (selectedTables.length || 1) * (selectedTargets.length || 1);
  };

  const updateButtonText = () => {
    if (getNumberOfSubscriptions() > 1) {
      setButtonText('Create subscriptions');
    } else {
      setButtonText('Create subscription');
    }
  };

  const onTargetSelect = (e) => {
    let selected = e.detail.selectedOptions;
    if (selected.length > 0) {
      let targetId = selected[0].id;
      setSelectedTargets(selected);
      setIamRole(targetsRaw[targetId].defaultAttachedRole);
      setIamRolePlaceholder(null);
      setDatabase(targetsRaw[targetId].defaultDatabase);
    } else {
      setSelectedTargets(selected);
      setIamRole(null);
      setIamRolePlaceholder('arn:aws:iam::<yourAccount>:role/MyClusterRole');
      setDatabase('');
    }
    updateButtonText();
  };

  const allFieldsSet = () => {
    if (getNumberOfSubscriptions() == 1) {
      return (
        selectedTables != undefined &&
        selectedTables.length > 0 &&
        selectedTargets.length > 0 &&
        validate.isValidRole(iamRole) &&
        validate.isNotFalsy(database) &&
        validate.isNotFalsy(schema) &&
        validate.isNotFalsy(tableName)
      );
    } else if (getNumberOfSubscriptions() > 1) {
      // For bulk subscriptions the default values are used.
      return selectedTables != undefined && selectedTables.length > 0 && selectedTargets.length > 0;
    } else {
      return false;
    }
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header
            description='You can subscribe to a Data Lake table by specifying a target Redshift cluster.'
            variant='h1'
          >
            <Box display='inline-block'>Create new subscription</Box>
          </Header>
        }
        actions={
          <div>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={() => setRedirect(Page.SUBSCRIPTIONS)}>
                Cancel
              </Button>
              <Button variant='primary' onClick={handleConfirm} loading={buttonLoading} disabled={!allFieldsSet()}>
                {buttonText}
              </Button>
            </SpaceBetween>
          </div>
        }
      >
        <Container className='custom-screenshot-hide' header={<Header variant='h2'>Subscription settings</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label={<div>Data Lake table</div>}
                description='Please select the Data Lake table(s) to subscribe to. You can request access to tables the Catalog section.'
              >
                <Multiselect
                  selectedAriaLabel='Selected'
                  options={tables}
                  selectedOptions={selectedTables}
                  onChange={({ detail }) => {
                    if (detail.selectedOptions.length == 1) {
                      settableInfo(detail.selectedOptions[0]);
                      setTableName(detail.selectedOptions[0].description);
                    }
                    setSelectedTables(detail.selectedOptions);
                    updateButtonText();
                  }}
                  placeholder='Select the table(s) that you want to subscribe to'
                  errorText='Error fetching type.'
                  recoveryText='Retry'
                  empty={'No types available'}
                  filteringType='auto'
                  filteringAriaLabel='Filter origins'
                  ariaRequired={true}
                  keepOpen={false}
                  statusType={datasetsStatusType}
                  loadingText='Loading tables...'
                />
              </FormField>
              <FormField
                label={<div>Target connection</div>}
                description='The connection that corresponds to the Redshift cluster where you want the data.'
              >
                <Multiselect
                  selectedOptions={selectedTargets}
                  options={targets}
                  selectedAriaLabel='Selected'
                  onChange={onTargetSelect}
                  deselectAriaLabel={(option) => `Remove option ${option.label} from selection`}
                  placeholder='Select the connection(s) where you want to load the data'
                  errorText='Error fetching type.'
                  recoveryText='Retry'
                  empty={'No connections available'}
                  filteringType='manual'
                  ariaRequired={true}
                  statusType={targetsStatusType}
                  keepOpen={false}
                  loadingText='Loading connections...'
                />
              </FormField>

              {getNumberOfSubscriptions() <= 1 && (
                <>
                  <FormField
                    label={<div>Redshift IAM role</div>}
                    description='This role must be attached to your cluster and must the able to assume the Data Lake role for your account.'
                  >
                    <Input
                      name='redshift-iam-role'
                      placeholder={iamRolePlaceholder}
                      value={iamRole}
                      ariaRequired={true}
                      onChange={(e) => setIamRole(e.detail.value.trim())}
                      invalid={iamRole !== null && !validate.isValidRole(iamRole)}
                    />
                  </FormField>
                  <FormField
                    label={<div>Target database name</div>}
                    description='Input the name of the database where you want to create the new table.'
                  >
                    <Input
                      name='target-database-name'
                      value={database}
                      ariaRequired={true}
                      onChange={(e) => setDatabase(e.detail.value.trim())}
                      invalid={database !== null && validate.isFalsy(database)}
                    />
                  </FormField>
                  <FormField
                    label={<div>Target schema name</div>}
                    description='Input the name of the schema where you want to create the new table.'
                  >
                    <Input
                      name='target-schema-name'
                      value={schema}
                      ariaRequired={true}
                      onChange={(e) => setSchema(e.detail.value.trim())}
                      invalid={schema !== null && validate.isFalsy(schema)}
                    />
                  </FormField>
                  <FormField
                    label={<div>Target table name</div>}
                    description='You can choose the name of the table in your cluster or keep the default.'
                  >
                    <Input
                      name='target-table-name'
                      value={tableName}
                      ariaRequired={true}
                      onChange={(e) => {
                        setTableName(e.detail.value.trim());
                      }}
                      invalid={tableName !== null && validate.isFalsy(tableName)}
                    />
                  </FormField>
                </>
              )}
            </div>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};
