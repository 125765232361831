import { FormField, Input, SpaceBetween, Modal, Form, Button, Header } from '@amzn/awsui-components-react-v3';
import React, { useState } from 'react';

export interface SaveAsTemplateModalProps {
  visible: boolean;
  onSave: any;
  onClose: any;
}

export const SaveAsTemplateModal = (props: SaveAsTemplateModalProps) => {
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');

  return (
    <Modal
      visible={props.visible}
      header={'Save as template'}
      onDismiss={props.onClose}
      footer={
        <Header
          actions={
            <SpaceBetween size={'s'} direction={'horizontal'} alignItems={'end'}>
              <Button variant={'link'} onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                variant={'primary'}
                onClick={() => props.onSave(templateName, templateDescription)}
                disabled={templateName == undefined || templateName.length == 0}
              >
                Save
              </Button>
            </SpaceBetween>
          }
        />
      }
    >
      <Form>
        <FormField
          label={'Template name'}
          description={
            "Select a name for the template. All the data you've provided in the form will be cloned into the template, except for the attached tickets. You can use this template to quickly create future advisories."
          }
        >
          <Input value={templateName} onChange={(e) => setTemplateName(e.detail.value)} />
        </FormField>
      </Form>
      <Form>
        <FormField label={'Template description'} description={'Input a description for the template.'}>
          <Input value={templateDescription} onChange={(e) => setTemplateDescription(e.detail.value)} />
        </FormField>
      </Form>
    </Modal>
  );
};
