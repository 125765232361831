import * as React from 'react';
import { InputProps } from '@amzn/awsui-components-react-v3';
import _ from 'lodash';

import {
  validateAndSaveInput,
  updateFieldNoValidation,
  validateForm,
  validation,
  accountIdRegex,
  roleRegex,
} from '../validationUtils';
import TokenInput from '../../../../commons/tokenInput/tokenInput';
import { Token } from '../../../../commons/tokenInput/interface';

interface OrganizationsSelectorProps {
  label: string;
  placeholder: string;
  description: any;
  hintText: string;
  isAccountsOnly: boolean;
  parentFields: any;
  accountIdErrorText?: string;
  accountIdsErrorText?: string;
  handleInputChange: (event: any) => void;
  setFields: (newFields: any) => void;
}

interface OrganizationsSelectorState {
  fields: any;
  edited: boolean;
}

export default class OrganizationsSelector extends React.Component<
  OrganizationsSelectorProps,
  OrganizationsSelectorState
> {
  constructor(props: OrganizationsSelectorProps) {
    super(props);
    this.state = {
      edited: false,
      fields: {
        accountId: {
          errorText: '',
          value: '',
          validation: [validation.awsRoleField],
          isOptional: true,
        },
        accountIds: {
          errorText: '',
          value: [],
          validation: [],
          isOptional: true,
        },
      },
    };
  }

  componentDidMount() {}

  componentDidUpdate() {
    this.initializeFields();
  }

  initializeFields() {
    const { parentFields } = this.props;
    const { fields, edited } = this.state;
    const { accountIds } = fields;
    if (
      parentFields.accountIds.value &&
      parentFields.accountIds.value.length === 0 &&
      accountIds.value &&
      accountIds.value.length > 0
    ) {
      const newFields = updateFieldNoValidation(fields, 'accountIds', parentFields.accountIds.value);
      this.setState({ fields: newFields, edited: false });
    }
    if (
      !edited &&
      accountIds.value &&
      accountIds.value.length === 0 &&
      parentFields.accountIds.value &&
      parentFields.accountIds.value.length > 0
    ) {
      const newFields = updateFieldNoValidation(fields, 'accountIds', parentFields.accountIds.value);
      this.setState({ fields: newFields });
    }
  }

  setFields = (newFields: any) => {
    const { setFields } = this.props;
    let { parentFields } = this.props;
    parentFields = updateFieldNoValidation(parentFields, 'accountId', newFields.accountId.value);
    parentFields = updateFieldNoValidation(parentFields, 'accountIds', newFields.accountIds.value);
    setFields(parentFields);
    this.setState({
      fields: validateForm(newFields),
      edited: true,
    });
  };

  handleInputChange = (event: CustomEvent<InputProps.ChangeDetail>) => {
    const { handleInputChange } = this.props;
    const { fields } = this.state;
    this.setState({ fields: validateAndSaveInput(event, fields, 'accountId') });
    handleInputChange(event);
  };

  addAccountId = (newAccountId: string) => {
    const { fields } = this.state;
    const { accountIds } = fields;
    if (accountIds.value) {
      const newAccountIds = new Set(_.clone(accountIds.value));
      newAccountIds.add(newAccountId);
      updateFieldNoValidation(fields, 'accountIds', Array.from(newAccountIds));
      updateFieldNoValidation(fields, 'accountId', '');
      this.setFields(fields);
    }
  };

  handleAddToken = () => {
    const { fields } = this.state;
    const { accountId } = fields;
    if (!accountId.errorText && accountId.value) {
      this.addAccountId(accountId.value);
    }
  };

  handleEnter = (event: CustomEvent<InputProps.KeyDetail>) => {
    if (event.detail.key === 'Enter') this.handleAddToken();
  };

  handleBlur = () => this.handleAddToken();

  handleCloseToken = (index: number) => () => {
    const { fields } = this.state;
    const { accountIds } = fields;
    if (accountIds.value) {
      const newAccountIds = _.clone(accountIds.value);
      newAccountIds.splice(index, 1);
      updateFieldNoValidation(fields, 'accountIds', newAccountIds);
      this.setFields(fields);
    }
  };

  //The only current type is external 12 digit account.
  getIdType = (id: string) => {
    if (accountIdRegex.test(id)) return 'Account';
    if (roleRegex.test(id)) return 'Role';
  };

  render() {
    const { accountIdErrorText, description, label, placeholder } = this.props;
    const { fields } = this.state;
    const { accountIds, accountId } = fields;
    const tokens: Token[] = [];

    if (accountIds.value) {
      accountIds.value.forEach((accountId) => {
        tokens.push({
          label: accountId,
          description: this.getIdType(accountId),
        });
      });
    }

    return (
      <TokenInput
        onBlur={this.handleBlur}
        onInput={this.handleInputChange}
        onKeypress={this.handleEnter}
        onTokenClosed={this.handleCloseToken}
        label={label}
        placeholder={placeholder}
        description={description}
        id='accountId'
        tokens={tokens}
        value={accountId.value}
        errorText={accountId.errorText || accountIdErrorText}
        disabled={tokens.length > 0}
      />
    );
  }
}
