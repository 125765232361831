import * as React from 'react';
import { Fragment } from 'react';

import {
  ColumnLayout,
  Container,
  ExpandableSection,
  Header,
  SpaceBetween,
  Spinner,
  Table,
} from '@amzn/awsui-components-react-v3';
import { StatusIcon } from 'src/components/subscriptions/statusIcon';

export const Status = ({ name = '', value = '', loading = false }) => {
  value ? value + ' ' : '';
  return (
    <div>
      <div className='awsui-util-label'>{name}</div>
      {loading ? <Spinner /> : <div className='awsui-util-copy-text'>{<StatusIcon status={value} />}</div>}
    </div>
  );
};

export const TableDetailsComponent = ({ mandatoryData, additionalData, columnDefinitions, items }) => (
  <Fragment>
    <SpaceBetween size='l'>
      <Container header={<Header variant='h2'>Table details</Header>}>
        <ColumnLayout columns={2} variant='text-grid'>
          {mandatoryData}
        </ColumnLayout>
      </Container>
      <ExpandableSection header={<Header variant='h2'>More details</Header>} variant='container'>
        <ColumnLayout columns={2} variant='text-grid'>
          {additionalData}
        </ColumnLayout>
      </ExpandableSection>
      <ExpandableSection header={<Header variant='h2'>Column mapping</Header>} variant='container'>
        <Table
          loadingText='Loading tables...'
          columnDefinitions={columnDefinitions}
          items={items}
          wrapLines={false}
          resizableColumns={true}
          empty={
            <div className='awsui-util-t-c'>
              <div className='awsui-util-pt-s awsui-util-mb-xs'>
                <b>No executions</b>
              </div>
              <p className='awsui-util-mb-s'>No column mapping found.</p>
            </div>
          }
        />
      </ExpandableSection>
    </SpaceBetween>
  </Fragment>
);

export const DetailsComponent = ({ mandatoryData, additionalData }) => (
  <Fragment>
    <SpaceBetween size='l'>
      <Container header={<Header variant='h2'>Pipeline details</Header>}>
        <ColumnLayout columns={2} variant='text-grid'>
          {mandatoryData}
        </ColumnLayout>
      </Container>
      <ExpandableSection header={<Header variant='h2'>More details</Header>} variant='container'>
        <ColumnLayout columns={2} variant='text-grid'>
          <div data-awsui-column-layout-root='true'>{additionalData}</div>
        </ColumnLayout>
      </ExpandableSection>
    </SpaceBetween>
  </Fragment>
);
