import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ColumnLayout, Textarea } from '@amzn/awsui-components-react-v3';
import { deleteNotificationTemplate, getNotificationTemplate } from 'src/api/notifications';
import { PageHeader } from 'src/components/notifications/common';
import { Page } from 'src/routes';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface NotificationTemplateDetailProps {
  activeWorkspace: any;
  setContentType: any;
  match: any;
  activeGroup: string;
}

export const NotificationTemplateDetail = (props: NotificationTemplateDetailProps) => {
  const [template, setTemplate] = useState({});
  const [redirect, setRedirect] = useState(undefined);

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    const template = await getNotificationTemplate({
      NotificationTemplateId: props.match.params.NotificationTemplateId,
      NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
    });
    setTemplate(template.NotificationTemplate);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleDelete = async () => {
    await deleteNotificationTemplate({
      NotificationTemplateId: props.match.params.NotificationTemplateId,
      NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
    });
    setRedirect(Page.NOTIFICATION_TEMPLATES);
  };

  const handleUpdate = async () => {
    setRedirect('/notificationtemplates/' + props.match.params.NotificationTemplateId + '/update');
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      {props.activeGroup == template['OwnerGroupId'] && (
        <PageHeader
          buttons={[
            {
              text: '',
              icon: 'refresh',
              onItemClick: handleRefresh,
            },
            {
              text: 'Edit',
              onItemClick: handleUpdate,
            },
            { text: 'Delete', onItemClick: handleDelete },
          ]}
          header='Notification template details'
        />
      )}
      <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
          <h2>Details</h2>
        </div>
        <ColumnLayout columns={4} variant='text-grid'>
          <div>
            <div className='awsui-util-label'>ID</div>
            <div>{template['NotificationTemplateId']}</div>
          </div>

          <div>
            <div className='awsui-util-label'>Template name</div>
            <div> {template['NotificationTemplateName']}</div>
          </div>

          <div>
            <div className='awsui-util-label'>Owner group</div>
            <div> {template['OwnerGroupId']}</div>
          </div>
        </ColumnLayout>

        <div>
          <div className='awsui-util-container-header'>
            <h2>Template content</h2>
          </div>
          <Textarea value={template['Content']} rows={10} readOnly />
        </div>
      </div>
    </>
  );
};
