import {
  Alert,
  Button,
  Checkbox,
  FormField,
  Header,
  Modal,
  SpaceBetween,
  Spinner,
  Textarea,
  Tiles,
} from '@amzn/awsui-components-react-v3';
import React, { useState } from 'react';
import { AdvisoryCustomer, AdvisoryCustomerList, AdvisoryImpactedResourceList } from 'aws-sdk/clients/awsdlomni';
import { getGroupBatch, listWorkspaces } from 'src/api/permissions';
import { convertResourceArnToDpResource, getIdInfoFromArn, userOwnsResource } from 'src/components/utils/arnUtil';
import { getResourceNameFromArn } from 'src/components/dataadvisory/constants';
import { fetchActiveConsumerDataPermissions } from 'src/components/permissions/myBaselining/baseliningUtils';

export interface AddCustomerModalProps {
  impactedResources: AdvisoryImpactedResourceList;
  userInfo: any;
  visible: boolean;
  onClose: any;
  onDone: any;
}

export const AddCustomerModal = (props: AddCustomerModalProps) => {
  const [selectedMethod, setSelectedMethod] = useState(undefined);
  const [rawGroupOrWorkspaceIds, setRawGroupOrWorkspaceIds] = useState('');
  const [selectedImportEmailForGroups, setSelectedImportEmailForGroups] = useState(true);
  const [rawEmails, setRawEmails] = useState('');
  const [rawCcEmails, setRawCcEmails] = useState('');
  const [rawSlackChannels, setRawSlackChannels] = useState('');
  const [customersToAdd, setCustomersToAdd] = useState([]);
  const [selectedResourceArns, setSelectedResourceArns] = useState(
    new Set<string>(props.impactedResources.map((impactedResource) => impactedResource.resourceArn)),
  );

  enum AddCustomerMode {
    SelectMethod = 'SelectMethod',
    ImportByResource = 'ImportByResource',
    ImportByGroupOrWorkspace = 'ImportByGroupOrWorkspace',
    AddEmail = 'AddEmail',
    AddCcEmail = 'AddCcEmail',
    AddSlack = 'AddSlack',
    Loading = 'Loading',
    Done = 'Done',
  }
  const [mode, setMode] = useState(AddCustomerMode.SelectMethod);

  const METHOD_OPTIONS = [
    {
      label: 'Import by resource',
      description: 'Import email addresses from groups and workspaces who consume the selected resources.',
      value: AddCustomerMode.ImportByResource,
      disabled: props.impactedResources.length == 0,
    },
    {
      label: 'Import by group or workspace',
      description: 'Import email addresses from provided groups and workspaces.',
      value: AddCustomerMode.ImportByGroupOrWorkspace,
    },
    {
      label: 'Add email address',
      description: 'Add email addresses manually, not associated with a group or workspace.',
      value: AddCustomerMode.AddEmail,
    },
    {
      label: 'Add CC email address',
      description: 'Add email addresses as CC recipients.',
      value: AddCustomerMode.AddCcEmail,
    },
    {
      label: 'Add Slack channel',
      description: 'Add Slack channels manually, not associated with a group or workspace.',
      value: AddCustomerMode.AddSlack,
    },
  ];

  const selectMethodContent = () => {
    return (
      <>
        <FormField label={'Select method'} description={'Select how you would like to add customers.'}>
          <Tiles value={selectedMethod} items={METHOD_OPTIONS} onChange={(e) => setSelectedMethod(e.detail.value)} />
        </FormField>
      </>
    );
  };

  // moved into a helper because it is used twice
  const checkboxForImportingGroupEmail = () => {
    return (
      <Checkbox
        checked={selectedImportEmailForGroups}
        onChange={(e) => setSelectedImportEmailForGroups(e.detail.checked)}
      >
        Include group email address
      </Checkbox>
    );
  };

  const selectResourcesContent = () => {
    return (
      <>
        <p>
          Omni will fetch the groups and workspaces consuming the impacted resources, then add their email addresses as
          customers.
        </p>
        <Alert type={'info'}>
          You can only import customers for resources you own. Resources you don't own will be skipped.
        </Alert>
        <p>Import customers from resources:</p>
        <>
          {props.impactedResources.map((impactedResource) => (
            <Checkbox
              checked={selectedResourceArns.has(impactedResource.resourceArn)}
              onChange={(e) => {
                let newSelectedResourceArns = new Set<string>(selectedResourceArns);
                if (e.detail.checked) {
                  newSelectedResourceArns.add(impactedResource.resourceArn);
                } else {
                  newSelectedResourceArns.delete(impactedResource.resourceArn);
                }
                setSelectedResourceArns(newSelectedResourceArns);
              }}
            >
              {getResourceNameFromArn(impactedResource.resourceArn)}
            </Checkbox>
          ))}
        </>
        <SpaceBetween size={'s'} direction={'horizontal'}>
          <Button
            variant={'link'}
            onClick={() =>
              setSelectedResourceArns(
                new Set<string>(props.impactedResources.map((impactedResource) => impactedResource.resourceArn)),
              )
            }
          >
            Select all
          </Button>
          <Button variant={'link'} onClick={() => setSelectedResourceArns(new Set<string>())}>
            Deselect all
          </Button>
        </SpaceBetween>
        <br />
        <>{checkboxForImportingGroupEmail()}</>
      </>
    );
  };

  const inputGroupOrWorkspaceIdsContent = () => {
    return (
      <>
        <FormField
          label={'Add group or workspace IDs'}
          description={'Add one or multiple group or workspace IDs as a comma-separated or whitespace-separated list.'}
        >
          <Textarea
            value={rawGroupOrWorkspaceIds}
            onChange={(e) => setRawGroupOrWorkspaceIds(e.detail.value)}
            placeholder={'GroupId1, wks-WorkspaceId2'}
          />
        </FormField>
        {checkboxForImportingGroupEmail()}
      </>
    );
  };

  const inputEmailAddressesContentHelper = (isCc: boolean) => {
    const emailString = isCc ? 'CC email' : 'email';
    return (
      <>
        <FormField
          label={`Add ${emailString} addresses`}
          description={`Add one or multiple ${emailString} addresses as a comma-separated or whitespace-separated list.`}
        >
          <Textarea
            value={isCc ? rawCcEmails : rawEmails}
            onChange={(e) => {
              if (isCc) {
                setRawCcEmails(e.detail.value);
              } else {
                setRawEmails(e.detail.value);
              }
            }}
            placeholder={'email1@amazon.com, email2@amazon.com'}
          />
        </FormField>
      </>
    );
  };

  const inputEmailAddressesContent = () => inputEmailAddressesContentHelper(false);
  const inputCcEmailAddressesContent = () => inputEmailAddressesContentHelper(true);

  const inputSlackChannelsContent = () => {
    return (
      <>
        <FormField
          label={`Add Slack channels`}
          description={`Add one or multiple Slack channels as a comma-separated or whitespace-separated list. Including the # at the beginning is optional.`}
        >
          <Textarea
            value={rawSlackChannels}
            onChange={(e) => setRawSlackChannels(e.detail.value)}
            placeholder={'slack-channel-1, #slack-channel-2'}
          />
        </FormField>
      </>
    );
  };

  const loadingContent = () => {
    return <Spinner />;
  };

  const doneContent = () => {
    return <>Successfully added {customersToAdd.length} customers.</>;
  };

  const contentByMode = {
    [AddCustomerMode.SelectMethod]: selectMethodContent,
    [AddCustomerMode.ImportByResource]: selectResourcesContent,
    [AddCustomerMode.ImportByGroupOrWorkspace]: inputGroupOrWorkspaceIdsContent,
    [AddCustomerMode.AddEmail]: inputEmailAddressesContent,
    [AddCustomerMode.AddCcEmail]: inputCcEmailAddressesContent,
    [AddCustomerMode.AddSlack]: inputSlackChannelsContent,
    [AddCustomerMode.Loading]: loadingContent,
    [AddCustomerMode.Done]: doneContent,
  };

  const handleNextButtonClick = () => {
    if (mode == AddCustomerMode.SelectMethod) {
      // after selecting a method and clicking next, it will take you to the input form for that method.
      setMode(selectedMethod);
    } else if (mode == AddCustomerMode.Loading) {
      // it should not be possible to click Next while loading
      return;
    } else if (mode == AddCustomerMode.Done) {
      // after done, the Finish button should close the modal and add the customers
      handleDone();
    } else {
      // after providing inputs, we start the import process (mode == AddCustomerMode.Loading)
      startImport();
    }
  };

  const handleCancelButtonClick = () => {
    reset();
    props.onClose();
  };

  const handleDone = () => {
    props.onDone(customersToAdd);
    reset();
    props.onClose();
  };

  const convertRawToListOfString = (raw: string) => {
    // we want to support comma-separated and whitespace-separated strings.
    // so, replace all commas with spaces, trim, then split by whitespace.
    // and finally, dedupe.
    const withDuplicates = raw.replaceAll(',', ' ').trim().split(/\s+/);
    let setOfStrings = new Set<string>(withDuplicates);
    setOfStrings.delete('');
    return [...setOfStrings];
  };

  const startImport = async () => {
    const oldMode = mode;
    setMode(AddCustomerMode.Loading);

    if (oldMode == AddCustomerMode.ImportByResource) {
      setCustomersToAdd(
        await importByResource(props.userInfo, [...selectedResourceArns], selectedImportEmailForGroups),
      );
    } else if (oldMode == AddCustomerMode.ImportByGroupOrWorkspace) {
      setCustomersToAdd(
        await importByGroupOrWorkspaceId(
          convertRawToListOfString(rawGroupOrWorkspaceIds),
          selectedImportEmailForGroups,
        ),
      );
    } else if (oldMode == AddCustomerMode.AddEmail) {
      setCustomersToAdd(addByEmail(convertRawToListOfString(rawEmails)));
    } else if (oldMode == AddCustomerMode.AddCcEmail) {
      setCustomersToAdd(addByCcEmail(convertRawToListOfString(rawCcEmails)));
    } else if (oldMode == AddCustomerMode.AddSlack) {
      setCustomersToAdd(addBySlack(convertRawToListOfString(rawSlackChannels)));
    }

    setMode(AddCustomerMode.Done);
  };

  const reset = () => {
    setRawGroupOrWorkspaceIds('');
    setRawEmails('');
    setRawCcEmails('');
    setRawSlackChannels('');
    setMode(AddCustomerMode.SelectMethod);
    setSelectedImportEmailForGroups(true);
    setSelectedMethod(undefined);
  };

  const shouldDisableNextButton = () => {
    if (mode == AddCustomerMode.SelectMethod) return selectedMethod == undefined;
    if (mode == AddCustomerMode.Loading) return true;
    if (mode == AddCustomerMode.ImportByResource) return selectedResourceArns.size == 0;
    if (mode == AddCustomerMode.ImportByGroupOrWorkspace)
      return convertRawToListOfString(rawGroupOrWorkspaceIds).length == 0;
    if (mode == AddCustomerMode.AddEmail) return convertRawToListOfString(rawEmails).length == 0;
    if (mode == AddCustomerMode.AddCcEmail) return convertRawToListOfString(rawCcEmails).length == 0;
    if (mode == AddCustomerMode.AddSlack) return convertRawToListOfString(rawSlackChannels).length == 0;
    return false;
  };

  return (
    <Modal
      visible={props.visible}
      header={'Add customers'}
      footer={
        <Header
          actions={
            <SpaceBetween direction={'horizontal'} size={'s'}>
              <Button variant={'link'} onClick={handleCancelButtonClick}>
                Cancel
              </Button>
              <Button variant={'primary'} disabled={shouldDisableNextButton()} onClick={handleNextButtonClick}>
                {mode == AddCustomerMode.Done ? 'Finish' : 'Next'}
              </Button>
            </SpaceBetween>
          }
        />
      }
      onDismiss={handleCancelButtonClick}
    >
      {contentByMode[mode]()}
    </Modal>
  );
};

export const importByResource = async (
  userInfo: any,
  resourceArns: string[],
  selectedImportEmailForGroups: boolean,
) => {
  let customerList = await getGroupsAndWorkspacesConsumingResources(userInfo, resourceArns);
  return await importEmailAndCtiForGroupOrWorkspace(customerList, selectedImportEmailForGroups);
};

export const importByGroupOrWorkspaceId = async (
  groupOrWorkspaceIds: string[],
  selectedImportEmailForGroups: boolean,
) => {
  // convert the groups/wks into AdvisoryCustomer list
  let customerList: AdvisoryCustomer[] = groupOrWorkspaceIds.map((groupOrWorkspaceId) => ({
    groupOrWorkspaceId: groupOrWorkspaceId,
    type: 'Omni',
    comment: '',
  }));
  // and call the helper to add the email/CTI to the customers
  return await importEmailAndCtiForGroupOrWorkspace(customerList, selectedImportEmailForGroups);
};

export const addByEmail = (emails: string[]) => {
  const emailCustomers: AdvisoryCustomer[] = emails.map((email) => ({
    emailAddress: email,
    type: 'Email',
    comment: '',
  }));
  return emailCustomers;
};

export const addByCcEmail = (ccEmails: string[]) => {
  const ccEmailCustomers: AdvisoryCustomer[] = ccEmails.map((ccEmail) => ({
    emailAddress: ccEmail,
    type: 'CCEmail',
    comment: '',
  }));
  return ccEmailCustomers;
};

export const addBySlack = (slackChannels: string[]) => {
  const slackCustomers: AdvisoryCustomer[] = slackChannels
    .map((slackChannel) => {
      if (!slackChannel.startsWith('#')) {
        return '#' + slackChannel;
      }
      return slackChannel;
    })
    .map((slackChannel) => ({
      slackChannel: slackChannel,
      type: 'Slack',
      comment: '',
    }));
  return slackCustomers;
};

export const listWorkspacesPaginated = async (workspaceIds: string[]) => {
  let startIndex = 0;
  let workspaces = [];

  // dedupe the list
  const setOfWorkspaceIds = new Set(workspaceIds);
  const dedupedWorkspaceIds = [...setOfWorkspaceIds];

  while (startIndex < dedupedWorkspaceIds.length) {
    const numOfWorkspacesToGet = Math.min(50, dedupedWorkspaceIds.length - startIndex);
    let workspaceIdsBatch = dedupedWorkspaceIds.slice(startIndex, startIndex + numOfWorkspacesToGet);
    let listWorkspacesResult;
    // try once with all wks ID's
    try {
      listWorkspacesResult = await listWorkspaces({
        workspaceIds: workspaceIdsBatch,
      });
    } catch (e) {
      // if some of the wks ID's are invalid, it will return them in the exception message like this:
      // Invalid workspaceIds present! Validate if the workspace exists! Invalid workspaces: [wks-662464530529-68314ca, wks-749673169545-e86d49c, wks-201647083133-aac6ae2, wks-446056755984-7b57e2b]
      let message: string = e.message;
      // remove the "Invalid workspace Ids...[" and "]"
      message = message.substring(85, message.length - 1);
      const invalidIds = message.split(', ');
      workspaceIdsBatch = workspaceIdsBatch.filter((workspaceId) => !invalidIds.includes(workspaceId));
      // and now retry the call without the invalid ones
      try {
        listWorkspacesResult = await listWorkspaces({
          workspaceIds: workspaceIdsBatch,
        });
      } catch (e) {
        // if the second call still fails, we can't continue, and we'll just return the workspaces we've gotten so far.
        // future enhancement: display the error message
        return workspaces;
      }
    }

    workspaces.push(...listWorkspacesResult.workspaces);
    startIndex += numOfWorkspacesToGet;
  }

  return workspaces;
};

export const listGroupsPaginated = async (groupIds: string[]) => {
  let startIndex = 0;
  let groups = [];

  // dedupe the list
  const setOfGroupIds = new Set(groupIds);
  const dedupedGroupIds = [...setOfGroupIds];

  while (startIndex < dedupedGroupIds.length) {
    const numOfGroupsToGet = Math.min(50, dedupedGroupIds.length - startIndex);
    try {
      const getGroupBatchResult = await getGroupBatch({
        groupIds: dedupedGroupIds.slice(startIndex, startIndex + numOfGroupsToGet),
      });
      groups.push(...getGroupBatchResult.groupsList);
    } catch (e) {
      // one possible cause of failure here is adding an invalid group ID (one that fails regex check), but the API
      // response does not specify which one(s) are invalid.
      // future enhancement: check this on our end and display an error.
      // for now, just return the groups we got so far.
      console.log(e);
      return groups;
    }
    startIndex += numOfGroupsToGet;
  }

  return groups;
};

// gets all data permissions for the given resources, then creates AdvisoryCustomer objects of the consuming group/wks and returns them as a list
export const getGroupsAndWorkspacesConsumingResources = async (userInfo: any, resourceArns: string[]) => {
  let dpResources = [];
  let skippedResources = [];
  for (const resourceArn of resourceArns) {
    const dpResource = convertResourceArnToDpResource(resourceArn);
    const resourceName = getResourceNameFromArn(resourceArn);
    const owns = await userOwnsResource(userInfo, getIdInfoFromArn(resourceArn));
    if (owns) {
      dpResources.push({ name: resourceName, resource: dpResource });
    } else {
      console.log('Not importing resources for ARN the user does not own: ' + resourceArn);
      skippedResources.push({ name: resourceName, resource: dpResource });
    }
  }

  let allDpsAndNames = [];
  for (const dpResourceAndArn of dpResources) {
    const name = dpResourceAndArn['name'];
    const dpResource = dpResourceAndArn['resource'];
    const dps = await fetchActiveConsumerDataPermissions(dpResource);
    const dpAndNames = dps.map((dp) => ({ name, dp }));
    allDpsAndNames.push(...dpAndNames);
  }

  // 2. convert them to AdvisoryCustomer objects
  let customers: AdvisoryCustomerList = allDpsAndNames.map((dpAndName) => {
    const name = dpAndName['name'];
    const dp = dpAndName['dp'];
    return {
      groupOrWorkspaceId: dp.ownerId,
      addedBy: 'AutoImport',
      type: 'Omni',
      comment: 'Imported by Omni for ' + name,
    };
  });
  // dedupe the list
  let uniqueGroupOrWorkspaceIds = new Set<string>();
  let uniqueCustomers: AdvisoryCustomerList = [];
  for (const customer of customers) {
    if (!uniqueGroupOrWorkspaceIds.has(customer.groupOrWorkspaceId)) {
      uniqueGroupOrWorkspaceIds.add(customer.groupOrWorkspaceId);
      uniqueCustomers.push(customer);
    }
  }
  return uniqueCustomers;
};

export const importEmailAndCtiForGroupOrWorkspace = async (
  customers: AdvisoryCustomer[],
  selectedImportEmailForGroups: boolean,
) => {
  const workspaceIds = customers
    .filter((customer) => customer.groupOrWorkspaceId.startsWith('wks-'))
    .map((customer) => customer.groupOrWorkspaceId);
  const workspaces = await listWorkspacesPaginated(workspaceIds);
  let workspaceToGroupIdMapping = {};
  workspaces.forEach((workspace) => {
    workspaceToGroupIdMapping[workspace.workspaceId] = workspace.groupId;
  });
  const groupIds = customers.map((customer) => {
    if (customer.groupOrWorkspaceId.startsWith('wks-')) {
      return workspaceToGroupIdMapping[customer.groupOrWorkspaceId];
    } else {
      return customer.groupOrWorkspaceId;
    }
  });
  const groups = await listGroupsPaginated(groupIds);
  let groupToEmailMapping = {};
  groups.forEach((group) => {
    groupToEmailMapping[group.groupId] = group.teamInfo['DistributionList'];
  });

  let nonexistentGroupsAndWorkspaces = [];
  for (const customer of customers) {
    const groupOrWorkspaceId = customer.groupOrWorkspaceId;
    let groupId = groupOrWorkspaceId;
    if (groupOrWorkspaceId.startsWith('wks-')) {
      // if it's a workspace, get the groupId for it
      groupId = workspaceToGroupIdMapping[groupOrWorkspaceId];
    }
    let emailAddress;
    if (groupId) {
      emailAddress = groupToEmailMapping[groupId];
    }
    if (emailAddress) {
      customer.emailAddress = emailAddress;
    } else {
      nonexistentGroupsAndWorkspaces.push(groupOrWorkspaceId);
    }
  }

  // remove customers for groups/wks that no longer exist
  customers = customers.filter((customer) => !nonexistentGroupsAndWorkspaces.includes(customer.groupOrWorkspaceId));

  // remove the emails if that option was not selected
  if (!selectedImportEmailForGroups) {
    customers.forEach((customer) => (customer.emailAddress = undefined));
  }

  return customers;
};
