import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, ColumnLayout, Flashbar, Header, SpaceBetween, Tabs } from '@amzn/awsui-components-react-v3';
import {
  convertToDgsHCResourceArn,
  convertToDgsHCResourceId,
  CopiableText,
  DefaultRouteProps,
  fetchTemplatesForResourceId,
  getGroupOrWorkspaceName,
  isDGSAdmin,
} from 'src/commons/common';
import { WSDatabaseList } from 'src/components/workspaces/dataBrowse/catalog/databaseList';
import { listCatalogs } from 'src/api/catalog';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import BusinessGlossaries from 'src/components/workspaces/common/businessGlossaries';
import MetadataDetails from 'src/components/workspaces/common/metadataDetails';
import { CatalogEditModal } from 'src/components/workspaces/dataBrowse/catalog/catalogEdit';
import { TemplatesForResourceDetailsTable } from 'src/components/templates/TemplatesForResourceDetailsTable';
import { AdvisoriesForResourceTable } from 'src/components/dataadvisory/listAdvisoriesPage/advisoriesForResource';
import { generateArnFromId } from 'src/components/utils/arnUtil';
import { enableAdvisories } from 'src/api/config';
import _ from 'lodash';
import { ContactInfo } from 'src/components/workspaces/common/ContactInfo';
import { lakeFormationIcon, ownerLinkItem } from 'src/components/workspaces/common/common';

export interface CatalogDetailProps extends DefaultRouteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
  setActiveCatalogName: any;
  catalogMap: any;
  userInfo: any;
}

export const CatalogDetail = (props: CatalogDetailProps) => {
  const [catalog, setCatalog] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [templates, setTemplates] = useState(undefined);
  const [editCatalogModalVisible, setEditCatalogModalVisible] = useState(false);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    const catalogs = await listCatalogs({
      Filter: {
        CatalogKeyList: [
          {
            CatalogId: props.match.params.catalogid,
            Region: props.match.params.region,
            ClusterIdentifier: props.match.params.clusterName,
          },
        ],
      },
    });
    let catalog = catalogs.CatalogInfoList[0];
    props.setActiveCatalogName(catalog?.Name);
    await fetchTemplatesForCatalog(catalog);
    setCatalog(catalog);
    setLoading(false);
  };

  const getTemplateForCatalog = () => {
    return <TemplatesForResourceDetailsTable items={templates} userOwnsResource={userOwnsCatalog()} />;
  };

  const fetchTemplatesForCatalog = async (catalog) => {
    let resourceId = convertToDgsHCResourceId(
      catalog?.CatalogId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      catalog?.DataAccessRole,
    );
    let fetchedTemplates = await fetchTemplatesForResourceId(resourceId);
    setTemplates(fetchedTemplates);
  };

  const getCatalogDetailsMetadata = () => {
    const catalog_metadata = [];
    if (catalog?.Name) {
      catalog_metadata.push(<CopiableText name={'Catalog name'} key={'Catalog name'} value={catalog?.Name} />);
    }

    if (catalog?.CatalogId) {
      catalog_metadata.push(<CopiableText name={'Catalog ID'} key={'Catalog ID'} value={catalog?.CatalogId} />);
    }
    if (catalog?.Owner) {
      catalog_metadata.push(
        <CopiableText
          name={'Owner'}
          key={'Owner'}
          value={getGroupOrWorkspaceName(catalog?.Owner, props.workspaceNameMap)}
          url={ownerLinkItem(catalog?.Owner, props.workspaceNameMap)}
        />,
      );
    }
    if (catalog?.CTI) {
      catalog_metadata.push(<CopiableText name={'CTI'} key={'CTI'} value={catalog?.CTI} />);
    }
    if (catalog?.Region) {
      catalog_metadata.push(<CopiableText name={'Region'} key={'Region'} value={catalog?.Region} />);
    }
    if (catalog?.Description) {
      catalog_metadata.push(<CopiableText name={'Description'} key={'Description'} value={catalog?.Description} />);
    }
    if (catalog?.CreatedBy) {
      catalog_metadata.push(<CopiableText name={'Created by'} key={'CreatedBy'} value={catalog?.CreatedBy} />);
    }
    if (catalog?.CreatedOn) {
      catalog_metadata.push(<CopiableText name={'Created on'} key={'CreatedOn'} value={catalog?.CreatedOn} />);
    }
    return catalog_metadata;
  };

  const userOwnsCatalog = () => {
    if (!catalog) return false;
    if (props.userInfo && isDGSAdmin(props.userInfo.memberGroupIds)) return true;
    // check if catalog owner is in user workspaces
    // authZ will happen on backend APIs, based on roles
    return props.userInfo && !!_.find(props.userInfo.memberWorkspaceIds, (wksId) => wksId === catalog?.Owner);
  };

  const closeEditModal = () => {
    setEditCatalogModalVisible(false);
  };

  const setNotification = async (header, message) => {
    if (header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const detail = () => {
    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: (
          <>
            <div className='awsui-util-container'>
              <div className='awsui-util-container-header'>
                <div className='awsui-util-action-stripe'>
                  <div className='awsui-util-action-stripe-title'>
                    <h2>Catalog details</h2>
                  </div>
                  <div className='awsui-util-action-stripe-group'>
                    <div style={{ paddingTop: 5, paddingRight: 10 }}>
                      {userOwnsCatalog() && (
                        <Button
                          variant='normal'
                          onClick={() => {
                            setEditCatalogModalVisible(true);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <ColumnLayout columns={3} borders='horizontal'>
                {getCatalogDetailsMetadata()}
                {catalog !== undefined && (
                  <CatalogEditModal
                    {...props}
                    catalog={catalog}
                    visible={editCatalogModalVisible}
                    dismiss={closeEditModal}
                    notify={setNotification}
                    handleRefresh={handleRefresh}
                  />
                )}
              </ColumnLayout>
            </div>
            <SpaceBetween size={'l'}>
              {!loading && catalog && <ContactInfo resource={getArn()} />}
              {!loading && catalog && (
                <BusinessGlossaries
                  activeGroup={props.activeGroup}
                  activeWorkspace={props.activeWorkspace}
                  match={''}
                  setContentType={props.setContentType}
                  resource={convertToDgsHCResourceArn(
                    catalog?.CatalogId,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    catalog?.DataAccessRole,
                  )}
                  isOwner={userOwnsCatalog()}
                />
              )}
            </SpaceBetween>
          </>
        ),
      },
      {
        label: 'Databases',
        id: 'Databases',
        content: (
          <WSDatabaseList
            {...props}
            catalogId={props.match.params.catalogid}
            catalogName={catalog?.Name}
            catalogRegion={catalog?.Region}
            clusterIdentifier={undefined}
          />
        ),
      },
      {
        label: 'Templates',
        id: 'Templates',
        content: getTemplateForCatalog(),
      },
      {
        label: 'Metadata',
        id: 'Metadata',
        content: (
          <MetadataDetails
            resourceOwnerIds={catalog?.Owner}
            resource={convertToDgsHCResourceArn(
              catalog?.CatalogId,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              catalog?.DataAccessRole,
            )}
            activeGroup={props.activeGroup}
            activeWorkspace={props.activeWorkspace}
            setContentType={props.setContentType}
            setNotification={setNotification}
          />
        ),
      },
    ];

    if (enableAdvisories()) {
      tabs.push({
        label: 'Advisories',
        id: 'advisories',
        content: (
          <AdvisoriesForResourceTable
            resourceArn={getArn()}
            activeGroup={props.activeGroup}
            setContentType={props.setContentType}
          />
        ),
      });
    }

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  };

  const getArn = () => {
    const id = `DS-glueLF|A-${props.match.params.catalogid}|R-${catalog?.Region}`;
    const arn = generateArnFromId(id);
    return arn;
  };

  if (!catalog && !loading) {
    return (
      <>
        <h2>Catalog not found</h2>
        The given catalog is not valid, or you do not have permission to view it. Please check the URL for mistakes and
        try again.
      </>
    );
  }

  return (
    <>
      <Flashbar items={notifications} />
      <Header description={catalog?.Description} variant={'h1'}>
        {lakeFormationIcon} {catalog?.Name}
      </Header>
      {detail()}
    </>
  );
};
