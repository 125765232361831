import * as React from 'react';
import {
  Alert,
  Button,
  Flashbar,
  Form,
  FormField,
  Container,
  Header,
  Icon,
  Select,
  Input,
  FlashbarProps,
  SelectProps,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

import { DropdownStatusProps } from '@amzn/awsui-components-react-v3/polaris/internal/components/dropdown-status/interfaces';
import { Redirect } from 'react-router-dom';

import {
  convertResourcesToOptions,
  describeResource,
  getAccounts,
  listResources,
  registerTable,
} from '../../api/publisher';
import { getRegion } from 'src/api/config';
import { Page } from '../../routes/Paths';
import { FORM_CONTENT_TYPE } from 'src/commons/constants';
import { PublisherDeprecation } from './publishTables';

export interface RegisterPublisherTableProps {
  setContentType: any;
  location: any;
  activeGroup: string;
}

export interface RegisterPublisherTableState {
  notifications: FlashbarProps.MessageDefinition[];
  tableName: string;
  logicalName: string;
  tables: SelectProps.Option[];
  buttonLoading: boolean;
  redirect: string;
  buttonText: string;
  resources: any;
  resourcesStatus: DropdownStatusProps.StatusType;
  resourceInfo: any;
  resourceInfoStatus: DropdownStatusProps.StatusType;
  selectedTable: SelectProps.Option;
  selectedAccount: SelectProps.Option;
  accountId: string;
  allRegisteredAccounts: any;
  streamArn: string;
  streamEnabled: boolean;
}

export class RegisterPublisherTable extends React.Component<RegisterPublisherTableProps, RegisterPublisherTableState> {
  state = {
    notifications: [],
    tableName: 'tableName',
    logicalName: '',
    tables: [],
    buttonLoading: false,
    redirect: '',
    cancelRedirect: Page.PUBLISHER_TABLES,
    buttonText: 'Register',
    resourcesStatus: 'finished' as DropdownStatusProps.StatusType,
    resources: undefined,
    resourceInfoStatus: 'finished' as DropdownStatusProps.StatusType,
    resourceInfo: undefined,
    selectedTable: undefined,
    selectedAccount: undefined,
    accountId: '',
    allRegisteredAccounts: [],
    streamArn: '',
    streamEnabled: false,
  };

  componentDidMount = async () => {
    this.props.setContentType(FORM_CONTENT_TYPE);
    this.fetchAccounts();
  };

  fetchAccounts = async () => {
    const allAccounts = [];
    try {
      const accounts = await getAccounts({ GroupId: this.props.activeGroup });
      for (let i = 0; i < accounts.Accounts.length; i++) {
        allAccounts.push({
          id: accounts.Accounts[i].AwsAccountId,
          label: accounts.Accounts[i].AwsAccountId,
        });
      }
      this.setState({
        allRegisteredAccounts: allAccounts,
      });
      console.log('RegisterTable page:: Accounts: ', this.state.allRegisteredAccounts);
    } catch (err) {
      console.log('RegisterTable page:: Unable to load accounts ', err);
    }
  };

  updateSelectedOption = async (event) => {
    const accountId = event.detail.selectedOption.label;
    this.setState({
      accountId,
      selectedAccount: event.detail.selectedOption,
    });

    const resources = await listResources({
      GroupId: this.props.activeGroup,
      AwsAccountId: accountId,
      Region: getRegion(),
    });
    console.log('RegisterTable page:: PostResourcesResponse: ', resources);
    this.setState({
      resources: resources,
      tables: convertResourcesToOptions(resources),
      resourcesStatus: 'finished',
    });
  };

  fetchResourceInfo = async (tableName) => {
    this.setState({
      resourceInfoStatus: 'loading',
      resourceInfo: undefined,
      tableName: tableName,
    });
    const resourceInfo = await describeResource({
      GroupId: this.props.activeGroup,
      AwsAccountId: this.state.accountId,
      PhysicalTableName: tableName,
      Region: getRegion(),
    });
    console.log('RegisterTable page:: GetResourceInfoResponse: ', resourceInfo);
    this.setState({
      resourceInfo: resourceInfo,
      streamArn: resourceInfo['Table']['LatestStreamArn'],
      streamEnabled: resourceInfo['Table']['StreamEnabled'] == 'ENABLED',
      resourceInfoStatus: 'finished',
    });
  };

  handleConfirm = async () => {
    this.setState({ buttonLoading: true });
    try {
      const response = await registerTable({
        GroupId: this.props.activeGroup,
        PhysicalTableName: this.state.tableName,
        TableName: this.state.logicalName,
        LatestStreamArn: this.state.streamArn,
        Region: getRegion(),
        Columns: this.state.resourceInfo['Table']['Columns'],
        AwsAccountId: this.state.accountId,
        StreamEnabled: this.state.streamEnabled ? 'ENABLED' : 'DISABLED',
      });
      const status = response.Status == 'Success' ? 'success' : 'error';
      const message = response.Status == 'Success' ? 'New table registered with Omni' : 'Failed to register table';
      console.log(message);
      this.setState({
        notifications: [
          {
            type: status,
            content: `  ${this.state.tableName} ${response.Message}`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } catch (e) {
      this.setState({
        notifications: [
          {
            type: 'error',
            content: `Error in registering the account ${e.message}`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
    this.setState({ buttonLoading: false });
  };

  allFieldsSet() {
    return this.state.resourceInfo != undefined && this.state.streamEnabled;
  }

  logicalNameIsValid() {
    return /^[a-zA-Z0-9_.]*$/.test(this.state.logicalName);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <SpaceBetween direction='horizontal' size='m'>
        <Alert statusIconAriaLabel='Error' type='error'>
          {PublisherDeprecation}
        </Alert>
        <Flashbar items={this.state.notifications}></Flashbar>
        <Form
          header={
            <Header variant={'h1'} description='Please specify the details of your the table that you want to publish.'>
              Register table
            </Header>
          }
          actions={
            <SpaceBetween size='s' direction='horizontal'>
              <Button variant='link' onClick={() => this.setState({ redirect: this.state.cancelRedirect })}>
                Cancel
              </Button>
              <Button variant='primary' onClick={this.handleConfirm} loading={this.state.buttonLoading} disabled={true}>
                {this.state.buttonText}
              </Button>
            </SpaceBetween>
          }
        >
          <Container className='custom-screenshot-hide' header={<Header variant='h2'>Account details</Header>}>
            <SpaceBetween size='m'>
              <FormField
                label='AWS account ID'
                description='Please select the AWS account that owns the DynamoDB tables.'
              >
                <Select
                  selectedAriaLabel='Selected'
                  options={this.state.allRegisteredAccounts}
                  placeholder='Please select the account'
                  onChange={this.updateSelectedOption}
                  selectedOption={this.state.selectedAccount}
                />
              </FormField>

              <FormField label='DynamoDB table' description='Please select the DynamoDB table to publish.'>
                <Select
                  selectedAriaLabel='Selected'
                  options={this.state.tables}
                  placeholder='Please select the table'
                  selectedOption={this.state.selectedTable}
                  onChange={(e) => {
                    this.fetchResourceInfo(e.detail.selectedOption.value);
                    this.setState({
                      selectedTable: e.detail.selectedOption,
                      logicalName: e.detail.selectedOption.value.replace(/\-/g, '_'),
                    });
                  }}
                  statusType={this.state.resourcesStatus}
                />
              </FormField>

              {this.state.resourceInfo && (
                <>
                  <div className='awsui-util-label'>
                    Latest stream ARN
                    <div
                      className={this.state.streamEnabled ? 'awsui-util-status-positive' : 'awsui-util-status-negative'}
                    >
                      {this.state.streamEnabled ? (
                        <>
                          <span>
                            <div>
                              {' '}
                              <Icon name='status-positive' /> Enabled
                            </div>{' '}
                          </span>
                        </>
                      ) : (
                        <div>
                          <Icon name='status-negative' />
                          Disabled
                          <br />
                          Please enable DynamoDB streams in the table.
                        </div>
                      )}
                      {this.state.streamArn}
                    </div>
                  </div>
                </>
              )}
              <FormField
                label={<div>Table logical name</div>}
                description='The name of the table as it will appear within Omni. (Can be the same or similar to the source table name.) Cannot contain special characters except dot/underscore.'
              >
                <Input
                  name='logical-name'
                  value={this.state.logicalName}
                  ariaRequired={true}
                  onChange={(e) => this.setState({ logicalName: e.detail.value.trim() })}
                  disabled={!this.state.streamEnabled}
                  invalid={!this.logicalNameIsValid()}
                />
              </FormField>
            </SpaceBetween>
          </Container>
        </Form>
      </SpaceBetween>
    );
  }
}
