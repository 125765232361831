import * as React from 'react';

import { Button, Header, Link, Modal, RadioGroup, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { Advisory, UpdateUserPreferenceRequest, UserPreference } from 'aws-sdk/clients/awsdlomni';
import { useState } from 'react';
import { updateUserPreference } from 'src/api/notifications';
import { activeGroupOrWorkspaceId } from 'src/components/dataadvisory/constants';
import { Page } from 'src/routes';

export interface OptInNotificationsModalProps {
  activeGroup: string;
  activeWorkspace?: any;
  advisory: Advisory;
  userOwnsAdvisory: boolean;
  userPreferences: UserPreference[];
  onPreferenceUpdateSuccess: any;
  onPreferenceUpdateFailure: any;
  opt: string; // in | out
  visible: boolean;
  onDismiss: any;
}

export const OptInOrOutNotificationsModal = (props: OptInNotificationsModalProps) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);

  const contactOptionsForCustomerInAdvisory = () => {
    const relevantCustomer = props.advisory.customers.filter(
      (customer) =>
        customer.groupOrWorkspaceId != undefined && customer.groupOrWorkspaceId == activeGroupOrWorkspaceId(props),
    )[0];
    if (!relevantCustomer) return [];
    return [
      relevantCustomer.emailAddress,
      relevantCustomer.cti,
      relevantCustomer.slackChannel,
      relevantCustomer.groupOrWorkspaceId,
    ].filter((value) => value != undefined);
  };

  const apply = async () => {
    setLoading(true);
    const requestForResourceArn = (resourceArn: string) => {
      return {
        ownerId: activeGroupOrWorkspaceId(props),
        resourceArn: resourceArn,
        type: 'AdvisoryPreferences',
        preferenceBodyJson:
          props.opt == 'in' ? '{"optIn":"true","optOut":"false"}' : '{"optIn":"false","optOut":"true"}',
      } as UpdateUserPreferenceRequest;
    };
    const advisoryOwner = props.advisory.ownerId;
    const impactedResourceArns = props.advisory.impactedResources.map((resource) => resource.resourceArn);
    const advisoryId = props.advisory.advisoryId;
    let requests: UpdateUserPreferenceRequest[] = [];
    if (selectedOption == 'advisory') {
      requests = [requestForResourceArn(advisoryId)];
    } else if (selectedOption == 'resources') {
      requests = impactedResourceArns.map((resourceArn) => requestForResourceArn(resourceArn));
    } else if (selectedOption == 'owner') {
      requests = [requestForResourceArn(advisoryOwner)];
    }
    try {
      for (const request of requests) {
        await updateUserPreference(request);
      }
      props.onPreferenceUpdateSuccess();
    } catch (e) {
      props.onPreferenceUpdateFailure(e);
    }
    setLoading(false);
    props.onDismiss();
  };

  return (
    <>
      <Modal
        visible={props.visible}
        onDismiss={props.onDismiss}
        header={props.opt == 'in' ? 'Subscribe' : 'Unsubscribe'}
        footer={
          <Header
            actions={
              <SpaceBetween direction={'horizontal'} size={'s'}>
                <Button variant={'link'} onClick={props.onDismiss}>
                  Cancel
                </Button>
                <Button
                  variant={'primary'}
                  disabled={selectedOption == undefined || selectedOption == '' || loading}
                  loading={loading}
                  onClick={apply}
                >
                  {'Apply'}
                </Button>
              </SpaceBetween>
            }
          />
        }
      >
        <p>
          The following contacts are linked with your group or workspace and will be{' '}
          {props.opt == 'in' ? 'subscribed to' : 'unsubscribed from'} notifications:
        </p>
        <ul>
          {contactOptionsForCustomerInAdvisory().map((contactOption) => (
            <li>{contactOption}</li>
          ))}
        </ul>
        <p>
          You can {props.opt == 'in' ? 'subscribe to' : 'unsubscribe from'} updates from this advisory, or all future
          advisories as well.
        </p>
        <RadioGroup
          onChange={(e) => setSelectedOption(e.detail.value)}
          value={selectedOption}
          items={[
            { value: 'advisory', label: 'Just this advisory' },
            { value: 'resources', label: 'All advisories for these resources' },
            { value: 'owner', label: 'All advisories from this data provider' },
          ]}
        />
        <p>
          You can manage all your notification settings <Link href={Page.NOTIFICATION_CONFIGURATION}>here</Link>.
        </p>
      </Modal>
    </>
  );
};
