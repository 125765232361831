import * as React from 'react';

import { Table, Header, Link } from '@amzn/awsui-components-react-v3';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { Advisory } from 'aws-sdk/clients/awsdlomni';
import { getCtiUrl } from 'src/commons/common';
import {
  CTI,
  EMAIL,
  LINK,
  ONCALL,
  READABLE_CTI,
  READABLE_EMAIL,
  READABLE_LINK,
  READABLE_ONCALL_LINK,
  READABLE_SLACK,
  READABLE_WIKI,
  SLACK,
  WIKI,
} from 'src/components/dataadvisory/constants';

export interface ContactInformationTableProps {
  advisory: Advisory;
  activeGroup: string;
  activeWorkspace?: any;
}

export const ContactInformationTable = (props: ContactInformationTableProps) => {
  const readableType = (type) => {
    switch (type) {
      case EMAIL:
        return READABLE_EMAIL;
      case SLACK:
        return READABLE_SLACK;
      case WIKI:
        return READABLE_WIKI;
      case CTI:
        return READABLE_CTI;
      case ONCALL:
        return READABLE_ONCALL_LINK;
      case LINK:
        return READABLE_LINK;
    }
  };

  const cell = (type, value) => {
    switch (type) {
      case EMAIL:
      case SLACK:
        return value;
      case WIKI:
      case ONCALL:
      case LINK:
        return (
          <Link external={true} href={value}>
            {value}
          </Link>
        );
      case CTI:
        const ctiComponents = value.split('/');
        const category = ctiComponents[0];
        const typ = ctiComponents[1];
        const item = ctiComponents[2];
        const url = getCtiUrl(category, typ, item);
        return (
          <Link href={url} external={true}>
            {value}
          </Link>
        );
    }
  };

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'contactType',
      header: 'Type',
      cell: (item) => readableType(item.type),
      width: 150,
      sortingField: 'contactType',
    },
    {
      id: 'value',
      header: 'Value',
      cell: (item) => cell(item.type, item.value),
      width: 300,
    },
    {
      id: 'comment',
      header: 'Comment',
      cell: (item) => item.comment,
      width: 550,
    },
  ];

  return (
    <>
      <Table
        loadingText='Loading contact information...'
        columnDefinitions={columnDefinitions}
        items={props.advisory.contactInfo}
        resizableColumns={true}
        header={<Header counter={'(' + props.advisory.contactInfo.length + ')'}>Contact information</Header>}
      />
    </>
  );
};
