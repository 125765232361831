import React, { useEffect, useState } from 'react';
import { Box, ColumnLayout, Container, Header, Spinner } from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import {
  fetchBaselineNeededConsumerDataPermissions,
  fetchBaselineNeededConsumerIAMDataPermissions,
} from 'src/components/permissions/myBaselining/baseliningUtils';
import { Link, SpaceBetween } from '@amzn/awsui-components-react-v3/polaris';
import { getWksFromDropdownItemId } from 'src/commons/common';
import { createBaselineOwnerLink } from 'src/routes';

export function Baselining(props) {
  const [ownerIds, setOwnerIds] = useState([]);
  const [workspaces, setWorkspaces] = useState({});
  const [ownerIdToBaselineNeededCount, setOwnerIdToBaselineNeededCount] = useState({});
  const [loadingBaseline, setLoadingBaseline] = useState(true);
  const [redirect] = useState(undefined);

  useEffect(() => {
    (async () => {
      props.setContentType(TABLE_CONTENT_TYPE);
      setLoadingBaseline(true);
      if (props.activeGroup) {
        const ownerIds = [];
        const workspaces = {};
        // Current group in session
        ownerIds.push(props.activeGroup);
        // Workspace
        for (let dropDownItem of props.groupsAndWorkspacesDropdownItems) {
          if (dropDownItem && dropDownItem['id'] == props.activeGroup && dropDownItem['text'] === ' ') {
            if (dropDownItem['items'] && dropDownItem['items'].length) {
              for (let workspaceItem of dropDownItem['items']) {
                const workspaceId = getWksFromDropdownItemId(workspaceItem['id']);
                ownerIds.push(workspaceId);
                workspaces[workspaceId] = workspaceItem;
              }
            }
          }
        }
        setOwnerIds(ownerIds);
        setWorkspaces(workspaces);

        try {
          const dps = await fetchBaselineNeededConsumerDataPermissions(ownerIds);
          const iamDPs = await fetchBaselineNeededConsumerIAMDataPermissions(props.activeGroup);
          dps.push(...iamDPs);
          // Find baseline needed count
          const ownerIdToBaselineNeededCount = {};
          for (let ownerId of ownerIds) ownerIdToBaselineNeededCount[ownerId] = { consumerDP: 0 };
          for (let dp of dps) ownerIdToBaselineNeededCount[dp.ownerId]['consumerDP']++;
          setOwnerIdToBaselineNeededCount(ownerIdToBaselineNeededCount);
          setLoadingBaseline(false);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, []);

  if (redirect) return <Redirect push to={redirect} />;

  function getBaselineNeededConsumerDPOwnerIdBoxes() {
    if (loadingBaseline || !ownerIds.length) return [];

    const boxes = [];
    for (let ownerId of ownerIds) {
      const baselineNeededCount = ownerIdToBaselineNeededCount[ownerId]['consumerDP'];
      const href = createBaselineOwnerLink(ownerId);
      let ownerType = 'Group';
      // Display workspace name
      if (ownerId.startsWith('wks-')) {
        ownerType = 'Workspace';
        ownerId = workspaces[ownerId].text ? workspaces[ownerId].text : ownerId;
      }
      boxes.push(
        <div>
          <Box variant='awsui-key-label'>{`${ownerType} ${ownerId}`}</Box>
          <Link variant='awsui-value-large' href={href}>
            {baselineNeededCount}
          </Link>
        </div>,
      );
    }
    return boxes;
  }

  return (
    <>
      <SpaceBetween size='xl'>
        <Header variant='h1'>Baseline dashboard</Header>
        <Container
          header={
            <Header
              variant='h2'
              description='Viewing your data access permissions that require a review, to ensure they are still necessary and appropriate.'
            >
              Baseline needed - <em>data access permission</em>
            </Header>
          }
        >
          <ColumnLayout columns={2} variant='text-grid'>
            {loadingBaseline && <Spinner size='large' />}
            {getBaselineNeededConsumerDPOwnerIdBoxes()}
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    </>
  );
}
