import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  TextFilter,
  Pagination,
  CollectionPreferences,
  CollectionPreferencesProps,
  Table,
  Header,
  StatusIndicator,
} from '@amzn/awsui-components-react-v3/polaris';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { EmptyState } from 'src/commons/EmptyState';
import { defaultWrapLinesPreference, largePageSizePreference, paginationLabels } from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';

export interface DataQualityReportTableProps {
  dataQualityMetrics: any;
}

export const DataQualityReportTable = (props: DataQualityReportTableProps) => {
  const [tableMessage] = useState('No data quality metrics');
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: true,
    pageSize: 25,
  });
  const [columnDefinitions, setColumnDefinitions] = useState([]);
  const glueDQColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => item.name,
      minWidth: 200,
      width: 250,
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: 250,
      width: 300,
    },
    {
      id: 'result',
      header: 'Result',
      cell: (item) => {
        switch (item.result) {
          case 'PASS':
            return <StatusIndicator>Rule passed</StatusIndicator>;
          case 'FAIL':
            return <StatusIndicator type={'error'}>Rule failed</StatusIndicator>;
          default:
            return item.result;
        }
      },
      minWidth: 150,
      width: 150,
      sortingField: 'result',
    },
    {
      id: 'evaluatedMetrics',
      header: 'Evaluated metrics',
      cell: (item) => {
        if (item.evaluatedMetrics === null || JSON.stringify(item.evaluatedMetrics) === '{}') return '-';
        let evaluatedMetricsString = '';
        for (let key in item.evaluatedMetrics) {
          evaluatedMetricsString = evaluatedMetricsString.concat(`${key}: ${item.evaluatedMetrics[key]}\n`);
        }
        return evaluatedMetricsString;
      },
      minWidth: 250,
      width: 300,
    },
    {
      id: 'evaluationMessage',
      header: 'Evaluation message',
      cell: (item) => (item.evaluationMessage ? item.evaluationMessage : '-'),
      minWidth: 250,
      width: 300,
    },
  ];

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    props.dataQualityMetrics.Data.metrics,
    {
      filtering: {
        empty: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{tableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No data quality metrics to display.</p>
          </div>
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  const setColumnDefinitionsFromType = (dataQualityType: string) => {
    switch (dataQualityType) {
      case 'GlueDQ':
        setColumnDefinitions(glueDQColumnDefinitions);
        break;
      default:
        setColumnDefinitions(null);
    }
  };

  useEffect(() => {
    setColumnDefinitionsFromType(props.dataQualityMetrics.Type);
  }, [props.dataQualityMetrics]);

  return (
    <>
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <Header
            counter={`(${props.dataQualityMetrics.Data.metrics.length})`}
            description='Data quality rules from recent runs'
          >
            Data quality metrics
          </Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter data quality by rules or data quality results'
            filteringPlaceholder='Filter data quality by rules or data quality results'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        empty={<EmptyState title={tableMessage} subtitle='No data quality metrics to display.' />}
      />
    </>
  );
};
