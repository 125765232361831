import React from 'react';
import {
  Container,
  DatePicker,
  ExpandableSection,
  FormField,
  Header,
  Input,
  Link,
  Select,
} from '@amzn/awsui-components-react-v3';

export interface TemplateRequestDetailsContainerProps {
  additionalMetadata: any[];
  setAdditionalMetadata: (additionalMetadata: any[]) => void;
}

export const TemplateRequestDetailsContainer = (props: TemplateRequestDetailsContainerProps) => {
  const validateTicketUrl = (url: string) => {
    return url.includes('t.corp.amazon.com') || url.includes('sim.amazon.com') || url == '';
  };

  const shouldEnableSimContainer = () => {
    let shouldEnable = false;
    props.additionalMetadata.forEach((item) => {
      if (item.accessManagementInfo.simTemplates?.[0]) {
        shouldEnable = true;
      }
    });
    return shouldEnable;
  };

  const shouldEnableApprovalContainer = () => {
    let shouldEnable = false;
    props.additionalMetadata.forEach((item) => {
      if (item.accessManagementInfo.approvalsWorkflowTemplate?.approvalTemplateId) {
        shouldEnable = true;
      }
    });
    return shouldEnable;
  };

  let validateNeededOnDate = (neededOnDate: string) => {
    if (!neededOnDate) {
      return true;
    }
    const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
    return regex.test(neededOnDate);
  };

  const convertValueToLabel = (value: string) => {
    const mapping = {
      LOW: 'Low',
      MEDIUM: 'Medium',
      HIGH: 'High',
    };
    return mapping[value];
  };

  const getUniqueApprovalTemplateIds = (additionalMetadata) => {
    let uniqueIds = new Set<string>();
    let uniqueAMIS = [];
    additionalMetadata.forEach((aMIS) => {
      if (!uniqueIds.has(aMIS.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateId)) {
        uniqueAMIS.push(aMIS);
        uniqueIds.add(aMIS.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateId);
      }
    });
    return uniqueAMIS;
  };

  return (
    <>
      {shouldEnableSimContainer() && (
        <Container
          header={
            <Header variant='h2' description='The data owner requests additional information.'>
              Additional request details
            </Header>
          }
        >
          {props.additionalMetadata.map((template) => {
            if (!template.accessManagementInfo?.simTemplates?.[0]) {
              return <></>;
            }
            return (
              <ExpandableSection
                defaultExpanded
                key={template.templateId}
                header={<React.Fragment>{template.name}</React.Fragment>}
              >
                {template.accessManagementInfo?.simTemplates?.map((simTemplate, ind) => (
                  <div key={ind}>
                    <div key={simTemplate.simTemplateLink}>
                      Fill out the following SIM template, then copy the link of the created ticket here.&nbsp;
                      <Link href={simTemplate.simTemplateLink} external={true}>
                        {simTemplate.simTemplateName}
                      </Link>
                    </div>
                    <FormField
                      label={
                        <div>
                          <br />
                          Ticket URL
                        </div>
                      }
                    >
                      <Input
                        name='Access request form/s'
                        placeholder='https://t.corp.amazon.com/123456789'
                        ariaRequired={true}
                        invalid={validateTicketUrl(simTemplate.simTicketLink) == false}
                        value={simTemplate.simTicketLink}
                        onChange={(e) => {
                          const editedTemplates = props.additionalMetadata;
                          editedTemplates.find((t) => {
                            if (t.templateId == template.templateId) {
                              t.accessManagementInfo.simTemplates.find(
                                (st) => st.simTemplateLink == simTemplate.simTemplateLink,
                              ).simTicketLink = e.detail.value;
                            }
                          });
                          props.setAdditionalMetadata(editedTemplates);
                        }}
                      />
                    </FormField>
                  </div>
                ))}
              </ExpandableSection>
            );
          })}
        </Container>
      )}
      <br />
      {shouldEnableApprovalContainer() && (
        <Container
          header={
            <Header
              variant='h2'
              description='When the request is raised an Approvals Workflow will be created from this template.
                You can view the status of your request at any time by going to the Access requests page on the sidebar.'
            >
              Approval workflow details
            </Header>
          }
        >
          {getUniqueApprovalTemplateIds(props.additionalMetadata).map((aMIS) => {
            if (
              !aMIS.accessManagementInfo.approvalsWorkflowTemplate ||
              !aMIS.accessManagementInfo.approvalsWorkflowTemplate.approvalTemplateId
            ) {
              return <></>;
            }
            return (
              <ExpandableSection defaultExpanded key={aMIS.name} header={<React.Fragment>{aMIS.name}</React.Fragment>}>
                <FormField
                  label={
                    <div>
                      <br />
                      Approval template ID
                    </div>
                  }
                >
                  <Input
                    name='approval template Id'
                    ariaRequired={true}
                    value={aMIS.accessManagementInfo.approvalsWorkflowTemplate.approvalTemplateId}
                    disabled={true}
                  />
                </FormField>
                <br />
                <FormField label='Approval neededOnDate' constraintText='Use YYYY/MM/DD format.'>
                  <DatePicker
                    onChange={(e) => {
                      props.additionalMetadata.forEach((aMISToCompare) => {
                        if (
                          aMISToCompare.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateId ==
                          aMIS.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateId
                        ) {
                          aMISToCompare.accessManagementInfo.approvalsWorkflowTemplate.neededOnDate = e.detail.value;
                        }
                      });
                      props.setAdditionalMetadata(props.additionalMetadata);
                    }}
                    value={aMIS.accessManagementInfo.approvalsWorkflowTemplate.neededOnDate}
                    openCalendarAriaLabel={(selectedDate) =>
                      'Choose approval neededOnDate' + (selectedDate ? `, selected date is ${selectedDate}` : '')
                    }
                    invalid={!validateNeededOnDate(aMIS.accessManagementInfo.approvalsWorkflowTemplate.neededOnDate)}
                    isDateEnabled={(date) => date > new Date()}
                    nextMonthAriaLabel='Next month'
                    placeholder='YYYY/MM/DD'
                    previousMonthAriaLabel='Previous month'
                    todayAriaLabel='Today'
                  />
                </FormField>
                <br />
                <FormField label='Approval priority' description='Select the approval priority.'>
                  <Select
                    selectedOption={{
                      label: convertValueToLabel(aMIS.accessManagementInfo.approvalsWorkflowTemplate.priority),
                      value: aMIS.accessManagementInfo.approvalsWorkflowTemplate.priority,
                    }}
                    onChange={(e) => {
                      props.additionalMetadata.forEach((aMISToCompare) => {
                        if (
                          aMISToCompare.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateId ==
                          aMIS.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateId
                        ) {
                          aMISToCompare.accessManagementInfo.approvalsWorkflowTemplate.priority =
                            e.detail.selectedOption.value;
                        }
                      });
                      props.setAdditionalMetadata(props.additionalMetadata);
                    }}
                    options={[
                      { label: 'Low', value: 'LOW' },
                      { label: 'Medium', value: 'MEDIUM' },
                      { label: 'High', value: 'HIGH' },
                    ]}
                    selectedAriaLabel='Selected'
                  />
                </FormField>
              </ExpandableSection>
            );
          })}
        </Container>
      )}
    </>
  );
};
