import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import OnboardingComponent from './components/common/allowlist';
import {
  getActiveHref,
  isGroupLevelPath,
  isHomePage,
  isWorkspacePath,
  shouldRedirectPage,
} from './components/utils/page';
import {
  activeHref,
  appLayoutLabels,
  navItemsForAdmins,
  navItemsForConsumer,
  navItemsForIsoAdmins,
  navItemsForIsoConsumer,
  navItemsForIsoProvider,
  navItemsForProvider,
  navItemsForWorkspacesConsumer,
  navItemsForWorkspacesProvider,
} from './commons/navigationData';
import {
  Alert,
  AppLayout,
  AppLayoutProps,
  Flashbar,
  FlashbarProps,
  SideNavigation,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react-v3';
import { getUser } from './api/auth';
import {
  getExternalGroups,
  getGroupInfo,
  getUserInfo,
  getWorkspace,
  getWorkspacesForGroups,
  getWorkspacesForSingleGroupMemoized,
  listWorkspaces,
} from './api/permissions';
import { PageHeader } from './components/common/header';
import { ErrorBoundary } from './components/error/errorBoundary';
import { updateNotificationIcon } from './components/notifications/common';
import { Breadcrumbs, Help, Page, Routes } from './routes';
import {
  AWSDGSFOUNDRYCATALOG_GROUP_ID,
  DATACONSUMER,
  DATAPROVIDER,
  DATAPROVIDER_AND_CONSUMER,
  DGS_ONCALL_URL,
  EXP_10_MINS,
  FORM_CONTENT_TYPE,
  NOT_FOUND_EXCEPTION,
  LIST_WKS_MAX_WORKSPACE_IDS,
  WORKSPACE_PREFIX,
} from 'src/commons/constants';
import { Link } from '@amzn/awsui-components-react-v3/polaris';
import {
  generateCatalogMap,
  generateWorkspaceNameMap,
  getDropdownItemId,
  getWorkspaceDisplayName,
  isDataLakeAdmin,
} from 'src/commons/common';
import { initAllClients } from 'src/api/client_call';
import { SearchFilters } from 'src/components/search/filters';
import { SearchAggregations, SearchEntityType } from 'src/components/search/constants';
import { StorageService, LocalStorageItemKey, LocalStorageItem } from 'src/components/utils/storage-service';
import _ from 'lodash';
import { isIso } from 'src/api/config';
import { listAdvisories } from 'src/api/notifications';
import { ActiveAdvisoriesHeader } from 'src/components/common/activeAdvisoryGlobalFlashbar';
import { Advisory } from 'aws-sdk/clients/awsdlomni';
import { IsengardAuthModal } from './components/common/IsengardAuthModal';

class App extends Component {
  storageInstance: StorageService;
  state = {
    toolsOpen: false,
    toolsHide: false,
    active: activeHref,
    redirect: undefined,
    username: undefined, // string
    userInfo: undefined,
    groups: [],
    activeGroup: undefined,
    activeGroupInfo: undefined,
    activeDatasetName: undefined,
    activeDatabaseName: undefined,
    activeCatalogName: undefined,
    activeTable: undefined,
    activeAccount: undefined,
    activeAdvisories: [],
    contentType: FORM_CONTENT_TYPE as AppLayoutProps.ContentType,
    displaySplitPanel: false,
    splitPanelOpen: true,
    splitPanelSize: 350,
    notifications: [] as FlashbarProps.MessageDefinition[],
    navOpen: true,
    workspaceId: undefined,
    allowlisted: true,
    internalError: false,
    loadingGroup: false,
    customerType: DATAPROVIDER_AND_CONSUMER,
    navigationItemsForCustomerType: undefined,
    cartItems: [],
    cartItemIds: [],
    unreadNotifications: undefined,
    unreadNotificationBatches: undefined,
    removeUnreadNotificationId: undefined,
    removeUnreadNotificationBatchId: undefined,
    groupsAndWorkspacesDropdownItems: [],
    activeWorkspace: undefined,
    activeTagName: undefined,
    catalogMap: new Map(),
    workspaceNameMap: new Map(),
    workspacesForGroups: undefined,
    workspaceCount: undefined,
    searchQueryString: '',
    externalGroups: undefined,
    searchLoading: false,
    searchFilters: {
      entityType: SearchEntityType.Dataset,
      aggregations: {},
      filters: undefined,
    },
  };

  constructor(props) {
    super(props);
    this.storageInstance = StorageService.getInstance();
    // event handler for updates to localStorage. These events don't happen automatically; we emit them by calling:
    // window.dispatchEvent(new StorageEvent('storage'));
    window.addEventListener('storage', () => {
      // re-render side nav and flashbar for changes to active advisories.
      this.fetchActiveAdvisoriesForDataYouConsume(this.state.activeGroup, this.state.workspaceId);
    });
  }

  onSearchEntityTypeChangeHandler = (newEntityType: SearchEntityType): void => {
    this.setState((state: any) => ({
      searchFilters: { ...state.searchFilters, entityType: newEntityType },
    }));
  };

  onSearchQueryChangeHandler = (searchQuery: string): void => {
    this.setState({ searchQueryString: searchQuery });
  };

  onSearchAggregationsChangeHandler = (newAggregations: SearchAggregations): void => {
    this.setState((state: any) => ({
      searchFilters: { ...state.searchFilters, aggregations: newAggregations },
    }));
  };

  onSearchFiltersChangeHandler = (newFilters: { [key: string]: string[] }): void => {
    this.setState((state: any) => ({
      searchFilters: { ...state.searchFilters, filters: newFilters },
    }));
  };

  onSplitPanelToggleHandler = ({ detail: { open } }): void => {
    this.setState({ splitPanelOpen: open });
  };

  onSplitPanelResizeHandler = ({ detail: { size } }): void => {
    this.setState({ splitPanelSize: size });
  };

  onSetSearchLoadingHandler = (isLoading: boolean): void => {
    this.setState({ searchLoading: isLoading });
  };

  onToolsHideHandler = (hideTools: boolean): void => {
    this.setState({ toolsHide: hideTools, toolsOpen: false });
  };

  fetchUserInfo = async (userId: string) => {
    const userInfoObj = await this.storageInstance.getItem(LocalStorageItemKey.UserInfo);
    if (userInfoObj) {
      console.info('Fetched user info from local cache!');
      return userInfoObj.data;
    } else {
      let userInfo = await getUserInfo({ userId: userId });
      await this.storageInstance.setItem(LocalStorageItemKey.UserInfo, new LocalStorageItem(userInfo, EXP_10_MINS));
      return userInfo;
    }
  };

  fetchAllGroupWorkspaces = async (userInfo: any) => {
    const allGroupWksItem = await this.storageInstance.getItem(LocalStorageItemKey.AllGroupWorkspaces);
    if (allGroupWksItem) {
      return allGroupWksItem.data as any;
    } else {
      try {
        let getWorkspacesForGroupsRequest = { groupIds: userInfo.groupIds };
        let workspacesForGroups =
          userInfo?.groupIds?.length > 0 ? await getWorkspacesForGroups(getWorkspacesForGroupsRequest) : {};
        await this.storageInstance.setItem(
          LocalStorageItemKey.AllGroupWorkspaces,
          new LocalStorageItem(workspacesForGroups, EXP_10_MINS),
        );
        return workspacesForGroups as any;
      } catch (err) {
        console.log('Failed to get workspaces for groups', err);
      }
      return null;
    }
  };

  fetchAllMemberWorkspaces = async (userInfo: any) => {
    const allWks = await this.storageInstance.getItem(LocalStorageItemKey.AllWorkspaces);
    if (allWks) {
      return allWks.data as any;
    } else {
      // Only invoke the listWorkspaces API if there are any workspaceIds.
      // If no workspaceIds are passed as a filter, the API returns all the workspaces present.
      // See ticket https://sim.amazon.com/issues/AWSDL-5780 for details.
      const userWithWorkspaces = !!userInfo && userInfo.memberWorkspaceIds;
      let listWorkspacesResult: any = { workspaces: [] };
      try {
        if (userWithWorkspaces) {
          const workspaceIdsList: any = _.chunk(userInfo.memberWorkspaceIds, LIST_WKS_MAX_WORKSPACE_IDS);
          // split the workspaces into chunks for LIST_WKS_MAX_WORKSPACE_IDS (50) and then process each list.
          const listWorkspacesResults = await Promise.all(
            workspaceIdsList.map(async (ids) => listWorkspaces({ workspaceIds: ids })),
          );
          const allWorkspaces = listWorkspacesResults
            .filter((listWksResult) => !!listWksResult && !!listWksResult.workspaces)
            .reduce((finalList, listWksResult) => {
              finalList.push(...listWksResult.workspaces);
              return finalList;
            }, []);
          listWorkspacesResult = { workspaces: allWorkspaces };
        }
        await this.storageInstance.setItem(
          LocalStorageItemKey.AllWorkspaces,
          new LocalStorageItem(listWorkspacesResult, EXP_10_MINS),
        );
        return listWorkspacesResult as any;
      } catch (err) {
        console.log('Failed to get workspaces for groups', err);
      }
      return null;
    }
  };

  fetchGroupInfo = _.memoize(async function (groupId: string) {
    return await getGroupInfo({ groupId: groupId });
  });

  fetchCurrentWorkspace = _.memoize(async function (wksId: string) {
    return await getWorkspace({ workspaceId: wksId });
  });

  // fetch all active advisories for the current user, and update the side nav and flashbar accordingly.
  fetchActiveAdvisoriesForDataYouConsume = async function (groupId: string, workspaceId: string) {
    const groupOrWorkspaceId =
      workspaceId == undefined || workspaceId == null || workspaceId == 'null' ? groupId : workspaceId;
    let advisories: Advisory[] = [];
    try {
      let nextToken = undefined;
      while (true) {
        const listAdvisoriesResult = await listAdvisories({
          ownerId: groupOrWorkspaceId,

          filter: {
            quickFilters: {
              allAdvisories: false,
              advisoriesForDataYouHaveAccessTo: true,
              advisoriesForDataYouOwn: false,
            },
          },
          nextToken: nextToken,
        });
        advisories.push(...listAdvisoriesResult.advisories);
        nextToken = listAdvisoriesResult.nextToken;
        if (!nextToken) break;
      }
      console.info('Fetched active advisories with %s entries', advisories.length);
      this.setState({ activeAdvisories: advisories });
    } catch (e) {
      console.log('Error when fetching active advisories.', e);
      this.setState({ activeAdvisories: [] });
    }
    this.setNavItems(this.state.customerType);
  };

  isNonAllowlistedUser = _.memoize(function (userInfo) {
    return (
      !!userInfo &&
      userInfo.groupIds.length == 1 &&
      userInfo.groupIds[0] === AWSDGSFOUNDRYCATALOG_GROUP_ID &&
      userInfo.memberGroupIds.length == 1 &&
      userInfo.memberGroupIds[0] === AWSDGSFOUNDRYCATALOG_GROUP_ID
    );
  });

  async componentDidMount() {
    // first, before calling any API's, should init clients
    await initAllClients();

    const parsedUrl = new URL(window.location.href);
    const signInWithOwner = parsedUrl?.searchParams.get('signInWith');
    // Get the history object
    const history = window.history;
    // Clear the selectedOwner query parameter
    if (signInWithOwner) {
      parsedUrl.searchParams.delete('signInWith');
      history.pushState(null, null, parsedUrl.toString());
    }

    this.setActiveGroupOrWorkspace = this.setActiveGroupOrWorkspace.bind(this);
    let user = '';
    let userInfo;
    try {
      user = await getUser();
      userInfo = await this.fetchUserInfo(user);
      console.log('Logged in as: ' + user);

      if (this.isNonAllowlistedUser(userInfo)) {
        console.log('Midway user with default Foundry Gladstone group found.');
        this.setState({ allowlisted: false });
      }

      this.setState({
        active: getActiveHref(),
        username: user,
        groups: userInfo.groupIds,
        userInfo: userInfo,
      });
      await this.setDropdownItems(userInfo);
    } catch (err) {
      if (err?.name.includes(NOT_FOUND_EXCEPTION)) {
        // provided user is not safelisted in Gladstone
        this.setState({
          allowlisted: false,
          username: user,
        });
        console.error('error ..', err);
      } else {
        // Server error (when dynamodb issue)
        this.setState({
          allowlisted: false,
          internalError: true,
          username: user,
        });
        console.log('Something went wrong... please come back later', err);
      }
    }

    try {
      let externalGroupsResponse = await getExternalGroups({});
      let externalGroups = externalGroupsResponse?.externalGroupsList
        .map((group) => group?.externalGroup?.galaxiGroup)
        .filter((groupItem) => groupItem != null);
      this.setState({
        externalGroups: externalGroups,
      });
    } catch (err) {
      if (
        err?.name == 'UnsupportedOperationException' &&
        err?.message == 'Galaxi group service is not enabled in this environment'
      ) {
        console.error(err.message);
      } else {
        console.error('Error fetching external groups');
      }
      this.setState({ externalGroups: undefined });
    }

    let savedGroup = localStorage.getItem('activeGroup');
    let savedWorkspace = localStorage.getItem('activeWorkspace');

    if (
      userInfo == undefined ||
      ((userInfo.groupIds == undefined || userInfo.groupIds.length == 0) &&
        (userInfo.memberWorkspaceIds == undefined || userInfo.memberWorkspaceIds.length == 0))
    ) {
      // user not safelisted
      this.setState({ allowlisted: false });
      this.setActiveGroupOrWorkspace('', null);
      this.setState({ workspaceId: null });
    } else if (
      signInWithOwner &&
      (userInfo?.groupIds?.includes(signInWithOwner) || userInfo?.memberWorkspaceIds?.includes(signInWithOwner))
    ) {
      //user belongs to group/workspace provided in the URL.
      if (signInWithOwner.startsWith(WORKSPACE_PREFIX)) {
        //provided the workspaceId
        this.setActiveGroupOrWorkspace(null, signInWithOwner);
        this.setState({ workspaceId: signInWithOwner });
      } else {
        //provided the groupId
        this.setActiveGroupOrWorkspace(signInWithOwner, null);
        this.setState({ workspaceId: null });
      }
    } else if (savedGroup && userInfo?.groupIds?.includes(savedGroup)) {
      // cached group is present and user is a part of it
      this.setActiveGroupOrWorkspace(savedGroup, savedWorkspace);
      this.setState({ workspaceId: savedWorkspace });
    } else if (savedWorkspace && userInfo?.memberWorkspaceIds?.includes(savedWorkspace)) {
      // cached workspace is present and user is a part of it
      this.setActiveGroupOrWorkspace(savedGroup, savedWorkspace);
      this.setState({ workspaceId: savedWorkspace });
    } else if (
      (userInfo.groupIds == undefined || userInfo.groupIds.length == 0) &&
      userInfo.memberWorkspaceIds &&
      userInfo.memberWorkspaceIds.length != 0
    ) {
      // user not a part of any group but only workspace
      this.setActiveGroupOrWorkspace(null, userInfo.memberWorkspaceIds[0]);
      this.setState({ workspaceId: userInfo.memberWorkspaceIds[0] });
    } else {
      // no cached data found and user have a group
      this.setActiveGroupOrWorkspace(userInfo.groupIds[0], null);
      this.setState({ workspaceId: null });
    }

    const catalogNameMap = await generateCatalogMap(this.storageInstance);
    catalogNameMap.forEach((v, k) => this.state.catalogMap.set(k, v));
    // generate async workspace name map:
    generateWorkspaceNameMap(this.storageInstance).then((wksNameMap) => {
      console.info('Fetched workspaces map with %s entries', wksNameMap.size);
      this.setState({ workspaceNameMap: wksNameMap });
    });

    //update notifications when the page is open
    this.updateNotifications(this.state.workspaceId ? this.state.workspaceId : this.state.activeGroup);
    // set side panel for search page
    this.setState({
      displaySplitPanel: !!this.state.active.endsWith(Page.SEARCH),
    });
  }

  async updateNotifications(group) {
    if (group === AWSDGSFOUNDRYCATALOG_GROUP_ID || group == null) return;
    if (group != 'null') {
      const notifs = await updateNotificationIcon(group, this.state.username);
      let unreadNotifications = notifs.unreadNotifications;
      let workspaceCount = notifs.workspaceCount;
      let unreadNotificationBatches = notifs.unreadNotificationBatches;
      this.setState({
        unreadNotifications: unreadNotifications,
        unreadNotificationBatches: unreadNotificationBatches,
        workspaceCount: workspaceCount,
      });
    }
  }

  async componentDidUpdate() {
    const href = getActiveHref();
    if (this.state.active != href) {
      this.setState({ active: href });
      this.handleSearchFiltersDisplay(href);
    }
  }

  async setNavItems(customerType) {
    let navItems;

    // Iso region navigation
    if (isIso()) {
      if (isDataLakeAdmin(this.state.activeGroup)) {
        navItems = navItemsForIsoAdmins;
      } else if (customerType == DATACONSUMER) {
        navItems = navItemsForIsoConsumer;
      } else if (customerType == DATAPROVIDER || customerType == DATAPROVIDER_AND_CONSUMER) {
        navItems = navItemsForIsoProvider;
      } else {
        navItems = navItemsForIsoConsumer;
      }
    }

    // Workspace level navigation
    else if (
      this.state.activeWorkspace !== undefined &&
      this.state.activeWorkspace !== null &&
      this.state.activeWorkspace !== 'null'
    ) {
      if (isDataLakeAdmin(this.state.activeGroup)) {
        navItems = navItemsForWorkspacesProvider;
      } else if (customerType == DATACONSUMER) {
        navItems = navItemsForWorkspacesConsumer;
      } else if (customerType == DATAPROVIDER || customerType == DATAPROVIDER_AND_CONSUMER) {
        navItems = navItemsForWorkspacesProvider;
      } else {
        navItems = navItemsForWorkspacesConsumer;
      }
    }

    // Group level navigation
    else {
      if (isDataLakeAdmin(this.state.activeGroup)) {
        navItems = navItemsForAdmins;
      } else if (customerType == DATACONSUMER) {
        navItems = navItemsForConsumer;
      } else if (customerType == DATAPROVIDER || customerType == DATAPROVIDER_AND_CONSUMER) {
        navItems = navItemsForProvider;
      } else {
        navItems = navItemsForConsumer;
      }
    }

    this.setState({
      navigationItemsForCustomerType: await navItems(this.state),
    });
  }

  async setDropdownItems(userInfo) {
    const groups = userInfo.groupIds;
    let items = [];
    const isNonAllowlistedUser = this.isNonAllowlistedUser(userInfo);
    if (!isNonAllowlistedUser) {
      let workspacesForGroups = await this.fetchAllGroupWorkspaces(userInfo);
      for (let group of groups) {
        items.push({ text: group, id: group });
        let workspaceList = [];
        // did the API itself return successfully?
        if (workspacesForGroups != null && workspacesForGroups.workspaces !== undefined) {
          let workspaces = workspacesForGroups.workspaces;
          let currentWorkspaces = workspaces[group];
          // did each group return workspaces or an error?
          if (
            currentWorkspaces !== undefined &&
            currentWorkspaces.succeeded &&
            currentWorkspaces.workspaces !== undefined
          ) {
            for (let ws of currentWorkspaces.workspaces) {
              // did each workspace return with all its information?
              if (ws.workspaceName !== undefined && ws.workspaceId !== undefined) {
                workspaceList.push({
                  text: getWorkspaceDisplayName(ws.workspaceName, ws.workspaceId),
                  id: getDropdownItemId(group, ws.workspaceId),
                  external: false,
                });
              }
            }
          } else {
            console.log('Failed to get workspaces for group:', group);
          }
        }
        if (workspaceList.length !== 0) {
          items.push({ text: ' ', id: group, items: workspaceList });
        }
      }
    }
    // RBAC (Role Based Access Control) allows user to belong to a workspace but not the workspace's group.

    const groupIdToWksListMap = new Map();
    if (!isNonAllowlistedUser) {
      try {
        const listWorkspacesResult = await this.fetchAllMemberWorkspaces(userInfo);
        const workspaces = listWorkspacesResult ? listWorkspacesResult.workspaces : [];

        for (let workspace of workspaces) {
          const wksGroupId = workspace.groupId;
          const wksId = workspace.workspaceId;
          const wksName = workspace.workspaceName;
          if (wksGroupId && wksId && wksName) {
            if (!groups.includes(wksGroupId)) {
              const wksDropdownItem = {
                text: getWorkspaceDisplayName(wksName, wksId),
                id: getDropdownItemId(wksGroupId, wksId),
                external: false,
              };

              if (groupIdToWksListMap.has(wksGroupId)) {
                groupIdToWksListMap.get(wksGroupId).push(wksDropdownItem);
              } else {
                groupIdToWksListMap.set(wksGroupId, [wksDropdownItem]);
              }
            }
          }
        }
      } catch (err) {
        console.error('Failed to list workspaces.', err);
      }
    }

    // Display group ID and workspace name normally but group ID click is disabled.
    for (let [groupId, wksList] of groupIdToWksListMap.entries()) {
      items.push({ text: groupId, id: groupId, disabled: true });
      items.push({ text: ' ', id: groupId, items: wksList });
    }
    this.setState({
      groupsAndWorkspacesDropdownItems: items,
    });
  }

  async setActiveGroupOrWorkspace(groupId, workspaceId) {
    let redirectToHomePage = true;
    //this will validate the accepted redirected pages, redirection should happen for Requests and Dataset detail pages
    // and default to home page for all other pages
    const isRedirectAccepted = shouldRedirectPage(window.location.pathname);

    if (isRedirectAccepted) {
      redirectToHomePage = false;
    }
    let savedGroup = localStorage.getItem('activeGroup');
    let savedWorkspace = localStorage.getItem('activeWorkspace');

    if (groupId == savedGroup && workspaceId == savedWorkspace) {
      redirectToHomePage = false;
    }

    let currentPath = getActiveHref();
    const isGroup = workspaceId === null || workspaceId === undefined || workspaceId == 'null';
    const isWorkspace =
      (workspaceId != null && workspaceId != '' && workspaceId != 'null') ||
      // can be a workspace from saved context
      (savedWorkspace != null && savedWorkspace != '' && savedWorkspace != 'null');
    if ((isGroup && !isGroupLevelPath(currentPath)) || (isWorkspace && !isWorkspacePath(currentPath))) {
      if (!isRedirectAccepted) {
        this.setState({ redirect: '/' }, () => {
          this.setState({ redirect: undefined });
          this.setState({ displaySplitPanel: false });
        });
      }
    }

    await this.setState({
      activeGroup: groupId,
      workspaceId: workspaceId,
      activeAdvisories: [],
      loadingGroup: true,
      unreadNotifications: [],
      unreadNotificationBatches: [],
      workspacesForGroups: {},
    });

    try {
      let workspacesForGroups = !!groupId ? await getWorkspacesForSingleGroupMemoized(groupId) : undefined;
      let list = workspacesForGroups?.workspaces[this.state.activeGroup]?.workspaces
        ? workspacesForGroups.workspaces[this.state.activeGroup].workspaces.map((item) => item['workspaceId'])
        : {};
      this.setState({ workspacesForGroups: list });
    } catch (err) {
      console.log('Error fetching workspaces for group:', groupId);
      console.log(err);
    }
    if (workspaceId !== undefined && workspaceId !== null && workspaceId !== 'null') {
      let res = await this.fetchCurrentWorkspace(workspaceId);
      this.setState({
        activeWorkspace: res.workspace,
        workspaceId: res.workspace.workspaceId,
      });
      this.updateNotifications(this.state.workspaceId ? this.state.workspaceId : this.state.activeGroup);
      const groupInfo = groupId ? await this.fetchGroupInfo(groupId) : undefined;
      const customerType = groupInfo ? groupInfo.teamInfo['CustomerType'] : DATAPROVIDER_AND_CONSUMER;
      this.setState({
        activeGroupInfo: groupInfo,
        navigationItemsForCustomerType:
          customerType == DATACONSUMER
            ? await navItemsForWorkspacesConsumer(this.state)
            : await navItemsForWorkspacesProvider(this.state),
        customerType: customerType,
      });
      this.setState({ loadingGroup: false });
      await this.setNavItems(this.state.customerType);
      localStorage.setItem('activeGroup', groupId);
      localStorage.setItem('activeWorkspace', workspaceId);
      if (redirectToHomePage) {
        this.setState(
          {
            redirect: '/',
          },
          () => {
            this.setState({ redirect: undefined });
            this.setState({ displaySplitPanel: false });
          },
        );
      }
      return;
    }
    localStorage.setItem('activeGroup', groupId);
    localStorage.setItem('activeWorkspace', null);

    try {
      const groupInfo = await this.fetchGroupInfo(groupId);
      const teamInfo = groupInfo.teamInfo;
      const customerType = teamInfo['CustomerType'];
      console.log('App page:: CustomerType: ', customerType);
      localStorage.setItem('customerType', customerType);
      const nItems =
        customerType == DATACONSUMER ? await navItemsForConsumer(this.state) : await navItemsForProvider(this.state);
      await this.setState({
        activeWorkspace: null,
        workspaceId: null,
        activeGroupInfo: groupInfo,
        navigationItemsForCustomerType: nItems,
        customerType: customerType,
      });
      this.updateNotifications(this.state.workspaceId ? this.state.workspaceId : this.state.activeGroup);
    } catch (err) {
      console.error('Error fetching group ' + groupId);
    }

    this.setState({ loadingGroup: false });
    this.fetchActiveAdvisoriesForDataYouConsume(groupId, workspaceId);

    if (redirectToHomePage) {
      this.setState(
        {
          redirect: '/',
        },
        () => {
          this.setState({ redirect: undefined });
          this.setState({ displaySplitPanel: false });
        },
      );
    }
  }

  handleSearchFiltersDisplay(currentPath: string) {
    let isAdvSearchPage = currentPath?.endsWith(Page.SEARCH);
    this.setState({ displaySplitPanel: isAdvSearchPage });
    if (!isAdvSearchPage) {
      // reset the search filters, help panel to default state
      this.setState((state: any) => ({
        toolsOpen: false,
        toolsHide: false,
        searchFilters: { ...state.searchFilters, filters: {} },
      }));
    }
  }

  handleFollow = (e) => {
    e.preventDefault();
    if (e.detail.href === 'javascript:void(0)') return;
    if (e.detail.external) {
      window.open(e.detail.href);
    } else {
      this.setState(
        {
          active: e.detail.href,
          redirect: e.detail.href,
        },
        () => this.setState({ redirect: undefined }),
      );
    }
    this.handleSearchFiltersDisplay(e.detail.href);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    if (this.state.username === undefined || this.state.activeGroup === undefined) {
      return (
        <div style={{ textAlign: 'center', paddingLeft: '30px' }}>
          <IsengardAuthModal />
          <Spinner size='large' />
        </div>
      );
    }

    if (!this.state.allowlisted) {
      if (this.state.internalError) {
        return (
          <Alert header='Something went wrong' type={'error'}>
            Please check back later or reach out to our{' '}
            <Link href={DGS_ONCALL_URL} external={true}>
              oncall
            </Link>{' '}
            for more information.
          </Alert>
        );
      } else {
        // Only display the onboarding and data discovery pages if we can get a valid user from Midway
        return this.state.username ? <OnboardingComponent /> : <h1>Access Denied</h1>;
      }
    }

    return (
      <div>
        <div id={'top-nav-bar'} style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
          <PageHeader
            user={this.state.username}
            groups={this.state.groups}
            activeGroup={this.state.activeGroup}
            setGroup={this.setActiveGroupOrWorkspace}
            loadingGroup={this.state.loadingGroup}
            groupInfo={this.state.activeGroupInfo}
            cartItems={this.state.cartItems}
            unreadNotifications={this.state.unreadNotifications}
            workspaceCount={this.state.workspaceCount}
            removeUnreadNotificationId={this.state.removeUnreadNotificationId}
            unreadNotificationBatches={this.state.unreadNotificationBatches}
            removeUnreadNotificationBatchId={this.state.removeUnreadNotificationBatchId}
            groupsAndWorkspacesDropdownItems={this.state.groupsAndWorkspacesDropdownItems}
            activeWorkspace={this.state.activeWorkspace}
            workspacesForGroups={this.state.workspacesForGroups}
          />
        </div>
        <AppLayout
          contentType={this.state.contentType}
          className={isHomePage() ? 'awsui-util-no-gutters' : ''}
          disableContentPaddings={isHomePage()}
          splitPanelPreferences={{ position: 'side' }}
          splitPanelOpen={this.state.splitPanelOpen}
          onSplitPanelToggle={this.onSplitPanelToggleHandler}
          splitPanelSize={this.state.splitPanelSize}
          onSplitPanelResize={this.onSplitPanelResizeHandler}
          splitPanel={
            this.state.displaySplitPanel ? (
              <SearchFilters
                {...this.state}
                entityType={this.state.searchFilters.entityType}
                onEntityTypeChange={this.onSearchEntityTypeChangeHandler}
                aggregations={this.state.searchFilters.aggregations}
                filters={this.state.searchFilters.filters}
                onFiltersChange={this.onSearchFiltersChangeHandler}
                searchLoading={this.state.searchLoading}
              />
            ) : null
          }
          content={
            this.state.loadingGroup ? (
              <div>
                <Spinner size='big' />
              </div>
            ) : (
              <div>
                <ErrorBoundary>
                  <SpaceBetween size={isHomePage() ? null : 'm'} direction='vertical'>
                    <ActiveAdvisoriesHeader activeAdvisories={this.state.activeAdvisories} />
                    <Routes
                      {...this.state}
                      setContentType={(type) => this.setState({ contentType: type })}
                      setActiveDatasetName={(name) => this.setState({ activeDatasetName: name })}
                      setActiveCatalogName={(name) => this.setState({ activeCatalogName: name })}
                      setActiveDatabaseName={(name) => this.setState({ activeDatabaseName: name })}
                      setActiveTagName={(name) => this.setState({ activeTagName: name })}
                      setNavigationOpen={(open) => this.setState({ navOpen: open })}
                      setItemsInCart={(cartItems: object[]) => this.setCartItemsAndIds(cartItems)}
                      refreshUnreadNotifications={(removeUnreadNotificationId, removeUnreadNotificationBatchId) => {
                        this.setState({
                          removeUnreadNotificationId: removeUnreadNotificationId,
                          removeUnreadNotificationBatchId: removeUnreadNotificationBatchId,
                        });
                      }}
                      addToCart={(items: object[]) => {
                        const newCartItems = [];
                        newCartItems.push(...this.state.cartItems);
                        newCartItems.push(...items);
                        this.setCartItemsAndIds(newCartItems);
                      }}
                      removeFromCart={(items: object[]) => {
                        const itemIDsToRemove = items.map((item) => item['id']);
                        const newCartItems = [];
                        newCartItems.push(
                          ...this.state.cartItems.filter((item) => !itemIDsToRemove.includes(item['id'])),
                        );
                        this.setCartItemsAndIds(newCartItems);
                      }}
                      allowListed={this.state.allowlisted}
                      getStartedTarget={Page.SEARCH}
                      toggleHelp={() => this.setState({ toolsOpen: !this.state.toolsOpen })}
                      setNotifications={(notifications) => this.setState({ notifications })}
                      catalogMap={this.state.catalogMap}
                      onAggregationsChange={this.onSearchAggregationsChangeHandler}
                      onSearchQueryChange={this.onSearchQueryChangeHandler}
                      onSetSearchLoading={this.onSetSearchLoadingHandler}
                      setDisplaySplitPanel={(open: boolean) => this.setState({ displaySplitPanel: open })}
                      setToolsHide={this.onToolsHideHandler}
                    />
                  </SpaceBetween>
                </ErrorBoundary>
                <main className='container' />
              </div>
            )
          }
          breadcrumbs={
            isHomePage() ? null : (
              <Breadcrumbs
                activeCatalogName={this.state.activeCatalogName}
                activeDatabaseName={this.state.activeDatabaseName}
                activeDatasetName={this.state.activeDatasetName}
                activeTagName={this.state.activeTagName}
                onFollow={this.handleFollow}
              />
            )
          }
          navigation={
            this.state.loadingGroup ? (
              <div>
                <Spinner size='big' />
              </div>
            ) : (
              <SideNavigation
                items={this.state.navigationItemsForCustomerType}
                activeHref={this.state.active}
                onFollow={this.handleFollow}
              />
            )
          }
          tools={<Help setToolsHide={this.onToolsHideHandler} />}
          navigationOpen={this.state.navOpen}
          onNavigationChange={(e) => {
            this.setState({ navOpen: e.detail.open });
          }}
          toolsHide={this.state.toolsHide}
          onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
          toolsOpen={this.state.toolsOpen}
          notifications={<Flashbar items={this.state.notifications} />}
          ariaLabels={appLayoutLabels}
          headerSelector='#top-nav-bar'
        />
      </div>
    );
  }

  private setCartItemsAndIds(newCartItems: object[]) {
    this.setState({
      cartItems: newCartItems,
      cartItemIds: newCartItems.map((item) => item['id']),
    });
  }
}

export default App;
