import { getStage } from 'src/api/config';

import {
  Alert,
  Input,
  Header,
  Container,
  FormField,
  SpaceBetween,
  Link,
  Select,
  SelectProps,
} from '@amzn/awsui-components-react-v3';
import { ResourceGroup } from 'aws-sdk/clients/awsdatalakeresourcesmanagerlambda';
import { getTableNameErrors, getDatabaseNameErrors, getCatalogIdErrors } from '../utils/validation';
import { testStrings } from '../../common/testStrings';
import { docs } from '../../common/constants';
import { iStep } from '../utils/types';
import { defaultTethysRMGroup } from '../utils/requestPayload';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { listResourceGroups } from '../../../../api/resourcesmanager';

export const RegisterStep = ({ request, isUpdate, isValidated, setRequest }: iStep) => {
  const [resourceGroups, setResourceGroups] = useState<ResourceGroup[]>([]);

  useEffect(() => {
    // Fetch resource groups and update the resourceGroups state
    const fetchAndSetResourceGroups = async () => {
      try {
        //console.log('Fetching resource groups with PrimaryOwner:', request.PrimaryOwner);
        const { resourceGroups: fetchedGroups } = await listResourceGroups({
          groupId: request.PrimaryOwner,
        });
        //console.log('Fetched resource groups:', fetchedGroups);
        setResourceGroups([...fetchedGroups, { name: defaultTethysRMGroup }]);
      } catch (error) {
        console.error('Error fetching resource groups:', error);
      }
    };
    fetchAndSetResourceGroups();
  }, [request.PrimaryOwner]);

  const handleRMGroupChange: SelectProps['onChange'] = ({ detail }) => {
    setRequest({ ...request, ResourceGroupName: detail.selectedOption.value });
  };

  return (
    <SpaceBetween direction='vertical' size='xl'>
      {!isUpdate && (
        <Alert header='Onboarding prerequisites'>
          Before registering your first dataset, follow Tethys
          <Link href={docs.onboarding} external>
            {' '}
            onboading prerequisites
          </Link>
        </Alert>
      )}

      <Container header={<Header variant='h2'>Dataset properties</Header>}>
        <SpaceBetween direction='vertical' size='l'>
          <FormField
            label='Dataset name'
            description='This is how other AWS teams will find, and identify your dataset.'
            errorText={isValidated ? getTableNameErrors(request) : ''}
          >
            <Input
              disabled={isUpdate}
              data-testid={testStrings.contractRegistration.step1.dataset}
              value={request.TableName}
              onChange={({ detail: { value } }) => setRequest({ ...request, TableName: value, DataSetName: value })}
            />
          </FormField>

          <FormField
            label={
              <>
                Database{' '}
                <Link variant='info' href={docs.databaseName} external>
                  Docs
                </Link>
              </>
            }
            description={`The AWS Glue database created during the onboarding prerequisites. Starts with aws_tethys`}
            errorText={isValidated ? getDatabaseNameErrors(request) : ''}
          >
            <Input
              disabled={isUpdate}
              data-testid={testStrings.contractRegistration.step1.database}
              value={request.DatabaseName}
              onChange={({ detail }) => setRequest({ ...request, DatabaseName: detail.value })}
            />
          </FormField>

          <FormField
            label={'AWS Glue Catalog ID'}
            description='The AWS account ID from the Glue catalog of your Tethys database'
            errorText={isValidated ? getCatalogIdErrors(request) : ''}
          >
            <Input
              type='number'
              data-testid={testStrings.contractRegistration.step1.catalog}
              disabled={isUpdate}
              value={request.CatalogId}
              onChange={({ detail }) => setRequest({ ...request, CatalogId: detail.value })}
            />
          </FormField>

          {(getStage() === 'gamma_iad' || getStage() === 'beta') && (
            <FormField
              label={
                <>
                  RM Group Name{' '}
                  <Link variant='info' href='/resourcegroups/new'>
                    Not seeing any resource groups? Click here to create one.
                  </Link>
                </>
              }
              description='The Resource Manager (RM) group name for your dataset'
            >
              <Select
                placeholder='Select a resource group'
                selectedOption={
                  request.ResourceGroupName
                    ? {
                        label: request.ResourceGroupName,
                        value: request.ResourceGroupName,
                      }
                    : undefined
                }
                onChange={handleRMGroupChange}
                options={resourceGroups.map((rg) => ({
                  label: rg.name,
                  value: rg.name,
                }))}
              />
            </FormField>
          )}
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
