import * as React from 'react';

import { useEffect, useState } from 'react';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { createPublishedPermissionDetailLink, createWorkspaceDetailLink, Page } from 'src/routes';
import { getFgaPolicy } from 'src/api/permissions';
import { Link, Redirect } from 'react-router-dom';
import { ColumnLayout, Flashbar } from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/permissions/myPermissions/common';

export interface ViewFgaPolicyProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const ViewFgaPolicy = (props: ViewFgaPolicyProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [fgaPolicyId, setFgaPolicyId] = useState(undefined);
  const [fgaPolicy, setFgaPolicy] = useState(undefined);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    const activeWorkspace = props.activeWorkspace;
    // only allow workspace users
    if (activeWorkspace) {
      setFgaPolicyId(props.match.params.id);
      console.log('policy id retrived:', props.match.params.id);
    } else {
      setRedirect(Page.HOME);
    }
  }, []);

  useEffect(() => {
    if (fgaPolicyId) {
      getFgaPolicyForGivenId();
    }
  }, [fgaPolicyId]);

  const handleRefresh = async () => {
    await getFgaPolicyForGivenId();
  };

  const getFgaPolicyForGivenId = async () => {
    try {
      let getFgaPolicyResult = await getFgaPolicy({
        id: fgaPolicyId,
      });
      setFgaPolicy(getFgaPolicyResult.FineGrainAccess);
    } catch (err) {
      setNotification('error', err.message);
    }
  };
  const buttonOptions = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
    ];
  };

  const setNotification = async (header, message) => {
    console.log('set notification');
    if (header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}

      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>

      <PageHeader buttons={buttonOptions()} header={`Policy ID: ${fgaPolicyId}`} />
      <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
          <h2>Policy details</h2>
        </div>
        <ColumnLayout columns={4} variant='text-grid'>
          <div>
            <div className='awsui-util-label'>Owner</div>
            <div>
              <Link to={createWorkspaceDetailLink(fgaPolicy?.ownerId)}>{fgaPolicy?.ownerId}</Link>
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>Policy name</div>
            <div> {fgaPolicy?.tableData?.name}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Description</div>
            <div> {fgaPolicy?.description}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Scope</div>
            <div> {fgaPolicy?.scope}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Type</div>
            <div> {fgaPolicy?.type}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Status</div>
            <div> {fgaPolicy?.status}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Status reason</div>
            <div> {fgaPolicy?.statusReason}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Data permission</div>
            <div>
              <Link to={createPublishedPermissionDetailLink(fgaPolicy?.dataPermissionId)}>
                {fgaPolicy?.dataPermissionId}
              </Link>
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>Create date</div>
            <div> {fgaPolicy?.createDate}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Update date</div>
            <div> {fgaPolicy?.updateDate}</div>
          </div>
          {fgaPolicy?.tableData?.clusterId && (
            <div>
              <div className='awsui-util-label'>Cluster</div>
              <div> {fgaPolicy?.tableData?.clusterId}</div>
            </div>
          )}

          <div>
            <div className='awsui-util-label'>Database</div>
            <div> {fgaPolicy?.tableData?.databaseName}</div>
          </div>
          {fgaPolicy?.tableData?.clusterId && (
            <div>
              <div className='awsui-util-label'>Schema</div>
              <div> {fgaPolicy?.tableData?.schemaName}</div>
            </div>
          )}
          <div>
            <div className='awsui-util-label'>Table</div>
            <div> {fgaPolicy?.tableData?.tableName}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Row filter expression</div>
            <div>
              {fgaPolicy?.tableData?.rowFilter?.filterExpression ? fgaPolicy.tableData.rowFilter.filterExpression : '*'}
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>Column level access</div>
            <div>
              {fgaPolicy?.tableData?.columnNames
                ? 'Include'
                : fgaPolicy?.tableData?.columnWildcard?.excludedColumnNames
                ? 'Exclude'
                : '*'}
            </div>
          </div>

          <div>
            <div className='awsui-util-label'>Columns</div>
            <div>
              {fgaPolicy?.tableData?.columnNames
                ? fgaPolicy?.tableData?.columnNames.join(',')
                : fgaPolicy?.tableData?.columnWildcard?.excludedColumnNames
                ? fgaPolicy?.tableData?.columnWildcard?.excludedColumnNames.join(',')
                : null}
            </div>
          </div>
        </ColumnLayout>
      </div>
    </div>
  );
};

export default ViewFgaPolicy;
