import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  ColumnLayout,
  Flashbar,
  Modal,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { STATUS_INACTIVE, STATUS_PENDING, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { listFgaPolicies, deleteFgaPolicy, createFgaPolicy } from 'src/api/permissions';
import {
  createFgaPolicyViewLink,
  createPublishedPermissionDetailLink,
  createWorkspaceDetailLink,
  Page,
} from 'src/routes';
import { EmptyState } from 'src/commons/EmptyState';
import { PageHeader } from 'src/components/subscriptions/common';
import { defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';
import { StatusIcon } from 'src/components/permissions/myDatasets/statusIcon';

export interface FineGrainAccessPoliciesProps {
  setContentType: any;
  activeGroup: string;
  match: any;
  activeWorkspace?: any;
}

export const FineGrainAccessPolicies = (props: FineGrainAccessPoliciesProps) => {
  const { state } = useLocation();
  const [redirect, setRedirect] = useState(undefined);
  const [loadingPolicies, setLoadingPolicies] = useState(false);
  const [ownerId, setOwnerId] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [deletePolicyModalVisible, setDeletePolicyModalVisible] = useState(false);
  const [viewPolicyModalVisible, setViewPolicyModalVisible] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(undefined);
  const [selectedPolicyId, setSelectedPolicyId] = useState(undefined);
  const [fgaPolicies, setFgaPolicies] = useState([]);
  const [tableMessage, setTableMessage] = useState('No policies');
  const [notifications, setNotifications] = useState([]);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });
  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(fgaPolicies, {
    filtering: {
      noMatch: '',
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No policies found.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedPolicy = selectedItems[0];
    if (selectedPolicy) {
      setSelectedPolicy(selectedPolicy);
      setSelectedPolicyId(selectedPolicy.id);
    }
  }, [collectionProps.selectedItems]);

  const fgaPolicyColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'policyName',
      header: 'Policy name',
      cell: (item) => <Link to={createFgaPolicyViewLink(item?.id)}>{item?.tableData?.name}</Link>,
      minWidth: 200,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.type,
      minWidth: 200,
    },
    {
      id: 'catalogId',
      header: 'Catalog',
      cell: (item) => item?.tableData?.tableCatalogId,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => <StatusIcon status={item?.status} />,
      minWidth: 200,
    },
    {
      id: 'statusReason',
      header: 'Status reason',
      cell: (item) => item?.statusReason,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);

    const activeWorkspace = props.activeWorkspace;
    if (activeWorkspace) {
      const ownerId = props.match.params.id ? props.match.params.id : activeWorkspace.workspaceId;
      setOwnerId(ownerId);

      if (state) {
        setNotification(state.status, state.message);
      }
    } else {
      setRedirect(Page.HOME);
    }
  }, []);

  useEffect(() => {
    if (ownerId) {
      getFgaPoliciesForWorkspace();
    }
  }, [ownerId]);

  const getFgaPoliciesForWorkspace = async () => {
    setLoadingPolicies(true);
    try {
      // get both LF and Redshift policies
      let listFgaPoliciesForWorkspaceResult = await listFgaPolicies({
        ownerId: ownerId,
      });
      let policies = listFgaPoliciesForWorkspaceResult.FineGrainAccess;
      while (listFgaPoliciesForWorkspaceResult.nextToken != undefined) {
        listFgaPoliciesForWorkspaceResult = await listFgaPolicies({
          ownerId: ownerId,
          nextToken: listFgaPoliciesForWorkspaceResult.nextToken,
        });
        policies.push(...listFgaPoliciesForWorkspaceResult.FineGrainAccess);
      }
      setFgaPolicies(
        policies.filter((policy) => {
          return policy != undefined;
        }),
      );
    } catch (err) {
      setTableMessage(`Unable to load policies. ${err.message}`);
    } finally {
      setLoadingPolicies(false);
    }
  };

  const handleRefresh = async () => {
    setLoadingPolicies(true);
    setSelectedPolicy(undefined);
    setSelectedPolicyId(undefined);
    await getFgaPoliciesForWorkspace();
  };

  const handleCreateFgaPolicy = async () => {
    try {
      let createFgaPolicyRequest = {
        ownerId: selectedPolicy.ownerId,
        description: selectedPolicy.description,
        scope: 'Dataset', //can be changed if needed but scoped always to dataset
        visibility: selectedPolicy.visibility,
        tableData: selectedPolicy.tableData,
      };
      const createFgaPolicyResponse = await createFgaPolicy(createFgaPolicyRequest);
      if (createFgaPolicyResponse.failures) {
        setNotification('error', 'Failure in creating policy');
      } else {
        setNotification('success', 'Submitted a request to retry the policy creation');
      }
    } catch (err) {
      setNotification('error', `Error in creating policy:${err.message}`);
    }
  };

  const handleEditFgaPolicy = async () => {
    if (selectedPolicy !== undefined) {
      setRedirect({
        pathname: Page.EDIT_FINE_GRAIN_ACCESS_POLICY,
        state: {
          fgaPolicyId: selectedPolicyId,
          workspaceId: ownerId,
        },
      });
    } else {
      setNotification('error', 'Please select a policy to edit');
    }
  };

  const handleDeleteFgaPolicy = async () => {
    setButtonLoading(true);
    try {
      let deleteFgaPolicyResult = await deleteFgaPolicy({
        id: selectedPolicy.id,
      });
      await handleRefresh();
      if (deleteFgaPolicyResult.failures) {
        setNotification('error', 'Failed to delete the policy.');
      } else {
        setNotification('success', 'Success');
      }
    } catch (err) {
      setNotification('error', `Error in deleting the policy. ${err.message}`);
    } finally {
      setButtonLoading(false);
      setDeletePolicyModalVisible(false);
    }
  };

  const buttonOptions = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Actions',
        onItemClick: handleButtonAction,
        items: [
          {
            text: 'View',
            id: 'viewPolicy',
            disabled: !selectedPolicyId,
          },
          {
            text: 'Edit',
            id: 'editPolicy',
            disabled: !selectedPolicyId || selectedPolicy?.status == STATUS_PENDING,
          },
          {
            text: 'Delete',
            id: 'deletePolicy',
            disabled: !selectedPolicyId || selectedPolicy?.status == STATUS_PENDING,
          },
          {
            text: 'Retry creation',
            id: 'recreatePolicy',
            disabled: selectedPolicy?.status != STATUS_INACTIVE,
          },
        ],
      },
      {
        text: 'Create policy',
        variant: 'primary',
        onItemClick: () => setRedirect(Page.CREATE_FINE_GRAIN_ACCESS_POLICY),
      },
    ];
  };

  const handleButtonAction = async (e) => {
    if (e.detail.id === 'editPolicy') {
      handleEditFgaPolicy();
    } else if (e.detail.id === 'deletePolicy') {
      setDeletePolicyModalVisible(true);
    } else if (e.detail.id === 'viewPolicy') {
      setViewPolicyModalVisible(true);
    } else if (e.detail.id == 'recreatePolicy') {
      handleCreateFgaPolicy();
    }
  };

  const setNotification = async (header, message) => {
    if (header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}

      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>

      <Modal
        visible={deletePolicyModalVisible}
        header={[`Delete?`]}
        onDismiss={() => setDeletePolicyModalVisible(false)}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='link'
                onClick={() => {
                  setDeletePolicyModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button variant='primary' loading={buttonLoading} onClick={handleDeleteFgaPolicy}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <div>
          <strong style={{ color: 'red' }}>WARNING: </strong>
          Are you sure you want to delete the <strong>{selectedPolicyId}</strong> policy from{' '}
          <strong>{props?.activeWorkspace?.accountId}</strong> account?
          <br />
          <br />
          <strong style={{ color: 'red' }}>This will also delete the policy from the associated AWS account.</strong>
        </div>
      </Modal>

      <Modal
        visible={viewPolicyModalVisible}
        header={[`Policy details`]}
        onDismiss={() => setViewPolicyModalVisible(false)}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='link'
                onClick={() => {
                  setViewPolicyModalVisible(false);
                }}
              >
                Cancel
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <div>
          <ColumnLayout borders='vertical' variant='text-grid' columns={3}>
            <div>
              <div className='awsui-util-label'>Policy ID</div>
              <div> {selectedPolicy?.id}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Owner</div>
              <div>
                {' '}
                <Link to={createWorkspaceDetailLink(selectedPolicy?.ownerId)}>{selectedPolicy?.ownerId}</Link>
              </div>
            </div>
            <div>
              <div className='awsui-util-label'>Description</div>
              <div> {selectedPolicy?.description}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Scope</div>
              <div> {selectedPolicy?.scope}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Type</div>
              <div> {selectedPolicy?.type}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Data permission</div>
              <div>
                {' '}
                <Link to={createPublishedPermissionDetailLink(selectedPolicy?.dataPermissionId)}>
                  {selectedPolicy?.dataPermissionId}
                </Link>
              </div>
            </div>
            <div>
              <div className='awsui-util-label'>Create date</div>
              <div> {selectedPolicy?.createDate}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Update date</div>
              <div> {selectedPolicy?.updateDate}</div>
            </div>
            {selectedPolicy?.tableData?.clusterId && (
              <div>
                <div className='awsui-util-label'>Cluster</div>
                <div> {selectedPolicy?.tableData?.clusterId}</div>
              </div>
            )}

            <div>
              <div className='awsui-util-label'>Database</div>
              <div> {selectedPolicy?.tableData?.databaseName}</div>
            </div>
            {selectedPolicy?.tableData?.clusterId && (
              <div>
                <div className='awsui-util-label'>Schema</div>
                <div> {selectedPolicy?.tableData?.schemaName}</div>
              </div>
            )}
            <div>
              <div className='awsui-util-label'>Table</div>
              <div> {selectedPolicy?.tableData?.tableName}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Column level access</div>
              <div>
                {selectedPolicy?.tableData?.columnNames
                  ? 'Include'
                  : selectedPolicy?.tableData?.columnWildcard?.excludedColumnNames
                  ? 'Exclude'
                  : '*'}
              </div>
            </div>
            <div>
              <div className='awsui-util-label'>Row filter expression</div>
              <div>
                {selectedPolicy?.tableData?.rowFilter?.filterExpression
                  ? selectedPolicy.tableData.rowFilter.filterExpression
                  : '*'}
              </div>
            </div>
            <div>
              <div className='awsui-util-label'>Columns</div>
              <div>
                {selectedPolicy?.tableData?.columnNames
                  ? selectedPolicy?.tableData?.columnNames.join(',')
                  : selectedPolicy?.tableData?.columnWildcard?.excludedColumnNames
                  ? selectedPolicy?.tableData?.columnWildcard?.excludedColumnNames.join(',')
                  : null}
              </div>
            </div>
          </ColumnLayout>
        </div>
      </Modal>

      <Table
        {...collectionProps}
        loadingText='Loading policies...'
        loading={loadingPolicies}
        columnDefinitions={fgaPolicyColumnDefinitions}
        items={items}
        selectionType='single'
        variant='stacked'
        empty={<EmptyState title='No policies' subtitle='No policies to display.' />}
        wrapLines={false}
        resizableColumns={true}
        header={
          <>
            <PageHeader buttons={buttonOptions()} header={<>Fine grain access policies</>} />
          </>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 5, label: '5 items' },
                { value: 10, label: '10 items' },
                { value: 25, label: '25 items' },
                { value: 50, label: '50 items' },
              ],
            }}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
      />
    </div>
  );
};

export default FineGrainAccessPolicies;
