import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  EXCLUDE_COLUMNS_FILTER,
  fgaPolicyTypeSelection,
  fgaPolicyVisibilitySelection,
  FORM_CONTENT_TYPE,
  INCLUDE_COLUMNS_FILTER,
  LAKE_FORMATION_DATASOURCE_ID,
  LAKEFORMATION_FGA_POLICY_TYPE,
  NO_COLUMN_FILTER,
  REDSHIFT_DATASOURCE_ID,
  REDSHIFT_FGA_POLICY_TYPE,
} from 'src/commons/constants';
import { Page } from 'src/routes';
import { Redirect } from 'react-router-dom';
import {
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  Multiselect,
  RadioGroup,
  Select,
  SelectProps,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react-v3';
import {
  getDataSetsFromHybridCatalogDatabase,
  getHybridCatalogDatabases,
  getLatestDataSetDetail,
  getSchemasForDatabase,
  listCatalogs,
} from 'src/api/catalog';

import { getRegion } from 'src/api/config';
import { createFgaPolicy } from 'src/api/permissions';
import {
  CatalogInfo,
  CatalogInfoList,
  Column,
  ColumnList,
  DatabaseInfo,
  DatabaseInfoList,
  DataSetList,
  HybridCatalogDataSet,
  SchemaInfo,
  SchemaInfoList,
} from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';

export interface CreateFgaPolicyProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const CreateFgaPolicy = (props: CreateFgaPolicyProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [policyType, setPolicyType] = useState(null);
  const [ownerId, setOwnerId] = useState(undefined);
  const [accountId, setAccountId] = useState(undefined);
  const [catalogListAsOptions, setCatalogListAsOptions] = useState(undefined);
  const [databaseListAsOptions, setDatabaseListAsOptions] = useState(undefined);
  const [schemaListAsOptions, setSchemaListAsOptions] = useState(undefined);
  const [tableListAsOptions, setTableListAsOptions] = useState(undefined);
  const [columnListAsOptions, setColumnListAsOptions] = useState(undefined);
  const [catalogIdAsOption, setCatalogIdAsOption] = useState(null);
  const [databaseNameAsOption, setDatabaseNameAsOption] = useState(null);
  const [schemaNameAsOption, setSchemaNameAsOption] = useState(null);
  const [tableNameAsOption, setTableNameAsOption] = useState(null);
  const [selectColumnFilterType, setSelectColumnFilterType] = useState(NO_COLUMN_FILTER);
  const [selectedColumns, setSelectedColumns] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [policyName, setPolicyName] = useState(undefined);
  const [rowFilterExpression, setRowFilterExpression] = useState(undefined);
  const [policyVisibility, setPolicyVisibility] = useState(null);

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);

    const activeWorkspace = props.activeWorkspace;

    if (activeWorkspace) {
      const ownerId = props.match.params.id ? props.match.params.id : activeWorkspace.workspaceId;
      setOwnerId(ownerId);
      setAccountId(activeWorkspace.accountId);
    } else {
      setRedirect(Page.HOME);
    }
  }, []);

  useEffect(() => {
    if (policyType) {
      getCatalogsForWorkspaceAndType();
    }
    setCatalogListAsOptions(undefined);
    setDatabaseListAsOptions(undefined);
    setSchemaListAsOptions(undefined);
    setTableListAsOptions(undefined);
    setColumnListAsOptions(undefined);
    setCatalogIdAsOption(null);
    setDatabaseNameAsOption(null);
    setSchemaNameAsOption(null);
    setTableNameAsOption(null);
    setSelectColumnFilterType(NO_COLUMN_FILTER);
    setSelectedColumns([]);
    setRowFilterExpression(undefined);
  }, [policyType]);

  useEffect(() => {
    if (catalogIdAsOption) {
      getDatabasesFromSelectedCatalog();
    }
    setDatabaseListAsOptions(undefined);
    setSchemaListAsOptions(undefined);
    setTableListAsOptions(undefined);
    setColumnListAsOptions(undefined);
    setDatabaseNameAsOption(null);
    setSchemaNameAsOption(null);
    setTableNameAsOption(null);
    setSelectColumnFilterType(NO_COLUMN_FILTER);
    setSelectedColumns([]);
    setRowFilterExpression(undefined);
  }, [catalogIdAsOption]);

  useEffect(() => {
    if (databaseNameAsOption) {
      getSchemasFromSelectedDatabase();
    }
    setSchemaListAsOptions(undefined);
    setTableListAsOptions(undefined);
    setColumnListAsOptions(undefined);
    setSchemaNameAsOption(null);
    setTableNameAsOption(null);
    setSelectColumnFilterType(NO_COLUMN_FILTER);
    setSelectedColumns([]);
    setRowFilterExpression(undefined);
  }, [databaseNameAsOption]);

  useEffect(() => {
    if (schemaNameAsOption || databaseNameAsOption) {
      getTablesFromSchemaOrDatabase();
    }
    setTableListAsOptions(undefined);
    setColumnListAsOptions(undefined);
    setTableNameAsOption(null);
    setSelectColumnFilterType(NO_COLUMN_FILTER);
    setSelectedColumns([]);
    setRowFilterExpression(undefined);
  }, [databaseNameAsOption, schemaNameAsOption]);

  useEffect(() => {
    if (tableNameAsOption) {
      getLatestDatasetDetailOfDataset(tableNameAsOption);
    }
    setColumnListAsOptions(undefined);
    setSelectColumnFilterType(NO_COLUMN_FILTER);
    setSelectedColumns([]);
    setRowFilterExpression(undefined);
  }, [tableNameAsOption]);

  const getCatalogsForWorkspaceAndType = async () => {
    try {
      let totalCatalogList = [];
      let getCatalogsResult = await listCatalogs({
        Filter: null,
      });
      totalCatalogList.push(...getCatalogsResult.CatalogInfoList);
      while (getCatalogsResult.NextToken != undefined) {
        getCatalogsResult = await listCatalogs({
          Filter: null,
          NextToken: getCatalogsResult.NextToken,
        });
        totalCatalogList.push(...getCatalogsResult.CatalogInfoList);
      }
      // get catalogs for current workspace
      const currentWorkspaceCatalogs = totalCatalogList.filter((catalogInfo) => {
        return catalogInfo != undefined && catalogInfo.Owner === ownerId;
      });
      let catalogsBasedOnType = [];
      if (policyType?.value === LAKEFORMATION_FGA_POLICY_TYPE) {
        catalogsBasedOnType = currentWorkspaceCatalogs.filter((catalogInfo) => {
          return catalogInfo?.ClusterIdentifier === null;
        });
      } else {
        catalogsBasedOnType = currentWorkspaceCatalogs.filter((catalogInfo) => {
          return catalogInfo.ClusterIdentifier != null;
        });
      }
      let optionsReturned = convertCatalogInfosToOptions(catalogsBasedOnType);
      setCatalogListAsOptions(optionsReturned);
    } catch (err) {
      setNotification('error', err.message);
    }
  };

  const convertCatalogInfosToOptions = (catalogInfos: CatalogInfoList): SelectProps.Option[] => {
    return catalogInfos.map((catalogInfo: CatalogInfo) => {
      return {
        label: catalogInfo.ClusterIdentifier === null ? catalogInfo.CatalogId : catalogInfo.ClusterIdentifier,
        value: catalogInfo.CatalogId,
        region: catalogInfo.Region,
        clusterId: catalogInfo.ClusterIdentifier,
      };
    });
  };

  const getDatabasesFromSelectedCatalog = async () => {
    try {
      // get all databases
      let totalDatabaseList = [];
      let getDatabasesResult = await getHybridCatalogDatabases({
        DatabaseKeyList: null,
      });
      totalDatabaseList.push(...getDatabasesResult.DatabaseInfoList);
      while (getDatabasesResult.NextToken != undefined) {
        getDatabasesResult = await getHybridCatalogDatabases({
          DatabaseKeyList: null,
          NextToken: getDatabasesResult.NextToken,
        });
        totalDatabaseList.push(...getDatabasesResult.DatabaseInfoList);
      }
      // get databases for current workspace
      const currentWorkspaceDatabases = totalDatabaseList.filter((databaseInfo) => {
        return databaseInfo?.Owners.includes(ownerId);
      });
      let databasesBasedOnType = [];
      if (policyType?.value === LAKEFORMATION_FGA_POLICY_TYPE) {
        databasesBasedOnType = currentWorkspaceDatabases.filter((databaseInfo) => {
          return databaseInfo.ClusterIdentifier === null;
        });
      } else {
        databasesBasedOnType = currentWorkspaceDatabases.filter((databaseInfo) => {
          return databaseInfo.ClusterIdentifier === catalogIdAsOption?.clusterId;
        });
      }
      setDatabaseListAsOptions(convertDatabaseInfosToOptions(databasesBasedOnType));
    } catch (err) {
      setNotification('error', err.message);
    }
  };

  const convertDatabaseInfosToOptions = (databaseInfos: DatabaseInfoList): SelectProps.Option[] => {
    return databaseInfos.map((databaseInfo: DatabaseInfo) => {
      return {
        label: databaseInfo.DatabaseName,
        value: databaseInfo.DatabaseName,
        region: databaseInfo.Region,
        catalogId: databaseInfo.CatalogId,
        clusterId: databaseInfo.ClusterIdentifier,
      };
    });
  };

  const getSchemasFromSelectedDatabase = async () => {
    if (databaseNameAsOption && databaseNameAsOption?.clusterId) {
      try {
        // get schemas for selected database
        let schemaList = [];
        let getSchemasForDatabaseResult = await getSchemasForDatabase({
          DatabaseName: databaseNameAsOption?.value,
          CatalogId: databaseNameAsOption?.catalogId,
          DataSourceId: REDSHIFT_DATASOURCE_ID,
          ClusterIdentifier: databaseNameAsOption?.clusterId,
          Region: getRegion(),
        });
        schemaList.push(...getSchemasForDatabaseResult.SchemaInfoList);
        while (getSchemasForDatabaseResult.NextToken != undefined) {
          getSchemasForDatabaseResult = await getSchemasForDatabase({
            DatabaseName: databaseNameAsOption?.value,
            CatalogId: databaseNameAsOption?.catalogId,
            DataSourceId: REDSHIFT_DATASOURCE_ID,
            ClusterIdentifier: databaseNameAsOption?.clusterId,
            Region: getRegion(),
            NextToken: getSchemasForDatabaseResult.NextToken,
          });
          schemaList.push(...getSchemasForDatabaseResult.SchemaInfoList);
        }
        setSchemaListAsOptions(convertSchemaInfosToOptions(schemaList));
      } catch (err) {
        setNotification('error', err.message);
      }
    }
  };

  const convertSchemaInfosToOptions = (schemaInfos: SchemaInfoList): SelectProps.Option[] => {
    return schemaInfos.map((schemaInfo: SchemaInfo) => {
      return {
        label: schemaInfo.Schema,
        value: schemaInfo.Schema,
        region: schemaInfo.Region,
        catalogId: schemaInfo.CatalogId,
        databaseName: schemaInfo.DatabaseName,
        clusterId: schemaInfo.ClusterIdentifier,
      };
    });
  };

  const getTablesFromSchemaOrDatabase = async () => {
    try {
      let tableList = [];
      let getTablesFromDatabaseResult = await getDataSetsFromHybridCatalogDatabase({
        DatabaseName: databaseNameAsOption?.value,
        CatalogId: catalogIdAsOption?.value,
        DataSourceId:
          policyType?.value === LAKEFORMATION_FGA_POLICY_TYPE ? LAKE_FORMATION_DATASOURCE_ID : REDSHIFT_DATASOURCE_ID,
        ClusterIdentifier: schemaNameAsOption != null ? schemaNameAsOption?.clusterId : null,
        SchemaName: schemaNameAsOption != null ? schemaNameAsOption?.value : null,
        Region: getRegion(),
      });
      tableList.push(...getTablesFromDatabaseResult.DataSetList);
      while (getTablesFromDatabaseResult.NextToken != undefined) {
        getTablesFromDatabaseResult = await getDataSetsFromHybridCatalogDatabase({
          DatabaseName: databaseNameAsOption?.value,
          CatalogId: catalogIdAsOption?.value,
          DataSourceId:
            policyType.value === LAKEFORMATION_FGA_POLICY_TYPE ? LAKE_FORMATION_DATASOURCE_ID : REDSHIFT_DATASOURCE_ID,
          ClusterIdentifier: schemaNameAsOption != null ? schemaNameAsOption?.clusterId : null,
          SchemaName: schemaNameAsOption != null ? schemaNameAsOption?.value : null,
          Region: getRegion(),
          NextToken: getTablesFromDatabaseResult.NextToken,
        });
        tableList.push(...getTablesFromDatabaseResult.DataSetList);
      }
      setTableListAsOptions(convertDatasetListToOptions(tableList));
    } catch (err) {
      setNotification('error', err.message);
    }
  };

  const convertDatasetListToOptions = (datasetList: DataSetList): SelectProps.Option[] => {
    return datasetList.map((dataset: HybridCatalogDataSet) => {
      return {
        label: dataset.IdInfo.TableName,
        value: dataset.IdInfo.TableName,
        region: dataset.IdInfo.Region,
        catalogId: dataset.IdInfo.CatalogId,
        databaseName: dataset.IdInfo.DatabaseName,
        schemaName: dataset.IdInfo.SchemaName,
        clusterId: dataset.IdInfo.ClusterIdentifier,
        datasetId: dataset.Id,
      };
    });
  };

  const getLatestDatasetDetailOfDataset = async (tableOption) => {
    let dataSetId = tableOption?.datasetId;
    try {
      let getLatestDatasetDetail = await getLatestDataSetDetail({
        Filter: {
          IdList: [dataSetId],
        },
      });
      if (getLatestDatasetDetail.DataSetDetailList.length > 0) {
        const datasetDetail = getLatestDatasetDetail.DataSetDetailList[0];
        setColumnListAsOptions(convertTableColumnListToOptions(datasetDetail.Columns));
      } else {
        setNotification('error', 'No details found for selected dataset. Perform a sync to generate the details');
      }
    } catch (err) {
      setNotification('error', err.message);
    }
  };

  const convertTableColumnListToOptions = (columnList: ColumnList): SelectProps.Option[] => {
    return columnList.map((column: Column) => {
      return {
        label: column.Name,
        value: column.Name,
        type: column.Type,
        description: column.Type,
        comment: column.Comment,
      };
    });
  };

  const handleConfirm = async () => {
    if (selectedColumns) {
      let columnsAsStringList = selectedColumns.map((column) => {
        return column.value;
      });
      setButtonLoading(true);
      try {
        let rowFilterPayload = rowFilterExpression
          ? { filterExpression: rowFilterExpression, AllRowsWildcard: null }
          : { AllRowsWildcard: {} };
        let columnWildCardPayload =
          selectColumnFilterType === INCLUDE_COLUMNS_FILTER
            ? null
            : selectColumnFilterType === NO_COLUMN_FILTER
            ? {}
            : { excludedColumnNames: columnsAsStringList };
        let createFgaPolicyRequest = {
          ownerId: ownerId,
          description: description,
          scope: 'Dataset', //can be changed if needed but scoped always to dataset
          visibility: policyVisibility.value,
          tableData: {
            name: policyName,
            tableCatalogId: accountId,
            clusterId: catalogIdAsOption?.clusterId,
            databaseName: databaseNameAsOption?.value,
            schemaName: schemaNameAsOption ? schemaNameAsOption.value : null,
            tableName: tableNameAsOption.value,
            region: getRegion(),
            rowFilter: rowFilterPayload,
            columnNames: selectColumnFilterType === INCLUDE_COLUMNS_FILTER ? columnsAsStringList : null,
            columnWildcard: columnWildCardPayload,
          },
        };
        const createFgaPolicyResponse = await createFgaPolicy(createFgaPolicyRequest);
        if (createFgaPolicyResponse.failures) {
          setNotification('error', 'Failure in creating policy');
        } else {
          setPolicyVisibility(null);
          setPolicyName(undefined);
          setPolicyType(null);
          setCatalogListAsOptions(undefined);
          setDatabaseListAsOptions(undefined);
          setSchemaListAsOptions(undefined);
          setTableListAsOptions(undefined);
          setColumnListAsOptions(undefined);
          setCatalogIdAsOption(null);
          setDatabaseNameAsOption(null);
          setSchemaNameAsOption(null);
          setTableNameAsOption(null);
          setSelectColumnFilterType(NO_COLUMN_FILTER);
          setSelectedColumns([]);
          setDescription(undefined);
          setRowFilterExpression(undefined);
          setRedirect({
            pathname: Page.FINE_GRAIN_ACCESS_POLICIES,
            state: {
              status: 'success',
              message: 'Policy created successfully',
            },
          });
        }
      } catch (err) {
        setNotification('error', `Error in creating policy:${err.message}`);
      } finally {
        setButtonLoading(false);
      }
    }
  };

  const setNotification = async (header, message) => {
    if (header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}

      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>

      <Form
        header={
          <Header variant='h1' description='Please specify the details of your policy.'>
            Create fine grain access policy
          </Header>
        }
        actions={
          <SpaceBetween direction='horizontal' size='s'>
            <Button variant='link' onClick={() => setRedirect(Page.FINE_GRAIN_ACCESS_POLICIES)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              loading={buttonLoading}
              onClick={handleConfirm}
              disabled={
                !policyName ||
                !policyType ||
                !catalogIdAsOption ||
                !databaseNameAsOption ||
                !tableNameAsOption ||
                !description ||
                (policyType.value === REDSHIFT_FGA_POLICY_TYPE && !schemaNameAsOption)
              }
            >
              {'Create policy'}
            </Button>
          </SpaceBetween>
        }
      >
        <Container className='custom-screenshot-hide' header={<Header variant='h2'>Tag details</Header>}>
          <ColumnLayout>
            <SpaceBetween size='m'>
              <FormField label={<div>Owner</div>} description='Owner of the policy.'>
                <Input
                  name='ownerId'
                  placeholder='Workspace owner'
                  value={ownerId}
                  ariaRequired={true}
                  onChange={({ detail }) => setPolicyName(detail.value.trim())}
                  disabled={true}
                />
              </FormField>
              <FormField label={<div>Policy name</div>} description='Name of the fine grain policy.'>
                <Input
                  name='policyName'
                  placeholder='Enter the name for the policy'
                  value={policyName}
                  ariaRequired={true}
                  onChange={({ detail }) => setPolicyName(detail.value)}
                />
              </FormField>
              <FormField
                label={<div>Policy visibility</div>}
                description='Mark it private to disable consumers requesting it.'
              >
                <Select
                  name='policyVisibility'
                  selectedOption={policyVisibility}
                  ariaRequired={true}
                  placeholder='Choose a type'
                  onChange={({ detail }) => setPolicyVisibility(detail.selectedOption)}
                  options={fgaPolicyVisibilitySelection}
                  selectedAriaLabel='Selected'
                />
              </FormField>
              <FormField label={<div>Policy type</div>} description='Select the resource type for policy'>
                <Select
                  name='policyType'
                  selectedOption={policyType}
                  ariaRequired={true}
                  placeholder='Choose a type'
                  onChange={({ detail }) => setPolicyType(detail.selectedOption)}
                  options={fgaPolicyTypeSelection}
                  selectedAriaLabel='Selected'
                />
              </FormField>

              {policyType && (
                <FormField
                  label={
                    policyType.value === LAKEFORMATION_FGA_POLICY_TYPE ? <div>Catalog ID</div> : <div>Cluster Id</div>
                  }
                  description={
                    policyType.value === LAKEFORMATION_FGA_POLICY_TYPE
                      ? 'Glue catalog'
                      : 'Select a cluster to create policy'
                  }
                >
                  <Select
                    name='catalogId'
                    selectedOption={catalogIdAsOption}
                    onChange={({ detail }) => setCatalogIdAsOption(detail.selectedOption)}
                    options={catalogListAsOptions}
                    selectedAriaLabel='Selected'
                    empty={
                      policyType.value === LAKEFORMATION_FGA_POLICY_TYPE
                        ? 'Catalog not onboarded'
                        : 'No clusters found for this workspace'
                    }
                  />
                </FormField>
              )}
              {catalogIdAsOption && (
                <FormField label={<div>Database name</div>} description='Select a database'>
                  <Select
                    name='databaseName'
                    selectedOption={databaseNameAsOption}
                    onChange={({ detail }) => setDatabaseNameAsOption(detail.selectedOption)}
                    statusType={databaseListAsOptions ? null : 'loading'}
                    loadingText='Fetching databases'
                    empty='No databases found'
                    disabled={false}
                    options={databaseListAsOptions}
                    selectedAriaLabel='Selected'
                  />
                </FormField>
              )}

              {/*only for redshift catalogs*/}
              {catalogIdAsOption && policyType?.value === REDSHIFT_FGA_POLICY_TYPE && (
                <FormField label={<div>Schema name</div>} description='Select a schema'>
                  <Select
                    name='schemaName'
                    selectedOption={schemaNameAsOption}
                    onChange={({ detail }) => setSchemaNameAsOption(detail.selectedOption)}
                    options={schemaListAsOptions}
                    statusType={schemaListAsOptions ? null : 'loading'}
                    loadingText='Fetching schemas'
                    empty='No schemas found'
                    disabled={!databaseNameAsOption}
                    selectedAriaLabel='Selected'
                  />
                </FormField>
              )}
              {catalogIdAsOption && (
                <FormField label={<div>Table name</div>} description='Select a table'>
                  <Select
                    name='tableName'
                    selectedOption={tableNameAsOption}
                    onChange={({ detail }) => setTableNameAsOption(detail.selectedOption)}
                    options={tableListAsOptions}
                    statusType={tableListAsOptions ? null : 'loading'}
                    loadingText='Fetching tables'
                    empty='No tables found'
                    disabled={!schemaNameAsOption && !databaseNameAsOption}
                    selectedAriaLabel='Selected'
                  />
                </FormField>
              )}
              {catalogIdAsOption && (
                <FormField
                  label={<div>Column level access</div>}
                  description='Choose whether this policy should have column level permissions'
                >
                  <RadioGroup
                    onChange={({ detail }) => setSelectColumnFilterType(detail.value)}
                    value={selectColumnFilterType}
                    items={[
                      {
                        value: NO_COLUMN_FILTER,
                        label: 'All Columns',
                        description: 'Filter will not have any column restrictions',
                      },
                      {
                        value: INCLUDE_COLUMNS_FILTER,
                        label: 'Include Columns',
                        description: 'Policy only allow access to specific columns',
                      },
                      {
                        value: EXCLUDE_COLUMNS_FILTER,
                        label: 'Exclude Columns',
                        description: 'Policy will allow access to all but specific columns',
                      },
                    ]}
                  />
                </FormField>
              )}

              {selectColumnFilterType &&
                (selectColumnFilterType === INCLUDE_COLUMNS_FILTER ||
                  selectColumnFilterType === EXCLUDE_COLUMNS_FILTER) && (
                  <FormField
                    label={<div>Select columns</div>}
                    description='Select the columns on which the policy is to be applied'
                  >
                    <Multiselect
                      selectedOptions={selectedColumns}
                      onChange={({ detail }) => setSelectedColumns(detail.selectedOptions)}
                      deselectAriaLabel={(e) => `Remove ${e.label}`}
                      options={columnListAsOptions}
                      placeholder='Choose columns'
                      statusType={columnListAsOptions ? null : 'loading'}
                      loadingText='Fetching columns'
                      empty='No columns found'
                      filteringType='auto'
                      disabled={!tableNameAsOption}
                      selectedAriaLabel='Selected'
                    />
                  </FormField>
                )}
              {catalogIdAsOption && (
                <FormField label={<div>Row filter expression</div>} description='See documentation for more details.'>
                  <Textarea
                    onChange={({ detail }) => setRowFilterExpression(detail.value)}
                    value={rowFilterExpression}
                    disabled={!tableNameAsOption}
                    placeholder="column_name='some_value'"
                  />
                </FormField>
              )}

              <FormField label={<div>Description</div>} description='Enter policy description'>
                <Textarea
                  onChange={({ detail }) => setDescription(detail.value)}
                  value={description}
                  placeholder='Enter a description'
                />
              </FormField>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};

export default CreateFgaPolicy;
