import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, ButtonDropdown, Header, Link, SpaceBetween } from '@amzn/awsui-components-react-v3';
import Wizard from '@amzn/awsui-components-react-v3/polaris/wizard';
import { TitleAndContentEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/titleAndContentEditor';
import { ImpactedResourcesEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/impactedResourcesEditor';
import { AttachedTicketsEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/attachedTicketsEditor';
import { CustomersEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/customersEditor';
import { LifecycleConfigurationEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/lifecycleConfigurationEditor';
import { ContactInfoEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/contactInfoEditor';
import { ReviewAndSubmitContent } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/reviewAndSubmitContent';
import { Redirect } from 'react-router-dom';
import { Advisory } from 'aws-sdk/clients/awsdlomni';
import {
  ConfirmCancelModal,
  convertAdvisoryToTemplate,
  convertJsonToAdvisory,
  convertAdvisoryToJson,
  activeGroupOrWorkspaceId,
  compareAdvisories,
  getTemplateOptions,
} from 'src/components/dataadvisory/constants';
import { SaveAsTemplateModal } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/saveAsTemplateModal';
import { SelectAdvisoryTemplateModal } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/selectAdvisoryTemplateModal';
import { createAdvisoryTemplateDetailsLink, Page } from 'src/routes';
import {
  CreateTemplateRequest,
  CreateTemplateResult,
  GetTemplateRequest,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { createTemplate, getTemplate } from 'src/api/permissions';

export interface CreateOrUpdateAdvisoryWizardProps {
  activeGroup: string;
  activeWorkspace?: any;
  mode: string; // createadvisory | editadvisory | edittemplate
  onSubmit: any;
  advisory?: Advisory; // for editing only, this is the current advisory, readonly.
  setAdvisory?: any;
  redirectTo?: string;
  setNotifications?: any;
  loading?: boolean;
  userInfo?: any;
}

export const AdvisoryChangeMode = {
  CREATE_ADVISORY_MODE: 'createadvisory',
  UPDATE_ADVISORY_MODE: 'editadvisory',
  UPDATE_TEMPLATE_MODE: 'edittemplate',
};

export const CreateOrUpdateAdvisoryWizard = (props: CreateOrUpdateAdvisoryWizardProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [saveAsTemplateModalVisible, setSaveAsTemplateModalVisible] = useState(false);
  const [importFromTemplateModalVisible, setImportFromTemplateModalVisible] = useState(false);
  const [redirect, setRedirect] = useState(undefined);

  const [title, setTitle] = useState(props.advisory ? props.advisory.title : '');
  const [content, setContent] = useState(
    props.advisory ? props.advisory.content : { rawContent: '', format: 'PlainText' },
  );

  const [impactedResources, setImpactedResources] = useState(props.advisory ? props.advisory.impactedResources : []);
  const [resourcesErrorText, setResourcesErrorText] = useState(undefined);

  const [attachedTickets, setAttachedTickets] = useState(props.advisory ? props.advisory.attachedTickets : []);
  const [ticketErrorText, setTicketErrorText] = useState(undefined);

  const [customers, setCustomers] = useState(props.advisory ? props.advisory.customers : []);
  const [customerErrorText, setCustomerErrorText] = useState(undefined);

  const [contactInfo, setContactInfo] = useState(props.advisory ? props.advisory.contactInfo : []);
  const [contactErrorText, setContactErrorText] = useState(undefined);

  const [lifecycleConfiguration, setLifecycleConfiguration] = useState(
    props.advisory
      ? props.advisory.lifecycleConfiguration
      : { autoResolveOnTicketsResolved: false, autoResolveAfterDays: 60 },
  );
  const [lifecycleConfigurationErrorText, setLifecycleConfigurationErrorText] = useState(undefined);

  const [activateUponCreation, setActivateUponCreation] = useState(false);

  const [templateOptions, setTemplateOptions] = useState([]);

  useEffect(() => {
    if (props.advisory) {
      populateFromAdvisory(props.advisory);
    }
    handleRefresh();
  }, [props.advisory]);

  const handleRefresh = async () => {
    const mappedItems = await getTemplateOptions(props);
    setTemplateOptions(mappedItems);
  };

  const populateFromAdvisory = (advisory: Advisory) => {
    setTitle(advisory.title ?? '');
    setContent(advisory.content ?? { rawContent: '', format: 'PlainText' });
    setImpactedResources(advisory.impactedResources ?? []);
    setAttachedTickets(advisory.attachedTickets ?? []);
    setCustomers(advisory.customers ?? []);
    setContactInfo(advisory.contactInfo ?? []);
    setLifecycleConfiguration(
      advisory.lifecycleConfiguration ?? {
        autoResolveOnTicketsResolved: false,
        autoResolveAfterDays: 30,
      },
    );
  };

  const wizardTitleText = () => {
    const textValues = {
      [AdvisoryChangeMode.CREATE_ADVISORY_MODE]: 'Create a new advisory',
      [AdvisoryChangeMode.UPDATE_ADVISORY_MODE]: 'Edit advisory',
      [AdvisoryChangeMode.UPDATE_TEMPLATE_MODE]: 'Edit advisory template',
    };
    return textValues[props.mode];
  };

  const wizardSubmitText = () => {
    const textValues = {
      [AdvisoryChangeMode.CREATE_ADVISORY_MODE]: 'Create',
      [AdvisoryChangeMode.UPDATE_ADVISORY_MODE]: 'Save',
      [AdvisoryChangeMode.UPDATE_TEMPLATE_MODE]: 'Save',
    };
    return textValues[props.mode];
  };

  const isCreateMode = () => {
    return props.mode == AdvisoryChangeMode.CREATE_ADVISORY_MODE;
  };

  const createAdvisoryObjectFromState = () => {
    return {
      title: title,
      content: content,
      impactedResources: impactedResources,
      attachedTickets: attachedTickets,
      customers: customers,
      contactInfo: contactInfo,
      lifecycleConfiguration: lifecycleConfiguration,
    };
  };

  const CREATE_ADVISORY_WIZARD_STEPS = () => {
    let steps = [
      {
        title: 'Title and content',
        info: <Link variant='info'>Info</Link>,
        description:
          'Provide a title and description for the advisory, which will serve as the subject and body when the notification is sent to customers.',
        content: (
          <TitleAndContentEditor
            {...props}
            title={title}
            setTitle={setTitle}
            content={content}
            setContent={setContent}
            readonly={false}
          />
        ),
      },
      {
        title: 'Add resources',
        info: <Link variant='info'>Info</Link>,
        description: 'Provide a set of resources (tables, databases, catalogs) that this advisory applies to.',
        content: (
          <ImpactedResourcesEditor
            {...props}
            impactedResources={impactedResources}
            setImpactedResources={setImpactedResources}
            setErrorMessage={setResourcesErrorText}
            readonly={false}
          />
        ),
        errorText: resourcesErrorText,
        isOptional: true,
      },
      {
        title: 'Attach tickets',
        info: <Link variant='info'>Info</Link>,
        description:
          'Provide a set of SIM-C or SIM-T tickets to attach to this advisory. Customers will be able to see the ticket ID and status from Omni. The advisory can be configured to automatically resolve once all the attached tickets are resolved.',
        content: (
          <AttachedTicketsEditor
            {...props}
            attachedTickets={attachedTickets}
            setAttachedTickets={setAttachedTickets}
            setErrorMessage={setTicketErrorText}
            readonly={false}
          />
        ),
        isOptional: true,
        errorText: ticketErrorText,
      },
      {
        title: 'Customers and stakeholders',
        info: <Link variant='info'>Info</Link>,
        description:
          'Provide email addresses and Slack channels to subscribe for updates about this advisory. Advisory announcements will be sent to every recipient you provide here. Announcements will be in the form of email, Slack message, or Omni notification, depending on what recipients you provide. Email addresses can be automatically imported by group or resource.',
        content: (
          <CustomersEditor
            {...props}
            customers={customers}
            setCustomers={setCustomers}
            setErrorMessage={setCustomerErrorText}
            impactedResources={impactedResources}
            readonly={false}
          />
        ),
        errorText: customerErrorText,
      },
      {
        title: 'Contact information',
        info: <Link variant='info'>Info</Link>,
        description:
          'Provide ways for customers to contact your team via Slack, ticket, or email. Customers will see these options on the advisory details page.',
        content: (
          <ContactInfoEditor
            {...props}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
            setErrorMessage={setContactErrorText}
            readonly={false}
          />
        ),
        isOptional: true,
        errorText: contactErrorText,
      },
      {
        title: 'Behavior and configuration',
        info: <Link variant='info'>Info</Link>,
        description: 'Miscellaneous configuration settings for the advisory.',
        content: (
          <LifecycleConfigurationEditor
            lifecycleConfiguration={lifecycleConfiguration}
            setLifecycleConfiguration={setLifecycleConfiguration}
            setErrorMessage={setLifecycleConfigurationErrorText}
            readonly={false}
            {...props}
          />
        ),
        isOptional: true,
        errorText: lifecycleConfigurationErrorText,
      },
      {
        title: 'Review and submit',
        info: <Link variant='info'>Info</Link>,
        description: 'Review your advisory below, then ' + (isCreateMode() ? 'create.' : 'submit edits.'),
        content: (
          <ReviewAndSubmitContent
            {...props}
            title={title}
            content={content}
            impactedResources={impactedResources}
            attachedTickets={attachedTickets}
            customers={customers}
            contacts={contactInfo}
            lifecycleConfiguration={lifecycleConfiguration}
            activateUponCreation={activateUponCreation}
            setActivateUponCreation={setActivateUponCreation}
          />
        ),
      },
    ];
    // Advisory templates don't store ticket information. Remove this step when editing a template.
    if (props.mode == AdvisoryChangeMode.UPDATE_TEMPLATE_MODE) {
      steps = steps.filter((step) => step.title != 'Attach tickets');
    }
    return steps;
  };

  const CREATE_ADVISORY_WIZARD_I18N_STRINGS = {
    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
    skipToButtonLabel: (step) => `Skip to ${step.title}`,
    navigationAriaLabel: 'Steps',
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: wizardSubmitText(),
    optional: 'optional',
  };

  const thereAreUnsavedChanges = () => {
    if (isCreateMode()) {
      // when creating, "unsaved changes" mean any changes at all
      if (
        title.length > 0 ||
        content.rawContent.length > 0 ||
        impactedResources.length > 0 ||
        attachedTickets.length > 0 ||
        customers.length > 0 ||
        contactInfo.length > 0
      ) {
        return true;
      }
      return false;
    } else {
      // when editing, "unsaved changes" means current advisory != initial advisory
      return !compareAdvisories(props.advisory, createAdvisoryObjectFromState());
    }
    return true;
  };

  const handleCancel = () => {
    // redirect to the page passsed in (usually advisory details page), or list advisories page by default
    setRedirect(props.redirectTo ? props.redirectTo : Page.LIST_ADVISORIES);
  };

  const saveAsTemplate = async (title: string, description: string) => {
    console.log('Saving template for ' + title + ' and ' + description);
    const advisory = createAdvisoryObjectFromState();
    const template = convertAdvisoryToTemplate(advisory);
    const templateBody = convertAdvisoryToJson(template);
    console.log('Advisory:', templateBody);
    const request: CreateTemplateRequest = {
      name: title,
      description: description,
      ownerId: activeGroupOrWorkspaceId(props),
      templateType: 'DataAdvisory',
      templateBodyJson: templateBody,
    };
    let response: CreateTemplateResult;
    let message: string = '';
    try {
      response = await createTemplate(request);
      message = response.message;
    } catch (e) {
      console.log('Error when creating template: {}', e);
      response = undefined;
      message = e;
    }
    if (response && response.templateId) {
      props.setNotifications([
        {
          type: 'success',
          content: 'Successfully created template ' + response.templateId + '.',
          action: (
            <Button onClick={() => setRedirect(createAdvisoryTemplateDetailsLink(response.templateId))}>
              View templates
            </Button>
          ),
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => props.setNotifications([]),
          id: 'successCreate',
        },
      ]);
    } else {
      props.setNotifications([
        {
          type: 'error',
          content: 'Failed to create template: ' + message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => props.setNotifications([]),
          id: 'successCreate',
        },
      ]);
    }
    setSaveAsTemplateModalVisible(false);
    handleRefresh();
  };

  const importFromTemplate = async (templateId: string) => {
    const request: GetTemplateRequest = {
      templateId: templateId,
      resourceId: 'NONE',
    };
    const response = await getTemplate(request);
    if (!response.template) {
      props.setNotifications([
        {
          type: 'error',
          content: 'Failed to load template: ' + response.message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => props.setNotifications([]),
          id: 'successCreate',
        },
      ]);
    }
    const templateAdvisory = convertJsonToAdvisory(response.template.templateBodyJson);
    populateFromAdvisory(templateAdvisory);
    props.setNotifications([
      {
        type: 'success',
        content: 'Successfully loaded from template. Now you can make additional edits then save the advisory.',
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => props.setNotifications([]),
        id: 'successCreate',
      },
    ]);
    setImportFromTemplateModalVisible(false);
  };

  const manageTemplates = () => {
    setRedirect(Page.LIST_ADVISORY_TEMPLATES);
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <ConfirmCancelModal onYes={handleCancel} onNo={() => setCancelModalVisible(false)} visible={cancelModalVisible} />
      <SaveAsTemplateModal
        visible={saveAsTemplateModalVisible}
        onSave={saveAsTemplate}
        onClose={() => setSaveAsTemplateModalVisible(false)}
      />
      <SelectAdvisoryTemplateModal
        visible={importFromTemplateModalVisible}
        templateOptions={templateOptions}
        onSelect={importFromTemplate}
        onClose={() => setImportFromTemplateModalVisible(false)}
        activeGroup={props.activeGroup}
        activeWorkspace={props.activeWorkspace}
        confirmButtonText={'Import'}
        descriptionText={
          "Select a template to import from. All the data in the template will be written into the form, overwriting any edits you've made."
        }
      />
      <SpaceBetween size={'l'} direction={'vertical'}>
        <Header variant={'h1'}>{wizardTitleText()}</Header>
        <Wizard
          steps={CREATE_ADVISORY_WIZARD_STEPS()}
          i18nStrings={CREATE_ADVISORY_WIZARD_I18N_STRINGS}
          activeStepIndex={activeStepIndex}
          onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
          secondaryActions={
            props.mode == AdvisoryChangeMode.UPDATE_TEMPLATE_MODE ? (
              []
            ) : (
              <ButtonDropdown
                items={[
                  {
                    text: 'Import from template...',
                    id: 'import',
                    disabled: false,
                  },
                  { text: 'Save as template...', id: 'save', disabled: false },
                  { text: 'Manage templates', id: 'manage', disabled: false },
                ]}
                onItemClick={(e) => {
                  if (e.detail.id == 'import') {
                    setImportFromTemplateModalVisible(true);
                  } else if (e.detail.id == 'save') {
                    setSaveAsTemplateModalVisible(true);
                  } else if (e.detail.id == 'manage') {
                    manageTemplates();
                  }
                }}
              >
                Advisory templates...
              </ButtonDropdown>
            )
          }
          onCancel={() => {
            if (thereAreUnsavedChanges()) {
              setCancelModalVisible(true);
            } else {
              handleCancel();
            }
          }}
          onSubmit={() => props.onSubmit(createAdvisoryObjectFromState(), activateUponCreation)}
          isLoadingNextStep={props.loading != undefined ? props.loading : false}
        />
      </SpaceBetween>
    </>
  );
};
