import { Box, Input, Modal, Button, FormField, SpaceBetween, Header, Link } from '@amzn/awsui-components-react-v3';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { S3NotificationConfig } from 'aws-sdk/clients/tethyscontractservicelambda';
import { docs } from '../common/constants';

interface iPublishModal {
  visible: boolean;
  addQueue(S3NotificationConfig: S3NotificationConfig): Promise<void>;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const PublishModal = ({ addQueue, visible, setVisible }: iPublishModal) => {
  const [BucketName, setBucketName] = useState<string>();
  const [NotificationName, setNotificationName] = useState<string>();
  const [submitted, setSubmitted] = useState<boolean>();

  const submit = () => {
    setSubmitted(true);

    if (BucketName && NotificationName) addQueue({ BucketName, NotificationName });
  };

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={submit}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={
        <Header
          variant='h2'
          description={'To start publishing data, create an S3 notification and register it here.'}
          info={
            <Link external variant='info' href={docs.publisherAPI}>
              Info
            </Link>
          }
        >
          Register your S3 notification
        </Header>
      }
    >
      <SpaceBetween size='s'>
        <FormField
          label='S3 bucket name'
          description='The S3 bucket where your data is stored.'
          errorText={submitted && !BucketName && 'Bucket name is required'}
        >
          <Input value={BucketName} onChange={({ detail }) => setBucketName(detail.value)} />
        </FormField>

        <FormField
          label='Notification name'
          description={`The name you gave to the Tethys S3 notification.`}
          errorText={submitted && !NotificationName && 'Notification name is required'}
        >
          <Input value={NotificationName} onChange={({ detail }) => setNotificationName(detail.value)} />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};
