import { Box } from '@amzn/awsui-components-react-v3';
import * as React from 'react';

interface iEmptyState {
  title: string;
  subtitle: string;
  action?: any;
}

export const EmptyState = ({ title, subtitle, action }: iEmptyState) => (
  <Box textAlign='center' color='inherit'>
    <Box variant='strong' textAlign='center' color='inherit'>
      {title}
    </Box>
    <Box variant='p' padding={{ bottom: 's' }} color='inherit'>
      {subtitle}
    </Box>
    {action}
  </Box>
);
