import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Flashbar, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { PageHeader } from '../subscriptions/common';
import { getTemplate } from 'src/api/permissions';
import { TemplateDetailsComponent } from 'src/components/templates/common';
import { AssociateTemplateModal } from 'src/components/templates/associateTemplateModal';
import { TABLE_CONTENT_TYPE, TEMPLATE_NONE } from 'src/commons/constants';
import { isGlobalTemplate } from 'src/components/utils/template';

export interface TemplateDetailsProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  activeWorkspace?: any;
}

export const TemplateView = (props: TemplateDetailsProps) => {
  const [template, setTemplate] = useState({});
  const [redirect] = useState(undefined);
  const [associateModalVisible, setAssociateModalVisible] = useState(false);
  const [templateId] = useState(props.match.params.templateId);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, [props.activeGroup, props.activeWorkspace]);

  const handleRefresh = async () => {
    if (!props.activeGroup || !props.match.params.templateId || !props.match.params.resourceId) return;
    try {
      const templateResult = await getTemplate({
        templateId: props.match.params.templateId,
        resourceId: props.match.params.resourceId,
      });
      const template = templateResult.template;
      setTemplate(template);
    } catch (err) {
      setTemplate(null);
    }
  };

  const handleNotification = (type, message) => {
    setNotifications([
      {
        type: type,
        content: message,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  if (redirect) return <Redirect push to={redirect} />;

  if (!template) {
    return (
      <>
        <h2>Template not found</h2>
        The given Template ID :{props.match.params.templateId} is not valid, or you do not have permission to view it.
        Please check the URL for mistakes and try again.
      </>
    );
  }

  const templateDetailsButtons = [];
  if (template) {
    const resourceId = template['resourceId'];
    if (resourceId && resourceId === TEMPLATE_NONE && !isGlobalTemplate(template)) {
      templateDetailsButtons.push({
        text: 'Associate resource',
        variant: 'primary',
        onItemClick: () => {
          setAssociateModalVisible(true);
        },
      });
    }
  }

  return (
    <>
      <Flashbar items={notifications}></Flashbar>
      <SpaceBetween direction='vertical' size='xs'>
        <PageHeader buttons={templateDetailsButtons} header={templateId} />
        <TemplateDetailsComponent template={template} />
      </SpaceBetween>
      <AssociateTemplateModal
        templateId={templateId}
        setNotification={handleNotification}
        close={() => {
          setAssociateModalVisible(false);
        }}
        visible={associateModalVisible}
        activeGroup={props.activeGroup}
        activeWorkspace={props.activeWorkspace}
        handleRefresh={handleRefresh}
      ></AssociateTemplateModal>
    </>
  );
};
