import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
  Link,
} from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/notifications/common';
import { defaultWrapLinesPreference, i18nStrings, largePageSizePreference, paginationLabels } from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { getSchemas, listDatabases } from 'src/api/catalog';
import {
  DATA_LOAD_LIMIT,
  LAKE_FORMATION_DATASOURCE_ID,
  REDSHIFT_DATASOURCE_ID,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import {
  createGlueCatalogDetailLink,
  createGlueDatabaseDetailLink,
  createRedshiftCatalogDetailLink,
  createRedshiftDatabaseDetailLink,
} from 'src/routes';
import { typeToHumanReadableObject } from 'src/components/permissions/myDatasets/common';
import { DefaultRouteProps } from 'src/commons/common';
import { ownerLinkItem } from 'src/components/workspaces/common/common';

export interface WSBrowseDatabasesProps extends DefaultRouteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  catalogMap: any;
}

export const WSBrowseDatabases = (props: WSBrowseDatabasesProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [databaseNextToken, setDatabaseNextToken] = useState(null);

  const [schemaNextToken, setSchemaNextToken] = useState(null);
  const [allDatabasesLoaded, setAllDatabasesLoaded] = useState(false);
  const [allSchemasLoaded, setAllSchemasLoaded] = useState(false);

  const handleRefresh = async () => {
    setLoadingTable(true);
    let databases = await listDatabases({ Limit: DATA_LOAD_LIMIT, IncludeTotalCount: true });
    setDatabaseNextToken(databases.NextToken);
    let schemas = await getSchemas({ Limit: DATA_LOAD_LIMIT, IncludeTotalCount: true });
    setSchemaNextToken(schemas.NextToken);
    setTotalCount(databases.TotalCount + schemas.TotalCount);
    if (databases.NextToken == null) {
      setAllDatabasesLoaded(true);
    }
    if (databases.NextToken == null) {
      setAllSchemasLoaded(true);
    }
    let databaseList = databases.DatabaseInfoList;
    databaseList.push(...schemas.SchemaInfoList);
    for (let database of databaseList) {
      if (database.ClusterIdentifier != null) {
        let catalogName = props.catalogMap.get(
          database?.CatalogId + ':' + database?.ClusterIdentifier + ':' + database?.Region,
        );
        database['CatalogName'] = catalogName == null ? database?.CatalogId : catalogName;
      } else {
        let catalogName = props.catalogMap.get(database?.CatalogId + ':' + database?.Region);
        database['CatalogName'] = catalogName == null ? database?.CatalogId : catalogName;
      }
    }
    setItems(databaseList);
    setLoadingTable(false);
  };

  const loadMoreData = async () => {
    setLoadingTable(true);
    let databaseList = [];
    let schemaList = [];
    if (!allDatabasesLoaded) {
      let databases = await listDatabases({ NextToken: databaseNextToken, Limit: DATA_LOAD_LIMIT });
      setDatabaseNextToken(databases.NextToken);
      databaseList = databases.DatabaseInfoList;
      if (databases.NextToken == null) {
        setAllDatabasesLoaded(true);
      }
    }
    if (!allSchemasLoaded) {
      let schemas = await getSchemas({ NextToken: schemaNextToken, Limit: DATA_LOAD_LIMIT });
      setSchemaNextToken(schemas.NextToken);
      schemaList = schemas.SchemaInfoList;
      if (schemas.NextToken == null) {
        setAllSchemasLoaded(true);
      }
    }

    databaseList.push(...schemaList);
    for (let database of databaseList) {
      if (database.ClusterIdentifier != null) {
        let catalogName = props.catalogMap.get(
          database?.CatalogId + ':' + database?.ClusterIdentifier + ':' + database?.Region,
        );
        database['CatalogName'] = catalogName == null ? database?.CatalogId : catalogName;
      } else {
        let catalogName = props.catalogMap.get(database?.CatalogId + ':' + database?.Region);
        database['CatalogName'] = catalogName == null ? database?.CatalogId : catalogName;
      }
    }
    let currentItems = allItems;
    currentItems.push(...databaseList);
    setItems(currentItems);
    setLoadingTable(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const flatten = (items) => {
    let list = [];
    list.push(
      ...items.map((item) => ({
        catalogId: item?.CatalogId,
        databaseName: item?.DatabaseName,
        catalogName: item?.CatalogName,
        description: item?.Description,
        ownerId: item?.Owners != null && item.Owners.length > 0 ? item?.Owners[0] : undefined,
        region: item?.Region,
        clusterIdentifier: item?.ClusterIdentifier,
        schemaName: item?.Schema,
      })),
    );

    return list;
  };

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) =>
        item.clusterIdentifier == undefined ? (
          <Link href={createGlueDatabaseDetailLink(item.catalogId, item.databaseName, item.region)}>
            {item.databaseName}
          </Link>
        ) : (
          <Link
            href={createRedshiftDatabaseDetailLink(
              item?.catalogId,
              item?.clusterIdentifier,
              item?.databaseName,
              item?.region,
            )}
          >
            {item.databaseName}
          </Link>
        ),
      sortingField: 'databaseName',
      minWidth: '200px',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: '200px',
    },
    {
      id: 'clusterIdentifier',
      header: 'Cluster identifier',
      cell: (item) => (
        <Link href={createRedshiftCatalogDetailLink(item?.catalogId, item?.clusterIdentifier, item?.region)}>
          {item.clusterIdentifier}
        </Link>
      ),
      sortingField: 'clusterIdentifier',
      minWidth: '200px',
    },
    {
      id: 'catalogName',
      header: 'Catalog name',

      cell: (item) =>
        item.clusterIdentifier != null ? (
          <Link href={createRedshiftCatalogDetailLink(item?.catalogId, item?.clusterIdentifier, item?.region)}>
            {item.catalogName}
          </Link>
        ) : (
          <Link href={createGlueCatalogDetailLink(item?.catalogId, item?.region)}>{item.catalogName}</Link>
        ),
      sortingField: 'catalogName',
      minWidth: '200px',
    },
    {
      id: 'ownerId',
      header: 'Owner',
      cell: (item) => ownerLinkItem(item.ownerId, props.workspaceNameMap),
      sortingField: 'ownerId',
      minWidth: '200px',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) =>
        item.clusterIdentifier == undefined
          ? typeToHumanReadableObject[LAKE_FORMATION_DATASOURCE_ID]
          : typeToHumanReadableObject[REDSHIFT_DATASOURCE_ID],
      minWidth: '200px',
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(
    flatten(allItems),
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      filtering: {
        noMatch: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No matches</b>
            </div>
            <p className='awsui-util-mb-s'>We can’t find a match.</p>
          </div>
        ),
        empty: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No datasets</b>
            </div>
            <p className='awsui-util-mb-s'>No datasets were found in the catalog.</p>
          </div>
        ),
      },
      selection: {},
      propertyFiltering: {
        filteringProperties: [
          {
            propertyLabel: 'Catalog name',
            key: 'catalogName',
            groupValuesLabel: 'Catalog names',
          },
          {
            propertyLabel: 'Database name',
            key: 'databaseName',
            groupValuesLabel: 'Database names',
          },
          {
            propertyLabel: 'Catalog ID',
            key: 'catalogId',
            groupValuesLabel: 'Catalog IDs',
          },
        ],
      },
    },
  );

  const handlePageChange = async (e) => {
    if (paginationProps.currentPageIndex == paginationProps.pagesCount && (!allDatabasesLoaded || !allSchemasLoaded)) {
      await loadMoreData();
    }
    if (
      paginationProps.currentPageIndex != paginationProps.pagesCount ||
      (e.detail.currentPageIndex <= paginationProps.pagesCount &&
        e.detail.currentPageIndex != paginationProps.currentPageIndex)
    ) {
      paginationProps.onChange(e);
    }
  };

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading databases...'
        loading={loadingTable}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Databases
                  <span className='awsui-util-header-counter'>{` (${totalCount})`}</span>
                </>
              }
            />
          </>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={paginationLabels}
            onChange={(e) => handlePageChange(e)}
            openEnd={!allDatabasesLoaded || !allSchemasLoaded}
            disabled={loadingTable}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={true}
            i18nStrings={{
              ...i18nStrings,
              filteringPlaceholder: 'To discover databases, use search page instead.',
            }}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
