import { Container, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { Checkbox } from '@amzn/awsui-components-react-v3/polaris';
import { AdvisoryLifecycleConfiguration } from 'aws-sdk/clients/awsdlomni';

export interface LifecycleConfigurationEditorProps {
  lifecycleConfiguration: AdvisoryLifecycleConfiguration;
  setLifecycleConfiguration: any;
  setErrorMessage: any;
  readonly: boolean;
}

export const LifecycleConfigurationEditor = (props: LifecycleConfigurationEditorProps) => {
  const cloneLifecycleConfigurationFromProps = () => {
    let newLifecycleConfiguration: AdvisoryLifecycleConfiguration = {
      autoResolveAfterDays: props.lifecycleConfiguration.autoResolveAfterDays,
      autoResolveOnTicketsResolved: props.lifecycleConfiguration.autoResolveOnTicketsResolved,
    };
    return newLifecycleConfiguration;
  };

  const convertInputToInt = (val: string) => {
    const num = Math.abs(parseInt(val));
    if (isNaN(num)) {
      return 0;
    }
    return num;
  };

  const convertIntToInputStr = (num: number) => {
    if (num == 0) {
      return '';
    }
    return '' + num;
  };

  return (
    <SpaceBetween size={'l'} direction={'vertical'}>
      <Container header={<Header>Lifecycle configuration</Header>}>
        <SpaceBetween size={'m'}>
          <Checkbox
            checked={props.lifecycleConfiguration.autoResolveOnTicketsResolved}
            onChange={(event) => {
              let newLifecycleConfiguration = cloneLifecycleConfigurationFromProps();
              newLifecycleConfiguration.autoResolveOnTicketsResolved = event.detail.checked;
              props.setLifecycleConfiguration(newLifecycleConfiguration);
            }}
            disabled={props.readonly}
          >
            Auto-resolve this advisory when all tickets are resolved
          </Checkbox>
          <FormField
            label={'Auto-resolve after days:'}
            description={props.readonly ? undefined : 'Set a maximum lifespan for this advisory.'}
          >
            <Input
              value={convertIntToInputStr(props.lifecycleConfiguration.autoResolveAfterDays)}
              disabled={props.readonly}
              inputMode={'decimal'}
              onChange={(e) => {
                let newLifecycleConfiguration = cloneLifecycleConfigurationFromProps();
                newLifecycleConfiguration.autoResolveAfterDays = convertInputToInt(e.detail.value);
                props.setLifecycleConfiguration(newLifecycleConfiguration);
              }}
            />
          </FormField>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
