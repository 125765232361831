import {
  Link,
  Input,
  Header,
  Select,
  Container,
  FormField,
  Multiselect,
  SpaceBetween,
  TagEditor,
  Box,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { useState } from 'react';
import { iStep } from '../utils/types';
import { capitalize } from '../utils/content';
import { docs } from '../../common/constants';
import { DedupKeys } from '../../common/primaryKeys';
import { testStrings } from '../../common/testStrings';
import { isSourceS3, validateStepFour, getSchemaColumnNames } from '../utils/validation';

export const orderValues = ['DESC', 'ASC'];
export const IngestionStep = ({ request, setRequest, isValidated, isUpdate }: iStep) => {
  const [columns, setColumns] = useState<string[]>([]);
  React.useEffect(() => {
    try {
      const columns = getSchemaColumnNames(request);
      setColumns(columns);
    } catch (e) {
      console.log('Schema not defined.');
    }
  }, []);

  // TODO: Define new props.
  return (
    <SpaceBetween direction='vertical' size='xl'>
      {isSourceS3(request) && (
        <Container header={<Header variant='h2'>Service level agreement (SLA)</Header>}>
          <SpaceBetween direction='vertical' size='m'>
            <FormField
              label={
                <>
                  IAM Role{' '}
                  <Link variant='info' href={docs.iamConfig} external>
                    Docs
                  </Link>
                </>
              }
              description={<>Grant Tethys read access permissions to the S3 bucket(s) where your data is stored.</>}
              errorText={
                isValidated && !validateStepFour(request).IAMRole
                  ? !request.DataContract.ServiceLevelAgreement.IAMRole
                    ? 'IAM Role is required.'
                    : 'IAM Role is not valid.'
                  : ''
              }
            >
              <Input
                value={request.DataContract.ServiceLevelAgreement.IAMRole}
                data-testid={testStrings.contractRegistration.step4.iamRole}
                onChange={({ detail }) =>
                  setRequest({
                    ...request,
                    DataContract: {
                      ...request.DataContract,
                      ServiceLevelAgreement: {
                        ...request.DataContract.ServiceLevelAgreement,
                        IAMRole: detail.value,
                      },
                    },
                  })
                }
              />
            </FormField>

            <FormField
              label='Publish Type'
              description='Is your data ingested incrementally or fully replaced after each ingestion event?'
              errorText={isValidated && !validateStepFour(request).PublishType ? 'Publish type is required.' : ''}
            >
              <Select
                selectedAriaLabel='Selected'
                data-testid={testStrings.contractRegistration.step4.publishtype}
                options={[
                  { label: 'Incremental', value: 'INCREMENTAL' },
                  { label: 'Overwrite', value: 'OVERWRITE' },
                ]}
                selectedOption={{
                  label: capitalize(request.DataContract.ServiceLevelAgreement.PublishType),
                  value: request.DataContract.ServiceLevelAgreement.PublishType,
                }}
                onChange={({ detail }) =>
                  setRequest({
                    ...request,
                    DataContract: {
                      ...request.DataContract,
                      ServiceLevelAgreement: {
                        ...request.DataContract.ServiceLevelAgreement,
                        PublishType: detail.selectedOption.value,
                      },
                    },
                  })
                }
              />
            </FormField>

            <FormField
              label={
                <span>
                  Refresh cadence <i>- optional</i>
                </span>
              }
              description='How often is your data published?'
            >
              <Select
                options={[
                  { label: 'Hourly', value: 'HOURLY' },
                  { label: 'Daily', value: 'DAILY' },
                ]}
                selectedOption={{
                  label: capitalize(request.DataContract.ServiceLevelAgreement.RefreshCadence),
                  value: request.DataContract.ServiceLevelAgreement.RefreshCadence,
                }}
                selectedAriaLabel='Selected'
                onChange={({ detail }) =>
                  setRequest({
                    ...request,
                    DataContract: {
                      ...request.DataContract,
                      ServiceLevelAgreement: {
                        ...request.DataContract.ServiceLevelAgreement,
                        RefreshCadence: detail.selectedOption.value,
                      },
                    },
                  })
                }
              />
            </FormField>
          </SpaceBetween>
        </Container>
      )}

      <Container
        header={
          <Header variant='h2' description={'Define the logic to update or delete duplicated records.'}>
            Data Deduplication
          </Header>
        }
      >
        <SpaceBetween direction='vertical' size='l'>
          <FormField
            label='Primary key'
            data-testid={testStrings.contractRegistration.step4.primaryKey}
            description='The attribute(s) that will be used to uniquely identify each record.'
            errorText={
              isValidated && !validateStepFour(request).PrimaryKeyColumns ? 'At least one primary key is required.' : ''
            }
          >
            <Multiselect
              disabled={isUpdate}
              options={columns.map((column) => ({
                label: column,
                value: column,
              }))}
              placeholder='Choose option'
              selectedAriaLabel='Selected'
              selectedOptions={request.DataContract.DataProperties.PrimaryKeyColumns.map((column) => ({
                label: column,
                value: column,
              }))}
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataContract: {
                    ...request.DataContract,
                    DataProperties: {
                      ...request.DataContract.DataProperties,
                      PrimaryKeyColumns: detail.selectedOptions.map(({ value }) => value),
                    },
                  },
                })
              }
            />
          </FormField>

          {isUpdate ? (
            <SpaceBetween direction='vertical' size='xxxs'>
              <Box variant='awsui-key-label'>Ordering Key (dedup)</Box>
              <DedupKeys keys={request.DataContract.DataProperties.DeDupKeyColumns} />
            </SpaceBetween>
          ) : (
            <SpaceBetween direction='vertical' size='xxxs'>
              <FormField
                label={
                  <span>
                    Dedup column <i>- optional</i>
                  </span>
                }
                description='Deduplication will be performed based on the primary key, and the dedup key ordering.'
              />
              <TagEditor
                i18nStrings={tagsStrings}
                allowedCharacterPattern={[...columns, ...orderValues].reduce((d, i, idx) =>
                  idx === 0 ? i : `${d}|${i}`,
                )} // Creates a RegEx allowlisting column names & ordering values (e.g. ['Col1', 'Col2', ..., 'ASC', 'DESC'])
                tags={request.DataContract.DataProperties.DeDupKeyColumns?.map(({ ColumnName, SortOrder }) => ({
                  key: ColumnName,
                  value: SortOrder,
                  existing: false,
                }))}
                onChange={({ detail: { tags } }) =>
                  setRequest({
                    ...request,
                    DataContract: {
                      ...request.DataContract,
                      DataProperties: {
                        ...request.DataContract.DataProperties,
                        DeDupKeyColumns: tags.map(({ key, value }) => ({
                          ColumnName: key,
                          SortOrder: value || 'DESC',
                        })),
                      },
                    },
                  })
                }
                keysRequest={() => Promise.resolve(columns.map((column) => column))}
                valuesRequest={() => Promise.resolve(orderValues)}
              />
            </SpaceBetween>
          )}
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};

const tagsStrings = {
  keyPlaceholder: 'Enter column',
  valuePlaceholder: 'Enter ordering',
  addButton: 'Add dedup column',
  removeButton: 'Remove',
  undoButton: '',
  undoPrompt: '',
  loading: '',
  keyHeader: 'Column',
  valueHeader: 'Order',
  optional: '(usually descending)',
  keySuggestion: 'Custom tag key',
  valueSuggestion: 'Custom tag value',
  emptyTags: '',
  tooManyKeysSuggestion: 'You have more keys than can be displayed',
  tooManyValuesSuggestion: 'You have more values than can be displayed',
  keysSuggestionLoading: '',
  keysSuggestionError: '',
  valuesSuggestionLoading: '',
  valuesSuggestionError: '',
  emptyKeyError: 'You must specify a column key',
  maxKeyCharLengthError: 'The maximum number of characters in a column is 128.',
  maxValueCharLengthError: 'The maximum number of characters in a column is 256.',
  duplicateKeyError: 'You must specify unique columns.',
  invalidKeyError: 'Only column names are allowed values.',
  invalidValueError: 'Only "ASC" & "DESC" are allowed values',
  awsPrefixError: '',
  tagLimit: () => '',
  tagLimitReached: () => '',
  tagLimitExceeded: () => '',
  enteredKeyLabel: () => 'Use one of the column names of your data schema.',
  enteredValueLabel: () => 'Use one of the column names of your data schema.',
};
