import { Link } from 'react-router-dom';
import * as React from 'react';
import { Box, Header, Table } from '@amzn/awsui-components-react-v3';

export interface TemplatesForResourceDetailsTableProps {
  items: any[];
  userOwnsResource: boolean;
}

export const TemplatesForResourceDetailsTable = (props: TemplatesForResourceDetailsTableProps) => {
  return (
    <Table
      loadingText='Loading templates'
      columnDefinitions={templateColumnDefinitions(props.userOwnsResource)}
      items={props.items}
      resizableColumns
      header={<Header counter={`(${props.items?.length})`}>Templates</Header>}
      empty={
        <Box textAlign='center' variant='p'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No Templates</b>
          </div>
          <p className='awsui-util-mb-s'>No templates found for the resource.</p>
        </Box>
      }
    />
  );
};

const templateColumnDefinitions = (userOwnsResource: boolean) => [
  {
    id: 'templateName',
    header: 'Name',
    cell: (item) =>
      userOwnsResource ? (
        <Link to={`/templates/${item.templateId}/${item.resourceId}`}>{item.name.split()}</Link>
      ) : (
        item.name.split()
      ),
    minWidth: 200,
    sortingField: 'templateName',
  },
  {
    id: 'description',
    header: 'Description',
    cell: (item) => item.description,
    minWidth: 200,
  },
  {
    id: 'ownerId',
    header: 'Owner',
    cell: (item) => item.ownerId,
    minWidth: 200,
  },
  {
    id: 'simTemplateLink',
    header: 'SIM template link',
    cell: (item) =>
      item.accessManagementInfo?.simTemplates?.[0]?.simTemplateLink ? (
        <a href={item.accessManagementInfo?.simTemplates?.[0]?.simTemplateLink} target='_blank'>
          {'Sim template link'}
        </a>
      ) : (
        'None'
      ),
    minWidth: 200,
  },
  {
    id: 'approvalTemplateLink',
    header: 'Approval template link',
    cell: (item) =>
      item.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink ? (
        <a href={item.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink} target='_blank'>
          {'Approval template link'}
        </a>
      ) : (
        'None'
      ),
    minWidth: 200,
  },
];
