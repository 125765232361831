import { RegisterDataSetRequest } from 'aws-sdk/clients/tethyscontractservicelambda';
import { TethysLocalStorage } from '../../common/keys';
// import { getStage } from "src/api/config";

export const region = 'us-east-1';
export const defaultTethysRMGroup = 'Default_Tethys_RM_Group';
export const initialRegisterDataSetRequest: RegisterDataSetRequest = {
  TableName: null, // Step 1
  DataSetName: null, // Step 1
  DatabaseName: null, // Step 1
  CatalogId: null, // Step 1
  //ResourceGroupName: "Default_Tethys_RM_Group", //Step 1
  DataSource: {
    SourceType: '',
  },
  DataContract: {
    DataFormat: '', // Omited
    FileProperties: {
      FileType: '',
      HeaderLines: 0, // Step 2-A
      FieldDelimiter: null, // Step 2-A # ',' | ';' | '|'
    },
    DataProperties: {
      CompressionFormat: null, // Step 2-A # 'GZIP' // Missing on docs.
      SchemaFormat: 'AVRO', // Ommited
      // Step 3. If there is a schema draft, use it.
      SchemaDefinition:
        localStorage.getItem(TethysLocalStorage) ||
        `{
    "type": "record",
    "namespace": "",
    "name": "",
    "fields": [
    ]
}`,
      TargetSchema: [],
      PrimaryKeyColumns: [], // Step 4
      DeDupKeyColumn: null, // Step 4
      DeDupKeyColumns: [], // Step 4
    },
    ServiceLevelAgreement: {
      IAMRole: null, // Step 2-B
      PublishType: 'INCREMENTAL', // Step 2-B # 'INCREMENTAL' | 'OVERWRITE'
    },
  },
  Region: region, // This should be defaulted on the back-end.
  CreatedBy: '', // This should be fetched from Gladstone.
  PrimaryOwner: '', // This should be fetched from Gladstone. Docs need update.
  //  SkipNotification: getStage() === 'local' ? true : false
  RegistrationSource: 'OMNI_WIZARD',
};
