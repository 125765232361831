import * as React from 'react';
import { useState, useEffect } from 'react';

import { mediumPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  FlashbarProps,
  Flashbar,
  Modal,
  Box,
  SpaceBetween,
  Button,
} from '@amzn/awsui-components-react-v3';

import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

import { deleteSubscription, listSubscriptions } from '../../../api/catalog';
import { Link, Redirect } from 'react-router-dom';
import { RMPageHeader } from '../../resourcesmanager/components';

import { Page } from '../../../routes/Paths';
import { EmptyState } from 'src/commons/EmptyState';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { SubscriptionInfo } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { StatusIcon } from 'src/components/permissions/myDatasets/statusIcon';

export interface EventSubscriptionsProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace: object;
}

export const EventSubscriptions = (props: EventSubscriptionsProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingEventSubscriptions, setLoadingEventSubscriptions] = useState(false);
  const [redirectParams] = useState(null);
  const [redirect, setRedirect] = useState(undefined);
  const [tableMessage] = useState('No event subscriptions');
  const [unsubscribeModalVisible, setUnsubscribeModalVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isAction, setIsAction] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const columnDefinitions: TableProps.ColumnDefinition<SubscriptionInfo>[] = [
    {
      id: 'eventSubscriptionId',
      header: 'Event subscription ID',
      cell: (item) => <Link to={`eventsubscriptions/${item.Id}`}>{item.Id}</Link>,
      minWidth: 200,
    },
    {
      id: 'eventSubscriptionResourceId',
      header: 'Resource ID',
      cell: (item) => item.ResourceId,
      minWidth: 225,
      sortingField: 'ResourceId',
    },
    {
      id: 'eventSubscriptionStatus',
      header: 'Status',
      cell: (item) => <StatusIcon status={item.Status} />,
      minWidth: 75,
      sortingField: 'Status',
    },
    {
      id: 'eventSubscriptionSubscriberId',
      header: 'Subscriber ID',
      cell: (item) => item.SubscriberId,
      minWidth: 75,
      sortingField: 'SubscriberId',
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup, props.activeWorkspace]);

  /**
   * Asynchronously fetches and stores the EventSubscriptions of the active group.
   */
  const handleRefresh = async () => {
    if (!props.activeGroup || !props.activeWorkspace) return;
    handleListSubscriptions(props.activeWorkspace);
  };

  const handleListSubscriptions = async (activeWorkspace) => {
    setLoadingEventSubscriptions(true);
    try {
      const response = await listSubscriptions({
        OwnerId: activeWorkspace.workspaceId,
      });
      setItems(response.Subscriptions);
    } catch (err) {
      setNotifications([
        {
          type: 'error' as FlashbarProps.Type,
          content: `Failed to load event subscriptions.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    setLoadingEventSubscriptions(false);
  };

  const handleDeleteSubscription = async () => {
    try {
      setIsAction(true);
      await deleteSubscription({ Id: selectedItem.Id });
    } catch (err) {
      setIsAction(false);
      setUnsubscribeModalVisible(false);
      setNotifications([
        {
          type: 'error' as FlashbarProps.Type,
          content: `Failed to delete subscription.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      console.error(err);
    }
    setIsAction(false);
    setUnsubscribeModalVisible(false);
    setSelectedItem(undefined);
    await handleRefresh();
    setNotifications([
      {
        type: 'success' as FlashbarProps.Type,
        content: `Successfully deleted subscription.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const handleAction = async (e) => {
    switch (e.detail.id) {
      case 'details':
        setRedirect(Page.EVENT_SUBSCRIPTIONS_DETAILS.replace(':id', collectionProps.selectedItems[0].Id));
        break;
      case 'unsubscribe':
        setSelectedItem(collectionProps.selectedItems[0]);
        setUnsubscribeModalVisible(true);
        break;
    }
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {},
    pagination: { pageSize: 25 },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'ResourceId',
        },
      },
    },
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  if (redirect) {
    return <Redirect push to={{ pathname: redirect, state: redirectParams }} />;
  }

  return (
    <>
      <Flashbar items={notifications} />

      <Modal
        visible={unsubscribeModalVisible}
        header={[`Unsubscribe?`]}
        onDismiss={() => {
          setUnsubscribeModalVisible(false);
          setIsAction(false);
        }}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='link'
                onClick={() => {
                  setUnsubscribeModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button variant='primary' loading={isAction} onClick={handleDeleteSubscription}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <div>
          Are you sure you want to unsubscribe from events for the resource: <strong>{selectedItem?.ResourceId}</strong>{' '}
          for <strong>{selectedItem?.SubscriberId}</strong> account?
          <br />
          <br />
          <strong>
            This account will no longer receive events for the resource, which can cause issues with any dependencies on
            these events.
          </strong>
        </div>
      </Modal>

      <Table
        {...collectionProps}
        loadingText='Loading event subscriptions...'
        loading={loadingEventSubscriptions}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <RMPageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
                {
                  text: 'Actions',
                  onItemClick: handleAction,
                  items: [
                    {
                      text: 'View details',
                      id: 'details',
                      disabled: !collectionProps.selectedItems.length,
                    },
                    {
                      text: 'Unsubscribe',
                      id: 'unsubscribe',
                      disabled:
                        !collectionProps.selectedItems.length ||
                        collectionProps.selectedItems.filter((item) => item.Status !== 'Active').length,
                    },
                  ],
                  loading: loadingEventSubscriptions,
                },
              ]}
              subheader={
                <>
                  Event subscriptions
                  <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                </>
              }
            />
          </>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Find event subscriptions'
            filteringPlaceholder='Find event subscriptions'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        empty={<EmptyState title={tableMessage} subtitle='No event subscriptions to display.' />}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        selectionType='single'
      />
    </>
  );
};
