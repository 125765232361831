import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  activeGroupOrWorkspaceId,
  ADVISORY_STATUS_ACTIVE,
  convertJsonToAdvisory,
} from 'src/components/dataadvisory/constants';
import {
  AdvisoryChangeMode,
  CreateOrUpdateAdvisoryWizard,
} from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/createOrUpdateAdvisoryWizard';
import { CreateAdvisoryRequest } from 'aws-sdk/clients/awsdlomni';
import { createAdvisoryDetailsLink, Page } from 'src/routes';
import { createAdvisory, getAdvisory, updateAdvisory } from 'src/api/notifications';
import { Button, Flashbar, Spinner } from '@amzn/awsui-components-react-v3';
import { getTemplate } from 'src/api/permissions';
import { Redirect } from 'react-router';
import { GLADSTONE_UNAUTHORIZED_MESSAGE } from 'src/components/utils/exceptions';

export interface CreateAdvisoryPageProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
  userInfo?: any;
}

export const CreateAdvisoryPage = (props: CreateAdvisoryPageProps) => {
  const [advisory, setAdvisory] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [redirect, setRedirect] = useState(undefined);

  useEffect(() => {
    props.setContentType('wizard');
    handleRefresh();
  }, []);

  const templateId = () => {
    return props.match.params.templateid;
  };

  const advisoryId = () => {
    return props.match.params.id;
  };

  const handleRefresh = async () => {
    setLoading(true);
    try {
      if (templateId()) {
        // if creating from a template
        setAdvisory(await fetchAdvisoryTemplate());
      }
    } catch (e) {
      setNotificationForFailedToLoadTemplate(e);
    }
    try {
      if (advisoryId()) {
        // if cloning from an advisory
        setAdvisory(await fetchAdvisory());
      }
    } catch (e) {
      setNotificationForFailedToLoadAdvisory(e);
    }
    setLoading(false);
  };

  const setNotificationForFailedToLoadTemplate = (e) => {
    setNotifications([
      {
        type: 'error',
        content: 'Failed to load from template: ' + e.message,
      },
    ]);
  };

  const setNotificationForFailedToLoadAdvisory = (e) => {
    setNotifications([
      {
        type: 'error',
        content: 'Failed to load from advisory: ' + e.message,
      },
    ]);
  };

  const fetchAdvisoryTemplate = async () => {
    const response = await getTemplate({
      templateId: templateId(),
      resourceId: 'NONE',
    });
    const templateBody = response.template.templateBodyJson;
    const advisoryTemplate = convertJsonToAdvisory(templateBody);
    return advisoryTemplate;
  };

  const fetchAdvisory = async () => {
    const response = await getAdvisory({
      advisoryId: advisoryId(),
    });
    return response.advisory;
  };

  const create = async (advisory, activateUponCreation) => {
    setCreating(true);
    let advisoryId;
    try {
      const createAdvisoryRequest: CreateAdvisoryRequest = {
        ownerId: activeGroupOrWorkspaceId(props),
        title: advisory.title,
        content: advisory.content,
        impactedResources: advisory.impactedResources,
        attachedTickets: advisory.attachedTickets,
        customers: advisory.customers,
        contactInfo: advisory.contactInfo,
        lifecycleConfiguration: advisory.lifecycleConfiguration,
      };
      const response = await createAdvisory(createAdvisoryRequest);
      advisoryId = response.advisory.advisoryId;
      setNotificationForSuccessfulCreate(advisoryId);
    } catch (e) {
      setNotificationForFailedCreate(e);
    }
    if (activateUponCreation) {
      try {
        await updateAdvisory({
          advisoryId: advisoryId,
          status: ADVISORY_STATUS_ACTIVE,
        });
      } catch (e) {
        console.log('Failed to activate the advisory upon creation: ', e);
      }
    }
    setCreating(false);
  };

  const setNotificationForSuccessfulCreate = (advisoryId: string) => {
    setNotifications([
      {
        type: 'success',
        content: 'Successfully created the advisory.',
        action: <Button onClick={() => redirectToViewAdvisory(advisoryId)}>View</Button>,
      },
    ]);
  };

  const redirectToViewAdvisory = (advisoryId: string) => {
    setRedirect(createAdvisoryDetailsLink(advisoryId));
  };

  const setNotificationForFailedCreate = (e) => {
    let message = `Failed to create the advisory: ${e.message}`;
    let action = undefined;
    // If it's an InsufficientPermissionException, include an additional message for how to edit your permissions.
    if (e.message?.includes(GLADSTONE_UNAUTHORIZED_MESSAGE)) {
      message =
        'Failed to create the advisory because of insufficient permissions. You can edit your advisory permissions by adding yourself to the DataProducer roles in the group details page.';
      action = (
        <Button href={Page.GROUP} target={'_blank'}>
          Manage permissions
        </Button>
      );
    }
    setNotifications([
      {
        type: 'error',
        content: message,
        action: action,
      },
    ]);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <Flashbar items={notifications} />
      <CreateOrUpdateAdvisoryWizard
        {...props}
        mode={AdvisoryChangeMode.CREATE_ADVISORY_MODE}
        onSubmit={create}
        redirectTo={Page.LIST_ADVISORIES}
        advisory={advisory}
        setAdvisory={setAdvisory}
        setNotifications={setNotifications}
        loading={creating}
      />
    </>
  );
};
