import { Flashbar, FlashbarProps, Link } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { Advisory } from 'aws-sdk/clients/awsdlomni';
import { createAdvisoryDetailsLink } from 'src/routes';
import { LocalStorageItem, LocalStorageItemKey, StorageService } from 'src/components/utils/storage-service';
import { EXP_1_WEEK_MINS } from 'src/commons/constants';

export interface ActiveAdvisoriesHeaderProps {
  // passed in from App component from a listAdvisories call, these will be filtered by which advisories the user has already dismissed
  activeAdvisories: Advisory[];
}

// retrieve list of dismissed advisory ID's from browser storage
export const getDismissedAdvisories = async () => {
  const storageInstance = StorageService.getInstance();
  const currentValue = await storageInstance.getItem(LocalStorageItemKey.DismissedAdvisories);
  if (!currentValue) {
    await storageInstance.setItem(LocalStorageItemKey.DismissedAdvisories, new LocalStorageItem('[]', EXP_1_WEEK_MINS));
  }
  const dismissedAdvisoriesString = await storageInstance.getItem(LocalStorageItemKey.DismissedAdvisories);
  let dismissedAdvisories: string[] = JSON.parse(dismissedAdvisoriesString.data as string);
  return dismissedAdvisories;
};

// add an advisory ID to browser storage and dispatch an event (App.tsx listens to this event)
export const addDismissedAdvisory = async (advisoryId: string) => {
  const storageInstance = StorageService.getInstance();
  let dismissedAdvisories = await getDismissedAdvisories();
  if (!dismissedAdvisories.includes(advisoryId)) {
    dismissedAdvisories.push(advisoryId);
  }
  await storageInstance.setItem(
    LocalStorageItemKey.DismissedAdvisories,
    new LocalStorageItem(JSON.stringify(dismissedAdvisories), EXP_1_WEEK_MINS),
  );
  window.dispatchEvent(new StorageEvent('storage'));
};

export const ActiveAdvisoriesHeader = (props: ActiveAdvisoriesHeaderProps) => {
  const getAdvisoriesToDisplay = async () => {
    const dismissedAdvisories = await getDismissedAdvisories();

    setAdvisoriesToDisplay(
      props.activeAdvisories.filter((advisory) => !dismissedAdvisories.includes(advisory.advisoryId)),
    );
  };

  const [advisoriesToDisplay, setAdvisoriesToDisplay] = useState([]);

  // add the advisory ID to the dismissed advisories in browser storage, and set state accordingly
  const handleDismiss = async (id: string) => {
    await addDismissedAdvisory(id);
    await getAdvisoriesToDisplay();
  };

  // re-render when there is a change in active advisories in props.
  useEffect(() => {
    getAdvisoriesToDisplay();
  }, [props.activeAdvisories]);

  const items: FlashbarProps.MessageDefinition[] = advisoriesToDisplay.map((advisory: Advisory) => ({
    type: 'warning',
    content: (
      <>
        There is an active advisory for data you consume:{' '}
        <Link href={createAdvisoryDetailsLink(advisory.advisoryId)}>{advisory.title}</Link>{' '}
      </>
    ),
    dismissible: true,
    onDismiss: () => handleDismiss(advisory.advisoryId),
  }));

  if (advisoriesToDisplay.length == 0) {
    return <></>;
  }
  return <Flashbar items={items} />;
};
