import { SpaceBetween, Modal, Button, Header } from '@amzn/awsui-components-react-v3';
import React from 'react';
export interface ConfirmDeleteTemplateModalProps {
  visible: boolean;
  onDelete: any;
  onCancel: any;
  templateName: string;
}

export const ConfirmDeleteTemplateModal = (props: ConfirmDeleteTemplateModalProps) => {
  return (
    <Modal
      visible={props.visible}
      header={'Confirm delete'}
      onDismiss={props.onCancel}
      footer={
        <Header
          actions={
            <SpaceBetween size={'s'} direction={'horizontal'} alignItems={'end'}>
              <Button variant={'link'} onClick={props.onCancel}>
                Cancel
              </Button>
              <Button variant={'primary'} onClick={props.onDelete}>
                Delete
              </Button>
            </SpaceBetween>
          }
        />
      }
    >
      Are you sure you want to delete the template <b>{props.templateName}</b>? This cannot be undone.
    </Modal>
  );
};
