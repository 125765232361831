import { InputProps } from '@amzn/awsui-components-react-v3';
import * as _ from 'lodash';

export const accountIdRegex = /^[0-9]{12}$/;
export const roleRegex = /^arn:(aws|aws-cn|aws-iso(-b)?):iam::\d{12}:(role|user)\/[\w+=,.@\-/]+$/;

/** A validation function return the error message or null */
type ValidationFunction = (field: Field, fields?: Fields) => string | undefined;

/** A Form field whose default type is string but can be specified */
export interface Field<T = string> {
  value?: T;
  validation: ValidationFunction[];
  isOptional?: boolean;
  errorText?: string;
}

/** A map of fields */
export interface Fields {
  [key: string]: Field<any>;
}

/* Start exported validation functions at the field and form levels */
export const validateAndSaveInput = <MyFields extends Fields>(
  event: CustomEvent<InputProps.ChangeDetail>,
  fields: MyFields,
  fieldName,
): MyFields => {
  const { value } = event.detail;
  return updateField(fields, fieldName, value);
};

const externalPrincipalField: ValidationFunction = (field, fields = {}) => {
  const accountIdsField: Field | undefined = _.get(fields, 'accountIds');
  if (accountIdsField && field) {
    if (_.isEmpty(accountIdsField.value) && _.isEmpty(field.value)) {
      return 'Required field';
    }
    accountIdsField.errorText = undefined;
  }
  if (field.value && !accountIdRegex.test(field.value)) {
    return 'Invalid AWS account';
  }
  return undefined;
};

const awsRoleField: ValidationFunction = (field, fields = {}) => {
  const accountIdsField: Field | undefined = _.get(fields, 'accountIds');
  if (accountIdsField && field) {
    if (_.isEmpty(accountIdsField.value) && _.isEmpty(field.value)) {
      return 'Required field';
    }
    accountIdsField.errorText = undefined;
  }
  if (field.value && !roleRegex.test(field.value)) {
    return 'Invalid AWS Role';
  }
  return undefined;
};

export const validation = {
  awsRoleField,
  externalPrincipalField,
};

/**
 * Update a field with fieldName in the fields, also does validation
 * If no field with fieldName exists, return the same fields
 */
export const updateField = <MyFields extends Fields>(
  fields: MyFields,
  fieldName: keyof MyFields,
  value: any,
): MyFields => {
  const field = fields[fieldName];
  if (!field) return fields;

  field.validation = field.isOptional ? field.validation : _.uniq([...field.validation, validation['requiredField']]);
  field.value = value;
  field.errorText = validateField(field, fields);

  return fields;
};

/** Validate a field by going through all of its validation functions */
const validateField = (field: Field, fields: Fields): string | undefined => {
  let errorText;
  field.validation = field.isOptional ? field.validation : _.uniq([...field.validation, validation['requiredField']]);

  field.validation.some((validate: ValidationFunction) => {
    errorText = validate(field, fields);
    return !_.isEmpty(errorText); // this return is to terminate .some loop
  });
  return errorText;
};

/** Directly update a field without validation */
export const updateFieldNoValidation = <MyFields extends Fields>(
  fields: MyFields,
  fieldName: keyof MyFields,
  value: any,
): MyFields => {
  const field = fields[fieldName];
  if (!field) return fields;

  field.value = value;
  field.errorText = '';

  return fields;
};

export const validateForm = <MyFields extends Fields>(fields: MyFields): MyFields => {
  _.each(fields, (field, fieldName) => {
    field.errorText = validateField(fields[fieldName], fields);
  });
  return fields;
};
