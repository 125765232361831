import { Alert, Box, Button, Header, Input, Link, SpaceBetween, Table } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import {
  COMMENT_TOO_LONG_ERROR,
  EMAIL,
  SLACK,
  OMNI,
  validateEmail,
  validateSlack,
  ADVISORIES_WIKI_PAGE,
  CC_EMAIL,
  linkForGroupOrWorkspace,
} from 'src/components/dataadvisory/constants';
import { AdvisoryCustomer, AdvisoryCustomerList, AdvisoryImpactedResourceList } from 'aws-sdk/clients/awsdlomni';
import { AddCustomerModal } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/addCustomerModal';
import { DefaultRouteProps } from 'src/commons/common';

export interface CustomersEditorProps extends DefaultRouteProps {
  customers: AdvisoryCustomerList;
  setCustomers?: any;
  impactedResources: AdvisoryImpactedResourceList;
  setErrorMessage?: any;
  readonly: boolean;
  activeGroup: string;
  activeWorkspace?: any;
  userInfo?: any;
}

export const CustomersEditor = (props: CustomersEditorProps) => {
  const [currentEditValue, setCurrentEditValue] = useState(undefined);
  const [importModalVisible, setImportModalVisible] = useState(false);

  const valueForCustomer = (customer: AdvisoryCustomer) => {
    if (customer.type == EMAIL) {
      return customer.emailAddress;
    } else if (customer.type == CC_EMAIL) {
      return customer.emailAddress;
    } else if (customer.type == SLACK) {
      return customer.slackChannel;
    } else if (customer.type == OMNI) {
      return customer.groupOrWorkspaceId;
    }
    return false;
  };

  const validateCustomer = (customer: AdvisoryCustomer) => {
    if (customer.type == EMAIL) {
      return validateEmail(customer.emailAddress);
    } else if (customer.type == CC_EMAIL) {
      return validateEmail(customer.emailAddress);
    } else if (customer.type == SLACK) {
      return validateSlack(customer.slackChannel);
    } else if (customer.type == OMNI) {
      return true; // future enhancement: add validation for a group or workspace ID here.
    }
    return false;
  };

  const validateCustomers = () => {
    if (!props.setErrorMessage) return;

    let invalidValues = [];
    props.customers.forEach((item: AdvisoryCustomer) => {
      if (!validateCustomer(item)) {
        invalidValues.push(valueForCustomer(item));
      }
    });

    const aCommentIsTooLong =
      props.customers.filter((customer: AdvisoryCustomer) => customer.comment?.length > 256).length > 0;
    if (aCommentIsTooLong) {
      props.setErrorMessage(COMMENT_TOO_LONG_ERROR);
    } else if (invalidValues.length > 0) {
      props.setErrorMessage('The following values are invalid: ' + invalidValues.toString().replaceAll(',', ', '));
    } else {
      props.setErrorMessage(undefined);
    }
  };

  useEffect(() => {
    validateCustomers();
  }, [props.customers]);

  const addCustomers = (customersToAdd: AdvisoryCustomerList) => {
    let newCustomers = [];
    newCustomers.push(...props.customers);
    newCustomers.push(...customersToAdd);
    props.setCustomers(newCustomers);
  };

  return (
    <>
      <AddCustomerModal
        visible={importModalVisible}
        onClose={() => setImportModalVisible(false)}
        onDone={(customersToAdd) => addCustomers(customersToAdd)}
        impactedResources={props.impactedResources}
        userInfo={props.userInfo}
      />
      <SpaceBetween size={'l'}>
        {props.readonly == false && (
          <Alert statusIconAriaLabel='Info' header='Slack channel access'>
            Omni can send messages to all public channels and any private channel where AWS Omni Bot is added as an
            integration. Click <Link href={ADVISORIES_WIKI_PAGE}>here</Link> for more details.
          </Alert>
        )}
        <Table
          items={props.customers}
          ariaLabels={{
            activateEditLabel: (column, item) => `Edit ${item.type} ${column.header}`,
            cancelEditLabel: (column) => `Cancel editing ${column.header}`,
            submitEditLabel: (column) => `Submit editing ${column.header}`,
          }}
          empty={
            <Box margin={{ vertical: 'xs' }} textAlign={'center'} color={'inherit'}>
              <SpaceBetween size={'m'}>
                <b>No customers</b>
                <p>Add customers above.</p>
              </SpaceBetween>
            </Box>
          }
          header={
            <Header
              counter={'(' + props.customers.length + ')'}
              actions={
                props.readonly ? (
                  []
                ) : (
                  <SpaceBetween size={'xs'} direction={'horizontal'}>
                    <Button onClick={() => setImportModalVisible(true)}>Add customers</Button>
                  </SpaceBetween>
                )
              }
            >
              Advisory customers
            </Header>
          }
          columnDefinitions={[
            {
              id: 'groupOrWorkspace',
              header: 'Group or workspace ID',
              cell: (item: AdvisoryCustomer) =>
                item.groupOrWorkspaceId
                  ? linkForGroupOrWorkspace(item.groupOrWorkspaceId, props.workspaceNameMap)
                  : '-',
              minWidth: 35,
            },
            {
              id: 'email',
              header: 'Email address',
              cell: (item: AdvisoryCustomer) => item.emailAddress ?? '-',
              minWidth: 125,
              editConfig: props.readonly
                ? undefined
                : {
                    ariaLabel: 'Customer email',
                    editIconAriaLabel: 'editable',
                    errorIconAriaLabel: 'Email error',
                    editingCell: (item: AdvisoryCustomer, { currentValue, setValue }) => {
                      return (
                        <Input
                          autoFocus={true}
                          value={currentValue ?? item.emailAddress}
                          onChange={(event) => {
                            const value = event.detail.value ?? item.emailAddress;
                            setValue(value);
                            setCurrentEditValue(value);
                          }}
                        />
                      );
                    },
                  },
            },
            {
              id: 'slack',
              header: 'Slack channel',
              cell: (item: AdvisoryCustomer) => item.slackChannel ?? '-',
              minWidth: 125,
              editConfig: props.readonly
                ? undefined
                : {
                    ariaLabel: 'Customer Slack',
                    editIconAriaLabel: 'editable',
                    errorIconAriaLabel: 'Slack error',
                    editingCell: (item: AdvisoryCustomer, { currentValue, setValue }) => {
                      return (
                        <Input
                          autoFocus={true}
                          value={currentValue ?? item.slackChannel}
                          onChange={(event) => {
                            const value = event.detail.value ?? item.slackChannel;
                            setValue(value);
                            setCurrentEditValue(value);
                          }}
                        />
                      );
                    },
                  },
            },
            {
              id: 'comment',
              header: 'Comment',
              cell: (item: AdvisoryCustomer) => item.comment,
              minWidth: 175,
              editConfig: props.readonly
                ? undefined
                : {
                    ariaLabel: 'Customer comment',
                    editIconAriaLabel: 'editable',
                    errorIconAriaLabel: 'Comment error',
                    editingCell: (item: AdvisoryCustomer, { currentValue, setValue }) => {
                      return (
                        <Input
                          autoFocus={true}
                          value={currentValue ?? item.comment}
                          onChange={(event) => {
                            const value = event.detail.value ?? item.comment;
                            setValue(value);
                            setCurrentEditValue(value);
                          }}
                        />
                      );
                    },
                  },
            },
            {
              id: 'actions',
              header: '',
              cell: (item: AdvisoryCustomer) => (
                <Button
                  variant={'icon'}
                  iconName={'remove'}
                  onClick={() => {
                    props.setCustomers(
                      props.customers.filter(
                        (itm) => valueForCustomer(itm) != valueForCustomer(item) || itm.comment != item.comment,
                      ),
                    );
                  }}
                />
              ),
            },
          ].slice(0, props.readonly ? 4 : 5)} // remove the "delete" column in readonly mode
          submitEdit={(item: AdvisoryCustomer, column) => {
            const newItems = props.customers.map((itm) => {
              if (itm == item) {
                if (column.header == 'Email address') {
                  return {
                    groupOrWorkspaceId: itm.groupOrWorkspaceId,
                    emailAddress: currentEditValue,
                    slackChannel: itm.slackChannel,
                    type: itm.type,
                    comment: itm.comment,
                    addedBy: 'Manually', // whenever editing value, change AutoImport to Manually
                  };
                } else if (column.header == 'Slack channel') {
                  return {
                    groupOrWorkspaceId: itm.groupOrWorkspaceId,
                    emailAddress: itm.emailAddress,
                    slackChannel: currentEditValue
                      ? currentEditValue.startsWith('#')
                        ? currentEditValue
                        : '#' + currentEditValue
                      : undefined,
                    type: itm.type,
                    comment: itm.comment,
                    addedBy: 'Manually', // whenever editing value, change AutoImport to Manually
                  };
                } else {
                  return {
                    groupOrWorkspaceId: itm.groupOrWorkspaceId,
                    emailAddress: itm.emailAddress,
                    slackChannel: itm.slackChannel,
                    type: itm.type,
                    comment: currentEditValue,
                    addedBy: itm.addedBy,
                  };
                }
              }
              return itm;
            });
            props.setCustomers(newItems);
            setCurrentEditValue(undefined);
          }}
        />
      </SpaceBetween>
    </>
  );
};
