import * as React from 'react';
import { useState, useEffect } from 'react';

import { mediumPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { listResources, refreshResource } from '../../../api/resourcesmanager';
import { ErrorAlert, RMPageHeader, coloredStatus } from '../components';
import { Page } from '../../../routes/Paths';
import { dateString } from '../helpers';
import { GuardrailUnbound } from '../components-V3';

export interface resourcesTableProps {
  redirect: any;
  resourceGroupId: string;
  activeGroup: string;
}

export const ResourcesTable = (props: resourcesTableProps) => {
  const [error, setError] = useState(undefined);
  const [loadingResources, setLoadingResources] = useState(true);
  const [redirect, setRedirect] = useState(undefined);
  const [redirectParams] = useState(undefined);
  const [resources, setResources] = useState([]);
  const [guardrail, setGuardrail] = useState(null);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  useEffect(() => {
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup]);

  const getResources = async () => {
    const resources = await listResources({
      groupId: props.activeGroup,
      resourceGroupId: props.resourceGroupId,
    });

    setResources(resources.resources);
    setLoadingResources(false);
    setGuardrail(null);
  };

  /**
   * Asynchronously fetch and store resource groups.
   */
  const handleRefresh = async () => {
    setLoadingResources(true);

    try {
      getResources();
    } catch (err) {
      setError({ ...err, while: 'LOADING RESOURCES' });
    }
  };

  const handleRefreshResource = async (resourceId) => {
    try {
      const response = await refreshResource({
        id: resourceId,
      });
      if (response.successful) {
        handleRefresh();
      } else {
        setError({
          ...response,
          code: 'ResponseNotSuccessful',
          while: 'REFRESH RESOURCE',
        });
      }
    } catch (err) {
      console.log(err);
      setError({ ...err, while: 'REFRESH RESOURCE' });
    }
  };

  const handleAction = async (e) => {
    switch (e.detail.id) {
      case 'details':
        setRedirect(Page.RESOURCE_DETAILS.replace(':id', collectionProps?.selectedItems[0]?.id));
        break;

      case 'refresh':
        setGuardrail(true);
        break;
    }
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(resources, {
    filtering: {},
    pagination: { pageSize: 25 },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  if (redirect) props.redirect(redirect, redirectParams);

  return (
    <>
      {ErrorAlert(error)}
      <GuardrailUnbound
        header={`Recreate Cluster: ${collectionProps?.selectedItems[0]?.id}?`}
        action={() => {
          handleRefreshResource(collectionProps?.selectedItems[0]?.id);
          setGuardrail(false);
          handleRefresh();
        }}
        visible={guardrail}
        onClose={() => setGuardrail(false)}
      />

      <Table
        {...collectionProps}
        loadingText={`Loading resources in resource group: ${props.resourceGroupId}`}
        loading={loadingResources}
        columnDefinitions={[
          {
            id: 'resourceId',
            header: 'Resource ID',
            cell: (item) => (
              <a
                onClick={() =>
                  setRedirect(Page.RESOURCE_DETAILS.replace(':rgid', props.resourceGroupId).replace(':id', item.id))
                }
              >
                {item.id}
              </a>
            ),
            minWidth: 150,
          },
          {
            id: 'resourceStatus',
            header: 'Status',
            cell: (item) => coloredStatus(item.status),
            minWidth: 130,
            sortingField: 'status',
          },
          {
            id: 'resourceStatusReason',
            header: 'Status Reason',
            cell: (item) => item.statusReason,
            minWidth: 150,
          },
          {
            id: 'resourceUpdateDate',
            header: 'Updated',
            cell: (item) => dateString(item.updateDate),
            sortingField: 'updateDate',
          },
        ]}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <RMPageHeader
            buttons={[
              {
                text: '',
                icon: 'refresh',
                onItemClick: handleRefresh,
              },
              {
                text: 'Actions',
                onItemClick: handleAction,
                items: [
                  {
                    text: 'View Details',
                    id: 'details',
                    disabled: !collectionProps.selectedItems.length,
                  },
                  {
                    text: 'Recreate Cluster',
                    id: 'refresh',
                    disabled: !collectionProps.selectedItems.length,
                  },
                ],
                loading: loadingResources,
              },
            ]}
            subheader={
              <>
                Resources
                <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
              </>
            }
          />
        }
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No resources to display.</p>
          </div>
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        selectionType='single'
      />
    </>
  );
};
