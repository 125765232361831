import * as React from 'react';
import { useEffect } from 'react';
import { Container, ExpandableSection, Header, Textarea } from '@amzn/awsui-components-react-v3';
import { NotificationComments } from 'src/components/notifications/comment';
import {
  NotificationArchiveDetail,
  NotificationInboxDetail,
  NotificationSentDetail,
} from 'src/components/notifications/common';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface NotificationDetailProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace: any;
  username: string;
  selectedNotificationInfo: any;
  activeTabId: any;
}

export const NotificationDetail = (props: NotificationDetailProps) => {
  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  return (
    <>
      <Container header={<Header variant='h2'>{props.selectedNotificationInfo.selectedSubject}</Header>}>
        <Textarea value={props.selectedNotificationInfo.selectedContent} readOnly rows={10} />
      </Container>

      <div className='awsui-util-container'>
        <ExpandableSection header={[<span>Details</span>]}>
          {props.activeTabId == 'inbox' && <NotificationInboxDetail notification={props.selectedNotificationInfo} />}

          {props.activeTabId == 'archive' && (
            <NotificationArchiveDetail notification={props.selectedNotificationInfo} />
          )}

          {props.activeTabId == 'sent' && <NotificationSentDetail notification={props.selectedNotificationInfo} />}
        </ExpandableSection>
      </div>

      {(props.activeTabId == 'inbox' || props.activeTabId == 'sent') && (
        <NotificationComments
          {...props}
          notificationBatchId={props.selectedNotificationInfo.selectedNotificationBatchId}
          notificationId={props.selectedNotificationInfo.selectedNotificationId}
        />
      )}
    </>
  );
};
