import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Modal,
  Multiselect,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { associateMetadataFormToResource } from 'src/api/catalog';
import { useEffect, useState } from 'react';

export interface MetadataFormModalProps {
  formId: string;
  resource: any;
  setNotification: (header: any, message: string) => void;
  close: () => void;
  visible: boolean;
  formOwnerId?: string;
  displayFormOn: any;
}

export const MetadataFormModal = (props: MetadataFormModalProps) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedDisplayFormOnOptions, setSelectedDisplayFormOnOptions] = useState(undefined);
  const [displayFormOnAllOptions] = useState([
    {
      label: 'Detail',
      value: 'Detail',
    },
  ]);
  const resource = props.resource;

  useEffect(() => {
    setSelectedDisplayFormOnOptions(props.displayFormOn ? props.displayFormOn : undefined);
  }, [props.displayFormOn]);

  const handleEditForm = async () => {
    setButtonLoading(true);
    try {
      let associateMetadataFormToResourceRequest;
      if (selectedDisplayFormOnOptions == null || selectedDisplayFormOnOptions.length == 0) {
        associateMetadataFormToResourceRequest = {
          MetadataFormId: props.formId,
          OwnerId: props.formOwnerId,
          ResourceArn: resource,
        };
      } else {
        associateMetadataFormToResourceRequest = {
          MetadataFormId: props.formId,
          OwnerId: props.formOwnerId,
          ResourceArn: resource,
          DisplayFormOn: selectedDisplayFormOnOptions.map((option) => option['value']),
        };
      }

      const associateMetadataFormToResourceResult = await associateMetadataFormToResource(
        associateMetadataFormToResourceRequest,
      );
      if (associateMetadataFormToResourceResult.Message == 'Success') {
        props.setNotification('success', 'Success');
      } else {
        props.setNotification('error', `Failure in associating form: ${associateMetadataFormToResourceResult.Message}`);
      }
    } catch (err) {
      props.setNotification('error', `Error in editing metadata form. ${err.message}`);
    } finally {
      setButtonLoading(false);
      props.close();
    }
  };

  return (
    <Modal
      onDismiss={() => {
        props.close();
      }}
      visible={props.visible}
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              variant='link'
              onClick={() => {
                props.close();
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              // disabled={inputIsInvalid()}
              onClick={handleEditForm}
              loading={buttonLoading}
            >
              {'Save changes'}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={'Edit metadata form'}
    >
      <ColumnLayout>
        <FormField
          label={'Form Display On'}
          description='Select the pages to display the form.'
          // errorText={inputIsInvalid() ? generateInvalidMessage() : ''}
        >
          <Multiselect
            name='formDisplayOn'
            selectedOptions={selectedDisplayFormOnOptions}
            ariaRequired={true}
            placeholder='Choose display form on'
            onChange={({ detail }) => setSelectedDisplayFormOnOptions(detail.selectedOptions as any[])}
            options={displayFormOnAllOptions}
            selectedAriaLabel='Selected'
          />
        </FormField>
      </ColumnLayout>
    </Modal>
  );
};

export default MetadataFormModal;
