import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Toggle,
  Textarea,
  Checkbox,
  Icon,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import {
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_REDSHIFT_TYPE,
  fgaPolicyVisibilitySelection,
  TABLE_STATUS_DEPRECATED,
  tableStatusOptions,
  TEMPLATE_TYPE_ACCESS_MANAGEMENT,
} from 'src/commons/constants';
import { editDataset } from 'src/api/catalog';
import { listTemplates } from 'src/api/permissions';
import { EditDataSetRequest, GetDataSetResponseStructure } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import { fetchActiveConsumerDataPermissions } from 'src/components/permissions/myBaselining/baseliningUtils';
import { getDatasetUpperLevelResourceId } from 'src/components/utils/hybridCatalog/idUtil';

export interface DatasetEditModalProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  dataset: GetDataSetResponseStructure;
  visible: boolean;
  dismiss: any;
  notifyEditSuccess: any;
  notifyEditFailure: any;
  refreshPage: any;
}

export const DatasetEditModal = (props: DatasetEditModalProps) => {
  const [tableStatusOption, setTableStatusOption] = useState(undefined);
  const [datasetName, setDatasetName] = useState(undefined);
  const [datasetDesc, setDatasetDesc] = useState(undefined);
  const [isPii, setIsPii] = useState(props.dataset.PII ?? false);
  const [confidenceFile, setConfidenceFile] = useState(undefined);
  const [visibility, setVisibility] = useState<OptionDefinition>(undefined);
  const [deprecationConfirmed, setDeprecationConfirmed] = useState(undefined);
  const [deprecationConfirmationContent, setDeprecationConfirmationContent] = useState(undefined);

  const handleEdit = async () => {
    try {
      props.dismiss();
      if (!(await validatePrerequisites())) {
        props.notifyEditFailure(
          'Unable to edit dataset. PII datasets must be associated with an access template, associate a template to it and try again.',
        );
        return;
      }
      let index = getTableStatusIndex(props.dataset.TableState);
      setTableStatusOption(tableStatusOptions[index]);
      let request: EditDataSetRequest = {
        Id: props.dataset.Id,
        TableState: tableStatusOption.label,
        DataSetName: datasetName,
        Description: datasetDesc,
        DataClassification: visibility.value,
        PII: isPii,
        WheeljackConfidenceFileLocation: confidenceFile,
      };
      await editDataset(request);
      props.notifyEditSuccess('Edit dataset success');
    } catch (err) {
      props.notifyEditFailure('Exception edit dataset ' + err.message);
    }
    props.refreshPage();
  };

  const validatePrerequisites = async () => {
    return (
      !isPii ||
      (
        await listTemplates({
          ownerId: props.dataset.PrimaryOwner,
          templateType: TEMPLATE_TYPE_ACCESS_MANAGEMENT,
          resourceId: props.dataset.Id,
        })
      )?.templates?.length > 0
    );
  };

  const handleRefresh = async () => {
    let tableStatusOptionIndex = getTableStatusIndex(props.dataset.TableState);
    setTableStatusOption(tableStatusOptions[tableStatusOptionIndex]);
    setDatasetName(props.dataset.DataSetName);
    setDatasetDesc(props.dataset.DataSetDesc);
    setConfidenceFile(props.dataset.WheeljackConfidenceFileLocation);
    setDeprecationConfirmed(undefined);
    setDeprecationConfirmationContent(undefined);
    setVisibility({
      value: props.dataset.DataClassification,
      label: props.dataset.DataClassification,
    });
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const getTableStatusIndex = (tableState) => {
    for (const item of tableStatusOptions) {
      if (item.label === tableState) {
        return parseInt(item.id) - 1;
      }
    }
    return tableStatusOptions.length - 1;
  };

  const fetchActiveConsumerData = async (dataset) => {
    let dataSourceId = dataset.IdInfo.DataSourceId.charAt(0).toUpperCase() + dataset.IdInfo.DataSourceId.slice(1);
    let resource = {
      accountId: dataset.IdInfo.CatalogId,
      region: dataset.IdInfo.Region,
      type: 'TABLE',
      dataCatalogObjectDetails: {
        dataSourceId: dataSourceId,
        clusterIdentifier: dataset.IdInfo.ClusterIdentifier,
        databaseName: dataset.IdInfo.DatabaseName,
        schemaName: dataset.IdInfo.SchemaName,
        tableName: dataset.IdInfo.TableName,
      },
      dpType: dataSourceId === 'GlueLF' ? DATA_PERMISSION_LAKE_FORMATION_TYPE : DATA_PERMISSION_REDSHIFT_TYPE,
      tagResourceId: dataset.Id,
      tagUpperLevelResourceId: getDatasetUpperLevelResourceId(dataset),
    };
    let activeDataPermissions = await fetchActiveConsumerDataPermissions(resource);
    setDeprecationConfirmationContent(
      `This dataset has ${activeDataPermissions.length} active consumer(s). Confirm deprecation? (Existing consumer permissions will not be revoked)`,
    );
  };

  useEffect(() => {
    if (tableStatusOption?.label == TABLE_STATUS_DEPRECATED && props.dataset != null) {
      fetchActiveConsumerData(props.dataset);
    }
  }, [tableStatusOption]);

  return (
    <Modal
      onDismiss={props.dismiss}
      visible={props.visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={props.dismiss}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleEdit}
              disabled={tableStatusOption?.label == TABLE_STATUS_DEPRECATED && !deprecationConfirmed}
            >
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      size='medium'
      header='Edit dataset'
    >
      <ColumnLayout>
        <FormField label='Dataset ID' description='Dataset ID cannot be updated'>
          <Input placeholder={'Dataset ID'} value={props.dataset.Id} disabled />
        </FormField>
        <FormField label='Dataset name' description='Input the new name of the dataset'>
          <Input
            placeholder={'Dataset name'}
            value={datasetName}
            onChange={(e) => {
              setDatasetName(e.detail.value);
            }}
          />
        </FormField>
        <FormField label='Dataset description' description='Input the new description of the dataset'>
          <Input
            placeholder={'Dataset description'}
            value={datasetDesc}
            onChange={(e) => {
              setDatasetDesc(e.detail.value);
            }}
          />
        </FormField>
        <FormField label='Dataset status' description='Current status of the dataset.'>
          <Select
            selectedOption={tableStatusOption}
            options={tableStatusOptions}
            selectedAriaLabel='Selected'
            onChange={(e) => setTableStatusOption(e.detail.selectedOption)}
          />
        </FormField>

        <FormField label='Visibility' description='Mark it private to disable consumers requesting it..'>
          <Select
            placeholder='Public'
            selectedOption={visibility}
            options={fgaPolicyVisibilitySelection}
            ariaRequired={true}
            onChange={({ detail }) => {
              setVisibility(detail.selectedOption);
            }}
            disabled={false}
          />
        </FormField>

        <FormField label='Confidence files' description='Input the confidence files seperated by `,`'>
          <Textarea
            placeholder={'s3://file1, s3://file2'}
            value={confidenceFile}
            onChange={(e) => {
              setConfidenceFile(e.detail.value);
            }}
          />
        </FormField>
        <FormField label='Contains PII data'>
          <Toggle checked={isPii} onChange={(e) => setIsPii(e.detail.checked)} />
        </FormField>

        {tableStatusOption?.label == TABLE_STATUS_DEPRECATED && deprecationConfirmationContent && (
          <Checkbox checked={deprecationConfirmed} onChange={(e) => setDeprecationConfirmed(e.detail.checked)}>
            <Icon name='status-warning' variant='warning' /> {deprecationConfirmationContent}
          </Checkbox>
        )}
      </ColumnLayout>
    </Modal>
  );
};
