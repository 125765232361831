import React, { Component } from 'react';
import { SpaceBetween, Tabs } from '@amzn/awsui-components-react-v3';
import { LakeFormationTable } from '../permissions/myPermissions/lakeFormation';
import { BrowseRequestsTable } from './browseRequests';
import { DATACONSUMER } from 'src/commons/constants';
import { isIso } from 'src/api/config';
import { signedInWithWorkspace } from 'src/components/dataadvisory/constants';
import { Page } from 'src/routes';
import { Redirect } from 'react-router-dom';

interface MyRequestsProps {
  groupInfo: string | undefined;
  activeGroup: 'outgoingRequests' | 'incomingRequests';
  username: string | undefined;
  setContentType?: (contentType: string | undefined) => void;
  customerType: string;
}

export interface BrowseTableState {
  activeTabId: string | undefined;
  redirect?: string;
}

export default class MyRequests extends Component<MyRequestsProps, BrowseTableState> {
  state = {
    // Consumers should default to outgoing requests
    activeTabId: this.props.customerType === DATACONSUMER ? 'outgoingRequests' : 'incomingRequests',
    redirect: undefined,
  };

  componentDidMount() {
    if (signedInWithWorkspace(this.props)) {
      this.setState({ redirect: Page.WORKSPACE_MY_REQUESTS });
    }
  }

  render() {
    const { setContentType, activeGroup } = this.props;

    let tabs = [
      {
        label: 'Incoming',
        id: 'incomingRequests',
        content: isIso() ? (
          <BrowseRequestsTable isIncomingRequests={true} setContentType={setContentType} activeGroup={activeGroup} />
        ) : (
          <SpaceBetween size='l'>
            <LakeFormationTable
              {...this.props}
              setContentType={setContentType}
              activeGroup={activeGroup}
              requestType='CreateLakeFormationGrantPermission'
              requestBoundOutgoing={false}
            />

            <BrowseRequestsTable isIncomingRequests={true} setContentType={setContentType} activeGroup={activeGroup} />
          </SpaceBetween>
        ),
      },
      {
        label: 'Outgoing',
        id: 'outgoingRequests',
        content: isIso() ? (
          <BrowseRequestsTable isIncomingRequests={true} setContentType={setContentType} activeGroup={activeGroup} />
        ) : (
          <SpaceBetween size='l'>
            <LakeFormationTable
              {...this.props}
              setContentType={setContentType}
              activeGroup={activeGroup}
              requestType='CreateLakeFormationGrantPermission'
              requestBoundOutgoing={true}
            />

            <BrowseRequestsTable
              {...this.props}
              isIncomingRequests={false}
              setContentType={setContentType}
              activeGroup={activeGroup}
            />
          </SpaceBetween>
        ),
      },
    ];

    if (this.props.customerType === DATACONSUMER) {
      tabs.reverse(); // We want outgoing to be first for consumers
    }

    if (this.state.redirect) return <Redirect push to={this.state.redirect} />;

    return (
      <>
        <Tabs
          tabs={tabs}
          activeTabId={this.state.activeTabId}
          variant='default'
          onChange={({ detail }) => {
            this.setState({ activeTabId: detail.activeTabId });
          }}
        />
      </>
    );
  }
}
