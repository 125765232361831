import { FormField, SpaceBetween, Modal, Form, Button, Header, Select } from '@amzn/awsui-components-react-v3';
import React, { useState } from 'react';

export interface SelectAdvisoryTemplateModalProps {
  visible: boolean;
  templateOptions: any;
  onSelect: any;
  onClose: any;
  activeGroup: string;
  activeWorkspace?: any;
  confirmButtonText: string;
  descriptionText: string;
}

export const SelectAdvisoryTemplateModal = (props: SelectAdvisoryTemplateModalProps) => {
  const [selectedOption, setSelectedOption] = useState(undefined);

  const getSelectedTemplateId = () => {
    return selectedOption?.value;
  };

  return (
    <Modal
      visible={props.visible}
      header={'Select template'}
      onDismiss={props.onClose}
      footer={
        <Header
          actions={
            <SpaceBetween size={'s'} direction={'horizontal'} alignItems={'end'}>
              <Button variant={'link'} onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                variant={'primary'}
                onClick={() => props.onSelect(getSelectedTemplateId())}
                disabled={selectedOption == undefined}
              >
                {props.confirmButtonText}
              </Button>
            </SpaceBetween>
          }
        />
      }
    >
      <Form>
        <FormField label={'Select template'} description={props.descriptionText}>
          <Select
            selectedOption={selectedOption}
            onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
            options={props.templateOptions}
          />
        </FormField>
      </Form>
    </Modal>
  );
};
