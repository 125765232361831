import * as React from 'react';
import { useState, useEffect } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Table,
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
} from '@amzn/awsui-components-react-v3/polaris';

import { getAccounts } from '../../api/publisher';
import { PageHeader } from '../subscriptions/common';
import { Redirect } from 'react-router-dom';
import { Page } from '../../routes/Paths';
import { largePageSizePreference, defaultWrapLinesPreference, i18nStrings, paginationLabels } from 'src/commons/tables';
import { EmptyState } from 'src/commons/EmptyState';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface PublisherAccountsProps {
  setContentType: any;
  activeGroup: string;
}

export interface PublisherAccountsState {}

export const PublisherAccounts = (props: PublisherAccountsProps) => {
  const [allItems, setItems] = useState([]);
  const [redirect, setRedirect] = useState(undefined);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [tableMessage, setTableMessage] = useState('No accounts');
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const columnDefinitions = [
    {
      id: 'accountId',
      header: 'Account ID',
      cell: (item) => item.AwsAccountId,
      minWidth: 200,
      sortingField: 'AwsAccountId',
    },
    {
      id: 'iamRole',
      header: 'IAM role',
      cell: (item) => item.IamRole,
      minWidth: 150,
      sortingField: 'IamRole',
    },
    {
      id: 'createDate',
      header: 'Create date',
      cell: (item) => item.CreatedTimeStamp,
      minWidth: 210,
      sortingField: 'CreatedTimeStamp',
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup]);

  const handleRefresh = async () => {
    setLoadingAccounts(true);

    try {
      const accounts = await getAccounts({ GroupId: props.activeGroup });
      setItems(accounts.Accounts);
      setLoadingAccounts(false);
    } catch (err) {
      setTableMessage(`Unable to load accounts: ${err.message}`);
      setLoadingAccounts(false);
    }
  };

  const { items, propertyFilterProps, filteredItemsCount, paginationProps, collectionProps } = useCollection(allItems, {
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Account ID',
          key: 'AwsAccountId',
          defaultOperator: '=',
          groupValuesLabel: 'Account ID',
        },
      ],
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No accounts to display.</p>
        </div>
      ),
      noMatch: <EmptyState title='No matches' subtitle='We can’t find a match.' />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading accounts...'
        loading={loadingAccounts}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },

                {
                  text: 'Register account',
                  variant: 'primary',
                  onItemClick: () => {
                    setRedirect(Page.PUBLISHER_REGISTER_ACCOUNT);
                  },
                },
              ]}
              header={
                <>
                  Accounts
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      />
    </>
  );
};
