import { pruneEmpty, deepCopy, get, flattenObj, chunk, chunksToString, sinceToday } from '../helpers';
import * as constants from '../constants';

export const optionToObject = (name, optionsList) => {
  while (optionsList.length > 0 && 'options' in optionsList[0]) {
    optionsList = optionsList.map((x) => x.options).flat();
  }

  return [name, Object.fromEntries(optionsList.map((option) => [option.value, option]))];
};

/**
 * Recursively flattens the options keys nested within an Option object (see constants and ResourceGroupForm for an example).
 */
export const makeOptionsObject = (options) =>
  Object.fromEntries(Object.entries(options).map(([name, optionsList]) => optionToObject(name, optionsList)));

export const getSelectedFromValues = (values, options) => {
  const defaults = flattenObj(constants.values_default);
  const given = flattenObj(values);
  const options_obj = makeOptionsObject(options);

  return Object.fromEntries(
    Object.keys(options_obj).map((key) => [key, get(options_obj, [key, given[key] || defaults[key]])]),
  );
};

/**
 * Converts the value JSON
 */
export const valuesJsonToForm = (values) => {
  var vals = deepCopy(values);
  //Convert Int to String
  vals.emrGroupConfig.clusterRefreshDays = '' + (vals.emrGroupConfig.clusterRefreshDays || '');
  vals.emrGroupConfig.minClusters = '' + (vals.emrGroupConfig.minClusters || '');
  vals.emrGroupConfig.maxClusters = '' + (vals.emrGroupConfig.maxClusters || '');
  vals.emrGroupConfig.coreInstanceCount = '' + (vals.emrGroupConfig.coreInstanceCount || '');
  vals.emrGroupConfig.masterInstanceCount = '' + (vals.emrGroupConfig.masterInstanceCount || '');

  //Convert List to String
  vals.emrGroupConfig.ec2SubnetIds = '' + (vals.emrGroupConfig.ec2SubnetId || vals.emrGroupConfig.ec2SubnetIds || ''); // IGNORE SINGLE STRING
  vals.emrGroupConfig.applications = '' + (vals.emrGroupConfig.applications || '');

  //Convert JSON to String --> ALWAYS SEND AS JSON STRING, EMPTY MAP
  // Merge to only one field of just JSON objects (!!!)
  //Eventually should be merged and then deprecate config
  vals.emrGroupConfig.configurations = vals.emrGroupConfig.configurations
    ? JSON.stringify(vals.emrGroupConfig.configurations, null, 2)
    : '{}';

  vals.notification = vals.notification ? JSON.stringify(vals.notification, null, 2) : '';

  delete vals.emrGroupConfig['ec2SubnetId'];
  return vals;
};

export const valuesFormToJson = (values) => {
  var vals = deepCopy(values);
  //Convert String to Int
  vals.emrGroupConfig.clusterRefreshDays = parseInt(vals.emrGroupConfig.clusterRefreshDays);
  vals.emrGroupConfig.minClusters = parseInt(vals.emrGroupConfig.minClusters);
  vals.emrGroupConfig.maxClusters = parseInt(vals.emrGroupConfig.maxClusters);
  vals.emrGroupConfig.coreInstanceCount = parseInt(vals.emrGroupConfig.coreInstanceCount);
  vals.emrGroupConfig.masterInstanceCount = parseInt(vals.emrGroupConfig.masterInstanceCount);

  //Convert String to List
  vals.emrGroupConfig.ec2SubnetIds = vals.emrGroupConfig.ec2SubnetIds.split(',');
  vals.emrGroupConfig.applications = vals.emrGroupConfig.applications.split(',');

  //Convert String to JSON
  vals.emrGroupConfig.configurations = JSON.parse(vals.emrGroupConfig.configurations);
  vals.notification = JSON.parse(vals.notification);

  // Get rid of empty/default vals
  vals.emrGroupConfig = pruneEmpty(vals.emrGroupConfig);
  vals = pruneEmpty(vals);
  vals.emrGroupConfig['configurationsJsonString'] = vals.emrGroupConfig['configurationsJsonString'].replace(/\s+/g, '');
  delete vals.emrGroupConfig['ec2SubnetId'];
  if (vals.emrGroupConfig['configurationsJsonString'] == '') delete vals.emrGroupConfig['configurationsJsonString'];
  return vals;
};

export const chunkifyLists = (name, values = null, subkeys = []) => {
  var chunks = chunk([...(values || get(constants.values_default, [...subkeys, name]))]);
  const optionsObj = optionToObject(name, constants.select_options[name])[1];
  const convertObj = Object.fromEntries(Object.entries(optionsObj).map(([key, value]) => [key, value['label']]));

  return chunksToString(chunks, convertObj);
};

/**
 * Returns the estimated time until the next Cluster Refresh for a given resource group.
 */
export const nextRefresh = (resourceGroup) => {
  const clusterRefreshDays = resourceGroup.emrGroupConfig?.clusterRefreshDays || 30;
  const createDate = new Date(resourceGroup.createDate);

  return sinceToday(createDate) % clusterRefreshDays || clusterRefreshDays;
};
