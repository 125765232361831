import React, { useEffect, useState } from 'react';
import { listAllMetricCollections } from 'src/api/metricstore';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Link } from 'react-router-dom';
import { MetricStorePageHeader } from 'src/components/metricstore/components';
import { MetricCollectionSummary } from '@amzn/metric-store-typescript-client/clients/metricstore';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  Table,
  TableProps,
  TextFilter,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { EmptyState } from 'src/commons/EmptyState';
import { defaultWrapLinesPreference, mediumPageSizePreference, paginationLabels } from 'src/commons/tables';
import { UnauthorizedModal } from 'src/components/metricstore/components-V3';
import {
  createMetricCollectionDefinitionLink,
  createMetricCollectionMonthlyLink,
  createMetricCollectionQuarterlyLink,
  createMetricCollectionWeeklyLink,
  createMetricCollectionYearlyLink,
} from 'src/routes/Paths';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface MetricsCollectionCatalogProps {
  setContentType: any;
}

export const MetricCollectionsCatalog = (props: MetricsCollectionCatalogProps) => {
  const [loadingMetricCollection, setLoadingMetricCollection] = useState(false);
  const [allItems, setItems] = useState<MetricCollectionSummary[]>([]);
  const [onboardModal, setOnboardModal] = useState(false);
  const [tableMessage, setTableMessage] = useState('No metric collection');
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 25,
    wrapLines: false,
  });
  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'metricCollectionName',
      header: 'Name',
      cell: (item: MetricCollectionSummary): React.ReactNode => (
        <Link to={createMetricCollectionDefinitionLink(item.id)}> {item.name} </Link>
      ),
      minWidth: 225,
      sortingField: 'metricCollectionName',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item: MetricCollectionSummary): React.ReactNode => item.description,
      minWidth: 150,
      sortingField: 'description',
    },
    {
      id: 'owner',
      header: 'Owner',
      cell: (item: MetricCollectionSummary): React.ReactNode => item.owner,
      minWidth: 150,
      sortingField: 'owner',
    },
    {
      id: 'isArchived',
      header: 'Status',
      cell: (item: MetricCollectionSummary): React.ReactNode => (item.isArchived ? 'Inactive' : 'Active'),
      minWidth: 150,
      sortingField: 'isArchived',
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  /**
   * Asynchronously fetches and stores the metrics catalog
   */
  const handleRefresh = async () => {
    setLoadingMetricCollection(true);
    try {
      const ListAllMetricCollectionsResponse = await listAllMetricCollections({});
      setItems(ListAllMetricCollectionsResponse.metricCollections);
    } catch (err) {
      if (err.name == 'InsufficientPermissionException') {
        setOnboardModal(true);
      }
      setTableMessage(`No metric collection to display.`);
    }
    setLoadingMetricCollection(false);
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {},
    pagination: { pageSize: 25 },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'metricCollectionName',
        },
      },
    },
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  return (
    <>
      <UnauthorizedModal visible={onboardModal} onClose={() => setOnboardModal(false)} />
      <SpaceBetween size={'s'}>
        <Header variant='h1' description={'This page lists all metric collection created using MetricStore.'}>
          Metric collection catalog
        </Header>
        <Table
          {...collectionProps}
          loadingText='Loading metric collections catalog...'
          loading={loadingMetricCollection}
          columnDefinitions={columnDefinitions}
          items={items}
          wrapLines={preferences.wrapLines}
          resizableColumns={true}
          header={
            <>
              <MetricStorePageHeader
                buttons={[
                  {
                    text: '',
                    icon: 'refresh',
                    onItemClick: handleRefresh,
                  },
                  {
                    items: [
                      // Set disable to true if there is a selected item
                      {
                        text: 'Weekly',
                        id: 'weekly',
                        disabled: !collectionProps.selectedItems[0]?.enabledViews?.weekly,
                        href: createMetricCollectionWeeklyLink(collectionProps.selectedItems[0]?.id ?? ''),
                      },
                      {
                        text: 'Monthly',
                        id: 'monthly',
                        disabled: !collectionProps.selectedItems[0]?.enabledViews?.monthly,
                        href: createMetricCollectionMonthlyLink(collectionProps.selectedItems[0]?.id ?? ''),
                      },
                      {
                        text: 'Quarterly',
                        id: 'quarterly',
                        disabled: !collectionProps.selectedItems[0]?.enabledViews?.quarterly,
                        href: createMetricCollectionQuarterlyLink(collectionProps.selectedItems[0]?.id ?? ''),
                      },
                      {
                        text: 'Yearly',
                        id: 'yearly',
                        disabled: !collectionProps.selectedItems[0]?.enabledViews?.yearly,
                        href: createMetricCollectionYearlyLink(collectionProps.selectedItems[0]?.id ?? ''),
                      },
                    ],
                    loading: false,
                    text: 'View',
                  },
                ]}
                subheader={
                  <>
                    Metric Collections Catalog
                    <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                  </>
                }
              />
            </>
          }
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel='Search metric collections'
              filteringPlaceholder='Search metric collections'
              countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
            />
          }
          empty={<EmptyState title={tableMessage} subtitle='No metric collections' />}
          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
          preferences={
            <CollectionPreferences
              title={'Preferences'}
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={mediumPageSizePreference}
              wrapLinesPreference={defaultWrapLinesPreference}
            />
          }
          selectionType='single'
        />
      </SpaceBetween>
    </>
  );
};
