import { useState, useEffect } from 'react';
import * as React from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Table,
  CollectionPreferences,
  Pagination,
  CollectionPreferencesProps,
  PropertyFilter,
  TableProps,
  SpaceBetween,
  Header,
} from '@amzn/awsui-components-react-v3/polaris';

import { getTableInfo } from '../../api/publisher';
import { Link, Redirect } from 'react-router-dom';
import { PageHeader } from 'src/components/subscriptions/common';
import { ManageDatasets } from '../ingestion/manageDatasets';
import { Page } from '../../routes/Paths';
import { largePageSizePreference, defaultWrapLinesPreference, i18nStrings, paginationLabels } from 'src/commons/tables';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface PublishTablesComponentProps {
  setContentType: any;
  activeGroup: string;
  toggleHelp(): void;
  title: string;
}

export const PublisherDeprecation = <>Publisher V2 is in path to deprecation. Now you can use Tethys.</>;

const PublishTablesComponent = (props: PublishTablesComponentProps) => {
  const [allItems, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'tableName',
      header: 'Name',
      cell: (item) => <Link to={`${Page.PUBLISHER_TABLES}/${item.TableId}/${item.TableName}`}>{item.TableName}</Link>,
      minWidth: 100,
      sortingField: 'TableName',
    },
    {
      id: 'awsAccountId',
      header: 'AWS account ID',
      cell: (item) => item.AwsAccountId,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => item.Status,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    fetchTables();
  }, []);

  const fetchTables = async () => {
    setLoading(true);

    try {
      const tabledetails = await getTableInfo({
        GroupId: props.activeGroup,
      });
      console.log('Published Tables page:: getTableInfoResponse: ', tabledetails);
      setItems(tabledetails.Tables);
    } catch (e) {}

    setLoading(false);
  };

  const handleRefresh = () => {
    fetchTables();
  };

  const { items, filteredItemsCount, propertyFilterProps, paginationProps, collectionProps } = useCollection(allItems, {
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Table name',
          key: 'TableName',
          groupValuesLabel: 'Table name',
        },
        {
          propertyLabel: 'Table state',
          key: 'Status',
          groupValuesLabel: 'Table state',
        },
      ],
    },
    filtering: {},
    pagination: { pageSize: 25 },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'TableName',
        },
      },
    },
    selection: {},
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <SpaceBetween size='xl'>
      <Header variant='h1' description={'Easily ingest your S3, or DynamoDB data to a data lake with Tethys.'}>
        Tethys
      </Header>
      <ManageDatasets
        toggleHelp={props.toggleHelp}
        activeGroup={props.activeGroup}
        setContentType={props.setContentType}
      />

      <Table
        {...collectionProps}
        {...propertyFilterProps}
        loading={loading}
        loadingText='Loading tables...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
                {
                  text: 'Published accounts',
                  onItemClick: () => {
                    setRedirect(Page.PUBLISHER_ACCOUNTS);
                  },
                },
                {
                  text: 'Register table',
                  variant: 'primary',
                  disabled: true,
                  onItemClick: () => {
                    setRedirect(Page.CREATE_PUBLISHER_TABLE);
                  },
                },
              ]}
              header={
                <>
                  Publisher V2
                  <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                </>
              }
              description={PublisherDeprecation}
            />
          </>
        }
        empty={
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No tables</b>
            </div>
            <p className='awsui-util-mb-s'>No tables have been registered.</p>
          </div>
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      />
    </SpaceBetween>
  );
};

export default PublishTablesComponent;
