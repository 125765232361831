import { ColumnLayout, Container, Modal } from '@amzn/awsui-components-react-v3';
import * as React from 'react';

import { useEffect, useState } from 'react';

export interface ViewMetadataFormFieldModalProps {
  metadataFormField: any;
  close: () => void;
  visible: boolean;
}

export const ViewMetadataFieldModal = (props: ViewMetadataFormFieldModalProps) => {
  const [name, setName] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [owner, setOwner] = useState(undefined);
  const [formId, setFormId] = useState(undefined);
  const [fieldId, setFieldId] = useState(undefined);
  const [fieldType, setFieldType] = useState(undefined);
  const [booleanConfig, setBooleanConfig] = useState(undefined);
  const [stringConfig, setStringConfig] = useState(undefined);
  const [numberConfig, setNumberConfig] = useState(undefined);
  const [bgConfig, setBgConfig] = useState(undefined);

  const formField = props.metadataFormField;
  useEffect(() => {
    if (formField) {
      setName(formField.Name);
      setDescription(formField.Description);
      setStatus(formField.Status);
      setOwner(formField.OwnerId);
      setFormId(formField.MetadataFormId);
      setFieldId(formField.MetadataFieldId);
      setFieldType(formField.FieldType.replace('Field#', ''));
      setBooleanConfig(formField?.FieldConfig?.BooleanConfig);
      setStringConfig(formField?.FieldConfig?.StringConfig);
      setNumberConfig(formField?.FieldConfig?.NumberConfig);
      setBgConfig(formField?.FieldConfig?.BusinessGlossaryConfig);
    }
  }, [formField]);
  return (
    <Modal
      onDismiss={() => {
        props.close();
      }}
      visible={props.visible}
      size='medium'
      header='Metadata field'
    >
      <div className={'awsui'}>
        <Container>
          <ColumnLayout columns={2} borders={'horizontal'}>
            <div>
              <div className='awsui-util-label'>Name</div>
              <div> {name}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Description</div>
              <div> {description}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Form ID</div>
              <div> {formId}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Field ID</div>
              <div> {fieldId}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Status</div>
              <div> {status}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Owner</div>
              <div> {owner}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Field type</div>
              <div> {fieldType}</div>
            </div>
            {booleanConfig && (
              <div>
                <div className='awsui-util-label'>Field type</div>
                <div> {fieldType}</div>
              </div>
            )}
            {stringConfig && (
              <div>
                <div className='awsui-util-label'>Format</div>
                <div> {stringConfig.StringConfigFormat}</div>
              </div>
            )}
            {stringConfig && (
              <div>
                <div className='awsui-util-label'>Min length</div>
                <div> {stringConfig.MinLength}</div>
              </div>
            )}
            {stringConfig && (
              <div>
                <div className='awsui-util-label'>Max length</div>
                <div> {stringConfig.MaxLength}</div>
              </div>
            )}
            {/*{bgConfig && (
              <div>
                <div className='awsui-util-label'>Glossary ID</div>
                <div> {bgConfig.GlossaryId}</div>
              </div>
            )}*/}
            {bgConfig && (
              <div>
                <div className='awsui-util-label'>Min value</div>
                <div> {bgConfig.MinValue}</div>
              </div>
            )}
            {bgConfig && (
              <div>
                <div className='awsui-util-label'>Max value</div>
                <div> {bgConfig.MaxValue}</div>
              </div>
            )}
            {numberConfig && (
              <div>
                <div className='awsui-util-label'>Min value</div>
                <div> {numberConfig.Min}</div>
              </div>
            )}
            {numberConfig && (
              <div>
                <div className='awsui-util-label'>Max value</div>
                <div> {numberConfig.Max}</div>
              </div>
            )}
          </ColumnLayout>
        </Container>
      </div>
    </Modal>
  );
};
