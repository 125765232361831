import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  createNotificationComment,
  getNotification,
  getNotificationBatch,
  getNotificationComments,
  updateNotificationBatch,
  updateNotifications,
} from 'src/api/notifications';
import { CommentLine } from 'src/components/notifications/common';
import { Icon, Button, Textarea, Form, Header, Container } from '@amzn/awsui-components-react-v3';

export interface NotificationCommentsProps {
  activeWorkspace: any;
  setContentType: any;
  activeGroup: string;
  username: string;
  notificationBatchId: any;
  notificationId: any;
}

export const NotificationComments = (props: NotificationCommentsProps) => {
  const [commentList, setCommentList] = useState([]);
  const [commentContent, setCommentContent] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [responseComment, setResponseComment] = useState(undefined);
  const [replyCommentLabel, setReplyCommentLabel] = useState(undefined);

  //fetch the comments according to batch id
  const fetchComments = async () => {
    const comments = await getNotificationComments({
      NotificationBatchId: props.notificationBatchId,
      NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
    });
    setCommentList([...comments.NotificationComments]);
  };

  //remove receiver from read list, then the notification will be unread for the user, the user would be notified
  const notifyNotificationReceiver = async (userId, notificationId, groupId) => {
    try {
      await updateNotifications({
        NotificationOwnerId: groupId,
        NotificationIdList: [notificationId],
        ReplyUserComment: userId,
      });
    } catch (e) {
      console.log(e);
    }
  };

  //add sender to the batch review list, then the sender need to be notified to review the notification batch
  const notifyNotificationSender = async () => {
    updateNotificationBatch({
      NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
      NotificationBatchId: props.notificationBatchId,
      MarkComment: true,
    });
  };

  const handleCreateComment = async () => {
    let comment = responseComment;
    setButtonLoading(true);
    if (comment != null) {
      // condition when a comment respond to another comment
      await createNotificationComment({
        NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
        NotificationCommentContent: commentContent,
        NotificationBatchId: props.notificationBatchId,
        RespondNotificationCommentId: comment.NotificationCommentId,
        NotificationId: props.notificationId,
      });

      const batch = getNotificationBatch({
        NotificationBatchId: props.notificationBatchId,
      });

      // when the respond comment is not added by the notification owner, notify the comment owner for this new comment
      if (comment.NotificationId != null && comment.UserId != batch['UserId'] && comment.UserId != props.username) {
        const notificationResponse = await getNotification({
          NotificationId: comment.NotificationId,
        });

        await notifyNotificationReceiver(
          comment.UserId,
          comment.NotificationId,
          notificationResponse['Notification']['ReceiverId'],
        );
      }
    } else {
      // create comment without respond to another comment
      await createNotificationComment({
        NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
        NotificationCommentContent: commentContent,
        NotificationBatchId: props.notificationBatchId,
        NotificationId: props.notificationId,
      });
    }
    //when new comment is added, notify the notification sender
    notifyNotificationSender();

    fetchComments();
    resetComment();
    setButtonLoading(false);
  };

  const resetComment = async () => {
    setCommentContent('');
    setResponseComment(undefined);
  };

  //ensure the form is filled
  const allFieldsSet = async () => {
    return commentContent != null && commentContent != '';
  };

  useEffect(() => {
    fetchComments();
  }, [props.notificationId, props.notificationBatchId]);

  let comments = commentList.map((item) => {
    return (
      <>
        <CommentLine comment={item} />

        {responseComment == null && (
          <Button
            className='awsui-util-f-r'
            variant='link'
            onClick={() => {
              setResponseComment(item);
              setReplyCommentLabel({
                replyCommentLabel: 'Reply to ' + responseComment.SenderId + ' (' + responseComment.RespondUserId + ') ',
              });
            }}
          >
            Reply
          </Button>
        )}

        {responseComment != null && responseComment.NotificationCommentId == item.NotificationCommentId && (
          <Form
            header={<Header description='You can add a comment here.'></Header>}
            actions={
              <div>
                <Button
                  variant='primary'
                  onClick={handleCreateComment}
                  loading={buttonLoading}
                  disabled={!allFieldsSet()}
                >
                  Create comment
                </Button>
              </div>
            }
          >
            {responseComment != null && (
              <>
                <div>
                  {replyCommentLabel}
                  {'    '}
                  <span className='awsui-util-f-r' onClick={() => setResponseComment(undefined)}>
                    <Icon name='close' size='normal' variant='normal' /> Cancel
                  </span>
                </div>
              </>
            )}
            <Textarea
              name='content'
              placeholder='Comment'
              value={commentContent}
              ariaRequired={true}
              onChange={(e) => setCommentContent(e.detail.value)}
              rows={4}
            />
          </Form>
        )}

        <br />
        <br />
        <br />
      </>
    );
  });

  return (
    <>
      <Container>
        <h3>All Comments ({comments.length})</h3>
        <br />
        <div>{comments}</div>
      </Container>
      {responseComment == null && (
        <Form
          actions={
            <div>
              <Button
                variant='primary'
                onClick={handleCreateComment}
                loading={buttonLoading}
                disabled={!allFieldsSet()}
              >
                Create Comment
              </Button>
            </div>
          }
          header={<Header description='You can add a comment here.'></Header>}
        >
          <Textarea
            name='content'
            placeholder='content'
            value={commentContent}
            ariaRequired={true}
            onChange={(e) => setCommentContent(e.detail.value)}
            rows={4}
          />
        </Form>
      )}
    </>
  );
};
