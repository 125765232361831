import React from 'react';
import { Icon } from '@amzn/awsui-components-react';

export const StatusIcon = (status) => {
  switch (status['status']) {
    case 'SUCCEEDED':
      return (
        <div className='awsui-util-status-positive'>
          <Icon name='status-positive' /> Success
        </div>
      );

    case 'FAILED':
      return (
        <div className='awsui-util-status-negative'>
          <Icon name='status-negative' /> Failed
        </div>
      );

    case 'ABORTED':
      return (
        <div className='awsui-util-status-negative'>
          <Icon name='status-negative' /> Aborted
        </div>
      );

    case 'STARTING':
      return (
        <div className='awsui-util-status-inactive'>
          <Icon name='status-pending' /> Pending
        </div>
      );

    case 'TIMEOUT':
      return (
        <div className='awsui-util-status-negative'>
          <Icon name='status-warning' /> Timeout
        </div>
      );

    case 'RUNNING':
      return (
        <div className='awsui-util-status-info'>
          <Icon name='status-in-progress' /> Running
        </div>
      );

    case 'PENDING':
      return (
        <div className='awsui-util-status-inactive'>
          <Icon name='status-in-progress' /> Pending
        </div>
      );

    case 'COMPLETED':
      return (
        <div className='awsui-util-status-positive'>
          <Icon name='status-positive' /> Success
        </div>
      );

    default:
      return (
        <div className='awsui-util-status-in-negative'>
          <Icon name='status-warning' /> {status['status']}
        </div>
      );
  }
};
