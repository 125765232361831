import { Link } from '@amzn/awsui-components-react-v3';
import { UX_WRITING } from '../../common/uxWriting';
import * as React from 'react';
import { docs } from '../../common/constants';

export const stepsHeader = {
  step1: {
    title: UX_WRITING.STEP_ONE_TITLE,
    description: 'Create a data contract to ingest your S3 data into AWS Data Lake.',
  },
  step2: {
    title: UX_WRITING.STEP_TWO_TITLE,
    description: 'Provide details on the state of your data before ingested into AWS Data Lake.',
  },
  step3: {
    title: UX_WRITING.STEP_THREE_TITLE,
    description: (
      <>
        Use
        <Link external href={docs.glueRegistry}>
          {' '}
          AWS Glue Schema Registry
        </Link>{' '}
        to define, control, and evolve the schema of your data.
      </>
    ),
  },
  dynamoSchema: {
    title: UX_WRITING.STEP_THREE_TITLE,
    description: 'Define, control, and evolve the schema of your data.',
  },
  step4: {
    title: UX_WRITING.STEP_FOUR_TITLE,
    description: 'Define how your data is ingested, and deduplicated.',
  },
  step5: {
    title: 'Review and submit',
    description: 'Validate the information before registering your data contract.',
  },
};

export const i18nStrings = {
  stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
  collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
  skipToButtonLabel: (step) => `Skip to ${step.title}`,
  cancelButton: 'Cancel',
  previousButton: 'Previous',
  nextButton: 'Next',
  optional: 'not required',
};

export const capitalize = (word: string) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
