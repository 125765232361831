import logcollector, { GetAggregatedStatsRequest, GetAggregatedStatsResponse } from 'aws-sdk/clients/logcollector';
import basicCallWrapper from './client_call';
import { getAccessLogCollectorConfig } from 'src/api/config';

const config = getAccessLogCollectorConfig();

let accessLogCollectorClient = new logcollector();

export async function initAccessLogCollectorClient(credentials) {
  const accessLogCollectorClientConfig = {
    region: config.region,
    endpoint: config.endpoint,
    credentials: credentials,
  };
  accessLogCollectorClient = new logcollector(accessLogCollectorClientConfig);
}

export const getAggregateStatistics = (input: GetAggregatedStatsRequest) => {
  return basicCallWrapper<GetAggregatedStatsRequest, GetAggregatedStatsResponse>(
    accessLogCollectorClient,
    accessLogCollectorClient.getAggregatedStats,
  )(input);
};
