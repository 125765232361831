import tethyscontractservicelambda, {
  GetDataSetRequest,
  GetDataSetResponse,
  ListDataSetsRequest,
  ListDataSetsResponse,
  RegisterDataSetRequest,
  RegisterDataSetResponse,
  RegisterContractVersionRequest,
  RegisterContractVersionResponse,
  PutPublishNotificationRequest,
  PutPublishNotificationResponse,
} from 'aws-sdk/clients/tethyscontractservicelambda';

import basicCallWrapper from './client_call';
import { getDatasetServiceConfig } from './config';
import { sourceTypes } from 'src/components/ingestion/common/constants';

const config = getDatasetServiceConfig();

let datasetServiceClient = new tethyscontractservicelambda();

export async function initDatasetServiceClient(credentials) {
  const datasetServiceClientConfig = {
    region: config.region,
    endpoint: config.endpoint,
    credentials: credentials,
  };

  datasetServiceClient = new tethyscontractservicelambda(datasetServiceClientConfig);
}

export const registerDataset = (input: RegisterDataSetRequest) => {
  return basicCallWrapper<RegisterDataSetRequest, RegisterDataSetResponse>(
    datasetServiceClient,
    datasetServiceClient.registerDataSet,
  )(input);
};

export const getDataset = async (input: GetDataSetRequest) => {
  const response = await basicCallWrapper<GetDataSetRequest, GetDataSetResponse>(
    datasetServiceClient,
    datasetServiceClient.getDataSet,
  )(input);

  if (!response.DataContract.DataProperties.DeDupKeyColumns) {
    response.DataContract.DataProperties.DeDupKeyColumns = [];
  }

  if (!response.DataSource) {
    response.DataSource = {
      SourceType: sourceTypes.S3,
    };
  }
  return response;
};

export const listDatasets = (input: ListDataSetsRequest) => {
  return basicCallWrapper<ListDataSetsRequest, ListDataSetsResponse>(
    datasetServiceClient,
    datasetServiceClient.listDataSets,
  )(input);
};

export const updateDataset = (input: RegisterContractVersionRequest) => {
  return basicCallWrapper<RegisterContractVersionRequest, RegisterContractVersionResponse>(
    datasetServiceClient,
    datasetServiceClient.registerContractVersion,
  )(input);
};

export const registerNotification = (input: PutPublishNotificationRequest) => {
  return basicCallWrapper<PutPublishNotificationRequest, PutPublishNotificationResponse>(
    datasetServiceClient,
    datasetServiceClient.putPublishNotification,
  )(input);
};
