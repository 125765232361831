import * as localforage from 'localforage';

export class StorageService {
  private static instance: StorageService;
  private userStorageConfig = {
    name: 'OmniLocalStorage',
    storeName: 'omni_local_storage',
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    version: 1,
    description: 'Web storage for infrequently used cached data - Catalog name, Wks names, etc.',
  };

  private constructor() {
    localforage.config(this.userStorageConfig);
  }

  public static getInstance(): StorageService {
    if (!StorageService.instance) {
      StorageService.instance = new StorageService();
    }
    return StorageService.instance;
  }

  public async setItem<T>(key: LocalStorageItemKey, value: LocalStorageItem<T>) {
    await localforage.setItem(key, value).catch((err) => console.error(`Error while storing ${key}`, err));
  }

  public async getItem<T>(key: LocalStorageItemKey): Promise<LocalStorageItem<T>> {
    let value: LocalStorageItem<T> = (await localforage.getItem(key).catch((err) => {
      console.error(`Error while fetching value of ${key}:`, err);
    })) as LocalStorageItem<T>;

    if (value) {
      // check for expiration and return
      const { lastUpdated, expiryMinutes } = value;
      const expiredDate = new Date(lastUpdated);
      expiredDate.setTime(lastUpdated.getTime() + expiryMinutes * 60 * 1000);
      if (new Date() < expiredDate) return value;
      else {
        // Key expired, remove from storage.
        await localforage.removeItem(key);
      }
    }
    return null;
  }

  /* This method removes the key from the local forage */
  public async removeItem(key: LocalStorageItemKey) {
    const data = await this.getItem(key);
    if (data) {
      await localforage.removeItem(key);
    }
  }
}

export class LocalStorageItem<T> {
  readonly lastUpdated: Date;

  constructor(readonly data: T, readonly expiryMinutes = 360) {
    this.lastUpdated = new Date();
  }
}

export enum LocalStorageItemKey {
  UserInfo = 'USER_INFO',
  CatalogNameMap = 'CATALOG_NAME_MAP',
  AllGroupWorkspaces = 'ALL_GROUP_WKS',
  AllWorkspaces = 'ALL_WKS',
  WorkspaceNameMap = 'WORKSPACE_NAME_MAP',
  DismissedAdvisories = 'DISMISSED_ADVISORIES',
}
