import React, { useState } from 'react';
import { Box, Header, Button, Container, SpaceBetween, ColumnLayout, Icon } from '@amzn/awsui-components-react-v3';
import { DedupKeys, PrimaryKeys } from '../../common/primaryKeys';
import { GetDataSetResponse } from 'aws-sdk/clients/tethyscontractservicelambda';
import { UpdateRedirect } from '../../common/navigation';
import { parseTethysARN } from '../../common/utils';
import { isDelimited, isSourceDDB, isSourceS3 } from '../../registerContract/utils/validation';
import { serializeDatasetManifest } from '../../registerContract/manifests/exportManifest';

interface iContractTab {
  dataset: GetDataSetResponse;
  arn: string;
}
export const ContractTab = ({ dataset, arn }: iContractTab) => {
  const [redirect, setRedirect] = useState<boolean>();
  if (redirect) return <UpdateRedirect id={dataset.DataSetId} />;

  const downloadManifest = () => {
    const serialization = serializeDatasetManifest(dataset);

    // create a temporary <a /> to link to the serialized content, then click it to download
    const url = URL.createObjectURL(new Blob([serialization], { type: 'application/json' }));
    const a = document.createElement('a');
    a.href = url;
    a.download = `tethys_${dataset.TableName}_export_${new Date().toISOString()}.json`;
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
  };

  return (
    <Container
      header={
        <Header
          variant='h2'
          actions={
            <SpaceBetween direction='horizontal' size='xl'>
              <Button onClick={downloadManifest}>
                <Icon name='download' /> Export manifest
              </Button>
              <Button onClick={() => setRedirect(true)}>Update</Button>
            </SpaceBetween>
          }
        >
          Data Contract
        </Header>
      }
    >
      <ColumnLayout columns={3} variant='text-grid'>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Dataset name</Box>
            <div> {dataset.TableName} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'>Database name</Box>
            <div> {dataset.DatabaseName} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'> Catalog Id </Box>
            <div> {dataset.CatalogId} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'> AWS region </Box>
            <div> {parseTethysARN(arn).region} </div>
          </div>

          {isSourceS3(dataset) && (
            <div>
              <Box variant='awsui-key-label'>IAM role</Box>
              <div> {dataset.DataContract.ServiceLevelAgreement.IAMRole} </div>
            </div>
          )}
        </SpaceBetween>

        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Publish type</Box>
            <div>{dataset.DataContract.ServiceLevelAgreement.PublishType} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'>Primary keys (dedup)</Box>
            <PrimaryKeys keys={dataset.DataContract.DataProperties.PrimaryKeyColumns} />
          </div>

          <div>
            <Box variant='awsui-key-label'>Ordering key (dedup)</Box>
            <DedupKeys keys={dataset.DataContract.DataProperties.DeDupKeyColumns} />
          </div>
        </SpaceBetween>

        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Data source</Box>
            <div> {dataset.DataSource?.SourceType} </div>
          </div>

          {isSourceS3(dataset) && (
            <div>
              <Box variant='awsui-key-label'>File format</Box>
              <div> {dataset.DataContract.DataFormat} </div>
            </div>
          )}

          {isDelimited(dataset?.DataContract) && ( // Delimited specific fields.
            <>
              <div>
                <Box variant='awsui-key-label'>Header lines</Box>
                <div> {dataset.DataContract.FileProperties.HeaderLines} </div>
              </div>

              <div>
                <Box variant='awsui-key-label'>Field delimiter</Box>
                <div>{dataset.DataContract.FileProperties.FieldDelimiter}</div>
              </div>

              <div>
                <Box variant='awsui-key-label'>Compression</Box>
                <div>{dataset.DataContract.DataProperties.CompressionFormat || 'None'}</div>
              </div>
            </>
          )}

          {isSourceS3(dataset) && (
            <>
              {dataset.DataSource?.S3SourceProperties?.Bucket && (
                <div>
                  <Box variant='awsui-key-label'>Bucket Name</Box>
                  <div> {dataset.DataSource?.S3SourceProperties?.Bucket} </div>
                </div>
              )}

              {dataset.DataSource?.S3SourceProperties?.Prefix && (
                <div>
                  <Box variant='awsui-key-label'>Prefix or wildcard</Box>
                  <div> {dataset.DataSource?.S3SourceProperties?.Prefix} </div>
                </div>
              )}
            </>
          )}

          {isSourceDDB(dataset) && (
            <>
              <div>
                <Box variant='awsui-key-label'>DynamoDB Table name</Box>
                <div>{dataset.DataSource?.DynamoDBSourceProperties?.DynamoTableArn}</div>
              </div>
            </>
          )}
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};
