import { Widget } from '@amzn/cloudwatchdashboards-inside';

export const recordsChart = (namespace: string, dataset: string): Widget => ({
  type: 'metric',
  x: 0,
  y: 5,
  width: 12,
  height: 8,
  properties: {
    title: 'Acquisition events',
    stacked: false,
    view: 'timeSeries',
    region: 'us-east-1',
    period: 300,
    stat: 'Sum',
    metrics: [
      [namespace, 'PublishEventsCount', 'Datasets', dataset, { label: 'Published events' }],
      ['.', 'AcquisitionFailures', '.', '.', { color: '#d62728', label: 'Acquisition failures' }],
    ],
  },
});

export const metricsChart = (namespace: string, dataset: string): Widget => ({
  type: 'metric',
  x: 12,
  y: 5,
  width: 12,
  height: 8,
  properties: {
    view: 'timeSeries',
    title: 'Acquired records',
    region: 'us-east-1',
    stacked: false,
    period: 300,
    stat: 'Sum',
    metrics: [[namespace, 'AcquiredRecords', 'Datasets', dataset, { label: 'Acquired records', color: '#aec7e8' }]],
  },
});
