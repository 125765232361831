import { iTethysRouteProps } from './types';

export const parseTethysARN = (arn: string) => ({
  region: arn.split(':')[3],
  accountId: arn.split(':')[4],
});

// Maps dataset ID to V1 or V2.
export const getDataSetId = (params: iTethysRouteProps) => {
  const { arn, database: db, dataset: ds } = params;
  return `${arn}${db ? `/${db}` : ''}/${ds}`;
}; // TODO: Deprecate V1 id before 2023.
