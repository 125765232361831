import * as React from 'react';
import { Page } from './Paths';
import { Redirect, Route, Switch } from 'react-router-dom';
import BrowseTable from '../components/catalog/browsedatasets';
import { DatasetDetails } from 'src/components/workspaces/dataBrowse/dataset/datasetDetail';
import BrowseCatalogsTable from '../components/catalog/browsecatalogs';
import BrowseDatabasesTable from '../components/catalog/browsedatabases';
import { Home } from '../components/home/home';
import { SearchHome } from 'src/components/search/search-home';
import { DatabaseDetail } from 'src/components/workspaces/dataBrowse/database/databaseDetail';
import { CatalogDetail } from 'src/components/workspaces/dataBrowse/catalog/catalogDetail';

export const OnboardingRoutes = (props) => (
  <Switch>
    <Route path={[Page.HOME]} exact render={(route_props) => <Home {...route_props} {...props} />} />

    <Route
      path={Page.BROWSE_CATALOGS}
      exact
      render={(route_props) => <BrowseCatalogsTable {...route_props} {...props} title='Catalogs' />}
    />
    <Route path={Page.CATALOG_DETAILS} exact render={(route_props) => <CatalogDetail {...route_props} {...props} />} />

    <Route
      path={Page.BROWSE_DATABASES}
      exact
      render={(route_props) => <BrowseDatabasesTable {...route_props} {...props} title='Databases' />}
    />
    <Route
      path={Page.DATABASE_DETAILS}
      exact
      render={(route_props) => <DatabaseDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.BROWSE_DATASETS}
      exact
      render={(route_props) => (
        <BrowseTable {...route_props} {...props} title='Datasets' extraFeatures={true} disableFiltering={true} />
      )}
    />
    <Route path={Page.SEARCH} exact render={(route_props) => <SearchHome {...route_props} {...props} />} />
    <Route path={Page.DATASET_DETAILS} exact render={(route_props) => <DatasetDetails {...route_props} {...props} />} />

    {/* Workspace dataset details page will redirect to the group-level page when user is not onboarded*/}
    <Route path={Page.DATASET_DETAILS} exact render={(route_props) => <DatasetDetails {...route_props} {...props} />} />

    <Route path={Page.DEPRECATED_CATALOG} exact render={(_) => <Redirect to={Page.BROWSE_DATASETS} />} />
    <Route
      path={Page.DEPRECATED_CATALOG_DETAILS}
      exact
      render={(route_props) => {
        return <Redirect to={Page.DATASET_DETAILS.replace(':id', route_props.match.params.id)} />;
      }}
    />

    <Route exact path='/onboarding' render={(_) => <Redirect to='/onboarding' />} />
    <Route path='*' render={(_) => <Redirect to='/' />} />
  </Switch>
);

export default OnboardingRoutes;
