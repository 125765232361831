import { useEffect, useState } from 'react';
import { search } from 'src/api/permissions';
import {
  defaultEntityBoostedFields,
  defaultTermAggregations,
  flatten,
  getDataSources,
  SearchEntityType,
} from 'src/components/search/constants';
import _ from 'lodash';

export function useServerCollections(params: any = {}) {
  const { pageSize } = params.pagination || { pageSize: 50 };
  const queryString = params.queryString || '';
  const filterTerms = params.filters;
  const searchEntityType = params.entityType || SearchEntityType.Dataset;
  const onAggregationsChange = params.onAggregationsChange;
  const isWorkspace = params.isWorkspace;
  const dataSources: string[] = getDataSources(isWorkspace);
  const catalogMap = params.catalogMap;
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(filterTerms);
  const [entityType, setEntityType] = useState(searchEntityType);

  useEffect(() => {
    // check if entity type is updated, if yes, reset filters and set entity type.
    if (searchEntityType !== entityType) {
      setFilters({});
      setEntityType(searchEntityType);
    } else {
      setFilters(filterTerms);
    }
  }, [searchEntityType, filterTerms]);

  useEffect(() => {
    const searchParams = {
      entityFilterList: entityType ? [entityType] : [SearchEntityType.Dataset],
      queryString,
      fields: defaultEntityBoostedFields.get(entityType),
      maxResults: pageSize,
      termAggFields: defaultTermAggregations.get(entityType),
      filters,
      nextToken: params.nextToken,
      onSetSearchLoading: params.onSetSearchLoading,
    };
    const callback = ({ items, nextToken, aggregations }) => {
      // filter and transform items based on entity type
      let filteredItems = flatten(items, dataSources, entityType, catalogMap);
      setItems(filteredItems);
      if (aggregations) {
        onAggregationsChange(aggregations);
      }
      params.onNextTokenChange(nextToken);
    };
    handleSearch(searchParams, callback);
  }, [entityType, filters, queryString]);

  return {
    items,
  };
}

async function handleSearch(params, callback) {
  let searchResult = await searchData(
    params.entityFilterList,
    params.queryString,
    params.fields,
    params.maxResults,
    params.termAggFields,
    params.filters,
    params.nextToken,
    params.onSetSearchLoading,
  );
  callback({
    items: searchResult.resultList,
    nextToken: searchResult.nextToken,
    aggregations: searchResult.aggregations,
  });
}

export const searchData = async (
  entityFilterList = [SearchEntityType.Dataset],
  queryString,
  fields = [],
  maxResults = 25,
  termAggFields,
  filterTerms,
  nextToken,
  onSetSearchLoading,
) => {
  onSetSearchLoading(true);
  // set results if only query or filters are set.
  // Display only filters on search page entry.
  let fetchResults = !!queryString || (filterTerms != undefined && Object.keys(filterTerms).length > 0);
  let searchRequest = {
    entityFilterList: entityFilterList,
    searchQuerySimple: {
      queryString: queryString ? queryString : undefined,
      fields: _.isEmpty(fields) ? [] : fields,
    },
    maxResults: fetchResults ? maxResults : 0,
    nextToken: nextToken,
  };
  if (filterTerms && Object.keys(filterTerms).length > 0) {
    searchRequest.searchQuerySimple['filterTerms'] = filterTerms;
  }
  if (termAggFields) {
    searchRequest.searchQuerySimple['termAggregatedFields'] = termAggFields;
  }
  let searchResult = await search(searchRequest);
  onSetSearchLoading(false);
  return searchResult;
};
