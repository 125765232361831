import * as React from 'react';
import { Box, Header, Table } from '@amzn/awsui-components-react-v3';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

export interface TableColumnListProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  version: any;
}

export const TableColumnList = (props: TableColumnListProps) => {
  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => item.Name,
      minWidth: '200px',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.Type,
      minWidth: '200px',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.Comment,
      minWidth: '200px',
    },
  ];

  return (
    <Table
      loadingText='Loading columns..'
      columnDefinitions={columnDefinitions}
      items={props.version?.Columns || []}
      resizableColumns
      header={
        <Header counter={props.version?.Columns?.length ? '(' + props.version.Columns.length + ')' : '0'}>
          Columns
        </Header>
      }
      empty={
        <Box textAlign='center' variant='p'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No columns</b>
          </div>
          <p className='awsui-util-mb-s'>No columns to display.</p>
        </Box>
      }
    />
  );
};
