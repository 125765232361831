import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Link,
  Modal,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { dissociateMetadataFormFromResource, listResourcesAssociatedToMetadataEntity } from 'src/api/catalog';
import { PageHeader } from 'src/components/subscriptions/common';
import { defaultWrapLinesPreference, notificationsPageSizePreference, paginationLabels } from 'src/commons/tables';
import { convertResourceARNToLink, getScopeForArn } from 'src/components/utils/arnUtil';

export interface MetadataFormAssociationsTableProps {
  form: any;
  ownerId: string;
  setNotification: (header: any, message: string) => void;
  entityAssociationsUpdateCounter: number;
}

const MetadataFormAssociationsTable = (props: MetadataFormAssociationsTableProps) => {
  const [metadataFormAssociations, setMetadataFormAssociations] = useState([]);
  const [selectedAssociatedResourceArn, setSelectedAssociatedResourceArn] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadingFormAssociations, setLoadingFormAssociations] = useState(false);
  const [formAssociationsTableMessage, setFormAssociationsTableMessage] = useState('No associations available');
  const [dissociateFormModalVisible, setDissociateFormModalVisible] = useState(false);
  const formId = props.form.MetadataFormId;
  const formOwnerId = props.form.OwnerId;
  const [nextToken, setNextToken] = useState(null);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    metadataFormAssociations == undefined ? [] : metadataFormAssociations,
    {
      filtering: {
        noMatch: '',
        empty: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{formAssociationsTableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No associated resources found. Associate form to a resource...</p>
          </div>
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  const generateResourceLink = (arn: string) => {
    return convertResourceARNToLink(arn);
  };

  const metadataFormAssociationColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'resourceArn',
      header: 'Resource ARN',
      cell: (item) => <Link href={generateResourceLink(item.Resource)}>{item.Resource}</Link>,
      minWidth: 200,
      sortingField: 'resourceArn',
    },
    {
      id: 'resourceType',
      header: 'Resource type',
      cell: (item) => getScopeForArn(item.Resource),
      minWidth: 200,
    },
  ];

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedFormAssociation = selectedItems[0];
    if (selectedFormAssociation) {
      setSelectedAssociatedResourceArn(selectedFormAssociation.Resource);
    }
  }, [collectionProps.selectedItems]);

  useEffect(() => {
    handleRefresh();
  }, [formId, props.entityAssociationsUpdateCounter]);

  const handleRefresh = async () => {
    setLoadingFormAssociations(true);
    setSelectedAssociatedResourceArn(undefined);
    await getMetadataFormAssociations();
  };

  const getMetadataFormAssociations = async () => {
    setLoadingFormAssociations(true);
    setMetadataFormAssociations([]);
    try {
      // get all metadata associatedResources for an owner and filter on the results
      let result = await listResourcesAssociatedToMetadataEntity({
        Id: formId,
        OwnerId: formOwnerId,
      });
      let associatedResources = [];
      setNextToken(result.NextToken);
      if (result.NextToken == null) {
        setAllDataLoaded(true);
      }
      //rename to shorter name
      associatedResources.push(
        ...result.TableList,
        ...result.CatalogList,
        ...result.DatabaseList,
        ...result.ClusterList,
        ...result.ColumnList,
      );
      setMetadataFormAssociations(associatedResources);
    } catch (err) {
      setFormAssociationsTableMessage(`Unable to load form associations. ${err.message}`);
    } finally {
      setLoadingFormAssociations(false);
    }
  };

  const loadMoreFormAssociations = async () => {
    setLoadingFormAssociations(true);
    setMetadataFormAssociations([]);
    try {
      let result = await listResourcesAssociatedToMetadataEntity({
        Id: formId,
        OwnerId: formOwnerId,
        NextToken: nextToken,
      });
      let associatedResources = [];
      associatedResources.push(
        ...result.TableList,
        ...result.CatalogList,
        ...result.DatabaseList,
        ...result.ClusterList,
        ...result.ColumnList,
      );
      let currentMetadataFormAssociations = metadataFormAssociations;
      currentMetadataFormAssociations.push(...associatedResources);
      setMetadataFormAssociations(currentMetadataFormAssociations);
      if (result.NextToken == null) {
        setAllDataLoaded(true);
      }
    } catch (err) {
      setFormAssociationsTableMessage(`Unable to load form associations. ${err.message}`);
    } finally {
      setLoadingFormAssociations(false);
    }
  };

  const handlePageChange = async (e) => {
    if (paginationProps.currentPageIndex == paginationProps.pagesCount && !allDataLoaded) {
      await loadMoreFormAssociations();
    }
    if (
      paginationProps.currentPageIndex != paginationProps.pagesCount ||
      (e.detail.currentPageIndex <= paginationProps.pagesCount &&
        e.detail.currentPageIndex != paginationProps.currentPageIndex)
    ) {
      paginationProps.onChange(e);
    }
  };

  const handleDissociateFormFromResource = async () => {
    setButtonLoading(true);
    try {
      let dissociateFormResult = await dissociateMetadataFormFromResource({
        ResourceArn: selectedAssociatedResourceArn,
        MetadataFormId: formId,
      });
      if (dissociateFormResult.Message == 'Success') {
        props.setNotification('success', 'Success');
      } else {
        props.setNotification('error', `Failure in dissociating form. Reason:${dissociateFormResult.Message}`);
      }
    } catch (err) {
      props.setNotification('error', `Error in dissociating form:${err.message}`);
    } finally {
      setButtonLoading(false);
      setDissociateFormModalVisible(false);
      await handleRefresh();
    }
  };

  const metadataFormAssociationTableButtonOptions = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Dissociate',
        variant: 'primary',
        disabled: !selectedAssociatedResourceArn,
        onItemClick: () => setDissociateFormModalVisible(true),
      },
    ];
  };

  const getFilterCounterText = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  return (
    <>
      <Modal
        visible={dissociateFormModalVisible}
        header={[`Delete?`]}
        onDismiss={() => setDissociateFormModalVisible(false)}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='primary' loading={buttonLoading} onClick={handleDissociateFormFromResource}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <div>
          <strong style={{ color: 'red' }}>WARNING: </strong>
          Are you sure you want to dissociate from resource <strong>{selectedAssociatedResourceArn}</strong>
          <br />
        </div>
      </Modal>
      <SpaceBetween size='l'>
        <Table
          {...collectionProps}
          selectionType='single'
          loadingText='Loading form associations...'
          loading={loadingFormAssociations}
          columnDefinitions={metadataFormAssociationColumnDefinitions}
          items={items}
          wrapLines={preferences.wrapLines}
          empty={
            <Box textAlign='center' color='inherit'>
              <b>No associations</b>
              <Box variant='p' color='inherit'>
                No associations to display.
              </Box>
            </Box>
          }
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel='Filter resources'
              filteringPlaceholder='Find resources'
              disabled={loadingFormAssociations}
              countText={getFilterCounterText(filteredItemsCount)}
            />
          }
          resizableColumns={true}
          header={
            <>
              <PageHeader
                buttons={metadataFormAssociationTableButtonOptions()}
                header={
                  <>
                    Resource Associations
                    <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                  </>
                }
              />
            </>
          }
          preferences={
            <CollectionPreferences
              title={'Preferences'}
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={notificationsPageSizePreference}
              wrapLinesPreference={defaultWrapLinesPreference}
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={paginationLabels}
              onChange={(e) => handlePageChange(e)}
              openEnd={!allDataLoaded}
              disabled={loadingFormAssociations}
            />
          }
        />
      </SpaceBetween>
    </>
  );
};

export default MetadataFormAssociationsTable;
