import * as React from 'react';

import { useEffect, useState } from 'react';
import { ButtonDropdownProps } from '@amzn/awsui-components-react-v3';
import TopNavigation from '@amzn/awsui-components-react-v3/polaris/top-navigation';
import { GROUP_WKS_ID_DELIMITER, WEBSITE_NAME } from '../../commons/constants';
import { Link, Redirect } from 'react-router-dom';
import { Page } from 'src/routes';
import { updateNotificationBatch, updateNotifications } from 'src/api/notifications';
import { getWorkspacesForSingleGroupMemoized } from 'src/api/permissions';
import { getGroupAndWksFromDropdownItemId, getWorkspaceDisplayName } from 'src/commons/common';

export interface HeaderProps {
  user: string;
  groups: Array<ButtonDropdownProps.Item>;
  setGroup: any;
  groupInfo: any;
  activeGroup: string;
  loadingGroup: boolean;
  cartItems: object[];
  unreadNotifications: any;
  unreadNotificationBatches: any;
  workspaceCount: any;
  removeUnreadNotificationId: any;
  removeUnreadNotificationBatchId: any;
  activeWorkspace: any;
  groupsAndWorkspacesDropdownItems: any[];
  workspacesForGroups: any;
}

let cartIconWhite = require('./cart_white.png');
let cartIconOrange = require('./cart_orange.png');

export const PageHeader = (props: HeaderProps) => {
  const [allItems, setItems] = useState(props.unreadNotifications);
  const [workspaceNotificationsCount, setWorkspaceNotificationsCount] = useState([]);
  const [redirect, setRedirect] = useState(undefined);
  const [workspaceUnreadNotificationsCount, setWorkspaceUnreadNotificationsCount] = useState(0);
  const [unreadWorkspaceItems, setUnreadWorkspaceItems] = useState([]);

  const onGroupChange = (e) => {
    if (e.detail.id.includes(`${GROUP_WKS_ID_DELIMITER}wks-`)) {
      const groupWksId = getGroupAndWksFromDropdownItemId(e.detail.id);
      props.setGroup(groupWksId[0], groupWksId[1]);
    } else {
      props.setGroup(e.detail.id, undefined);
    }
    setItems([]);
  };

  useEffect(() => {
    if (props.removeUnreadNotificationId !== undefined && allItems != null) {
      setItems(allItems.filter((item) => item.id != props.removeUnreadNotificationId));
    }
  }, [props.removeUnreadNotificationId]);

  useEffect(() => {
    if (props.removeUnreadNotificationBatchId !== undefined && allItems != null) {
      setItems(allItems.filter((item) => item.id != props.removeUnreadNotificationBatchId));
    }
  }, [props.removeUnreadNotificationBatchId]);

  useEffect(() => {
    if (props.unreadNotifications !== undefined) {
      transferNotificationToItems(props.unreadNotifications);
    }
  }, [props.unreadNotifications]);

  useEffect(() => setRedirect(undefined), [redirect]);

  const handleClick = async (e) => {
    if (e.detail.id == 'markRead') {
      let notificationIdList = [];
      for (let item of allItems) {
        if (item['id'].startsWith('notif-')) {
          notificationIdList.push(item['id']);
        } else if (item['id'].startsWith('notifbatch-')) {
          await updateNotificationBatch({
            NotificationBatchId: item['id'],
            MarkComment: false,
            NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
          });
        }
      }

      await updateNotifications({
        NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
        NotificationIdList: notificationIdList,
        MarkRead: true,
      });
      setItems([]);
    } else if (e.detail.id == 'viewAll') {
      setRedirect(Page.NOTIFICATIONS);
    } else if (e.detail.id == 'configuration') {
      setRedirect(Page.NOTIFICATION_CONFIGURATION);
    } else if (e.detail.id == 'create') {
      setRedirect(Page.CREATE_NOTIFICATION);
    } else if (e.detail.id.startsWith('notif-')) {
      let notificationId = e.detail.id;
      setItems(allItems.filter((item) => item.id != notificationId));
      setRedirect(`/notifications/` + notificationId);
    } else if (e.detail.id.startsWith('notifbatch-')) {
      let notificationBatchId = e.detail.id;
      setItems(allItems.filter((item) => item.id != notificationBatchId));
      setRedirect(`/notification-batch/` + notificationBatchId);
    } else if (e.detail.id.startsWith('wks-')) {
      let workspace = e.detail.id;
      props.setGroup(props.activeGroup, workspace);
    } else {
      console.log('Invalid request type found' + e);
    }
  };
  let allWorkspaces = [];

  const transferNotificationToItems = async (items) => {
    try {
      let getWorkspacesForGroupsResult = await getWorkspacesForSingleGroupMemoized(props.activeGroup);
      const groupWorkspacesMap = getWorkspacesForGroupsResult.workspaces;
      const workspacesByGroup = groupWorkspacesMap[props.activeGroup];
      if (workspacesByGroup !== undefined && workspacesByGroup.succeeded) {
        allWorkspaces = workspacesByGroup.workspaces;
      }
    } catch (e) {
      console.log(e);
    }

    let workspaceCountMap = props.workspaceCount;
    let sum = 0;
    setWorkspaceUnreadNotificationsCount(sum);

    if (!props.activeWorkspace && workspaceCountMap) {
      let totalUnreadCount = 0;
      for (let workspace of allWorkspaces) {
        let unreadNotifsForWorkspace = workspaceCountMap[workspace.workspaceId];
        totalUnreadCount += unreadNotifsForWorkspace;

        if (unreadNotifsForWorkspace > 0) {
          let unreadList = unreadWorkspaceItems;
          unreadList.push({
            id: workspace.workspaceId,
            text: `${
              workspace.workspaceName ? workspace.workspaceName : workspace.workspaceId
            } (${unreadNotifsForWorkspace} unread)`,
          });

          setUnreadWorkspaceItems(unreadList);
        }
      }
      setWorkspaceUnreadNotificationsCount(totalUnreadCount);
    }

    setWorkspaceNotificationsCount(unreadWorkspaceItems);

    if (items.length == 0) {
      return [];
    }

    let notImportantNotificationList = [];
    let importnatNotificationList = [];
    let commentsReview = [];

    for (let notification of items) {
      if (notification['PriorityLevel'] != null && notification['PriorityLevel'] === 'HIGH' && !props.activeWorkspace) {
        importnatNotificationList.push({
          id: notification['NotificationId'],
          text: (
            <Link to={`/notifications/${notification['NotificationId']}`}>
              {notification['Subject']} ({notification['ReceiverId']})
            </Link>
          ),
          iconName: 'status-warning',
        });
      } else if (
        notification['PriorityLevel'] != null &&
        notification['PriorityLevel'] === 'HIGH' &&
        props.activeWorkspace
      ) {
        importnatNotificationList.push({
          id: notification['NotificationId'],
          text: <Link to={`/notifications/${notification['NotificationId']}`}>{notification['Subject']}</Link>,
          iconName: 'status-warning',
        });
      } else if (notification['NotificationId'] != null && !props.activeWorkspace) {
        notImportantNotificationList.push({
          id: notification['NotificationId'],
          text: (
            <Link to={`/notifications/${notification['NotificationId']}`}>
              {notification['Subject']} ({notification['ReceiverId']})
            </Link>
          ),
        });
      } else if (notification['NotificationId'] != null && props.activeWorkspace) {
        notImportantNotificationList.push({
          id: notification['NotificationId'],
          text: <Link to={`/notifications/${notification['NotificationId']}`}>{notification['Subject']}</Link>,
        });
      }
    }
    for (let notificationBatch of props.unreadNotificationBatches) {
      if (
        notificationBatch['ReviewerList'] != null &&
        notificationBatch['ReviewerList'][props.user] != null &&
        !props.activeWorkspace
      ) {
        commentsReview.push({
          id: notificationBatch['NotificationBatchId'],
          text: (
            <Link to={`/notification-batch/${notificationBatch['NotificationBatchId']}`}>
              {notificationBatch['Subject']} ({notificationBatch['ReceiverId']})
            </Link>
          ),
        });
      } else if (
        notificationBatch['ReviewerList'] != null &&
        notificationBatch['ReviewerList'][props.user] != null &&
        props.activeWorkspace
      ) {
        commentsReview.push({
          id: notificationBatch['NotificationBatchId'],
          text: (
            <Link to={`/notification-batch/${notificationBatch['NotificationBatchId']}`}>
              {notificationBatch['Subject']}
            </Link>
          ),
        });
      }
    }
    importnatNotificationList.push(...notImportantNotificationList);
    importnatNotificationList.push(...commentsReview);
    setItems(importnatNotificationList);
  };

  let phoneTool = `https://phonetool.amazon.com/users/${props.user}`;

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <TopNavigation
      className={'awsui-button-icon'}
      identity={{
        href: '/',
        title: WEBSITE_NAME,
        logo: {
          src: '/AWSDPLogo.png',
        },
      }}
      utilities={[
        {
          type: 'button',
          text: '' + (props.cartItems == undefined || props.cartItems.length == undefined ? 0 : props.cartItems.length),
          iconUrl: props.cartItems !== undefined && props.cartItems.length == 0 ? cartIconWhite : cartIconOrange,
          onClick: () => setRedirect(Page.CHECKOUT),
        },
        {
          type: 'menu-dropdown',
          iconName: 'notification',
          ariaLabel: 'Notifications',
          title:
            allItems !== null && allItems !== undefined && allItems.length !== null && allItems.length !== undefined
              ? allItems.length + workspaceUnreadNotificationsCount + ' Unread Notifications'
              : 'Loading...',
          badge:
            (allItems !== null &&
              allItems !== undefined &&
              allItems.length !== null &&
              allItems.length !== undefined &&
              allItems.length > 0) ||
            workspaceUnreadNotificationsCount > 0,
          onItemClick: (e) => handleClick(e),
          items: [
            { id: 'markRead', text: 'Mark all notifications as read' },
            {
              id: 'unreadItems',
              text:
                props.activeWorkspace !== undefined && props.activeWorkspace !== null
                  ? (allItems?.length ?? 0) + ' unread workspace notifications'
                  : (allItems?.length ?? 0) +
                    workspaceUnreadNotificationsCount +
                    ' unread group and workspace notifications',
              items: allItems,
            },
            {
              id: 'workspaceUnread',
              items: workspaceNotificationsCount,
            },
            {
              id: 'configuration',
              text: 'Notification settings',
              iconName: 'settings',
            },
            {
              id: 'create',
              text: 'Create new notifications',
              iconName: 'add-plus',
            },
            {
              id: 'viewAll',
              text: 'View all notifications',
              iconName: 'menu',
            },
          ],
        },
        {
          type: 'menu-dropdown',
          text:
            props.activeWorkspace !== undefined && props.activeWorkspace !== null
              ? getWorkspaceDisplayName(props.activeWorkspace.workspaceName, props.activeWorkspace.workspaceId)
              : props.activeGroup,
          onItemClick: (e) => {
            onGroupChange(e);
          },
          items: props.groupsAndWorkspacesDropdownItems,
        },
        {
          type: 'button',
          iconName: 'user-profile',
          text: props.user,
          href: phoneTool,
        },
      ]}
      i18nStrings={{
        overflowMenuTriggerText: 'More',
      }}
    />
  );
};
