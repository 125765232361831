import * as React from 'react';
import { useState } from 'react';

import {
  Button,
  ColumnLayout,
  DateRangePicker,
  DateRangePickerProps,
  RadioGroup,
  SpaceBetween,
  TokenGroup,
} from '@amzn/awsui-components-react-v3';
import { Checkbox, ExpandableSection } from '@amzn/awsui-components-react-v3/polaris';
import { AdvisoriesFilterResources, AdvisoriesFilterStatuses, ListAdvisoriesFilter } from 'aws-sdk/clients/awsdlomni';
import { getResourceNameFromArn } from 'src/components/dataadvisory/constants';
import { generateArnFromId } from 'src/components/utils/arnUtil';
import { ResourceSelectorModal } from 'src/components/common/resourceSelector/resourceSelectorModal';

export interface DataAdvisoryTableFilterProps {
  activeGroup: string;
  activeWorkspace?: any;
  onSearch: any;
}

export const DataAdvisoryTableFilter = (props: DataAdvisoryTableFilterProps) => {
  const [resourceSelectorModalVisible, setResourceSelectorModalVisible] = useState(false);

  const [allAdvisoriesChecked, setAllAdvisoriesChecked] = useState(false);
  const [advisoriesForDataYouOwnChecked, setAdvisoriesForDataYouOwnChecked] = useState(false);
  const [advisoriesForDataYouHATChecked, setAdvisoriesForDataYouHATChecked] = useState(true);

  const [activeSelected, setActiveSelected] = useState(true);
  const [resolvedSelected, setResolvedSelected] = useState(false);
  const [canceledSelected, setCanceledSelected] = useState(false);
  const [draftSelected, setDraftSelected] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangePickerProps.Value>(undefined);

  const [selectedResources, setSelectedResources] = useState([]);
  const [resourceOperator, setResourceOperator] = useState('Or');

  const reset = () => {
    setAllAdvisoriesChecked(true);
    setAdvisoriesForDataYouOwnChecked(false);
    setAdvisoriesForDataYouHATChecked(false);
    setActiveSelected(true);
    setResolvedSelected(false);
    setCanceledSelected(false);
    setDraftSelected(false);
  };

  const createFilterObject = () => {
    const anyStatusesSelected = activeSelected || draftSelected || resolvedSelected || canceledSelected;
    const anyResourcesSelected = selectedResources.length > 0;
    const anyDateRangeSelected = selectedDateRange != undefined;
    const dateRangeSelected = convertRangeToStartAndEndDate();
    let statuses: AdvisoriesFilterStatuses = {
      active: activeSelected,
      draft: draftSelected,
      resolved: resolvedSelected,
      canceled: canceledSelected,
    };
    let resources: AdvisoriesFilterResources = {
      arns: selectedResources.map((resource) => resource.arn),
      operator: resourceOperator,
    };
    let filter: ListAdvisoriesFilter = {
      quickFilters: {
        allAdvisories: allAdvisoriesChecked,
        advisoriesForDataYouOwn: advisoriesForDataYouOwnChecked,
        advisoriesForDataYouHaveAccessTo: advisoriesForDataYouHATChecked,
      },
    };
    if (anyStatusesSelected) filter.statuses = statuses;
    if (anyResourcesSelected) filter.resources = resources;
    if (anyDateRangeSelected) filter.createDateRange = dateRangeSelected;
    return filter;
  };

  const convertRangeToStartAndEndDate = () => {
    // backend uses this format: yyyy-mm-ddThh:mm:ssZ, where the T and Z are constants. It is in UTC time.
    // front end uses: 2023-12-12T00:00:00-08:00 yyyy-mm-ddThh:mm-08:00 where the -8 is the timezone diff from UTC.
    if (!selectedDateRange) return;
    if (selectedDateRange.type == 'absolute') {
      const startDate = new Date(selectedDateRange.startDate);
      const endDate = new Date(selectedDateRange.endDate);
      return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
    } else {
      const amount = selectedDateRange.amount;
      const unit = selectedDateRange.unit;
      const endDate = new Date();
      let startDate = new Date();
      if (unit == 'second') {
        startDate.setSeconds(startDate.getSeconds() - amount);
      } else if (unit == 'minute') {
        startDate.setMinutes(startDate.getMinutes() - amount);
      } else if (unit == 'hour') {
        startDate.setHours(startDate.getHours() - amount);
      } else if (unit == 'day') {
        startDate.setDate(startDate.getDate() - amount);
      } else if (unit == 'week') {
        startDate.setDate(startDate.getDate() - 7 * amount);
      } else if (unit == 'month') {
        startDate.setMonth(startDate.getMonth() - amount);
      } else if (unit == 'year') {
        startDate.setFullYear(startDate.getFullYear() - amount);
      }
      return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
    }
  };

  const addResource = (resourceId: string) => {
    const arn = generateArnFromId(resourceId);
    const name = getResourceNameFromArn(arn);
    const newResource = {
      arn: arn,
      name: name,
    };
    let newResources = [];
    newResources.push(...selectedResources);
    newResources.push(newResource);
    setSelectedResources(newResources);
  };

  return (
    <>
      <ResourceSelectorModal
        onSelect={(resourceId) => {
          addResource(resourceId);
        }}
        visible={resourceSelectorModalVisible}
        close={() => setResourceSelectorModalVisible(false)}
        {...props}
      />
      <ExpandableSection variant='container' headerText='Filter'>
        <SpaceBetween size={'m'} direction={'vertical'}>
          <ColumnLayout columns={2}>
            <div>
              Quick filters:
              <Checkbox
                checked={allAdvisoriesChecked}
                onChange={(e) => {
                  setAllAdvisoriesChecked(e.detail.checked);
                  if (e.detail.checked) {
                    setAdvisoriesForDataYouOwnChecked(false);
                    setAdvisoriesForDataYouHATChecked(false);
                  }
                }}
              >
                All advisories
              </Checkbox>
              <Checkbox
                checked={advisoriesForDataYouOwnChecked}
                onChange={(e) => setAdvisoriesForDataYouOwnChecked(e.detail.checked)}
                disabled={allAdvisoriesChecked}
              >
                Advisories for data you own
              </Checkbox>
              <Checkbox
                checked={advisoriesForDataYouHATChecked}
                onChange={(e) => setAdvisoriesForDataYouHATChecked(e.detail.checked)}
                disabled={allAdvisoriesChecked}
              >
                Advisories for data you have access to
              </Checkbox>
            </div>
            <div>
              Created in range:
              <DateRangePicker
                onChange={(e) => {
                  console.log(e.detail.value);
                  setSelectedDateRange(e.detail.value);
                }}
                value={selectedDateRange}
                isValidRange={() => {
                  return {
                    valid: true,
                  };
                }}
                relativeOptions={[
                  {
                    key: 'previous-1-day',
                    amount: 1,
                    unit: 'day',
                    type: 'relative',
                  },
                  {
                    key: 'previous-2-days',
                    amount: 2,
                    unit: 'day',
                    type: 'relative',
                  },
                  {
                    key: 'previous-1-week',
                    amount: 1,
                    unit: 'week',
                    type: 'relative',
                  },
                  {
                    key: 'previous-2-weeks',
                    amount: 2,
                    unit: 'week',
                    type: 'relative',
                  },
                  {
                    key: 'previous-1-month',
                    amount: 1,
                    unit: 'month',
                    type: 'relative',
                  },
                ]}
                i18nStrings={{
                  absoluteModeTitle: 'Absolute',
                  applyButtonLabel: 'Apply',
                  //ariaDescribedby?: string
                  //ariaLabel?: string
                  //ariaLabelledby?: string
                  cancelButtonLabel: 'Cancel',
                  clearButtonLabel: 'Clear',
                  customRelativeRangeDurationLabel: 'Duration',
                  customRelativeRangeDurationPlaceholder: 'Enter a duration',
                  customRelativeRangeOptionDescription: 'Select a duration and unit of time.',
                  customRelativeRangeOptionLabel: 'Custom',
                  customRelativeRangeUnitLabel: 'Unit of time',
                  dateTimeConstraintText: 'For date, use YYYY/MM/DD. For time, use 24 hr format.',
                  endDateLabel: 'End date',
                  endTimeLabel: 'End time',
                  //errorIconAriaLabel: 'string',
                  formatRelativeRange: (value: DateRangePickerProps.RelativeValue) => {
                    return 'Last ' + value.amount + ' ' + value.unit + (value.amount > 1 ? 's' : '');
                  },
                  formatUnit: (unit: DateRangePickerProps.TimeUnit) => {
                    return unit + 's';
                  },
                  nextMonthAriaLabel: 'Next month',
                  previousMonthAriaLabel: 'Previous month',
                  relativeModeTitle: 'Relative',
                  relativeRangeSelectionHeading: 'Select a range from the options below.',
                  startDateLabel: 'Start date',
                  startTimeLabel: 'Start time',
                  todayAriaLabel: 'Today',
                }}
                placeholder={'Filter by create date range'}
              />
            </div>
            <div>
              Status:
              <Checkbox checked={activeSelected} onChange={(e) => setActiveSelected(e.detail.checked)}>
                Active
              </Checkbox>
              <Checkbox checked={resolvedSelected} onChange={(e) => setResolvedSelected(e.detail.checked)}>
                Resolved
              </Checkbox>
              <Checkbox checked={canceledSelected} onChange={(e) => setCanceledSelected(e.detail.checked)}>
                Canceled
              </Checkbox>
              <Checkbox checked={draftSelected} onChange={(e) => setDraftSelected(e.detail.checked)}>
                Draft
              </Checkbox>
            </div>
            <div>
              <p>Impacts resources:</p>
              <SpaceBetween size={'xs'} direction={'vertical'}>
                <RadioGroup
                  value={resourceOperator}
                  onChange={({ detail }) => setResourceOperator(detail.value)}
                  items={[
                    {
                      value: 'And',
                      label: 'All the selected resources',
                      disabled: selectedResources.length == 0,
                    },
                    {
                      value: 'Or',
                      label: 'At least one of the selected resources',
                      disabled: selectedResources.length == 0,
                    },
                  ]}
                />
                <Button onClick={() => setResourceSelectorModalVisible(true)}>Add resource</Button>
                {selectedResources.length > 0 ? (
                  <TokenGroup
                    items={selectedResources.map((resource) => {
                      return { label: resource.name, value: resource.arn };
                    })}
                    onDismiss={({ detail: { itemIndex } }) => {
                      setSelectedResources([
                        ...selectedResources.slice(0, itemIndex),
                        ...selectedResources.slice(itemIndex + 1),
                      ]);
                    }}
                  />
                ) : (
                  'No resources selected.'
                )}
              </SpaceBetween>
            </div>
          </ColumnLayout>
          <SpaceBetween size={'s'} direction={'horizontal'}>
            <Button variant={'primary'} onClick={() => props.onSearch(createFilterObject())}>
              Search
            </Button>
            <Button variant={'link'} onClick={reset}>
              Reset
            </Button>
          </SpaceBetween>
        </SpaceBetween>
      </ExpandableSection>
    </>
  );
};
