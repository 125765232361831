import * as React from 'react';
import { Component } from 'react';
import {
  Button,
  Checkbox,
  FormField,
  Select,
  SpaceBetween,
  Spinner,
  SplitPanel,
} from '@amzn/awsui-components-react-v3';
import {
  aggregationDisplayConfig,
  aggregationDisplaySortOrder,
  isFilterableFacet,
  OWNER_SEARCH_KEY_FIELD_NAMES,
  SearchAggregations,
  SearchEntityType,
} from 'src/components/search/constants';
import _ from 'lodash';
import { isValidWorkspace } from 'src/commons/validationUtils';
import { DefaultRouteProps, getGroupOrWorkspaceName } from 'src/commons/common';

interface SearchFiltersProps extends DefaultRouteProps {
  entityType: SearchEntityType;
  onEntityTypeChange: (entityType: SearchEntityType) => void;
  aggregations?: SearchAggregations;
  filters?: { [key: string]: string[] };
  onFiltersChange: (filters: { [key: string]: string[] }) => void;
  searchLoading: boolean;
}

interface SearchFiltersState {
  checkedFilters: { [key: string]: boolean };
  selectedFilters: { [key: string]: string[] };
  entityType: SearchEntityType;
  aggDisplayConfig: { [name: string]: { displayCount: boolean } };
}

export class SearchFilters extends Component<SearchFiltersProps, SearchFiltersState> {
  constructor(props) {
    super(props);
    this.state = {
      checkedFilters: {},
      selectedFilters: {},
      entityType: props.entityType,
      aggDisplayConfig: aggregationDisplayConfig.get(props.entityType),
    };
  }

  componentDidUpdate(prevProps: SearchFiltersProps) {
    if (prevProps.entityType !== this.state.entityType) {
      this.setState({
        entityType: this.props.entityType,
        aggDisplayConfig: aggregationDisplayConfig.get(this.props.entityType),
      });
    }
  }

  entityFilterList = (): { label: string; value: string }[] => {
    return Object.keys(SearchEntityType).map((key) => {
      return { label: this.getHumanReadableEntityName(SearchEntityType[key]), value: key };
    });
  };

  onCheckedEventHandler = (checked: boolean, fieldName, fieldValue) => {
    this.setState((current) => ({
      checkedFilters: {
        ...current.checkedFilters,
        [fieldValue]: checked,
      },
    }));
    let currentFieldFilter = checked
      ? this.state.selectedFilters[fieldName]
        ? [...this.state.selectedFilters[fieldName], fieldValue]
        : [fieldValue]
      : // remove filter from field
      this.state.selectedFilters[fieldName] && this.state.selectedFilters[fieldName].length > 1
      ? this.state.selectedFilters[fieldName].filter((f) => f != fieldValue)
      : null;
    // set state and notify in parent for changed filters
    this.setState(
      (current) => {
        let newState = {
          selectedFilters: {
            ...current.selectedFilters,
            [fieldName]: currentFieldFilter,
          },
        };
        if (currentFieldFilter === null) {
          // delete filter entry
          newState = { selectedFilters: { ...current.selectedFilters } };
          delete newState.selectedFilters[fieldName];
        }
        return newState;
      },
      () => {
        this.props.onFiltersChange(this.state.selectedFilters);
      },
    );
  };

  onSelectEventHandler = (fieldName: string, fieldValuesList: Array<string>) => {
    this.setState(
      (current) => {
        return {
          selectedFilters: {
            ...current.selectedFilters,
            [fieldName]: fieldValuesList,
          },
        };
      },
      () => {
        this.props.onFiltersChange(this.state.selectedFilters);
      },
    );
  };

  clearFilter = (fieldName: string) => {
    if (this.state.selectedFilters[fieldName]) {
      this.setState(
        (current) => {
          let newState = { selectedFilters: { ...current.selectedFilters } };
          delete newState.selectedFilters[fieldName];
          return newState;
        },
        () => {
          this.props.onFiltersChange(this.state.selectedFilters);
        },
      );
    }
  };

  getHumanReadableName = (fieldName: string, key: string): string => {
    if (fieldName == 'PII') {
      return key === '0' ? 'Non-PII' : 'PII';
    } else if (OWNER_SEARCH_KEY_FIELD_NAMES.has(fieldName) && isValidWorkspace(key)) {
      // return workspaceNames if present in global cache
      return getGroupOrWorkspaceName(key, this.props.workspaceNameMap);
    }
    return key;
  };

  getHumanReadableEntityName = (entityType: SearchEntityType): string => {
    if (entityType == SearchEntityType.BusinessGlossary) {
      return 'Business glossary';
    } else if (entityType == SearchEntityType.MetadataForm) {
      return 'Metadata form';
    }
    return entityType;
  };

  getHumanReadableFilter = (fieldName: string, fieldValue: string, docCount: number) => {
    return `${this.getHumanReadableName(fieldName, fieldValue)} (${
      this.state.aggDisplayConfig[fieldName]?.displayCount && docCount
    })`;
  };

  resetFilters = () => {
    this.setState(
      () => {
        return { selectedFilters: {}, checkedFilters: {} };
      },
      () => {
        this.props.onFiltersChange(this.state.selectedFilters);
      },
    );
  };

  createSingleCheckbox = (fieldName: string, bucket: { key?: string; docCount?: number }) => {
    return (
      <Checkbox
        onChange={({ detail }) => {
          this.onCheckedEventHandler(detail.checked, fieldName, bucket.key);
        }}
        checked={Object.keys(this.state.checkedFilters).includes(bucket.key) && this.state.checkedFilters[bucket.key]}
        name={fieldName}
        key={bucket.key}
      >
        {' '}
        {this.getHumanReadableName(fieldName, bucket.key)}{' '}
        {this.state.aggDisplayConfig[fieldName]?.displayCount && `(${bucket.docCount})`}
      </Checkbox>
    );
  };

  createSelect = (fieldName: string, buckets: Array<{ key?: string; docCount?: number }>) => {
    let options = [
      { label: 'Choose filter', value: 'None' },
      ...buckets.map((bucket) => ({
        label: this.getHumanReadableFilter(fieldName, bucket.key, bucket.docCount),
        value: bucket.key,
      })),
    ];
    let selectedOptions = this.state.selectedFilters[fieldName]
      ? buckets
          .filter((bucket) => _.includes(this.state.selectedFilters[fieldName], bucket.key))
          .map((bucket) => ({
            label: this.getHumanReadableFilter(fieldName, bucket.key, bucket.docCount),
            value: bucket.key,
          }))
      : [{ label: 'Choose filter', value: 'None' }];
    return (
      <Select
        selectedOption={selectedOptions[0]}
        onChange={({ detail }) => {
          if (detail.selectedOption.value === 'None') {
            this.clearFilter(fieldName);
          } else {
            this.onSelectEventHandler(fieldName, [detail.selectedOption.value]);
          }
        }}
        options={options}
        filteringType='auto'
        placeholder='Choose filter'
      />
    );
  };

  // build checkboxes based on aggregations
  getCheckboxes = (aggregations: SearchAggregations) => {
    let checkboxes = [];
    if (aggregations != undefined) {
      Object.entries(this.props.aggregations)
        .sort((ent1, ent2) => {
          let key1 = aggregationDisplaySortOrder.has(ent1[0]) ? aggregationDisplaySortOrder.get(ent1[0]) : 0;
          let key2 = aggregationDisplaySortOrder.has(ent2[0]) ? aggregationDisplaySortOrder.get(ent2[0]) : 0;
          if (key1 > key2) return -1;
          if (key1 < key2) return 1;
          return 0;
        })
        .forEach(([key, value]) => {
          let isFilterableAggregation: boolean = isFilterableFacet(key);
          const fieldName = value.fieldName;
          if (!isFilterableAggregation) {
            // Display regular checkboxes
            let boxes = value.buckets.map((bucket) => this.createSingleCheckbox(fieldName, bucket));
            checkboxes.push(
              <div key={key}>
                <FormField label={<b>{key}</b>}>{boxes}</FormField>
              </div>,
            );
          } else {
            // Display filterable select
            let selectList = this.createSelect(fieldName, value.buckets);
            checkboxes.push(
              <div key={key}>
                <FormField label={<b>{key}</b>}>{selectList}</FormField>
              </div>,
            );
          }
        });
    }
    return checkboxes;
  };

  render() {
    return (
      <SplitPanel header='Filter results' hidePreferencesButton={true}>
        <SpaceBetween direction='vertical' size='l'>
          <FormField label='Type of data'>
            <Select
              selectedOption={{
                label: this.getHumanReadableEntityName(this.props.entityType),
                value: this.props.entityType,
              }}
              onChange={({ detail }) => {
                // reset filters and state
                this.setState({ checkedFilters: {}, selectedFilters: {} });
                this.props.onFiltersChange({});
                this.props.onEntityTypeChange(detail.selectedOption?.value as SearchEntityType);
              }}
              options={this.entityFilterList()}
            />
          </FormField>
          <hr />
          {!_.isEmpty(this.state.selectedFilters) && (
            <Button variant='primary' onClick={this.resetFilters}>
              Clear filters
            </Button>
          )}
          {this.props.searchLoading && <Spinner size='big' />}
          {!this.props.searchLoading && this.getCheckboxes(this.props.aggregations)}
        </SpaceBetween>
      </SplitPanel>
    );
  }
}
