import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  FormField,
  Header,
  Input,
  Modal,
  Multiselect,
  Select,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getGroupInfo, getTaggedResources } from 'src/api/permissions';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { isValidPrincipalNotRoot, isValidWorkspace } from 'src/commons/validationUtils';
import { getConsumerDataLakePrincipal, getFoundryRoleARN } from 'src/commons/common';
import {
  DATABASE_RESOURCE_TYPE,
  DATASET_RESOURCE_TYPE,
  FGA_POLICY_TYPE_PREFIX,
  GALAXI_DATASOURCE_ID,
  GALAXI_NEW_DATA_ACCESS_CATEGORY,
  GALAXI_NEW_DATA_ACCESS_ITEM,
  GALAXI_NEW_DATA_ACCESS_TYPE,
  GALAXI_TYPE,
  LAKE_FORMATION_TAG_ITEM,
  LAKEFORMATION_RESOURCE,
  LAKEFORMATION_TAG_TYPE,
  REDSHIFT_RESOURCE,
  REDSHIFT_TAG_ITEM,
  REDSHIFT_TAG_TYPE,
  RESOURCE_ITEM,
  SCHEMA_RESOURCE_TYPE,
  TAG_ITEM,
  WEBSITE_NAME,
} from 'src/commons/constants';
import {
  createFgaPolicyViewLink,
  createDatasetDetailLink,
  createGlueDatabaseDetailLink,
  createSchemaDetailLink,
} from 'src/routes';
import { constructTicketUrlWithParams, getGalaxiSubscriptionURL } from 'src/components/workspaces/common/common';
import { GetGroupResult } from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { EmptyState } from 'src/commons/EmptyState';
import { SelectProps } from '@amzn/awsui-components-react-v3/polaris/select/interfaces';

export interface AccessSelectModalProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace?: any;
  visible: boolean;
  dismiss: any;
  dataset: any;
  addToCart: any;
  catalogName: any;
  cartItemIds: any;
  cartItems?: any;
  externalGroups?: any;
  allowListed?: boolean;
  selectedPermissionType?: any;
  allPermissionDropdownOptions: any[];
}

export const AccessSelectModal = (props: AccessSelectModalProps) => {
  const [selectedPermission, setSelectedPermission] = useState(undefined);
  const [selectedPermissions, setSelectedPermissions] = useState(undefined);
  const [allItems, setItems] = useState([]);
  const [selectedPrincipalType, setSelectedPrincipalType] = useState(undefined);
  const [principal, setPrincipal] = useState(undefined);
  const [principalErrorText, setPrincipalErrorText] = useState(undefined);
  const [dropdownPrincipalOptions, setDropDownPrincipalOptions] = useState([]);
  const [loadingResources, setLoadingResources] = useState(false);
  const groupServiceSupported = props.externalGroups != null;
  const onboardedToGalaxi = props.externalGroups?.length > 0;
  const isGalaxiPermission = props.selectedPermissionType == GALAXI_TYPE;
  let buttonText = 'Add to cart';
  if (props.dataset?.IdInfo.DataSourceId == GALAXI_DATASOURCE_ID) {
    if (groupServiceSupported && !onboardedToGalaxi) {
      buttonText = 'Onboard to Galaxi';
    } else {
      buttonText = 'Request Access';
    }
  } else if (isGalaxiPermission) {
    buttonText = 'Cut access ticket to Galaxi support team';
  }

  const flattenDatasetAccess = async (selectedPermission) => {
    return {
      datasetName: selectedPermission.datasetName,
      databaseName: selectedPermission.databaseName,
      tableName: selectedPermission.datasetName,
      catalogId: selectedPermission.catalogId,
      region: props.dataset?.IdInfo?.Region,
      catalogDisplayName: selectedPermission.catalogName,
      dataOwnerRole: isValidWorkspace(selectedPermission.owner)
        ? getFoundryRoleARN(props.dataset?.IdInfo?.Region, selectedPermission.catalogId)
        : selectedPermission.publisherRole,
      owner: selectedPermission.owner,
      itemType: RESOURCE_ITEM,
      permissionId: selectedPermission.permissionId,
      catalogName: selectedPermission.catalogName,
      id: selectedPermission.id,
      dataLakePrincipal: principal === undefined ? props.activeWorkspace.accountId : principal,
    };
  };

  let flattenTagAccess = async (selectedPermission) => {
    let catalogId = selectedPermission.catalogId;
    let catalogName = selectedPermission.catalogName;
    let permissionId = selectedPermission.permissionId;
    let tagKey = selectedPermission.tagKey;
    let tagValue = selectedPermission.tagValue;
    let owner = selectedPermission.owner;
    let id = selectedPermission.id;
    let resources = [];
    let getTaggedResourcesResponse = await getTaggedResources({
      tagPair: tagKey + ':' + tagValue,
      catalogId: selectedPermission.catalogId,
      region: props.dataset?.IdInfo?.Region,
      type: props.dataset?.ClusterIdentifier != null ? REDSHIFT_TAG_TYPE : LAKEFORMATION_TAG_TYPE,
    });

    for (let resource of getTaggedResourcesResponse.resources) {
      if (resource['resourceType'] == DATASET_RESOURCE_TYPE) {
        if (resource['schemaName'] != null) {
          resources.push(
            `${catalogName}.${resource['databaseName']}.${resource['schemaName']}.${resource['resourceName']}`,
          );
        } else {
          resources.push(`${catalogName}/${resource['databaseName']}/${resource['resourceName']}`);
        }
      } else if (resource['resourceType'] == DATABASE_RESOURCE_TYPE) {
        resources.push(`${catalogName}/${resource['databaseName']}`);
      } else if (resource['resourceType'] == SCHEMA_RESOURCE_TYPE) {
        resources.push(`${catalogName}.${resource['databaseName']}.${resource['databaseName']}`);
      }
    }

    let item = {
      tagKey: tagKey,
      tagValue: tagValue,
      tag: tagKey + '.' + tagValue,
      owner: owner,
      permissionId: permissionId,
      itemType: props.dataset?.ClusterIdentifier != null ? REDSHIFT_TAG_ITEM : LAKE_FORMATION_TAG_ITEM,
      resources: resources,
      catalogDisplayName: catalogName,
      dataOwnerRole: getFoundryRoleARN(props.dataset?.IdInfo?.Region, catalogId),
      catalogId: catalogId,
      catalogName: catalogName,
      id: id,
      region: props.dataset?.IdInfo?.Region,
      dataLakePrincipal:
        props.dataset?.ClusterIdentifier != null
          ? getConsumerDataLakePrincipal(REDSHIFT_RESOURCE, undefined, props.activeWorkspace.accountId)
          : getConsumerDataLakePrincipal(LAKEFORMATION_RESOURCE, principal, props.activeWorkspace.accountId),
    };
    return item;
  };

  let flattenFineGrainAccess = async (selectedPermission) => {
    return {
      catalogDisplayName: selectedPermission.catalogName,
      catalogId: selectedPermission.catalogId,
      clusterId: selectedPermission.clusterId,
      databaseName: selectedPermission.databaseName,
      schemaName: selectedPermission.schemaName,
      tableName: selectedPermission.tableName,
      region: props.dataset?.IdInfo?.Region,
      dataOwnerRole: getFoundryRoleARN(props.dataset?.IdInfo?.Region, selectedPermission.catalogId),
      owner: selectedPermission.ownerId,
      itemType: FGA_POLICY_TYPE_PREFIX,
      permissionId: selectedPermission.permissionId,
      fgapName: selectedPermission.fgapName,
      id: selectedPermission.id,
      dataLakePrincipal:
        props.dataset?.ClusterIdentifier != null
          ? getConsumerDataLakePrincipal(REDSHIFT_RESOURCE, undefined, props.activeWorkspace.accountId)
          : getConsumerDataLakePrincipal(LAKEFORMATION_RESOURCE, principal, props.activeWorkspace.accountId),
    };
  };

  const handleConfirm = async () => {
    if (isGalaxiPermission && props.dataset?.IdInfo.DataSourceId == GALAXI_DATASOURCE_ID) {
      if (groupServiceSupported && !onboardedToGalaxi) {
        window.open('https://w.amazon.com/bin/view/AWS/WWRO/BigDataPlatform/GalaxiAccessManager/Onboarding/', '_blank');
      } else if (selectedPermission) {
        //selected group is selectedPermission?.value
        //need to append it to url
        window.open(
          getGalaxiSubscriptionURL(
            props.dataset?.PrimaryOwner?.slice(4),
            props.dataset?.IdInfo.DatabaseName,
            props.dataset?.IdInfo.TableName,
          ),
          '_blank',
        );
      } else {
        window.open(
          getGalaxiSubscriptionURL(
            props.dataset?.PrimaryOwner?.slice(4),
            props.dataset?.IdInfo.DatabaseName,
            props.dataset?.IdInfo.TableName,
          ),
          '_blank',
        );
      }
      return;
    }

    if (isGalaxiPermission && props.dataset?.IdInfo.DataSourceId != GALAXI_DATASOURCE_ID) {
      let activeGroupInfo: GetGroupResult;
      if (props.activeGroup) {
        activeGroupInfo = await getGroupInfo({
          groupId: props.activeGroup,
        });
      }
      const ticketUrl: URL = constructTicketUrlWithParams({
        category: GALAXI_NEW_DATA_ACCESS_CATEGORY,
        type: GALAXI_NEW_DATA_ACCESS_TYPE,
        item: GALAXI_NEW_DATA_ACCESS_ITEM,
        datasetName: props.dataset?.IdInfo.TableName ?? '',
        databaseName: props.dataset.IdInfo.DatabaseName ?? '',
        requesterTeamName: selectedPermission?.teamName ?? activeGroupInfo?.teamInfo?.Name ?? '',
        requesterGAMGroupName: selectedPermission?.value ?? '',
        requesterEmail: activeGroupInfo?.teamInfo?.DistributionList ?? '',
      });
      window.open(ticketUrl);

      return;
    }

    if (
      props.dataset.ClusterIdentifier == null &&
      ((selectedPermission == undefined && selectedPermissions == undefined) ||
        (selectedPrincipalType.value === 'iamRoleUserAccess' && !validateIAMPrincipal()))
    ) {
      return;
    }

    if (selectedPermissions != undefined && selectedPermission == undefined) {
      let cartItems = [];
      for (let permission of selectedPermissions) {
        if (permission.type == FGA_POLICY_TYPE_PREFIX) {
          let cartItem = await flattenFineGrainAccess(permission);
          cartItems.push(cartItem);
        }
      }
      props.addToCart(cartItems);
      props.dismiss();
      return;
    }

    if (selectedPermission.type == RESOURCE_ITEM) {
      let ds = await flattenDatasetAccess(selectedPermission);
      props.addToCart([ds]);
    } else if (selectedPermission.type == TAG_ITEM) {
      let cartItem = await flattenTagAccess(selectedPermission);
      props.addToCart([cartItem]);
    } else if (selectedPermission.type == FGA_POLICY_TYPE_PREFIX) {
      let cartItem = await flattenFineGrainAccess(selectedPermission);
      props.addToCart([cartItem]);
    }
    props.dismiss();
  };

  const transferResources = async (selectedPermissions: []) => {
    setLoadingResources(true);
    let res = [];

    if (selectedPermissions == undefined) {
      return res;
    }

    for (let selectedPermission of selectedPermissions) {
      if (selectedPermission['type'] == FGA_POLICY_TYPE_PREFIX) {
        res.push({
          type: FGA_POLICY_TYPE_PREFIX,
          resource: `${selectedPermission['fgapName']}`,
          resourceLink: createFgaPolicyViewLink(selectedPermission['id']),
        });
      }
    }
    setItems(res);
    setLoadingResources(false);
  };

  const transferResource = async (selectedPermission) => {
    setLoadingResources(true);
    let res = [];

    if (selectedPermission == undefined) {
      return res;
    }

    //named resource
    if (selectedPermission?.type == RESOURCE_ITEM) {
      res.push({
        type: DATASET_RESOURCE_TYPE,
        resource: `${selectedPermission.catalogName}/${selectedPermission.databaseName}/${selectedPermission.datasetName}`,
        resourceLink: createDatasetDetailLink(props.dataset?.Id),
      });
      setItems(res);
    } else if (selectedPermission.type == TAG_ITEM) {
      //tag resource
      let getTaggedResourcesResponse = await getTaggedResources({
        tagPair: selectedPermission.tagKey + ':' + selectedPermission.tagValue,
        catalogId: selectedPermission.catalogId,
        region: props.dataset?.IdInfo?.Region,
        type: props.dataset.ClusterIdentifier != null ? REDSHIFT_TAG_TYPE : LAKEFORMATION_TAG_TYPE,
      });
      for (let resource of getTaggedResourcesResponse.resources) {
        if (resource.clusterId != null) {
          if (resource.resourceType == DATASET_RESOURCE_TYPE) {
            res.push({
              type: resource.resourceType,
              resource: `${selectedPermission.catalogName}.${resource.clusterId}.${resource.databaseName}.${resource.schemaName}.${resource.resourceName}`,
              resourceLink: createDatasetDetailLink(resource.resourceId),
            });
          } else if (resource.resourceType == SCHEMA_RESOURCE_TYPE) {
            res.push({
              type: resource.resourceType,
              resource: `${selectedPermission.catalogName}.${resource.clusterId}.${resource.databaseName}.${resource.resourceName}`,
              resourceLink: createSchemaDetailLink(
                resource.catalogId,
                resource.clusterId,
                resource.databaseName,
                resource.resourceName,
                resource.region,
              ),
            });
          }
        } else {
          if (resource.resourceType == DATASET_RESOURCE_TYPE) {
            res.push({
              type: resource.resourceType,
              resource: `${selectedPermission.catalogName}/${resource.databaseName}/${resource.resourceName}`,
              resourceLink: createDatasetDetailLink(resource.resourceId),
            });
          } else if (resource.resourceType == DATABASE_RESOURCE_TYPE) {
            res.push({
              type: resource.resourceType,
              resource: `${selectedPermission.catalogName}/${resource.databaseName}`,
              resourceLink: createGlueDatabaseDetailLink(resource.catalogId, resource.databaseName, resource.region),
            });
          }
        }
      }
      setItems(res);
    }
    setLoadingResources(false);
  };

  const { items } = useCollection(allItems, {
    filtering: {
      empty: <EmptyState title='no resources attached' subtitle='No resources to display' />,
    },
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const validateIAMPrincipal = () => {
    let errorText = !isValidPrincipalNotRoot(principal)
      ? 'Invalid IAM principal ARN'
      : selectedPermission.principals.filter((dataLakePrincipal) => dataLakePrincipal === principal).length > 0
      ? 'Principal already consumed or is in your cart'
      : undefined;
    setPrincipalErrorText(errorText);
    return errorText === undefined;
  };

  const displayResource = () => {
    return (
      <Table
        loading={loadingResources}
        columnDefinitions={[
          {
            id: 'resource',
            header: 'Resource',
            cell: (item) => <Link to={item.resourceLink}>{item.resource}</Link>,
          },
          {
            id: 'type',
            header: 'Resource type',
            cell: (item) => item.type,
          },
        ]}
        items={items}
        loadingText='Loading resources'
        sortingDisabled
        header={<Header> Resources </Header>}
      />
    );
  };

  const handleChange = (e) => {
    if (props.selectedPermissionType == FGA_POLICY_TYPE_PREFIX) {
      setSelectedPermissions(e.detail.selectedOptions);
      setSelectedPermission(undefined);
      const options: SelectProps.Option[] = [
        {
          label: 'Workspace access (Entire account)',
          value: 'workspaceAccess',
          disabled: e.detail.selectedOptions.filter((option) => option.workspaceAccess).length > 0,
        },
        {
          label: 'IAM role/user access',
          value: 'iamRoleUserAccess',
        },
      ];
      setDropDownPrincipalOptions(options);
      setSelectedPrincipalType(options[0]);
      transferResources(e.detail.selectedOptions);
    } else if (isGalaxiPermission) {
      setSelectedPermissions(e.detail.selectedOptions);
      setSelectedPermission(e.detail.selectedOption);
      setSelectedPrincipalType(undefined);
    } else {
      setSelectedPermission(e.detail.selectedOption);
      setSelectedPermissions(undefined);
      const options: SelectProps.Option[] = [
        {
          label: 'Workspace access (Entire account)',
          value: 'workspaceAccess',
          disabled: e.detail.selectedOption.workspaceAccess,
        },
        {
          label: 'IAM role/user access',
          value: 'iamRoleUserAccess',
        },
      ];
      setDropDownPrincipalOptions(options);
      setSelectedPrincipalType(options[0]);
      transferResource(e.detail.selectedOption);
    }
  };

  const getDropDownItemsBasedOnSelectionType = () => {
    if (props.selectedPermissionType) {
      switch (props.selectedPermissionType) {
        case TAG_ITEM:
          return props.allPermissionDropdownOptions?.filter((dropdownOption) => {
            return dropdownOption.type == TAG_ITEM;
          });
        case FGA_POLICY_TYPE_PREFIX:
          return props.allPermissionDropdownOptions?.filter((dropdownOption) => {
            return dropdownOption.type == FGA_POLICY_TYPE_PREFIX;
          });
        case GALAXI_TYPE:
          return props.allPermissionDropdownOptions?.filter((dropdownOption) => {
            return dropdownOption.type == GALAXI_TYPE;
          });
        default:
          return props.allPermissionDropdownOptions?.filter((dropdownOption) => {
            return dropdownOption.type == RESOURCE_ITEM;
          });
      }
    }
  };
  useEffect(() => {
    getDropDownItemsBasedOnSelectionType();
    setSelectedPermission(undefined);
    setSelectedPermissions(undefined);
    setItems([]);
  }, [props.selectedPermissionType]);

  const getPermissionTypeDescription = (selectedPermissionType) => {
    switch (selectedPermissionType) {
      case RESOURCE_ITEM:
        return 'Select resource access permission provided by the data provider';
      case TAG_ITEM:
        return 'Select tag access permission defined by the data provider';
      case FGA_POLICY_TYPE_PREFIX:
        return 'Select fine grain access policies permission defined by the data provider';
      default:
        return 'Select data permission defined by the data provider';
    }
  };

  const getGalaxiGroupForm = () => {
    let alertMsg = `You will be redirected outside of ${WEBSITE_NAME} to continue your subscription.`;
    if (!onboardedToGalaxi) {
      alertMsg = 'To subscribe to this dataset, you must first onboard on the Galaxi platform.';
    }
    return (
      (onboardedToGalaxi && (
        <FormField
          label='Target galaxi group'
          description='Select the target galaxi group for your subscription request'
        >
          <SpaceBetween size='m'>
            <Select
              selectedOption={selectedPermission}
              onChange={handleChange}
              options={getDropDownItemsBasedOnSelectionType()}
              selectedAriaLabel='Selected'
              empty={
                <EmptyState
                  title='No galaxi groups found'
                  subtitle='To subscribe to this dataset, you must first onboard on the Galaxi platform.'
                />
              }
              statusType={loadingResources ? 'loading' : 'finished'}
              loadingText='Loading access options'
            />
            <Alert statusIconAriaLabel='Warning' type='warning'>
              {alertMsg}
            </Alert>
          </SpaceBetween>
        </FormField>
      )) || (
        <Alert statusIconAriaLabel='Warning' type='warning'>
          {alertMsg}
        </Alert>
      )
    );
  };

  return (
    <Modal
      onDismiss={props.dismiss}
      visible={props.visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={props.dismiss}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleConfirm}>
              {buttonText}
            </Button>
          </SpaceBetween>
        </Box>
      }
      size='medium'
      header={'Subscribe to dataset'}
    >
      <ColumnLayout>
        {props.selectedPermissionType && !isGalaxiPermission && (
          <FormField
            label='Select permission(s)'
            description={getPermissionTypeDescription(props.selectedPermissionType)}
          >
            {props.selectedPermissionType != FGA_POLICY_TYPE_PREFIX && (
              <Select
                selectedOption={selectedPermission}
                onChange={handleChange}
                options={getDropDownItemsBasedOnSelectionType()}
                selectedAriaLabel='Selected'
                empty='No access options'
                statusType={loadingResources ? 'loading' : 'finished'}
                loadingText='Loading access options'
                data-testid={'select-permissions'}
              />
            )}
            {props.selectedPermissionType == FGA_POLICY_TYPE_PREFIX && (
              <Multiselect
                options={getDropDownItemsBasedOnSelectionType()}
                selectedAriaLabel='Selected'
                selectedOptions={selectedPermissions}
                onChange={handleChange}
                empty='No access options'
                statusType={loadingResources ? 'loading' : 'finished'}
                loadingText='Loading access options'
              />
            )}
          </FormField>
        )}
        {props.selectedPermissionType && isGalaxiPermission && (
          <>
            {!groupServiceSupported && (
              <FormField>
                <Alert statusIconAriaLabel='Warning' type='warning'>
                  You will be redirected outside of {WEBSITE_NAME} to continue your subscription.
                </Alert>
              </FormField>
            )}
            {groupServiceSupported && getGalaxiGroupForm()}
          </>
        )}

        {(selectedPermission !== undefined || selectedPermissions !== undefined) &&
          props.dataset?.ClusterIdentifier == undefined &&
          props.selectedPermissionType != GALAXI_TYPE && (
            <FormField label='Select Lake Formation access principal type'>
              <Select
                selectedOption={selectedPrincipalType}
                onChange={(e) => {
                  setSelectedPrincipalType(e.detail.selectedOption);
                }}
                options={dropdownPrincipalOptions}
                statusType={loadingResources ? 'loading' : 'finished'}
                loadingText='Loading access options'
                data-testid={'select-permissions-principal-type'}
              />
            </FormField>
          )}
        {(selectedPermission !== undefined || selectedPermissions !== undefined) &&
          selectedPrincipalType !== undefined &&
          props.selectedPermissionType != GALAXI_TYPE &&
          selectedPrincipalType.value === 'iamRoleUserAccess' && (
            <FormField
              label='IAM User/Role'
              description='Enter the ARN for the IAM user or role'
              errorText={principalErrorText}
            >
              <Input
                ariaRequired={true}
                placeholder={'arn:aws:iam::123456789012:role/AWSDGSFoundry-ManagementRole-DO-NOT-DELETE'}
                value={principal}
                onChange={(e) => {
                  setPrincipalErrorText(undefined);
                  setPrincipal(e.detail.value);
                }}
              />
            </FormField>
          )}
        {(selectedPermission !== undefined || selectedPermissions !== undefined) &&
          !isGalaxiPermission &&
          displayResource()}
      </ColumnLayout>
    </Modal>
  );
};
