import * as React from 'react';
import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import {
  HC_METADATA_FORM_RESOURCE,
  hcGlossaryResourceTypes,
  LAKE_FORMATION_DATASOURCE_ID,
  LAKEFORMATION_RESOURCE,
  REDSHIFT_DATASOURCE_ID,
  REDSHIFT_RESOURCE,
} from 'src/commons/constants';
import {
  associateGlossaryToResource,
  getDataSetsFromHybridCatalogDatabase,
  getHybridCatalogDatabases,
  getLatestDataSetDetail,
  getSchemasForDatabase,
  listCatalogs,
  listMetadataForms,
} from 'src/api/catalog';
import { useEffect, useState } from 'react';
import {
  CatalogInfo,
  CatalogInfoList,
  Column,
  ColumnList,
  DatabaseInfo,
  DatabaseInfoList,
  DataSetList,
  HybridCatalogDataSet,
  MetadataFormDetails,
  MetadataFormsList,
  SchemaInfo,
  SchemaInfoList,
} from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { getRegion } from 'src/api/config';
import { convertToDgsHCResourceArn } from 'src/commons/common';

export interface AssociateGlossaryModalProps {
  glossary: any;
  setNotification: (header: any, message: string) => void;
  close: () => void;
  visible: boolean;
  ownerId?: string;
  handleRefresh?: () => void;
}

export const AssociateGlossaryModal = (props: AssociateGlossaryModalProps) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [resourceType, setResourceType] = useState(undefined);
  const [metadataFormsAsOptions, setMetadataFormsAsOptions] = useState(undefined);
  const [catalogListAsOptions, setCatalogListAsOptions] = useState(undefined);
  const [databaseListAsOptions, setDatabaseListAsOptions] = useState(undefined);
  const [schemaListAsOptions, setSchemaListAsOptions] = useState(undefined);
  const [tableListAsOptions, setTableListAsOptions] = useState(undefined);
  const [columnListAsOptions, setColumnListAsOptions] = useState(undefined);
  const [metadataFormIdAsOption, setMetadataFormIdAsOption] = useState(null);
  const [catalogIdAsOption, setCatalogIdAsOption] = useState(null);
  const [databaseNameAsOption, setDatabaseNameAsOption] = useState(null);
  const [schemaNameAsOption, setSchemaNameAsOption] = useState(null);
  const [tableNameAsOption, setTableNameAsOption] = useState(null);
  const [columnNameAsOption, setColumnNameAsOption] = useState(null);
  const [dataAccessRole, setDataAccessRole] = useState(null);
  const ownerId = props.ownerId;
  const glossaryId = props.glossary?.GlossaryId;
  const glossaryOwnerId = props.glossary?.OwnerId;

  useEffect(() => {
    setResourceType(undefined);
    setMetadataFormsAsOptions(undefined);
    setCatalogListAsOptions(undefined);
    setDatabaseListAsOptions(undefined);
    setSchemaListAsOptions(undefined);
    setTableListAsOptions(undefined);
    setColumnListAsOptions(undefined);
    setMetadataFormIdAsOption(null);
    setCatalogIdAsOption(null);
    setDatabaseNameAsOption(null);
    setSchemaNameAsOption(null);
    setTableNameAsOption(null);
    setColumnNameAsOption(null);
  }, [glossaryId]);

  useEffect(() => {
    if (resourceType && (resourceType.value === LAKEFORMATION_RESOURCE || resourceType.value === REDSHIFT_RESOURCE)) {
      getCatalogsFromOmni();
    }
    if (resourceType && resourceType.value === HC_METADATA_FORM_RESOURCE) {
      getMetaDataFormsForOwner();
    }
    setMetadataFormsAsOptions(undefined);
    setCatalogListAsOptions(undefined);
    setDatabaseListAsOptions(undefined);
    setSchemaListAsOptions(undefined);
    setTableListAsOptions(undefined);
    setColumnListAsOptions(undefined);
    setMetadataFormIdAsOption(null);
    setCatalogIdAsOption(null);
    setDatabaseNameAsOption(null);
    setSchemaNameAsOption(null);
    setTableNameAsOption(null);
    setColumnNameAsOption(null);
  }, [resourceType]);

  useEffect(() => {
    if (catalogIdAsOption) {
      getDatabasesFromSelectedCatalog();
    }
    setDatabaseListAsOptions(undefined);
    setSchemaListAsOptions(undefined);
    setTableListAsOptions(undefined);
    setColumnListAsOptions(undefined);
    setDatabaseNameAsOption(null);
    setSchemaNameAsOption(null);
    setTableNameAsOption(null);
    setColumnNameAsOption(null);
    setDataAccessRole(catalogIdAsOption?.dataAccessRole);
  }, [catalogIdAsOption]);

  useEffect(() => {
    if (databaseNameAsOption) {
      getSchemasFromSelectedDatabase();
    }
    setSchemaListAsOptions(undefined);
    setTableListAsOptions(undefined);
    setColumnListAsOptions(undefined);
    setSchemaNameAsOption(null);
    setTableNameAsOption(null);
    setColumnNameAsOption(null);
    setDataAccessRole(databaseNameAsOption?.dataAccessRole);
  }, [databaseNameAsOption]);

  useEffect(() => {
    if (schemaNameAsOption || databaseNameAsOption) {
      getTablesFromSchemaOrDatabase();
    }
    setTableListAsOptions(undefined);
    setColumnListAsOptions(undefined);
    setTableNameAsOption(null);
    setColumnNameAsOption(null);
    if (schemaNameAsOption != null) {
      setDataAccessRole(schemaNameAsOption?.dataAccessRole);
    }
  }, [databaseNameAsOption, schemaNameAsOption]);

  useEffect(() => {
    if (tableNameAsOption) {
      getLatestDatasetDetailOfDataset(tableNameAsOption);
    }
    setColumnListAsOptions(undefined);
    setColumnNameAsOption(null);
    setDataAccessRole(tableNameAsOption?.dataAccessRole);
  }, [tableNameAsOption]);

  const reset = () => {
    setResourceType(undefined);
    setMetadataFormsAsOptions(undefined);
    setCatalogListAsOptions(undefined);
    setDatabaseListAsOptions(undefined);
    setSchemaListAsOptions(undefined);
    setTableListAsOptions(undefined);
    setColumnListAsOptions(undefined);
    setMetadataFormIdAsOption(undefined);
    setCatalogIdAsOption(undefined);
    setDatabaseNameAsOption(undefined);
    setSchemaNameAsOption(undefined);
    setTableNameAsOption(undefined);
    setColumnNameAsOption(undefined);
  };

  const getMetaDataFormsForOwner = async () => {
    try {
      let forms = [];
      let listMetadataFormsResult = await listMetadataForms({
        OwnerId: ownerId,
      });
      forms = listMetadataFormsResult.MetadataFormsList;
      while (listMetadataFormsResult.NextToken != undefined) {
        listMetadataFormsResult = await listMetadataForms({
          OwnerId: ownerId,
          NextToken: listMetadataFormsResult.NextToken,
        });
        forms.push(...listMetadataFormsResult.MetadataFormsList);
      }
      let optionsReturned = convertMetadataFormsToOptions(forms);
      setMetadataFormsAsOptions(optionsReturned);
    } catch (err) {
      props.setNotification('error', err.message);
    }
  };

  const convertMetadataFormsToOptions = (metadataForms: MetadataFormsList): SelectProps.Option[] => {
    return metadataForms.map((metadataForm: MetadataFormDetails) => {
      return {
        label: `${metadataForm.MetadataFormId} (${metadataForm.Name})`,
        value: metadataForm.MetadataFormId,
        status: metadataForm.Status,
        formType: metadataForm.FormType,
        formOwnerId: metadataForm.OwnerId,
      };
    });
  };

  const getCatalogsFromOmni = async () => {
    try {
      let totalCatalogList = [];
      let getCatalogsResult = await listCatalogs({
        Filter: null,
      });
      totalCatalogList.push(...getCatalogsResult.CatalogInfoList);
      while (getCatalogsResult.NextToken != undefined) {
        getCatalogsResult = await listCatalogs({
          Filter: null,
          NextToken: getCatalogsResult.NextToken,
        });
        totalCatalogList.push(...getCatalogsResult.CatalogInfoList);
      }
      // get catalogs for current owner
      const currentOwnerCatalogs = totalCatalogList.filter((catalogInfo) => {
        return catalogInfo != undefined && catalogInfo.Owner === ownerId;
      });
      let catalogsBasedOnType = [];
      if (resourceType?.value === LAKEFORMATION_RESOURCE) {
        catalogsBasedOnType = currentOwnerCatalogs.filter((catalogInfo) => {
          return catalogInfo?.ClusterIdentifier === null;
        });
      } else {
        catalogsBasedOnType = currentOwnerCatalogs.filter((catalogInfo) => {
          return catalogInfo.ClusterIdentifier != null;
        });
      }
      let optionsReturned = convertCatalogInfosToOptions(catalogsBasedOnType);
      setCatalogListAsOptions(optionsReturned);
    } catch (err) {
      props.setNotification('error', err.message);
    }
  };

  const convertCatalogInfosToOptions = (catalogInfos: CatalogInfoList): SelectProps.Option[] => {
    return catalogInfos.map((catalogInfo: CatalogInfo) => {
      return {
        label: catalogInfo.ClusterIdentifier === null ? catalogInfo.CatalogId : catalogInfo.ClusterIdentifier,
        value: catalogInfo.CatalogId,
        region: catalogInfo.Region,
        clusterId: catalogInfo.ClusterIdentifier,
        dataAccessRole: catalogInfo.DataAccessRole,
      };
    });
  };

  const getDatabasesFromSelectedCatalog = async () => {
    try {
      // get all databases
      let totalDatabaseList = [];
      let getDatabasesResult = await getHybridCatalogDatabases({
        DatabaseKeyList: null,
      });
      totalDatabaseList.push(...getDatabasesResult.DatabaseInfoList);
      while (getDatabasesResult.NextToken != undefined) {
        getDatabasesResult = await getHybridCatalogDatabases({
          DatabaseKeyList: null,
          NextToken: getDatabasesResult.NextToken,
        });
        totalDatabaseList.push(...getDatabasesResult.DatabaseInfoList);
      }
      // get databases for current owner
      const currentOwnerDatabases = totalDatabaseList.filter((databaseInfo) => {
        return databaseInfo?.Owners.includes(ownerId) && databaseInfo?.CatalogId == catalogIdAsOption?.value;
      });
      let databasesBasedOnType = [];
      if (resourceType?.value === LAKEFORMATION_RESOURCE) {
        databasesBasedOnType = currentOwnerDatabases.filter((databaseInfo) => {
          return databaseInfo.ClusterIdentifier === null;
        });
      } else {
        databasesBasedOnType = currentOwnerDatabases.filter((databaseInfo) => {
          return databaseInfo.ClusterIdentifier === catalogIdAsOption?.clusterId;
        });
      }
      setDatabaseListAsOptions(convertDatabaseInfosToOptions(databasesBasedOnType));
    } catch (err) {
      props.setNotification('error', err.message);
    }
  };

  const convertDatabaseInfosToOptions = (databaseInfos: DatabaseInfoList): SelectProps.Option[] => {
    return databaseInfos.map((databaseInfo: DatabaseInfo) => {
      return {
        label: databaseInfo.DatabaseName,
        value: databaseInfo.DatabaseName,
        region: databaseInfo.Region,
        catalogId: databaseInfo.CatalogId,
        clusterId: databaseInfo.ClusterIdentifier,
        dataAccessRole: databaseInfo.DataAccessRole,
      };
    });
  };

  const getSchemasFromSelectedDatabase = async () => {
    if (databaseNameAsOption && databaseNameAsOption?.clusterId) {
      try {
        // get schemas for selected database
        let schemaList = [];
        let getSchemasForDatabaseResult = await getSchemasForDatabase({
          DatabaseName: databaseNameAsOption?.value,
          CatalogId: databaseNameAsOption?.catalogId,
          DataSourceId: REDSHIFT_DATASOURCE_ID,
          ClusterIdentifier: databaseNameAsOption?.clusterId,
          Region: getRegion(),
        });
        schemaList.push(...getSchemasForDatabaseResult.SchemaInfoList);
        while (getSchemasForDatabaseResult.NextToken != undefined) {
          getSchemasForDatabaseResult = await getSchemasForDatabase({
            DatabaseName: databaseNameAsOption?.value,
            CatalogId: databaseNameAsOption?.catalogId,
            DataSourceId: REDSHIFT_DATASOURCE_ID,
            ClusterIdentifier: databaseNameAsOption?.clusterId,
            Region: getRegion(),
            NextToken: getSchemasForDatabaseResult.NextToken,
          });
          schemaList.push(...getSchemasForDatabaseResult.SchemaInfoList);
        }
        setSchemaListAsOptions(convertSchemaInfosToOptions(schemaList));
      } catch (err) {
        props.setNotification('error', err.message);
      }
    }
  };

  const convertSchemaInfosToOptions = (schemaInfos: SchemaInfoList): SelectProps.Option[] => {
    return schemaInfos.map((schemaInfo: SchemaInfo) => {
      return {
        label: schemaInfo.Schema,
        value: schemaInfo.Schema,
        region: schemaInfo.Region,
        catalogId: schemaInfo.CatalogId,
        databaseName: schemaInfo.DatabaseName,
        clusterId: schemaInfo.ClusterIdentifier,
        dataAccessRole: schemaInfo.DataAccessRole,
      };
    });
  };

  const getTablesFromSchemaOrDatabase = async () => {
    try {
      let tableList = [];
      let getTablesFromDatabaseResult = await getDataSetsFromHybridCatalogDatabase({
        DatabaseName: databaseNameAsOption?.value,
        CatalogId: catalogIdAsOption?.value,
        DataSourceId:
          resourceType?.value === LAKEFORMATION_RESOURCE ? LAKE_FORMATION_DATASOURCE_ID : REDSHIFT_DATASOURCE_ID,
        ClusterIdentifier: schemaNameAsOption != null ? schemaNameAsOption?.clusterId : null,
        SchemaName: schemaNameAsOption != null ? schemaNameAsOption?.value : null,
        Region: getRegion(),
      });
      tableList.push(...getTablesFromDatabaseResult.DataSetList);
      while (getTablesFromDatabaseResult.NextToken != undefined) {
        getTablesFromDatabaseResult = await getDataSetsFromHybridCatalogDatabase({
          DatabaseName: databaseNameAsOption?.value,
          CatalogId: catalogIdAsOption?.value,
          DataSourceId:
            resourceType.value === LAKEFORMATION_RESOURCE ? LAKE_FORMATION_DATASOURCE_ID : REDSHIFT_DATASOURCE_ID,
          ClusterIdentifier: schemaNameAsOption != null ? schemaNameAsOption?.clusterId : null,
          SchemaName: schemaNameAsOption != null ? schemaNameAsOption?.value : null,
          Region: getRegion(),
          NextToken: getTablesFromDatabaseResult.NextToken,
        });
        tableList.push(...getTablesFromDatabaseResult.DataSetList);
      }
      setTableListAsOptions(convertDatasetListToOptions(tableList));
    } catch (err) {
      props.setNotification('error', err.message);
    }
  };

  const convertDatasetListToOptions = (datasetList: DataSetList): SelectProps.Option[] => {
    return datasetList.map((dataset: HybridCatalogDataSet) => {
      return {
        label: dataset.IdInfo.TableName,
        value: dataset.IdInfo.TableName,
        region: dataset.IdInfo.Region,
        catalogId: dataset.IdInfo.CatalogId,
        databaseName: dataset.IdInfo.DatabaseName,
        schemaName: dataset.IdInfo.SchemaName,
        clusterId: dataset.IdInfo.ClusterIdentifier,
        datasetId: dataset.Id,
        dataAccessRole: dataset.DataAccessRole,
      };
    });
  };

  const getLatestDatasetDetailOfDataset = async (tableOption) => {
    let dataSetId = tableOption?.datasetId;
    try {
      let getLatestDatasetDetail = await getLatestDataSetDetail({
        Filter: {
          IdList: [dataSetId],
        },
      });
      if (getLatestDatasetDetail.DataSetDetailList.length > 0) {
        const datasetDetail = getLatestDatasetDetail.DataSetDetailList[0];
        setColumnListAsOptions(convertTableColumnListToOptions(datasetDetail.Columns));
      }
    } catch (err) {
      props.setNotification('error', err.message);
    }
  };

  const convertTableColumnListToOptions = (columnList: ColumnList): SelectProps.Option[] => {
    return columnList.map((column: Column) => {
      return {
        label: column.Name,
        value: column.Name,
        type: column.Type,
        description: column.Type,
        comment: column.Comment,
      };
    });
  };

  const handleAssociateGlossary = async () => {
    setButtonLoading(true);
    try {
      let resourceArn = convertToDgsHCResourceArn(
        catalogIdAsOption?.value,
        catalogIdAsOption?.clusterId,
        databaseNameAsOption?.value,
        schemaNameAsOption?.value,
        tableNameAsOption?.value,
        columnNameAsOption?.value,
        dataAccessRole,
      );
      let associateGlossaryRequest;
      if (resourceType.value === HC_METADATA_FORM_RESOURCE) {
        associateGlossaryRequest = {
          GlossaryId: glossaryId,
          OwnerId: glossaryOwnerId,
          FormOwnerId: metadataFormIdAsOption.formOwnerId,
          ResourceArn: metadataFormIdAsOption.value,
        };
      } else {
        associateGlossaryRequest = {
          GlossaryId: glossaryId,
          OwnerId: glossaryOwnerId,
          ResourceArn: resourceArn,
        };
      }
      const associateGlossaryResult = await associateGlossaryToResource(associateGlossaryRequest);
      if (associateGlossaryResult.Message == 'Success') {
        props.setNotification('success', 'Successfully associated to the glossary');
      } else {
        props.setNotification('error', `Failure in associating form :${associateGlossaryResult.Message}`);
      }
    } catch (err) {
      props.setNotification('error', `Error in associating glossary :${err.message}`);
    } finally {
      setButtonLoading(false);
      props.close();
      props.handleRefresh();
      reset();
    }
  };

  const enableAssociateButton = () => {
    if (resourceType?.value == HC_METADATA_FORM_RESOURCE) {
      return metadataFormIdAsOption;
    } else {
      return catalogIdAsOption;
    }
  };

  return (
    <Modal
      onDismiss={() => {
        props.close();
        reset();
      }}
      visible={props.visible}
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              variant='link'
              onClick={() => {
                props.close();
                reset();
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              disabled={!enableAssociateButton()}
              onClick={handleAssociateGlossary}
              loading={buttonLoading}
            >
              {'Associate'}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header='Associate business glossary to resource'
    >
      <ColumnLayout>
        <FormField label={'Glossary ID'}>
          <Input ariaRequired={true} value={glossaryId} readOnly={true} disabled />
        </FormField>
        <FormField
          label={<div>Resource type</div>}
          description='Select the type of resource to be associated with the glossary.'
        >
          <Select
            name='resourceType'
            selectedOption={resourceType}
            ariaRequired={true}
            placeholder='Choose a type'
            onChange={({ detail }) => setResourceType(detail.selectedOption)}
            options={hcGlossaryResourceTypes}
            selectedAriaLabel='Selected'
          />
        </FormField>
        {resourceType && resourceType.value === HC_METADATA_FORM_RESOURCE && (
          <>
            <FormField label={<div>Metadata form</div>} description='Select a metadata form'>
              <Select
                name='metadataForm'
                selectedOption={metadataFormIdAsOption}
                onChange={({ detail }) => setMetadataFormIdAsOption(detail.selectedOption)}
                options={metadataFormsAsOptions}
                selectedAriaLabel='Selected'
                empty={'No metadata forms found to associate'}
              />
            </FormField>
          </>
        )}
        {resourceType &&
          (resourceType.value === LAKEFORMATION_RESOURCE || resourceType.value === REDSHIFT_RESOURCE) && (
            <>
              <FormField
                label={resourceType.value === LAKEFORMATION_RESOURCE ? <div>Catalog ID</div> : <div>Cluster Id</div>}
                description={resourceType.value === LAKEFORMATION_RESOURCE ? 'Glue catalog' : 'Select a cluster'}
              >
                <Select
                  name='catalogId'
                  selectedOption={catalogIdAsOption}
                  onChange={({ detail }) => setCatalogIdAsOption(detail.selectedOption)}
                  options={catalogListAsOptions}
                  selectedAriaLabel='Selected'
                  empty={
                    resourceType.value === LAKEFORMATION_RESOURCE
                      ? 'Catalog not onboarded'
                      : 'No redshift clusters found'
                  }
                />
              </FormField>
            </>
          )}
        {catalogIdAsOption && (
          <>
            <FormField label={<div>Database name</div>} description='Select a database'>
              <Select
                name='databaseName'
                selectedOption={databaseNameAsOption}
                onChange={({ detail }) => setDatabaseNameAsOption(detail.selectedOption)}
                statusType={databaseListAsOptions ? null : 'loading'}
                loadingText='Fetching databases'
                empty='No databases found'
                disabled={false}
                options={databaseListAsOptions}
                selectedAriaLabel='Selected'
              />
            </FormField>
          </>
        )}
        {/*only for redshift catalogs*/}
        {catalogIdAsOption && resourceType?.value === REDSHIFT_RESOURCE && (
          <FormField label={<div>Schema name</div>} description='Select a schema'>
            <Select
              name='schemaName'
              selectedOption={schemaNameAsOption}
              onChange={({ detail }) => setSchemaNameAsOption(detail.selectedOption)}
              options={schemaListAsOptions}
              statusType={schemaListAsOptions ? null : 'loading'}
              loadingText='Fetching schemas'
              empty='No schemas found'
              disabled={!databaseNameAsOption}
              selectedAriaLabel='Selected'
            />
          </FormField>
        )}
        {catalogIdAsOption && (
          <FormField label={<div>Table name</div>} description='Select a table'>
            <Select
              name='tableName'
              selectedOption={tableNameAsOption}
              onChange={({ detail }) => setTableNameAsOption(detail.selectedOption)}
              options={tableListAsOptions}
              statusType={tableListAsOptions ? null : 'loading'}
              loadingText='Fetching tables'
              empty='No tables found'
              disabled={!schemaNameAsOption && !databaseNameAsOption}
              selectedAriaLabel='Selected'
            />
          </FormField>
        )}
        {catalogIdAsOption && (
          <FormField label={<div>Column name</div>} description='Select a column'>
            <Select
              name='columnName'
              selectedOption={columnNameAsOption}
              onChange={({ detail }) => setColumnNameAsOption(detail.selectedOption)}
              options={columnListAsOptions}
              statusType={columnListAsOptions ? null : 'loading'}
              loadingText='Fetching columns'
              empty='No columns found'
              disabled={!tableNameAsOption}
              selectedAriaLabel='Selected'
            />
          </FormField>
        )}
      </ColumnLayout>
    </Modal>
  );
};
