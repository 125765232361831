import React, { useEffect, useState } from 'react';
import { ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';

import { getReportingTableById } from 'src/api/metricstore';

import { labeledTextFromList, ErrorAlert } from 'src/components/resourcesmanager/components';
import { Redirect } from 'react-router-dom';
import { dateString } from 'src/components/metricstore/helpers';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface ReportingTableDefinitionProps {
  setContentType: any;
  match: any;
}

export const ReportingTableDefinition = (props: ReportingTableDefinitionProps) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [reportingTableMetadata, setReportingTableMetadata] = useState(null);
  const [redirect] = useState(undefined);
  const [redirectParams] = useState({});

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  /**
   * Asynchronously fetches the reporting table definition from the metricstore API and updates the state.
   */
  const handleRefresh = async () => {
    setLoading(true);

    try {
      const reportingTableMetadataResponse = await getReportingTableById({
        id: props.match.params.id,
      });
      setReportingTableMetadata(reportingTableMetadataResponse);
    } catch (err) {
      console.log(err);
      setError('Error loading reporting table metadata');
    }
    setLoading(false);
  };

  if (redirect) {
    return <Redirect push to={{ pathname: redirect, state: redirectParams }} />;
  }

  return (
    <>
      {ErrorAlert(error)}

      <SpaceBetween size='l'>
        <Container header={<Header variant='h2'> {reportingTableMetadata?.name} </Header>}>
          <ColumnLayout columns={2} borders='horizontal'>
            {labeledTextFromList(
              [
                {
                  label: 'Id',
                  value: reportingTableMetadata?.id,
                  copiable: true,
                },
                {
                  label: 'Name',
                  value: reportingTableMetadata?.name,
                  copiable: true,
                },
                {
                  label: 'Description',
                  value: reportingTableMetadata?.description,
                  copiable: false,
                },
                {
                  label: 'Status',
                  value: reportingTableMetadata?.isArchived ? 'Inactive' : 'Active',
                  copiable: false,
                },
                {
                  label: 'Owner team',
                  value: reportingTableMetadata?.ownerTeam,
                  copiable: false,
                },
                {
                  label: 'Owner email',
                  value: reportingTableMetadata?.ownerEmail,
                  copiable: false,
                },
                {
                  label: 'Owner CTI',
                  value:
                    reportingTableMetadata?.ownerCTI?.category +
                    '/' +
                    reportingTableMetadata?.ownerCTI?.type +
                    '/' +
                    reportingTableMetadata?.ownerCTI?.item,
                  copiable: false,
                },
                {
                  label: 'Source query',
                  value: reportingTableMetadata?.sourceQuery,
                  copiable: false,
                },
                {
                  label: 'Tags',
                  value: reportingTableMetadata?.tags?.toString(),
                  copiable: false,
                },
                {
                  label: 'Last refreshed at',
                  value: dateString(reportingTableMetadata?.lastRefreshedAt),
                  copiable: false,
                },
                {
                  label: 'Created by',
                  value: reportingTableMetadata?.createdBy,
                  copiable: true,
                },
                {
                  label: 'Created at',
                  value: dateString(reportingTableMetadata?.createdAt),
                },
                {
                  label: 'Updated by',
                  value: reportingTableMetadata?.updatedBy,
                  copiable: true,
                },
                {
                  label: 'Updated at',
                  value: dateString(reportingTableMetadata?.updatedAt),
                },
                {
                  label: 'Version',
                  value: reportingTableMetadata?.version,
                  copiable: false,
                },
              ],
              loading,
            )}
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    </>
  );
};
