import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/notifications/common';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { getDataSourceDatabases, listDatabases } from 'src/api/catalog';
import { REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { registerStatus } from 'src/commons/common';

export interface GlueDatabasesProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  selectedDatabaseName: string;
  selectedDatabaseDescription: string;
  setSelectedDatabaseName: any;
  setSelectedDatabaseDescription: any;
  setSelectedDatabaseRegistered: any;
  clusterName: any;
  setNotifications: any;
  onDatabaseLoadFailure: any;
}

export const RedshiftDatabases = (props: GlueDatabasesProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingDatabases, setLoadingDatabases] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState(undefined);
  const [databaseDescription, setDatabaseDescription] = useState(undefined);

  const handleRefresh = async () => {
    setLoadingDatabases(true);
    let databaseMap = new Map();
    try {
      let getDataSourceDatabasesRequest = {
        DataSourceId: REDSHIFT_DATASOURCE_ID,
        CatalogId: props.activeWorkspace.accountId,
        ClusterIdentifier: props.clusterName,
        NextToken: null,
      };
      let databaseList = [];
      try {
        let getDataSourceDatabasesResponse = await getDataSourceDatabases(getDataSourceDatabasesRequest);
        databaseList = getDataSourceDatabasesResponse.DatabaseList;
        while (getDataSourceDatabasesResponse.NextToken != null) {
          getDataSourceDatabasesRequest.NextToken = getDataSourceDatabasesResponse.NextToken;
          getDataSourceDatabasesResponse = await getDataSourceDatabases(getDataSourceDatabasesRequest);
          databaseList.push(...getDataSourceDatabasesResponse.DatabaseList);
        }
        for (let database of databaseList) {
          databaseMap.set(database.Name, database);
        }
      } catch (err) {
        console.log('Exception when fetching Redshift databases', err);
        props.setNotifications([
          {
            type: 'error',
            content: `Database load error` + err.message,
            dismissible: true,
            onDismiss: () => props.setNotifications([]),
          },
        ]);
        props.onDatabaseLoadFailure();
      }
      let databases;
      databases = await listDatabases({});
      for (let database of databases.DatabaseInfoList) {
        if (
          database.CatalogId == props.activeWorkspace.accountId &&
          database.Region == props.activeWorkspace.region &&
          database.DataClassification == 'Public' &&
          database.DataAccessRole == props.activeWorkspace.workspaceRoleArn &&
          database.ClusterIdentifier == props.clusterName
        ) {
          if (databaseMap.has(database.DatabaseName)) {
            let cur = databaseMap.get(database.DatabaseName);
            cur.HCDescription = database.Description;
            cur.Registered = true;
          }
        }
      }
    } catch (err) {
      setLoadingDatabases(false);
      console.log('Exception loading HC databases ', err);
      props.setNotifications([
        {
          type: 'error',
          content: `Failed to register catalog. ` + err.message,
          dismissible: true,
          onDismiss: () => props.setNotifications([]),
        },
      ]);
    }
    setItems(Array.from(databaseMap.values()));
    setLoadingDatabases(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) => item.Name,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Register status',
      cell: (item) => registerStatus(item.Registered !== undefined),
      minWidth: 200,
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Database name',
          key: 'Name',
          groupValuesLabel: 'Database names',
        },
      ],
    },
  });

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedItem = selectedItems[selectedItems.length - 1];
    setSelectedDatabase(selectedItem);
    props.setSelectedDatabaseName(selectedItem?.Name);
    if (selectedItem?.HCDescription !== undefined) {
      props.setSelectedDatabaseDescription(selectedItem?.HCDescription);
      props.setSelectedDatabaseRegistered(true);
    } else {
      props.setSelectedDatabaseRegistered(false);
    }
    setDatabaseDescription(undefined);
  }, [collectionProps.selectedItems]);

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading databases...'
        loading={loadingDatabases}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Redshift Databases
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        selectionType='single'
        isItemDisabled={(item) => item.status == 'Registered'}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No databases</p>
          </div>
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingDatabases}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />

      <br />
      <br />

      {selectedDatabase !== undefined && !selectedDatabase.Registered && (
        <>
          <Container header={<Header variant='h2'>Database details</Header>}>
            <ColumnLayout>
              <FormField label='Database name'>
                <Input value={selectedDatabase.Name} disabled />
              </FormField>
              <FormField label='Database description'>
                {selectedDatabase.Registered == undefined ? (
                  <Input
                    placeholder={'Database description'}
                    value={databaseDescription}
                    onChange={(e) => {
                      setDatabaseDescription(e.detail.value);
                      props.setSelectedDatabaseDescription(e.detail.value);
                    }}
                  />
                ) : (
                  <Input placeholder={'Database description'} value={selectedDatabase.HCDescription} disabled />
                )}
              </FormField>
            </ColumnLayout>
          </Container>
        </>
      )}
    </>
  );
};
