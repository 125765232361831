export const testStrings = {
  manageDatasets: {
    tableHeader: 'ingestion-manageDatasets-tableHeader',
  },
  contractRegistration: {
    step1: {
      dataset: 'dataset-name-input',
      database: 'database-name-input',
      catalog: 'glue-catalog-input',
      resourceGroupName: 'rm-group-name',
    },
    step2: {
      dataSource: 'datasource-input',
      dataFormat: 'dataformat-input',
      delimiter: 'field-delimiter-input',
      bucket: 'bucket-input',
      prefix: 'prefix',
      ddbTable: 'ddb-input',
    },
    step3: {
      schema: 'schema-editor-textarea',
    },
    step4: {
      iamRole: 'IAM-role-input',
      publishtype: 'publish-type-select',
      primaryKey: 'primary-key-input',
    },
  },
};
