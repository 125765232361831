import {
  CollectionPreferencesProps,
  Table,
  Pagination,
  CollectionPreferences,
  PropertyFilter,
  Flashbar,
  Header,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { PageHeader } from 'src/components/notifications/common';
import { getWorkspacesCredentials, listDataPermissions } from 'src/api/permissions';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { RedshiftWSResources } from 'src/components/workspaces/analytics/redshiftWSResources';
import { EmptyState } from 'src/commons/EmptyState';
import { isValidWorkspace } from 'src/commons/validationUtils';
import { createGroupDetailLink, createWorkspaceDetailLink } from 'src/routes';
import {
  ANALYTICS_FEDERATION_TIME_OUT,
  CLUSTER_RUNNING_STATE,
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_REDSHIFT_TYPE,
  DATA_PERMISSION_STATUS_ACTIVE,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { Link } from '@amzn/awsui-components-react-v3/polaris';

export interface WorkspaceQueryEditorProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
  catalogMap: any;
  toggleHelp: any;
}

export const WorkspaceQueryEditor = (props: WorkspaceQueryEditorProps) => {
  const [allItems, setItems] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(undefined);
  const [loadingResource, setLoadingResource] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [ownRegisteredCluster, setOwnRegisteredCluster] = useState(false);
  let workspaceMap = new Map();

  const checkHavingRegisteredRedshiftCluster = async () => {
    let registeredRunningClusterList = [];
    if (props.activeWorkspace.workspaceConfig.workspaceRedshiftResources != null) {
      registeredRunningClusterList = props.activeWorkspace.workspaceConfig.workspaceRedshiftResources.filter(
        (cluster) => cluster.clusterStatus == CLUSTER_RUNNING_STATE,
      );
    }
    if (registeredRunningClusterList.length > 0) {
      setOwnRegisteredCluster(true);
    }
  };

  const fetchDataPermissions = async () => {
    let listDataPermissionsRequest = {
      ownerId: props.activeWorkspace.workspaceId,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      option: DATA_PERMISSION_CONSUMER_OPTION,
      type: DATA_PERMISSION_REDSHIFT_TYPE,
      nextToken: null,
    };
    let itemList = [];
    let response = await listDataPermissions(listDataPermissionsRequest);
    let dataPermissions = [...response.dataPermissionList];
    //Loop and get remaining tables
    while (response.nextToken != null) {
      listDataPermissionsRequest.nextToken = response.nextToken;
      response = await listDataPermissions(listDataPermissionsRequest);
      dataPermissions.push(...response.dataPermissionList);
    }
    for (let dataPermission of dataPermissions) {
      if (
        dataPermission.resource &&
        (dataPermission.resource?.redshiftTagPolicy || dataPermission.resource?.dataCellsFilter)
      ) {
        let rsTagPolicy = dataPermission.resource?.redshiftTagPolicy;
        let dataCellFilter = dataPermission.resource?.dataCellsFilter;
        if (workspaceMap.has(dataPermission.audit?.DataOwnerId)) {
          workspaceMap.get(dataPermission.audit.DataOwnerId).push({
            tagKey: rsTagPolicy?.tagKey,
            tagValue: rsTagPolicy?.tagValue,
            catalogId: rsTagPolicy ? rsTagPolicy?.catalogId : dataCellFilter?.tableCatalogId,
            clusterId: dataCellFilter?.clusterId,
            databaseName: dataCellFilter?.databaseName,
            schemaName: dataCellFilter?.schemaName,
            tableName: dataCellFilter?.tableName,
            fgapName: dataCellFilter?.name,
            fgapId: dataPermission?.fgapId,
          });
        } else {
          workspaceMap.set(dataPermission.audit?.DataOwnerId, [
            {
              tagKey: rsTagPolicy?.tagKey,
              tagValue: rsTagPolicy?.tagValue,
              catalogId: rsTagPolicy ? rsTagPolicy?.catalogId : dataCellFilter?.tableCatalogId,
              clusterId: dataCellFilter?.clusterId,
              databaseName: dataCellFilter?.databaseName,
              schemaName: dataCellFilter?.schemaName,
              tableName: dataCellFilter?.tableName,
              fgapName: dataCellFilter?.name,
              fgapId: dataPermission?.fgapId,
            },
          ]);
          itemList.push({
            dataOwner: dataPermission.audit?.DataOwnerId,
            owner: dataPermission.ownerId,
            catalogId: rsTagPolicy ? rsTagPolicy?.catalogId : dataCellFilter?.tableCatalogId,
            region: dataPermission.region,
          });
        }
      }
    }
    for (let item of itemList) {
      item.resourcesFound = workspaceMap.get(item.dataOwner);
    }
    setItems(itemList);
  };

  const handleRefresh = async () => {
    setLoadingResource(true);
    try {
      await fetchDataPermissions();
      await checkHavingRegisteredRedshiftCluster();
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Error when fetching the resources: ${err.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setLoadingResource(false);
    }
    setLoadingResource(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions = [
    {
      id: 'dataOwner',
      header: 'Data owner',
      cell: (item) => (
        <Link
          href={
            isValidWorkspace(item.dataOwner)
              ? createWorkspaceDetailLink(item.dataOwner)
              : createGroupDetailLink(item.dataOwner)
          }
        >
          {item.dataOwner}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'catalogId',
      header: 'Catalog ID',
      cell: (item) => item?.catalogId,
      minWidth: 350,
    },
    {
      id: 'region',
      header: 'Region',
      cell: (item) => item?.region,
      minWidth: 350,
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Database name',
          key: 'Name',
          groupValuesLabel: 'Database names',
        },
      ],
    },
  });

  const federateIntoWorkspace = async (workspaceId) => {
    if (
      props.activeWorkspace != null &&
      (selectedWorkspace != null || workspaceId == props.activeWorkspace.workspaceId)
    ) {
      let response;
      try {
        response = await getWorkspacesCredentials({
          workspaceId: workspaceId,
          currentWorkspaceId: props.activeWorkspace.workspaceId,
          federatedService: 'Redshift',
          expirationTimeMins: ANALYTICS_FEDERATION_TIME_OUT,
          getConsoleURL: true,
          getIAMCredentials: false,
        });
        console.log('isengard url:', response);
        window.open(response.consoleURL);
      } catch (err) {
        setNotifications([
          {
            type: 'error',
            content: 'Failed to federate into workspace. Reason: ' + err.message,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      }
    }
  };

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedItem = selectedItems[selectedItems.length - 1];
    setSelectedWorkspace(selectedItem);
  }, [collectionProps.selectedItems]);

  return (
    <>
      <Flashbar items={notifications}></Flashbar>
      <Table
        {...collectionProps}
        loadingText='Loading databases...'
        loading={loadingResource}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={
                !ownRegisteredCluster
                  ? [
                      {
                        text: '',
                        icon: 'refresh',
                        onItemClick: handleRefresh,
                      },
                      {
                        text: 'Query consuming Redshift',
                        onItemClick: () => {
                          federateIntoWorkspace(selectedWorkspace.dataOwner);
                        },
                        disabled: !selectedWorkspace,
                      },
                    ]
                  : [
                      {
                        text: '',
                        icon: 'refresh',
                        onItemClick: handleRefresh,
                      },
                      {
                        text: 'Query my own Redshift',
                        onItemClick: () => {
                          federateIntoWorkspace(props.activeWorkspace.workspaceId);
                        },
                      },
                      {
                        text: 'Query consuming Redshift',
                        onItemClick: () => {
                          federateIntoWorkspace(selectedWorkspace.dataOwner);
                        },
                        disabled: !selectedWorkspace,
                      },
                    ]
              }
              header={
                <Header
                  variant='h2'
                  info={
                    <Link variant='info' onFollow={props.toggleHelp}>
                      {' '}
                      Info{' '}
                    </Link>
                  }
                >
                  Accessible Redshift data
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </Header>
              }
            />
          </>
        }
        selectionType='single'
        isItemDisabled={(item) => item.status == 'registered'}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        empty={<EmptyState title='No Redshift data' subtitle='No Redshift data were found.' />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingResource}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
      <br />
      <br />
      {selectedWorkspace !== undefined && <RedshiftWSResources {...props} selectedWorkspace={selectedWorkspace} />}
    </>
  );
};
