import awsdatalakedependencylambda, {
  CreateDependencyRequest,
  CreateDependencyResponse,
  DataSet,
  DeleteDependencyRequest,
  DeleteDependencyResponse,
  GetDataSetRequest,
  GetDataSetResponse,
  GetDependencyRequest,
  GetDependencyResponse,
  ListDataSetsRequest,
  ListDataSetsResponse,
  ListDependenciesRequest,
  ListDependenciesResponse,
  UpdateDependencyRequest,
  UpdateDependencyResponse,
} from 'aws-sdk/clients/awsdatalakedependencylambda';

import basicCallWrapper from './client_call';
import { getDependencyConfig } from './config';
import { Select } from '@amzn/awsui-components-react';

const config = getDependencyConfig();

let dependencyClient = new awsdatalakedependencylambda();

export async function initDependencyClient(credentials) {
  const dependencyClientConfig = {
    region: config.region,
    endpoint: config.endpoint,
    credentials: credentials,
  };
  dependencyClient = new awsdatalakedependencylambda(dependencyClientConfig);
}

export const listDependencyDataSets = (input?: ListDataSetsRequest) => {
  return basicCallWrapper<ListDataSetsRequest, ListDataSetsResponse>(
    dependencyClient,
    dependencyClient.listDataSets,
  )(input);
};

export const listDependencies = (input?: ListDependenciesRequest) => {
  return basicCallWrapper<ListDependenciesRequest, ListDependenciesResponse>(
    dependencyClient,
    dependencyClient.listDependencies,
  )(input);
};

export const getDataSet = (input?: GetDataSetRequest) => {
  return basicCallWrapper<GetDataSetRequest, GetDataSetResponse>(dependencyClient, dependencyClient.getDataSet)(input);
};

export const getDependency = (input?: GetDependencyRequest) => {
  return basicCallWrapper<GetDependencyRequest, GetDependencyResponse>(
    dependencyClient,
    dependencyClient.getDependency,
  )(input);
};

export const createDependency = (input?: CreateDependencyRequest) => {
  return basicCallWrapper<CreateDependencyRequest, CreateDependencyResponse>(
    dependencyClient,
    dependencyClient.createDependency,
  )(input);
};

export const updateDependency = (input?: UpdateDependencyRequest) => {
  return basicCallWrapper<UpdateDependencyRequest, UpdateDependencyResponse>(
    dependencyClient,
    dependencyClient.updateDependency,
  )(input);
};

export const deleteDependency = (input?: DeleteDependencyRequest) => {
  return basicCallWrapper<DeleteDependencyRequest, DeleteDependencyResponse>(
    dependencyClient,
    dependencyClient.deleteDependency,
  )(input);
};

export const convertDataSetsToOptions = (dataSets: DataSet[]): Select.Option[] => {
  const options: Select.Option[] = dataSets.map((dataset: DataSet) => {
    const subs = dataset.hybridCatalogDataSetId.split('|');
    const catalogId = subs[1].slice(2);
    const databaseName = subs[2].slice(3);
    const region = subs[4].slice(2);

    return {
      id: dataset.dependencyServiceDataSetId,
      label: dataset.tableName,
      value: dataset.dependencyServiceDataSetId,
      description: `Catalog: ${catalogId} Database: ${databaseName} Region: ${region}`,
    };
  });
  return options;
};
