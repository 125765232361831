import { Widget } from '@amzn/cloudwatchdashboards-inside';

interface iDatasetMetadata {
  dataset: string;
  database: string;
  catalog: string;
}

const query = '| fields @timestamp, @message | sort @timestamp desc | limit 50';

const getLogGroup = (metadata: iDatasetMetadata): string => {
  try {
    const source = `SOURCE '/aws/tethys/${metadata.catalog}/${metadata.database}/${metadata.dataset}'`;
    return `${source} ${query}`;
  } catch (e) {
    return '';
  }
};

export const ingestionLogs = (metadata: iDatasetMetadata): Widget => ({
  type: 'log',
  x: 0,
  y: 14,
  width: 24,
  height: 9,
  properties: {
    query: getLogGroup(metadata),
    region: 'us-east-1',
    title: `Ingestion logs ${getLogGroup(metadata)}`,
  },
});
