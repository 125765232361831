import { getRegion } from 'src/api/config';
import { DataSetManifest } from './manifest';
import { GetDataSetResponse } from 'aws-sdk/clients/tethyscontractservicelambda';

export const LATEST_MANIFEST_VERSION = 1;

/**
 * Builds a dataset manifest and serializes it (in JSON) to a string
 */
export function serializeDatasetManifest(dataset: GetDataSetResponse): string {
  const manifest: DataSetManifest = {
    version: LATEST_MANIFEST_VERSION,
    source_site: getRegion(),
    contract: dataset,
  };

  return JSON.stringify(manifest);
}
