export const docs = {
  publisherAPI: 'https://quip-amazon.com/uQUwAC0Tf9OB/Tethys-Onboarding-Guide#temp:C:ASD9dfbbd980cb746ff8b62ba645',
  monitoring: 'https://quip-amazon.com/a2f4AXbm1BlX/Alarm-Mechanism-Tethys-Customer-Datasets',
  foundry: 'https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry',
  iamConfig: 'https://quip-amazon.com/uQUwAC0Tf9OB/Tethys-Onboarding-Guide#temp:C:ASDf419df40d36f429883f5a0183',
  onboarding: 'https://w.amazon.com/bin/view/AWSCP/PRET/Docs/Services/Tethys/DP/Start',
  glueRegistry: 'https://docs.aws.amazon.com/glue/latest/dg/schema-registry.html',
  databaseName:
    'https://quip-amazon.com/eK5aAc2cF1tO/Tethys-Onboarding-Prerequisites-from-Provider#temp:C:MFfb13224a971734943b97d86002',
  DynamoDB: 'https://quip-amazon.com/avnfAh7m7tGF/Tethys-DynamoDB-Stopgap-Solution-Detail',
  oncall: 'https://oncall.corp.amazon.com/#/view/aws-di-primary/schedule',
};

// API Docs: Reference: https://code.amazon.com/packages/AWSDITethysContractServiceLambdaModel/blobs/4414a920526d25cef1ae45172adb9cd1a8af9061/--/model/shapes.smithy#L108
export const fileFormats = {
  delimited: 'DELIMITED',
  parquet: 'PARQUET',
  ddbJson: 'DYNAMODB_JSON',
};

export const sourceTypes = {
  S3: 'S3',
  DynamoDB: 'DynamoDB',
};
