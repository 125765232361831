import awsdatalakesubscription, {
  CheckConnectivityRequest,
  CheckConnectivityResult,
  CreateSubscriptionRequest,
  CreateSubscriptionResult,
  CreateTargetRequest,
  CreateTargetResult,
  DataSetEntry,
  DeleteSubscriptionRequest,
  DeleteSubscriptionResult,
  DeleteTargetRequest,
  DeleteTargetResult,
  DescribeSubscriptionRequest,
  DescribeSubscriptionResult,
  DescribeTargetRequest,
  DescribeTargetResult,
  ListDataSetsRequest,
  ListDataSetsResult,
  ListExecutionsRequest,
  ListExecutionsResult,
  ListSubscriptionsRequest,
  ListSubscriptionsResult,
  ListTargetsRequest,
  ListTargetsResult,
  SyncSubscriptionRequest,
  SyncSubscriptionResult,
  TargetEntry,
} from 'aws-sdk/clients/awsdatalakesubscription';
import basicCallWrapper from './client_call';
import { Select } from '@amzn/awsui-components-react';
import { getSubscriptionConfig } from './config';

const config = getSubscriptionConfig();

let subscriptions = new awsdatalakesubscription();

export async function initSubscriptionsClient(credentials) {
  const subscriptionsClientConfig = {
    region: config.region,
    endpoint: config.endpoint,
    credentials: credentials,
  };
  subscriptions = new awsdatalakesubscription(subscriptionsClientConfig);
}

export const convertDataSetsToOptions = (datasets: ListDataSetsResult): Select.Option[] => {
  const options: Select.Option[] = datasets['datasets'].map((dataset: DataSetEntry) => {
    return {
      id: dataset.datasetId,
      label: dataset.name,
      description: dataset.datasetId.split('|')[3].substr(3),
    };
  });
  return options;
};

export const convertTargetsToOptions = (result: ListTargetsResult): Select.Option[] => {
  const options: Select.Option[] = result.targets.map((target: TargetEntry) => {
    return {
      id: target.targetId,
      label: target.targetId,
      description: target.name,
    };
  });
  return options;
};

export const parseDatasetId = (datasetId): any => {
  let result = {};
  let names = ['type', 'account', 'database', 'table', 'region'];
  let values = datasetId.split('|');
  names.forEach((e, i) => (result[e] = values[i].substr(values[i].indexOf('-') + 1)));
  return result;
};

export const listDatasets = (input: ListDataSetsRequest) => {
  return basicCallWrapper<ListDataSetsRequest, ListDataSetsResult>(subscriptions, subscriptions.listDataSets)(input);
};

export const listTargets = (input: ListTargetsRequest) => {
  return basicCallWrapper<ListTargetsRequest, ListTargetsResult>(subscriptions, subscriptions.listTargets)(input);
};

export const createTarget = (input: CreateTargetRequest) => {
  return basicCallWrapper<CreateTargetRequest, CreateTargetResult>(subscriptions, subscriptions.createTarget)(input);
};

export const createSubscription = (input: CreateSubscriptionRequest) => {
  return basicCallWrapper<CreateSubscriptionRequest, CreateSubscriptionResult>(
    subscriptions,
    subscriptions.createSubscription,
  )(input);
};

export const syncSubscription = (input: SyncSubscriptionRequest) => {
  return basicCallWrapper<SyncSubscriptionRequest, SyncSubscriptionResult>(
    subscriptions,
    subscriptions.syncSubscription,
  )(input);
};

export const deleteSubscription = (input: DeleteSubscriptionRequest) => {
  return basicCallWrapper<DeleteSubscriptionRequest, DeleteSubscriptionResult>(
    subscriptions,
    subscriptions.deleteSubscription,
  )(input);
};

export const deleteTarget = (input: DeleteTargetRequest) => {
  return basicCallWrapper<DeleteTargetRequest, DeleteTargetResult>(subscriptions, subscriptions.deleteTarget)(input);
};

export const listSubscriptions = (input: ListSubscriptionsRequest) => {
  return basicCallWrapper<ListSubscriptionsRequest, ListSubscriptionsResult>(
    subscriptions,
    subscriptions.listSubscriptions,
  )(input);
};

export const listExecutions = (input: ListExecutionsRequest) => {
  return basicCallWrapper<ListExecutionsRequest, ListExecutionsResult>(
    subscriptions,
    subscriptions.listExecutions,
  )(input);
};

export const describeSubscription = (input: DescribeSubscriptionRequest) => {
  return basicCallWrapper<DescribeSubscriptionRequest, DescribeSubscriptionResult>(
    subscriptions,
    subscriptions.describeSubscription,
  )(input);
};

export const describeTarget = (input: DescribeTargetRequest) => {
  return basicCallWrapper<DescribeTargetRequest, DescribeTargetResult>(
    subscriptions,
    subscriptions.describeTarget,
  )(input);
};

export const checkConnectivity = (input: CheckConnectivityRequest) => {
  return basicCallWrapper<CheckConnectivityRequest, CheckConnectivityResult>(
    subscriptions,
    subscriptions.checkConnectivity,
  )(input);
};
