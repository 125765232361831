import { Button, Flashbar, Header, Link, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { Advisory } from 'aws-sdk/clients/awsdlomni';
import { getIdInfoFromArn, getResourceNameForIdInfo, getScopeForIdInfo } from 'src/components/utils/arnUtil';
import {
  createAdvisoryDetailsLink,
  createGroupDetailLink,
  createLinkForResourceArn,
  createWorkspaceDetailLink,
} from 'src/routes';
import { Marked } from '@ts-stack/markdown';
import * as DOMPurify from 'dompurify';
import { getGroupOrWorkspaceName } from 'src/commons/common';
import { ListTemplatesRequest, ListTemplatesResult } from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { listTemplates } from 'src/api/permissions';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';

export const ADVISORY_STATUS_ACTIVE = 'Active';
export const ADVISORY_STATUS_CANCELED = 'Canceled';
export const ADVISORY_STATUS_RESOLVED = 'Resolved';
export const ADVISORY_STATUS_DRAFT = 'Draft';

export const TICKET_STATUS_RESOLVED = 'Resolved';
export const TICKET_STATUS_WORK_IN_PROGRESS = 'Work in Progress';
export const TICKET_STATUS_PENDING = 'Pending';
export const TICKET_STATUS_ASSIGNED = 'Assigned';

export const EMAIL = 'Email';
export const CC_EMAIL = 'CCEmail';
export const SLACK = 'Slack';
export const WIKI = 'Wiki';
export const CTI = 'CTI';
export const ONCALL = 'Oncall';
export const LINK = 'Link';
export const OMNI = 'Omni';
export const DEFAULT_EMAIL = 'email@amazon.com';
export const DEFAULT_CC_EMAIL = 'ccemail@amazon.com';
export const DEFAULT_SLACK = '#slack-channel';
export const DEFAULT_WIKI = 'https://w.amazon.com/bin/view';
export const DEFAULT_CTI = 'Category/Type/Item';
export const DEFAULT_ONCALL_LINK = 'https://oncall.corp.amazon.com/#/view/your-team-here/schedule';
export const DEFAULT_LINK = 'https://';
export const READABLE_EMAIL = 'Email address';
export const READABLE_CC_EMAIL = 'CC email address';
export const READABLE_SLACK = 'Slack channel';
export const READABLE_WIKI = 'Wiki page';
export const READABLE_CTI = 'CTI';
export const READABLE_ONCALL_LINK = 'Oncall schedule';
export const READABLE_LINK = 'Link';
export const READABLE_OMNI = 'Omni group/wks';

export const ADVISORIES_WIKI_PAGE = 'https://w.amazon.com/bin/view/AWSCP/PRET/Services/DataPlatform/Omni/DataAdvisory';
export const ADVISORIES_WIKI_PAGE_CREATE_SECTION =
  'https://w.amazon.com/bin/view/AWSCP/PRET/Services/DataPlatform/Omni/DataAdvisory#HCreatinganadvisory';

export const COMMENT_TOO_LONG_ERROR = 'Comments cannot be more than 256 characters.';

export const statusIndicatorForAdvisory = (advisory) => {
  let type;
  const status = advisory.status;
  if (status == ADVISORY_STATUS_ACTIVE) {
    type = 'warning';
  } else if (status == ADVISORY_STATUS_RESOLVED) {
    type = 'success';
  } else if (status == ADVISORY_STATUS_CANCELED) {
    type = 'stopped';
  } else if (status == ADVISORY_STATUS_DRAFT) {
    type = 'in-progress';
  }
  return <StatusIndicator type={type}>{status}</StatusIndicator>;
};

export const generateLinkForResourceArn = (arn: string) => {
  if (arn.startsWith('arn:')) {
    // Hybrid Catalog ARN
    return createLinkForResourceArn(arn);
  }
  if (arn.startsWith('wks-')) {
    // workspace ID
    return createWorkspaceDetailLink(arn);
  }
  if (arn.startsWith('adv-')) {
    return createAdvisoryDetailsLink(arn);
  }
  // else, it is a group ID
  return createGroupDetailLink(arn);
};

export const getResourceNameFromArn = (arn) => {
  if (!arn) {
    return '';
  }
  if (arn.startsWith('arn:')) {
    // Hybrid Catalog ARN
    return getResourceNameForIdInfo(getIdInfoFromArn(arn));
  }
  if (arn.startsWith('wks-')) {
    // workspace ID
    return arn; // Future enhancement: show workspace name, not ID
  }
  // else, it is a group ID
  return arn;
};

export const getResourceTypeFromArn = (arn) => {
  if (arn.startsWith('arn:')) {
    return getScopeForIdInfo(getIdInfoFromArn(arn));
  }
  if (arn.startsWith('wks-')) {
    return 'Data provider (workspace)';
  }
  if (arn.startsWith('adv-')) {
    return 'Advisory';
  }
  // else, it is a group ID
  return 'Data provider (group)';
};

export const linkForResource = (resource) => {
  return (
    <Link href={generateLinkForResourceArn(resource.resourceArn)}>{getResourceNameFromArn(resource.resourceArn)}</Link>
  );
};

export const linkForGroupOrWorkspace = (groupOrWorkspaceId: string, workspaceNameMap: Map<string, string>) => {
  return (
    <Link href={generateLinkForResourceArn(groupOrWorkspaceId)}>
      {getGroupOrWorkspaceName(groupOrWorkspaceId, workspaceNameMap)}
    </Link>
  );
};

export const validateEmail = (email: string) => {
  if (!email || email.length == 0) return true;
  const emailRegex = new RegExp(
    // taken from here:https://code.amazon.com/packages/GladstoneLambda/blobs/e012e1b37efcfe17f3f870b43ec0c02d3f9102f3/--/src/com/amazon/gladstonelambda/util/ExceptionUtil.java#L93
    '^[A-Z0-9._%+-]+(@amazon\\.[.a-z]{2,10}|@sc2s\\.sgov\\.gov|@c2s\\.ic\\.gov)$',
    'i',
  ); // 'i' flag means case-insensitive
  return emailRegex.test(email);
};

export const validateSlack = (slack: string) => {
  if (!slack || slack.length == 0) return true;
  return slack.startsWith('#') && slack.length <= 81; // max channel name length is 80, plus the #
};

export const validateWikiLink = (url: string) => {
  return url.includes('https://w.amazon.com/bin/view');
};

export const validateOncallLink = (url: string) => {
  return url.includes('https://oncall.corp.amazon.com/#/view/');
};

export const validateGenericLink = (url: string) => {
  return url.startsWith('https://');
};

export const validateCti = (cti: string) => {
  const ctiRegex = new RegExp('.+\\/.+\\/.+');
  return ctiRegex.test(cti) && cti.length >= 5;
};

export const convertAdvisoryToTemplate = (advisory: Advisory) => {
  const template: Advisory = {
    ownerId: advisory.ownerId,
    title: advisory.title,
    content: advisory.content,
    impactedResources: advisory.impactedResources,
    customers: advisory.customers,
    contactInfo: advisory.contactInfo,
    lifecycleConfiguration: advisory.lifecycleConfiguration,
  };
  return template;
};

export const convertAdvisoryToJson = (advisory: Advisory) => {
  return JSON.stringify(advisory);
};

export const convertJsonToAdvisory = (templateBody: string) => {
  return JSON.parse(templateBody) as Advisory;
};

export interface ConfirmCancelModalProps {
  onYes: any;
  onNo: any;
  visible: boolean;
}
export const ConfirmCancelModal = (props: ConfirmCancelModalProps) => {
  return (
    <Modal
      visible={props.visible}
      onDismiss={props.onNo}
      header={'Confirm cancel'}
      footer={
        <Header
          actions={
            <SpaceBetween size={'s'} direction={'horizontal'}>
              <Button variant={'link'} onClick={props.onNo}>
                No
              </Button>
              <Button variant={'primary'} onClick={props.onYes}>
                Yes
              </Button>
            </SpaceBetween>
          }
        />
      }
    >
      You have unsaved changes. Are you sure you want to cancel?
    </Modal>
  );
};

export const notificationsToFlashbar = (notifications: any[], setNotifications: any) => {
  if (notifications == undefined) return <></>;
  return (
    <Flashbar
      items={notifications?.map((notification) => {
        return {
          type: notification.type,
          header: notification.header,
          onDismiss: () => setNotifications([]),
          dismissible: true,
          action:
            notification.buttonText != undefined ? (
              <Button onClick={notification.onClick}>{notification.buttonText}</Button>
            ) : undefined,
          content: notification.content,
        };
      })}
    />
  );
};

export const getTemplateOptions = async (props: any) => {
  const request: ListTemplatesRequest = {
    ownerId: activeGroupOrWorkspaceId(props),
    templateType: 'DataAdvisory',
  };
  const response: ListTemplatesResult = await listTemplates(request);
  const mappedItems: OptionDefinition[] = response.templates.map((template) => {
    return {
      label: template.name,
      value: template.templateId,
    };
  });
  return mappedItems;
};

export const signedInWithWorkspace = (props: any) => {
  return props.activeWorkspace != undefined;
};

export const activeGroupOrWorkspaceId = (props: any) => {
  if (signedInWithWorkspace(props)) {
    return props.activeWorkspace.workspaceId;
  } else {
    return props.activeGroup;
  }
};

export const compareAdvisories = (adv1: Advisory, adv2: Advisory) => {
  const json1 = JSON.stringify(adv1);
  const json2 = JSON.stringify(adv2);
  return json1 == json2;
};

export const safelyRenderedAdvisoryMarkdownContent = (markdownRawContent: string) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(Marked.parse(markdownRawContent)),
      }}
    />
  );
};
