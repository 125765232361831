import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/subscriptions/common';
import { defaultWrapLinesPreference, notificationsPageSizePreference, paginationLabels } from 'src/commons/tables';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { METADATA_ENABLED, METADATA_FORM } from 'src/commons/constants';
import { useCollection } from '@amzn/awsui-collection-hooks';
import MetadataFormFieldValueDetailsTable from 'src/components/workspaces/common/metadataFormFieldValueDetails';
import { listMetadataEntitiesAssociatedToResource } from 'src/api/catalog';
import MetadataFormModal from 'src/components/workspaces/common/metadataFormModal';

export interface MetadataDetailsProps {
  resource: any;
  activeGroup: string;
  setContentType: any;
  activeWorkspace?: any;
  setNotification: any;
  resourceOwnerIds: any;
}

export const MetadataDetails = (props: MetadataDetailsProps) => {
  const { state } = useLocation();
  const [ownerId, setOwnerId] = useState(undefined);
  const [metadataForms, setMetadataForms] = useState([]);
  const [, setButtonLoading] = useState(false);
  const [redirect] = useState(undefined);
  const [selectedFormId, setSelectedFormId] = useState(undefined);
  const [selectedFormOwnerId, setSelectedFormOwnerId] = useState(undefined);
  const [selectedDisplayFormOn, setSelectedDisplayFormOn] = useState(undefined);
  const [loadingForms, setLoadingForms] = useState(false);
  const resource = props.resource;
  const [formsTableMessage, setFormsTableMessage] = useState('No forms available');
  const [editFormModalVisible, setEditFormModalVisible] = useState(false);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(metadataForms, {
    filtering: {
      noMatch: '',
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{formsTableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No forms found.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  useEffect(() => {
    const activeGroup = props.activeGroup;
    const activeWorkspace = props.activeWorkspace;
    const ownerId = activeWorkspace ? activeWorkspace.workspaceId : activeGroup;
    setOwnerId(ownerId);
    if (state) {
      props.setNotification(state.status, state.message);
    }
  }, []);

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedForm = selectedItems[0];
    if (selectedForm) {
      setSelectedFormId(selectedForm.MetadataFormId);
      setSelectedFormOwnerId(selectedForm.OwnerId);
      setSelectedDisplayFormOn(
        selectedForm.DisplayFormOn?.map((item) => {
          return { label: item, value: item };
        }) ?? [],
      );
    }
  }, [collectionProps.selectedItems]);

  useEffect(() => {
    if (ownerId) {
      handleRefresh();
    }
  }, [ownerId]);

  const handleRefresh = async () => {
    setLoadingForms(true);
    setButtonLoading(true);
    setSelectedFormId(undefined);
    await listMetadataFormEntitiesAssociatedForResource();
  };

  const listMetadataFormEntitiesAssociatedForResource = async () => {
    setLoadingForms(true);
    setButtonLoading(true);
    try {
      let formsForResource = [];
      let filteredEnabledForms = [];
      let result = await listMetadataEntitiesAssociatedToResource({
        ResourceArn: resource,
        MetadataEntityFilter: METADATA_FORM,
      });
      filteredEnabledForms = result.FormList.filter((form) => {
        return form.Status == METADATA_ENABLED;
      });
      formsForResource.push(...filteredEnabledForms);
      while (result.NextToken != undefined) {
        result = await listMetadataEntitiesAssociatedToResource({
          ResourceArn: resource,
          MetadataEntityFilter: METADATA_FORM,
          NextToken: result.NextToken,
        });
        filteredEnabledForms = result.FormList.filter((form) => {
          return form.Status == METADATA_ENABLED;
        });
        formsForResource.push(...filteredEnabledForms);
      }
      setMetadataForms(formsForResource);
    } catch (err) {
      setFormsTableMessage(`Unable to load forms. ${err.message}`);
    } finally {
      setLoadingForms(false);
      setButtonLoading(false);
    }
  };

  const metadataFormColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'metadataFormName',
      header: 'Name',
      cell: (item) => <Link to={`/metadataForms/${item.MetadataFormId}/${item.OwnerId}`}>{item.Name.split()}</Link>,
      minWidth: 200,
      sortingField: 'metadataFormName',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.Description,
      minWidth: 200,
    },
    {
      id: 'ownerId',
      header: 'OwnerId',
      cell: (item) => item.OwnerId,
      minWidth: 200,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.FormType.replace('Form#', ''),
      minWidth: 200,
    },
  ];

  const handleMetadataFormButtonAction = async (e) => {
    if (e.detail.id === 'editMetadataForm') {
      setEditFormModalVisible(true);
    }
  };

  const userIsOwner = () => {
    const ownerId = props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup;
    return props.resourceOwnerIds?.includes(ownerId);
  };
  const metadataFormResourceButtonOptions = () => {
    if (!userIsOwner()) {
      return [
        {
          text: '',
          icon: 'refresh',
          onItemClick: handleRefresh,
        },
      ];
    }
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Actions',
        onItemClick: handleMetadataFormButtonAction,
        items: [
          {
            text: 'Edit form',
            id: 'editMetadataForm',
            disabled: !selectedFormId,
          },
        ],
      },
    ];
  };

  const getFilterCounterText = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        selectionType='single'
        loadingText='Loading forms...'
        loading={loadingForms}
        columnDefinitions={metadataFormColumnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No forms</b>
            <Box variant='p' color='inherit'>
              No forms to display.
            </Box>
          </Box>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        resizableColumns={true}
        header={
          <PageHeader
            buttons={metadataFormResourceButtonOptions()}
            header={
              <>
                Metadata Forms
                <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
              </>
            }
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={notificationsPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      />
      <br />
      {selectedFormId && selectedDisplayFormOn && (
        <MetadataFormModal
          formId={selectedFormId}
          formOwnerId={selectedFormOwnerId}
          resource={resource}
          close={() => {
            setEditFormModalVisible(false);
          }}
          visible={editFormModalVisible}
          displayFormOn={selectedDisplayFormOn}
          setNotification={props.setNotification}
        />
      )}

      {selectedFormId && (
        <MetadataFormFieldValueDetailsTable
          resourceOwnerIds={props.resourceOwnerIds}
          formId={selectedFormId}
          formOwnerId={selectedFormOwnerId}
          setNotification={props.setNotification}
          activeGroup={props.activeGroup}
          activeWorkspace={props.activeWorkspace}
          resource={resource}
        />
      )}
    </>
  );
};

export default MetadataDetails;
