import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  ColumnLayout,
  FormField,
  Input,
  Header,
  Modal,
  Box,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { Page } from '../../../../routes/Paths';
import { createResourceLinkForDataPermission } from 'src/api/permissions';
import {
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';

export interface DatabaseResourceLinkProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace: any;
  visible: boolean;
  closeModal: any;
  database: any;
  notifyCreateResourceLinkFailure: any;
  notifyCreateResourceLinkSuccess: any;
}

export const DatabaseResourceLink = (props: DatabaseResourceLinkProps) => {
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [resourceName, setResourceName] = useState(undefined);
  const [resourceRegion, setResourceRegion] = useState(undefined);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      let request = {
        dataPermissionId: props.database.dataPermissionId,
        resourceLink: {
          ownerId: props.activeWorkspace.workspaceId,
          option: DATA_PERMISSION_CONSUMER_OPTION,
          type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
          dataConsumerRole: props.activeWorkspace.workspaceRoleArn,
          region: resourceRegion,
          resource: {
            glueDatabase: {
              name: resourceName,
              catalogId: props.activeWorkspace.accountId,
              targetDatabase: {
                catalogId: props.database.catalogId,
                databaseName: props.database.databaseName,
                region: props.database.region,
              },
            },
          },
        },
      };
      await createResourceLinkForDataPermission(request);
      props.notifyCreateResourceLinkSuccess('Create database resource link success');
      setLoading(false);
    } catch (err) {
      console.log('Exception create database resource link', err);
      props.notifyCreateResourceLinkFailure('Failed to create database resource link, ' + err.message);
      setLoading(false);
    }
    props.closeModal();
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Modal
        visible={props.visible}
        onDismiss={props.closeModal}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={() => setRedirect(Page.HOME)}>
                Cancel
              </Button>
              <Button variant='primary' loading={loading} onClick={handleConfirm}>
                Create Database Resource Link
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header description='You can create database resource link here' variant='h1'>
            Database resource link details
          </Header>
        }
      >
        <ColumnLayout>
          <FormField
            label={<div>Resource link name</div>}
            description={
              <>
                If you plan to access the database from Amazon Athena, then the name should be under 256 characters and
                contain only lowercase letters (a-z), numbers (0-9), and underscore (_).
                <a
                  href='https://docs.aws.amazon.com/athena/latest/ug/tables-databases-columns-names.html#ate-table-database-and-column-names-allow-only-underscore-special-characters'
                  className='awsui-util-help-info-link'
                  target={'_blank'}
                >
                  Athena names.
                </a>
              </>
            }
          >
            <Input
              placeholder='Enter resource link name'
              ariaRequired={true}
              onChange={(event) => setResourceName(event.detail.value)}
              value={resourceName}
            />
          </FormField>
          <FormField label={<div>Resource link region</div>} description='Input the resource link region.'>
            <Input
              placeholder='us-east-1'
              ariaRequired={true}
              onChange={(event) => setResourceRegion(event.detail.value)}
              value={resourceRegion}
            />
          </FormField>
          <FormField label={<div>Datasource database</div>} description='Datasource database'>
            <Input ariaRequired={true} value={props.database?.databaseName} disabled />
          </FormField>
          <FormField label={<div>Datasource catalogId</div>} description='Datasource catalogId'>
            <Input ariaRequired={true} value={props.database?.catalogId} disabled />
          </FormField>
          <FormField label={<div>Datasource Region</div>} description='Datasource region'>
            <Input ariaRequired={true} value={props.database?.region} disabled />
          </FormField>
        </ColumnLayout>
      </Modal>
    </div>
  );
};
