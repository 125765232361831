import * as React from 'react';
import { useState, useEffect } from 'react';

import { defaultPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  Header,
  Link,
  Button,
  SpaceBetween,
  StatusIndicator,
  StatusIndicatorProps,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { Advisory } from 'aws-sdk/clients/awsdlomni';
import {
  TICKET_STATUS_ASSIGNED,
  TICKET_STATUS_PENDING,
  TICKET_STATUS_RESOLVED,
  TICKET_STATUS_WORK_IN_PROGRESS,
} from 'src/components/dataadvisory/constants';

export interface AttachedTicketTableProps {
  advisory: Advisory;
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
}

export const AttachedTicketTable = (props: AttachedTicketTableProps) => {
  const [allItems, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableMessage, setTableMessage] = useState('No advisories');
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const statusIndicatorForTicket = (ticketStatus: string) => {
    let statusIndicatorType: StatusIndicatorProps.Type;
    if (ticketStatus == TICKET_STATUS_RESOLVED) {
      statusIndicatorType = 'success';
    } else if (
      ticketStatus == TICKET_STATUS_WORK_IN_PROGRESS ||
      ticketStatus == TICKET_STATUS_PENDING ||
      ticketStatus == TICKET_STATUS_ASSIGNED
    ) {
      statusIndicatorType = 'in-progress';
    } else {
      // otherwise, status is "Unknown"
      statusIndicatorType = 'pending';
    }
    return <StatusIndicator type={statusIndicatorType}>{ticketStatus ?? 'Unknown'}</StatusIndicator>;
  };

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'ticketLink',
      header: 'Ticket link',
      cell: (item) => (
        <Link external={true} href={item.ticketUrl}>
          {item.ticketUrl}
        </Link>
      ),
      width: 300,
      sortingField: 'ticketLink',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.comment,
      width: 600,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => statusIndicatorForTicket(item.ticketStatus),
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup, props.activeWorkspace]);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      setItems(props.advisory.attachedTickets);
      setLoading(false);
    } catch (err) {
      setTableMessage(`Unable to load tickets: ${err.message}`);
      setLoading(false);
    }
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No tickets to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading tickets...'
        loading={loading}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <Header
            counter={'(' + items.length + ')'}
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button iconName='refresh' variant='icon' />
              </SpaceBetween>
            }
          >
            Tickets
          </Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
