import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  convertJsonToAdvisory,
  notificationsToFlashbar,
  safelyRenderedAdvisoryMarkdownContent,
} from 'src/components/dataadvisory/constants';
import {
  Alert,
  ButtonDropdown,
  Container,
  Header,
  SpaceBetween,
  Spinner,
  TextContent,
} from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { ImpactedResourcesEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/impactedResourcesEditor';
import { CustomersEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/customersEditor';
import { ContactInfoEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/contactInfoEditor';
import { ConfirmDeleteTemplateModal } from 'src/components/dataadvisory/templates/confirmDeleteTemplateModal';
import { deleteTemplate, getTemplate } from 'src/api/permissions';
import { createCreateAdvisoryFromTemplateLink, createUpdateAdvisoryTemplateLink, Page } from 'src/routes';
import { LifecycleConfigurationEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/lifecycleConfigurationEditor';

export interface TemplateDetailPageProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
}

export const TemplateDetailPage = (props: TemplateDetailPageProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [advisory, setAdvisory] = useState(undefined);
  const [template, setTemplate] = useState(undefined);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    props.setContentType('form');
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const getTemplateResponse = await getTemplate({
        templateId: templateId(),
        resourceId: 'NONE',
      });
      const gladstoneTemplate = getTemplateResponse.template;
      const templateBody = gladstoneTemplate.templateBodyJson;
      const advisoryTemplate = convertJsonToAdvisory(templateBody);
      console.log(advisoryTemplate);
      setAdvisory(advisoryTemplate);
      setTemplate(gladstoneTemplate);
    } catch (e) {
      setFailedToLoad(true);
    }
    setLoading(false);
  };

  const templateId = () => {
    return props.match.params.id;
  };

  const handleDeleteTemplate = async () => {
    try {
      await deleteTemplate({
        templateId: templateId(),
      });
      setRedirect(Page.LIST_ADVISORY_TEMPLATES);
    } catch (e) {
      setNotificationForFailedDelete(e);
    }
  };

  const setNotificationForFailedDelete = (e) => {
    setNotifications([
      {
        type: 'error',
        content: 'Failed to delete the template: ' + e.message,
      },
    ]);
  };

  if (loading) {
    return <Spinner />;
  }
  if (failedToLoad) {
    return (
      <Alert type={'error'} statusIconAriaLabel={'Error'} header={'Unable to load template'}>
        Please double-check the template ID in the URL and try again.
      </Alert>
    );
  }

  return (
    <SpaceBetween size={'m'}>
      {redirect && <Redirect to={redirect} />}
      {notifications.length > 0 && notificationsToFlashbar(notifications, setNotifications)}
      <ConfirmDeleteTemplateModal
        visible={deleteModalVisible}
        onDelete={handleDeleteTemplate}
        onCancel={() => setDeleteModalVisible(false)}
        templateName={template.templateName}
      />
      <SpaceBetween size={'l'} direction={'vertical'}>
        <Header
          variant={'h1'}
          actions={
            <SpaceBetween size={'s'} direction={'horizontal'}>
              <ButtonDropdown
                items={[
                  {
                    text: 'Edit',
                    id: 'edit',
                    disabled: false,
                  },
                  { text: 'Delete', id: 'delete', disabled: false },
                  {
                    text: 'Create advisory from template',
                    id: 'create',
                    disabled: false,
                  },
                ]}
                onItemClick={(e) => {
                  if (e.detail.id == 'edit') {
                    setRedirect(createUpdateAdvisoryTemplateLink(template.templateId));
                  } else if (e.detail.id == 'delete') {
                    setDeleteModalVisible(true);
                  } else if (e.detail.id == 'create') {
                    setRedirect(createCreateAdvisoryFromTemplateLink(template.templateId));
                  }
                }}
              >
                Actions
              </ButtonDropdown>
            </SpaceBetween>
          }
        >
          {template.name}
        </Header>
        {advisory.title && advisory.content && (
          <Container header={<Header variant={'h2'}>{advisory.title}</Header>}>
            <TextContent>
              {advisory.content.format == 'Markdown'
                ? safelyRenderedAdvisoryMarkdownContent(advisory.content.rawContent)
                : advisory.content.rawContent}
            </TextContent>
          </Container>
        )}
        {advisory.impactedResources && advisory.impactedResources.length > 0 && (
          <ImpactedResourcesEditor
            impactedResources={advisory.impactedResources}
            readonly={true}
            activeGroup={props.activeGroup}
            activeWorkspace={props.activeWorkspace}
          />
        )}
        {advisory.customers && advisory.customers.length > 0 && (
          <CustomersEditor
            customers={advisory.customers}
            impactedResources={advisory.impactedResources}
            readonly={true}
            activeGroup={props.activeGroup}
            activeWorkspace={props.activeWorkspace}
          />
        )}
        {advisory.contactInfo && advisory.contactInfo.length > 0 && (
          <ContactInfoEditor contactInfo={advisory.contactInfo} readonly={true} />
        )}
        {advisory.lifecycleConfiguration && (
          <LifecycleConfigurationEditor
            lifecycleConfiguration={advisory.lifecycleConfiguration}
            setLifecycleConfiguration={() => {}}
            setErrorMessage={() => {}}
            readonly={true}
          />
        )}
      </SpaceBetween>
    </SpaceBetween>
  );
};
