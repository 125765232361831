import * as React from 'react';
import { AppLayout, AppLayoutProps, SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react-v3';
import TopNavigation from '@amzn/awsui-components-react-v3/polaris/top-navigation/1.0-beta';

import { getUser } from 'src/api/auth';
import { appLayoutLabels, navItemsNewCustomers } from '../../commons/navigationData';
import { helpContentNewCustomers } from '../../routes/helpData';
import { Redirect, Route } from 'react-router-dom';
import { EXP_10_MINS, WEBSITE_NAME } from '../../commons/constants';
import { getActiveHref, isHomePage } from '../utils/page';
import { ErrorBoundary } from '../error/errorBoundary';
import { OnboardingRoutes } from 'src/routes/onboardingRoutes';
import { Breadcrumbs, Page } from 'src/routes';
import { CreateNewGroupForm } from 'src/components/common/createNewGroupForm';
import { SearchFilters } from 'src/components/search/filters';
import { SearchAggregations, SearchEntityType } from 'src/components/search/constants';
import { LocalStorageItem, LocalStorageItemKey, StorageService } from 'src/components/utils/storage-service';
import { getExternalGroups, getUserInfo } from 'src/api/permissions';
import { generateCatalogMap, generateWorkspaceNameMap } from 'src/commons/common';

export interface OnboardingComponentProps {}

export interface OnboardingComponentState {
  userName: string;
  userInfo: any;
  userIdValidation: boolean;
  userIdErrorText: string;
  navOpen: boolean;
  contentType: AppLayoutProps.ContentType;
  redirect: string;
  active: string;
  activeDatasetName: string;
  activeDatabaseName: string;
  activeCatalogName: string;
  catalogMap: any;
  workspaceNameMap: Map<string, string>;
  toolsHide: boolean;
  toolsOpen: boolean;
  displaySplitPanel: boolean;
  splitPanelOpen: boolean;
  splitPanelSize: number;
  searchQueryString: string;
  searchLoading: boolean;
  searchFilters: {
    entityType: SearchEntityType;
    aggregations: any;
    filters: any;
  };
  externalGroups: any;
  navigationData: any[];
}

export default class HomeOnboardingComponent extends React.Component<
  OnboardingComponentProps,
  OnboardingComponentState
> {
  storageInstance: StorageService;
  state = {
    groupInfo: undefined,
    userIdValidation: false,
    userIdErrorText: '',
    toolsHide: false,
    toolsOpen: false,
    active: '/onboarding',
    userName: '',
    userInfo: undefined,
    groups: [],
    activeGroup: '',
    contentType: 'form' as AppLayoutProps.ContentType,
    navOpen: true,
    redirect: undefined,
    activeDatasetName: undefined,
    activeDatabaseName: undefined,
    activeCatalogName: undefined,
    catalogMap: new Map(),
    workspaceNameMap: new Map(),
    displaySplitPanel: false,
    splitPanelOpen: true,
    splitPanelSize: 350,
    searchQueryString: '',
    searchLoading: false,
    searchFilters: {
      entityType: SearchEntityType.Dataset,
      aggregations: {},
      filters: undefined,
    },
    navigationData: [],
    externalGroups: undefined,
  };

  constructor(props) {
    super(props);
    this.storageInstance = StorageService.getInstance();
  }

  onSearchEntityTypeChangeHandler = (newEntityType: SearchEntityType): void => {
    this.setState((state: any) => ({
      searchFilters: { ...state.searchFilters, entityType: newEntityType },
    }));
  };

  onSearchQueryChangeHandler = (searchQuery: string): void => {
    this.setState({ searchQueryString: searchQuery });
  };

  onSearchAggregationsChangeHandler = (newAggregations: SearchAggregations): void => {
    this.setState((state: any) => ({
      searchFilters: { ...state.searchFilters, aggregations: newAggregations },
    }));
  };

  onSearchFiltersChangeHandler = (newFilters: { [key: string]: string[] }): void => {
    this.setState((state: any) => ({
      searchFilters: { ...state.searchFilters, filters: newFilters },
    }));
  };

  onSplitPanelToggleHandler = ({ detail: { open } }): void => {
    this.setState({ splitPanelOpen: open });
  };

  onSplitPanelResizeHandler = ({ detail: { size } }): void => {
    this.setState({ splitPanelSize: size });
  };

  onSetSearchLoadingHandler = (isLoading: boolean): void => {
    this.setState({ searchLoading: isLoading });
  };

  onToolsHideHandler = (hideTools: boolean): void => {
    this.setState({ toolsHide: hideTools, toolsOpen: false });
  };

  fetchUserInfo = async (userId: string) => {
    const userInfoObj = await this.storageInstance?.getItem(LocalStorageItemKey.UserInfo);
    if (userInfoObj) {
      return userInfoObj.data;
    } else {
      let userInfo = await getUserInfo({ userId: userId });
      await this.storageInstance?.setItem(LocalStorageItemKey.UserInfo, new LocalStorageItem(userInfo, EXP_10_MINS));
      return userInfo;
    }
  };

  componentDidMount = async () => {
    const user = await getUser();
    console.log('Logged in as: ' + user);
    const userInfoObj = await this.fetchUserInfo(user);
    try {
      let externalGroupsResponse = await getExternalGroups({});
      let externalGroups = externalGroupsResponse?.externalGroupsList
        .map((group) => group?.externalGroup?.galaxiGroup)
        .filter((groupItem) => groupItem != null);
      this.setState({
        externalGroups: externalGroups,
      });
    } catch (err) {
      if (
        err.name == 'UnsupportedOperationException' &&
        err?.message == 'Galaxi group service is not enabled in this environment'
      ) {
        this.setState({ externalGroups: undefined });
      }
      console.error('Error fetching external groups');
    }

    const navItems = await navItemsNewCustomers(this.state);
    this.setState({
      active: getActiveHref(),
      userName: user,
      userInfo: userInfoObj,
      displaySplitPanel: !!this.state.active.endsWith(Page.SEARCH),
      toolsHide: !!this.state.active.endsWith(Page.SEARCH),
      navigationData: navItems,
    });
    const catalogNameMap = await generateCatalogMap(this.storageInstance);
    catalogNameMap.forEach((v, k) => this.state.catalogMap.set(k, v));

    // generate async workspace name map:
    generateWorkspaceNameMap(this.storageInstance).then((wksNameMap) => {
      console.info('Fetched workspaces map with %s entries', wksNameMap.size);
      this.setState({ workspaceNameMap: wksNameMap });
    });
  };

  async componentDidUpdate() {
    const href = getActiveHref();
    if (this.state.active != href) {
      this.setState({ active: href });
      this.handleSearchFiltersDisplay(href);
    }
  }

  handleSearchFiltersDisplay(currentPath: string) {
    let isAdvSearchPage = currentPath?.endsWith(Page.SEARCH);
    this.setState({ displaySplitPanel: isAdvSearchPage, toolsHide: isAdvSearchPage });
    if (!isAdvSearchPage) {
      // reset the search filters, help panel to default state
      this.setState((state: any) => ({
        toolsOpen: false,
        toolsHide: false,
        searchFilters: { ...state.searchFilters, filters: {} },
      }));
    }
  }

  handleFollow = (e) => {
    e.preventDefault();
    if (e.detail.external) {
      window.open(e.detail.href);
    } else {
      this.setState(
        {
          active: e.detail.href,
          redirect: e.detail.href,
        },
        () => this.setState({ redirect: undefined }),
      );
    }
    this.handleSearchFiltersDisplay(e.detail.href);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <>
        <div id={'top-nav-bar'} style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
          <TopNavigation
            id='top-navbar'
            className={'awsui-button-icon'}
            identity={{
              href: '/',
              title: WEBSITE_NAME,
              logo: {
                src: '/AWSDPLogo.png',
              },
            }}
            utilities={[
              {
                type: 'button',
                iconName: 'user-profile',
                text: this.state.userName,
                href: `https://phonetool.amazon.com/users/${this.state.userName}`,
              },
            ]}
            i18nStrings={{
              overflowMenuTriggerText: 'More',
            }}
          />
        </div>
        <AppLayout
          contentType={this.state.contentType}
          className={isHomePage() ? 'awsui-util-no-gutters' : ''}
          disableContentPaddings={isHomePage() || this.state.active == 'onboarding' ? true : false}
          splitPanelPreferences={{ position: 'side' }}
          splitPanelOpen={this.state.splitPanelOpen}
          onSplitPanelToggle={this.onSplitPanelToggleHandler}
          splitPanelSize={this.state.splitPanelSize}
          onSplitPanelResize={this.onSplitPanelResizeHandler}
          splitPanel={
            this.state.displaySplitPanel ? (
              <SearchFilters
                entityType={this.state.searchFilters.entityType}
                onEntityTypeChange={this.onSearchEntityTypeChangeHandler}
                aggregations={this.state.searchFilters.aggregations}
                filters={this.state.searchFilters.filters}
                onFiltersChange={this.onSearchFiltersChangeHandler}
                searchLoading={this.state.searchLoading}
              />
            ) : null
          }
          content={
            <ErrorBoundary>
              <OnboardingRoutes
                {...this.state}
                setContentType={(type) => this.setState({ contentType: type })}
                setActiveDatasetName={(name) => this.setState({ activeDatasetName: name })}
                setActiveCatalogName={(name) => this.setState({ activeCatalogName: name })}
                setActiveDatabaseName={(name) => this.setState({ activeDatabaseName: name })}
                setNavigationOpen={(open) => this.setState({ navOpen: open })}
                catalogMap={this.state.catalogMap}
                getStartedTarget={Page.SEARCH}
                onAggregationsChange={this.onSearchAggregationsChangeHandler}
                onSearchQueryChange={this.onSearchQueryChangeHandler}
                onSetSearchLoading={this.onSetSearchLoadingHandler}
                setDisplaySplitPanel={(open: boolean) => this.setState({ displaySplitPanel: open })}
                setToolsHide={this.onToolsHideHandler}
              />
              <Route exact path='/onboarding'>
                <CreateNewGroupForm
                  allowlisted={false}
                  setContentType={(type) => this.setState({ contentType: type })}
                />
                <main className='container' />
              </Route>
            </ErrorBoundary>
          }
          breadcrumbs={
            !isHomePage() && (
              <Breadcrumbs
                activeCatalogName={this.state.activeCatalogName}
                activeDatabaseName={this.state.activeDatabaseName}
                activeDatasetName={this.state.activeDatasetName}
                onFollow={this.handleFollow}
              />
            )
          }
          navigation={
            <SideNavigation
              items={this.state.navigationData as SideNavigationProps.Item[]}
              activeHref={this.state.active}
              onFollow={this.handleFollow}
            />
          }
          tools={helpContentNewCustomers}
          navigationOpen={this.state.navOpen}
          onNavigationChange={(e) => {
            this.setState({ navOpen: e.detail.open });
          }}
          toolsOpen={this.state.toolsOpen}
          toolsHide={this.state.toolsHide}
          ariaLabels={appLayoutLabels}
          headerSelector='#top-nav-bar'
        />
      </>
    );
  }
}
