import {
  Header,
  Container,
  FormField,
  CodeEditor,
  SpaceBetween,
  ButtonDropdown,
  Button,
} from '@amzn/awsui-components-react-v3';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { iStep } from '../utils/types';

import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';
import { getSchemaColumnNames, isSourceDDB, isSourceS3, validateStepThree } from '../utils/validation';
import { TethysLocalStorage } from '../../common/keys';
import { testStrings } from '../../common/testStrings';
import { RegisterDataSetRequest, TargetColumn, TargetSchema } from 'aws-sdk/clients/tethyscontractservicelambda';
import { initialRegisterDataSetRequest } from '../utils/requestPayload';

const i18nStrings = {
  loadingState: 'Loading code editor',
  errorState: 'There was an error loading the code editor.',
  errorStateRecovery: 'Retry',
  editorGroupAriaLabel: 'Code editor',
  statusBarGroupAriaLabel: 'Status bar',
  cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
  errorsTab: 'Errors',
  warningsTab: 'Warnings',
  preferencesButtonAriaLabel: 'Preferences',
  paneCloseButtonAriaLabel: 'Close',
  preferencesModalHeader: 'Preferences',
  preferencesModalCancel: 'Cancel',
  preferencesModalConfirm: 'Confirm',
  preferencesModalWrapLines: 'Wrap lines',
  preferencesModalTheme: 'Theme',
  preferencesModalLightThemes: 'Light themes',
  preferencesModalDarkThemes: 'Dark themes',
};

type DateTypes = 'date' | 'timestamp-millis' | 'timestamp-micros';
type SchemaType = 'varchar' | 'bigint' | 'decimal' | 'boolean' | 'double' | DateTypes;

const typesDropdown = [
  { id: 'varchar', text: 'varchar' },
  { id: 'bigint', text: 'bigint' },
  { id: 'decimal', text: 'decimal' },
  { id: 'boolean', text: 'boolean' },
];

const mapS3Fields = (type: SchemaType) => {
  if (type === 'varchar') return { name: '', type: 'string' };
  if (type === 'bigint') return { name: '', type: 'long' };
  if (type === 'boolean') return { name: '', type: 'boolean' };
  if (type === 'decimal')
    return {
      name: '',
      scale: 6,
      precision: 38,
      type: 'bytes',
      logicalType: 'decimal',
    };

  // Handles each of the 3 different date types.
  if (['date', 'timestamp-millis', 'timestamp-micros'].includes(type))
    return {
      name: '',
      type: 'long',
      logicalType: type,
    };
};

const mapDdbFields = (type: SchemaType): TargetColumn => {
  if (type === 'varchar')
    return {
      Name: '',
      Type: 'string',
      DynamoDBSourceType: 'S',
      SourceName: '',
    };
  if (type === 'bigint') return { Name: '', Type: 'long', DynamoDBSourceType: 'N', SourceName: '' };
  if (type === 'boolean')
    return {
      Name: '',
      Type: 'boolean',
      DynamoDBSourceType: 'BOOL',
      SourceName: '',
    };
  if (type === 'decimal')
    return {
      Name: '',
      Type: 'bytes',
      Scale: 6,
      Precision: 38,
      DynamoDBSourceType: 'N',
      SourceName: '',
      //      LogicalType: 'decimal',
    };
  if (type === 'double')
    return {
      Name: '',
      Type: 'double',
      DynamoDBSourceType: 'N',
      SourceName: '',
    };
};

interface iSchemaStep extends iStep {
  isJsonValid: boolean;
  setJsonValid(isValid: boolean): void;
}

export const SchemaStep = ({
  request,
  isCreate,
  isUpdate,
  isJsonValid,
  isValidated,
  setJsonValid,
  setRequest,
}: iSchemaStep) => {
  const [preferences, setPreferences] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [ace, setAce] = useState<any>();

  useEffect(() => {
    import('ace-builds')
      .then((ace) =>
        import('ace-builds/webpack-resolver')
          .then(() => {
            ace.config.set('useStrictCSP', true);
            ace.config.set('loadWorkerFromBlob', false);
            setAce(ace);
            setLoading(false);
          })
          .catch(() => setLoading(false)),
      )
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (isUpdate) return;

    const { SchemaDefinition, TargetSchema, ...DataProperties } = request.DataContract.DataProperties;

    // Remove TargetSchema when S3 is selected. Else SchemaDefinition is overriden.
    if (isSourceS3(request)) {
      setRequest({
        ...request,
        DataContract: {
          ...request.DataContract,
          DataProperties: {
            ...DataProperties,
            SchemaDefinition:
              SchemaDefinition || initialRegisterDataSetRequest.DataContract.DataProperties.SchemaDefinition,
          },
        },
      });
    }

    if (isSourceDDB(request)) {
      setRequest({
        ...request,
        DataContract: {
          ...request.DataContract,
          DataProperties: {
            ...DataProperties,
            TargetSchema: TargetSchema || initialRegisterDataSetRequest.DataContract.DataProperties.TargetSchema,
          },
        },
      });
    }
  }, [request.DataSource.SourceType]);

  useEffect(() => {
    console.log('request', request);
  }, [request]);

  const updateSchema = (newSchema: string | TargetSchema, request: RegisterDataSetRequest) => {
    setRequest({
      ...request,
      DataContract: {
        ...request.DataContract,
        DataProperties: isSourceS3(request)
          ? {
              ...request.DataContract.DataProperties,
              SchemaDefinition: newSchema as string,
            }
          : {
              ...request.DataContract.DataProperties,
              TargetSchema: newSchema as TargetSchema,
            },
      },
    });
  };

  const addField = (schemaType: SchemaType) => {
    try {
      const schemaDefinition = isSourceS3(request)
        ? JSON.parse(request.DataContract.DataProperties.SchemaDefinition)
        : request.DataContract.DataProperties.TargetSchema;

      const newSchema = isSourceS3(request)
        ? JSON.stringify(
            {
              ...schemaDefinition,
              fields: [...schemaDefinition.fields, mapS3Fields(schemaType)],
            },
            null,
            '\t',
          )
        : [...schemaDefinition, mapDdbFields(schemaType)];

      updateSchema(newSchema, request);
    } catch (e) {
      console.log(e, schemaType, request);
    }
  };

  return (
    <SpaceBetween size='xl'>
      <Container
        header={
          <Header
            variant='h2'
            actions={
              <SpaceBetween direction='horizontal' size='s'>
                <ButtonDropdown
                  onItemClick={({ detail }) => addField(detail.id as SchemaType)}
                  items={[
                    ...typesDropdown,
                    isSourceDDB(request) // Allow double on DDB, timestamps on S3.
                      ? { id: 'double', text: 'double' }
                      : {
                          text: 'timestamp',
                          items: [
                            { id: 'date', text: 'date' },
                            {
                              id: 'timestamp-millis',
                              text: 'timestamp-millis',
                            },
                            {
                              id: 'timestamp-micros',
                              text: 'timestamp-micros',
                            },
                          ],
                        },
                  ]}
                >
                  Add field
                </ButtonDropdown>
                {isCreate && isSourceS3(request) && (
                  <Button
                    onClick={() =>
                      localStorage.setItem(TethysLocalStorage, request.DataContract.DataProperties.SchemaDefinition)
                    }
                  >
                    Save Draft
                  </Button>
                )}
              </SpaceBetween>
            }
          >
            {isSourceS3(request) ? 'AWS Glue Schema Registry' : 'Tethys schema'}
          </Header>
        }
      >
        <FormField
          errorText={
            isValidated && (!isJsonValid || !validateStepThree(request).SchemaDefinition)
              ? !request.DataContract.DataProperties.SchemaDefinition
                ? 'Schema is required.'
                : !isJsonValid
                ? 'Invalid JSON'
                : !!getSchemaColumnNames(request).length
                ? `Invalid Schema: there are columns without "Name" ${isSourceDDB(request) ? 'or "SourceName".' : ''}.`
                : `Invalid Schema: no fields detected in your schema.`
              : ''
          }
        >
          <CodeEditor
            ace={ace}
            loading={loading}
            language='json'
            data-testid={testStrings.contractRegistration.step3.schema}
            preferences={preferences}
            i18nStrings={i18nStrings}
            value={
              isSourceS3(request)
                ? request.DataContract.DataProperties.SchemaDefinition || ''
                : JSON.stringify(request.DataContract.DataProperties.TargetSchema || '', null, '\t')
            }
            onChange={({ detail }) => {
              try {
                updateSchema(isSourceDDB(request) ? JSON.parse(detail.value) : detail.value, request);
                setJsonValid(true);

                if (isSourceS3(request)) {
                  JSON.parse(detail.value);
                }
              } catch (e) {
                setJsonValid(false);
              }
            }}
            onPreferencesChange={(e) => setPreferences(e.detail)}
          />
        </FormField>
      </Container>
    </SpaceBetween>
  );
};
