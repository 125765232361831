import { MetricWidget, Widget } from '@amzn/cloudwatchdashboards-inside';

export const ingestionKpis = (namespace: string, arn: string): Widget[] => [
  acquisitionKpis(namespace, arn),
  ingestion(namespace, arn),
  recordCounters(namespace, arn),
];

interface iMetricWidget extends MetricWidget {
  setPeriodToTimeRange?: boolean;
}

interface iWidget extends Widget {
  properties: iMetricWidget;
}

const timeDelta = 60 * 5;
const acquisitionKpis = (namespace: string, arn: string): iWidget => ({
  type: 'metric',
  x: 0,
  y: 0,
  width: 8,
  height: 4,
  properties: {
    stat: 'Sum',
    period: timeDelta,
    region: 'us-east-1',
    view: 'singleValue',
    setPeriodToTimeRange: true,
    title: 'Acquisition (single events)',
    metrics: [
      [namespace, 'PublishEventsCount', 'Datasets', arn, { label: 'Published events' }],
      ['.', 'AcquisitionFailures', '.', '.', { color: '#ff7f0e', label: 'Acquisition failures' }],
    ],
  },
});

const ingestion = (namespace: string, arn: string): iWidget => ({
  type: 'metric',
  x: 8,
  y: 0,
  width: 8,
  height: 4,
  properties: {
    stat: 'Sum',
    period: timeDelta,
    region: 'us-east-1',
    view: 'singleValue',
    setPeriodToTimeRange: true,
    title: 'Ingestion (micro-batches)',
    metrics: [
      [
        namespace,
        'IngestionExecutionCount',
        'Datasets',
        arn,
        'Views',
        'default_view',
        { color: '#2ca02c', label: 'Ingestion events' },
      ],
      ['.', 'IngestionFailures', '.', '.', '.', '.', { color: '#d62728', label: 'Ingestion failures' }],
    ],
  },
});

const recordCounters = (namespace: string, arn: string): iWidget => ({
  type: 'metric',
  x: 16,
  y: 0,
  width: 8,
  height: 4,
  properties: {
    stat: 'Sum',
    period: timeDelta,
    region: 'us-east-1',
    view: 'singleValue',
    setPeriodToTimeRange: true,
    title: 'Record metrics',
    metrics: [
      [namespace, 'AcquiredRecords', 'Datasets', arn, { color: '#aec7e8', label: 'Acquired records' }],
      [
        '.',
        'IngestionRecordsCount',
        '.',
        '.',
        'Views',
        'default_view',
        { label: 'Total ingested records', color: '#9467bd' },
      ],
    ],
  },
});
