import { GetDataSetResponse } from 'aws-sdk/clients/tethyscontractservicelambda';
import { TextContent } from '@amzn/awsui-components-react-v3';
import React, { useState, useEffect } from 'react';

export const SchemaText = ({ dataset }: { dataset: GetDataSetResponse }) => {
  const [schema, setSchema] = useState<string>();

  useEffect(() => {
    try {
      const { SchemaDefinition, TargetSchema } = dataset.DataContract.DataProperties;
      const jsonSchema = TargetSchema || JSON.parse(SchemaDefinition);
      const beautified = JSON.stringify(jsonSchema, null, '\t');

      setSchema(beautified);
    } catch (e) {
      setSchema(dataset?.DataContract?.DataProperties?.SchemaDefinition);
    }
  }, [dataset]);

  return (
    <TextContent>
      <p style={{ whiteSpace: 'pre-wrap' }}>
        <code>{schema}</code>
      </p>
    </TextContent>
  );
};
