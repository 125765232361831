import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { hcMetadataStatus, hcMetadataStringFormatType } from 'src/commons/constants';
import { editMetadataField } from 'src/api/catalog';

export interface EditMetadataFormFieldModalProps {
  formId: string;
  fieldId: string;
  metadataFormField: any;
  setNotification: (header: any, message: string) => void;
  close: () => void;
  visible: boolean;
  ownerId?: string;
  handleRefresh?: () => void;
}

export const EditMetadataFieldModal = (props: EditMetadataFormFieldModalProps) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentFieldName, setCurrentFieldName] = useState(undefined);
  const [currentFieldType, setCurrentFieldType] = useState(undefined);
  const [currentFieldStatus, setCurrentFieldStatus] = useState(null);
  const [currentFieldDescription, setCurrentFieldDescription] = useState(undefined);
  const [minValue, setMinValue] = useState(undefined);
  const [maxValue, setMaxValue] = useState(undefined);
  const [minLength, setMinLength] = useState(undefined);
  const [maxLength, setMaxLength] = useState(undefined);
  const [stringFormat, setStringFormat] = useState(undefined);
  const formId = props.formId;
  const fieldId = props.fieldId;
  const field = props.metadataFormField;

  useEffect(() => {
    setInitialFieldDetails(field);
  }, [field, fieldId]);

  const setInitialFieldDetails = (field) => {
    if (field) {
      setCurrentFieldName(field.Name);
      setCurrentFieldDescription(field.Description);
      setCurrentFieldStatus({
        label: field.Status,
        value: field.Status,
      });
      setCurrentFieldType(field.FieldType);
      if (field.FieldType == 'Field#String') {
        setStringFormat({
          label: field.FieldConfig.StringConfig.StringConfigFormat,
          value: field.FieldConfig.StringConfig.StringConfigFormat,
        });
        setMinLength(field.FieldConfig.StringConfig.MinLength);
        setMaxLength(field.FieldConfig.StringConfig.MaxLength);
      }
      if (field.FieldType == 'Field#Number') {
        setMinValue(field.FieldConfig.NumberConfig.MinValue);
        setMaxValue(field.FieldConfig.NumberConfig.MaxValue);
      }
      if (field.FieldType == 'Field#BusinessGlossary') {
        setMinValue(field.FieldConfig.BusinessGlossaryConfig.MinValue);
        setMaxValue(field.FieldConfig.BusinessGlossaryConfig.MaxValue);
      }
    }
  };

  const handleEditMetadataField = async () => {
    setButtonLoading(true);
    try {
      let newFieldConfig = undefined;
      if (currentFieldType == 'Field#String') {
        newFieldConfig = {
          StringConfig: {
            StringConfigFormat: stringFormat.value,
            MinLength: parseInt(minLength),
            MaxLength: parseInt(maxLength),
          },
        };
      }
      if (currentFieldType == 'Field#Number') {
        newFieldConfig = {
          NumberConfig: {
            Min: parseInt(minValue),
            Max: parseInt(maxValue),
          },
        };
      }
      if (currentFieldType == 'Field#Boolean') {
        newFieldConfig = {
          BooleanConfig: {},
        };
      }
      if (currentFieldType == 'Field#BusinessGlossary') {
        newFieldConfig = {
          BusinessGlossaryConfig: {
            MinValue: parseInt(minValue),
            MaxValue: parseInt(maxValue),
          },
        };
      }
      let editMetadataFieldRequest = {
        MetadataFieldId: fieldId,
        MetadataFormId: formId,
        NewName: currentFieldName,
        NewDescription: currentFieldDescription,
        NewStatus: currentFieldStatus.value,
        NewFieldConfig: newFieldConfig, // todo: generate field config based on tyep selected
      };
      let editMetadataFieldResult = await editMetadataField(editMetadataFieldRequest);
      if (editMetadataFieldResult.Message == 'Success') {
        props.setNotification('success', 'Successfully edited a field');
      } else {
        props.setNotification('error', `Failed to edit field. Reason: ${editMetadataFieldResult.Message}`);
      }
    } catch (err) {
      props.setNotification('error', `Error in editing field. ${err.message}`);
    } finally {
      setButtonLoading(false);
      props.close();
      props.handleRefresh();
    }
  };

  const enableEditButton = () => {
    return currentFieldName && currentFieldDescription && currentFieldType && currentFieldStatus;
  };

  return (
    <Modal
      onDismiss={() => {
        props.close();
      }}
      visible={props.visible}
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              variant='link'
              onClick={() => {
                props.close();
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              disabled={!enableEditButton()}
              onClick={handleEditMetadataField}
              loading={buttonLoading}
            >
              {'Save changes'}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header='Edit field in metadata form'
    >
      <ColumnLayout>
        <FormField label={'Form ID'}>
          <Input ariaRequired={true} value={formId} readOnly={true} disabled />
        </FormField>
        <FormField label={'Field name'} description='Enter the name for the field.'>
          <Input
            name='fieldName'
            placeholder='Enter the name for the field'
            value={currentFieldName}
            ariaRequired={true}
            onChange={({ detail }) => setCurrentFieldName(detail.value)}
          />
        </FormField>
        <FormField label={'Field description'} description='Enter the name for the field.'>
          <Input
            name='fieldDescription'
            placeholder='Enter the description of the field'
            value={currentFieldDescription}
            ariaRequired={true}
            onChange={({ detail }) => setCurrentFieldDescription(detail.value)}
          />
        </FormField>
        <FormField label={<div>Field status</div>} description='Select the status of the field.'>
          <Select
            name='fieldStatus'
            selectedOption={currentFieldStatus}
            ariaRequired={true}
            placeholder='Choose a type'
            onChange={({ detail }) => setCurrentFieldStatus(detail.selectedOption)}
            options={hcMetadataStatus}
            selectedAriaLabel='Selected'
          />
        </FormField>
        <FormField label={<div>Field type</div>}>
          <Input
            name='fieldType'
            placeholder='Choose a type'
            readOnly={true}
            disabled
            value={currentFieldType?.replace('Field#', '')}
          />
        </FormField>
        {currentFieldType && currentFieldType == 'Field#String' && (
          <FormField label={'String format'} description='String format.'>
            <Select
              name='stringFormat'
              placeholder='Enter the String format'
              selectedOption={stringFormat}
              ariaRequired={true}
              onChange={({ detail }) => setStringFormat(detail.selectedOption)}
              options={hcMetadataStringFormatType}
              selectedAriaLabel='Selected'
            />
          </FormField>
        )}
        {currentFieldType && currentFieldType == 'Field#String' && (
          <FormField label={'Minimum length'} description='Minimum length of a string'>
            <Input
              name='minLength'
              placeholder='0'
              value={minLength}
              type='number'
              onChange={({ detail }) => setMinLength(detail.value)}
              ariaRequired={true}
            />
          </FormField>
        )}
        {currentFieldType && currentFieldType == 'Field#String' && (
          <FormField label={'Maximum length'} description='Maximum length of a string'>
            <Input
              name='maxLength'
              placeholder='0'
              value={maxLength}
              type='number'
              onChange={({ detail }) => setMaxLength(detail.value)}
              ariaRequired={true}
            />
          </FormField>
        )}
        {currentFieldType && (currentFieldType == 'Field#Number' || currentFieldType == 'Field#BusinessGlossary') && (
          <FormField label={'Minimum value'} description='Minimum value'>
            <Input
              name='minValue'
              placeholder='0'
              value={minValue}
              type='number'
              onChange={({ detail }) => setMinValue(detail.value)}
              ariaRequired={true}
            />
          </FormField>
        )}
        {currentFieldType && (currentFieldType == 'Field#Number' || currentFieldType == 'Field#BusinessGlossary') && (
          <FormField label={'Maximum value'} description='Maximum value'>
            <Input
              name='maxValue'
              placeholder='0'
              value={maxValue}
              type='number'
              onChange={({ detail }) => setMaxValue(detail.value)}
              ariaRequired={true}
            />
          </FormField>
        )}
      </ColumnLayout>
    </Modal>
  );
};

export default EditMetadataFieldModal;
