import React, { useCallback, useEffect, useState } from 'react';
import { TilesProps, Container, Header, FormField, Tiles, SpaceBetween } from '@amzn/awsui-components-react-v3';
import ColumnPermissionsFormFields, { ColumnPermissionsChange } from './columnPermissionsFormFields';
import TablePermissionsFormFields, { TablePermissions } from './tablePermissionsFormFields';
import './permissionSelectorComponent.css';
import { Database } from 'aws-sdk/clients/glue';
import { DataLakePrincipal, Resource } from 'aws-sdk/clients/lakeformation';
import { HybridCatalogDataSet } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { isValidWorkspace } from 'src/commons/validationUtils';
import { isMPData } from 'src/components/workspaces/common/common';

type Tile = TilesProps.TilesDefinition;
type Tiles = TilesProps.TilesDefinition[];

interface PermissionSelectorProps {
  permissions: TablePermissions;
  table: HybridCatalogDataSet | undefined;
  database: Database | undefined;
  principal: DataLakePrincipal;
  onChange: (resource: Resource) => void;
  onPermissionsChange: (permissions: TablePermissions) => void;
}

const options = [
  {
    label: 'Table permissions',
    value: 'table',
    description: 'Grant table-wide grantable permissions.',
    disabled: true,
  },
  {
    label: 'Column-based permissions',
    value: 'column',
    description: 'Grant data access to specific columns.',
    disabled: true,
  },
  {
    label: 'Row-based permissions',
    value: 'row',
    description: 'Grant data access specific to rows.',
    disabled: true,
  },
];

type ResourceType = 'database' | 'table' | 'resourceLink';

/**
 * Utility function to determine what type of resource we're dealing with. A table and a database can both be
 * linked resources (a.k.a resources that have been shared). In order to determine if a table or a database
 * is a linked resource, we need to check for the existence of `TargetTable` and `TargetDatabase` respectively.
 *
 * More Info: https://docs.aws.amazon.com/lake-formation/latest/dg/resource-links-about.html
 */
const getResourceType = (table?: HybridCatalogDataSet, database?: Database): ResourceType => {
  if (table === undefined) {
    if (database && database['TargetDatabase']) return 'resourceLink';
    return 'database';
  }
  if (table && table['TargetTable']) return 'resourceLink';
  return 'table';
};

/**
 * Based on the type of resource, the first tile in the selection needs to display the correct label.
 * Initially, this was only supposed to support tables hence `option.value` is set to `table` which can be confusing.
 * We should change that to be something like `resource` since that tile captures any kind of resource. Not changing
 * it now since we dont have sufficient testing to catch all the dependencies.
 *
 * TODO: Change `option.value` for the first tile to be `resource`.
 */
const getTileLabel = (option: Tile, table?: HybridCatalogDataSet, database?: Database) => {
  if (option.value === 'table') {
    const resource = getResourceType(table, database);
    if (resource === 'table') return 'Table permissions';
    if (resource === 'resourceLink') return 'Resource link permissions';
    return 'Table permissions';
  }
  return option.label;
};

/**
 * We only want to allow row and column permissions for tables. Given thats the case, these two options should
 * be disabled if the resource is a `Table` or a `ResourceLink`. See above for more details on linked resources.
 */
const isTileDisabled = (option: Tile, table?: HybridCatalogDataSet) => {
  const noTable = table === undefined;
  //Disable if there is no table
  if (noTable) {
    return true;
  }

  if (isMPData(table?.IdInfo['CatalogId'], table?.IdInfo['DatabaseName'], table?.IdInfo['TableName'])) {
    return true;
  }

  const isWorkspaceData = table.Owners != null && isValidWorkspace(table.Owners);
  const isAllTables = table['Id'] === 'All|Tables' || table?.IdInfo?.TableName == 'ALL_TABLES';
  const isOptionalTile = option.value === 'row' || option.value === 'column';
  const isTableTile = option.value === 'table';
  const isColumns = table['Columns'] !== undefined;
  // rows disabled till LF supports it.
  return (
    noTable ||
    option.value === 'row' ||
    (isAllTables && isOptionalTile) ||
    (isColumns && isTableTile) ||
    (isWorkspaceData && isOptionalTile)
  );
};

const PermissionSelector = (props: PermissionSelectorProps) => {
  const { database, table, onChange } = props;
  const [permissionOptions, setPermissionOptions] = useState<Tiles>(options);
  const [selectedOption, setSelectedOption] = useState(undefined);

  useEffect(() => {
    const updatedPermissionOptions = options.map((option: Tile) => {
      return {
        ...option,
        label: getTileLabel(option, table, database),
        disabled: isTileDisabled(option, table),
      };
    });

    setPermissionOptions(updatedPermissionOptions);
    if (table !== undefined) {
      setSelectedOption(table['Columns'] === undefined ? 'table' : 'column');
    } else {
      setSelectedOption(undefined);
    }
    onChange(undefined);
  }, [table, database]);

  const handleOptionChange = useCallback((e: CustomEvent<TilesProps.ChangeDetail>) => {
    setSelectedOption(e.detail.value);
    if (e.detail.value == 'table') onChange(undefined);
  }, []);

  const handleTablePermissionsChange = useCallback(
    (permissions: TablePermissions) => {
      props.onPermissionsChange(permissions);
    },
    [props.onPermissionsChange],
  );

  const handleColumnPermissionsChange = useCallback(
    (columnPermissions: ColumnPermissionsChange) => {
      // If a table does not exist, this change should not fire.
      if (!table) {
        return;
      }
      let TableWithColumns = {
        CatalogId: table['IdInfo'].CatalogId,
        DatabaseName: table['IdInfo'].DatabaseName || '',
        Name: table['IdInfo'].TableName,
        ColumnNames: undefined,
        ColumnWildcard: undefined,
      };
      if (columnPermissions.columnPermissionType === 'include') {
        TableWithColumns = {
          ...TableWithColumns,
          ColumnNames: columnPermissions.columns.map((item) => item.value),
        };
      } else {
        TableWithColumns = {
          ...TableWithColumns,
          ColumnWildcard: {
            excludedColumnNames: columnPermissions.columns.map((item) => item.value),
          },
        };
      }

      const resource: Resource = { TableWithColumns };

      onChange(resource);
    },
    [table, onChange],
  );

  return (
    <Container
      header={
        <Header variant='h2' description='Select the permissions to grant.'>
          Permissions
        </Header>
      }
    >
      <SpaceBetween size='m'>
        <FormField stretch={true}>
          <Tiles value={selectedOption} items={permissionOptions} onChange={handleOptionChange} />
        </FormField>
        <FormField>
          <div className='divider-base divider-display' />
        </FormField>
        {!(table === undefined) && selectedOption === 'table' && (
          <TablePermissionsFormFields
            permissions={props.permissions}
            principal={props.principal}
            onChange={handleTablePermissionsChange}
            resource={getResourceType(table, database)}
          />
        )}
        {!(table === undefined) && selectedOption === 'column' && (
          <ColumnPermissionsFormFields
            table={table}
            permissions={props.permissions}
            onChange={handleColumnPermissionsChange}
            onGrantablePermissionsChange={handleTablePermissionsChange}
          />
        )}
      </SpaceBetween>
    </Container>
  );
};

export default PermissionSelector;
