import * as React from 'react';
import { MetricTableView } from 'src/components/metricstore/metriccollection/metricTableView';
import { ViewType } from "src/components/metricstore/helpers";

export interface quarterlyViewProps {
  setContentType: any;
  match: any;
}

export const QuarterlyView = (props: quarterlyViewProps) => {
  return <MetricTableView props={props} viewType={ViewType.QUARTERLY} columnCount={4} />;
};
