import React from 'react';

import { Header, Textarea, Container, FormField } from '@amzn/awsui-components-react-v3';
interface useCaseProps {
  value: string;
  formSubmitted?: boolean;
  onChange?: (event: string | undefined) => void;
}

const UseCaseComponent = (props: useCaseProps) => {
  const { onChange, value } = props;

  const handleUseCaseChange = (event: CustomEvent | undefined) => {
    if (onChange) onChange(event['detail'].value);
  };

  return (
    <Container header={<Header variant='h2'>Use Case</Header>}>
      <FormField errorText={props.formSubmitted && !value && 'Use case is required.'}>
        <Textarea value={value} onChange={handleUseCaseChange} placeholder='Enter use case' />
      </FormField>
    </Container>
  );
};

export default UseCaseComponent;
