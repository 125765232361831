import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Flashbar,
  FormField,
  Input,
  Header,
  ColumnLayout,
  Container,
} from '@amzn/awsui-components-react-v3';
import { Page } from 'src/routes';
import { Redirect } from 'react-router-dom';
import { getWorkspace, editWorkspace } from 'src/api/permissions';
import { scrollUp } from 'src/components/utils/navigation';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface WorkspaceUpdateProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  username: string;
}

export const WorkspaceUpdate = (props: WorkspaceUpdateProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [workspaceName, setWorkspaceName] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    const getWorkspaceResult = await getWorkspace({
      workspaceId: props.match.params.workspaceId,
    });
    if (getWorkspaceResult.succeeded) {
      setWorkspaceName(getWorkspaceResult.workspace.workspaceName);
    }
  };

  const handleEdit = async () => {
    setButtonLoading(true);
    try {
      const editWorkspaceResult = await editWorkspace({
        workspaceId: props.match.params.workspaceId,
        workspaceName: workspaceName,
      });
      if (editWorkspaceResult.succeeded) {
        setButtonLoading(false);
        setNotifications([
          {
            type: 'success',
            content: `Successfully updated workspace.`,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      } else {
        setButtonLoading(false);
        setNotifications([
          {
            type: 'error',
            content: 'Failed to update workspace. Reason:' + editWorkspaceResult.message,
            dismissible: true,
            onDismiss: () => setNotifications([]),
          },
        ]);
      }
    } catch (e) {
      setButtonLoading(false);
      setNotifications([
        {
          type: 'error',
          content: 'Failed to update workspace. Reason:' + e.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    scrollUp();
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header description='You can update your workspace here'>
            <h1 className='awsui-util-d-ib'>Update workspace</h1>
          </Header>
        }
        actions={
          <div>
            <Button variant='link' onClick={() => setRedirect(Page.BROWSE_WORKSPACES)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleEdit} loading={buttonLoading}>
              Submit
            </Button>
          </div>
        }
      >
        <Container className='custom-screenshot-hide' header={<h2>Update workspace</h2>}>
          <ColumnLayout>
            <>
              <FormField label={<div>Workspace Name</div>} description='The name of the workspace.'>
                <Input
                  onChange={(event) => setWorkspaceName(event.detail.value)}
                  ariaRequired={true}
                  value={workspaceName}
                />
              </FormField>
            </>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};
