import * as React from 'react';
import { useEffect, useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  CollectionPreferences,
  Header,
  Input,
  Modal,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3/polaris';

import { scrollUp } from '../utils/navigation';

import { deactivateDataPermission, getDataPermissionRequests, listDataPermissions } from '../../api/permissions';
import { PageHeader } from '../subscriptions/common';
import { EmptyState } from 'src/commons/EmptyState';
import { DEFAULT_CANCEL_REASON, resourceColumnDefinitions } from 'src/components/workspace_access/utils';
import { Flashbar } from '@amzn/awsui-components-react-v3';
import { GladstoneDataPermissionItem, ListDataPermissionsRequest } from 'aws-sdk/clients/awsdatalakegladstonelambda';
import {
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_STATUS_ACTIVE,
  RESOURCE_LINK_RES_TYPE,
} from 'src/commons/constants';
import { getRegion } from 'src/api/config';
import { flattenDataPermission, generateCsvFileName, getCsvDataForDataPermission } from 'src/commons/common';
import _ from 'lodash';
import CsvDownloaderWrapper, { generateCsvColumnDefinitions } from 'src/components/common/csvDownloaderWrapper';

export interface ResourcesTableProps {
  setContentType: any;
  isIncomingRequests: boolean;
  activeGroup?: string;
  activeWorkspace?: string;
}

export interface ResourcesTableState {
  filterText: string;
}

// const DEFAULT_CANCEL_REASON = 'Please cancel the request.';

interface iResourcesTable {
  isIncomingRequests: boolean;

  setContentType(contentType: string): void;

  activeGroup: string;
  activeWorkspace: any;
}

export function ResourcesTable({ isIncomingRequests, setContentType, activeGroup, activeWorkspace }: iResourcesTable) {
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [allItems, setAllItems] = useState([]);
  const [, setSelected] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [columnDefinitions] = useState(resourceColumnDefinitions);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [cancelRequestLoading, setCancelRequestLoading] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [csvColumnDefinition, setCsvColumnDefinition] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const refForCSVDownloader: React.RefObject<any> = React.createRef();
  const onClickDownload = () => {
    refForCSVDownloader.current.handleClick();
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: (
          <EmptyState
            title='No instances'
            subtitle='No instances to display.'
            action={<Button>Create instance</Button>}
          />
        ),
        noMatch: (
          <EmptyState
            title='No matches'
            subtitle='We can’t find a match.'
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 25 },
      sorting: {},
      selection: {},
    },
  );
  const { selectedItems } = collectionProps;

  // component did mount
  useEffect(() => {
    setContentType('table');
  }, []);

  // component did update
  useEffect(() => {
    const refresh = async () => {
      await handleRefresh();
    };
    refresh();
  }, [activeGroup, activeWorkspace, isIncomingRequests]);

  const handleCancel = async () => {
    setCancelRequestLoading(true);

    let finalCancelReason = DEFAULT_CANCEL_REASON;
    if (cancelReason !== '') {
      finalCancelReason = cancelReason;
    }
    try {
      for (var i = 0; i < selectedItems.length; i++) {
        await deactivateDataPermission({
          dataPermissionId: selectedItems[i].dataPermissionId,
          reasonOfAction: finalCancelReason,
        });
      }
      await handleRefresh();
      setNotifications([
        {
          type: 'success',
          content: 'Submitted request for cancellation',
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Permission cancel failed. Reason: ${err.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      console.log(err);
    }

    setCancelRequestLoading(false);
    setCancelReason('');
    setCancelModalVisible(false);
    scrollUp();
  };

  const handleRefresh = async () => {
    if (!activeWorkspace) return;
    setRequestsLoading(true);
    if (isIncomingRequests) {
      await getIncomingAccess();
    } else {
      await getOutgoingAccess();
    }
  };

  const fetchActiveDataPermissions = async (request: ListDataPermissionsRequest) => {
    try {
      let response = await listDataPermissions(request);
      let dataPermissions = [...response.dataPermissionList];
      //Loop and get remaining tables
      while (response.nextToken != null) {
        request.nextToken = response.nextToken;
        response = await listDataPermissions(request);
        dataPermissions.push(...response.dataPermissionList);
      }
      return dataPermissions;
    } catch (err) {
      console.log('Unable to fetch data permissions for ' + JSON.stringify(request) + ' ' + err.message);
    }
  };

  const getResource = (dp: GladstoneDataPermissionItem) => {
    if (dp.resource.tableWithColumns != undefined) {
      return dp.resource.tableWithColumns;
    } else if (dp.resource.table != undefined) {
      return dp.resource.table;
    } else if (dp.resource.database != undefined) {
      return dp.resource.database;
    } else if (dp.resource.dataCellsFilter != undefined) {
      return dp.resource.dataCellsFilter;
    }
  };

  const getIncomingAccess = async () => {
    if (!activeWorkspace) return;
    setRequestsLoading(true);
    const requests = [];
    try {
      let nextToken = undefined;
      do {
        let dataPermissionAccessRequest = {
          workspaceId: activeWorkspace.workspaceId,
          isIncomingRequests: isIncomingRequests,
          includesRequestHistory: true,
          nextToken: null,
        };
        if (nextToken != undefined) {
          dataPermissionAccessRequest['nextToken'] = nextToken;
        }
        const getNamedResourceAccessRequestResult = await getDataPermissionRequests(dataPermissionAccessRequest);
        nextToken = getNamedResourceAccessRequestResult.nextToken;
        requests.push(...getNamedResourceAccessRequestResult.requestList);
      } while (nextToken != undefined);
    } catch (err) {
      console.log(err.message);
    }

    // filter approved requests from history and generate list datapermission request payload
    let approvedPermissionRequests = requests
      .filter(
        (req) =>
          req.status == 'APPROVED' &&
          req.dataPermissionRequest.resource.lFTagPolicy == undefined &&
          req.dataPermissionRequest.resource.redshiftTagPolicy == undefined,
      )
      .map((r) => ({
        region: r.dataPermissionRequest.dataLocationRegion,
        resource: r.dataPermissionRequest.resource,
        option: DATA_PERMISSION_CONSUMER_OPTION,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        nextToken: null,
      }));

    // remove duplicate requests
    approvedPermissionRequests = approvedPermissionRequests.filter(
      (request, i) =>
        i === approvedPermissionRequests.findIndex((req) => JSON.stringify(req) === JSON.stringify(request)),
    );

    // invoke list data permissions parallely with limit 5
    let listPermissionResponses = [];
    const parallelInvocationSize = 5;
    for (let i = 0; i < approvedPermissionRequests.length; i += parallelInvocationSize) {
      // Array.slice end can be > array.length, it returns upto array.length.
      const limitedRequests = approvedPermissionRequests.slice(i, i + parallelInvocationSize);
      const responses = await Promise.all(limitedRequests.map((req) => fetchActiveDataPermissions(req)));
      listPermissionResponses.push(...responses);
    }
    // filter out resource links, inactive, publishers
    const allDataPermissionsExceptInactive = listPermissionResponses
      .flat(1)
      .filter(
        (dp) =>
          dp.status === DATA_PERMISSION_STATUS_ACTIVE &&
          dp.option === DATA_PERMISSION_CONSUMER_OPTION &&
          dp.resource.glueDatabase === undefined &&
          dp.resource.glueTable === undefined,
      )
      .map((dp) => ({
        workspace: dp.ownerId,
        dataLakePrincipal: dp.dataLakePrincipal,
        catalogId: getResource(dp)['catalogId'] ? getResource(dp)['catalogId'] : getResource(dp)['tableCatalogId'],
        clusterId: getResource(dp)['clusterId'],
        databaseName: getResource(dp)['databaseName'],
        schemaName: getResource(dp)['schemaName'],
        tableName: getResource(dp)['tableName'] ? getResource(dp)['tableName'] : getResource(dp)['name'],
        fgapName: dp.resource?.dataCellsFilter?.name,
        dataPermissionId: dp.dataPermissionId,
        status: dp.status,
        statusReason: dp.statusReason,
      }));
    setAllItems(allDataPermissionsExceptInactive);
    if (listPermissionResponses) {
      let csvData = listPermissionResponses
        .flat(1)
        .filter((dp) => RESOURCE_LINK_RES_TYPE !== dp.resourceType)
        .map((item) => flattenDataPermission(item))
        .filter((item) => !_.isEmpty(item))
        .map((item) => getCsvDataForDataPermission(item));
      setCsvData(csvData);
      setCsvColumnDefinition(generateCsvColumnDefinitions(csvData));
    }
    setRequestsLoading(false);
    setSelected(undefined);
  };

  const getOutgoingAccess = async () => {
    const dataPermissions = await fetchActiveDataPermissions({
      ownerId: activeWorkspace.workspaceId,
      region: getRegion(),
      option: DATA_PERMISSION_CONSUMER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      nextToken: null,
    });
    // remove resource links and tag based permissions
    const activeDataPermissions = dataPermissions
      .filter(
        (dp) =>
          dp.resource.lFTagPolicy === undefined &&
          dp.resource.glueDatabase === undefined &&
          dp.resource.glueTable === undefined &&
          dp.resource.redshiftTagPolicy === undefined,
      )
      .map((dp) => ({
        workspace: dp.audit.DataOwnerId,
        dataLakePrincipal: dp.dataLakePrincipal,
        clusterId: getResource(dp)['clusterId'],
        databaseName: getResource(dp)['databaseName'],
        schemaName: getResource(dp)['schemaName'],
        tableName: getResource(dp)['tableName'] ? getResource(dp)['tableName'] : getResource(dp)['name'],
        catalogId: getResource(dp)['catalogId'] ? getResource(dp)['catalogId'] : getResource(dp)['tableCatalogId'],
        fgapName: dp?.resource?.dataCellsFilter?.name,
        dataPermissionId: dp.dataPermissionId,
        status: dp.status,
        statusReason: dp.statusReason,
      }));
    setAllItems(activeDataPermissions);
    if (dataPermissions) {
      let csvData = dataPermissions
        .filter((dp) => RESOURCE_LINK_RES_TYPE !== dp.resourceType)
        .map((item) => flattenDataPermission(item))
        .filter((item) => !_.isEmpty(item))
        .map((item) => getCsvDataForDataPermission(item));
      setCsvData(csvData);
      setCsvColumnDefinition(generateCsvColumnDefinitions(csvData));
    }
    setRequestsLoading(false);
    setSelected(undefined);
  };

  const options = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Cancel',
        onItemClick: () => setCancelModalVisible(true),
        loading: requestsLoading,
        disabled: selectedItems.length === 0,
      },
    ];
  };

  return (
    <>
      <Flashbar items={notifications} />
      <Table
        {...collectionProps}
        loadingText='Loading permissions...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={false}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={options()}
              header={<Header counter={`(${allItems.length})`}>Workspace Resource-Based Access</Header>}
              additionalItems={
                !requestsLoading &&
                csvData.length > 0 && (
                  <>
                    <Button variant='primary' onClick={onClickDownload} iconName='download'>
                      Download as CSV
                    </Button>
                    <CsvDownloaderWrapper
                      rowItems={csvData}
                      fileName={generateCsvFileName(
                        'permissions',
                        activeWorkspace ? activeWorkspace.workspaceName : null,
                      )}
                      refForCsvDownloader={refForCSVDownloader}
                      userFriendlyDataDefinitions={csvColumnDefinition}
                    />
                  </>
                )
              }
            />
          </>
        }
        empty={<EmptyState title='No workspaces' subtitle='No workspaces were found.' />}
        loading={requestsLoading}
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            preferences={{
              pageSize: 25,
            }}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 25, label: '25 items' },
                { value: 50, label: '50 items' },
                { value: 100, label: '100 items' },
              ],
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Enable to wrap table cell content, disable to truncate text.',
            }}
          />
        }
        selectionType='multi'
        selectedItems={selectedItems}
        filter={
          <TextFilter
            {...filterProps}
            filteringPlaceholder='Find resources'
            filteringAriaLabel='Filter IAM access requests'
            countText={`${filteredItemsCount} ${filteredItemsCount > 1 ? 'matches' : 'match'}`}
          />
        }
        pagination={<Pagination {...paginationProps} />}
      />
      <Modal
        onDismiss={() => setCancelModalVisible(false)}
        visible={cancelModalVisible}
        size='small'
        footer={
          <span className='awsui-util-f-r'>
            <Button
              variant='link'
              onClick={() => {
                setCancelModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' onClick={handleCancel} loading={cancelRequestLoading}>
              Confirm
            </Button>
          </span>
        }
        header={'Please provide the reason for the request cancellation.'}
      >
        <Input
          placeholder='Please provide your reason.'
          onChange={(e) => setCancelReason(e.detail.value)}
          value={cancelReason}
        />
      </Modal>
    </>
  );
}
