import { Alert, Checkbox, Container, Header, SpaceBetween, TextContent } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { ImpactedResourcesEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/impactedResourcesEditor';
import { AttachedTicketsEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/attachedTicketsEditor';
import { CustomersEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/customersEditor';
import { ContactInfoEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/contactInfoEditor';
import {
  AdvisoryAttachedTicketList,
  AdvisoryContactInfoList,
  AdvisoryContent,
  AdvisoryCustomerList,
  AdvisoryImpactedResourceList,
  AdvisoryLifecycleConfiguration,
} from 'aws-sdk/clients/awsdlomni';
import { LifecycleConfigurationEditor } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/lifecycleConfigurationEditor';
import { safelyRenderedAdvisoryMarkdownContent } from 'src/components/dataadvisory/constants';
import { AdvisoryChangeMode } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/createOrUpdateAdvisoryWizard';

export interface ReviewAndSubmitContentProps {
  mode: string; // Create or Edit
  title: string;
  content: AdvisoryContent;
  impactedResources: AdvisoryImpactedResourceList;
  attachedTickets: AdvisoryAttachedTicketList;
  customers: AdvisoryCustomerList;
  contacts: AdvisoryContactInfoList;
  lifecycleConfiguration: AdvisoryLifecycleConfiguration;
  activateUponCreation: boolean;
  setActivateUponCreation: any;
  activeGroup: string;
  activeWorkspace?: any;
}

export const ReviewAndSubmitContent = (props: ReviewAndSubmitContentProps) => {
  return (
    <SpaceBetween size={'l'} direction={'vertical'}>
      <Header variant={'h2'}>Review title and content</Header>
      <Container header={<Header variant={'h2'}>{props.title}</Header>}>
        <TextContent>
          {props.content.format == 'Markdown'
            ? safelyRenderedAdvisoryMarkdownContent(props.content.rawContent)
            : props.content.rawContent}
        </TextContent>
      </Container>
      <Header variant={'h2'}>Review resources</Header>
      <ImpactedResourcesEditor
        impactedResources={props.impactedResources}
        readonly={true}
        activeGroup={props.activeGroup}
        activeWorkspace={props.activeWorkspace}
      />
      <Header variant={'h2'}>Review tickets</Header>
      <AttachedTicketsEditor attachedTickets={props.attachedTickets} readonly={true} />
      <Header variant={'h2'}>Review customers</Header>
      <CustomersEditor
        customers={props.customers}
        impactedResources={props.impactedResources}
        readonly={true}
        activeGroup={props.activeGroup}
        activeWorkspace={props.activeWorkspace}
      />
      <Header variant={'h2'}>Review contact information</Header>
      <ContactInfoEditor contactInfo={props.contacts} readonly={true} />
      <Header variant={'h2'}>Review lifecycle configuration</Header>
      <LifecycleConfigurationEditor
        lifecycleConfiguration={props.lifecycleConfiguration}
        setLifecycleConfiguration={() => {}}
        setErrorMessage={() => {}}
        readonly={true}
      />
      {props.mode != AdvisoryChangeMode.UPDATE_TEMPLATE_MODE && (
        <Alert statusIconAriaLabel='Info' header={'Save as template'}>
          To save this content as an advisory template for future advisories, select <b>Advisory templates</b>, then{' '}
          <b>Save as template</b>, below.
        </Alert>
      )}
      {props.mode == AdvisoryChangeMode.CREATE_ADVISORY_MODE && (
        <Checkbox
          checked={props.activateUponCreation}
          onChange={(e) => props.setActivateUponCreation(e.detail.checked)}
        >
          Activate advisory upon creation
        </Checkbox>
      )}
    </SpaceBetween>
  );
};
