import React, { useEffect, useState } from 'react';
import { StatusIndicator, AppLayoutProps, SpaceBetween, Header, Button, Link } from '@amzn/awsui-components-react-v3';

import { Dashboard, DashboardModel } from '@amzn/cloudwatchdashboards-inside';
import { metricsChart, recordsChart } from './widgets/charts';
import { ingestionKpis } from './widgets/kpis';
import { ingestionLogs } from './widgets/logs';
import { Redirect } from 'react-router-dom';

import { parseTethysARN } from '../common/utils';
import { UX_WRITING } from '../common/uxWriting';
import { getTethysAccount } from './../../../api/config';
import { Page } from 'src/routes';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { docs } from '../common/constants';

const mapAccountByURL = getTethysAccount();

const config = {
  displayMode: 'static',
  heightUnit: 24,
  widgetMarginX: 20,
  widgetMarginY: 20,
  style: 'polarisCard',
  internal: {
    accountId: mapAccountByURL,
  },
};

interface iRouteParams {
  arn: string;
  dataset: string;
  database: string;
  team: string;
}

const buildWidgets = ({ arn, dataset, database }: iRouteParams): DashboardModel => {
  const catalog = parseTethysARN(arn).accountId;
  const namespace = `Tethys/${catalog}`;
  const datasetId = arn.includes('datasource') ? `${arn}/${dataset}` : `${arn}/${database}/${dataset}`;

  return {
    widgets: [
      ...ingestionKpis(namespace, datasetId),
      recordsChart(namespace, datasetId),
      metricsChart(namespace, datasetId),
      ingestionLogs({ catalog, database, dataset }),
    ],
  };
};

interface iMonitoringDashboardProps {
  params: any;
}
interface iMonitoringDashboardState {
  loading: boolean;
}

export class MonitoringDashboard extends React.Component<iMonitoringDashboardProps, iMonitoringDashboardState> {
  private dashboard;
  private dashboardContainer;

  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.displayDashboard = this.displayDashboard.bind(this);
  }

  componentDidMount() {
    this.dashboard = new Dashboard(this.dashboardContainer, 'Vasya-test-dashboard', 'us-east-1', config);
    this.dashboard.on('ready', this.displayDashboard);
  }

  componentWillUnmount() {
    this.dashboard.release();
  }

  render() {
    const { loading } = this.state;

    return (
      <div>
        {loading && <StatusIndicator type='loading'>Loading</StatusIndicator>}
        <div ref={(div) => (this.dashboardContainer = div)} />
      </div>
    );
  }

  displayDashboard() {
    const widgets = buildWidgets(this.props.params);
    this.dashboard.update(widgets);
    this.setState({ loading: false });
  }
}

interface iMonitoring {
  params: iRouteParams;
  toggleHelp(): void;
  setContentType(contentType: AppLayoutProps.ContentType): void;
}

export const Monitoring = ({ params, toggleHelp, setContentType }: iMonitoring) => {
  useEffect(() => {
    setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const [redirect, setRedirect] = useState<string>();
  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <SpaceBetween size='xxxs'>
        <Header
          variant='h1'
          info={
            <Link variant='info' onFollow={toggleHelp}>
              {' '}
              Info{' '}
            </Link>
          }
          actions={
            <SpaceBetween direction='horizontal' size='s'>
              <Button variant='normal' href={docs.monitoring}>
                Alarm configuration
              </Button>
              <Button variant='primary' onClick={() => setRedirect(Page.INGESTION.REGISTER_CONTRACT)}>
                {UX_WRITING.REGISTER_DATASET}
              </Button>
            </SpaceBetween>
          }
        >
          Ingestion Monitoring - {params.dataset}
        </Header>
        <MonitoringDashboard params={params} />
      </SpaceBetween>
    </>
  );
};
