import { Input, Header, Select, Container, FormField, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { iStep } from '../utils/types';
import {
  isSourceDDB,
  isSourceS3,
  getDataSourceErrors,
  getDataFormatErrors,
  getFieldDelimiterErrors,
  getS3BucketErrors,
  getDDBTableNameErrors,
} from '../utils/validation';
import { testStrings } from '../../common/testStrings';
import { fileFormats, sourceTypes } from '../../common/constants';
import { useEffect } from 'react';
import * as React from 'react';
import { capitalize } from '../utils/content';

export const SourceStep = ({ request, setRequest, isValidated, isCreate }: iStep) => {
  useEffect(() => {
    // Set the data format when selecting DDB.
    if (request.DataSource.SourceType === sourceTypes.DynamoDB)
      return setRequest({
        ...request,
        DataContract: {
          ...request.DataContract,
          DataFormat: fileFormats.ddbJson,
        },
      });

    // Reset the format when S3 is selected (after DDB was selected).
    if (request.DataContract.DataFormat === fileFormats.ddbJson)
      return setRequest({
        ...request,
        DataContract: {
          ...request.DataContract,
          DataFormat: '',
        },
      });
  }, [request.DataSource.SourceType]);

  return (
    <SpaceBetween direction='vertical' size='xl'>
      <Container header={<Header variant='h2'>Source type</Header>}>
        <SpaceBetween direction='vertical' size='m'>
          <FormField
            label='Data source'
            description={`The service from which the data originates.`}
            errorText={isValidated ? getDataSourceErrors(request, isCreate) : ''}
          >
            <Select
              selectedAriaLabel='Selected'
              data-testid={testStrings.contractRegistration.step2.dataSource}
              disabled={!isCreate}
              options={[
                { label: sourceTypes.DynamoDB, value: sourceTypes.DynamoDB },
                { label: sourceTypes.S3, value: sourceTypes.S3 },
              ]}
              selectedOption={{
                label: request.DataSource.SourceType,
                value: request.DataSource.SourceType,
              }}
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataSource: {
                    SourceType: detail.selectedOption.value,
                  },
                })
              }
            />
          </FormField>

          {isSourceS3(request) && (
            <FormField
              label='S3 file format'
              description={`The file format of the files stored on S3.`}
              errorText={isValidated ? getDataFormatErrors(request, isCreate) : ''}
            >
              <Select
                selectedAriaLabel='Selected'
                data-testid={testStrings.contractRegistration.step2.dataFormat}
                disabled={!isCreate}
                options={[
                  { label: 'Delimited', value: fileFormats.delimited },
                  { label: 'Parquet', value: fileFormats.parquet },
                ]}
                selectedOption={{
                  label: capitalize(request.DataContract.DataFormat),
                  value: request.DataContract.DataFormat,
                }}
                onChange={({ detail }) =>
                  setRequest({
                    ...request,
                    DataContract: {
                      ...request.DataContract,
                      DataFormat: detail.selectedOption.value,
                    },
                  })
                }
              />
            </FormField>
          )}

          {isSourceDDB(request) && (
            <SpaceBetween direction='vertical' size='m'>
              <FormField
                label={<span>DynamoDB table ARN</span>}
                data-testid={testStrings.contractRegistration.step2.ddbTable}
                errorText={isValidated ? getDDBTableNameErrors(request) : ''}
              >
                <Input // TODO: Reset DDB ARN when moving to step 3, if S3 is selected.
                  value={request.DataSource.DynamoDBSourceProperties?.DynamoTableArn}
                  disabled={!isCreate}
                  onChange={({ detail }) =>
                    setRequest({
                      ...request,
                      DataSource: {
                        ...request.DataSource,
                        DynamoDBSourceProperties: {
                          ...request.DataSource.DynamoDBSourceProperties,
                          DynamoTableArn: detail.value,
                        },
                      },
                    })
                  }
                />
              </FormField>
            </SpaceBetween>
          )}
        </SpaceBetween>
      </Container>

      {['FILE', fileFormats.delimited].includes(
        // File type supported for legacy data contracts.
        request.DataContract.DataFormat,
      ) && (
        <Container header={<Header variant='h2'>File format</Header>}>
          <SpaceBetween direction='vertical' size='m'>
            <FormField
              label='Skipped header rows'
              description='The number of header lines that will be skipped during ingestion'
            >
              <Input
                type='number'
                value={request.DataContract.FileProperties.HeaderLines.toString()}
                placeholder='0'
                onChange={({ detail }) =>
                  setRequest({
                    ...request,
                    DataContract: {
                      ...request.DataContract,
                      FileProperties: {
                        ...request.DataContract.FileProperties,
                        HeaderLines: Number(detail.value),
                      },
                    },
                  })
                }
              />
            </FormField>

            <FormField
              label='Field delimiter'
              data-testid={testStrings.contractRegistration.step2.delimiter}
              description='What delimiter will the CSV files use?'
              errorText={isValidated ? getFieldDelimiterErrors(request) : ''}
            >
              <Input
                value={request.DataContract.FileProperties.FieldDelimiter}
                onChange={({ detail }) =>
                  setRequest({
                    ...request,
                    DataContract: {
                      ...request.DataContract,
                      FileProperties: {
                        ...request.DataContract.FileProperties,
                        FieldDelimiter: detail.value,
                      },
                    },
                  })
                }
              />
            </FormField>

            <FormField
              label={
                <span>
                  {' '}
                  <i>Compression method - optional</i>
                </span>
              }
              description='Are data files compressed?'
            >
              <Select
                selectedAriaLabel='Selected'
                options={[
                  { label: 'None', value: null },
                  { label: 'GZIP', value: 'GZIP' },
                ]}
                selectedOption={{
                  label: request.DataContract.DataProperties.CompressionFormat,
                  value: request.DataContract.DataProperties.CompressionFormat,
                }}
                onChange={({ detail }) =>
                  setRequest({
                    ...request,
                    DataContract: {
                      ...request.DataContract,
                      DataProperties: {
                        ...request.DataContract.DataProperties,
                        CompressionFormat: detail.selectedOption.value,
                      },
                    },
                  })
                }
              />
            </FormField>
          </SpaceBetween>
        </Container>
      )}

      {
        // TODO: Add description to container with link to documentation.
        isSourceS3(request) && !!request.DataContract.DataFormat && (
          <Container
            header={
              <Header variant='h2'>
                S3 properties - <i>Optional</i>
              </Header>
            }
          >
            <SpaceBetween direction='vertical' size='m'>
              <FormField
                label='Bucket name'
                description='In what bucket is the raw data stored?'
                errorText={isValidated ? getS3BucketErrors(request) : ''}
              >
                <Input
                  value={request.DataSource.S3SourceProperties?.Bucket}
                  data-testid={testStrings.contractRegistration.step2.bucket}
                  onChange={({ detail }) =>
                    setRequest({
                      ...request,
                      DataSource: {
                        SourceType: request.DataSource?.SourceType,
                        ...((detail.value || // Only set property if input has lenght.
                          request.DataSource?.S3SourceProperties?.Prefix) && {
                          S3SourceProperties: {
                            ...request.DataSource.S3SourceProperties,
                            Bucket: detail.value,
                          },
                        }),
                      },
                    })
                  }
                />
              </FormField>

              <FormField
                label={<span>Prefix or wildcard</span>}
                description='Used to define EventBridge content filtering rules'
              >
                <Input
                  value={request.DataSource.S3SourceProperties?.Prefix}
                  data-testid={testStrings.contractRegistration.step2.prefix}
                  onChange={({ detail }) =>
                    setRequest({
                      ...request,
                      DataSource: {
                        SourceType: request.DataSource?.SourceType,
                        ...((detail.value || // Only set property if input has lenght.
                          request.DataSource?.S3SourceProperties?.Bucket) && {
                          S3SourceProperties: {
                            ...request.DataSource.S3SourceProperties,
                            Prefix: detail.value,
                          },
                        }),
                      },
                    })
                  }
                />
              </FormField>
            </SpaceBetween>
          </Container>
        )
      }
    </SpaceBetween>
  );
};
