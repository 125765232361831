import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3';

import { defaultWrapLinesPreference, mediumPageSizePreference, paginationLabels } from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface WorkspaceResourceStatusProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  setActiveTabId: any;
  service: any;
  name: any;
}

export const WorkspaceResourceStatus = (props: WorkspaceResourceStatusProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResource, setLoadingResource] = useState(false);
  const handleRefresh = () => {
    setLoadingResource(true);
    props.setActiveTabId('resourceStatus');
    let itemList = [];
    if (props.service !== undefined && props.service !== null) {
      for (let resource in props.service) {
        let resourceName = getResourceNameToBeDisplayed(resource);
        let status = getResourceStatusToBeDisplayed(props.service[resource].status);
        if (status != undefined) {
          itemList.push({
            resource: resourceName,
            status: status,
            statusReason: props.service[resource].statusReason,
            updateDate: props.service[resource].updateDate,
          });
        }
      }
    }
    setItems(itemList);
    setLoadingResource(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'resource',
      header: 'Resource',
      cell: (item) => item.resource,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => item.status,
      minWidth: 200,
    },
    {
      id: 'statusReason',
      header: 'Status Reason',
      cell: (item) => item.statusReason,
      minWidth: 200,
    },
    {
      id: 'updateDate',
      header: 'Update date',
      cell: (item) => item.updateDate,
      minWidth: 200,
    },
  ];

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading resource status...'
        loading={loadingResource}
        columnDefinitions={columnDefinitions}
        items={items}
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No resources</b>
            <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
              No resources to display.
            </Box>
          </Box>
        }
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <Header description={`tasks for ${props.name}`}>{props.name}</Header>
          </>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};

const getResourceNameToBeDisplayed = (resourceName) => {
  switch (resourceName) {
    case 'CatalogManagerCfnCreate':
    case 'AccessLoggingCfnCreate':
    case 'AnalyticsManagerCfnCreate':
      return 'CloudFormation create stack';
    case 'CatalogManagerCfnDrift':
    case 'AccessLoggingCfnDrift':
    case 'AnalyticsManagerCfnDrift':
      return 'CloudFormation drift status';
    case 'CatalogManagerCfnUpdate':
    case 'AccessLoggingCfnUpdate':
    case 'AnalyticsManagerCfnUpdate':
      return 'CloudFormation update stack';
    case 'WorkspacePolicySync':
      return 'Workspace policy';
    default:
      return resourceName;
  }
};
const getResourceStatusToBeDisplayed = (resourceStatus) => {
  switch (resourceStatus) {
    case 'SUCCEEDED':
      return 'Completed';
    case 'FAILED':
      return 'Failed';
    default:
      return resourceStatus;
  }
};
