import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  Link,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { getNotificationConfiguration, updateNotificationConfiguration } from 'src/api/notifications';
import { scrollUp } from 'src/components/utils/navigation';
import { FORM_CONTENT_TYPE } from 'src/commons/constants';
import { CopiableText } from 'src/commons/common';
import { UserPreferencesTable } from 'src/components/notifications/userPreferencesTable';
import { getGroupInfo } from 'src/api/permissions';
import { activeGroupOrWorkspaceId } from 'src/components/dataadvisory/constants';

export interface NotificationConfigurationProps {
  activeWorkspace?: string;
  activeGroup: string;
  username: string | undefined;
  setContentType: any;
}

export const NotificationConfiguration = (props: NotificationConfigurationProps) => {
  const [blockGroupEmail, setBlockGroupEmail] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [cti, setCti] = useState('');

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);
    initConfiguration();
  }, []);

  const initConfiguration = async () => {
    const groupResult = await getGroupInfo({
      groupId: props.activeGroup,
    });
    console.log(groupResult);
    setEmailAddress(groupResult.teamInfo['DistributionList']);
    setCti(groupResult.primaryCTI.category + ' / ' + groupResult.primaryCTI.type + ' / ' + groupResult.primaryCTI.item);
    const notificationConfiguration = await getNotificationConfiguration({
      NotificationOwnerId: activeGroupOrWorkspaceId(props),
    });
    if (
      notificationConfiguration != null &&
      notificationConfiguration.NotificationConfiguration != null &&
      notificationConfiguration.NotificationConfiguration.EmailBlocked
    ) {
      setBlockGroupEmail(true);
    }
  };

  const handleNotificationConfigurationUpdate = async () => {
    setButtonLoading(true);
    const response = await updateNotificationConfiguration({
      NotificationOwnerId: activeGroupOrWorkspaceId(props),
      EmailBlocked: blockGroupEmail,
    });
    if (response.Message == 'Update Success') {
      setNotifications([
        {
          type: 'success',
          content: `Successfully updated notification settings.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    setButtonLoading(false);
    scrollUp();
  };

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header
            variant={'h1'}
            description='Manage your group or workspace contact information and notification preferences.'
          >
            Notification settings
          </Header>
        }
        actions={
          <div>
            <Button variant='primary' onClick={handleNotificationConfigurationUpdate} loading={buttonLoading}>
              Update
            </Button>
          </div>
        }
      >
        <SpaceBetween size={'m'}>
          <Container header={<Header variant={'h2'}>Group contact information</Header>}>
            <SpaceBetween size={'s'}>
              <p>
                Omni will periodically send notifications to your group's CTI and email address for things like access
                request updates and Data Advisories. To edit these values, go to the Group page{' '}
                <Link href={'/groups'}>here</Link>.
              </p>

              <CopiableText name={'Email address'} value={emailAddress} />
              <CopiableText name={'Ticket CTI'} value={cti} />
            </SpaceBetween>
          </Container>
          <Container header={<Header variant={'h2'}>Data advisory notification settings</Header>}>
            <SpaceBetween size={'m'}>
              <UserPreferencesTable
                preferenceType={'AdvisoryNotification'}
                advisoryPreferenceType={'opt-in'}
                setContentType={props.setContentType}
                activeGroup={props.activeGroup}
                activeWorkspace={props.activeWorkspace}
                setNotifications={setNotifications}
              />
              <UserPreferencesTable
                preferenceType={'AdvisoryNotification'}
                advisoryPreferenceType={'opt-out'}
                setContentType={props.setContentType}
                activeGroup={props.activeGroup}
                activeWorkspace={props.activeWorkspace}
                setNotifications={setNotifications}
              />
            </SpaceBetween>
          </Container>
          <Container header={<Header variant={'h2'}>Block all emails</Header>}>
            <FormField
              label='Block receiving email'
              description='Do not send any emails from Omni to this address. This will override any settings related to this email address above.'
            >
              <Toggle onChange={(e) => setBlockGroupEmail(e.detail.checked)} checked={blockGroupEmail} />
            </FormField>
          </Container>
        </SpaceBetween>
      </Form>
    </div>
  );
};
