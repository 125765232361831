import * as React from 'react';
import { Button, Modal } from '@amzn/awsui-components-react-v3';

export interface DependencyModalProps {
  setOndismiss: any;
  handleOnClick: any;
  loading: boolean;
  visible: boolean;
  operation: string;
  dependencyName: string;
  handleCancel: any;
}

export const DependencyModal = (props: DependencyModalProps) => {
  return (
    <Modal
      onDismiss={props.setOndismiss}
      visible={props.visible}
      closeAriaLabel='Close modal'
      size='medium'
      footer={
        <span className='awsui-util-f-r'>
          <Button variant='link' onClick={props.handleCancel}>
            Cancel
          </Button>
          <Button variant='primary' onClick={props.handleOnClick} loading={props.loading}>
            {props.operation}
          </Button>
        </span>
      }
      header={props.operation + ' ' + props.dependencyName}
    >
      <div>
        Are your sure you want to {props.operation.toLowerCase()}{' '}
        <span style={{ color: '#ff5d64' }}>{props.dependencyName}</span>?
      </div>
    </Modal>
  );
};
