import React, { useEffect, useState } from 'react';
import { SpaceBetween, Tabs } from '@amzn/awsui-components-react-v3';
import { DATACONSUMER } from 'src/commons/constants';
import { BrowseTagBasedRequestsTable } from 'src/components/workspaces/requests/browseTagBasedRequests';
import { BrowseResourceBasedRequestsTable } from 'src/components/workspaces/requests/browseResourceBasedRequests';
import { signedInWithWorkspace } from 'src/components/dataadvisory/constants';
import { Page } from 'src/routes';
import { Redirect } from 'react-router-dom';

interface WSRequestsProps {
  groupInfo: string | undefined;
  activeGroup: 'outgoingRequests' | 'incomingRequests';
  username: string | undefined;
  setContentType?: (contentType: string | undefined) => void;
  activeWorkspace: any;
  customerType: string;
  match: any;
}

export interface BrowseTableState {
  activeTabId: string | undefined;
}

export const WSMyRequests = (props: WSRequestsProps) => {
  const [activeTabId, setActiveTabId] = useState(
    props.customerType === DATACONSUMER ? 'outgoingRequests' : 'incomingRequests',
  );
  const { setContentType, activeGroup, activeWorkspace } = props;
  const [redirect, setRedirect] = useState(undefined);

  useEffect(() => {
    if (!signedInWithWorkspace(props)) {
      setRedirect(Page.REQUESTS);
    }
  }, [props.activeWorkspace, props.activeGroup]);

  let tabs = [
    {
      label: 'Incoming',
      id: 'incomingRequests',
      content: (
        <SpaceBetween size='l'>
          <BrowseTagBasedRequestsTable
            {...props}
            isIncomingRequests={true}
            setContentType={setContentType}
            activeGroup={activeGroup}
            activeWorkspace={activeWorkspace}
          />

          <BrowseResourceBasedRequestsTable
            {...props}
            isIncomingRequests={true}
            setContentType={setContentType}
            activeGroup={activeGroup}
            activeWorkspace={activeWorkspace}
          />
        </SpaceBetween>
      ),
    },
    {
      label: 'Outgoing',
      id: 'outgoingRequests',
      content: (
        <SpaceBetween size='l'>
          <BrowseTagBasedRequestsTable
            {...props}
            isIncomingRequests={false}
            setContentType={setContentType}
            activeGroup={activeGroup}
          />

          <BrowseResourceBasedRequestsTable
            {...props}
            isIncomingRequests={false}
            setContentType={setContentType}
            activeGroup={activeGroup}
          />
        </SpaceBetween>
      ),
    },
  ];

  if (props.customerType === DATACONSUMER) {
    tabs.reverse(); // We want outgoing to be first for consumers
  }

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Tabs
        tabs={tabs}
        activeTabId={activeTabId}
        variant='default'
        onChange={({ detail }) => {
          setActiveTabId(detail.activeTabId);
        }}
      />
    </>
  );
};
