import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Component } from 'react';
import { Button, Flashbar, Modal, Spinner, Textarea, TableProps, FlashbarProps } from '@amzn/awsui-components-react-v3';
import {
  getDataPermission,
  listDataPermissions,
  deactivateDataPermission,
  auditDataPermission,
} from '../../../api/permissions';
import { StatusIcon } from './statusIcon';
import { Link } from 'react-router-dom';
import {
  PageHeader,
  DataPermissionInformation,
  DataPermissionLakeFormation,
  DataPermissionConsumers,
  DataPermissionRAMResources,
  DataPermissionRAMResourceShare,
} from './common';
import { createWSPermissionLFLink, Page } from 'src/routes';
import {
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_STATUS_INACTIVE,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { signedInWithWorkspace } from 'src/components/dataadvisory/constants';

export interface DatasetsDetailsTableProps {
  groups: any;
  setContentType: any;
  match: any;
  activeGroup: string;
}

export interface DatasetsDetailsTableState {
  notifications: FlashbarProps.MessageDefinition[];
  reasonOfAction: string;
  modalVisible: boolean;
  buttonLoading: boolean;
  isInvalid: boolean;
  dataPermissionId: any;
  dataPermissionItem: any;
  dataPermissions: any;
  actionLoading: boolean;
  dataPermissionLoading: boolean;
  dataPermissionsLoading: boolean;
  deactivateLoading: boolean;
  redirect: any;
  deactivateText: string;
  selected: any;
  textAreaPlaceholder: any;
}

export class DatasetsDetailsTable extends Component<DatasetsDetailsTableProps, DatasetsDetailsTableState> {
  state = {
    notifications: [],
    reasonOfAction: undefined,
    modalVisible: false,
    buttonLoading: false,
    isInvalid: false,
    dataPermissionId: this.props.match.params.id,
    dataPermissionItem: {},
    dataPermissions: [],
    actionLoading: false,
    dataPermissionLoading: false,
    dataPermissionsLoading: false,
    deactivateLoading: false,
    redirect: undefined,
    deactivateText: 'Deactivated',
    selected: undefined,
    textAreaPlaceholder: undefined,
  };

  columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'dataPermissionId',
      header: 'Dataset Permission ID',
      cell: (item) => <Link to={`/mydatasets/${item.dataPermissionId}`}>{item.dataPermissionId}</Link>,
      minWidth: 200,
    },
    {
      id: 'dataLakePrincipal',
      header: 'DataLake principal',
      cell: (item) => item.dataLakePrincipal,
      minWidth: 120,
      sortingField: 'dataLakePrincipal',
    },
    {
      id: 'ownerId',
      header: 'Owner Id',
      cell: (item) => item.ownerId,
      minWidth: 120,
      sortingField: 'ownerId',
    },
    {
      id: 'table',
      header: 'Table',
      cell: (item) => item.tableName,
      minWidth: 120,
      sortingField: 'tableName',
    },
    {
      id: 'activatedDate',
      header: 'Activated date',
      cell: (item) => item.dateActive,
      minWidth: 210,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => <StatusIcon status={item.status} />,
      minWidth: 100,
    },
    {
      id: 'auditDate',
      header: 'Audit date',
      cell: (item) => item.dateOfLastAudit,
      minWidth: 210,
    },
    {
      id: 'auditStatus',
      header: 'Audit status',
      cell: (item) => <StatusIcon status={item.auditStatus} />,
      minWidth: 100,
    },
  ];

  componentDidMount = async () => {
    this.props.setContentType(TABLE_CONTENT_TYPE);
    await this.handleRefresh();
  };

  componentDidUpdate = async () => {
    if (this.props.match.params.id !== this.state.dataPermissionId) {
      await this.setState({ dataPermissionId: this.props.match.params.id });
      await this.handleRefresh();
    }
    if (signedInWithWorkspace(this.props)) {
      this.setState({ redirect: createWSPermissionLFLink(this.props.match.params.id) });
    }
  };

  handleRefresh = async () => {
    this.setState({
      dataPermissionLoading: true,
      dataPermissionsLoading: true,
      actionLoading: false,
    });

    const dataPermissionResult = await getDataPermission({
      dataPermissionId: this.props.match.params.id,
      metadata: this.props.match.params.id,
    });
    const flatten = (item) => {
      let tableName = undefined;
      let databaseName = undefined;
      let catalogId = undefined;
      let columns = undefined;
      if (item.resource?.table !== undefined) {
        databaseName = item.resource.table.databaseName;
        catalogId = item.resource.table.catalogId;
        tableName = item.resource.table.name == null ? 'ALL_TABLES' : item.resource.table.name;
      } else if (item.resource?.tableWithColumns !== undefined) {
        databaseName = item.resource.tableWithColumns.databaseName;
        catalogId = item.resource.tableWithColumns.catalogId;
        tableName = item.resource.tableWithColumns.name;
        columns = item.resource.tableWithColumns.columnNames;
      }

      return {
        dataPermissionId: item.dataPermissionId,
        metadata: item.metadata,
        ownerId: item.ownerId,
        status: item.status,
        option: item.option,
        type: item.type,
        region: item.region,
        databaseName: databaseName,
        catalogId: catalogId,
        tableName: tableName,
        columns: columns,
        permissions: item.permissions,
        permissionsWithGrantOption: item.permissionsWithGrantOption,
        dataLakePrincipal: item.dataLakePrincipal,
        auditStatus: item.auditStatus,
        dateActive: item.dateActive,
        dateInActive: item.dateInActive,
        dateOfLastAudit: item.dateOfLastAudit,
        activatedBy: item.activatedBy,
        deactivatedBy: item.deactivatedBy,
        reasonOfAction: item.reasonOfAction,
        audit: item.audit,
        ticketDetails: item.ticketDetails,
        permissionRequestId: item.permissionRequestId,
        permissionRequestedDate: item.permissionRequestedDate,
      };
    };

    await this.setState({
      dataPermissionItem: flatten(dataPermissionResult.DataPermission),
      dataPermissionLoading: false,
    });
    //If Publisher get Consumers
    if (dataPermissionResult.DataPermission.option === DATA_PERMISSION_PUBLISHER_OPTION) {
      let resource = undefined;
      if (dataPermissionResult.DataPermission.resource.table !== undefined) {
        resource = {
          table: {
            databaseName: dataPermissionResult.DataPermission.resource.table.databaseName,
            name: dataPermissionResult.DataPermission.resource.table.name,
            catalogId: dataPermissionResult.DataPermission.resource.table.catalogId,
            tableWildcard: null,
          },
        };
      } else if (dataPermissionResult.DataPermission.resource.tableWithColumns !== undefined) {
        resource = {
          tableWithColumns: {
            databaseName: dataPermissionResult.DataPermission.resource.tableWithColumns.databaseName,
            name: dataPermissionResult.DataPermission.resource.tableWithColumns.name,
            catalogId: dataPermissionResult.DataPermission.resource.tableWithColumns.catalogId,
            columnNames: dataPermissionResult.DataPermission.resource.tableWithColumns.columnNames,
            columnWildcard: dataPermissionResult.DataPermission.resource.tableWithColumns.columnWildcard,
          },
        };
      }

      let listDataPermissionsRequest = {
        resource: resource,
        region: dataPermissionResult.DataPermission.region,
        status: dataPermissionResult.DataPermission.status,
        option: DATA_PERMISSION_CONSUMER_OPTION,
        type: dataPermissionResult.DataPermission.type,
        nextToken: null,
      };

      let listDataPermissionsResult = await listDataPermissions(listDataPermissionsRequest);
      let dataPermissions = [...listDataPermissionsResult.dataPermissionList];
      //Loop and get remaining tables
      while (listDataPermissionsResult.nextToken != null) {
        listDataPermissionsRequest.nextToken = listDataPermissionsResult.nextToken;
        listDataPermissionsResult = await listDataPermissions(listDataPermissionsRequest);
        dataPermissions.push(...listDataPermissionsResult.dataPermissionList);
      }

      let consumerPermissions = dataPermissions.map((item) => flatten(item));
      this.setState({
        dataPermissions: consumerPermissions,
        dataPermissionsLoading: false,
      });
    }
  };

  handleDeactivate = async () => {
    if (!this.state.reasonOfAction) {
      this.setState({ isInvalid: true });
      return;
    }
    this.setState({ buttonLoading: true });
    try {
      await deactivateDataPermission({
        dataPermissionId: this.props.match.params.id,
        reasonOfAction: this.state.reasonOfAction.replace(/\n/g, ''),
      });
      // Notification is not rendered because of the redirect.
      // The notification could be displayed at an app level.
      this.successMessage(this.props.match.params.id, 'deactivated');
    } catch (err) {
      console.log(err);
      this.errorMessage(err.message, 'deactivating');
    }
    this.closeModal();
    this.setState({
      redirect: Page.MY_DATASETS,
    });
    await this.handleRefresh();
  };

  handleAction = async (e) => {
    if (e.detail.id === 'audit') {
      try {
        this.setState({ actionLoading: true });
        const response = await auditDataPermission({
          dataPermissionId: this.props.match.params.id,
        });
        await this.handleRefresh();
        response.auditStatus === 'SUCCEEDED'
          ? this.successMessage(`Audit of: ${response.dataPermissionId}`, response.auditStatus)
          : this.errorMessage(`Audit of: ${response.dataPermissionId}`, response.auditStatus);
      } catch (err) {
        console.log(err);
        this.errorMessage(`Audit of: ${this.state.selected} : ${err.message}`, 'FAILED');
      }
    }
    if (e.detail.id === 'deactivate') {
      this.openModal();
    }
  };

  openModal = () => {
    this.setState({
      deactivateText: `Dataset Permission ID: ${this.state.dataPermissionId}`,
      modalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({ buttonLoading: false, modalVisible: false });
  };

  successMessage(message, action) {
    this.setState({
      notifications: [
        {
          type: 'success',
          content: `Dataset Permission ID: '${message}' was successfully '${action}'`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  }

  errorMessage(message, action) {
    this.setState({
      notifications: [
        {
          type: 'error',
          content: `There was an error '${action}' while '${message}'`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div>
        <Flashbar items={this.state.notifications} />
        <br />
        <Modal
          visible={this.state.modalVisible}
          header={[
            `Enter reason for deactivating`,
            <br />,
            `Dataset Permission ID: ${this.state.dataPermissionItem['dataPermissionId']}`,
            <br />,
            `Database: ${this.state.dataPermissionItem['databaseName']}`,
            <br />,
            `Table: ${this.state.dataPermissionItem['tableName']}`,
          ]}
          onDismiss={() => this.closeModal()}
          footer={
            <span className='awsui-util-f-r'>
              <Button variant='link' onClick={() => this.closeModal()}>
                No
              </Button>
              <Button variant='primary' loading={this.state.buttonLoading} onClick={() => this.handleDeactivate()}>
                Yes
              </Button>
            </span>
          }
        >
          <Textarea
            value={this.state.reasonOfAction}
            onChange={({ detail }) => this.setState({ isInvalid: false, reasonOfAction: detail.value })}
            placeholder={this.state.textAreaPlaceholder}
            invalid={this.state.isInvalid}
          />
          Are you sure you want to deactivate this Dataset permission?
        </Modal>
        <PageHeader
          buttons={[
            {
              text: '',
              icon: 'refresh',
              onItemClick: this.handleRefresh,
            },
            {
              text: 'Actions',
              onItemClick: this.handleAction,
              items: [
                {
                  text: 'Audit',
                  id: 'audit',
                  disabled: this.state.dataPermissionItem['status'] === DATA_PERMISSION_STATUS_INACTIVE,
                },
                {
                  text: 'Deactivate',
                  id: 'deactivate',
                  disabled: this.state.dataPermissionItem['status'] === DATA_PERMISSION_STATUS_INACTIVE,
                },
              ],
              loading: this.state.actionLoading,
            },
          ]}
          header={`Dataset Permission ID: ${this.props.match.params.id}`}
        />

        {this.state.dataPermissionLoading === true && <Spinner size='large' />}

        {this.state.dataPermissionLoading === false && (
          <>
            <DataPermissionInformation dataPermissionItem={this.state.dataPermissionItem} />

            {this.state.dataPermissionItem['option'] === DATA_PERMISSION_CONSUMER_OPTION && (
              <>
                <DataPermissionLakeFormation dataPermissionItem={this.state.dataPermissionItem} />

                <DataPermissionRAMResourceShare
                  ramResourceShare={
                    this.state.dataPermissionItem['audit'] &&
                    this.state.dataPermissionItem['audit']['RamResourceShare'] !== undefined
                      ? JSON.parse(this.state.dataPermissionItem['audit']['RamResourceShare'])[0]
                      : undefined
                  }
                />
                <DataPermissionRAMResources
                  ramResources={
                    this.state.dataPermissionItem['audit'] &&
                    this.state.dataPermissionItem['audit']['RamResources'] !== undefined
                      ? JSON.parse(this.state.dataPermissionItem['audit']['RamResources'])
                      : []
                  }
                />
              </>
            )}

            {this.state.dataPermissionItem['option'] === DATA_PERMISSION_PUBLISHER_OPTION && (
              <DataPermissionConsumers
                dataPermissions={this.state.dataPermissions}
                columnDefinitions={this.columnDefinitions}
                dataPermissionsLoading={this.state.dataPermissionsLoading}
              />
            )}
          </>
        )}
      </div>
    );
  }
}
