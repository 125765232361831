import * as React from 'react';

import { useEffect, useState } from 'react';

import { ColumnLayout, Flashbar } from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/permissions/myPermissions/common';
import { getGlossary } from 'src/api/catalog';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface ViewBusinessGlossaryProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const ViewBusinessGlossary = (props: ViewBusinessGlossaryProps) => {
  const [businessGlossary, setBusinessGlossary] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [glossaryId, setGlossaryId] = useState(undefined);
  const [ownerId, setOwnerId] = useState(undefined);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    setOwnerId(props.match.params.glossaryOwnerId);
    setGlossaryId(props.match.params.id);
  }, []);

  useEffect(() => {
    if (glossaryId && ownerId) {
      getGlossaryForGivenId();
    }
  }, [glossaryId, ownerId]);

  const getGlossaryForGivenId = async () => {
    try {
      let getGlossaryResult = await getGlossary({
        GlossaryId: glossaryId,
        OwnerId: ownerId,
      });
      setBusinessGlossary(getGlossaryResult.GlossaryDetails);
    } catch (err) {
      setNotification('error', err.message);
    }
  };

  const buttonOptions = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: getGlossaryForGivenId,
      },
    ];
  };

  const setNotification = async (header, message) => {
    if (header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <>
      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>

      <PageHeader buttons={buttonOptions()} header={`Glossary ID: ${glossaryId}`} />
      <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
          <h2>Glossary details</h2>
        </div>
        <ColumnLayout columns={3} variant='text-grid'>
          <div>
            <div className='awsui-util-label'>Owner</div>
            <div>{businessGlossary?.OwnerId}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Name</div>
            <div> {businessGlossary?.Name}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Description</div>
            <div> {businessGlossary?.Description}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Status</div>
            <div> {businessGlossary?.Status}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Type</div>
            <div> {businessGlossary?.GlossaryType.replace('Glossary#', '')}</div>
          </div>
        </ColumnLayout>
      </div>
    </>
  );
};

export default ViewBusinessGlossary;
