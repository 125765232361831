import { Page } from 'src/routes';
import { getURL } from 'src/api/config';

export const PENDING_DATA_ACCESS_REQUEST_SUBJECT = 'Pending Data Access Requests';

export function getPendingDataAccessRequestContent(items, requester, dataProvider) {
  let toReturn = `Hi ${dataProvider},\n\n\n`;
  toReturn += `You have pending request(s) from group ${requester}. `;
  toReturn += `To approve/deny these requests please visit Omni at ${getURL() + Page.REQUESTS}.\n\n`;
  toReturn += `The pending requests are for the following tables:\n`;
  for (const item of items) {
    toReturn += `- ${getDatasetDetailLink(item['id'])}\n`;
  }

  toReturn += '\n\n';

  toReturn += 'Sincerely,\n';
  toReturn += 'AWS Data Lake';

  return toReturn;
}

export function getDatasetDetailLink(datasetId) {
  return getURL() + Page.DATASET_DETAILS.replace(':id', encodeURI(datasetId));
}
