import { Dependency } from 'aws-sdk/clients/awsdatalakedependencylambda';
import { useCollection } from '@amzn/awsui-collection-hooks';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import {
  Table,
  TextFilter,
  CollectionPreferences,
  Pagination,
  CollectionPreferencesProps,
} from '@amzn/awsui-components-react-v3/polaris';
import { listDependencyDataSets } from '../../api/dependency';
import { defaultPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface BrowseDependenciesTableProps {
  setContentType: any;
  title: string;
  dependencies: Dependency[];
  loadingDependencies: boolean;
  activeGroup: string;
  tableType: string;
}

const BrowseDependenciesTable = (props: BrowseDependenciesTableProps) => {
  const [allItems, setItems] = useState([]);
  const [redirect] = useState(undefined);
  const [dataSetsLoading, setDataSetsLoading] = useState(true);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const columnDefinitions = [
    {
      id: 'dependencyName',
      header: 'Dependency name',
      cell: (item) => <Link to={`dependency/${item.dependencyId}`}>{item.dependencyName}</Link>,
      minWidth: 200,
    },
    {
      id: 'dependencyDescription',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: 200,
    },
    {
      id: 'dataSetName',
      header: 'Table name',
      cell: (item) => <Link to={`datasets/${item.hybridCatalogDataSetId}`}>{item.dataSetName}</Link>,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    fetchDataSets();
  }, []);

  useEffect(() => {
    fetchDataSets();
  }, [props.dependencies]);

  const fetchDataSets = async () => {
    setDataSetsLoading(true);

    const curItems = [];
    let dataSetIds = props.dependencies.map((dependency) => dependency.dependencyServiceDataSetId);
    const queryLimit = 100;
    if (dataSetIds && dataSetIds.length > 0) {
      let requestDataSetIds = [];
      let dataSets = [];

      do {
        requestDataSetIds = dataSetIds.slice(0, queryLimit);
        dataSetIds = dataSetIds.slice(queryLimit);

        let listDataSetsRequest = {
          limit: queryLimit,
          dependencyServiceDataSetIds: requestDataSetIds,
          groupId: props.activeGroup,
        };
        let listDataSetsResult = await listDependencyDataSets(listDataSetsRequest);
        dataSets.push(...listDataSetsResult.dataSets);
      } while (dataSetIds.length > 0);

      const dataSetIdDetail = dataSets.reduce((map, dataSet) => {
        map[dataSet.dependencyServiceDataSetId] = {
          tableName: dataSet.tableName,
          hybridCatalogDataSetId: dataSet.hybridCatalogDataSetId,
        };
        return map;
      }, {});

      props.dependencies.forEach((dependency) =>
        curItems.push({
          dependencyId: dependency.dependencyId,
          dependencyName: dependency.dependencyName,
          description: dependency.description,
          dataSetName: dataSetIdDetail[dependency.dependencyServiceDataSetId]['tableName'],
          hybridCatalogDataSetId: dataSetIdDetail[dependency.dependencyServiceDataSetId]['hybridCatalogDataSetId'],
        }),
      );

      setItems(curItems);
    }

    setDataSetsLoading(false);
  };

  const { items, filterProps, filteredItemsCount, paginationProps, collectionProps } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <p className='awsui-util-mb-s'>No {props.tableType} dependencies were found in the current group.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading dependencies...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <h2>
            {props.title}
            <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
          </h2>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        loading={props.loadingDependencies || dataSetsLoading}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      />
    </>
  );
};

export default BrowseDependenciesTable;
