import { Spinner, SpinnerProps } from '@amzn/awsui-components-react-v3';
import * as React from 'react';

// returns a given number of spinners
export function getSpinners(num, spinnerSize: SpinnerProps.Size = 'normal') {
  const metadata = [];
  for (let i = 0; i < num; i++) {
    metadata.push(<Spinner size={spinnerSize} />);
  }
  return metadata;
}
