import { Table } from 'aws-sdk/clients/glue';
import { camelCase } from 'lodash';

import { DataLakePrincipal, Resource } from 'aws-sdk/clients/lakeformation';
import { TablePermissions } from './permissionSelector/tablePermissionsFormFields';
import { CreateLakeFormationRequest } from 'aws-sdk/clients/awsdatalakegladstonelambda';

const createPermissionRequest = (
  groupRequestedBy: string,
  table: any = {},
  resource: Resource = {},
  principal: DataLakePrincipal = {},
  ramRole: string,
  permissions: string[] = ['SELECT'],
  grantablePermissions: string[] = [],
  useCase: string,
  additionalMetadata: any[],
): CreateLakeFormationRequest => {
  let request = {
    groupRequestedBy: groupRequestedBy,
    groupRequestedTo: table['Owners'],
    dataLocationArn: table['LakeFormationRoleARN'],
    dataLocationRegion: table['IdInfo'].Region,
    ramRole: ramRole,
    dataLakePrincipal: principal.DataLakePrincipalIdentifier,
    resourceString: JSON.stringify(camelizeKeys(resource)),
    permissionsString: JSON.stringify(permissions),
    permissionsWithGrantOptionString: JSON.stringify(grantablePermissions),
    useCase: useCase,
    accessManagementTemplateMetadata: additionalMetadata,
  };
  return request;
};

const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

const createTableResource = (selectedTable: any): Resource => {
  let Table: any = {
    DatabaseName: selectedTable['IdInfo'].DatabaseName || '',
    CatalogId: selectedTable['IdInfo'].CatalogId,
  };
  if (selectedTable['IdInfo'].TableName === 'All Tables') {
    Table = { ...Table, TableWildcard: {} };
  } else {
    Table = { ...Table, Name: selectedTable['IdInfo'].TableName };
  }
  return { Table };
};

const createPermissionsPayloadForTable = (
  groupRequestedBy: string,
  table: Table,
  principal: DataLakePrincipal,
  ramRole: string,
  permissions: TablePermissions,
  useCase: string,
  additionalMetadata: any[],
) => {
  return createPermissionRequest(
    groupRequestedBy,
    table,
    createTableResource(table),
    principal,
    ramRole,
    permissions.tablePermissions,
    permissions.grantablePermissions,
    useCase,
    additionalMetadata,
  );
};

const DEFAULT_PERMISSION = {
  tablePermissions: ['SELECT'],
  grantablePermissions: [],
};

export const createLakeFormationPermissionsPayload = (
  groupRequestedBy: string,
  resource: Resource | undefined,
  principal: DataLakePrincipal,
  ramRole: string,
  permissions: TablePermissions | undefined = undefined,
  table: Table | undefined = undefined,
  useCase: string | undefined = undefined,
  additionalMetadata: any[],
) => {
  const permissionToApply: TablePermissions = permissions ? permissions : DEFAULT_PERMISSION;

  if (resource !== undefined) {
    return createPermissionRequest(
      groupRequestedBy,
      table,
      resource,
      principal,
      ramRole,
      permissionToApply.tablePermissions,
      permissionToApply.grantablePermissions,
      useCase,
      additionalMetadata,
    );
  }

  if (table !== undefined) {
    return createPermissionsPayloadForTable(
      groupRequestedBy,
      table,
      principal,
      ramRole,
      permissionToApply,
      useCase,
      additionalMetadata,
    );
  }

  return createPermissionRequest(
    groupRequestedBy,
    table,
    resource,
    principal,
    ramRole,
    permissionToApply.tablePermissions,
    permissionToApply.grantablePermissions,
    useCase,
    additionalMetadata,
  );
};

// Junk function. Needs to be removed.
export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getArn = (principal: DataLakePrincipal | undefined) => {
  return principal ? principal.DataLakePrincipalIdentifier || 'ARN not available' : '-';
};

export const getCatalog = () => {
  const catalogRegex = new RegExp('[?&]' + 'catalog' + '=([^&]+).*$');
  const match = location.href.match(catalogRegex);
  return match ? match[1] : '';
};

export const getPrincipal = () => {
  const principalRegex = new RegExp('[?&]' + 'principal' + '=([^&]+).*$');
  const match = location.href.match(principalRegex);
  return match ? match[1] : '';
};

export const getFilterType = () => {
  const filterTypeRegex = new RegExp('[?&]' + 'filterType' + '=([^&]+).*$');
  const match = location.href.match(filterTypeRegex);
  return match ? match[1] : 'table';
};

export const getFilterName = () => {
  const filterTypeRegex = new RegExp('[?&]' + 'rowFilter' + '=([^&]+).*$');
  const match = location.href.match(filterTypeRegex);
  const filterName = match ? match[1] : undefined;
  return filterName ? decodeURIComponent(filterName) : filterName;
};
