import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  convertAdvisoryToTemplate,
  convertJsonToAdvisory,
  convertAdvisoryToJson,
  notificationsToFlashbar,
} from 'src/components/dataadvisory/constants';
import {
  AdvisoryChangeMode,
  CreateOrUpdateAdvisoryWizard,
} from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/createOrUpdateAdvisoryWizard';
import { Page } from 'src/routes';
import { editTemplate, getTemplate } from 'src/api/permissions';
import { Advisory } from 'aws-sdk/clients/awsdlomni';
import { Alert, SpaceBetween, Spinner } from '@amzn/awsui-components-react-v3';

export interface UpdateAdvisoryTemplatePageProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  activeWorkspace?: any;
  userInfo?: any;
}

export const UpdateTemplatePage = (props: UpdateAdvisoryTemplatePageProps) => {
  const [advisory, setAdvisory] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [templateName, setTemplateName] = useState(undefined);
  const [templateDescription, setTemplateDescription] = useState(undefined);

  useEffect(() => {
    props.setContentType('wizard');
    handleRefresh();
  }, []);

  const templateId = () => {
    return props.match.params.id;
  };

  const handleRefresh = async () => {
    setLoading(true);
    try {
      setAdvisory(await fetchTemplate());
    } catch (e) {
      setFailedToLoad(true);
    }
    setLoading(false);
  };

  const fetchTemplate = async () => {
    const response = await getTemplate({
      templateId: templateId(),
      resourceId: 'NONE',
    });
    const templateBody = response.template.templateBodyJson;
    const advisoryTemplate = convertJsonToAdvisory(templateBody);
    setTemplateName(response.template.name);
    setTemplateDescription(response.template.description);
    return advisoryTemplate;
  };

  const updateAdvisoryTemplate = async (advisory: Advisory) => {
    setUpdating(true);
    try {
      const template = convertAdvisoryToTemplate(advisory);
      const templateBody = convertAdvisoryToJson(template);
      await editTemplate({
        templateId: templateId(),
        name: templateName,
        description: templateDescription,
        resourceId: 'NONE',
        templateBodyJson: templateBody,
      });
      setNotificationForSuccessfulUpdate();
    } catch (e) {
      setNotificationForFailedUpdate(e);
    }
    setUpdating(false);
  };

  const setNotificationForSuccessfulUpdate = () => {
    setNotifications([
      {
        type: 'success',
        content: 'Successfully updated the template.',
      },
    ]);
  };

  const setNotificationForFailedUpdate = (e) => {
    setNotifications([
      {
        type: 'error',
        content: 'Failed to update the template: ' + e.message,
      },
    ]);
  };

  if (loading) {
    return <Spinner />;
  }
  if (failedToLoad) {
    return (
      <Alert type={'error'} statusIconAriaLabel={'Error'} header={'Unable to load template'}>
        Please double-check the template ID in the URL and try again.
      </Alert>
    );
  }

  return (
    <SpaceBetween size={'m'}>
      {notifications.length > 0 && notificationsToFlashbar(notifications, setNotifications)}
      <CreateOrUpdateAdvisoryWizard
        {...props}
        mode={AdvisoryChangeMode.UPDATE_TEMPLATE_MODE}
        onSubmit={updateAdvisoryTemplate}
        advisory={advisory}
        redirectTo={Page.LIST_ADVISORY_TEMPLATES}
        loading={updating}
      />
    </SpaceBetween>
  );
};
