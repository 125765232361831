import * as React from 'react';
import {
  Button,
  ButtonDropdown,
  Icon,
  Popover,
  Spinner,
  Flashbar,
  Modal,
  ColumnLayout,
  ExpandableSection,
  FormField,
  Toggle,
  Textarea,
  Input,
  Select,
  Multiselect,
  Header,
  SpaceBetween,
  FlashbarProps,
  Box,
  StatusIndicator,
} from '@amzn/awsui-components-react-v3';

import { scrollUp } from '../utils/navigation';
import { pop, get, set, dateString } from './helpers';
import * as validate from '../../commons/validationUtils';

/**
 * A function which, WHEN BOUND WITH 'this' in a React component, generates the Guardrail modal defined at this.state.guardrail
 * @returns <Modal>
 */
export function Guardrail_UNBOUND() {
  if (typeof this === 'undefined') {
    throw "Guardrail not bound, 'this' was undefined";
  }

  if (this.state.guardrail) {
    var props = new Object({ ...this.state.guardrail });
    props['header'] = props['header'] || 'PLEASE CONFIRM ACTION:';
    props['closeAriaLabel'] = props['closeAriaLabel'] || 'Close modal';
    props['visible'] = typeof props['visible'] !== 'undefined' ? props['visible'] : true;
    props['onDismiss'] = () => this.setState({ guardrail: null, submitting: false });

    const content = pop(
      props,
      'content',
      [
        'Are you sure you want to continue with the above action?',
        'If so, please click "OK" to proceed.',
        'Otherwise close this box by clicking "Cancel" below or "X" above.',
      ].map((t) => <p>{t}</p>),
    );
    const action = pop(props, 'action', (e) => console.error('No Action Defined. Event: ', e));

    props['footer'] = (
      <Box float='right'>
        <SpaceBetween direction='horizontal' size='xs'>
          <Button variant='link' onClick={() => this.setState({ guardrail: null, submitting: false })}>
            Cancel
          </Button>
          <Button variant='primary' onClick={action}>
            OK
          </Button>
        </SpaceBetween>
      </Box>
    );

    return (
      <Modal visible={props['visible']} {...props}>
        {content}
      </Modal>
    );
  } else {
    return [];
  }
}

const reBucket = /^s3:\/\/([^\/]+)/;
const rePrefix = /^s3:\/\/[^\/]+\/(.*)/;
const getBucket = (location) => location.match(reBucket)[1];
const getPrefix = (location) => location.match(rePrefix)[1];

export const s3url = (path) => {
  if (validate.isValidS3Path(path)) {
    var url = 'https://s3.console.aws.amazon.com/s3/object/';

    try {
      const bucket = getBucket(path);
      url += bucket;
    } catch (err) {
      console.error('No bucket found for: ', path);
      return null;
    }
    try {
      const prefix = getPrefix(path);
      url += '?region=us-east-1&prefix=' + prefix;
    } catch (err) {
      console.error('No prefix found for: ', path);
    }
    return url;
  } else {
    return null;
  }
};

const getBaseText = (obj) => {
  const children = get(obj, ['props', 'children']);
  if (typeof obj == 'object' && children != undefined) {
    if (Array.isArray(children)) {
      return children.map(getBaseText).join(' ');
    } else {
      return children;
    }
  } else {
    return obj;
  }
};

/**
 * A formatted Polaris component with a label and value.
 *
 * @param {object} param0 - An object containing the parameters below
 *    @param {string} label
 *    @param {string} value
 *    @param {boolean} loading - Whether the component content is loading
 *    @param {string} url - Optional url to convert value into a Link component
 *    @param {boolean} copiable - Whether to add a Copy icon to the value
 *
 * @returns <div>
 */
export const LabeledText = ({
  label = null,
  value = null,
  url = null,
  target = null,
  loading = false,
  copiable = false,
}) => {
  value = value || '';
  label = label || '';
  const valueText = getBaseText(value);
  return (
    <div>
      <div className='awsui-util-label'>{label}</div>
      {loading ? (
        <Spinner />
      ) : (
        <div className='awsui-util-copy-text'>
          {url == null ? (
            value
          ) : (
            <a href={url} target={target}>
              {value} <Icon name='external'></Icon>
            </a>
          )}
          {value && copiable && (
            <Popover
              size='small'
              position='top'
              dismissButton={false}
              triggerType='custom'
              content={
                <span className='awsui-util-status-positive'>
                  <Icon variant='success' name='status-positive' /> Copied
                </span>
              }
            >
              <Button
                variant='icon'
                iconName='copy'
                iconAlt='Copy'
                ariaLabel='Copy ARN'
                onClick={() => {
                  navigator.clipboard.writeText(valueText);
                }}
              />
            </Popover>
          )}
        </div>
      )}
    </div>
  );
};

export const LabeledTextColumns = ({ labeledTextList = [], columns = 1, borders = null, loading = false }) => (
  <ColumnLayout columns={columns} borders={borders}>
    <div data-awsui-column-layout-root='true'>{labeledTextFromList(labeledTextList, loading)}</div>
  </ColumnLayout>
);

/**
 * Wrapper function to generate a labeled texts from a list of objects.
 * @param {array} list - A list of objects, each containing the parameters for the labeled text.
 * @param loading - whether all of the LabeledTexts are loading or note
 * @returns list<LabeledText>
 */
export const labeledTextFromList = (list, loading = false) =>
  list.map((obj) => (
    <LabeledText
      label={obj.label || ''}
      value={obj.value || ''}
      loading={loading || obj.loading || false}
      url={obj.url || null}
      target={obj.target || null}
      copiable={obj.copiable || false}
    />
  ));

/**
 * A PageHeader component specifically used for ResourcesManager pages.
 * TODO: Can be removed during refactoring.
 */
export const RMPageHeader = ({ header = null, buttons = null, subheader = null }) => {
  header = header || '';
  buttons = buttons || [];
  subheader = subheader || '';
  return (
    <Header
      variant={header ? 'h1' : 'h2'}
      actions={
        <SpaceBetween direction='horizontal' size='s'>
          {buttons.map((button, key) =>
            !button.items ? (
              <Button
                href={button.href || ''}
                disabled={button.disabled || false}
                iconName={button.icon}
                onClick={button.onItemClick}
                key={key}
                loading={button.loading || false}
                variant={button.variant || 'normal'}
              >
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown items={button.items} key={key} onItemClick={button.onItemClick} loading={button.loading}>
                {button.text}
              </ButtonDropdown>
            ),
          )}
        </SpaceBetween>
      }
    >
      {header}
      {subheader}
    </Header>
  );
};

/**
 * A function for rendering Flashbars in the case of errors.
 * @param {object | Error} err - An object containing information about the error. Can include a user-defined key-value pair "where": {string} which indicates in which context the error ocurred.
 * @returns <Flashbar>
 */
export const ErrorAlert = (err) => {
  if (err) {
    console.error(err);
    scrollUp();
    const mainItem = {
      type: 'error',
      dismissible: false,
      header: `ERROR WHILE ${err.while}: ${err.code}`,
      content: err.message,
    };

    const items = err.errors
      ? [
          mainItem,
          ...err.errors.map(
            (e) =>
              new Object({
                type: 'error',
                dismissable: false,
                header: `Error Code: ${e.code}`,
                content: e.message,
              }),
          ),
        ]
      : [mainItem];
    return <Flashbar items={items} />;
  } else {
    return [];
  }
};

interface iBootstrapError {
  errorWhile: string;
  code: string;
  message: string;
}

/**
 * A function that returns a configura Flashbar error, fixes ErrorAlert on Polaris V3 forms by decoupling the notification from the scroll up.
 * @param iBootstrapError err - An object containing information about the error. Can include a user-defined key-value pair "where": {string} which indicates in which context the error ocurred.
 * @returns FlashbarProps.MessageDefinition[]
 */
export const createBootstrapError = ({
  errorWhile,
  code,
  message,
}: iBootstrapError): FlashbarProps.MessageDefinition[] => {
  scrollUp();

  return [
    {
      type: 'error',
      content: message,
      dismissible: false,
      header: `ERROR WHILE ${errorWhile}: ${code}`,
    },
  ];
};

export const coloredStatus = (status) => {
  return coloredStatusComponents[('' + status).toUpperCase()] || '' + status;
};

export const coloredStatusComponents = {
  // ResourceGroupStatus
  ENABLED: <StatusIndicator colorOverride='green'>Enabled</StatusIndicator>,
  DISABLED: (
    <StatusIndicator colorOverride='grey' type='stopped'>
      Disabled
    </StatusIndicator>
  ),
  CREATE_PENDING: (
    <span className='awsui-util-status-inactive' style={{ color: 'LimeGreen' }}>
      <Icon name='status-pending' /> Create Pending
    </span>
  ),
  DELETE_PENDING: (
    <span className='awsui-util-status-inactive' style={{ color: 'IndianRed' }}>
      <Icon name='status-pending' /> Delete Pending
    </span>
  ),
  UPDATE_PENDING_ENABLED: (
    <span className='awsui-util-status-inactive'>
      <Icon name='status-pending' /> Update Pending Enabled
    </span>
  ),
  UPDATE_PENDING_DISABLED: (
    <span className='awsui-util-status-inactive'>
      <Icon name='status-pending' /> Update Pending Disabled
    </span>
  ),
  NOT_COMPLIANT: (
    <span className='awsui-util-status-negative'>
      <Icon name='status-warning' /> Not Compliant
    </span>
  ),
  DELETED: (
    <span className='awsui-util-status-negative'>
      <Icon name='status-negative' /> Deleted
    </span>
  ),

  // ResourceStatus
  RUNNING: (
    <span className='awsui-util-status-positive'>
      <Icon name='status-positive' /> Running
    </span>
  ),
  STARTING: (
    <span className='awsui-util-status-positive' style={{ color: 'LimeGreen' }}>
      <Icon name='status-pending' /> Starting
    </span>
  ),
  IDLE: (
    <span className='awsui-util-status-inactive'>
      <Icon name='status-stopped' /> Idle
    </span>
  ),
  READY_TO_RELEASE: (
    <span className='awsui-util-status-negative' style={{ color: 'IndianRed' }}>
      <Icon name='status-pending' /> Ready to Release
    </span>
  ),
  TERMINATED: (
    <span className='awsui-util-status-negative'>
      <Icon name='status-negative' /> Terminated
    </span>
  ),

  // Other:
  true: <span style={{ color: 'LimeGreen' }}>True</span>,
  false: <span style={{ color: 'IndianRed' }}>False</span>,
  TRUE: <span style={{ color: 'LimeGreen' }}>True</span>,
  FALSE: <span style={{ color: 'IndianRed' }}>False</span>,
  YES: <span style={{ color: 'LimeGreen' }}>Yes</span>,
  NO: <span style={{ color: 'IndianRed' }}>No</span>,
};

export const AuditLogsCard = ({ auditLogs = [], loading = false }) => (
  <ExpandableSection
    header={<Header variant='h2'> {`Audit logs (${auditLogs.length || 0})`} </Header>}
    variant='container'
  >
    <ColumnLayout columns={1}>
      <LabeledText
        value={
          <ul>
            {auditLogs.reverse().map((log) => (
              <li>
                <b>{dateString(log.time)}:</b> {log.message}
              </li>
            ))}
          </ul>
        }
        loading={loading}
      />
    </ColumnLayout>
  </ExpandableSection>
);

export const reqStar = <span style={{ color: 'red', fontWeight: 'bold' }}> *</span>;

/**
 * Creates the desired <Form> input Components and links it to the appropriate this.state.values[...subkeys] object.
 * @param {object} input - The parameters for the rendered component. Special fields include:
 *      * {string} fieldType - Currently accepts {'number', 'input', 'select', 'toggle', 'multiselect', 'textArea', 'json'}. Default is 'input'
 *      * {string} fieldKey - The key within this.state.values[...subkeys] associated with the particular form field.
 *      * {string} fieldLabel - The label placed above the form field.
 *      * {string} fieldDescription - The description between the label and the form field.
 * @param {array<string>} subkeys - Any subkeys nested within the values object. Default is [] (directly queries this.state.values).
 * @returns An Component of the specific form field.
 */
function renderInput(obj, subkeys = []) {
  var input = { ...obj };
  const fieldKey = pop(input, 'fieldKey', null);
  if (fieldKey == null) throw `fieldKey must not be null: ${JSON.stringify(input)}`;

  const fieldLabel = pop(input, 'fieldLabel', '');
  const fieldDescription = pop(input, 'fieldDescription', '');
  const fieldType = pop(input, 'fieldType', 'input');

  var values = this.state.values;

  input.value = typeof input.value === 'boolean' ? input.value : get(values, [...subkeys, fieldKey]);
  input.checked = !!input.value;

  input.name = `target-${subkeys.join('-')}-${fieldKey}`;
  input.placeholder = input.placeholder || '';
  input.ariaRequired = !pop(input, 'optional', false);

  if (!('invalid' in input)) {
    input.invalid = isInputInvalid(input, this.state.attempted);
  }

  input.errorText =
    this.state.attempted && validate.isFalsy(input.value)
      ? 'Input is required, cannot be blank.'
      : input.errorText || 'Input is invalid.';

  const onAction = pop(input, 'onAction', (e) => {
    this.setState({
      values: set(values, [...subkeys, fieldKey], fieldType == 'toggle' ? e.detail.checked : e.detail.value),
    });
  });
  if (['multiselect', 'select'].includes(fieldType)) {
    input.onInput = onAction;
  } else {
    input.onChange = onAction;
  }

  return (
    <FormField
      label={[fieldLabel, input.ariaRequired ? reqStar : '']}
      description={fieldDescription}
      errorText={input.invalid && input.errorText}
    >
      {!(fieldType == 'blank') &&
        (fieldType == 'number' ? (
          <Input {...input} type='number' />
        ) : fieldType == 'toggle' ? (
          <Toggle {...input} />
        ) : fieldType == 'textarea' ? (
          <Textarea {...input} />
        ) : fieldType == 'json' ? (
          [
            <Textarea {...input} />,
            <a
              onClick={() => {
                this.setState({
                  values: set(values, [...subkeys, fieldKey], JSON.stringify(JSON.parse(input.value), null, 2)),
                });
              }}
            >
              Prettify JSON
            </a>,
          ]
        ) : fieldType == 'multiselect' ? (
          <Multiselect
            {...input}
            options={this.state.select_options[fieldKey] || []}
            selectedOptions={this.state.selected[fieldKey] || []}
            onChange={({ detail }) => {
              var state = { ...this.state };

              const selectedOptions = input.disable_autosort
                ? detail.selectedOptions
                : [...detail.selectedOptions].sort((a, b) => (a.value > b.value ? 1 : -1));

              state.selected[fieldKey] = selectedOptions;

              const jsonValue = selectedOptions.map((x) => x['value']);
              state = set(state, ['values', ...subkeys, fieldKey], jsonValue);
              this.setState(state);
            }}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            selectedAriaLabel='Selected'
          />
        ) : fieldType == 'select' ? (
          <Select
            {...input}
            options={this.state.select_options[fieldKey] || []}
            selectedOption={this.state.selected[fieldKey] || []}
            onChange={({ detail }) => {
              var state = { ...this.state };
              state.selected[fieldKey] = detail.selectedOption;
              this.setState(set(state, ['values', ...subkeys, fieldKey], detail.selectedOption['value']));
            }}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            selectedAriaLabel='Selected'
          />
        ) : (
          <Input {...input} />
        ))}
    </FormField>
  );
}

export function renderInputsFromList(inputs, subkeys = [], columnParams = {}) {
  const render = renderInput.bind(this);

  return (
    <SpaceBetween size='m'>
      <ColumnLayout {...columnParams}>{inputs.map((input) => render(input, subkeys))}</ColumnLayout>
    </SpaceBetween>
  );
}

export const isInputInvalid = (input, attempted = false) =>
  (validate.isEmpty(input.value) && attempted && input.ariaRequired) ||
  (validate.isNotEmpty(input.value) && !pop(input, 'validation', () => true)(input.value));

export function allInputsValid_helper(obj, subkeys = []) {
  var input = { ...obj };
  const fieldKey = pop(input, 'fieldKey', null);
  if (fieldKey == null) throw `fieldKey must not be null: ${JSON.stringify(input)}`;

  input.value = typeof input.value === 'boolean' ? input.value : get(this.state.values, [...subkeys, fieldKey]);

  input.ariaRequired = !pop(input, 'optional', false);

  return pop(input, ['validation'], isInputInvalid(input, this.state.attempted));
}

export function someInputInvalid(inputs, subkeys = []) {
  const invalid = allInputsValid_helper.bind(this);
  return inputs.some((input) => invalid(input, subkeys));
}
