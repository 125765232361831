import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Container,
  Header,
  Pagination,
  PropertyFilter,
  Table,
  TokenGroup,
} from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/notifications/common';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { getDataSetsFromHybridCatalogDatabase, getDataSourceTables } from 'src/api/catalog';
import { listDataPermissions } from 'src/api/permissions';
import {
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_STATUS_ACTIVE,
  LAKE_FORMATION_DATASOURCE_ID,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { Toggle } from '@amzn/awsui-components-react';
import { registerStatus } from 'src/commons/common';

export interface GlueDatasetsProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  setRegisterItems: any;
  registerItems: any[];
  selectedDatabaseName: string;
  setRegisterDataPermissionType: any;
  setHasAllTablePermission: any;
  setNotifications: any;
}

export const GlueDatasets = (props: GlueDatasetsProps) => {
  const [glueTables, setGlueTables] = useState([]);
  const [loadingDatasets, setLoadingDatasets] = useState(false);
  const [selectedDatasets, setSelectedDatasets] = useState(undefined);
  const [autoRegisterDatasets, setAutoRegisterDatasets] = useState(undefined); // used for group to workspace migration
  const [piiDatasets, setPIIDatasets] = useState({});

  const getAllDatasetsFromHCForSelectedCatalogAndDatabase = async () => {
    let request = {
      DatabaseName: props.selectedDatabaseName,
      CatalogId: props.activeWorkspace.accountId,
      NextToken: undefined,
    };
    let response = await getDataSetsFromHybridCatalogDatabase(request);
    let dataSetList = [...response.DataSetList];
    while (response.NextToken != null) {
      request.NextToken = response.NextToken;
      response = await getDataSetsFromHybridCatalogDatabase(request);
      dataSetList.push(...response.DataSetList);
    }
    return dataSetList;
  };

  const handleRefresh = async () => {
    setLoadingDatasets(true);
    let glueTablesMap = new Map();
    let getDataSourceTablesRequest = {
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
      CatalogId: props.activeWorkspace.accountId,
      DatabaseName: props.selectedDatabaseName,
      Region: props.activeWorkspace.region,
      NextToken: null,
    };
    try {
      let tableList = [];
      let getDataSourceTablesResponse = await getDataSourceTables(getDataSourceTablesRequest);
      tableList = getDataSourceTablesResponse.TableList;
      while (getDataSourceTablesResponse.NextToken != null) {
        getDataSourceTablesRequest.NextToken = getDataSourceTablesResponse.NextToken;
        getDataSourceTablesResponse = await getDataSourceTables(getDataSourceTablesRequest);
        tableList.push(...getDataSourceTablesResponse.TableList);
      }
      setGlueTables(tableList);
      for (let table of tableList) {
        glueTablesMap.set(table.Name, table);
      }
    } catch (err) {
      setLoadingDatasets(false);
      console.log('Error getting glue tables from: ', props.selectedDatabaseName, err);
    }
    let hcRegisteredDatasets = await getAllDatasetsFromHCForSelectedCatalogAndDatabase();
    let autoRegisterDatasetsList = [];
    for (let dataSet of hcRegisteredDatasets) {
      if (
        dataSet?.DataAccessRole == props.activeWorkspace.workspaceRoleArn &&
        glueTablesMap.has(dataSet?.IdInfo.TableName)
      ) {
        let registeredTableInfo = glueTablesMap.get(dataSet?.IdInfo.TableName);
        registeredTableInfo.registered = true;
        registeredTableInfo.PII = dataSet?.PII ?? false;
        glueTablesMap.set(dataSet?.IdInfo.TableName, registeredTableInfo);
      }
      // if migrating from group to workspace register all existing datasets
      if (glueTablesMap.has(dataSet?.IdInfo.TableName)) {
        let registeredTableInfo = glueTablesMap.get(dataSet?.IdInfo.TableName);
        if (registeredTableInfo.registered == undefined || !registeredTableInfo.registered) {
          registeredTableInfo.makeSelectedByDefault = true;
          glueTablesMap.set(dataSet?.IdInfo.TableName, registeredTableInfo);
          // insert into autoSelectRegisterDatasetList
          autoRegisterDatasetsList.push({
            label: dataSet?.IdInfo.TableName,
            labelTag: dataSet?.IdInfo.DatabaseName,
            disabled: true,
            pii: false, // all datasets at group are non PII
            tags: [],
          });
        }
      }
    }
    setAutoRegisterDatasets(autoRegisterDatasetsList);
    props.setRegisterItems(autoRegisterDatasetsList);
    let haveAllTablePermission = await checkAllTablePermission();
    props.setHasAllTablePermission(haveAllTablePermission);
    setLoadingDatasets(false);
  };

  const isDatasetSelected = (name) => selectedDatasets?.find((e) => e?.Name == name) !== undefined;

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'datasetName',
      header: 'Dataset name',
      cell: (item) => item.Name,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => registerStatus(item.registered !== undefined),
      minWidth: 200,
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (_) => props.selectedDatabaseName,
      minWidth: 200,
    },
    {
      id: 'isPII',
      header: 'Has PII data',
      cell: (item) => (
        <Toggle
          onChange={({ detail }) => {
            setPIIDatasets((prevState) => ({
              ...prevState,
              [item.Name]: detail.checked,
            }));
          }}
          checked={
            (piiDatasets[item.Name] || // marked as pii
              item.PII || // registered and pii
              (isDatasetSelected(item.Name) && // selected and not yet marked
                piiDatasets[item.Name] == undefined)) ??
            false
          }
          disabled={item.registered !== undefined || !isDatasetSelected(item.Name)}
        />
      ),
      minWidth: 200,
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(
    glueTables,
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: { keepSelection: true },
      propertyFiltering: {
        filteringProperties: [
          {
            propertyLabel: 'Dataset name',
            key: 'Name',
            groupValuesLabel: 'Dataset names',
          },
        ],
      },
    },
  );

  useEffect(() => {
    const { selectedItems } = collectionProps;
    setSelectedDatasets(selectedItems);
  }, [collectionProps.selectedItems]);

  useEffect(() => {
    handleDatasetsUpdate();
  }, [selectedDatasets, piiDatasets]);

  const handleDatasetsUpdate = () => {
    let itemsToBeRegistered = [];
    for (let data of selectedDatasets ?? []) {
      itemsToBeRegistered.push({
        label: data?.Name,
        labelTag: props.selectedDatabaseName,
        disabled: false,
        pii: piiDatasets[data.Name] ?? false,
        tags: piiDatasets[data?.Name] || piiDatasets[data?.Name] == undefined ? ['PII'] : [],
      });
    }
    // for group to workspace migration
    for (let data of autoRegisterDatasets ?? []) {
      itemsToBeRegistered.push(data);
    }
    props.setRegisterItems(itemsToBeRegistered);
    props.setRegisterDataPermissionType('ALLTablePermission');
  };

  const checkAllTablePermission = async () => {
    let request = {
      ownerId: props.activeWorkspace.workspaceId,
      resource: {
        table: {
          databaseName: props.selectedDatabaseName,
          catalogId: props.activeWorkspace.accountId,
          tableWildcard: {},
        },
      },
      region: props.activeWorkspace.region,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      option: DATA_PERMISSION_PUBLISHER_OPTION,
    };
    try {
      let listAllTablesPermissionResponse = await listDataPermissions(request);
      if (
        listAllTablesPermissionResponse !== undefined &&
        listAllTablesPermissionResponse.dataPermissionList.length > 0
      ) {
        return true;
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        loading={loadingDatasets}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Lake Formation Datasets
                  <span className='awsui-util-header-counter'>{` (${glueTables.length})`}</span>
                </>
              }
            />
          </>
        }
        selectionType='multi'
        isItemDisabled={(glueTable) => glueTable.registered || glueTable.makeSelectedByDefault}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No datasets</p>
          </div>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingDatasets}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />

      <br />
      <br />
      {(selectedDatasets || autoRegisterDatasets) && (
        <>
          {props.registerItems.length !== 0 && (
            <Container
              header={
                <Header variant='h2' description='Registers the selected and existing legacy datasets to workspace'>
                  Datasets to register
                </Header>
              }
            >
              {/*Token group includes selected and autoselected datasets to register*/}
              <TokenGroup
                items={props.registerItems}
                alignment='vertical'
                onDismiss={({ detail: { itemIndex } }) => {
                  props.setRegisterItems([
                    ...props.registerItems.slice(0, itemIndex),
                    ...props.registerItems.slice(itemIndex + 1),
                  ]);
                }}
              />
            </Container>
          )}
        </>
      )}
    </>
  );
};
