import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Button,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Input,
  Header,
  Container,
  SpaceBetween,
  Textarea,
  Select,
  ExpandableSection,
  Toggle,
} from '@amzn/awsui-components-react-v3';

import { Redirect, useLocation } from 'react-router-dom';
import * as validate from '../../commons/validationUtils';
import { Page } from '../../routes/Paths';
import { editTemplate } from 'src/api/permissions';
import { validateApprovalUrl, validateTemplateUrl } from '../../commons/validationUtils';
import { FORM_CONTENT_TYPE } from 'src/commons/constants';

export interface EditTemplateProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}

export const EditTemplate = (props: EditTemplateProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const { state } = useLocation();
  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [templateName, setTemplateName] = useState(state.template.name);
  const [description, setDescription] = useState(state.template.description);
  const [simTemplateName, setSimTemplateName] = useState(
    state.template.accessManagementInfo?.simTemplates?.[0]?.simTemplateName,
  );
  const [simTemplateLink, setSimTemplateLink] = useState(
    state.template.accessManagementInfo?.simTemplates?.[0]?.simTemplateLink,
  );
  const [templateId] = useState(state.template.templateId);
  const [templateType, setTemplateType] = useState(null);
  const [approvalTemplateLink, setApprovalTemplateLink] = useState(
    state.template.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink,
  );
  const [approvalTemplateTitle, setApprovalTemplateTitle] = useState(
    state.template.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateTitle,
  );
  const [autoApproval, setAutoApproval] = useState(
    state.template.accessManagementInfo?.approvalsWorkflowTemplate?.autoApproval,
  );
  const [allowMergeApprovalWorkflow, setAllowMergeApprovalWorkflow] = useState(
    state.template.accessManagementInfo?.approvalsWorkflowTemplate?.allowMergeApprovalWorkflow,
  );

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);
    if (!state) {
      setRedirect(Page.TEMPLATES);
    }
  }, []);

  const validateEditTemplate = () => {
    if (
      state.template.name == templateName &&
      state.template.description == description &&
      state.template.accessManagementInfo?.simTemplates?.[0]?.simTemplateName == simTemplateName &&
      state.template.accessManagementInfo?.simTemplates?.[0]?.simTemplateLink == simTemplateLink &&
      state.template.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink == approvalTemplateLink &&
      state.template.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateTitle == approvalTemplateTitle &&
      state.template.accessManagementInfo?.approvalsWorkflowTemplate?.autoApproval == autoApproval &&
      state.template.accessManagementInfo?.approvalsWorkflowTemplate?.allowMergeApprovalWorkflow ==
        allowMergeApprovalWorkflow
    )
      return true;
    else if (
      validate.isFalsy(templateName) ||
      validate.isFalsy(description) ||
      ((validate.isFalsy(simTemplateName) || validate.isFalsy(simTemplateLink)) &&
        (validate.isFalsy(approvalTemplateLink) || validate.isFalsy(approvalTemplateTitle)))
    )
      return true;
    else return false;
  };

  const handleConfirm = async () => {
    setButtonLoading(true);
    let accessManagementInfo = {};
    if (simTemplateName && simTemplateName.length > 0) {
      accessManagementInfo['simTemplates'] = [
        {
          simTemplateLink: simTemplateLink,
          simTemplateName: simTemplateName,
        },
      ];
    }
    if (approvalTemplateLink && approvalTemplateLink.length > 0) {
      accessManagementInfo['approvalsWorkflowTemplate'] = {
        approvalTemplateLink: approvalTemplateLink,
        approvalTemplateTitle: approvalTemplateTitle,
        autoApproval: autoApproval,
        allowMergeApprovalWorkflow: allowMergeApprovalWorkflow,
      };
    }
    try {
      const editResponse = await editTemplate({
        templateId,
        resourceId: state.template.resourceId,
        name: templateName,
        description,
        accessManagementInfo: accessManagementInfo,
      });
      const status = editResponse.message == 'SUCCESS' ? 'success' : 'error';
      setButtonLoading(false);
      setRedirect({
        pathname: Page.TEMPLATES,
        state: {
          status,
          message: 'Template edited successfully',
        },
      });
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Failed to Edit the template for template id: ${templateId}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}
      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header variant='h1' description='Please specify the details of the template.'>
            Edit Template
          </Header>
        }
        actions={
          <SpaceBetween direction='horizontal' size='s'>
            <Button variant='link' onClick={() => setRedirect(Page.TEMPLATES)}>
              Cancel
            </Button>
            <Button variant='primary' loading={buttonLoading} onClick={handleConfirm} disabled={validateEditTemplate()}>
              {'Edit template'}
            </Button>
          </SpaceBetween>
        }
      >
        <Container className='custom-screenshot-hide' header={<Header variant='h2'>Template details</Header>}>
          <ColumnLayout>
            <SpaceBetween size='m'>
              <FormField label={<div>Template ID</div>}>
                <Input name='templateId' value={state.template.templateId} disabled={true} />
              </FormField>
              <FormField label={<div>Owner</div>}>
                <Input name='ownerId' value={state.template.ownerId} disabled={true} />
              </FormField>
              <FormField label={<div>Name</div>} description='Name of the template'>
                <Input
                  name='templateName'
                  placeholder='Name of the template'
                  ariaRequired={true}
                  onChange={(event) => setTemplateName(event.detail.value)}
                  invalid={templateName !== null && validate.isFalsy(templateName)}
                  value={templateName}
                />
              </FormField>
              <FormField label={<div>Description</div>} description='A brief description about template.'>
                <Textarea
                  name='description'
                  placeholder='A brief description about the template'
                  ariaRequired={true}
                  onChange={(event) => setDescription(event.detail.value)}
                  invalid={description !== null && validate.isFalsy(description)}
                  value={description}
                />
              </FormField>
              <FormField
                label={<div>Template type</div>}
                description='The type of template to be edited. Currently only access management template is supported.'
              >
                <Select
                  selectedOption={templateType}
                  onChange={({ detail }) => setTemplateType(detail.selectedOption)}
                  options={[{ label: 'Access Management', value: 'Access management' }]}
                  selectedAriaLabel='Selected'
                  placeholder='please select the template type'
                  errorText='Error fetching type.'
                  recoveryText='Retry'
                  empty={'No types available'}
                  filteringType='auto'
                  filteringAriaLabel='Filter origins'
                  ariaRequired={true}
                />
              </FormField>

              {templateType != null && templateType.label == 'Access Management' && (
                <>
                  <ExpandableSection defaultExpanded headerText='SIM template'>
                    <FormField
                      label={<div>SIM template name</div>}
                      description='A name to associate with the template URL.'
                    >
                      <Input
                        name='template-details'
                        placeholder='Name of the SIM template'
                        ariaRequired={true}
                        onChange={(event) => setSimTemplateName(event.detail.value)}
                        invalid={simTemplateName && validate.isFalsy(simTemplateName)}
                        value={simTemplateName}
                      />
                    </FormField>
                    <br />
                    <FormField
                      label={'SIM template link '}
                      description='A link to fill out a ticket using this template.'
                    >
                      <Input
                        onChange={({ detail }) => setSimTemplateLink(detail.value.trim())}
                        ariaRequired={true}
                        value={simTemplateLink}
                        placeholder='https://sim.amazon.com/123456789'
                        invalid={simTemplateLink != null && validateTemplateUrl(simTemplateLink) == false}
                      />
                    </FormField>
                  </ExpandableSection>
                  <ExpandableSection defaultExpanded headerText='Approval template'>
                    <FormField
                      label={'Approval template title '}
                      description='A title of the approval template for use on Omni.'
                    >
                      <Input
                        onChange={({ detail }) => {
                          setApprovalTemplateTitle(detail.value);
                        }}
                        ariaRequired={true}
                        value={approvalTemplateTitle}
                        placeholder='My approval template'
                        invalid={approvalTemplateTitle && validate.isFalsy(approvalTemplateTitle)}
                      />
                    </FormField>
                    <br />
                    <FormField
                      label={<div>Approval template link</div>}
                      description='A link to create the approval workflow from a template.'
                    >
                      <Input
                        name='template-details'
                        placeholder='https://approvals.amazon.com/TemplateDetails/1234'
                        ariaRequired={true}
                        onChange={(event) => setApprovalTemplateLink(event.detail.value.trim())}
                        value={approvalTemplateLink}
                        invalid={approvalTemplateLink !== null && validateApprovalUrl(approvalTemplateLink) == false}
                      />
                    </FormField>
                    <br />
                    <Toggle
                      onChange={({ detail }) => setAutoApproval(detail.checked)}
                      checked={autoApproval}
                      description='Automatically approve or deny requests in Omni based on the approval workflow status.'
                    >
                      Auto approval
                    </Toggle>
                    <br />
                    <Toggle
                      onChange={({ detail }) => setAllowMergeApprovalWorkflow(detail.checked)}
                      checked={allowMergeApprovalWorkflow}
                      description='Allow merging approval workflows. If enabled, the request will be merged to the
                           existing approval workflow. If disabled, will create a new approval workflow.'
                    >
                      Allow merge workflows
                    </Toggle>
                  </ExpandableSection>
                </>
              )}
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};
