import {
  FormField,
  Input,
  SpaceBetween,
  Textarea,
  Container,
  TextContent,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import React from 'react';
import { AdvisoryContent } from 'aws-sdk/clients/awsdlomni';
import { safelyRenderedAdvisoryMarkdownContent } from 'src/components/dataadvisory/constants';

export interface TitleAndContentEditorProps {
  title: string;
  setTitle?: any;
  content: AdvisoryContent; // string content and boolean isMarkdown
  setContent?: any;
  readonly: boolean;
}

export const TitleAndContentEditor = (props: TitleAndContentEditorProps) => {
  const isMarkdown = () => {
    return props.content.format == 'Markdown';
  };

  const titleErrorText = () => {
    if (!props.title) return;
    const length = props.title.length;
    if (length < 3) return 'Title should be at least 3 characters long.';
    if (length > 128) return 'Title should be at most 64 characters long. Current: ' + length;
  };
  const titleInvalid = () => {
    const title = props.title;
    return title && (title.length < 3 || title.length > 128);
  };
  const contentErrorText = () => {
    if (!props.content) return;
    if (!props.content.rawContent) return;
    const length = props.content.rawContent.length;
    if (length < 20) return 'Content should be at least 20 characters long.';
    if (length > 4096) return 'Content should be at most 4096 characters long. Current: ' + length;
  };
  const contentInvalid = () => {
    const content = props.content;
    return content && content.rawContent && (content.rawContent.length < 20 || content.rawContent.length > 4096);
  };

  return (
    <SpaceBetween size={'l'} direction={'vertical'}>
      <FormField
        label={'Advisory title'}
        description={'This is how the advisory will show throughout Omni and in the email subject line.'}
        errorText={titleErrorText()}
      >
        <Input
          value={props.title}
          invalid={titleInvalid()}
          onChange={(e) => {
            props.setTitle(e.detail.value);
          }}
        />
      </FormField>
      <FormField
        label={'Advisory content'}
        description={'This is the full content of the advisory.'}
        errorText={contentErrorText()}
      >
        <Textarea
          value={props.content.rawContent}
          rows={16}
          readOnly={props.readonly}
          invalid={contentInvalid()}
          onChange={(e) => {
            props.setContent({
              rawContent: e.detail.value,
              format: props.content.format,
            });
          }}
        />
        <Toggle
          checked={isMarkdown()}
          onChange={({ detail }) =>
            props.setContent({
              rawContent: props.content.rawContent,
              format: detail.checked ? 'Markdown' : 'PlainText',
            })
          }
        >
          Markdown
        </Toggle>
      </FormField>
      {isMarkdown() && (
        <FormField label={'Preview'}>
          <Container>
            <TextContent>
              {isMarkdown()
                ? safelyRenderedAdvisoryMarkdownContent(props.content.rawContent)
                : props.content.rawContent}
            </TextContent>
          </Container>
        </FormField>
      )}
    </SpaceBetween>
  );
};
