import * as React from 'react';
import { Component } from 'react';

import { getAggregateStatistics } from 'src/api/usageStats';
import { Table, Link, Header, Box, PieChart, MixedLineBarChart, Button } from '@amzn/awsui-components-react-v3/polaris';
import { ColumnLayout, Container, Select, SelectProps } from '@amzn/awsui-components-react-v3';

export interface UsageStatisticsDetailsProps {
  dataset: any;
}

export interface UsageStatisticsDetailsState {
  tableArn: string;
  aggregationPatternType: string;
  totalQueriesOnDatasetData: any;
  totalQueriesByCallerAccountData: any;
  totalQueriesByServiceData: any;
  topFiveQueriesData: any;
  xDomainForCallerAccountData: any;
  xDomainForServiceData: any;
  aggregationDropdownSelectedOption: SelectProps.Option;
}

export const dropdownSelectedOptions = [
  { label: '30 Days', value: 'aggregationBylast30days' },
  { label: '1 Month', value: 'aggregationBy1Month' },
  { label: '3 Months', value: 'aggregationBy3Months' },
];

export default class UsageStatistics extends Component<UsageStatisticsDetailsProps, UsageStatisticsDetailsState> {
  state = {
    aggregationPatternType: 'aggregationBy1Month',
    totalQueriesOnDatasetData: [],
    totalQueriesByCallerAccountData: [],
    totalQueriesByServiceData: [],
    topFiveQueriesData: [],
    xDomainForCallerAccountData: [],
    xDomainForServiceData: [],
    tableArn: undefined,
    aggregationDropdownSelectedOption: { label: '1 Month', value: 'aggregationBy1Month' },
  };

  // call the getAggregateStatistics api
  getUsageStatistics = async () => {
    console.log('Making getAggregateStatistics API call');
    const usageAggregateStats = await getAggregateStatistics({
      tableArn: this.createTableArnFromDatasetInfo(),
      aggregationPatternType: this.state.aggregationPatternType,
    });
    this.transformResponseData(usageAggregateStats);
  };
  componentDidMount = async () => {
    console.log('UsageStatistics | componentDidMount');
    console.log('UsageStatistics | aggregationPatternType : ' + this.state.aggregationPatternType);

    this.getUsageStatistics();
  };

  render() {
    return (
      <>
        <Container header={<Header variant='h2'>Aggregated by</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <Select
                selectedOption={this.state.aggregationDropdownSelectedOption}
                options={dropdownSelectedOptions}
                selectedAriaLabel='Selected'
                onChange={(e) => {
                  this.setState(
                    {
                      aggregationPatternType: e.detail.selectedOption.value,
                      aggregationDropdownSelectedOption: e.detail.selectedOption,
                    },
                    () => {
                      console.log('Dropdown after update = ' + this.state.aggregationPatternType);
                      this.getUsageStatistics();
                    },
                  );
                }}
              />
            </div>
          </ColumnLayout>
        </Container>

        <br />

        <Container header={<Header variant='h2'>Total athena queries</Header>}>
          <PieChart
            data={this.state.totalQueriesOnDatasetData}
            i18nStrings={{
              detailsValue: 'Value',
              detailsPercentage: 'Percentage',
              filterLabel: 'Filter displayed data',
              filterPlaceholder: 'Filter data',
              filterSelectedAriaLabel: 'selected',
              detailPopoverDismissAriaLabel: 'Dismiss',
              legendAriaLabel: 'Legend',
              chartAriaRoleDescription: 'pie chart',
              segmentAriaRoleDescription: 'segment',
            }}
            ariaDescription='Pie chart showing Number of Queries ran on a Dataset.'
            ariaLabel='Pie chart'
            errorText='Error loading data.'
            loadingText='Loading chart'
            recoveryText='Retry'
            variant='donut'
            empty={
              <Box textAlign='center' color='inherit'>
                <b>No data available</b>
                <Box variant='p' color='inherit'>
                  There is no data available
                </Box>
              </Box>
            }
            noMatch={
              <Box textAlign='center' color='inherit'>
                <b>No matching data</b>
                <Box variant='p' color='inherit'>
                  There is no matching data to display
                </Box>
                <Button>Clear filter</Button>
              </Box>
            }
          />
        </Container>

        <br />

        <Container header={<Header variant='h2'>Total Athena queries by caller account</Header>}>
          <MixedLineBarChart
            series={this.state.totalQueriesByCallerAccountData}
            xDomain={this.state.xDomainForCallerAccountData}
            yDomain={[0, 1000]}
            i18nStrings={{
              filterLabel: 'Filter displayed data',
              filterPlaceholder: 'Filter data',
              filterSelectedAriaLabel: 'selected',
              legendAriaLabel: 'Legend',
              chartAriaRoleDescription: 'line chart',
              yTickFormatter: function l(e) {
                return Math.abs(e) >= 1e9
                  ? (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
                  : Math.abs(e) >= 1e6
                  ? (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
                  : Math.abs(e) >= 1e3
                  ? (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
                  : e.toFixed(2);
              },
            }}
            ariaLabel='Mixed bar chart'
            errorText='Error loading data.'
            height={300}
            loadingText='Loading chart'
            recoveryText='Retry'
            xScaleType='categorical'
            xTitle='Number of Queries by CallerAccount'
            yTitle='No of Queries'
            empty={
              <Box textAlign='center' color='inherit'>
                <b>No data available</b>
                <Box variant='p' color='inherit'>
                  There is no data available
                </Box>
              </Box>
            }
            noMatch={
              <Box textAlign='center' color='inherit'>
                <b>No matching data</b>
                <Box variant='p' color='inherit'>
                  There is no matching data to display
                </Box>
                <Button>Clear filter</Button>
              </Box>
            }
          />
        </Container>

        <br />

        <Container header={<Header variant='h2'>Total athena queries by service</Header>}>
          <MixedLineBarChart
            series={this.state.totalQueriesByServiceData}
            xDomain={this.state.xDomainForServiceData}
            yDomain={[0, 1000]}
            i18nStrings={{
              filterLabel: 'Filter displayed data',
              filterPlaceholder: 'Filter data',
              filterSelectedAriaLabel: 'selected',
              legendAriaLabel: 'Legend',
              chartAriaRoleDescription: 'line chart',
              yTickFormatter: function l(e) {
                return Math.abs(e) >= 1e9
                  ? (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
                  : Math.abs(e) >= 1e6
                  ? (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
                  : Math.abs(e) >= 1e3
                  ? (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
                  : e.toFixed(2);
              },
            }}
            ariaLabel='Mixed bar chart'
            errorText='Error loading data.'
            height={300}
            loadingText='Loading chart'
            recoveryText='Retry'
            xScaleType='categorical'
            xTitle='Number of Queries by Service'
            yTitle='No of Queries'
            empty={
              <Box textAlign='center' color='inherit'>
                <b>No data available</b>
                <Box variant='p' color='inherit'>
                  There is no data available
                </Box>
              </Box>
            }
            noMatch={
              <Box textAlign='center' color='inherit'>
                <b>No matching data</b>
                <Box variant='p' color='inherit'>
                  There is no matching data to display
                </Box>
                <Button>Clear filter</Button>
              </Box>
            }
          />
        </Container>

        <br />

        <Container header={<Header variant='h2'>Top 5 athena queries</Header>}>
          <Table
            columnDefinitions={[
              {
                id: 'queryString',
                header: 'Query String',
                cell: (item) => item.queryString || '-',
              },
              {
                id: 'numberOfTimes',
                header: 'Query Number',
                cell: (item) => <Box float='left'>{item.numberOfTimes || '-'}</Box>,
              },
            ]}
            items={this.state.topFiveQueriesData}
            loadingText='Loading resources'
            resizableColumns
            empty={
              <Box textAlign='center' color='inherit'>
                <b>No resources</b>
                <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                  No resources to display.
                </Box>
                <Button>Create resource</Button>
              </Box>
            }
            header={<Header>Number of Queries by Year</Header>}
          />
        </Container>
      </>
    );
  }

  transformResponseData(usageAggregateStats: any) {
    console.log('response = ' + usageAggregateStats.toString());
    let totalQueriesOnDataset = this.transformNumberOfQueriesData(usageAggregateStats.numberOfQueries);
    let totalQueriesByCallerAccount = this.transformQueriesByCallerAccountData(
      usageAggregateStats.numberOfQueriesByCallerAccount,
    );
    let xDomainForCallerAccount = this.transformXdomainForCallerAccount(
      usageAggregateStats.numberOfQueriesByCallerAccount,
    );
    let totalQueriesByService = this.transformQueriesByServiceData(usageAggregateStats.numberOfQueriesByService);
    let xDomainForService = this.transformXdomainForService(usageAggregateStats.numberOfQueriesByService);
    let topFiveQueries = usageAggregateStats.topQueries;

    console.log('UsageStatistics | transformResponseData');
    console.log('UsageStatistics | totalQueriesOnDataset' + totalQueriesOnDataset);
    console.log('UsageStatistics | totalQueriesByCallerAccount' + totalQueriesByCallerAccount);
    console.log('UsageStatistics | xDomainForCallerAccount' + xDomainForCallerAccount);
    console.log('UsageStatistics | totalQueriesByService' + totalQueriesByService);
    console.log('UsageStatistics | xDomainForService' + xDomainForService);
    console.log('UsageStatistics | topFiveQueries' + topFiveQueries);

    this.setState({
      totalQueriesOnDatasetData: totalQueriesOnDataset,
      totalQueriesByCallerAccountData: totalQueriesByCallerAccount,
      xDomainForCallerAccountData: xDomainForCallerAccount,
      totalQueriesByServiceData: totalQueriesByService,
      xDomainForServiceData: xDomainForService,
      topFiveQueriesData: topFiveQueries,
    });
  }

  transformNumberOfQueriesData(NumberOfQueries: any) {
    let dataList = [];

    Object.keys(NumberOfQueries).map(function (keyName) {
      console.log('NumberOfQueries = ' + keyName);
      let item = { title: keyName, value: NumberOfQueries[keyName] };
      dataList.push(item);
    });

    return dataList;
  }

  transformQueriesByCallerAccountData(NumberOfQueriesByCallerAccount: any) {
    let dataList = [];
    let accountDict = {};
    Object.keys(NumberOfQueriesByCallerAccount).map(function (keyName) {
      let dateKey = keyName;
      let valueDict = NumberOfQueriesByCallerAccount[dateKey];

      Object.keys(valueDict).map(function (accountId) {
        let numOfQueries = valueDict[accountId];
        // If accountNumber already seen before then add this record to its data
        // If accountNumber is new then create its object and add to the dict
        if (accountId in accountDict) {
          let accountDetails = accountDict[accountId];
          accountDetails['data'].push({ x: dateKey, y: numOfQueries });
          accountDict[accountId] = accountDetails;
        } else {
          const accountDetails = {
            title: (
              <Link href={'https://Isengard.amazon.com/manage-accounts/' + accountId + '/manage'}>{accountId}</Link>
            ),
            type: 'line',
            data: [{ x: dateKey, y: numOfQueries }],
            valueFormatter: (e) => e.toLocaleString('en-US') + ' queries',
          };
          accountDict[accountId] = accountDetails;
        }
      });
    });

    dataList = Object.values(accountDict);
    return dataList;
  }

  transformXdomainForCallerAccount(NumberOfQueriesByCallerAccount: any) {
    let dateList = [];
    Object.keys(NumberOfQueriesByCallerAccount).map(function (keyName) {
      dateList.push(keyName);
    });
    return dateList;
  }

  transformQueriesByServiceData(NumberOfQueriesByService: any) {
    let dataList = [];
    let serviceDict = {};
    Object.keys(NumberOfQueriesByService).map(function (keyName) {
      let dateKey = keyName;
      let valueDict = NumberOfQueriesByService[dateKey];

      Object.keys(valueDict).map(function (serviceName) {
        let numOfQueries = valueDict[serviceName];
        // If service already seen before then add date:numberOfQueries record to its data
        // If service is new then create its object and add to the dict
        if (serviceName in serviceDict) {
          let serviceDetails = serviceDict[serviceName];
          serviceDetails['data'].push({ x: dateKey, y: numOfQueries });
          serviceDict[serviceName] = serviceDetails;
        } else {
          let serviceDetails = {
            title: serviceName,
            type: 'bar',
            data: [{ x: dateKey, y: numOfQueries }],
            valueFormatter: (e) => e.toLocaleString('en-US') + ' queries',
          };
          console.log('serviceDetails for title = ' + serviceDetails['title']);
          console.log('serviceDetails for type = ' + serviceDetails['type']);
          serviceDict[serviceName] = serviceDetails;
        }
      });
    });

    dataList = Object.values(serviceDict);
    return dataList;
  }

  transformXdomainForService(NumberOfQueriesByService: any) {
    let dateList = [];
    Object.keys(NumberOfQueriesByService).map(function (keyName) {
      dateList.push(keyName);
    });
    return dateList;
  }

  createTableArnFromDatasetInfo() {
    var arn =
      'arn:aws:glue:' +
      this.props.dataset.IdInfo.Region +
      ':' +
      this.props.dataset.IdInfo.CatalogId +
      ':table:' +
      this.props.dataset.IdInfo.DatabaseName +
      ':' +
      this.props.dataset.IdInfo.TableName;
    console.log('UsageStatistics | tableArn = ' + arn);
    return arn;
  }
}
