import * as React from 'react';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { isMemberOfGroupingMechanismId } from 'src/api/permissions';
import { Box, Button, Modal, SpaceBetween, Spinner } from '@amzn/awsui-components-react-v3';
import { getGroupMechanismIdLink, getPhoneToolLink } from 'src/commons/common';
import { BRASS_ACTOR_TYPE_PRINCIPAL } from 'src/commons/constants';

export interface UserMembershipModalProps {
  userId: string;
  visible: boolean;
  onClose: () => void;
  membership: MembershipMap;
  additionalReasons?: Reason[];
}

export type Reason = ReactNode;

export interface MembershipMap {
  [key: string]: Reason[];
}

export const UserMembershipModal = ({
  userId,
  visible,
  onClose,
  membership,
  additionalReasons,
}: UserMembershipModalProps) => {
  const [loading, setLoading] = useState(true);
  const [membershipList, setMembershipList] = useState([] as string[]);

  useEffect(() => {
    handleRefresh();
  }, [userId]);
  let groupings = Object.keys(membership);

  const handleRefresh = async () => {
    try {
      setLoading(true);

      if (userId && groupings?.length) {
        let promises = groupings?.map((grouping) =>
          isMemberOfGroupingMechanismId({
            groupingMechanismId: grouping,
            targetUser: {
              userId,
              userType: BRASS_ACTOR_TYPE_PRINCIPAL,
            },
          }).catch((_) => {
            // default to not being member
            return { memberOf: false };
          }),
        );
        const isMemberList = await Promise.all(promises);
        setMembershipList(isMemberList.flatMap((o, idx) => (o.memberOf ? [groupings[idx]] : [])));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getGroupMembershipList = () => {
    return membershipList.map((grouping) => {
      return (
        <Fragment key={'membership_reasons'}>
          <li key={grouping}>{getGroupMechanismIdLink(grouping, true)}</li>
          <ul key={grouping + ' reasons'}>
            {membership[grouping].map((reason, idx) => (
              <li key={idx}>{reason}</li>
            ))}
          </ul>
        </Fragment>
      );
    });
  };

  const getAdditionalReasons = () => {
    if (additionalReasons && additionalReasons.length) {
      return (
        <Fragment key={'additional_reasons'}>
          <li>
            <b>User</b> {getPhoneToolLink(userId)}
          </li>
          <ul key={'additional_reasons'}>
            {additionalReasons.map((reason, idx) => (
              <li key={idx}>{reason}</li>
            ))}
          </ul>
        </Fragment>
      );
    }
  };

  return (
    <Modal
      visible={visible}
      header={<div>User {getPhoneToolLink(userId)} membership details</div>}
      onDismiss={onClose}
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={onClose}>
              Cancel
            </Button>
            <Button variant='primary' onClick={onClose}>
              OK
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Box variant='p'>
            The following describes why user <b>{userId}</b> has access to Omni.
          </Box>
          <Box variant='p'>
            User <b>{userId}</b> is a member of the following POSIX/Teams associated to Omni
          </Box>
          <ul data-testid='membership-list'>
            {getGroupMembershipList()}
            {getAdditionalReasons()}
          </ul>
          <Box variant='p'>
            To remove <b>{userId}</b> from Omni, you need to remove them directly from the underlying POSIX/Team and let
            Omni sync the list of users by itself.
          </Box>
        </>
      )}
    </Modal>
  );
};
