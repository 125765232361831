import {
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  Flashbar,
  FlashbarProps,
  Form,
  FormField,
  Header,
  Input,
  Link,
  RadioGroup,
  Select,
  Textarea,
} from '@amzn/awsui-components-react-v3';
import {
  AWS_DATA_HANDLING_POLICY_URL,
  DATACONSUMER,
  DATAPROVIDER,
  DATAPROVIDER_AND_CONSUMER,
  FORM_CONTENT_TYPE,
  OMNI_TERMS_AND_CONDITIONS_URL,
  WEBSITE_NAME,
} from 'src/commons/constants';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as validate from 'src/commons/validationUtils';
import { createGroup, isMemberOfGroupingMechanismId } from 'src/api/permissions';
import { Page } from 'src/routes';
import { Redirect } from 'react-router-dom';
import { getOmniOncallLink, getRequestSubmittedWithAutoApproval } from 'src/commons/common';

export interface CreateNewGroupFormProps {
  setContentType: any;
  allowlisted: boolean;
  cancelRedirect?: string;
}

export const CreateNewGroupForm = (props: CreateNewGroupFormProps) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonText] = useState('Submit');
  const [notifications, setNotifications] = useState([]);
  const [teamNameValidation, setTeamNameValidation] = useState(false);
  const [teamName, setTeamName] = useState('');
  const [teamWikiValidation, setTeamWikiValidation] = useState(false);
  const [teamWiki, setTeamWiki] = useState('');
  const [teamEmailValidation, setTeamEmailValidation] = useState(false);
  const [teamEmailErrorText, setTeamEmailErrorText] = useState('');
  const [teamEmail, setTeamEmail] = useState('');
  const [categoryValidation, setCategoryValidation] = useState(false);
  const [category, setCategory] = useState('');
  const [typeValidation, setTypeValidation] = useState(false);
  const [type, setType] = useState('');
  const [itemValidation, setItemValidation] = useState(false);
  const [item, setItem] = useState('');
  const [customerType, setCustomerType] = useState(DATACONSUMER);
  const [dataAccessAndProcessingSelectValidation, setDataAccessAndProcessingSelectValidation] = useState(false);
  const [dataAccessAndProcessingErrorText, setDataAccessAndProcessingErrorText] = useState('');
  const [optionLabel, setOptionLabel] = useState('');
  const [selectedDataAccessOption, setSelectedDataAccessOption] = useState(undefined);
  const [dataAccessAndProcessingValidation, setDataAccessAndProcessingValidation] = useState(false);
  const [dataAccessAndProcessing, setDataAccessAndProcessing] = useState('');
  const [useCaseValidation, setUseCaseValidation] = useState(false);
  const [useCase, setUseCase] = useState('');
  const [existingUseCase, setExistingUseCase] = useState('');
  const [groupIdValidation, setGroupIdValidation] = useState(false);
  const [groupIdErrorText, setGroupIdErrorText] = useState('');
  const [groupId, setGroupId] = useState('');
  const [groupingMechanismIdValidation, setGroupingMechanismIdValidation] = useState(false);
  const [groupingMechanismIdErrorText, setGroupingMechanismIdErrorText] = useState('');
  const [groupingMechanismId, setGroupingMechanismId] = useState('');
  const [dataLatency] = useState('N/A');
  const [dataAvailabilitySLA] = useState('N/A');
  const [onboardTimeline] = useState('N/A');
  const NOT_ALLOWLISTED_HEADER = `${WEBSITE_NAME} is a one-stop portal to discover, manage access, publish and 
    subscribe to data in AWS Data Lake.`;
  const ALLOWLISTED_HEADER = `If you have already onboarded, you can still create a new group.`;
  const [redirect, setRedirect] = useState('');
  const [acceptedToS, setAcceptedToS] = useState(false);
  const [readDataHandlingPolicy, setReadDataHandlingPolicy] = useState(false);
  const [acceptedTermsErrorText, setAcceptedTermsErrorText] = useState('');

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);
  }, []);

  const submitCreateGroupRequest = async () => {
    await createGroup({
      groupId: groupId,
      teamName: teamName,
      teamWiki: teamWiki,
      primaryCTI: {
        category: category,
        type: type,
        item: item,
      },
      teamDistributionList: teamEmail,
      useCase: useCase,
      existingUseCase: existingUseCase,
      dataLatency: dataLatency,
      dataAvailabilitySLA: dataAvailabilitySLA,
      dataAccessAndProcessing: dataAccessAndProcessing,
      onboardTimeline: onboardTimeline,
      customerType: customerType,
      groupingMechanismId: groupingMechanismId,
    });
  };

  const handleConfirm = async () => {
    setButtonLoading(true);
    const validated = await validateOnboardingRequest();
    if (!validated) {
      setNotification('Some of the required fields are missing or invalid', '');
      const target = document.getElementById('flash-bar');
      target.scrollIntoView(true);
      setButtonLoading(false);
      return;
    }

    try {
      await submitCreateGroupRequest();
      setNotification('', getRequestSubmittedWithAutoApproval());
    } catch (e) {
      console.error('Failed to submit onboarding request with cause:', e);
      let errorMessage = (
        <>
          Please contact {getOmniOncallLink()} to help you with your onboarding request.
          <br /> {e.message}
        </>
      );
      // TO DO with error handling from backend
      setNotification('Failed to submit your onboarding request', errorMessage);
    }
    const target = document.getElementById('flash-bar');
    target.scrollIntoView(true);
    setButtonLoading(false);
  };

  const setNotification = (header, message) => {
    if (header === '') {
      setNotifications([
        {
          type: 'success' as FlashbarProps.Type,
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error' as FlashbarProps.Type,
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const validateOnboardingRequest = async () => {
    const validGroupId = validate.isValidGroup(groupId);
    const validTeamEmail = validate.isValidAmazonEmail(teamEmail);

    let isGoodRequest = true;
    // Group ID
    if (groupId.length === 0 || !groupId.trim()) {
      setGroupIdValidation(true);
      setGroupIdErrorText('Group name must not be empty');
      isGoodRequest = false;
    } else if (!validGroupId) {
      setGroupIdValidation(true);
      setGroupIdErrorText('Invalid group name found');
      isGoodRequest = false;
    }

    // Team email
    if (teamEmail.length === 0 || !teamEmail.trim()) {
      setTeamEmailValidation(true);
      setTeamEmailErrorText('Team email must not be empty');
      isGoodRequest = false;
    } else if (!validTeamEmail) {
      setTeamEmailValidation(true);
      setTeamEmailErrorText('Invalid team email found');
      isGoodRequest = false;
    }

    // Team name
    if (teamName.length === 0 || !teamName.trim()) {
      setTeamNameValidation(true);
      isGoodRequest = false;
    }

    // Team wiki
    if (teamWiki.length === 0 || !teamWiki.trim()) {
      setTeamWikiValidation(true);
      isGoodRequest = false;
    }

    // Category
    if (category.length === 0 || !category.trim()) {
      setCategoryValidation(true);
      isGoodRequest = false;
    }

    // Type
    if (type.length === 0 || !type.trim()) {
      setTypeValidation(true);
      isGoodRequest = false;
    }

    // Item
    if (item.length === 0 || !item.trim()) {
      setItemValidation(true);
      isGoodRequest = false;
    }

    if (customerType == DATACONSUMER) {
      // Use case
      if (useCase.length === 0 || !useCase.trim()) {
        setUseCaseValidation(true);
        isGoodRequest = false;
      }

      // Data access and processing
      if (optionLabel.length === 0) {
        setDataAccessAndProcessingValidation(true);
        setDataAccessAndProcessingErrorText('Please select an option');
        isGoodRequest = false;
      } else if (dataAccessAndProcessing.length === 0 || !dataAccessAndProcessing.trim()) {
        setDataAccessAndProcessingValidation(true);
        setDataAccessAndProcessingErrorText('Data access and processing description must not be empty');
        isGoodRequest = false;
      }
    }

    if (!(acceptedToS && readDataHandlingPolicy)) {
      setAcceptedTermsErrorText('You must accept all terms to onboard to Omni.');
      isGoodRequest = false;
    } else {
      setAcceptedTermsErrorText('');
    }

    if (groupingMechanismId.length === 0) {
      setGroupingMechanismIdValidation(true);
      setGroupingMechanismIdErrorText('Grouping mechanism ID must not be empty');
      isGoodRequest = false;
    } else {
      const result = await isMemberOfGroupingMechanismId({
        groupingMechanismId: groupingMechanismId,
      });
      if (!result.memberOf) {
        setGroupingMechanismIdValidation(true);
        setGroupingMechanismIdErrorText('You are not a member of the provided grouping ID');
        isGoodRequest = false;
      } else {
        setGroupingMechanismIdValidation(false);
      }
    }
    console.log('After alias good request?', isGoodRequest);
    return isGoodRequest;
  };

  const radioButtonSelection = (event) => {
    const value = event.detail.value;
    setCustomerType(value);
  };

  const dataAccessOptions = [
    {
      label: 'Amazon EMR',
      id: '1',
    },
    {
      label: 'Amazon Redshift',
      id: '2',
    },
    {
      label: 'Amazon Redshift Spectrum',
      id: '3',
    },
    {
      label: 'Other',
      id: '4',
    },
  ];

  if (redirect !== '') return <Redirect push to={redirect} />;

  return (
    <Form
      header={
        <Header variant='h1' description={props.allowlisted ? ALLOWLISTED_HEADER : NOT_ALLOWLISTED_HEADER}>
          {props.allowlisted ? 'Create new group' : `Welcome to ${WEBSITE_NAME}`}
        </Header>
      }
      actions={
        <div>
          <Button
            variant='link'
            onClick={() => {
              setRedirect(props.cancelRedirect ? props.cancelRedirect : Page.HOME);
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' onClick={handleConfirm} loading={buttonLoading}>
            {buttonText}
          </Button>
        </div>
      }
    >
      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>
      <Container className='custom-screenshot-hide' header={<h2>Onboarding request</h2>}>
        <ColumnLayout>
          <FormField label='Team name' errorText={teamNameValidation ? 'Team name must not be empty' : false}>
            <Input
              ariaRequired={true}
              onChange={(e) => {
                setTeamName(e.detail.value);
                setTeamNameValidation(false);
              }}
              value={teamName}
            />
          </FormField>

          <FormField label='Team wiki' errorText={teamWikiValidation ? 'Team wiki must not be empty' : false}>
            <Input
              ariaRequired={true}
              onChange={(e) => {
                setTeamWiki(e.detail.value);
                setTeamWikiValidation(false);
              }}
              value={teamWiki}
            />
          </FormField>

          <FormField label='Team email' errorText={teamEmailValidation ? teamEmailErrorText : false}>
            <Input
              ariaRequired={true}
              onChange={(e) => {
                setTeamEmail(e.detail.value);
                setTeamEmailValidation(false);
              }}
              value={teamEmail}
            />
          </FormField>
          <FormField label='Category (C)' errorText={categoryValidation ? 'CTI must not be empty' : false}>
            <Input
              ariaRequired={true}
              onChange={(e) => {
                setCategory(e.detail.value);
                setCategoryValidation(false);
              }}
              value={category}
            />
          </FormField>

          <FormField label='Type (T)' errorText={typeValidation ? 'CTI must not be empty' : false}>
            <Input
              ariaRequired={true}
              onChange={(e) => {
                setType(e.detail.value);
                setTypeValidation(false);
              }}
              value={type}
            />
          </FormField>

          <FormField label='Item (I)' errorText={itemValidation ? 'CTI must not be empty' : false}>
            <Input
              ariaRequired={true}
              onChange={(e) => {
                setItem(e.detail.value);
                setItemValidation(false);
              }}
              value={item}
            />
          </FormField>
          <RadioGroup
            onChange={radioButtonSelection}
            value={customerType}
            items={[
              {
                value: DATACONSUMER,
                label: "I'm a Data Consumer",
              },
              {
                value: DATAPROVIDER,
                label: "I'm a Data Provider",
              },
              {
                value: DATAPROVIDER_AND_CONSUMER,
                label: 'Both',
              },
            ]}
          />
          {(customerType == DATACONSUMER || customerType == DATAPROVIDER_AND_CONSUMER) && (
            <>
              <FormField
                label='Data access and processing'
                description='How are you looking to access and process the data?'
                errorText={dataAccessAndProcessingSelectValidation ? dataAccessAndProcessingErrorText : false}
              >
                <Select
                  options={dataAccessOptions}
                  placeholder='Select an option'
                  selectedAriaLabel='Selected'
                  onChange={(e) => {
                    {
                      setOptionLabel(e.detail.selectedOption.label);
                      setDataAccessAndProcessing('');
                      setDataAccessAndProcessingSelectValidation(false);
                      setSelectedDataAccessOption(e.detail.selectedOption);
                    }
                    if (optionLabel != 'Other') {
                      setDataAccessAndProcessing(e.detail.selectedOption.label);
                    }
                  }}
                  selectedOption={selectedDataAccessOption}
                />
              </FormField>
              {optionLabel === 'Other' && (
                <>
                  <FormField
                    description='Explain your way of data access and processing.'
                    errorText={
                      dataAccessAndProcessingValidation
                        ? 'Data access and processing description must not be empty'
                        : false
                    }
                  >
                    <Textarea
                      ariaRequired={true}
                      onChange={(e) => {
                        setDataAccessAndProcessing(e.detail.value);
                        setDataAccessAndProcessingValidation(false);
                      }}
                      value={dataAccessAndProcessing}
                    />
                  </FormField>
                </>
              )}

              <FormField
                label='Use case'
                description='Explain your use case and the business problem that it is solving.'
                errorText={useCaseValidation ? 'Use case must not be empty' : false}
              >
                <Textarea
                  ariaRequired={true}
                  onChange={(e) => {
                    setUseCase(e.detail.value);
                    setUseCaseValidation(false);
                  }}
                  value={useCase}
                />
              </FormField>

              <FormField
                label={
                  <div>
                    Existing use case <i> - optional</i>
                  </div>
                }
                description='Explain your existing use case. How is it currently satisfied? What are the challenges with the current approach?'
              >
                <Textarea
                  ariaRequired={false}
                  onChange={(e) => {
                    setExistingUseCase(e.detail.value);
                  }}
                  value={existingUseCase}
                />
              </FormField>
            </>
          )}
          <FormField
            label='Group name'
            description='A name for the group that will be created for your team.'
            constraintText="Use alphanumeric and '-_' characters. Maximum 64 characters."
            errorText={groupIdValidation ? groupIdErrorText : false}
          >
            <Input
              ariaRequired={true}
              onChange={(e) => {
                setGroupId(e.detail.value);
                setGroupIdValidation(false);
              }}
              placeholder={'AWSDataLake_Prod'}
              value={groupId}
            />
          </FormField>

          <FormField
            label='Grouping ID'
            description='Please enter either a POSIX group or a Teams ID. This ID will be used to determine your group members.'
            constraintText='An example of a POSIX group is "aws-datalake". An example of a Teams ID is "amzn1.abacus.team.t67zmnfvv22qpbubm6fa".'
            errorText={groupingMechanismIdValidation ? groupingMechanismIdErrorText : false}
          >
            <Input
              ariaRequired={true}
              placeholder={'amzn1.abacus.team.t67zmnfvv22qpbubm6fa'}
              onChange={(e) => {
                {
                  setGroupingMechanismId(e.detail.value);
                  setGroupingMechanismIdValidation(false);
                }
              }}
              value={groupingMechanismId}
            />
          </FormField>
          <FormField
            label={'Omni platform terms and conditions'}
            description={"Have you read Omni's terms and conditions and shared responsibility model?"}
            errorText={acceptedTermsErrorText}
          >
            <Checkbox onChange={({ detail }) => setAcceptedToS(detail.checked)} checked={acceptedToS}>
              I understand Omni's&nbsp;
              <Link external={true} href={OMNI_TERMS_AND_CONDITIONS_URL}>
                shared responsibility model and T&C
              </Link>
            </Checkbox>
            <Checkbox
              onChange={({ detail }) => setReadDataHandlingPolicy(detail.checked)}
              checked={readDataHandlingPolicy}
            >
              I have read the&nbsp;
              <Link external={true} href={AWS_DATA_HANDLING_POLICY_URL}>
                AWS Data Classification and Handling Policy
              </Link>
            </Checkbox>
          </FormField>
        </ColumnLayout>
      </Container>
    </Form>
  );
};
