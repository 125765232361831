import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ColumnLayout, Flashbar, FormField, Icon } from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/notifications/common';
import { auditWorkspace, getWorkspace, syncWorkspace } from 'src/api/permissions';
import { createGroupDetailLink, createPosixGroupLink } from 'src/routes';
import { Link } from '@amzn/awsui-components-react-v3/polaris';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { getRegion } from 'src/api/config';

export interface WorkspaceDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  workspace: any;
  setActiveTabId: any;
  refresh: any;
}

export const WorkspaceDetail = (props: WorkspaceDetailProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [workspace, setWorkspace] = useState(undefined);
  const [catalogManagerEnabled, setCatalogManagerEnabled] = useState(false);
  const [redshiftManagerEnabled, setRedshiftManagerEnabled] = useState(false);
  const [analyticsManagerEnabled, setAnalyticsManagerEnabled] = useState(false);
  const [auditButtonLoading, setAuditButtonLoading] = useState(false);
  const [syncButtonLoading, setSyncButtonLoading] = useState(false);
  const [refreshButtonLoading, setRefreshButtonLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const init = async () => {
    props.setActiveTabId('detail');
    if (!props.activeGroup && !props.match.params.workspaceId) return;
    try {
      setWorkspace(props.workspace);
      let supportedServices = props.workspace.supportedServices;

      for (let supportedService of supportedServices) {
        if (supportedService == 'FoundryAnalyticsManager') {
          setAnalyticsManagerEnabled(true);
        } else if (supportedService == 'FoundryCatalogManager') {
          setCatalogManagerEnabled(true);
        } else if (supportedService == 'FoundryRedshiftManager') {
          setRedshiftManagerEnabled(true);
        }
      }
    } catch (err) {
      console.log('Exception when get workspace for group', err);
    }
  };

  const handleRefresh = async () => {
    setRefreshButtonLoading(true);
    try {
      props.refresh();
      let getWorkspaceResponse = await getWorkspace({
        workspaceId: props.match.params.workspaceId,
      });
      setWorkspace(getWorkspaceResponse.workspace);
      let supportedServices = getWorkspaceResponse.workspace.supportedServices;

      for (let supportedService of supportedServices) {
        if (supportedService == 'FoundryAnalyticsManager') {
          setAnalyticsManagerEnabled(true);
        } else if (supportedService == 'FoundryCatalogManager') {
          setCatalogManagerEnabled(true);
        } else if (supportedService == 'FoundryRedshiftManager') {
          setRedshiftManagerEnabled(true);
        }
      }
      setRefreshButtonLoading(false);
    } catch (err) {
      console.log('Exception when get workspace for group', err);
      setRefreshButtonLoading(false);
    }
    setDisableButton(false);
  };

  useEffect(() => {
    init();
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const performEditWorkspace = () => {
    setRedirect('/workspaces/' + props.match.params.workspaceId + '/update');
  };

  const serviceStatus = (serviceName, successful, description) => {
    if (successful) {
      let label = (
        <div>
          {serviceName}
          <Icon name='status-positive' variant='success' />
        </div>
      );
      return <FormField label={label} description={description}></FormField>;
    } else {
      let label = (
        <div>
          {serviceName}
          <Icon name='status-negative' variant='warning' />
        </div>
      );
      return <FormField label={label} description={description}></FormField>;
    }
  };

  const performAuditWorkspace = () => {
    setAuditButtonLoading(true);
    setNotifications([
      {
        type: 'success',
        content: `Start audit workspace.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
    setDisableButton(true);
    try {
      auditWorkspace({ workspaceId: workspace?.workspaceId });
      setAuditButtonLoading(false);
    } catch (err) {
      console.log('Exception when audit workspace', err);
      setNotifications([
        {
          type: 'error',
          content: err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setAuditButtonLoading(false);
    }
  };

  const performSyncWorkspace = () => {
    setSyncButtonLoading(true);
    setNotifications([
      {
        type: 'success',
        content: `Start sync workspace.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
    setDisableButton(true);
    try {
      syncWorkspace({ workspaceId: workspace?.workspaceId });
      setSyncButtonLoading(false);
    } catch (err) {
      console.log('Exception when sync workspace', err);
      setNotifications([
        {
          type: 'error',
          content: err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setSyncButtonLoading(false);
    }
  };

  const getStatus = (item) => {
    let currentStatus = <></>;

    switch (item?.status) {
      case 'Active_Sync':
        currentStatus = (
          <div>
            <Icon variant='success' name='status-positive' /> In sync
          </div>
        );
        break;
      case 'Active_Syncing':
        currentStatus = (
          <div>
            <Icon name='status-pending' variant='success' /> Syncing
          </div>
        );
        break;
      case 'Active_Auditing':
        currentStatus = (
          <div>
            <Icon name='status-pending' variant='success' /> Auditing
          </div>
        );
        break;
      case 'Active_Running':
        currentStatus = (
          <div>
            <Icon name='status-pending' variant='success' /> Running
          </div>
        );
        break;
      case 'Active_Outdated':
        currentStatus = (
          <div>
            <Icon name='status-warning' variant='warning' /> Outdated
          </div>
        );
        break;
      case 'Active_Error':
        currentStatus = (
          <div>
            <Icon name='status-warning' variant='warning' /> Error
          </div>
        );
        break;
      case 'Inactive':
        currentStatus = (
          <div>
            <Icon name='status-warning' variant='warning' /> Inactive
          </div>
        );
        break;
    }
    return (
      <>
        {currentStatus}
        <div>
          completed tasks: {item?.workspaceConfig['workflowCompletedTaskCount']} /{' '}
          {item?.workspaceConfig['workflowTotalTaskCount']}
        </div>
      </>
    );
  };

  if (redirect) return <Redirect push to={redirect} />;
  return (
    <>
      <Flashbar items={notifications}></Flashbar>
      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            onItemClick: handleRefresh,
            loading: refreshButtonLoading,
          },
          {
            text: 'CloudFormation drift check',
            onItemClick: performAuditWorkspace,
            disabled:
              workspace?.status == 'Active_Syncing' ||
              workspace?.status == 'Active_Auditing' ||
              workspace?.status == 'Active_Running' ||
              disableButton,
            loading: auditButtonLoading,
          },
          {
            text: 'Fix my workspace',
            onItemClick: performSyncWorkspace,
            disabled:
              workspace?.status == 'Active_Syncing' ||
              workspace?.status == 'Active_Auditing' ||
              workspace?.status == 'Active_Running' ||
              disableButton,
            loading: syncButtonLoading,
          },
          {
            text: 'Edit',
            onItemClick: performEditWorkspace,
          },
        ]}
        header='Workspace details'
      />

      <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
          <h2>{workspace?.workspaceName}</h2>
        </div>
        <ColumnLayout columns={4} variant='text-grid'>
          <div>
            <div className='awsui-util-label'>Workspace ID</div>
            <div> {workspace?.workspaceId}</div>
          </div>

          <div>
            <div className='awsui-util-label'>Group</div>
            <div>
              <Link href={createGroupDetailLink(workspace?.groupId)}>{workspace?.groupId}</Link>
            </div>
          </div>

          <div>
            <div className='awsui-util-label'>Owner</div>
            <div>
              <Link href={createPosixGroupLink(workspace?.owner)} external={true}>
                {workspace?.owner}
              </Link>
            </div>
          </div>

          <div>
            <div className='awsui-util-label'>Account ID</div>
            <div>{workspace?.accountId}</div>
          </div>

          <div>
            <div className='awsui-util-label'>Region</div>
            <div>{getRegion()}</div>
          </div>

          <div>
            <div className='awsui-util-label'>Last update</div>
            <div>
              {workspace?.workspaceConfig != undefined &&
              workspace.workspaceConfig?.lastExecutedWorkflowType != undefined
                ? workspace.workspaceConfig.lastExecutedWorkflowType + ' ' + workspace.updateDate
                : workspace?.updateDate}
            </div>
          </div>

          <div>
            <div className='awsui-util-label'>Status</div>
            <div>{getStatus(workspace)}</div>
          </div>

          <div>
            <div className='awsui-util-label'>Status Reason</div>
            <div>{workspace?.statusReason}</div>
          </div>
        </ColumnLayout>
        <ColumnLayout>
          <div>
            <div className='awsui-util-label'>Supported services</div>
            {serviceStatus(
              'FoundryCatalogManager ',
              catalogManagerEnabled,
              'Enables you to perform glue catalog management on Omni',
            )}
            {serviceStatus(
              'FoundryRedshiftManager ',
              redshiftManagerEnabled,
              'Enables you to perform redshift management on Omni',
            )}
            {serviceStatus(
              'FoundryAnalyticsManager ',
              analyticsManagerEnabled,
              'Enables you to perform analytics on the data through Omni',
            )}
          </div>
        </ColumnLayout>
      </div>
    </>
  );
};
