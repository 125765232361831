import * as React from 'react';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import {
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Flashbar,
  Modal,
  Pagination,
  PropertyFilter,
  Table,
  TableProps,
  Link,
} from '@amzn/awsui-components-react-v3/polaris';
import { listCatalogs, listDataSets } from '../../../src/api/catalog';
import { isValidAccountId, isValidRegion } from '../../../src/commons/validationUtils';
import { PageHeader } from 'src/components/subscriptions/common';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { editDataLakeRole, listDataLakeRoleProperty } from '../../../src/api/permissions';
import { defaultWrapLinesPreference, i18nStrings, largePageSizePreference, paginationLabels } from 'src/commons/tables';
import { compareBy } from '../../../src/components/utils/sorting';
import { DATA_LOAD_LIMIT, REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { createGlueCatalogDetailLink, createGlueDatabaseDetailLink, createDatasetDetailLink } from 'src/routes';
import { CopiableText, DefaultRouteProps } from 'src/commons/common';
import { ownerLinkItem } from 'src/components/workspaces/common/common';

export interface BrowseTableProps extends DefaultRouteProps {
  allowListed: boolean;
  setContentType: any;
  idFilter?: string[];
  extraFeatures: boolean;
  onlyShowDatasetName?: boolean;
  title: string;
  loading?: boolean;
  roleArns?: string[];
  cartItemIds?: string[];
  addToCart?: any;
  activeGroup?: string;
  iamDatasetPermission?: boolean;
  multiSelection?: boolean;
  disableFiltering?: boolean;
}

const findRoleArn = (item, roleArns) => {
  if (roleArns == null) return null;

  for (const roleArn of roleArns) {
    if (roleArn.split(':')[4] == item.IdInfo.CatalogId) {
      return roleArn;
    }
  }
};

export const flattenItem = (item, roleArns, catalog) => {
  return {
    dataSetName: item['DataSetName'],
    databaseName: item['IdInfo']['DatabaseName'],
    tableName: item['IdInfo']['TableName'],
    dataSourceId: item['IdInfo']['DataSourceId'],
    catalogId: item['IdInfo']['CatalogId'],
    region: item['IdInfo']['Region'],
    catalogInfo: item['catalogInfo'] || catalog,
    catalogDisplayName: item['catalogDisplayName'],
    id: item['Id'],
    roleArn: findRoleArn(item, roleArns),
    supportedAccessTypes: item['SupportedAccessTypes'],
    owners: item['Owners'],
    dataClassificationOption: item['DataClassification'] === 'Private' ? item['DataClassification'] : 'Public',
    primaryOwner: item['PrimaryOwner'],
  };
};

const BrowseTable = (props: BrowseTableProps) => {
  const [allItems, setItems] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogsMap] = useState(new Map());
  const [notifications, setNotifications] = useState([]);
  const [redirect] = useState(undefined);
  const [isLoading, setLoading] = useState(true);
  const [roleProperties, setRoleProperties] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selected, setSelected] = useState([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [paginationDisabled, setPaginationDisabled] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const [columnDefinitions, setColumnDefinitions] = useState<TableProps.ColumnDefinition<any>[]>([
    {
      id: 'tableName',
      header: 'Table name',
      cell: (item) => <Link href={createDatasetDetailLink(item?.id)}>{item.tableName}</Link>,
      minWidth: '200px',
      sortingField: props.extraFeatures || props.multiSelection ? 'tableName' : null,
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) => (
        <Link href={createGlueDatabaseDetailLink(item?.catalogId, item?.databaseName, item?.region)}>
          {item.databaseName}
        </Link>
      ),
      minWidth: '200px',
      sortingField: props.extraFeatures || props.multiSelection ? 'databaseName' : null,
      sortingComparator: compareBy('databaseName', 'tableName'),
    },
    {
      id: 'catalogDisplayName',
      header: 'Catalog name',
      cell: (item) => (
        <Link href={createGlueCatalogDetailLink(item?.catalogId, item?.region)}>{item.catalogDisplayName}</Link>
      ),
      minWidth: '100px',
      sortingField: props.extraFeatures || props.multiSelection ? 'catalogDisplayName' : null,
      sortingComparator: compareBy('catalogDisplayName', 'databaseName', 'tableName'),
    },
    {
      id: 'owner',
      header: 'Owner',
      cell: (item) => ownerLinkItem(item.primaryOwner, props.workspaceNameMap),
      minWidth: '200px',
      sortingField: 'owner',
    },
  ]);

  // Load role properties.  Currently used to indicate that some items
  // can't be added to cart because they are pending permissions or
  // have already approved permissions.  Note that the active group

  // simplifies the dataset objects in order to make the rest of the code a bit cleaner
  const flattenItems = (items, roleArns) => {
    return items.map((item) => ({
      ...flattenItem(item, roleArns, null),
      catalogDisplayName: getCatalogName(item.IdInfo?.CatalogId, item.IdInfo?.Region),
    }));
  };

  const getCatalogName = (catalogId: string, region: string) => {
    const catalog = getCatalogFromMap(catalogId, region);
    if (catalog == null) {
      return String(catalogId);
    } else {
      return String(catalog.Name);
    }
  };

  const getCatalogFromMap = (catalogId: string, region: string) => {
    return catalogsMap.get(catalogId + ':' + region);
  };

  // is an optional props
  const loadRoleProperties = async () => {
    const roleProperties = await listDataLakeRoleProperty({
      groupId: props.activeGroup,
    });

    setRoleProperties(roleProperties);
  };

  const componentDidMount = async () => {
    if (props.onlyShowDatasetName) {
      setColumnDefinitions([
        {
          id: 'dataSetName',
          header: 'Table name',
          cell: (item) => <Link href={createDatasetDetailLink(item?.id)}>{item.tableName}</Link>,
          minWidth: '200px',
        },
      ]);
    }

    props.setContentType(TABLE_CONTENT_TYPE);
    if (props.roleArns != null) {
      columnDefinitions.push({
        id: 'roleArn',
        header: 'Role ARN',
        cell: (item) => (
          <div style={{ paddingBottom: 6 }}>
            <CopiableText loading={false} value={item.roleArn} name={''} />
          </div>
        ),
        minWidth: '200px',
      });
    }

    setLoading(true);
    await fetchAllData();
    if (props.extraFeatures && props.activeGroup) await loadRoleProperties();
    setLoading(false);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  useEffect(() => {
    fetchAllData();
  }, [props.idFilter]);

  useEffect(() => {
    setItems(
      allItems.map((item) => ({
        ...item,
        catalogDisplayName: getCatalogName(item.catalogId, item.region),
      })),
    );
  }, [catalogs]);

  useEffect(() => {
    if (props.extraFeatures && props.activeGroup) loadRoleProperties();
  }, [props.activeGroup]);

  // Fetch datasets and then their catalogs
  const fetchAllData = async () => {
    setLoading(true);
    await fetchDatasets();
    await fetchCatalogsForDataSets();
    setLoading(false);
  };

  // Gets the catalogs relevant to the datasets currently in state.items
  const fetchCatalogsForDataSets = async () => {
    // Use a set to make them unique
    const catalogIdRegions = new Set<string>();
    for (const item of allItems) {
      if (item.IdInfo != null && item.IdInfo.CatalogId != null && item.IdInfo.Region != null) {
        const key: string = item.IdInfo.CatalogId + ':' + item.IdInfo.Region;
        catalogIdRegions.add(key);
      }
    }

    // Start to build a list of catalog keys for the request
    const catalogKeys = [];
    for (const idRegion of catalogIdRegions) {
      const catalogIdAndRegion = idRegion.split(':');
      const catalogId = catalogIdAndRegion[0];
      const region = catalogIdAndRegion[1];
      // Non-null if there's a match
      if (isValidAccountId(catalogId) && isValidRegion(region)) {
        catalogKeys.push({
          CatalogId: catalogId,
          Region: region,
        });
      }
    }
    let catalogInfoList = [];
    let request = {
      Filter: {
        CatalogKeyList: catalogKeys,
      },
      NextToken: null,
    };
    let result = await listCatalogs(request);
    catalogInfoList = result.CatalogInfoList;
    while (result.NextToken != null) {
      request.NextToken = result.NextToken;
      result = await listCatalogs(request);
      catalogInfoList.push(...result.CatalogInfoList);
    }

    // Populate a map with our new catalogs
    catalogsMap.clear();
    for (let i = 0; i < catalogInfoList.length; i++) {
      addCatalogItemToMap(catalogInfoList[i]);
    }

    setCatalogs(catalogInfoList);
  };

  const fetchDatasets = async () => {
    let dataSets;
    let dataSetList = [];
    try {
      if (props.idFilter) {
        for (let index = 0; index < props.idFilter.length; index += DATA_LOAD_LIMIT) {
          let request = {
            Filter: { IdList: props.idFilter.slice(index, index + DATA_LOAD_LIMIT) },
            Limit: DATA_LOAD_LIMIT,
            IncludeTotalCount: true,
          };
          dataSets = await listDataSets(request);
          let currentList = dataSets.DataSetList.filter((item) => item.DataSourceType != REDSHIFT_DATASOURCE_ID);
          dataSetList.push(...currentList);
        }
        setNextToken(null);
        setAllDataLoaded(true);
        setTotalCount(props.idFilter.length);
      } else {
        let request = { Limit: DATA_LOAD_LIMIT, IncludeTotalCount: true };
        dataSets = await listDataSets(request);
        setNextToken(dataSets.NextToken);
        if (dataSets.NextToken == null) {
          setAllDataLoaded(true);
        }
        setTotalCount(dataSets.TotalCount);
        dataSetList = dataSets.DataSetList.filter((item) => item.DataSourceType != REDSHIFT_DATASOURCE_ID);
      }
      // here, datasets are filtered by "public" and "restricted" only,
      // The "private" datasets will be visible only to owners
      let publicAndRestrictedDataSets = [];
      let privateDataSets = [];
      for (const dataSet of dataSetList) {
        if (dataSet.DataClassification === 'Public' || dataSet.DataClassification === 'Restricted') {
          publicAndRestrictedDataSets.push(dataSet);
        } else {
          if (dataSet.Owners.includes(props.activeGroup)) {
            privateDataSets.push(dataSet);
          }

          // Apply valid Primary owner to datasets without one so it can be filterable
          dataSet.PrimaryOwner = dataSet.PrimaryOwner != null ? dataSet.PrimaryOwner : 'No primary owner';
        }
      }
      const datasets = publicAndRestrictedDataSets.concat(privateDataSets);
      const flatItems = flattenItems(datasets, props.roleArns);
      setItems(flatItems);
    } catch (e) {
      console.log('Exception when fetch datasets', e);
      setNotifications([
        {
          type: 'error',
          content: `Error when trying to fetch datasets: ${e.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const loadMoreData = async () => {
    if (props.idFilter) {
      return;
    }
    setPaginationDisabled(true);
    let request = { Limit: DATA_LOAD_LIMIT, NextToken: nextToken };
    let dataSets = await listDataSets(request);

    setNextToken(dataSets.NextToken);
    let dataSetList = dataSets.DataSetList.filter((item) => item.DataSourceType != REDSHIFT_DATASOURCE_ID);
    // here, datasets are filtered by "public" and "restricted" only,
    // The "private" datasets will be visible only to owners
    let publicAndRestrictedDataSets = [];
    let privateDataSets = [];
    for (const dataSet of dataSetList) {
      if (dataSet.DataClassification === 'Public' || dataSet.DataClassification === 'Restricted') {
        publicAndRestrictedDataSets.push(dataSet);
      } else {
        if (dataSet.Owners.includes(props.activeGroup)) {
          privateDataSets.push(dataSet);
        }
      }

      // Apply valid Primary owner to datasets without one so it can be filterable
      dataSet.PrimaryOwner = dataSet.PrimaryOwner != null ? dataSet.PrimaryOwner : 'No primary owner';
    }

    const datasets = publicAndRestrictedDataSets.concat(privateDataSets);

    const flatItems = flattenItems(datasets, props.roleArns);
    let currentItems = allItems;
    currentItems.push(...flatItems);
    setItems(currentItems);
    if (dataSets.NextToken == null) {
      setAllDataLoaded(true);
    }
    setPaginationDisabled(false);
  };

  const handleIamDatasetsPermissionActions = async (e) => {
    if (e.detail.id === 'relinquish') openModal();
  };

  const handleReplinquishPermission = async () => {
    setButtonLoading(true);

    const datasetsId = [];
    for (var i = 0; i < selected.length; i++) {
      datasetsId.push(selected[i].id);
    }

    await editDataLakeRole({
      groupId: props.activeGroup,
      datasetsToDelete: datasetsId,
    });

    setSelected([]);

    for (var i = 0; i < datasetsId.length; i++) {
      props.idFilter.splice(props.idFilter.indexOf(datasetsId[i]), 1);
    } // Potential bug: datasets are spliced on the front-end, regardless of the response.

    closeModal();

    setLoading(true);
    await fetchDatasets();
    setLoading(false);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setButtonLoading(false);
    setModalVisible(false);
  };

  const addCatalogItemToMap = (item) => {
    const catalogId: string = item.CatalogId;
    const region: string = item.Region;
    catalogsMap.set(catalogId + ':' + region, item);
  };

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {},
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'catalogDisplayName',
          sortingComparator: compareBy('catalogDisplayName', 'databaseName', 'tableName'),
        },
      },
    },
    selection: {},
    propertyFiltering: {
      noMatch: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No matches</b>
          </div>
          <p className='awsui-util-mb-s'>We can’t find a match.</p>
        </div>
      ),
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No datasets</b>
          </div>
          <p className='awsui-util-mb-s'>No datasets were found in the catalog.</p>
        </div>
      ),

      filteringProperties: [
        {
          propertyLabel: 'Catalog name',
          key: 'catalogDisplayName',
          groupValuesLabel: 'Catalog names',
        },
        {
          propertyLabel: 'Database',
          key: 'databaseName',
          groupValuesLabel: 'Databases',
        },
        {
          propertyLabel: 'Table name',
          key: 'tableName',
          groupValuesLabel: 'Table names',
        },
        {
          propertyLabel: 'Data visibility',
          key: 'dataClassificationOption',
          groupValuesLabel: 'Data visibility options',
        },
        {
          propertyLabel: 'Primary owner',
          key: 'primaryOwner',
          groupValuesLabel: 'Primary owners',
        },
      ],
    },
  });

  const handlePageChange = async (e) => {
    if (paginationProps.currentPageIndex == paginationProps.pagesCount && !allDataLoaded) {
      await loadMoreData();
    }
    if (
      paginationProps.currentPageIndex != paginationProps.pagesCount ||
      (e.detail.currentPageIndex <= paginationProps.pagesCount &&
        e.detail.currentPageIndex != paginationProps.currentPageIndex)
    ) {
      paginationProps.onChange(e);
    }
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Flashbar items={notifications} />
      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        trackBy={'id'}
        selectedItems={selected}
        onSelectionChange={({ detail }) => setSelected(detail.selectedItems)}
        header={
          <>
            <PageHeader
              buttons={
                props.extraFeatures && props.addToCart
                  ? [
                      {
                        text: 'Add to cart',
                        variant: 'normal',
                        disabled: selected.length === 0,
                        onItemClick: () => {
                          props.addToCart(selected);
                          setSelected([]);
                        },
                      },
                    ]
                  : props.iamDatasetPermission
                  ? [
                      {
                        text: 'Actions',
                        onItemClick: handleIamDatasetsPermissionActions,
                        items: [
                          {
                            text: 'Relinquish permission',
                            id: 'relinquish',
                            disabled: !selected.length,
                          },
                        ],
                        loading: isLoading,
                      },
                    ]
                  : []
              }
              header={
                <>
                  {props.title}
                  <span className='awsui-util-header-counter'>
                    {isLoading || ` (${totalCount}${!allDataLoaded && props.idFilter ? '+' : ''})`}
                  </span>
                </>
              }
            />

            <Modal
              visible={modalVisible}
              header={[`Are you sure to relinquish the permission for datasets below?`]}
              onDismiss={closeModal}
              footer={
                <span className='awsui-util-f-r'>
                  <Button variant='link' onClick={closeModal}>
                    No
                  </Button>
                  <Button variant='primary' loading={buttonLoading} onClick={handleReplinquishPermission}>
                    Yes
                  </Button>
                </span>
              }
            >
              <div>
                Do you want to remove permissions for datasets below?
                {selected.map(({ id }) => (
                  <li key={id}>{id}</li>
                ))}
              </div>
            </Modal>
          </>
        }
        selectionType={(props.extraFeatures || props.multiSelection) && props.allowListed ? 'multi' : undefined}
        isItemDisabled={(item) => {
          if (!props.allowListed) return false;
          if (props.multiSelection) return;
          return (
            item['supportedAccessTypes'] == null ||
            // Currently disabling all IAM bulk permission requests
            !item['supportedAccessTypes'].includes('IAM') ||
            // Not allowed to re-add if already in cart
            props.cartItemIds.includes(item['id']) ||
            // Not allowed to add tables that are pending or already approved
            (roleProperties !== undefined &&
              (roleProperties.approvedPermissions.includes(item['id']) ||
                roleProperties.pendingPermissions.includes(item['id'])))
          );
        }}
        loading={isLoading}
        filter={
          (props.extraFeatures || props.multiSelection) && (
            <PropertyFilter
              {...propertyFilterProps}
              disabled={isLoading || props.disableFiltering}
              i18nStrings={
                props.disableFiltering
                  ? {
                      ...i18nStrings,
                      filteringPlaceholder: 'To discover datasets, use search page instead.',
                    }
                  : i18nStrings
              }
              countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
            />
          )
        }
        pagination={
          (props.extraFeatures || props.multiSelection) && (
            <Pagination
              {...paginationProps}
              ariaLabels={paginationLabels}
              onChange={(e) => handlePageChange(e)}
              // currentPageIndex={currentPageIndex}
              openEnd={!allDataLoaded}
              disabled={paginationDisabled}
            />
          )
        }
        preferences={
          (props.extraFeatures || props.multiSelection) && (
            <CollectionPreferences
              title={'Preferences'}
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={largePageSizePreference}
              wrapLinesPreference={defaultWrapLinesPreference}
            />
          )
        }
      />
    </>
  );
};

export default BrowseTable;
